import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { PageBase } from "@/components/PageBase";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { axiosPut, axiosPost } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import useEmpresa from "@/hooks/useEmpresa";
import { SelectButton, TabPanel, TabView } from "primereact";
import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";

const url = "/fiscal/cst-icms/";
const urlvoltar = "/fiscal/cadastros/outros/icms";

export const FiscalIcmsForm = (props) => {
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const { empresaSelecionadaId } = useEmpresa();

    const formik = useFormik({
        initialValues: props.location.state?.icms || {
            empresa: empresaSelecionadaId,
            codigo: "",
            descricao: "",
            simples_nacional: true,
            csosn: "",
            tributado: false,
            subs_tributaria: false,
            obs: "",
            isento: false,
            destaca_icms_entrada: false,
            destaca_icms_saida: false,
            reducao_icms_entrada: false,
            reducao_icms_saida: false,
            reducao_subst_entrada: false,
            reducao_subst_saida: false,
            fcp: false,
            fcp_diferimento: false,
            fcp_subst: false,
            fcp_retido_st: false,
            fcp_consumidor_final: false,
            cst_efetivo: false,
            cst_desonerado: false,
            cst_diferido_ent: false,
            cst_diferido_sai: false,
            icms_proprio: false,
            monofasico_proprio: false,
            monofasico_retencao: false,
            monofasico_diferido: false,
            monofasico_cobrado_ant: false,
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                codigo: Yup.string().required("O campo 'código' é obrigatório."),
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(`${url}`, values);
                hideLoading();
                if (resposta.status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "ICMS cadastrado com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        cancelar();
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(`${url}${values.id}/`, values);
                hideLoading();
                if (resposta.status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "ICMS alterado com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        cancelar();
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const cancelar = () => {
        history.push(urlvoltar);
    };

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Novo ICMS" : "Manutenção de ICMS"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <TabView className="tabview-custom">
                    <TabPanel header="Dados básicos" leftIcon="pi pi-book">
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="codigo">Código *</label>
                                <InputText
                                    id="codigo"
                                    name="codigo"
                                    autoComplete="off"
                                    autoFocus
                                    value={formik.values.codigo}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.codigo })}
                                />
                                {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-8">
                                <label htmlFor="descricao">Descrição *</label>
                                <InputText
                                    id="descricao"
                                    name="descricao"
                                    autoComplete="off"
                                    value={formik.values.descricao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.descricao })}
                                />
                                {formik.errors.descricao && (
                                    <small className="p-error">{formik.errors.descricao}</small>
                                )}
                            </div>
                            <div className="p-field-checkbox p-col-12 p-md-2 p-mt-5">
                                <Checkbox
                                    inputId="simples-nacional"
                                    name="simples_nacional"
                                    checked={formik.values.simples_nacional}
                                    onChange={formik.handleChange}
                                />
                                <label htmlFor="simples-nacional">Simples Nacional?</label>
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field-checkbox p-col-12 p-md-3 p-mt-5">
                                <Checkbox
                                    inputId="subs-tributaria"
                                    name="subs_tributaria"
                                    checked={formik.values.subs_tributaria}
                                    onChange={formik.handleChange}
                                />
                                <label htmlFor="subs-tributaria">Substituição tributaria?</label>
                            </div>
                            <div className="p-field p-col-12 p-md-7">
                                <label htmlFor="obs">Observação</label>
                                <InputText
                                    id="obs"
                                    name="obs"
                                    autoComplete="off"
                                    value={formik.values.obs}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="p-field-checkbox p-col-12 p-md-2 p-mt-5">
                                <Checkbox
                                    inputId="ativo"
                                    name="ativo"
                                    checked={formik.values.ativo}
                                    onChange={formik.handleChange}
                                />
                                <label htmlFor="ativo">Ativo?</label>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel header="Parâmetros" leftIcon="pi pi-cog">
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="isento">Isento</label>
                                <SelectButton
                                    id="isento"
                                    name="isento"
                                    value={formik.values.isento}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="destaca_icms_entrada">Destaca ICMS Entrada</label>
                                <SelectButton
                                    id="destaca_icms_entrada"
                                    name="destaca_icms_entrada"
                                    value={formik.values.destaca_icms_entrada}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="destaca_icms_saida">Destaca ICMS Saída</label>
                                <SelectButton
                                    id="destaca_icms_saida"
                                    name="destaca_icms_saida"
                                    value={formik.values.destaca_icms_saida}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="reducao_icms_entrada">Redução ICMS Entrada</label>
                                <SelectButton
                                    id="reducao_icms_entrada"
                                    name="reducao_icms_entrada"
                                    value={formik.values.reducao_icms_entrada}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="reducao_icms_saida">Redução ICMS Saída</label>
                                <SelectButton
                                    id="reducao_icms_saida"
                                    name="reducao_icms_saida"
                                    value={formik.values.reducao_icms_saida}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="reducao_subst_entrada">Redução Sub. ST Entrada</label>
                                <SelectButton
                                    id="reducao_subst_entrada"
                                    name="reducao_subst_entrada"
                                    value={formik.values.reducao_subst_entrada}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="reducao_subst_saida">Redução Sub. ST Saída</label>
                                <SelectButton
                                    id="reducao_subst_saida"
                                    name="reducao_subst_saida"
                                    value={formik.values.reducao_subst_saida}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="fcp">FCP</label>
                                <SelectButton
                                    id="fcp"
                                    name="fcp"
                                    value={formik.values.fcp}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="fcp_diferimento">FCP Diferimento</label>
                                <SelectButton
                                    id="fcp_diferimento"
                                    name="fcp_diferimento"
                                    value={formik.values.fcp_diferimento}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="fcp_subst">FCP Substituto ST.</label>
                                <SelectButton
                                    id="fcp_subst"
                                    name="fcp_subst"
                                    value={formik.values.fcp_subst}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="fcp_retido_st">FCP Retido ST.</label>
                                <SelectButton
                                    id="fcp_retido_st"
                                    name="fcp_retido_st"
                                    value={formik.values.fcp_retido_st}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="fcp_consumidor_final">FCP Consumidor Final</label>
                                <SelectButton
                                    id="fcp_consumidor_final"
                                    name="fcp_consumidor_final"
                                    value={formik.values.fcp_consumidor_final}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="cst_efetivo">CST Efetivo</label>
                                <SelectButton
                                    id="cst_efetivo"
                                    name="cst_efetivo"
                                    value={formik.values.cst_efetivo}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="cst_desonerado">CST Desonerado</label>
                                <SelectButton
                                    id="cst_desonerado"
                                    name="cst_desonerado"
                                    value={formik.values.cst_desonerado}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="cst_diferido_ent">CST Diferido Entrada</label>
                                <SelectButton
                                    id="cst_diferido_ent"
                                    name="cst_diferido_ent"
                                    value={formik.values.cst_diferido_ent}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="cst_diferido_sai">CST Diferido Saída</label>
                                <SelectButton
                                    id="cst_diferido_sai"
                                    name="cst_diferido_sai"
                                    value={formik.values.cst_diferido_sai}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="icms_proprio">ICMS Próprio</label>
                                <SelectButton
                                    id="icms_proprio"
                                    name="icms_proprio"
                                    value={formik.values.icms_proprio}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="monofasico_proprio">Monofásico Próprio</label>
                                <SelectButton
                                    id="monofasico_proprio"
                                    name="monofasico_proprio"
                                    value={formik.values.monofasico_proprio}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="monofasico_retencao">Monofásico Retenção</label>
                                <SelectButton
                                    id="monofasico_retencao"
                                    name="monofasico_retencao"
                                    value={formik.values.monofasico_retencao}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="monofasico_diferido">Monofásico Diferido</label>
                                <SelectButton
                                    id="monofasico_diferido"
                                    name="monofasico_diferido"
                                    value={formik.values.monofasico_diferido}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="monofasico_cobrado_ant">Mono. Cobrado Anteriormente</label>
                                <SelectButton
                                    id="monofasico_cobrado_ant"
                                    name="monofasico_cobrado_ant"
                                    value={formik.values.monofasico_cobrado_ant}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                        </div>
                    </TabPanel>
                </TabView>
                <CamposObrigatorios />
                <div className="p-grid p-col-12 p-md-6">
                    <Button label="Gravar" type="submit" icon="pi pi-save" className="p-button-info p-mr-2" />
                    <Button
                        label="Cancelar"
                        icon="pi pi-times"
                        type="button"
                        className="p-button-danger p-mr-2"
                        onClick={cancelar}
                    />
                </div>
            </form>
        </PageBase>
    );
};
