import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import MakoListagem from "@/components/MakoListagem";
import { useHistory } from "react-router-dom";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import { ConfirmDialog } from "primereact/confirmdialog";
import { axiosDelete } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import { Dialog } from "primereact/dialog";
import { ProdutoGradeAtributosFormModal } from "./formModal";
import {
    BooleanFiltroTemplate,
    CodigoFiltroTemplate,
    DropdownFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";

export const ProdutoGradeAtributosPage = () => {
    const history = useHistory();
    const [atributo, setAtributo] = useState(null);
    const [deleteDialogTable, setDeleteDialogTable] = useState(false);
    const [gradeDialog, setGradeDialog] = useState(false);
    const [gradeAtributo, setGradeAtributo] = useState(null);
    const listagemRef = useRef(null);
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();

    const actionValorNumerico = (rowData) => {
        let valor;
        if (rowData.valor_numerico === true) {
            valor = "Sim";
        } else {
            valor = "Não";
        }
        return (
            <>
                <div className="p-text-normal">{valor}</div>
            </>
        );
    };

    const actionCalculaMedida = (rowData) => {
        let valor;
        if (rowData.calcula_medida === true) {
            valor = "Sim";
        } else {
            valor = "Não";
        }
        return (
            <>
                <div className="p-text-normal">{valor}</div>
            </>
        );
    };

    const actionUsaPreco = (rowData) => {
        let valor;
        if (rowData.usa_preco_medida_servico === true) {
            valor = "Sim";
        } else {
            valor = "Não";
        }
        return (
            <>
                <div className="p-text-normal">{valor}</div>
            </>
        );
    };

    const actionService = (rowData) => {
        let service;
        if (rowData.servico_venda !== null) {
            service = rowData.servico_venda.nome;
        } else {
            service = "";
        }

        return (
            <>
                <div className="p-text-normal">{service}</div>
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_CADASTRO_GRADEATRIBUTO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar Cadastro de Atributo"
                    tooltipOptions={{ position: "left" }}
                    type="button"
                    onClick={() => exibirGradeDialog(rowData)}
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_CADASTRO_GRADEATRIBUTO_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    type="button"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Deletar Atributo"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const AtributoFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "id",
                    optionLabel: "nome",
                    url: "/produtos/atributos/?limit=100",
                }}
                options={options}
            />
        );
    };

    const ServicoVendaFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "id",
                    optionLabel: "nome",
                    url: "/vendas/servicos-vendas/?limit=100",
                }}
                options={options}
            />
        );
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { minWidth: "120px" },
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "atributo.nome",
            header: "Atributo",
            style: { minWidth: "250px" },
            filter: true,
            filterElement: AtributoFiltroTemplate,
            filterField: "atributo"
        },
        {
            field: "codigo",
            header: "Grade",
            style: { minWidth: "200px" },
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "opcoes_valor",
            header: "Opções",
            style: { minWidth: "350px" },
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "valor_numerico",
            header: "Valor Numérico",
            action: (e) => actionValorNumerico(e),
            style: { minWidth: "200px" },
            filter: true,
            filterElement: BooleanFiltroTemplate,
        },
        {
            field: "calcula_medida",
            header: "Calcula Medida",
            action: (e) => actionCalculaMedida(e),
            style: { minWidth: "120px" },
            filter: true,
            filterElement: BooleanFiltroTemplate,
        },
        {
            field: "servico_venda",
            header: "Serviço",
            action: (e) => actionService(e),
            style: { minWidth: "200px" },
            filter: true,
            filterElement: ServicoVendaFiltroTemplate,
            filterField: "servico_venda"
        },
        {
            field: "usa_preco_medida_servico",
            header: "Usa Preço Medida Serviço",
            action: (e) => actionUsaPreco(e),
            style: { minWidth: "250px" },
            filter: true,
            filterElement: BooleanFiltroTemplate,
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "200px" },
            frozen: true,
            alignFrozen: "right",
        },
    ];

    const deleteParametro = async () => {
        showLoading();
        const resposta = await axiosDelete(`/produtos/grades-atributos/${atributo.id}`);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            showSuccess({
                summary: "Sucesso!",
                detail: "Grade Atributo deletado com sucesso!",
                life: 2000,
            });
            listagemRef.current?.buscarDados();
        } else {
            showError({
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
        esconderDeleteDialog();
    };

    const confirmarDelete = (gradeatributo) => {
        setAtributo(gradeatributo);
        setDeleteDialogTable(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialogTable(false);
    };

    const esconderGradeDialog = () => {
        setGradeDialog(false);
        setGradeAtributo(null);
        listagemRef.current?.buscarDados();
    };

    const exibirGradeDialog = (grade) => {
        setAtributo(grade.atributo);
        setGradeAtributo(grade);
        setGradeDialog(true);
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.PRODUTO_CADASTRO_GRADEATRIBUTO_INCLUIR]}
                componente={Button}
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                type="button"
                onClick={() => history.push("/produto/cadastros/grade-atributos/form")}
                label="Novo"
            />
        </>
    );

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Grade Atributos"
                        colunas={colunas}
                        painelEsquerdo={cabecalhoTabela}
                        urlPesquisa={`/produtos/grades-atributos/`}
                        filtros={{
                            id: { value: null, matchMode: "equals" },
                            atributo: { value: null, matchMode: "equals" },
                            codigo: { value: null, matchMode: "equals" },
                            opcoes_valor: { value: null, matchMode: "equals" },
                            valor_numerico: { value: null, matchMode: "equals" },
                            calcula_medida: { value: null, matchMode: "equals" },
                            servico_venda: { value: null, matchMode: "equals" },
                            usa_preco_medida_servico: { value: null, matchMode: "equals" },
                        }}
                        configTabela={{
                            paginator: true,
                            lazy: true,
                        }}
                    />
                </div>
                <Dialog
                    header={`Adicionar Grade Atributo (${atributo?.nome})`}
                    visible={gradeDialog}
                    breakpoints={{ "960px": "75vw" }}
                    style={{ width: "65vw" }}
                    onHide={() => setGradeDialog(false)}
                >
                    <ProdutoGradeAtributosFormModal
                        listagemRef={listagemRef}
                        atributo={atributo?.id || null}
                        esconderDialog={esconderGradeDialog}
                        gradeAtributo={gradeAtributo}
                    />
                </Dialog>
                <ConfirmDialog
                    visible={deleteDialogTable}
                    onHide={() => esconderDeleteDialog()}
                    header="Confirmação"
                    message={
                        atributo && (
                            <span>
                                {"Deseja realmente excluir a grade atributo código: "}
                                <b>{atributo.codigo}</b>?
                            </span>
                        )
                    }
                    icon="pi pi-info-circle p-mr-3"
                    accept={deleteParametro}
                    acceptLabel="Sim"
                    acceptClassName="p-button-danger"
                    reject={esconderDeleteDialog}
                    rejectLabel="Não"
                />
            </div>
        </div>
    );
};
