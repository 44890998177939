import React, { useCallback, useEffect, useState, useRef } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import MakoListagem from "@/components/MakoListagem";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { BotaoDelete } from "@/components/BotaoDelete";
import { Dropdown } from "@/components/Dropdown";
import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { ModalContaBancaria } from "@/pages/Financeiro/Cadastros/ContaBancaria/ModalContaBancaria";
import { ModalChavePix } from "@/pages/Financeiro/Cadastros/ChavePix/ModalChavePix";
import { TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE } from "@/assets/constants/financeiro";
import { dataToStr } from "@/assets/util/datas";
import { parseMoeda } from "@/assets/util/util";
import { axiosGet, axiosPost, axiosPut } from "@/services/http";
import useEntrada from "@/hooks/useEntrada";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useToast from "@/hooks/useToast";
import useClearRefs from "@/hooks/useClearRefs";
import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";

export const FinanceiroEntradaForm = () => {
    const [toggleInfoEntrada, setToggleInfoEntrada] = useState(true);
    const [toggleInfoOC, setToggleInfoOC] = useState(true);
    const [chavesPix, setChavesPix] = useState([]);
    const [loadingChavePix, setLoadingChavePix] = useState(false);
    const [contasBancarias, setContasBancarias] = useState([]);
    const [loadingContBancarias, setLoadingContBancarias] = useState(false);
    const listagemRef = useRef(null);
    const modalCBRef = useRef(null);
    const modalCPRef = useRef(null);
    const [formatarDocumento] = useFormatCNPJCPF();
    const { dadosBasicos, entradaPendente } = useEntrada();
    const { showWarning, showError } = useToast();

    useClearRefs(listagemRef, modalCBRef, modalCPRef);

    const edicaoBloqueada = !entradaPendente;

    const formik = useFormik({
        initialValues: {
            numero_parcela: 0,
            vencimento: null,
            valor_parcela: 0,
            forma_pagamento: null,
            numero_boleto: "",
            adiantamento: null,
            conta_bancaria: null,
            chave_pix: null,
            emissao: new Date(),
            historico_padrao: null,
            template_rateio: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                numero_parcela: Yup.number().required("O campo 'parcela' é obrigatório."),
                vencimento: Yup.date()
                    .required("O campo 'vencimento' é obrigatório.")
                    .typeError("Informe uma data válida."),
                valor_parcela: Yup.number()
                    .required("O campo 'valor' é obrigatório.")
                    .min(0.01, "O campo 'valor' não pode ser ZERO."),
                forma_pagamento: Yup.number()
                    .required("Escolha uma forma de pagamento.")
                    .typeError("Escolha uma forma de pagamento."),
                numero_boleto: Yup.string().when("forma_pagamento", {
                    is: (val) => val === 5,
                    then: Yup.string().required("Informe o código de barras do boleto."),
                }),
                conta_bancaria: Yup.number()
                    .when("forma_pagamento", {
                        is: (val) => val === 7,
                        then: Yup.number().required("Escolha uma conta bancária."),
                    })
                    .nullable(),
                chave_pix: Yup.number()
                    .when("forma_pagamento", {
                        is: (val) => val === 3,
                        then: Yup.number().required("Escolha uma chave PIX."),
                    })
                    .nullable(),
                template_rateio: Yup.number()
                    .required("Escolha uma regra de rateio.")
                    .typeError("Escolha uma regra de rateio."),
                historico_padrao: Yup.object()
                    .required("Escolha um histórico padrão.")
                    .typeError("Escolha um histórico padrão."),
            });
            await formSchema.validate(values, { abortEarly: false });
            const novaParcela = {
                ...values,
                vencimento: dataToStr(values.vencimento, "yyyy-MM-dd"),
                entrada: dadosBasicos?.id,
                numero_boleto: values.forma_pagamento === 5 ? values.numero_boleto : "",
                conta_bancaria: values.forma_pagamento === 7 ? values.conta_bancaria : null,
                chave_pix: values.forma_pagamento === 3 ? values.chave_pix : null,
                historico_padrao: values.historico_padrao?.id,
                emissao: dataToStr(values.emissao, "yyyy-MM-dd")
            };
            if (!values.id) {
                const { status, data } = await axiosPost("/compras/parcelas-entradas/", novaParcela);
                if (status === 201) {
                    formik.resetForm();
                    listagemRef.current?.buscarDados();
                } else if (status === 400 && data.message) {
                    showWarning({
                        summary: "Aviso!",
                        detail: data.message,
                        life: 4000,
                    });
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não foi possível processar sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                const { status, data } = await axiosPut(`/compras/parcelas-entradas/${novaParcela.id}/`, novaParcela);
                if (status === 200) {
                    formik.resetForm();
                    listagemRef.current?.buscarDados();
                } else if (status === 400 && data.message) {
                    showWarning({
                        summary: "Aviso!",
                        detail: data.message,
                        life: 4000,
                    });
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não foi possível processar sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const listarChavesPix = useCallback(async () => {
        setLoadingChavePix(true);
        const json = await axiosGet(`/financeiro/chaves-pix/?perfil=${dadosBasicos.fornecedor?.id}`);
        setLoadingChavePix(false);
        if (json.status === 200) {
            setChavesPix(json.data.results);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível listar suas chaves PIX.",
                life: 3000,
            });
        }
    }, [dadosBasicos, showError]);

    const listarContasBancarias = useCallback(async () => {
        setLoadingContBancarias(true);
        const json = await axiosGet(`/financeiro/contas-bancarias/?perfil=${dadosBasicos.fornecedor?.id}`);
        setLoadingContBancarias(false);
        if (json.status === 200) {
            setContasBancarias(json.data.results);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível listar suas contas bancárias.",
                life: 3000,
            });
        }
    }, [dadosBasicos, showError]);

    useEffect(() => {
        listarChavesPix();
        listarContasBancarias();
    }, [listarChavesPix, listarContasBancarias]);

    const chavePixItemTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{`${option.descricao} - ${option.chave}`}</div>
            </div>
        );
    };

    const contaBancariaItemTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{`BCO: ${option.banco.descricao} - AG: ${option.agencia} - CC: ${option.numero_conta}-${option.numero_conta_dv}`}</div>
            </div>
        );
    };

    const contaBancariaSelecionadaTemplate = (option, props) => {
        if (option) return contaBancariaItemTemplate(option);
        return <span>{props.placeholder}</span>;
    };

    const adiantamentoItemTemplate = (option) => {
        const { pagamento_original: pagamento } = option;
        return (
            <div className="country-item">
                <div>{`Doc.: ${pagamento.documento} - Saldo: ${parseMoeda(pagamento.saldo_adiantamento)}`}</div>
            </div>
        );
    };

    const formaPgtoBodyTemplate = (rowData) => {
        const formaPgto = TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE.find((el) => el.id === rowData.forma_pagamento);
        return <span>{formaPgto?.label || "N/A"}</span>;
    };

    const infoPgtoBodyTemplate = (rowData) => {
        if (rowData.forma_pagamento === "BO") {
            return <span>{rowData.numero_boleto}</span>;
        } else if (rowData.forma_pagamento === "DE") {
            const contaBancaria = contasBancarias.find((el) => el.id === rowData.conta_bancaria);
            return (
                <span>{`Bco: ${contaBancaria.banco.descricao} - AG: ${contaBancaria.agencia} - CC: ${contaBancaria.numero_conta}-${contaBancaria.numero_conta_dv}`}</span>
            );
        } else if (rowData.forma_pagamento === "PX") {
            const chavePix = chavesPix.find((el) => el.id === rowData.chave_pix);
            return <span>{`${chavePix.tipo_chave.descricao} - ${chavePix.chave}`}</span>;
        }
    };

    const actionBodyTemplate = (rowData) => {
        console.log(rowData)
        return (
            <div className="actions">
                <Button
                    icon="pi pi-pencil"
                    disabled={edicaoBloqueada}
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    onClick={() => formik.setValues(rowData)}
                />
                <BotaoDelete
                    url="/compras/parcelas-entradas/"
                    objetoId={rowData.id}
                    classNames="p-mb-1"
                    disabled={edicaoBloqueada}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Confirma a exclusão da parcela número <b>{rowData.numero_parcela}</b>?
                        </span>
                    }
                    msgToastErroExclusao="A parcela não pode ser excluída"
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const colunas = [
        { field: "numero_parcela", header: "Parcela", style: { width: "8%" } },
        {
            field: "vencimento",
            header: "Vencimento",
            dateFormat: "dd/MM/yyyy",
            style: { width: "10%" },
        },
        {
            field: "valor_parcela",
            header: "Valor",
            money: true,
            style: { width: "10%" },
        },
        { field: "adiantamento", header: "Adiantamento", style: { width: "10%" } },
        {
            field: "forma_pagamento",
            header: "Forma pgto",
            style: { width: "12%" },
            action: (e) => formaPgtoBodyTemplate(e),
        },
        { field: "info_pgto", header: "Informações sobre o pagamento", action: (e) => infoPgtoBodyTemplate(e) },
        { field: "actions", header: "Ações", style: { width: "10%" }, action: (e) => actionBodyTemplate(e) },
    ];

    return (
        <>
            <ModalContaBancaria ref={modalCBRef} aposSalvar={() => listarContasBancarias()} />
            <ModalChavePix ref={modalCPRef} aposSalvar={() => listarChavesPix()} />
            <Panel
                header="Informações da entrada"
                toggleable
                collapsed={toggleInfoEntrada}
                onToggle={(e) => setToggleInfoEntrada(e.value)}
            >
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="n-entrada">Número da entrada</label>
                        <InputText id="n-entrada" disabled value={dadosBasicos?.id || 0} />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="data-entrada"> Data da entrada</label>
                        <InputText
                            id="data-entrada"
                            disabled
                            value={dataToStr(dadosBasicos?.datahora_entrada, "dd/MM/yyyy") || ""}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-5">
                        <label htmlFor="fornecedor">Fornecedor</label>
                        <InputText id="fornecedor" disabled value={dadosBasicos?.fornecedor?.nome || ""} />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="identificacao">CPF / CNPJ</label>
                        <InputText
                            id="identificacao"
                            disabled
                            value={formatarDocumento(dadosBasicos?.fornecedor?.identificacao)}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-frete">Valor total do frete</label>
                        <MakoInputMoeda id="valor-frete" disabled valueMoeda={dadosBasicos?.valor_total_frete} />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-produtos">Valor total dos produtos</label>
                        <MakoInputMoeda id="valor-produtos" disabled valueMoeda={dadosBasicos?.valor_total_produtos} />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-entrada">Valor total da entrada</label>
                        <MakoInputMoeda id="valor-entrada" disabled valueMoeda={dadosBasicos?.valor_total_entrada} />
                    </div>
                </div>
            </Panel>
            <Panel
                header="Informações da ordem de compra"
                toggleable
                collapsed={toggleInfoOC}
                onToggle={(e) => setToggleInfoOC(e.value)}
                className="p-mt-3"
            >
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="intervalo-parcelas">Dias entre as parcelas</label>
                        <InputText id="intervalo-parcelas" disabled value={dadosBasicos?.intervalo_parcelas || ""} />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="valor-total-oc">Valor total da OC</label>
                        <MakoInputMoeda
                            id="valor-total-oc"
                            disabled
                            valueMoeda={dadosBasicos?.ordem_compra_principal?.valor_total_ordem_compra}
                        />
                    </div>
                </div>
            </Panel>
            <form className="p-mt-4" onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="n-parcela">Parcela *</label>
                        <InputNumber
                            id="n-parcela"
                            name="numero_parcela"
                            disabled={!!formik.values.id}
                            min={0}
                            value={formik.values.numero_parcela}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.numero_parcela })}
                        />
                        {formik.errors.numero_parcela && (
                            <small className="p-error">{formik.errors.numero_parcela}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="vencimento">Vencimento *</label>
                        <MakoCalendar
                            id="vencimento"
                            name="vencimento"
                            minDate={dadosBasicos.datahora_entrada}
                            valueCalendar={formik.values.vencimento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.vencimento })}
                        />
                        {formik.errors.vencimento && <small className="p-error">{formik.errors.vencimento}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor">Valor *</label>
                        <MakoInputMoeda
                            id="valor"
                            name="valor_parcela"
                            valueMoeda={formik.values.valor_parcela}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor_parcela })}
                        />
                        {formik.errors.valor_parcela && (
                            <small className="p-error">{formik.errors.valor_parcela}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="historico-padrao">Histórico *</label>
                        <MakoAutoComplete
                            id="historico"
                            name="historico_padrao"
                            urlSearch="/financeiro/historico-padrao/?descricao__unaccent__icontains="
                            field="descricao"
                            value={formik.values.historico_padrao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.historico_padrao })}
                        />
                        {formik.errors.historico_padrao && (
                            <small className="p-error">{formik.errors.historico_padrao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="template-rateio">Regra de rateio *</label>
                        <Dropdown
                            id="template-rateio"
                            name="template_rateio"
                            url={`/financeiro/templates-rateios/?query={id,descricao}&empresa=${dadosBasicos?.empresa}`}
                            optionLabel="descricao"
                            optionValue="id"
                            value={formik.values.template_rateio}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.template_rateio })}
                        />
                        {formik.errors.template_rateio && (
                            <small className="p-error">{formik.errors.template_rateio}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="forma-pagamento">Forma de pagamento *</label>
                        <Dropdown
                            id="forma-pagamento"
                            name="forma_pagamento"
                            options={TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.forma_pagamento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.forma_pagamento })}
                        />
                        {formik.errors.forma_pagamento && (
                            <small className="p-error">{formik.errors.forma_pagamento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="adiantamento">Código de adiantamento</label>
                        <Dropdown
                            id="adiantamento"
                            name="adiantamento"
                            url={`/financeiro/pagamentos-efetivados/?query={pagamento,id,valor,data}&pagamento__adiantamento=True&pagamento__entrada__isnull=True&pagamento__credor=${dadosBasicos.fornecedor?.id}`}
                            optionValue="id"
                            optionLabel="pagamento.documento"
                            itemTemplate={adiantamentoItemTemplate}
                            showClear
                            value={formik.values.adiantamento}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="numero-boleto">Número do boleto</label>
                        <InputText
                            id="numero-boleto"
                            name="numero_boleto"
                            value={formik.values.numero_boleto}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.numero_boleto })}
                        />
                        {formik.errors.numero_boleto && (
                            <small className="p-error">{formik.errors.numero_boleto}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="chave-pix">Chave PIX</label>
                        <div className="p-inputgroup">
                            <Dropdown
                                id="chave-pix"
                                name="chave_pix"
                                disabled={loadingChavePix}
                                options={chavesPix}
                                placeholder={!loadingChavePix ? "Selecione" : "Buscando..."}
                                optionValue="id"
                                optionLabel="chave"
                                itemTemplate={chavePixItemTemplate}
                                showClear
                                value={formik.values.chave_pix}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.chave_pix })}
                            />
                            <Button icon="pi pi-plus" type="button" onClick={() => modalCPRef.current?.abrirModal()} />
                        </div>
                        {formik.errors.chave_pix && <small className="p-error">{formik.errors.chave_pix}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="conta-bancaria">Conta bancária</label>
                        <div className="p-inputgroup">
                            <Dropdown
                                id="conta-bancaria"
                                name="conta_bancaria"
                                disabled={loadingContBancarias}
                                options={contasBancarias}
                                placeholder={!loadingContBancarias ? "Selecione" : "Buscando..."}
                                optionValue="id"
                                optionLabel="numero_conta"
                                valueTemplate={contaBancariaSelecionadaTemplate}
                                itemTemplate={contaBancariaItemTemplate}
                                showClear
                                value={formik.values.conta_bancaria}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.conta_bancaria })}
                            />
                            <Button icon="pi pi-plus" type="button" onClick={() => modalCBRef.current?.abrirModal()} />
                        </div>
                        {formik.errors.conta_bancaria && (
                            <small className="p-error">{formik.errors.conta_bancaria}</small>
                        )}
                    </div>
                </div>
                <CamposObrigatorios />
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button
                            type="submit"
                            icon="pi pi-save"
                            disabled={edicaoBloqueada}
                            label="Salvar parcela"
                            className="p-mr-2 p-mb-2"
                        />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            disabled={edicaoBloqueada}
                            label="Limpar formulário"
                            onClick={() => formik.resetForm()}
                            className="p-button-warning p-mr-2 p-mb-2"
                        />
                    </div>
                </div>
            </form>
            <MakoListagem
                ref={listagemRef}
                urlPesquisa={`/compras/parcelas-entradas/?entrada=${dadosBasicos?.id}`}
                colunas={colunas}
                configTabela={{ paginator: true, lazy: true }}
            />
        </>
    );
};
