import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import { axiosDelete } from "@/services/http";
import { PageBase } from "@/components/PageBase";
import { useHistory } from "react-router-dom";

export const EstoqueCargaPage = () => {
    const history = useHistory();
    const [deleteDialog, setDeleteDialog] = useState(false);

    const [carga, setCarga] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const listagemRef = useRef(null);

    const confirmarDelete = (carga) => {
        setCarga(carga);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const deleteCarga = async () => {
        showLoading();
        const resposta = await axiosDelete(`/transferencias/carga/${carga.id}`);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Carga deletada com sucesso!",
                life: 3000,
            });

            listagemRef.current?.buscarDados();
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
        setDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.ESTOQUE_CADASTRO_CARGA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de carga"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/estoque/cadastros/carga/form",
                            state: { carga: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.ESTOQUE_CADASTRO_CARGA_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Deletar carga"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.ESTOQUE_CADASTRO_CARGA_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/estoque/cadastros/carga/form")}
            />
        </>
    );

    const colunas = [
        {
            field: "id",
            header: "Carga",
            style: { minWidth: "10%" },
            filter: true,
        },
        { field: "rota_transf.descricao", header: "Rota de Transferência", style: { minWidth: "30%" }, filter: true },
        { field: "motorista.nome", header: "Motorista", style: { minWidth: "30%" }, filter: true },
        { field: "veiculo.placa", header: "Placa do Veículo", style: { minWidth: "10%" }, filter: true },

        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "15%" },
        },
    ];

    return (
        <PageBase>
            <Toast ref={toastRef} />

            <MakoListagem
                ref={listagemRef}
                titulo="Cargas"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={"/transferencias/carga/"}
                responsiva
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: { value: "", matchMode: "equals" },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                }}
            />
            <ConfirmDialog
                visible={deleteDialog}
                onHide={esconderDeleteDialog}
                header="Confirmação"
                message={
                    carga && (
                        <span>
                            {"Deseja realmente excluir a carga "}
                            <b>{carga.id}</b>?
                        </span>
                    )
                }
                icon="pi pi-info-circle p-mr-3"
                accept={deleteCarga}
                acceptLabel="Sim"
                acceptClassName="p-button-danger"
                reject={esconderDeleteDialog}
                rejectLabel="Não"
            />
        </PageBase>
    );
};
