import React, { useCallback, useRef } from "react";
import { Redirect, useHistory } from "react-router-dom";
import classNames from "classnames";
import useWebSocket from "react-use-websocket";
import { Button } from "primereact/button";

import AppBreadcrumb from "../AppBreadcrumb";
import { MakoDropdownEmpresas } from "../MakoDropdownEmpresas";
import { ModalAlterarSenhaUsuario } from "@/pages/Gestao/Usuarios/GerenciarUsuarios/modal/AlterarSenhaUsuario";
import useAuth from "@/hooks/useAuth";
import useParam from "@/hooks/useParam";
import useEmpresa from "@/hooks/useEmpresa";
import useCaixa from "@/hooks/useCaixa";
import useNotificacao from "@/hooks/useNotificacao";
import MakoAjuda from "../MakoAjuda";

const AppTopbar = (props) => {
    const modalAltSenhaRef = useRef(null);
    const history = useHistory();
    const { signed, signOut, user } = useAuth();
    const { loadParams } = useParam();
    const { empresaSelecionadaId, alterarEmpresaSelecionada } = useEmpresa();
    const { caixa } = useCaixa();
    const { addNotificacao, marcarTodasMsgComoLidas, notifications } = useNotificacao();

    useWebSocket(process.env.REACT_APP_WEBSOCKET_URL, {
        onOpen: () => console.log(`Connected to WebSocket`),
        onMessage: (message) => {
            if (message?.data) {
                addNotificacao(JSON.parse(message.data));
            }
        },
        // onError: (event) => { console.error(event); },
        shouldReconnect: (closeEvent) => true,
        reconnectInterval: 3000,
    });

    const notificationsItemClassName = classNames("notifications-item", {
        "active-menuitem": props.topbarNotificationMenuActive,
    });

    const profileItemClassName = classNames("profile-item", {
        "active-menuitem fadeInDown": props.topbarUserMenuActive,
    });

    const caixaItemClassName = classNames("caixa-item", {
        "active-menuitem fadeInDown": props.topbarCaixaMenuActive,
    });

    const handleSignOut = useCallback(() => {
        signOut();
    }, [signOut]);

    return !signed ? (
        <Redirect to="/login" />
    ) : (
        <>
            <ModalAlterarSenhaUsuario ref={modalAltSenhaRef} />
            <div className="layout-topbar">
                <div className="topbar-left">
                    <button type="button" className="menu-button p-link" onClick={props.onMenuButtonClick}>
                        <i className="pi pi-chevron-left" />
                    </button>
                    <span className="topbar-separator" />
                    <div
                        className="layout-breadcrumb viewname"
                        style={{ textTransform: "uppercase", fontSize: "13px" }}
                    >
                        <AppBreadcrumb routers={props.routers} />
                    </div>
                    <img
                        id="logo-mobile"
                        className="mobile-logo"
                        src="assets/layout/images/logo-dark.svg"
                        alt="diamond-layout"
                    />
                </div>

                <div className="topbar-right">
                    <ul className="topbar-menu">
                        <li className={profileItemClassName}>
                            <i className="pi pi-building p-mr-2" />
                            <MakoDropdownEmpresas
                                placeholder="Selecione uma empresa"
                                redefinirEmpresaPadrao
                                value={empresaSelecionadaId}
                                onChange={(e) => alterarEmpresaSelecionada(e.empresa)}
                            />
                        </li>
                        {caixa && (
                            <li className={caixaItemClassName}>
                                <button type="button" className="p-link" onClick={props.onTopbarCaixa}>
                                    <i className="fas fa-cash-register" />
                                </button>
                                <ul className="profile-menu fade-in-up">
                                    <li>
                                        <button type="button" className="p-link">
                                            <i className="fas fa-cash-register" />
                                            <span>{caixa?.caixa.conta_financeira.codigo}</span>
                                        </button>
                                    </li>
                                    <li>
                                        <button type="button" className="p-link">
                                            {caixa.bloqueado ? (
                                                <i className="pi pi-lock" style={{ color: "#D32F2F" }} />
                                            ) : (
                                                <i className="pi pi-unlock" style={{ color: "#689F38" }} />
                                            )}
                                            <span>{`Situação: ${
                                                caixa?.bloqueado ? "Bloqueado" : "Desbloqueado"
                                            }`}</span>
                                        </button>
                                    </li>
                                </ul>
                            </li>
                        )}
                        <li className={notificationsItemClassName}>
                            <button type="button" className="p-link" onClick={props.onTopbarNotification}>
                                <i className="pi pi-bell"> </i>
                                {notifications?.length > 0 && (
                                    <span className="topbar-badge">{notifications?.length}</span>
                                )}
                            </button>
                            <ul className="notifications-menu fade-in-up">
                                <li role="menuitem" style={{ textAlign: "center" }}>
                                    <Button
                                        label="Central de notificações"
                                        icon="pi pi-bell"
                                        className="p-button-secondary p-mb-2"
                                        onClick={() => history.push("/notificacoes")}
                                    />
                                    <Button
                                        label="Marcar todas como lida"
                                        icon="pi pi-check-square"
                                        className="p-button-info p-mb-2"
                                        onClick={marcarTodasMsgComoLidas}
                                    />
                                </li>
                            </ul>
                        </li>
                        <li>
                            <MakoAjuda />
                        </li>
                        <li className={profileItemClassName}>
                            <button type="button" className="p-link" onClick={props.onTopbarUserMenu}>
                                <i className="pi pi-user" />
                                <span className="profile-name"> {" " + user.usuario.username}</span>
                            </button>
                            <ul className="profile-menu fade-in-up">
                                <li>
                                    <button type="button" className="p-link" onClick={() => loadParams()}>
                                        <i className="pi pi-cog" />
                                        <span>Recarregar parâmetros</span>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        className="p-link"
                                        onClick={() => modalAltSenhaRef.current?.abrirModal(user)}
                                    >
                                        <i className="pi pi-lock" />
                                        <span>Alterar Senha</span>
                                    </button>
                                </li>
                                <li>
                                    <button type="button" className="p-link" onClick={handleSignOut}>
                                        <i className="pi pi-power-off" />
                                        <span>Sair do Sistema</span>
                                    </button>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default AppTopbar;
