import React, { useState, useRef } from "react";
import { Redirect } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";

import { ClonarOperacaoModalForm } from "./ModalClonarOperacaoFiscal";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import permissoes from "@/assets/constants/permissoes";
import { TIPO_ESFERA_FISCAL_CHOICE, TIPO_MOVIMENTACAO_ESTOQUE_CHOICE } from "@/assets/constants/constants";
import { axiosDelete } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import useLocalStorage from "@/hooks/useLocalStorage";
import storageKeys from "@/assets/constants/storage";

export const OperacoesFiscaisPage = () => {
    const [redirecionar, setRedirecionar] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [opFiscal, setOpFiscal] = useState(null);
    const [clonarOperacao, setClonarOperacao] = useState(false);
    const [empresaSelecionada] = useLocalStorage(storageKeys.EMPRESA_KEY);
    const { showLoading, hideLoading } = useLoading();

    const toastRef = useRef(null);
    const listagemRef = useRef(null);

    const confirmarDelete = (opFiscal) => {
        setOpFiscal(opFiscal);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const deleteParametro = async () => {
        showLoading();
        const resposta = await axiosDelete(`/fiscal/operacoes-fiscais/${opFiscal.id}`);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Operação deletada com sucesso!",
                life: 3000,
            });

            listagemRef.current?.buscarDados();
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
        setDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_CADASTRO_OPERACAOFISCAL_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de operação fiscal"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        setRedirecionar({
                            pathname: "/fiscal/cadastros/operacoes-fiscais/form",
                            opFiscal: rowData,
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_CADASTRO_OPERACAOFISCAL_EDITAR]}
                    componente={Button}
                    icon="pi pi-clone"
                    className="p-button-rounded p-button-help p-mr-2 p-mb-1"
                    tooltip="Clonar operação fiscal"
                    tooltipOptions={{ position: "left" }}
                    onClick={() => mostrarClonarDialog(rowData)}
                />
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_CADASTRO_OPERACAOFISCAL_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Deletar operação fiscal"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FISCAL_CADASTRO_OPERACAOFISCAL_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setRedirecionar("/fiscal/cadastros/operacoes-fiscais/form")}
            />
        </>
    );

    const formatTipoMovimento = (valor) => {
        if (valor === "E") return "Entrada";
        return "Saída";
    };

    const formatEsfera = (valor) => {
        if (valor === "D") return "Dentro do estado";
        else if (valor === "E") return "Entre estados";
        return "Internacional";
    };

    const codigoFiltroTemplate = (options) => {
        return <InputNumber value={options.value} onValueChange={(e) => options.filterCallback(e.value)} />;
    };

    const tipoMovimentoFiltroTemplate = (options) => {
        return (
            <Dropdown
                placeholder="Selecione"
                value={options.value}
                options={TIPO_MOVIMENTACAO_ESTOQUE_CHOICE}
                optionValue="id"
                optionLabel="label"
                onChange={(e) => options.filterCallback(e.value)}
            />
        );
    };

    const esferaFiltroTemplate = (options) => {
        return (
            <Dropdown
                placeholder="Selecione"
                value={options.value}
                options={TIPO_ESFERA_FISCAL_CHOICE}
                optionValue="id"
                optionLabel="label"
                onChange={(e) => options.filterCallback(e.value)}
            />
        );
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { minWidth: "50px" },
            filter: true,
            filterElement: codigoFiltroTemplate,
        },
        { field: "descricao", header: "Descrição", style: { minWidth: "350px" }, filter: true },
        {
            field: "tipo_movimento",
            header: "Tipo",
            filter: true,
            filterElement: tipoMovimentoFiltroTemplate,
            action: (e) => formatTipoMovimento(e.tipo_movimento),
            style: { minWidth: "100px" },
        },
        {
            field: "esfera",
            header: "Esfera",
            filter: true,
            filterElement: esferaFiltroTemplate,
            action: (e) => formatEsfera(e.esfera),
            style: { minWidth: "200px" },
        },
        {
            field: "vigencia_inicial",
            header: "Início",
            style: { minWidth: "120px" },
            dateFormat: "dd/MM/yyyy",
        },
        {
            field: "vigencia_final",
            header: "Fim",
            style: { minWidth: "120px" },
            dateFormat: "dd/MM/yyyy",
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "150px" },
        },
    ];

    const mostrarClonarDialog = (clone) => {
        setClonarOperacao(true);
        setOpFiscal(clone);
    };

    const esconderDialog = () => {
        setClonarOperacao(false);
        listagemRef.current?.buscarDados();
        setOpFiscal(null);
    };

    return redirecionar ? (
        <Redirect to={redirecionar} />
    ) : (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Operações Fiscais"
                        colunas={colunas}
                        painelEsquerdo={cabecalhoTabela}
                        urlPesquisa={`/fiscal/operacoes-fiscais/`}
                        responsiva
                        filtarPorEmpresa
                        naoBuscarSemEmpresa
                        filtros={{
                            id: { value: "", matchMode: "equals" },
                            tipo_movimento: { value: "", matchMode: "equals" },
                            esfera: { value: "", matchMode: "equals" },
                            aplicacao: { value: "", matchMode: "equals" },
                        }}
                        configTabela={{
                            paginator: true,
                            lazy: true,
                            filterDisplay: "menu",
                            scrollable: true,
                        }}
                    />
                    <ConfirmDialog
                        visible={deleteDialog}
                        onHide={esconderDeleteDialog}
                        header="Confirmação"
                        message={
                            opFiscal && (
                                <span>
                                    {"Deseja realmente excluir a operação "}
                                    <b>{opFiscal.descricao}</b>?
                                </span>
                            )
                        }
                        icon="pi pi-info-circle p-mr-3"
                        accept={deleteParametro}
                        acceptLabel="Sim"
                        acceptClassName="p-button-danger"
                        reject={esconderDeleteDialog}
                        rejectLabel="Não"
                    />
                </div>
            </div>
            <Dialog
                header="Clonar Operação Fiscal"
                onHide={() => setClonarOperacao(false)}
                visible={clonarOperacao}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "45vw", display: "block" }}
            >
                <ClonarOperacaoModalForm
                    esconderDialog={esconderDialog}
                    opFiscal={opFiscal}
                    empresa={empresaSelecionada}
                />
            </Dialog>
        </div>
    );
};
