import React, { useCallback, useEffect, useRef, useState } from "react";

import { useFormik } from "formik";

import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { MakoSelecionarEmails } from "@/components/MakoSelecionarEmails";
import { Dropdown } from "@/components/Dropdown";

import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import useLoadingLocal from "@/hooks/useLoadingLocal";
import useRelatorio from "@/hooks/useRelatorio";
import useClearRefs from "@/hooks/useClearRefs";

import { dataToStr } from "@/assets/util/datas";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIO_PRODUTOS_TABELAPRECO } from "@/assets/constants/relatorios";
import { axiosGet } from "@/services/http";

export const RelatorioTabelaPrecos = () => {
    const [visible, setVisible] = useState(false);
    const [emails, setEmails] = useState(null);
    const [tags, setTags] = useState([]);

    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { solicitarRelatorio } = useRelatorio();

    const categoriaRef = useRef();

    useClearRefs(categoriaRef);

    const { setValues, ...formik } = useFormik({
        initialValues: {
            vigencia: null,
            tags: null,
            plano_recebimento: null,
            categoria: null,
        },
        onSubmit: handleSubmit,
    });

    const vigenciaItemTemplate = (item) => {
        return (
            <div>
                <p>
                    <small>Descrição: {item.descricao}</small>
                </p>
                <p>
                    {dataToStr(item.data_inicio, "dd/MM/yyyy")} |{" "}
                    {item.data_fim !== null ? dataToStr(item.data_fim, "dd/MM/yyyy") : "Sem data de fim"}
                </p>
            </div>
        );
    };

    const filtrosTemplate = [
        {
            key: "plano_recebimento",
            label: "Plano de recebimento",
            path: "descricao",
        },
        {
            key: "vigencia",
            label: "Vigência",
            formatter: (item) => {
                return `${dataToStr(item.data_inicio, "dd/MM/yyyy")} | ${
                    item.data_fim ? dataToStr(item.data_fim, "dd/MM/yyyy") : "Sem data de fim"
                }`;
            },
        },
        {
            key: "categoria",
            label: "Categoria",
            path: "nome",
        },
        {
            key: "tags",
            label: "Tags",
            path: "descricao",
            type: TIPOS_FILTROS_TEMPLATE.MULTISELECT,
        },
    ];

    async function handleSubmit(values = null) {
        try {
            let dadosValidados = { ...values };
            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                categoria: dadosValidados.categoria ? categoriaRef.current : null,
            });
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.plano_recebimento)
                dadosValidados.plano_recebimento = dadosValidados.plano_recebimento.id;
            if (dadosValidados.vigencia) dadosValidados.vigencia = dadosValidados.vigencia.id;
            if (dadosValidados.tags?.length) dadosValidados.tags = dadosValidados.tags.map((t) => t.id);

            let filtros = {};
            if (dadosValidados) {
                Object.keys(dadosValidados).forEach((key) => {
                    if (dadosValidados[key]) filtros[key] = dadosValidados[key];
                });
            }
            solicitarRelatorio({
                chave: RELATORIO_PRODUTOS_TABELAPRECO,
                emails,
                filtros,
                filtros_template: filtrosTemplateAplicados,
            });
            formik.resetForm();
        } catch (error) {
            console.log(error);
        }
    }

    const fetchTags = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/pessoas/tags-perfis/");
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            setTags(resposta.data.results);
        }
    }, [showLoading, hideLoading]);

    const handleConfirm = (emails) => {
        fecharModal();
        if (emails && emails.length > 0) {
            handlePreSubmit(emails);
        }
    };

    const handlePreSubmit = (emails = []) => {
        setEmails(emails);
        formik.handleSubmit();
    };

    const limparFiltro = () => {
        formik.resetForm();
    };

    const fecharModal = () => {
        setVisible(() => false);
    };

    const getCategoriaCompleta = (e) => {
        categoriaRef.current = e;
    };

    useEffect(() => fetchTags(), [fetchTags]);

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <form onSubmit={formik.handleSubmit}>
                    <div className="card">
                        <h5>Filtro para emissão de relatório de tabela de preços</h5>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="empresa">Vigéncia: *</label>
                                <Dropdown
                                    id="vigencia"
                                    name="vigencia"
                                    placeholder="Selecione uma vigência"
                                    filter
                                    filterBy="descricao"
                                    itemTemplate={vigenciaItemTemplate}
                                    optionLabel="descricao"
                                    url={"/produtos/precos-vigencia/"}
                                    value={formik.values.vigencia}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="tags">Tags Perfil:</label>
                                <MultiSelect
                                    id="tags"
                                    name="tags"
                                    placeholder={loading ? "Buscando..." : "Selecione uma tag ou várias"}
                                    disabled={loading}
                                    options={tags}
                                    optionLabel="descricao"
                                    value={formik.values.tags}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="plano_recebimento">Plano de recebimento:</label>
                                <Dropdown
                                    id="plano_recebimento"
                                    name="plano_recebimento"
                                    value={formik.values.plano_recebimento}
                                    onChange={formik.handleChange}
                                    optionLabel="descricao"
                                    url="/financeiro/planos-recebimentos/?search="
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="categoria">Categoria:</label>
                                <MakoDropdownCategoriasHierarquicas
                                    id="categoria"
                                    name="categoria"
                                    categoriaTituloSelecionavel
                                    getCategoriaCompleta={getCategoriaCompleta}
                                    value={formik.values.categoria}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </div>
                        <div className="p-grid p-col-12 p-md-6 p-mt-2">
                            <Button
                                label="Gerar PDF"
                                icon="pi pi-file-pdf"
                                type="button"
                                onClick={() => handlePreSubmit()}
                                className="p-button-info p-mr-2"
                            />
                            <Button
                                type="button"
                                onClick={() => setVisible(true)}
                                label="Enviar por email"
                                icon="pi pi-envelope"
                                className="p-button-info p-mr-2"
                            />
                            <Button
                                type="reset"
                                icon="pi pi-trash"
                                label="Limpar"
                                onClick={() => limparFiltro()}
                                className="p-button-warning p-mr-2"
                            />
                        </div>
                    </div>
                </form>
            </div>
            <Dialog visible={visible} onHide={fecharModal} style={{ width: "60vw" }} header="Informar emails">
                <MakoSelecionarEmails onCancel={fecharModal} onConfirm={handleConfirm} />
            </Dialog>
        </div>
    );
};
