import React from "react";
import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";

import { ResumoDevolucao } from "./ResumoDevolucao";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import useDevolucao from "@/hooks/useDevolucao";

export const FinanceiroDevolucaoForm = () => {
    const { dadosBasicos } = useDevolucao();

    return (
        <>
            <ResumoDevolucao devolucao={dadosBasicos} />
            <Panel header="Resumo financeiro">
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="itens-devolvidos">Itens devolvidos</label>
                        <InputText id="itens-devolvidos" disabled />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="estorno-cartao-credito">Estorno cartão de crédito</label>
                        <MakoInputMoeda id="estorno-cartao-credito" disabled />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="estorno-cartao-debito">Estorno cartão de débito</label>
                        <MakoInputMoeda id="estorno-cartao-debito" disabled />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="estorno-crediario">Estorno de crediário</label>
                        <MakoInputMoeda id="estorno-crediario" disabled />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="nova-transacao-cartao">Nova transação de cartão</label>
                        <MakoInputMoeda id="nova-transacao-cartao" disabled />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-cheque-devolvido">Valor cheque devolvido</label>
                        <MakoInputMoeda id="valor-cheque-devolvido" disabled />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="estorno-boleto">Estorno de boleto</label>
                        <MakoInputMoeda id="estorno-boleto" disabled />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="boletos-cancelados">Boletos cancelados</label>
                        <MakoInputMoeda id="boletos-cancelados" disabled />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-vale-troca">Valor do vale-troca</label>
                        <MakoInputMoeda id="valor-vale-troca" disabled />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-devolvido">Valor devolvido</label>
                        <MakoInputMoeda id="valor-devolvido" disabled />
                    </div>
                </div>
            </Panel>
        </>
    );
};
