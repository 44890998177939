import React, { useState, useRef } from "react";

import { useHistory } from "react-router-dom";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";

import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";

import { axiosDelete } from "@/services/http";

import permissoes from "@/assets/constants/permissoes";

import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

const url = "/produtos/vinculo-fornecedor-representante/";

export const VinculoRepresentanteFornecedorPage = () => {
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [vinculo, setVinculo] = useState(null);

    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const history = useHistory();

    const listagemRef = useRef(null);

    const confirmarDelete = (tag) => {
        setVinculo(tag);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const handleDelete = async () => {
        showLoading();
        const resposta = await axiosDelete(`${url}${vinculo.id}/`);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            showSuccess({
                severity: "success",
                summary: "Sucesso",
                detail: "Vinculo deletado com sucesso!",
                life: 3000,
            });

            listagemRef.current?.buscarDados();
        } else {
            showError({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
        setDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_CADASTRO_REPRESENTANTEFORNECEDOR_VINCULO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar vinculo"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/gestao/cadastros/vinculo-representante/form",
                            state: { vinculo: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_CADASTRO_REPRESENTANTEFORNECEDOR_VINCULO_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Deletar vinculo"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.GESTAO_CADASTRO_REPRESENTANTEFORNECEDOR_VINCULO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/gestao/cadastros/vinculo-representante/form")}
            />
        </>
    );

    const colunas = [
        {
            field: "id",
            header: "Id",
            style: { width: "12%" },
            filter: true,
        },
        {
            field: "representante",
            header: "Representante",
            action: ({ representante }) => `${representante.nome} | ${representante.identificacao}`,
        },
        {
            field: "fornecedor",
            header: "Fornecedor",
            action: ({ fornecedor }) => `${fornecedor.nome} | ${fornecedor.identificacao}`,
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "15%" },
        },
    ];

    return (
        <>
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <MakoListagem
                            ref={listagemRef}
                            titulo="Vínculos representante a fornecedor"
                            colunas={colunas}
                            painelEsquerdo={cabecalhoTabela}
                            urlPesquisa={url}
                            responsiva
                            configTabela={{
                                paginator: true,
                                lazy: true,
                                filterDisplay: "menu",
                            }}
                            filtros={{
                                id: { value: "", matchMode: "equals" },
                                // descricao: {
                                //     operator: "and",
                                //     constraints: [{ value: "", matchMode: "icontains" }],
                                // },
                            }}
                        />
                        <ConfirmDialog
                            visible={deleteDialog}
                            onHide={esconderDeleteDialog}
                            header="Confirmação"
                            message={
                                vinculo && (
                                    <span>
                                        {"Deseja realmente excluir o vinculo "}
                                        <b>{vinculo.descricao}</b>?
                                    </span>
                                )
                            }
                            icon="pi pi-info-circle p-mr-3"
                            accept={handleDelete}
                            acceptLabel="Sim"
                            acceptClassName="p-button-danger"
                            reject={esconderDeleteDialog}
                            rejectLabel="Não"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
