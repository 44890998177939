import React, { createContext, useCallback, useMemo, useState } from "react";

import * as pd from "@/assets/util/persistenciaDjango";
import { dataToStr } from "@/assets/util/datas";
import { axiosGet, axiosPost, axiosPatch } from "@/services/http";
import useAuth from "@/hooks/useAuth";
import {
    PESSOAS_PERFIL_CADASTRO_TELEFONE_OBRIGATORIEDADE,
    PESSOAS_PERFIL_CADASTRO_EMAIL_OBRIGATORIEDADE,
    PESSOAS_PERFIL_CADASTRO_REDESOCIAL_OBRIGATORIEDADE,
    PESSOAS_PERFIL_CADASTRO_ENDERECO_OBRIGATORIEDADE,
} from "@/assets/constants/parametros";
import useParam from "@/hooks/useParam";

const PessoaContext = createContext({});

export const PessoaProvider = ({ children }) => {
    const [submit, setSubmit] = useState(false);
    const [mostrarConfirm, setMostrarConfirm] = useState(false);
    const [loadingPessoa, setLoadingPessoa] = useState(false);
    const [pessoa, setPessoa] = useState({});
    const [enderecos, setEnderecos] = useState([]);
    const [telefones, setTelefones] = useState([]);
    const [emails, setEmails] = useState([]);
    const [redesSociais, setRedesSociais] = useState([]);
    const [customFields, setCustomFields] = useState(null);
    const [tags, setTags] = useState([]);
    const { user } = useAuth();
    const { getParam } = useParam();

    const emailObrigatorio = useMemo(() => {
        const param = getParam(PESSOAS_PERFIL_CADASTRO_EMAIL_OBRIGATORIEDADE);
        if (!param) return false;
        return param?.valor === "1";
    }, [getParam]);

    const telefoneObrigatorio = useMemo(() => {
        const param = getParam(PESSOAS_PERFIL_CADASTRO_TELEFONE_OBRIGATORIEDADE);
        if (!param) return false;
        return param?.valor === "1";
    }, [getParam]);

    const redesocialObrigatorio = useMemo(() => {
        const param = getParam(PESSOAS_PERFIL_CADASTRO_REDESOCIAL_OBRIGATORIEDADE);
        if (!param) return false;
        return param?.valor === "1";
    }, [getParam]);

    const enderecoObrigatorio = useMemo(() => {
        const param = getParam(PESSOAS_PERFIL_CADASTRO_ENDERECO_OBRIGATORIEDADE);
        if (!param) return false;
        return param?.valor === "1";
    }, [getParam]);

    const handlePessoaFisica = useCallback(
        (pessoaFisica) => {
            setPessoa({
                ...pessoa,
                perfil_pf: pessoaFisica,
                descricao: pessoaFisica.obs,
                tipo_pessoa: "PF",
                nome: `${pessoaFisica.nome} ${pessoaFisica.sobrenome}`,
                identificacao: pessoaFisica.cpf,
                nome_curto: pessoaFisica.nome_curto,
            });
            setMostrarConfirm(true);
            setSubmit(true);
        },
        [pessoa]
    );

    const handlePessoaJuridica = useCallback(
        (pessoaJuridica) => {
            setPessoa({
                ...pessoa,
                perfil_pj: pessoaJuridica,
                descricao: pessoaJuridica.obs,
                tipo_pessoa: "PJ",
                nome: pessoaJuridica.razao_social,
                identificacao: pessoaJuridica.cnpj,
                nome_curto: pessoaJuridica.nome_curto,
            });
            setMostrarConfirm(true);
            setSubmit(true);
        },
        [pessoa]
    );

    const handlePessoaEstrangeira = useCallback(
        (pessoaEstrangeira) => {
            setPessoa({
                ...pessoa,
                perfil_pe: pessoaEstrangeira,
                descricao: pessoaEstrangeira.obs,
                tipo_pessoa: "PE",
                nome: `${pessoaEstrangeira.nome} ${pessoaEstrangeira.sobrenome}`,
                identificacao: pessoaEstrangeira.passaporte,
                nome_curto: pessoaEstrangeira.nome_curto,
            });
            setMostrarConfirm(true);
            setSubmit(true);
        },
        [pessoa]
    );

    const handleFotoPerfil = useCallback(
        (foto) => {
            setPessoa({
                ...pessoa,
                foto,
            });
            setMostrarConfirm(true);
        },
        [pessoa]
    );

    const handleEndereco = useCallback(
        (endereco, op) => {
            let _enderecos = [...enderecos];

            const novoEndereco = {
                ...endereco,
                usuario_alteracao: user.id,
                data_alteracao: dataToStr(new Date(), "yyyy-MM-dd HH:mm:ss"),
            };

            _enderecos = pd.criarListaProvisoriaParaPersistencia(_enderecos, novoEndereco, op);

            setEnderecos(_enderecos);
            setMostrarConfirm(true);
        },
        [enderecos, user]
    );

    const handleEnderecoPrincipal = useCallback(
        (endPrincipal) => {
            let _enderecos = [...enderecos];
            const id = endPrincipal._id || endPrincipal.id;
            enderecos.forEach((endereco) => {
                if ((endereco.id === id || endereco._id === id) && !endereco.principal) {
                    _enderecos = pd.criarListaProvisoriaParaPersistencia(
                        _enderecos,
                        { ...endereco, principal: true },
                        pd.OP_CRUD_DJANGO.editar
                    );
                } else if (endereco.principal) {
                    _enderecos = pd.criarListaProvisoriaParaPersistencia(
                        _enderecos,
                        { ...endereco, principal: false },
                        pd.OP_CRUD_DJANGO.editar
                    );
                }
            });
            setMostrarConfirm(true);
            setEnderecos(_enderecos);
        },
        [enderecos]
    );

    const handleTelefone = useCallback(
        (telefone, op) => {
            let _telefones = [...telefones];

            const novoTelefone = {
                ...telefone,
                usuario_alteracao: user.id,
                data_alteracao: dataToStr(new Date(), "yyyy-MM-dd HH:mm:ss"),
            };

            _telefones = pd.criarListaProvisoriaParaPersistencia(_telefones, novoTelefone, op);
            setMostrarConfirm(true);
            setTelefones(_telefones);
        },
        [telefones, user]
    );

    const handleTelefonePrincipal = useCallback(
        (telPrincipal) => {
            let _telefones = [...telefones];
            const id = telPrincipal._id || telPrincipal.id;
            telefones.forEach((telefone) => {
                if ((telefone.id === id || telefone._id === id) && !telefone.principal) {
                    _telefones = pd.criarListaProvisoriaParaPersistencia(
                        _telefones,
                        { ...telefone, principal: true },
                        pd.OP_CRUD_DJANGO.editar
                    );
                } else if (telefone.principal) {
                    _telefones = pd.criarListaProvisoriaParaPersistencia(
                        _telefones,
                        { ...telefone, principal: false },
                        pd.OP_CRUD_DJANGO.editar
                    );
                }
            });
            setTelefones(_telefones);
            setMostrarConfirm(true);
        },
        [telefones]
    );

    const handleEmail = useCallback(
        (email, op) => {
            let _emails = [...emails];

            const novoEmail = {
                ...email,
                usuario_alteracao: user.id,
                data_alteracao: dataToStr(new Date(), "yyyy-MM-dd HH:mm:ss"),
            };

            _emails = pd.criarListaProvisoriaParaPersistencia(_emails, novoEmail, op);

            setEmails(_emails);
            setMostrarConfirm(true);
        },
        [emails, user]
    );

    const handleEmailPrincipal = useCallback(
        (emailPrincipal) => {
            let _emails = [...emails];
            const id = emailPrincipal._id || emailPrincipal.id;
            emails.forEach((email) => {
                if ((email.id === id || email._id === id) && !email.principal) {
                    _emails = pd.criarListaProvisoriaParaPersistencia(
                        _emails,
                        { ...email, principal: true },
                        pd.OP_CRUD_DJANGO.editar
                    );
                } else if (email.principal) {
                    _emails = pd.criarListaProvisoriaParaPersistencia(
                        _emails,
                        { ...email, principal: false },
                        pd.OP_CRUD_DJANGO.editar
                    );
                }
            });

            setEmails(_emails);
            setMostrarConfirm(true);
        },
        [emails]
    );

    const handleRedeSocial = useCallback(
        (redeSocial, op) => {
            let _redesSociais = [...redesSociais];

            const novaRedeSocial = {
                ...redeSocial,
                data_alteracao: dataToStr(new Date(), "yyyy-MM-dd HH:mm:ss"),
            };

            _redesSociais = pd.criarListaProvisoriaParaPersistencia(_redesSociais, novaRedeSocial, op);

            setRedesSociais(_redesSociais);
            setMostrarConfirm(true);
        },
        [redesSociais]
    );

    const handleTag = useCallback(
        (tag, op) => {
            let _tags = [...tags];
            _tags = pd.criarListaProvisoriaParaPersistencia(_tags, tag, op);
            setTags(_tags);
            setMostrarConfirm(true);
        },
        [tags]
    );

    const handleCustomFields = useCallback((values) => {
        setCustomFields(values);
        setMostrarConfirm(true);
    }, []);

    const checarInformacao = useCallback(
        async (tipo, id) => {
            const body = {
                data_alteracao: dataToStr(new Date(), "yyyy-MM-dd HH:mm:ss"),
                usuario_alteracao: user.id,
                ativo: true,
            };

            const { status } = await axiosPatch(`/pessoas/${tipo}-perfis/${id}/`, body);

            if (status === 200) {
                if (tipo === "enderecos") {
                    const _enderecos = [...enderecos];
                    const index = _enderecos.findIndex((el) => el.id === id);

                    _enderecos[index] = {
                        ..._enderecos[index],
                        ...body,
                    };

                    setEnderecos(_enderecos);
                } else if (tipo === "telefones") {
                    const _telefones = [...telefones];
                    const index = _telefones.findIndex((el) => el.id === id);

                    _telefones[index] = {
                        ..._telefones[index],
                        ...body,
                    };

                    setTelefones(_telefones);
                } else if (tipo === "emails") {
                    const _emails = [...emails];
                    const index = _emails.findIndex((el) => el.id === id);

                    _emails[index] = {
                        ..._emails[index],
                        ...body,
                    };

                    setEmails(_emails);
                } else if (tipo === "redes-sociais") {
                    const _redesSociais = [...redesSociais];
                    const index = _redesSociais.findIndex((el) => el.id === id);

                    _redesSociais[index] = {
                        ..._redesSociais[index],
                        ...body,
                    };

                    setRedesSociais(_redesSociais);
                }
            }

            return status;
        },
        [enderecos, telefones, emails, redesSociais, user]
    );

    const handlePreencherPessoa = useCallback(async (idPessoa) => {
        setLoadingPessoa(true);
        const json = await axiosGet(
            `/pessoas/perfis?id=${idPessoa}&query={id,perfil_pf,perfil_pj,perfil_pe,nome_curto,foto,tipo_pessoa,descricao,enderecoperfil_set,telefoneperfil_set,emailperfil_set,redesocialperfil_set,vinculoperfiltag_set}`
        );
        setLoadingPessoa(false);

        if (json.status === 200) {
            const [pessoa] = json.data.results;

            const {
                id,
                tipo_pessoa,
                nome_curto,
                foto,
                perfil_pf,
                perfil_pj,
                perfil_pe,
                enderecoperfil_set,
                telefoneperfil_set: telefones,
                emailperfil_set: emails,
                redesocialperfil_set,
                vinculoperfiltag_set,
                descricao: obs,
            } = pessoa;

            let perfil = {};

            if (perfil_pf) {
                perfil = {
                    perfil_pf: { ...perfil_pf, nome_curto, obs },
                    nome: `${perfil_pf.nome} ${perfil_pf.sobrenome}`,
                    identificacao: perfil_pf.cpf,
                };
            }

            if (perfil_pj) {
                perfil = {
                    perfil_pj: { ...perfil_pj, nome_curto, obs },
                    nome: perfil_pj.razao_social,
                    identificacao: perfil_pj.cnpj,
                };
            }

            if (perfil_pe) {
                perfil = {
                    perfil_pe: { ...perfil_pe, nome_curto, obs },
                    nome: `${perfil_pe.nome} ${perfil_pe.sobrenome}`,
                    identificacao: perfil_pe.passaporte,
                };
            }

            const enderecos = enderecoperfil_set.map((end) => {
                const { cidade: localidade, ...endereco } = end;

                return {
                    ...endereco,
                    localidade,
                    pais: localidade.estado.pais,
                    estado: localidade.estado.id,
                    cidade: localidade.id,
                };
            });

            const tags = vinculoperfiltag_set.map((aux) => {
                const { id, tag } = aux;

                return { id, tag: tag };
            });

            setPessoa({
                ...perfil,
                id,
                tipo_pessoa,
                foto,
            });
            setEnderecos(enderecos);
            setTelefones(telefones);
            setEmails(emails);
            setRedesSociais(redesocialperfil_set);
            setTags(tags);

            setSubmit(true);
            setMostrarConfirm(false);
        }
    }, []);

    const handlePessoa = async () => {
        let perfil = { ...pessoa };
        const obrigatoriedades = {
            status: false,
            message: { pessoa: {} },
        };

        if (emailObrigatorio && emails.length === 0) {
            obrigatoriedades.status = true;
            obrigatoriedades.message.pessoa.email = ["Informe ao menos um email!"];
        }

        if (telefoneObrigatorio && telefones.length === 0) {
            obrigatoriedades.status = true;
            obrigatoriedades.message.pessoa.telefone = ["Informe ao menos um telefone!"];
        }

        if (redesocialObrigatorio && redesSociais.length === 0) {
            obrigatoriedades.status = true;
            obrigatoriedades.message.pessoa.redesocial = ["Informe ao menos uma rede social!"];
        }

        if (enderecoObrigatorio && enderecos.length === 0) {
            obrigatoriedades.status = true;
            obrigatoriedades.message.pessoa.redesocial = ["Informe ao menos um endereço!"];
        }

        if (enderecos.length > 0) {
            const _enderecos = enderecos.map((end) => {
                const rest = { ...end, tag_endereco: end.tag_endereco?.id };

                return rest;
            });

            const enderecoperfil_set = pd.montarObjetoParaPersistenciaDjango(_enderecos);

            perfil = {
                ...perfil,
                enderecoperfil_set,
            };
        }

        if (telefones.length > 0) {
            const _telefones = telefones.map((tel) => {
                const { tags, ...rest } = tel;

                return rest;
            });

            const telefoneperfil_set = pd.montarObjetoParaPersistenciaDjango(_telefones);

            perfil = {
                ...perfil,
                telefoneperfil_set,
            };
        }

        if (emails.length > 0) {
            const _emails = emails.map((email) => {
                const { tags, ...rest } = email;

                return rest;
            });

            const emailperfil_set = pd.montarObjetoParaPersistenciaDjango(_emails);

            perfil = {
                ...perfil,
                emailperfil_set,
            };
        }

        if (redesSociais.length > 0) {
            const _redesSociais = redesSociais.map((redeSocial) => {
                const { rede_social, ...rest } = redeSocial;

                return { ...rest, rede_social: rede_social.id };
            });

            const redesocialperfil_set = pd.montarObjetoParaPersistenciaDjango(_redesSociais);

            perfil = {
                ...perfil,
                redesocialperfil_set,
            };
        }

        if (tags.length > 0) {
            const _tags = tags.map((aux) => {
                const { tag, ...rest } = aux;

                return { ...rest, tag: tag.id };
            });

            const vinculoperfiltag_set = pd.montarObjetoParaPersistenciaDjango(_tags);

            perfil = {
                ...perfil,
                vinculoperfiltag_set,
            };
        }

        if (customFields) {
            perfil = {
                ...perfil,
                custom_fields: customFields,
            };
        }

        if (obrigatoriedades.status) {
            return { status: 400, data: obrigatoriedades.message };
        }

        if (!perfil.id) {
            const { status, data } = await axiosPost("/pessoas/perfis/", perfil);
            setSubmit(false);

            return { status, data };
        } else {
            const { status, data } = await axiosPatch(`/pessoas/perfis/${perfil.id}/`, perfil);
            setSubmit(false);

            return { status, data };
        }
    };

    return (
        <PessoaContext.Provider
            value={{
                submit,
                loadingPessoa,
                pessoa,
                enderecos,
                telefones,
                emails,
                redesSociais,
                tags,
                customFields,
                mostrarConfirm,
                handlePessoaFisica,
                handlePessoaJuridica,
                handlePessoaEstrangeira,
                handleFotoPerfil,
                handleEndereco,
                handleEnderecoPrincipal,
                handleTelefone,
                handleTelefonePrincipal,
                handleEmail,
                handleEmailPrincipal,
                handleRedeSocial,
                handleTag,
                handleCustomFields,
                handlePreencherPessoa,
                handlePessoa,
                checarInformacao,
                setSubmit,
            }}
        >
            {children}
        </PessoaContext.Provider>
    );
};

export default PessoaContext;
