import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";

import { PageBase } from "@/components/PageBase";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { Dropdown } from "@/components/Dropdown";
import { BotaoDelete } from "@/components/BotaoDelete";
import { TIPO_CENTRO_ESTOCAGEM } from "@/assets/constants/estoque";
import permissoes from "@/assets/constants/permissoes";
import useEmpresa from "@/hooks/useEmpresa";
import { CodigoFiltroTemplate, DropdownFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";

const url = "/produtos/centros-estocagem/";

export const CentroEstocagemPage = () => {
    const [estoqueSelecionado, setEstoqueSelecionado] = useState(null);
    const listagemRef = useRef(null);
    const history = useHistory();
    const { empresaSelecionadaId } = useEmpresa();

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.ESTOQUE_CADASTRO_CENTROESTOCAGEM_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    onClick={() =>
                        history.push({
                            pathname: "/estoque/cadastros/centro-estocagem/form",
                            state: rowData,
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.ESTOQUE_CADASTRO_CENTROESTOCAGEM_EXCLUIR]}
                    componente={BotaoDelete}
                    url={url}
                    objetoId={rowData.id}
                    classNames="p-mb-1"
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Confirma a exclusão do centro de estocagem <b>{rowData.nome}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O centro de estocagem não pode ser excluído."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <Dropdown
                id="estoque"
                name="estoque"
                buscar={!!empresaSelecionadaId}
                url={`/produtos/estoques-empresas/?empresa__id=${empresaSelecionadaId}`}
                style={{ width: "300px", paddingLeft: "2%" }}
                value={estoqueSelecionado}
                placeholder="Selecione um estoque"
                optionValue="id"
                optionLabel="nome"
                className="p-mr-2"
                onChange={(e) => setEstoqueSelecionado(e.value)}
            />
            <Button
                label="Novo"
                icon="pi pi-plus"
                disabled={!!!estoqueSelecionado}
                className="p-button-success p-mr-2"
                onClick={() =>
                    history.push({
                        pathname: "/estoque/cadastros/centro-estocagem/form",
                        estoqueId: estoqueSelecionado,
                    })
                }
            />
        </>
    );

    const tipoCentroEstocagemBodyTemplate = (rowData) => {
        const tipo = TIPO_CENTRO_ESTOCAGEM.find((el) => el.value === rowData.tipo);
        if (!tipo) return <span>N/A</span>;
        return <span>{tipo.label}</span>;
    };

    const TipoFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: TIPO_CENTRO_ESTOCAGEM,
                }}
                options={options}
            />
        );
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "8%" }, filter: true, filterElement: CodigoFiltroTemplate },
        { field: "nome", header: "Nome", filter: true, filterElement: TextoFiltroTemplate },
        { field: "descricao", header: "Descrição", filter: true, filterElement: TextoFiltroTemplate },
        {
            field: "tipo",
            header: "Tipo",
            style: { width: "10%" },
            action: (e) => tipoCentroEstocagemBodyTemplate(e),
            filter: true,
            filterElement: TipoFiltroTemplate,
        },
        { field: "estoque_empresa.nome", header: "Estoque" },
        { field: "estoque_empresa.endereco.logradouro", header: "Endereço" },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Listagem de centros de estocagem"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={estoqueSelecionado ? `${url}?estoque_empresa__id=${estoqueSelecionado}` : url}
                filtarPorEmpresa
                fieldFiltroEmpresa="estoque_empresa__empresa__id"
                naoBuscarSemEmpresa
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    nome: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    tipo: { value: null, matchMode: "equals" },
                }}
            />
        </PageBase>
    );
};
