import React, { useState, useRef, useEffect, useCallback } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";
import { useFormik } from "formik";
import { Toast } from "primereact/toast";
import { gerarStringAleatoria } from "@/assets/util/util";
import { InputTextarea } from "primereact/inputtextarea";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { axiosGet } from "@/services/http";
// import { ProdutoModelosForm } from "@/pages/Produto/Cadastros/Modelo/form";
// import { ProdutoUnidadeMedidaFormBase } from "@/pages/Produto/Cadastros/UnidadeMedida/formBase";
//import { PRODUTOS_SKU_GAVETA } from "@/assets/constants/parametros";
import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";
import { ModalGradeAtributos } from "./modals/modalGradeAtributos";
import { TIPO_ORIGEM_NF_CHOICE } from "@/assets/constants/constants";
import { Dropdown } from "@/components/Dropdown";
import * as pd from "@/assets/util/persistenciaDjango";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useProduto from "@/hooks/useProduto";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import { Tag } from "primereact/tag";
import { MakoErrosPersonalizados } from "@/components/MakoErrosPersonalizados";
import { ProdutoModelosForm } from "../../Cadastros/Modelo/form";
import { ProdutoUnidadeMedidaFormBase } from "../../Cadastros/UnidadeMedida/formBase";

const DetalhesForm = () => {
    const { showLoading, hideLoading } = useLoading();
    const [modelos, setModelos] = useState(null);
    const [visualizar, setVisualizar] = useState(false);
    const [exibirCadastroModelo, setExibirCadastroModelo] = useState(false);
    const [exibirCadastroUnidade, setExibirCadastroUnidade] = useState(false);
    const { handleDetalhe, dadosBasicos, handlePosicaoDescricao, descricaoPadrao } = useProduto();
    const [listUnidades, setListUnidades] = useState(null);
    const [inserirDetalhe, setInserirDetalhe] = useState(false);
    const [detalheSelecionado, setDetalheSelecionado] = useState(null);
    const [form, setForm] = useState(false);
    const [exibeDescricaoPadrao, setExibeDescricaoPadrao] = useState(false);
    const [exibirGrade, setExibirGrade] = useState(false);
    const [listaNiveis, setListaNiveis] = useState([]);
    const [listaDescricoes, setListaDescricoes] = useState([]);
    const [componenteDescricao, setComponenteDescricao] = useState(null);
    const toastRef = useRef(null);
    const listagemRef = useRef(null);
    const modalErrosRef = useRef(null);

    const { setFieldValue, setValues, resetForm, ...formik } = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: null,
            codigo: "",
            comissao_venda: null,
            caracteristicas: "",
            descricao_template: "",
            descricao_complementar: "",
            descricao_reduzida: "",
            qtd_min_venda: 1,
            peso_bruto: null,
            gaveta: null,
            tipo_controle_saldo: null,
            formacao_preco: null,
            unidade_padrao: null,
            permite_fracionamento: false,
            peso_liquido: null,
            dias_garantia: 90,
            qtd_volumes: 1,
            sku_controle_saldo: null,
            modelo: null,
            intervalo_compra_chegada: null,
            e_commerce: false,
            controle_fci: "",
            composto: false,
            origem: 0,
            observacoes: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        if (form) {
            try {
                const formSchema = Yup.object().shape({
                    codigo: Yup.string().required("O campo 'código' é obrigatório."),
                    descricao_complementar: Yup.string()
                        .max(255)
                        .required("O campo 'descrição complementar' é obrigatório."),
                    descricao_reduzida: Yup.string().max(40).required("O campo 'descrição reduzida' é obrigatório."),
                    unidade_padrao: Yup.string()
                        .required("O campo 'unidade padrão' é obrigatório.")
                        .typeError("Informe uma 'unidade' válida."),
                    qtd_volumes: Yup.number(),
                    dias_garantia: Yup.number().min(0).required("O campo 'dias de garantia' é obrigatório."),
                    qtd_min_venda: Yup.number().required("O campo 'quantidade mínima' é obrigatório."),
                    observacoes: Yup.string().max(255).nullable(),
                });

                await formSchema.validate(values, {
                    abortEarly: false,
                });

                const { status, data } = await handleDetalhe(
                    { ...values, sku_controle_saldo: values.sku_controle_saldo?.id || null },
                    values.id ? pd.OP_CRUD_DJANGO.editar : pd.OP_CRUD_DJANGO.novo
                );

                if (status === 200 || status === 201) {
                    setInserirDetalhe(false);
                    resetForm();
                    listagemRef.current?.buscarDados();
                } else {
                    const keys = Object.keys(data);
                    const values = Object.values(data);
                    modalErrosRef.current?.abrirModal(keys.map((key, index) => ({ erro: `${key}: ${values[index]}` })));
                }
            } catch (error) {
                if (error instanceof Yup.ValidationError) {
                    let errorMessages = {};
                    error.inner.forEach((err) => {
                        errorMessages[err.path] = err.message;
                    });
                    formik.setErrors(errorMessages);
                }
            }
        }
    }

    const handleStatus = async (produto) => {
        const { status } = await handleDetalhe(
            {
                id: produto.id,
                descricao_reduzida: produto.descricao_reduzida,
                item: produto.item,
                ativo: !produto.ativo,
            },
            pd.OP_CRUD_DJANGO.editar
        );
        if (status === 200) listagemRef.current?.buscarDados();
    };

    const listarModelos = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet(`/produtos/modelos?marca=${dadosBasicos.marca || "0"}&&limit=300`);
        hideLoading();

        if (resposta.status === 200) setModelos(resposta.data.results);
    }, [showLoading, hideLoading, dadosBasicos.marca]);

    const listarUnidades = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/produtos/unidades-medida?limit=300");
        hideLoading();

        if (resposta.status === 200) setListUnidades(resposta.data.results);
    }, [showLoading, hideLoading]);

    const listarNiveis = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/produtos/niveis-padrao-descricao?limit=300");
        hideLoading();

        if (resposta.status === 200) setListaNiveis(resposta.data.results);
    }, [showLoading, hideLoading]);

    const listarDescricoes = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/produtos/descricao-padrao?limit=3000");
        hideLoading();

        if (resposta.status === 200) {
            setListaDescricoes(resposta.data.results);
        }
    }, [showLoading, hideLoading]);

    useEffect(() => {
        const controller = new AbortController();
        listarModelos();
        listarUnidades();
        listarNiveis();
        listarDescricoes();
        return () => controller.abort();
    }, [listarModelos, listarUnidades, listarNiveis, listarDescricoes]);

    const esconderCadastroUnidades = () => {
        setExibirCadastroUnidade(false);
        listarUnidades();
    };

    const esconderCadastroModelos = () => {
        setExibirCadastroModelo(false);
        listarModelos();
    };

    const acoesDetalhes = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_PRODUTOS_CONSULTAR]}
                    componente={Button}
                    icon="pi pi-eye"
                    className="p-button-rounded p-button-secondary p-mr-2 p-mb-1"
                    tooltip="Visualizar"
                    onClick={() => editarDetalhe(rowData, true)}
                    tooltipOptions={{ position: "left" }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_PRODUTOS_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar"
                    onClick={() => editarDetalhe(rowData, false)}
                    tooltipOptions={{ position: "left" }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_GRADEATRIBUTOSKU_CONSULTAR]}
                    componente={Button}
                    className="p-button-rounded p-button-info p-mr-2 p-mb-1"
                    tooltip="Grade de atributos"
                    tooltipOptions={{ position: "left" }}
                    icon="pi pi-table"
                    onClick={() => {
                        setDetalheSelecionado(rowData);
                        setExibirGrade(true);
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_PRODUTOS_EDITAR]}
                    componente={Button}
                    icon="pi pi-cog"
                    className="p-button-rounded p-button-help p-mr-2 p-mb-1"
                    onClick={() => handleStatus(rowData)}
                    tooltip="Alterar status"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const editarDetalhe = (detalhe, visualizar) => {
        setValues({
            ...detalhe,
            unidade_padrao: detalhe.unidade_padrao.id,
            qtd_min_venda: Number.parseFloat(detalhe.qtd_min_venda),
            modelo: detalhe.modelo?.id || null,
            sku_controle_saldo: {
                ...detalhe.sku_controle_saldo,
                label: `${detalhe.sku_controle_saldo.codigo} - ${detalhe.sku_controle_saldo.descricao_derivada}`,
            },
        });
        setVisualizar(visualizar);
        setInserirDetalhe(true);
    };

    const esconderInserirDetalhe = () => {
        resetForm();
        setVisualizar(false);
        setInserirDetalhe(false);
    };

    const cabecalhoTabela = (
        <>
            <Button
                label="Inserir"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setInserirDetalhe(true)}
            />
        </>
    );

    const statusBodyTemplate = (status) => {
        if (status) return <Tag severity="success" value="ATIVO" />;
        return <Tag severity="danger" value="DESATIVADO" />;
    };

    /*const precoVigencia = (precos) => {
        if (precos instanceof Array === false) return "";
        if (precos.length === 0) return precos;
        if (precos.length === 1) return precos[0].vigencia.descricao;
        const _precos = precos.sort((a, b) => a.vigencia.nivel < b.vigencia.nivel);
        for (let nivel = 1; nivel < 3; nivel++) {
            const preco = _precos.find((item) => item.vigencia.nivel === nivel);
            if (preco !== undefined) return preco.vigencia.descricao;
        }
    };*/

    const colunaDetalhes = [
        { field: "codigo", header: "Código", style: { minWidth: "100px" } },
        {
            field: "descricao_complementar",
            header: "Descrição Complementar",
            style: { minWidth: "200px" },
        },
        /*{
            field: "precosku_set",
            header: "Vigência",
            style: { minWidth: "120px" },
            action: ({ precosku_set }) => precoVigencia(precosku_set),
        },*/
        {
            field: "descricao_reduzida",
            header: "Descrição Reduzida",
            style: { minWidth: "200px" },
        },
        {
            field: "ativo",
            header: "Status",
            action: (e) => statusBodyTemplate(e.ativo),
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => acoesDetalhes(e),
            style: { minWidth: "180px" },
        },
    ];

    const geraComponente = useCallback(async () => {
        showLoading();
        setComponenteDescricao(
            listaNiveis.map((nivel) => {
                return (
                    <div className="p-fluid p-formgrid p-grid" key={nivel.nivel}>
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="nivel">{`Nível ${nivel.nivel}`}</label>
                            <Dropdown
                                key={nivel.nivel}
                                id="nivel"
                                name="nivel"
                                options={listaDescricoes
                                    .filter(function (descricao) {
                                        if (descricao.nivel === nivel.id) {
                                            return descricao;
                                        }
                                    })
                                    .map((descricao) => descricao)}
                                filter
                                filterBy="descricao"
                                optionLabel="descricao"
                                optionValue="descricao"
                                placeholder="Selecione uma descrição"
                                //disabled={nivel.nivel - 0 ? false : descricaoPadrao[nivel.nivel - 2] ? false : true}
                                value={descricaoPadrao[nivel.nivel - 1] || null}
                                onChange={(e) => {
                                    handlePosicaoDescricao(nivel.nivel - 1, e.value);
                                    geraComponente();
                                }}
                            />
                        </div>
                    </div>
                );
            }) || []
        );
        hideLoading();
    }, [showLoading, hideLoading, listaNiveis, listaDescricoes, descricaoPadrao, handlePosicaoDescricao]);

    useEffect(() => {
        geraComponente();
    }, [listaNiveis, listaDescricoes, geraComponente]);

    const gerarDescricao = () => {
        let descricao = "";
        descricaoPadrao.forEach((palavra) => {
            descricao = descricao + ` ${palavra}`;
        });
        setFieldValue("descricao_complementar", descricao);
        toastRef.current.show({
            severity: "info",
            summary: "Descrição padronizada",
            detail: "Descrição padronizada gerada sucesso!",
            life: 3000,
        });
        setExibeDescricaoPadrao(false);
    };

    const autoCompleteTemplate = (item) => {
        item.label = `${item.codigo} - ${item.descricao_derivada}`;
        return `${item.codigo} - ${item.descricao_derivada}`;
    };

    return (
        <>
            <Toast ref={toastRef} />
            <MakoListagem
                ref={listagemRef}
                colunas={colunaDetalhes}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={`/produtos/sku?item__id=${dadosBasicos.id}`}
                configTabela={{
                    responsiveLayout: "scroll",
                    paginator: true,
                    lazy: true,
                }}
            />
            <Dialog
                header={"Cadastro do produto"}
                visible={inserirDetalhe}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "60vw" }}
                onHide={() => esconderInserirDetalhe()}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid ">
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="descricao_template">Descrição dinâmica</label>
                            <Dropdown
                                id="descricao_template"
                                name="descricao_template"
                                url="/produtos/templates-descricoes-skus/"
                                filter
                                filterBy="descricao"
                                optionValue="id"
                                optionLabel="descricao"
                                placeholder="Selecione um template"
                                disabled={visualizar}
                                value={formik.values.descricao_template}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="modelo">Modelo</label>
                            <div className="p-inputgroup">
                                <Dropdown
                                    id="modelo"
                                    name="modelo"
                                    options={modelos}
                                    placeholder="Selecione um modelo"
                                    onChange={formik.handleChange}
                                    value={formik.values.modelo}
                                    disabled={visualizar ? true : !dadosBasicos.marca}
                                    filter
                                    filterBy="nome"
                                    tooltip="Modelos vinculados a marca selecionada"
                                    optionValue="id"
                                    optionLabel="nome"
                                />
                                <MakoControleAcesso
                                    permissao={[permissoes.PRODUTO_CADASTRO_MODELO_INCLUIR]}
                                    componente={Button}
                                    type="button"
                                    icon="pi pi-plus"
                                    disabled={visualizar ? true : dadosBasicos.marca ? false : true}
                                    className="p-button-success"
                                    onClick={() => setExibirCadastroModelo(true)}
                                />
                            </div>
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="comissao_venda">Comissão de venda</label>
                            <Dropdown
                                id="comissao_venda"
                                name="comissao_venda"
                                url="/vendas/comissoes-vendas/"
                                optionValue="id"
                                optionLabel="descricao"
                                filter
                                filterBy="descricao"
                                placeholder="Selecione uma comissão de vendas"
                                disabled={visualizar}
                                value={formik.values.comissao_venda}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid ">
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="codigo">Código *</label>
                            <div className="p-inputgroup">
                                <InputText
                                    id="codigo"
                                    name="codigo"
                                    disabled={visualizar}
                                    onChange={formik.handleChange}
                                    value={formik.values.codigo}
                                    autoComplete="off"
                                    className={classNames({ "p-invalid": formik.errors.codigo })}
                                />
                                <Button
                                    type="button"
                                    icon="pi pi-refresh"
                                    disabled={visualizar}
                                    className="p-button-info"
                                    tooltip="Gerar código aleatório"
                                    onClick={() => setFieldValue("codigo", gerarStringAleatoria(5))}
                                />
                            </div>
                            {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-10">
                            <label htmlFor="descricao_complementar">Descrição complementar *</label>
                            <div className="p-inputgroup">
                                <InputText
                                    id="descricao_complementar"
                                    name="descricao_complementar"
                                    disabled={visualizar}
                                    onChange={formik.handleChange}
                                    value={formik.values.descricao_complementar}
                                    className={classNames({ "p-invalid": formik.errors.descricao_complementar })}
                                    autoComplete="off"
                                />
                                <Button
                                    type="button"
                                    icon="pi pi-cog"
                                    disabled={visualizar}
                                    className="p-button-help"
                                    tooltip="Gerar descrição padronizada"
                                    onClick={() => setExibeDescricaoPadrao(true)}
                                />
                            </div>
                            {formik.errors.descricao_complementar && (
                                <small className="p-error">{formik.errors.descricao_complementar}</small>
                            )}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid ">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="descricao_reduzida">Descrição reduzida *</label>
                            <InputText
                                id="descricao_reduzida"
                                name="descricao_reduzida"
                                disabled={visualizar}
                                onChange={formik.handleChange}
                                value={formik.values.descricao_reduzida}
                                className={classNames({ "p-invalid": formik.errors.descricao_reduzida })}
                                autoComplete="off"
                            />
                            {formik.errors.descricao_reduzida && (
                                <small className="p-error">{formik.errors.descricao_reduzida}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="sku_controle_saldo">Movimenta saldo de:</label>
                            <MakoAutoComplete
                                id="sku_controle_saldo"
                                name="sku_controle_saldo"
                                value={formik.values.sku_controle_saldo}
                                onChange={formik.handleChange}
                                minCaracteresBusca={4}
                                itemTemplate={autoCompleteTemplate}
                                field="label"
                                urlSearch={`/produtos/sku?ativo=true&query={id, codigo, descricao_derivada, descricao_reduzida}&search=`}
                                placeholder="Comece a digitar o código ou descrição do item para buscar... (min 4 caracteres)"
                                className={classNames({ "p-invalid": formik.errors.sku_controle_saldo })}
                            />
                            {formik.errors.sku_controle_saldo && (
                                <small className="p-error">{formik.errors.sku_controle_saldo}</small>
                            )}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid ">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="unidade_padrao">Unidade padrão *</label>
                            <div className="p-inputgroup">
                                <Dropdown
                                    id="unidade_padrao"
                                    name="unidade_padrao"
                                    placeholder="Selecione uma unidade"
                                    disabled={visualizar}
                                    onChange={formik.handleChange}
                                    value={formik.values.unidade_padrao}
                                    filter
                                    filterBy="nome"
                                    optionValue="id"
                                    optionLabel="nome"
                                    options={listUnidades}
                                    autoComplete="off"
                                    className={classNames({ "p-invalid": formik.errors.unidade_padrao })}
                                />
                                <MakoControleAcesso
                                    permissao={[permissoes.PRODUTO_PRODUTO_UNIDADEMEDIDA_INCLUIR]}
                                    componente={Button}
                                    type="button"
                                    icon="pi pi-plus"
                                    className="p-button-success"
                                    disabled={visualizar}
                                    onClick={() => setExibirCadastroUnidade(true)}
                                />
                            </div>
                            {formik.errors.unidade_padrao && (
                                <small className="p-error">{formik.errors.unidade_padrao}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="peso_bruto">Peso bruto (kg)</label>
                            <InputNumber
                                id="peso_bruto"
                                name="peso_bruto"
                                mode="decimal"
                                min={0}
                                suffix=" kg"
                                minFractionDigits={1}
                                maxFractionDigits={3}
                                disabled={visualizar}
                                value={formik.values.peso_bruto}
                                onValueChange={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="peso_liquido">Peso líquido (kg)</label>
                            <InputNumber
                                id="peso_liquido"
                                name="peso_liquido"
                                mode="decimal"
                                min={0}
                                suffix=" kg"
                                minFractionDigits={1}
                                maxFractionDigits={3}
                                disabled={visualizar}
                                value={formik.values.peso_liquido}
                                onValueChange={formik.handleChange}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid ">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="origem">Origem *</label>
                            <div className="p-inputgroup">
                                <Dropdown
                                    id="origem"
                                    name="origem"
                                    placeholder="Selecione uma origem"
                                    disabled={visualizar}
                                    onChange={formik.handleChange}
                                    value={formik.values.origem}
                                    filter
                                    filterBy="label"
                                    optionValue="id"
                                    optionLabel="label"
                                    options={TIPO_ORIGEM_NF_CHOICE}
                                />
                            </div>
                            {formik.errors.origem && <small className="p-error">{formik.errors.origem}</small>}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid ">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="caracteristicas">Características</label>
                            <InputTextarea
                                id="caracteristicas"
                                name="caracteristicas"
                                disabled={visualizar}
                                value={formik.values.caracteristicas}
                                onChange={formik.handleChange}
                                rows={5}
                                autoComplete="off"
                                autoResize
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid ">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="qtd_min_venda">Quantidade mínima *</label>
                            <InputNumber
                                id="qtd_min_venda"
                                name="qtd_min_venda"
                                min={0}
                                disabled={visualizar}
                                showButtons
                                onValueChange={formik.handleChange}
                                value={formik.values.qtd_min_venda}
                                className={classNames({ "p-invalid": formik.errors.qtd_min_venda })}
                                autoComplete="off"
                            />
                            {formik.errors.qtd_min_venda && (
                                <small className="p-error">{formik.errors.qtd_min_venda}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="qtd_volumes">Quantidade volumes *</label>
                            <InputNumber
                                id="qtd_volumes"
                                name="qtd_volumes"
                                min={0}
                                disabled={visualizar}
                                showButtons
                                onValueChange={formik.handleChange}
                                value={formik.values.qtd_volumes}
                                className={classNames({ "p-invalid": formik.errors.qtd_volumes })}
                                autoComplete="off"
                            />
                            {formik.errors.qtd_volumes && (
                                <small className="p-error">{formik.errors.qtd_volumes}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="dias_garantia">Dias Garantia *</label>
                            <InputNumber
                                id="dias_garantia"
                                name="dias_garantia"
                                disabled={visualizar}
                                showButtons
                                onValueChange={formik.handleChange}
                                value={formik.values.dias_garantia}
                                className={classNames({ "p-invalid": formik.errors.dias_garantia })}
                                autoComplete="off"
                            />
                            {formik.errors.dias_garantia && (
                                <small className="p-error">{formik.errors.dias_garantia}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="intervalo_compra_chegada">Intervalo compra/chegada</label>
                            <InputNumber
                                id="intervalo_compra_chegada"
                                name="intervalo_compra_chegada"
                                disabled={visualizar}
                                showButtons
                                onValueChange={formik.handleChange}
                                value={formik.values.intervalo_compra_chegada}
                                className={classNames({ "p-invalid": formik.errors.intervalo_compra_chegada })}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid ">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="gaveta">{"Gaveta"}</label>
                            <InputText
                                id="gaveta"
                                name="gaveta"
                                disabled={visualizar}
                                onChange={formik.handleChange}
                                value={formik.values.gaveta}
                                className={classNames({ "p-invalid": formik.errors.gaveta })}
                                autoComplete="off"
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="controle_fci">Controle FCI</label>
                            <InputText
                                id="controle_fci"
                                name="controle_fci"
                                disabled={visualizar}
                                onChange={formik.handleChange}
                                value={formik.values.controle_fci}
                                className={classNames({ "p-invalid": formik.errors.controle_fci })}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid ">
                        <div className="p-field p-col-12 p-md-5">
                            <label htmlFor="formacao_preco">Formação de preço</label>
                            <Dropdown
                                id="formacao_preco"
                                name="formacao_preco"
                                url="/produtos/formacoes-precos/"
                                optionValue="id"
                                optionLabel="descricao"
                                filter
                                filterBy="descricao"
                                placeholder="Selecione uma formação"
                                disabled={visualizar}
                                value={formik.values.formacao_preco}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="p-field-checkbox p-col-12 p-md-3">
                            <br></br>
                            <Checkbox
                                inputId="permite_fracionamento"
                                id="permite_fracionamento"
                                name="permite_fracionamento"
                                onChange={formik.handleChange}
                                checked={formik.values.permite_fracionamento}
                                value={formik.values.permite_fracionamento}
                                className="p-mt-5"
                                disabled={visualizar}
                            />
                            <label htmlFor="permite_fracionamento" className="p-mt-5">
                                <b>Permite fracionamento</b>
                            </label>
                        </div>
                        <div className="p-field-checkbox p-col-12 p-md-2">
                            <br></br>
                            <Checkbox
                                inputId="e_commerce"
                                id="e_commerce"
                                name="e_commerce"
                                onChange={formik.handleChange}
                                checked={formik.values.e_commerce}
                                value={formik.values.e_commerce}
                                className="p-mt-5"
                                disabled={visualizar}
                            />
                            <label htmlFor="e_commerce" className="p-mt-5">
                                <b>E-commerce</b>
                            </label>
                        </div>
                        <div className="p-field-checkbox p-col-12 p-md-2">
                            <br></br>
                            <Checkbox
                                inputId="composto"
                                id="composto"
                                name="composto"
                                onChange={formik.handleChange}
                                checked={formik.values.composto}
                                value={formik.values.composto}
                                className="p-mt-5"
                                disabled={visualizar}
                            />
                            <label htmlFor="composto" className="p-mt-5">
                                <b>Composto</b>
                            </label>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid ">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="observacoes">Observações</label>
                            <InputTextarea
                                id="observacoes"
                                name="observacoes"
                                disabled={visualizar}
                                value={formik.values.observacoes}
                                onChange={formik.handleChange}
                                rows={3}
                                autoComplete="off"
                                autoResize
                            />
                        </div>
                    </div>
                    <p>
                        <b>* Campos obrigatórios.</b>
                    </p>
                    <div className="p-grid p-justify-end p-col-12">
                        <Button
                            className="p-button-danger p-button-text"
                            type="reset"
                            icon="pi pi-times"
                            label="Fechar"
                            onClick={() => esconderInserirDetalhe()}
                        />
                        {visualizar ? null : (
                            <Button
                                type="submit"
                                icon="pi pi-check-circle"
                                onClick={() => setForm(true)}
                                label={formik.values.id ? "Salvar" : "Inserir"}
                            />
                        )}
                    </div>
                </form>
            </Dialog>
            <Dialog
                header={"Selecione a formação para a construção da descrição:"}
                visible={exibeDescricaoPadrao}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "35w", display: "block" }}
                onHide={() => setExibeDescricaoPadrao(false)}
            >
                {componenteDescricao?.length > 0 ? componenteDescricao : <h6>Não possui níveis cadastrados.</h6>}
                <Button
                    type="button"
                    icon="pi pi-check-square"
                    label="Gerar"
                    disabled={descricaoPadrao.length > 0 ? false : true}
                    onClick={() => gerarDescricao()}
                />
            </Dialog>
            <ModalGradeAtributos
                visible={exibirGrade}
                setVisible={setExibirGrade}
                detalheSelecionado={detalheSelecionado}
                toastRef={toastRef}
            />
            <MakoErrosPersonalizados
                ref={modalErrosRef}
                titulo="Inconsistências encontradas"
                colunas={[{ field: "erro", header: "Descrição da pendência" }]}
            />
            <Dialog
                header={"Cadastrar modelo"}
                visible={exibirCadastroModelo}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "30vw", display: "block" }}
                onHide={() => setExibirCadastroModelo(false)}
            >
                <ProdutoModelosForm modelo={{ marca: dadosBasicos.marca }} dialog={esconderCadastroModelos} />
            </Dialog>
            <Dialog
                header={"Cadastrar unidade de medida"}
                visible={exibirCadastroUnidade}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "40vw", display: "block" }}
                onHide={() => setExibirCadastroUnidade(false)}
            >
                <ProdutoUnidadeMedidaFormBase unidade aposSalvar={() => esconderCadastroUnidades()} />
            </Dialog>
        </>
    );
};

export default DetalhesForm;
