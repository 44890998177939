export const baseChart = {
    labels: [],
    datasets: [],
    indexDefault: 0,
    tabs: [],
    src_graph: null,
    itemsMenu: [],
};

export const initialIndicadoresReducer = {
    loading: false,
    tarefas: [],
    pedidos: baseChart,
};

export const ACTIONS_DASHBOARD = {
    SHOW_LOADING: "SHOWLOADING",
    HIDE_LOADING: "HIDELOADING",
    UPDATE_TAREFAS: "UPDATETAREFAS",
    UPDATE_PEDIDOS: "UPDATEPEDIDOS",
};

export const dashboardReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS_DASHBOARD.SHOW_LOADING:
            return { ...state, loading: true };
        case ACTIONS_DASHBOARD.HIDE_LOADING:
            return { ...state, loading: false };
        case ACTIONS_DASHBOARD.UPDATE_TAREFAS:
            return { ...state, tarefas: action.payload.value };
        case ACTIONS_DASHBOARD.UPDATE_PEDIDOS:
            return { ...state, pedidos: action.payload.value };
        default:
            return state;
    }
};
