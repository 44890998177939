import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";

import { ModalAlterarEstagio } from "./ModalAlterarEstagio";
import { PageBase } from "@/components/PageBase";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoCalendar } from "@/components/MakoCalendar";
import permissoes from "@/assets/constants/permissoes";
import * as chavesRelatorios from "@/assets/constants/relatorios";
import {
    VENDA_TIPOMOVIMENTACAO_PADRAO,
    VENDA_COMPETENCIA_FINANCEIRA_CORRENTE,
    VENDA_TEMPLATERATEIO_PADRAO,
    // VENDA_COMISSAO_PADRAO,
    // VENDA_GRUPO_CATEGORIA_COMISSAO,
} from "@/assets/constants/parametros";
import { axiosPut } from "@/services/http";
import { dataToStr } from "@/assets/util/datas";
import useToast from "@/hooks/useToast";
import useRelatorio from "@/hooks/useRelatorio";
import useLoading from "@/hooks/useLoading";
import useFormatCnpjCpf from "@/hooks/useFomatCNPJCPF";
import useParam from "@/hooks/useParam";
import useAuth from "@/hooks/useAuth";
import { FiltroAvancadoVendas } from "./filtroAvancado";
import useClearRefs from "@/hooks/useClearRefs";
import { key_filtros } from "@/assets/constants/filtros";
import { useLocalFiltro } from "@/hooks/useLocalFiltro";

const BASE_URL =
    "/vendas/vendas/?query={id,data,empresa,cliente,valor_total_ap,estagio_venda,situacao,sequencia_estagio_venda}";

export const VendasPage = () => {
    const [filtros, setFiltro, removerFiltro] = useLocalFiltro(key_filtros.VENDAS_VENDA_AVANCADO);
    const [desabilitarVenda, setDesabilitarVenda] = useState(false);
    const [url, setUrl] = useState(`${BASE_URL}&situacao__in=P,F`);
    const [totalizadorFiltros, setTotalizadorFiltros] = useState(0);

    const { showSuccess, showWarning, showError, showCustom } = useToast();
    const { showLoading, hideLoading } = useLoading();
    const { solicitarRelatorio } = useRelatorio();
    const [formatDocumento] = useFormatCnpjCpf();
    const { verifyPermission } = useAuth();
    const { getParam } = useParam();

    const modalAlterarEstagioRef = useRef(null);
    const modalFiltroAvancado = useRef(null);
    const menuAcoesVendaRef = useRef(null);
    const vendaSelecionada = useRef(null);
    const listagemRef = useRef(null);
    const history = useHistory(null);

    useClearRefs(modalAlterarEstagioRef, modalFiltroAvancado, menuAcoesVendaRef, vendaSelecionada);

    const validarNovasVendas = useCallback(async () => {
        // VENDA_COMISSAO_PADRAO, VENDA_GRUPO_CATEGORIA_COMISSAO
        let messages = [];
        [VENDA_COMPETENCIA_FINANCEIRA_CORRENTE, VENDA_TEMPLATERATEIO_PADRAO, VENDA_TIPOMOVIMENTACAO_PADRAO].forEach(
            (chave) => {
                if (!getParam(chave)) {
                    messages.push({
                        severity: "warn",
                        summary: "Aviso!",
                        detail: `Antes de realizar a venda é preciso definir o parâmetro: ${chave}`,
                        life: 10000,
                    });
                }
            }
        );
        if (messages.length > 0) {
            setDesabilitarVenda(true);
            showCustom(messages);
        }
    }, [getParam, showCustom]);

    useEffect(() => {
        validarNovasVendas();
    }, [validarNovasVendas]);

    const painelEsquerdoTabela = (
        <>
            <Button
                label="Nova"
                icon="pi pi-plus"
                disabled={desabilitarVenda}
                className="p-button-success p-mr-2"
                onClick={() => history.push("/vendas/venda/form")}
            />
            <Button
                label="Filtro Avançado"
                icon="pi pi-filter"
                className="p-button-help p-mr-2"
                badge={totalizadorFiltros > 0 ? totalizadorFiltros : null}
                onClick={() => modalFiltroAvancado.current?.abrirModal()}
            />
        </>
    );

    const orcamentoFiltroTemplate = (options) => {
        return <InputNumber value={options.value} onValueChange={(e) => options.filterCallback(e.value)} />;
    };

    const dataFiltroTemplate = (options) => {
        return (
            <MakoCalendar
                valueCalendar={options.value}
                onChange={(e) => options.filterCallback(dataToStr(e.value, "yyyy-MM-dd"))}
            />
        );
    };

    const empresaBodyTemplate = (rowData) => {
        const { empresa } = rowData;
        if (empresa?.nome_curto?.trim().length > 0) return <span>{empresa.nome_curto}</span>;
        return <span>{formatDocumento(empresa.identificacao)}</span>;
    };

    const clienteBodyTemplate = (cliente) => {
        return <span>{`${cliente.nome} - ${formatDocumento(cliente.identificacao)}`}</span>;
    };

    const itensMenuAcoes = [
        {
            label: "Opções",
            items: [
                {
                    label: "Finalizar venda",
                    disabled: !verifyPermission([permissoes.VENDAS_VENDA_REALIZARVENDA_FINALIZAR]),
                    command: () => finalizarVenda(vendaSelecionada.current?.id),
                },
                {
                    label: "Cancelar venda",
                    disabled: !verifyPermission([permissoes.VENDAS_VENDA_REALIZARVENDA_CANCELAR]),
                    command: () => cancelarVenda(vendaSelecionada.current?.id),
                },
                {
                    label: "Alterar fluxo de venda",
                    command: () => {
                        const seqEstagioVenda = vendaSelecionada.current?.sequencia_estagio_venda;
                        modalAlterarEstagioRef.current.abrirModal(
                            seqEstagioVenda?.estagio_venda,
                            seqEstagioVenda?.sequencia,
                            vendaSelecionada.current?.id
                        );
                    },
                },
            ],
        },
        {
            label: "Emissão de documentos.",
            items: [
                {
                    label: "Contrato",
                    command: () =>
                        solicitarRelatorio({
                            chave: chavesRelatorios.RELATORIO_VENDAS_CONTRATOVENDA,
                            filtros: { orcamento: vendaSelecionada.current?.id },
                        }),
                },
                {
                    label: "Recibo",
                    command: () =>
                        solicitarRelatorio({
                            chave: chavesRelatorios.RELATORIO_VENDAS_RECIBO_VENDAS,
                            filtros: { id: vendaSelecionada.current?.id },
                        }),
                },
                {
                    label: "Orçamento",
                    command: () =>
                        solicitarRelatorio({
                            chave: chavesRelatorios.RELATORIO_VENDAS_ORCAMENTO,
                            filtros: { id: vendaSelecionada.current?.id },
                        }),
                },
                {
                    label: "Controle entrega",
                    command: () =>
                        solicitarRelatorio({
                            chave: chavesRelatorios.RELATORIO_VENDAS_TABELACONTROLEENTREGA,
                            filtros: { vendas: [vendaSelecionada.current?.id] },
                        }),
                },
                {
                    label: "Carnê",
                    disabled: true,
                    command: () => {},
                },
                {
                    label: "Emitir orçamento",
                    disabled: true,
                    command: () => {},
                },
            ],
        },
    ];

    const cancelarVenda = async (vendaId) => {
        showLoading();
        const resposta = await axiosPut(`/vendas/cancelar-venda/${vendaId}/`);
        hideLoading();
        if (resposta.status === 200) {
            showSuccess({
                summary: "Sucesso!",
                detail: "Venda cancelada com sucesso!",
                life: 1500,
            });
            setTimeout(() => {
                listagemRef.current?.buscarDados();
            }, 2000);
        } else if (resposta.status === 400) {
            showWarning({
                summary: "Aviso!",
                detail: `Não foi possível cancelar a venda: ${resposta.data.msg}`,
                life: 10000,
            });
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos processar a requisição.",
                life: 3000,
            });
        }
    };

    const finalizarVenda = async (vendaId) => {
        showLoading();
        const resposta = await axiosPut(`/vendas/finalizar-venda/${vendaId}/`);
        hideLoading();
        if (resposta.status === 200) {
            showSuccess({
                summary: "Sucesso!",
                detail: "Venda finalizada com sucesso!",
                life: 1500,
            });
            setTimeout(() => {
                listagemRef.current?.buscarDados();
            }, 2000);
        } else if (resposta.status === 400) {
            showWarning({
                summary: "Aviso!",
                detail: `Não foi possível finalizar a venda: ${resposta.data.msg}`,
                life: 10000,
            });
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos processar a requisição.",
                life: 3000,
            });
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_REALIZARVENDA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    onClick={() =>
                        history.push({
                            pathname: "/vendas/venda/form",
                            state: {
                                idVenda: rowData.id,
                            },
                        })
                    }
                />
                <Menu model={itensMenuAcoes} popup ref={menuAcoesVendaRef} id="popup_menu_acoes" />
                <Button
                    icon="pi pi-cog"
                    aria-haspopup
                    aria-controls="popup_menu_acoes"
                    className="p-button-rounded p-button-info p-mr-2 p-mb-1"
                    onClick={(e) => {
                        vendaSelecionada.current = rowData;
                        menuAcoesVendaRef.current?.toggle(e);
                    }}
                />
            </div>
        );
    };

    const colunas = [
        {
            field: "id",
            header: "Orçamento",
            style: { width: "8%" },
            filter: true,
            filterElement: orcamentoFiltroTemplate,
        },
        {
            field: "empresa.nome",
            header: "Loja",
            action: (e) => empresaBodyTemplate(e),
        },
        {
            field: "cliente",
            header: "Cliente",
            filter: true,
            action: (e) => clienteBodyTemplate(e.cliente),
        },
        {
            field: "data",
            header: "Data",
            style: { width: "10%" },
            filter: true,
            dateFormat: "dd/MM/yyyy",
            filterElement: dataFiltroTemplate,
        },
        {
            field: "valor_total_ap",
            header: "Valor",
            money: true,
            style: { width: "10%" },
        },
        {
            field: "estagio_venda.descricao",
            header: "Estágio de venda",
            style: { width: "15%" },
        },

        { field: "action", header: "Ações", style: { width: "10%" }, action: (e) => actionBodyTemplate(e) },
    ];

    const rowClass = (rowData) => {
        return {
            "table-recebimentos-overdue": rowData.situacao === "C",
            "table-recebimentos-effective": rowData.situacao === "F",
        };
    };

    const onConfirmFiltro = (newUrl, contador) => {
        setUrl(newUrl);
        setTotalizadorFiltros(contador);
    };

    const removerFiltros = () => {
        removerFiltro();
        setTotalizadorFiltros(0);
    };

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Vendas"
                colunas={colunas}
                painelEsquerdo={painelEsquerdoTabela}
                urlPesquisa={url}
                filtarPorEmpresa
                naoBuscarSemEmpresa
                keyFiltrosStorage={key_filtros.VENDAS_VENDA_TABELA}
                filtros={{
                    id: { value: "", matchMode: "equals" },
                    cliente: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "equals" }],
                    },
                    data: { value: "", matchMode: "equals" },
                    situacao: { value: "", matchMode: "equals" },
                }}
                configTabela={{
                    paginator: true,
                    lazy: true,
                    rowClassName: rowClass,
                }}
            />
            <ModalAlterarEstagio ref={modalAlterarEstagioRef} onSuccess={() => listagemRef.current?.buscarDados()} />
            <FiltroAvancadoVendas
                ref={modalFiltroAvancado}
                onConfirm={onConfirmFiltro}
                url={BASE_URL}
                filtros={filtros}
                setFiltro={setFiltro}
                removerFiltro={removerFiltros}
            />
        </PageBase>
    );
};
