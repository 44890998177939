import React, { useCallback, useEffect, useState } from "react";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { Dropdown } from "@/components/Dropdown";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import classNames from "classnames";
import { axiosGet } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import { InputText } from "primereact/inputtext";
import { MakoInputQuantidadeSku } from "@/components/MakoInputs/MakoInputQuantidadeSku";
import * as Yup from "yup";
import { formatarCasasDecimais, gerarId } from "@/assets/util/util";
import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";

export const ItemOrcamentoModalForm = (props) => {
    const [unidadesVenda, setUnidadesVenda] = useState([]);
    const [listaTabelas, setListaTabelas] = useState([]);

    const { showLoading, hideLoading } = useLoading();

    const { setValues, setFieldValue, resetForm, ...formik } = useFormik({
        initialValues: {
            sku: null,
            sku_movimenta_estoque: null,
            quantidade: 0,
            valor_desconto_unitario: 0,
            valor_total: 0,
            valor_unitario: 0,
            observacoes: "",
            altura: null,
            comprimento: null,
            cubagem: 1,
            largura: null,
            status: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                sku: Yup.object()
                    .typeError("Você precisa buscar e selecionar um produto.")
                    .required("Você precisa buscar e selecionar um produto."),
                quantidade: Yup.number()
                    .when("sku", {
                        is: (val) => val && val.permite_fracionamento === true,
                        then: Yup.number().min(0.001, "O campo 'quantidade' não pode ser ZERO."),
                        otherwise: Yup.number().min(1, "O campo 'quantidade' não pode ser ZERO."),
                    })
                    .required("O campo 'quantidade' não pode ser ZERO."),
                valor_unitario: Yup.number().required("O campo 'valor unitário' é obrigatório."),
                valor_desconto_unitario: Yup.number().required("O campo 'descontos' é obrigatório."),
                unidade_venda: Yup.object().required("O campo 'unidade de medida' é obrigatório."),
                tabela_preco: Yup.object().required("O campo 'tabela de preço' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const { valor_total, altura, comprimento, largura, ...rest } = values;
            const item = {
                ...rest,
                desconto: rest.quantidade * rest.valor_desconto_unitario,
                valor_total: valor_total,
                valor_total_av: valor_total,
                valor_total_ap: valor_total,
                altura: altura || 1,
                comprimento: comprimento || 1,
                largura: largura || 1,
            };

            const __STATUS = values.id || values?.status ? "editar" : "novo";

            props.setItensOrcamento([
                ...props.itensOrcamento,
                { ...item, id: item.id ? item.id : gerarId(), status: __STATUS },
            ]);
            props.listagemRef.current?.buscarDados();
            resetForm();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const listarUnidades = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/produtos/unidades-medida?limit=10000");
        hideLoading();

        if (resposta.status === 200) setUnidadesVenda(resposta.data.results);
    }, [showLoading, hideLoading]);

    const listarTabelasPreco = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/produtos/tabelas-preco?limit=10000");
        hideLoading();

        if (resposta.status === 200) setListaTabelas(resposta.data.results);
    }, [showLoading, hideLoading]);

    const calcularValorTotalItem = useCallback(() => {
        if (formik.values.quantidade > 0 && formik.values.valor_unitario > 0) {
            const valorItem = formik.values.valor_unitario - formik.values.valor_desconto_unitario;
            const valorTotal = formik.values.cubagem * formik.values.quantidade * valorItem;

            setFieldValue("valor_total", formatarCasasDecimais(valorTotal));
        }
    }, [
        formik.values.cubagem,
        formik.values.quantidade,
        formik.values.valor_desconto_unitario,
        formik.values.valor_unitario,
        setFieldValue,
    ]);

    const autoCompleteTemplate = (item) => {
        item.label = `${item.codigo} - ${item.descricao_reduzida}`;
        return `${item.codigo} - ${item.descricao_reduzida}`;
    };

    const onChangeTabela = (tabela) => {
        setFieldValue("tabela_preco", tabela);
        let existe = false;
        tabela.precostabelapreco_set.forEach((preco) => {
            if (preco.sku.id === formik.values.sku.id) {
                calcularValorTotalItem();
                setFieldValue("valor_unitario", parseFloat(preco.preco_calculado));
                existe = true;
            }
        });
        if (!existe)
            props.toastRef.current.show({
                severity: "warn",
                summary: "Aviso",
                detail: "Não encontrado! O item informado não está presente nesta tabela de preço.",
                life: 3000,
            });
    };

    useEffect(() => {
        calcularValorTotalItem();
    }, [calcularValorTotalItem]);

    useEffect(() => {
        listarUnidades();
        listarTabelasPreco();
    }, [listarUnidades, listarTabelasPreco]);

    const onChangeDimensao = useCallback(
        (value) => {
            setFieldValue("cubagem", value);
        },
        [setFieldValue]
    );

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="sku">Item *</label>
                            <MakoBuscaSkuPersonalizada
                                id="sku"
                                name="sku"
                                skuValue={formik.values.sku}
                                exibeDimensoes
                                skuChange={(e) => {
                                    setFieldValue("sku", e);
                                    setFieldValue("sku_movimenta_estoque", {
                                        ...e.sku_controle_saldo,
                                        label:
                                            typeof e.sku_controle_saldo === "object"
                                                ? `${e.sku_controle_saldo.codigo} - ${e.sku_controle_saldo.descricao_complementar}`
                                                : null,
                                    });
                                }}
                                skuError={formik.errors.sku}
                                alturaValue={formik.values.altura}
                                onChangeAltura={(e) => setFieldValue("altura", e)}
                                larguraValue={formik.values.largura}
                                onChangeLargura={(e) => setFieldValue("largura", e)}
                                comprimentoValue={formik.values.comprimento}
                                onChangeComprimento={(e) => setFieldValue("comprimento", e)}
                                dimensaoValue={formik.values.cubagem}
                                onChangeDimensao={onChangeDimensao}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="unidade_venda">Unidade de medida *</label>
                            <Dropdown
                                id="unidade_venda"
                                name="unidade_venda"
                                options={unidadesVenda}
                                optionLabel="nome"
                                emptyMessage="Nenhuma unidade disponível."
                                value={formik.values.unidade_venda}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.unidade_venda })}
                            />
                            {formik.errors.unidade_venda && (
                                <small className="p-error">{formik.errors.unidade_venda}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="quantidade">Quantidade *</label>
                            <MakoInputQuantidadeSku
                                id="quantidade"
                                name="quantidade"
                                permiteFracionario={!!formik.values.sku?.permite_fracionamento}
                                value={formik.values.quantidade}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.quantidade })}
                            />
                            {formik.errors.quantidade && <small className="p-error">{formik.errors.quantidade}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="sku_movimenta_estoque">Item que movimenta estoque *</label>
                            <MakoAutoComplete
                                id="sku_movimenta_estoque"
                                name="sku_movimenta_estoque"
                                value={formik.values.sku_movimenta_estoque}
                                onChange={formik.handleChange}
                                minCaracteresBusca={4}
                                itemTemplate={autoCompleteTemplate}
                                field="label"
                                disabled
                                urlSearch={`/produtos/sku?search=`}
                                placeholder="Comece a digitar o código ou descrição do item para buscar... (min 4 caracteres)"
                                className={classNames({ "p-invalid": formik.errors.sku_movimenta_estoque })}
                            />
                            {formik.errors.sku_movimenta_estoque && (
                                <small className="p-error">{formik.errors.sku_movimenta_estoque}</small>
                            )}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-5">
                            <label htmlFor="tabela_preco">Tabela de preço *</label>
                            <Dropdown
                                id="tabela_preco"
                                name="tabela_preco"
                                options={listaTabelas}
                                optionLabel="nome"
                                placeholder="Selecione uma tabela de preço"
                                emptyMessage="Nenhuma unidade disponível."
                                disabled={!formik.values.sku}
                                value={formik.values.tabela_preco}
                                onChange={(e) => onChangeTabela(e.value)}
                                className={classNames({ "p-invalid": formik.errors.tabela_preco })}
                            />
                            {formik.errors.tabela_preco && (
                                <small className="p-error">{formik.errors.tabela_preco}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="valor_unitario">Valor unitário *</label>
                            <MakoInputMoeda
                                id="valor_unitario"
                                name="valor_unitario"
                                valueMoeda={formik.values.valor_unitario}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.valor_unitario })}
                            />
                            {formik.errors.valor_unitario && (
                                <small className="p-error">{formik.errors.valor_unitario}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="descontos">Desconto unitário *</label>
                            <MakoInputMoeda
                                id="descontos"
                                name="valor_desconto_unitario"
                                min={0}
                                max={formik.values.valor_unitario}
                                valueMoeda={formik.values.valor_desconto_unitario}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.valor_desconto_unitario })}
                            />
                            {formik.errors.valor_desconto_unitario && (
                                <small className="p-error">{formik.errors.valor_desconto_unitario}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="valor_total">Total do item</label>
                            <MakoInputMoeda
                                id="valor_total"
                                name="valor_total"
                                disabled
                                value={formik.values.valor_total}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="obs">Observações</label>
                            <InputText
                                id="obs"
                                name="observacoes"
                                value={formik.values.observacoes}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                    <div className="p-grid p-col-12 p-md-12 p-justify-end p-mt-1">
                        <Button icon="pi pi-plus" label="Inserir" type="submit" className="p-button-success p-mr-2" />
                        <Button
                            icon="pi pi-times"
                            label="Fechar"
                            type="reset"
                            className="p-button-danger"
                            onClick={() => {
                                props.exibirDialog(false);
                                resetForm();
                            }}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};
