import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import * as Yup from "yup";
import { axiosPost, axiosPut, axiosGet } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import { useHistory } from "react-router-dom";

const url = "/produtos/modelos/";
const urlvoltar = "/produto/cadastros/modelos";

export const ProdutoModelosForm = (props) => {
    const history = useHistory();
    const [marcas, setMarcas] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const paramVazio = {
        id: "",
        nome: "",
        marca: "",
    };

    const { setFieldValue, ...formik } = useFormik({
        initialValues: props.location && props.location.state ? props.location.state.modelos : paramVazio,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo 'nome' é obrigatório."),
                marca: Yup.number().required("O campo 'marca' é obrigatório.").typeError("Informe uma 'marca' válida."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(`${url}`, values);
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Modelo cadastrado com sucesso!",
                        life: 1500,
                    });

                    if (!props.modelo)
                        setTimeout(() => {
                            formik.resetForm();
                            history.push(urlvoltar);
                        }, 2000);
                    else props.dialog();
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(`${url}${values.id}/`, values);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Modelo alterado com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    const listarMarcas = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/produtos/marcas/");
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) setMarcas(resposta.data.results);
    }, [showLoading, hideLoading]);

    useEffect(() => {
        if (formik.values.marca?.id) setFieldValue("marca", formik.values.marca.id);
        if (props.modelo?.marca) setFieldValue("marca", props.modelo.marca);
        listarMarcas();
    }, [listarMarcas, setFieldValue, props.modelo?.marca, formik.values.marca?.id]);

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    {props.modelo ? null : <h3>{!formik.values.id ? "Novo modelo" : "Manutenção de modelo"}</h3>}

                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className={props.modelo ? "p-field p-col-12 p-md-12" : "p-field p-col-12 p-md-2"}>
                                <label htmlFor="marca">Marca * </label>
                                <Dropdown
                                    id="marca"
                                    name="marca"
                                    placeholder="Selecione uma marca"
                                    filter
                                    filterBy="nome"
                                    options={marcas}
                                    optionValue="id"
                                    optionLabel="nome"
                                    disabled={props.modelo}
                                    value={formik.values.marca}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.marca })}
                                />
                                {formik.errors.marca && <small className="p-error">{formik.errors.marca}</small>}
                            </div>
                            <div className={props.modelo ? "p-field p-col-12 p-md-12" : "p-field p-col-12 p-md-4"}>
                                <label htmlFor="nome">Nome *</label>
                                <InputText
                                    id="nome"
                                    name="nome"
                                    value={formik.values.nome}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.nome })}
                                    autoComplete="off"
                                    autoFocus
                                />
                                {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                            </div>
                        </div>
                        <p>
                            <b>* Campos obrigatórios.</b>
                        </p>

                        <div className={`p-grid p-col-12 ${props.modelo ? "p-md-12" : "p-md-6"}`}>
                            <Button
                                label="Gravar"
                                type="submit"
                                className={`p-button-info p-mr-2 ${props.modelo ? "" : "p-mb-6"}`}
                            />
                            {props.modelo ? (
                                <Button
                                    label="Fechar"
                                    type="reset"
                                    className="p-button-danger p-mr-2"
                                    onClick={() => props.dialog()}
                                />
                            ) : (
                                <Button
                                    label="Cancelar"
                                    type="reset"
                                    className="p-button-danger p-mr-2 p-mb-6"
                                    onClick={cancelar}
                                />
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
