import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";

import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import { BotaoDelete } from "@/components/BotaoDelete";
import useClearRefs from "@/hooks/useClearRefs";
import useToast from "@/hooks/useToast";
import {
    CodigoFiltroTemplate,
    DateFiltroTemplate,
    DropdownFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";

export const VigenciaPrecoPage = () => {
    const history = useHistory();
    const listagemRef = useRef(null);
    const { showSuccess } = useToast();
    useClearRefs(listagemRef);

    const painelEsquerdoTabela = (
        <>
            <Button
                label="Nova"
                icon="pi pi-plus"
                className="p-button-success p-mr-2 p-mb-2"
                onClick={() => history.push("/vendas/cadastros/vigencia-preco/form")}
            />
        </>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_CADASTRO_VIGENCIAPRECO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    tooltip="Alterar cadastro."
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/vendas/cadastros/vigencia-preco/form",
                            state: rowData,
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_CADASTRO_VIGENCIAPRECO_EXCLUIR]}
                    componente={BotaoDelete}
                    url={"/produtos/precos-vigencia/"}
                    objetoId={rowData.id}
                    classNames="p-mb-1"
                    exigeConfirmacao
                    msgConfirmacao={<span>Deseja realmente excluir a vigência de preço?</span>}
                    msgToastErroExclusao="A vigência não pode ser excluída."
                    onDelete={() => {
                        listagemRef.current?.buscarDados();
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Vigência excluída com sucesso!",
                            life: 1500,
                        });
                    }}
                />
            </div>
        );
    };

    const NivelFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: [
                        { value: 1, label: "1" },
                        { value: 2, label: "2" },
                        { value: 3, label: "3" },
                    ],
                }}
                options={options}
            />
        );
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "10%" }, filter: true, filterElement: CodigoFiltroTemplate },
        { field: "descricao", header: "Descrição", filter: true, filterElement: TextoFiltroTemplate },
        { field: "nivel", header: "Nível", style: { width: "10%" }, filter: true, filterElement: NivelFiltroTemplate },
        {
            field: "data_inicio",
            header: "Início da vigência",
            dateFormat: "dd/MM/yyyy",
            style: { width: "12%" },
            filter: true,
            filterElement: DateFiltroTemplate,
        },
        {
            field: "data_fim",
            header: "Final da vigência",
            dateFormat: "dd/MM/yyyy",
            style: { width: "12%" },
            filter: true,
            filterElement: DateFiltroTemplate,
        },
        { field: "action", header: "Ações", style: { width: "8%" }, action: (e) => actionBodyTemplate(e) },
    ];

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Cadastro de Vigência de Preços"
                        colunas={colunas}
                        painelEsquerdo={painelEsquerdoTabela}
                        botaoExportar
                        urlPesquisa="/produtos/precos-vigencia/"
                        configTabela={{
                            paginator: true,
                            lazy: true,
                        }}
                        filtros={{
                            id: { value: null, matchMode: "equals" },
                            descricao: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                            },
                            nivel: { value: null, matchMode: "equals" },
                            data_inicio: { value: null, matchMode: "equals" },
                            data_fim: { value: null, matchMode: "equals" }
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
