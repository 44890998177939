import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Dialog } from "primereact/dialog";

import { FinanceiroChavePixFormBase } from "../formBase";

const ChavePixComponente = ({ aposSalvar }, ref) => {
    const [visible, setVisible] = useState(false);

    useImperativeHandle(ref, () => {
        return {
            abrirModal: () => setVisible(true),
        };
    });

    return (
        <Dialog visible={visible} header="Nova chave Pix" style={{ width: "65vw" }} onHide={() => setVisible(false)}>
            <FinanceiroChavePixFormBase modal aposSalvar={aposSalvar} />
        </Dialog>
    );
};

export const ModalChavePix = forwardRef(ChavePixComponente);
