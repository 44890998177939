import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";

import MakoListagem from "@/components/MakoListagem";
import { PageBase } from "@/components/PageBase";
import { BotaoDelete } from "@/components/BotaoDelete";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import { GRUPO_CHAVE_PARAMETRO } from "@/assets/constants/constants";

export const ParametrosPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.OUTROS_PARAMETROS_MANUTENCAO, permissoes.OUTROS_PARAMETROS_EDITARVALOR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro do parâmetro"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/configuracoes/parametros/form",
                            state: { parametro: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.OUTROS_PARAMETROS_MANUTENCAO]}
                    componente={BotaoDelete}
                    url="/configuracoes/parametros"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir o parâmetro <b>{rowData.chave.chave}</b>?
                        </span>
                    }
                    classNames="p-mb-1"
                    tooltip="Deletar parâmetro"
                    tooltipOptions={{ position: "left" }}
                    msgToastErroExclusao="O parâmetro não pode ser excluído."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <Button
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2 p-mb-2"
                onClick={() => history.push("/configuracoes/parametros/form")}
            />
        </>
    );

    const grupoChaveBodyTemplate = (rowData) => {
        const grupo = GRUPO_CHAVE_PARAMETRO.find((el) => el.value === rowData.chave.grupo);
        if (grupo) return <span>{grupo.label}</span>;
        return <span>--</span>;
    };

    const tipoParametroBody = (rowData) => {
        if (rowData.chave.tipo === "U") {
            return <Tag className="p-mr-2" severity="success" value="USUÁRIO" />;
        } else {
            return <Tag className="p-mr-2" severity="danger" value="SISTEMA" />;
        }
    };

    const grupoFiltroTemplate = (options) => {
        return (
            <Dropdown
                options={GRUPO_CHAVE_PARAMETRO}
                value={options.value}
                placeholder="Selecione"
                showClear
                onChange={(e) => options.filterCallback(e.value, options.index)}
                className="p-column-filter"
            />
        );
    };

    const colunas = [
        {
            field: "chave.descricao",
            header: "Descrição",
            filter: true,
            filterField: "chave__descricao",
        },
        {
            field: "chave.grupo",
            header: "Grupo",
            style: { width: "12%" },
            filter: true,
            filterField: "chave__grupo",
            filterElement: grupoFiltroTemplate,
            action: (e) => grupoChaveBodyTemplate(e),
        },
        {
            field: "valor",
            header: "Valor",
            style: { width: "15%" },
        },
        {
            field: "perfil",
            header: "Perfil",
            action: ({ perfil }) => (perfil?.nome_curto ? perfil?.nome_curto : perfil?.nome),
        },
        { field: "tipo", header: "Tipo", style: { width: "5%" }, action: (e) => tipoParametroBody(e) },
        { field: "action", header: "Ações", style: { width: "8%" }, action: (e) => actionBodyTemplate(e) },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Listagem de parâmetros"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                botaoExportar
                urlPesquisa="/configuracoes/parametros?integracao=false"
                filtros={{
                    chave__descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    chave__grupo: { value: null, matchMode: "equals" },
                }}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
            />
        </PageBase>
    );
};
