import React, { useCallback, useEffect, useImperativeHandle, useRef, useState, forwardRef } from "react";
import { Dialog } from "primereact/dialog";
import { Steps } from "primereact/steps";

import { BlockUI } from "@/components/BlockUI";
import { Dropdown } from "@/components/Dropdown";
import { axiosPut } from "@/services/http";
import useToast from "@/hooks/useToast";

const Modal = ({ onSuccess }, ref) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [edicaoBloqueada, setEdicaoBloqueada] = useState(false);
    const [sequencia, setSequencia] = useState([]);
    const [estagiosVenda, setEstagiosVenda] = useState([]);
    const [estagioVendaSelecionado, setEstagioVendaSelecionado] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const vendaIdRef = useRef(null);
    const { showSuccess, showWarning, showError } = useToast();

    const alterarEstagioVenda = useCallback(
        async (novaSequenciaId) => {
            const url = `/vendas/alterar-estagio-venda/${vendaIdRef.current}/${novaSequenciaId}/`;
            setLoading(true);
            const { status, data } = await axiosPut(url);
            setLoading(false);
            if (status === 200) {
                showSuccess({
                    summary: "Sucesso!",
                    detail: data?.msg || "Estágio processado com sucesso.",
                    life: 2000,
                });
                if (typeof onSuccess === "function") onSuccess();
            } else if (status !== 500) {
                showWarning({
                    summary: "Aviso",
                    detail: data?.msg || "Não foi possível processar o estágio de venda.",
                    life: 3000,
                });
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não foi possível processar o estágio de venda.",
                    life: 3000,
                });
            }
        },
        [onSuccess, showSuccess, showWarning, showError]
    );

    const abrirModal = (estagioId, sequenciaAtual, vendaId) => {
        setVisible(true);
        setEdicaoBloqueada(!!estagioId);
        if (estagioId) setEstagioVendaSelecionado(estagioId);
        if (sequenciaAtual) setActiveIndex(sequenciaAtual ? sequenciaAtual - 1 : -1);
        vendaIdRef.current = vendaId;
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const definirSequencia = useCallback(() => {
        const estagio = estagiosVenda.find((el) => el.id === estagioVendaSelecionado);
        const itens = estagio?.sequenciaestagiovenda_set.map((seq) => ({
            label: seq.tipo_estagio_venda.descricao,
            command: () => alterarEstagioVenda(seq.id),
        }));
        setSequencia(itens);
    }, [estagiosVenda, estagioVendaSelecionado, alterarEstagioVenda]);

    useEffect(() => {
        definirSequencia();
    }, [definirSequencia]);

    return (
        <Dialog
            header="Alterar fluxo de venda"
            visible={visible}
            onHide={() => setVisible(false)}
            style={{ width: "70vw" }}
        >
            <BlockUI loading={loading}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="competencia">Fluxo de venda</label>
                        <Dropdown
                            url="/vendas/estagios-vendas/"
                            setObjects={setEstagiosVenda}
                            optionValue="id"
                            optionLabel="descricao"
                            disabled={edicaoBloqueada}
                            value={estagioVendaSelecionado}
                            onChange={(e) => setEstagioVendaSelecionado(e.value)}
                            className="p-mb-3"
                        />
                    </div>
                </div>
                <Steps
                    model={sequencia}
                    activeIndex={activeIndex}
                    onSelect={(e) => setActiveIndex(e.index)}
                    readOnly={false}
                />
            </BlockUI>
        </Dialog>
    );
};

export const ModalAlterarEstagio = forwardRef(Modal);
