import React, { useRef, useState, useEffect, useCallback } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { axiosPost, axiosPut, axiosGet } from "@/services/http";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { useHistory } from "react-router-dom";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import { TIPO_COMPOSICAO_TEMPLATE_CHOICE } from "@/assets/constants/constants";
import { gerarId } from "@/assets/util/util";

const url = "/produtos/templates-descricoes-skus/";
const urlvoltar = "/produto/cadastros/padrao-nome";

export const PadraoTemplateForm = (props) => {
    const { showLoading, hideLoading } = useLoading();
    const [showItemTemp, setShowItemTemp] = useState(false);
    const [listaItens, setListaItens] = useState([]);
    const [remocaoItemTemplate, setRemocaoItemTemplate] = useState([]);
    const [inserindoItemTemplate, setInserindoItemTemplate] = useState([]);
    const [tipo, setTipo] = useState(null);
    const [sequencia, setSequencia] = useState(null);
    const [tamanho, setTamanho] = useState(null);
    const [atributo, setAtributo] = useState(null);
    const [listaAtributos, setListaAtributos] = useState([]);

    const listagemRef = useRef(null);
    const toastRef = useRef(null);
    const history = useHistory();

    const templateVazio = {
        separador: " ",
        descricao: "",
        composicaotemplatedescricaosku_set: { create: [] },
    };

    const formik = useFormik({
        initialValues: props.location.state ? props.location.state.templateSku : templateVazio,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo 'descricao' é obrigatório."),
                separador: Yup.string().max(1),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                values.composicaotemplatedescricaosku_set = { create: inserindoItemTemplate };

                showLoading();
                const resposta = await axiosPost(`${url}`, values);
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Template cadastrado com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                let remove = [];
                let insere = [];
                if (remocaoItemTemplate.length !== 0) remove = remocaoItemTemplate;
                if (inserindoItemTemplate.length !== 0) insere = inserindoItemTemplate;

                values.composicaotemplatedescricaosku_set = {
                    remove: remove,
                    create: insere,
                };

                showLoading();
                const resposta = await axiosPut(`${url}${values.id}/`, values);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Template alterado com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    const insereItem = () => {
        let idItem = gerarId();
        let existe = false;

        listaItens.forEach((valor) => {
            if (valor.sequencia === sequencia) existe = true;
        });

        if (!existe) {
            setInserindoItemTemplate([
                ...inserindoItemTemplate,
                {
                    id: idItem,
                    sequencia: sequencia,
                    tipo: tipo.id,
                    atributo: atributo && atributo.id ? atributo.id : null,
                    tamanho: tamanho,
                },
            ]);
            setListaItens([
                ...listaItens,
                {
                    id: idItem,
                    sequencia: sequencia,
                    tipo: tipo,
                    atributo: atributo && atributo.id ? atributo.id : null,
                    tamanho: tamanho,
                },
            ]);

            toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Inserido com sucesso!",
                life: 1500,
            });
            setSequencia(null);
            setTamanho(null);
            setAtributo(null);
            setTipo(null);
        } else {
            toastRef.current.show({
                severity: "warn",
                summary: "Sequência duplicada!",
                detail: "A sequência informada já existe, por favor corriga a informação e tente novamente.",
                life: 3000,
            });
        }
    };

    const listarAtributos = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/produtos/atributos?limit=300");
        hideLoading();

        if (resposta.status === 200 || resposta.status === 201) {
            setListaAtributos(resposta.data.results);
        }
    }, [showLoading, hideLoading]);

    useEffect(() => {
        listarAtributos();
        let listaAux = [];

        if (props.location.state?.templateSku?.composicaotemplatedescricaosku_set?.length > 0) {
            props.location.state.templateSku.composicaotemplatedescricaosku_set.forEach((value) => {
                listaAux.push(value);
            });
            setListaItens(listaAux);
        }
    }, [listarAtributos, props.location.state?.templateSku?.composicaotemplatedescricaosku_set]);

    useEffect(() => {
        listagemRef.current?.buscarDados();
    }, [listaItens]);

    const removeItemTemplate = (item) => {
        let listaNova = [];
        let listaInsercao = [];

        listaItens.forEach((value) => {
            if (item.id !== value.id) listaNova.push(value);
        }, []);
        setListaItens(listaNova);

        inserindoItemTemplate.forEach((value) => {
            if (item.id !== value.id) listaInsercao.push(value);
        }, []);
        setInserindoItemTemplate(listaInsercao);

        if (typeof item.id !== "string") setRemocaoItemTemplate([...remocaoItemTemplate, item.id]);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_CADASTRO_COMPOSICAOTEMPLATEDESCRICAOSKU_EXCLUIR]}
                    componente={Button}
                    type="button"
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => removeItemTemplate(rowData)}
                    tooltip="Deletar centro de estoque"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.PRODUTO_CADASTRO_COMPOSICAOTEMPLATEDESCRICAOSKU_INCLUIR]}
                componente={Button}
                label="Item do template"
                icon="pi pi-plus"
                type="button"
                className="p-button-success p-button-outlined p-mr-2"
                onClick={() => setShowItemTemp(true)}
            />
        </>
    );

    const colunas = [
        { field: "sequencia", header: "Sequencia" },
        { field: "tamanho", header: "Tamanho" },
        { field: "tipo.descricao", header: "Tipo" },
        { field: "atributo.nome", header: "Atributo" },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "20%" },
        },
    ];

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toastRef} />
                    <h3>{!formik.values.id ? "Novo template" : "Manutenção de template"}</h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-5">
                                <label htmlFor="descricao">Descrição</label>
                                <InputText
                                    id="descricao"
                                    name="descricao"
                                    value={formik.values.descricao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.descricao })}
                                    tooltip="Descrição que será dada ao estoque."
                                    autoComplete="off"
                                />
                                {formik.errors.descricao && (
                                    <small className="p-error">{formik.errors.descricao}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="separador">Separador *</label>
                                <InputText
                                    id="separador"
                                    name="separador"
                                    value={formik.values.separador}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.separador })}
                                    autoComplete="off"
                                />
                                {formik.errors.separador && (
                                    <small className="p-error">{formik.errors.separador}</small>
                                )}
                            </div>
                        </div>
                        <br />
                        <br />
                        {listaItens ? (
                            <MakoListagem
                                ref={listagemRef}
                                colunas={colunas}
                                painelEsquerdo={cabecalhoTabela}
                                dadosLocal={listaItens}
                                responsiva
                                configTabela={{
                                    paginator: true,
                                }}
                            />
                        ) : null}
                        <br />
                        <br />
                        <div className="p-grid p-col-12 p-md-6">
                            <Button label="Gravar" type="submit" className="p-button-info p-mr-2 p-mb-6" />
                            <Button
                                label="Cancelar"
                                type="reset"
                                className="p-button-danger p-mr-2 p-mb-6"
                                onClick={cancelar}
                            />
                        </div>
                    </form>
                </div>
                <Dialog
                    header={"Cadastro item do template"}
                    visible={showItemTemp}
                    breakpoints={{ "960px": "75vw" }}
                    style={{ width: "45vw", display: "block" }}
                    onHide={() => setShowItemTemp(false)}
                >
                    <form>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="tipo">Tipo *</label>
                                <Dropdown
                                    id="tipo"
                                    placeholder="Selecione um tipo"
                                    name="tipo"
                                    filter
                                    filterBy="descricao"
                                    onChange={(e) => setTipo(e.target.value)}
                                    value={tipo}
                                    optionLabel="descricao"
                                    options={TIPO_COMPOSICAO_TEMPLATE_CHOICE}
                                    autoComplete="off"
                                    autoFocus
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="atributo">Atributo</label>
                                <Dropdown
                                    id="atributo"
                                    placeholder="Selecione um atributo"
                                    name="atributo"
                                    filter
                                    filterBy="nome"
                                    showClear
                                    onChange={(e) => setAtributo(e.target.value)}
                                    value={atributo}
                                    optionLabel="nome"
                                    options={listaAtributos}
                                    disabled={
                                        tipo && tipo.id
                                            ? tipo.id === "CA" || tipo.id === "NA" || tipo.id === "VA"
                                                ? false
                                                : true
                                            : true
                                    }
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="sequencia">Sequencia *</label>
                                <InputNumber
                                    id="sequencia"
                                    name="sequencia"
                                    min={0}
                                    showButtons
                                    onValueChange={(e) => setSequencia(e.target.value)}
                                    value={sequencia}
                                    autoComplete="off"
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="tamanho">Tamanho *</label>
                                <InputNumber
                                    id="tamanho"
                                    name="tamanho"
                                    min={1}
                                    showButtons
                                    onValueChange={(e) => setTamanho(e.target.value)}
                                    value={tamanho}
                                    autoComplete="off"
                                />
                            </div>
                        </div>

                        <br />

                        <p>
                            <b>* Campos obrigatórios.</b>
                        </p>

                        <br />
                        <div className="p-grid p-justify-end" style={{ paddingRight: "2%" }}>
                            <Button
                                label="Inserir"
                                type="button"
                                className="p-button-success"
                                disabled={!(tipo !== null && sequencia !== null && tamanho !== null)}
                                onClick={() => insereItem()}
                            />
                        </div>
                    </form>
                </Dialog>
            </div>
        </div>
    );
};
