import React, { useEffect } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";

import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";

import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import useAuth from "@/hooks/useAuth";

import { axiosPost } from "@/services/http";
import { dataToStr } from "@/assets/util/datas";

export const FormAbrir = ({ fecharModal = () => {}, handleCaixa = () => {} }) => {
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const { user } = useAuth();

    const { setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            operador: user?.nome,
            caixa: null,
            data_abertura: new Date(),
            hora_abertura: new Date(),
            dinheiro_inicial: 0,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                operador: Yup.string().required("O campo 'operador' é obrigatório.").typeError("Informe uma operador"),
                caixa: Yup.number().required("O campo 'caixa' é obrigatório.").typeError("Selecione um caixa."),
                data_abertura: Yup.date()
                    .required("O campo 'data abertura' é obrigatório")
                    .typeError("Seleciona um data de abertura."),
                hora_abertura: Yup.date()
                    .required("O campo 'hora abertura' é obrigatório")
                    .typeError("Seleciona uma hora de abertura."),
                dinheiro_inicial: Yup.number()
                    .required("O campo 'saldo inicial' é obrigatório.")
                    .typeError("Informe um saldo inicial."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const caixaMovi = {
                ...values,
                operador: user.id,
                data_abertura: dataToStr(values.data_abertura, "yyyy-MM-dd"),
                hora_abertura: dataToStr(values.hora_abertura, "HH:mm:ss"),
            };

            showLoading();
            const resposta = await axiosPost("/financeiro/caixas-movimento/", caixaMovi);
            hideLoading();

            if (resposta.status === 201) {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Caixa aberto com sucesso!",
                    life: 1500,
                });
                handleCaixa();
                fecharModal();
            } else {
                showError({
                    severity: "error",
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos processar sua requisição.",
                    life: 3000,
                });
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    useEffect(() => {
        resetForm();
    }, [resetForm]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="operador">Nome Operador *</label>
                    <InputText
                        id="operador"
                        name="operador"
                        value={formik.values.operador}
                        className={classNames({ "p-invalid": formik.errors.operador })}
                        disabled
                    />
                    {formik.errors.operador && <small className="p-error">{formik.errors.operador}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="caixa">Selecione o caixa *</label>
                    <Dropdown
                        id="caixa"
                        name="caixa"
                        url={`/financeiro/caixas/?query={id,nome}&caixamovimento__operador=${user.id}&ativo=true&caixamovimento__datahora_fechamento__isnull=true`}
                        optionValue="id"
                        optionLabel="nome"
                        filter
                        filterBy="nome"
                        placeholder="Selecione um caixa..."
                        value={formik.values.caixa}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.caixa })}
                    />
                    {formik.errors.caixa && <small className="p-error">{formik.errors.caixa}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="data_abertura">Data Abertura *</label>
                    <MakoCalendar
                        id="data_abertura"
                        name="data_abertura"
                        valueCalendar={formik.values.data_abertura}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.data_abertura })}
                    />
                    {formik.errors.data_abertura && <small className="p-error">{formik.errors.data_abertura}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="hora_abertura">Hora Abertura *</label>
                    <Calendar
                        id="hora_abertura"
                        name="hora_abertura"
                        timeOnly
                        showTime
                        hourFormat="24"
                        value={formik.values.hora_abertura}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.hora_abertura })}
                    />
                    {formik.errors.hora_abertura && <small className="p-error">{formik.errors.hora_abertura}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="dinheiro_inicial">Saldo inicial</label>
                    <MakoInputMoeda
                        id="dinheiro_inicial"
                        name="dinheiro_inicial"
                        valueMoeda={formik.values.dinheiro_inicial}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.dinheiro_inicial })}
                    />
                    {formik.errors.dinheiro_inicial && <small className="p-error">{formik.errors.saldo_inicial}</small>}
                </div>
            </div>
            <div className="p-grid p-justify-end p-mt-2">
                <Button label="Gravar" className="p-button-success p-mr-2" type="submit" />
                <Button label="Cancelar" className="p-button-danger p-mr-3" onClick={fecharModal} />
            </div>
        </form>
    );
};
