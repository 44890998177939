import React, { forwardRef, useCallback, useImperativeHandle, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact";

import { MakoDropdownCompetenciaFinanceira } from "@/components/MakoInputs/MakoDropdownCompetenciaFinanceira";
import { MakoInputCliente } from "@/components/MakoInputs/MakoInputCliente";
import { MakoInputCheckBox } from "@/components/MakoInputs/MakoInputCheckbox";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoButton } from "@/components/MakoButton";
import { Dropdown } from "@/components/Dropdown";

import { NATUREZA_FINANCEIRA } from "@/assets/constants/financeiro";
import useEmpresa from "@/hooks/useEmpresa";
import { dataToStr } from "@/assets/util/datas";
import { totalizadorFiltrosAplicados } from "@/assets/util/util";

const INITIAL_VALUES = {
    data_emissao: null,
    data_lancamento: null,
    vencimento: null,
    quantidade_parcela: null,
    numero_parcela: null,
    valor: null,
    valor_recebido: null,
    forma_recebimento: null,
    competencia: null,
    conta_financeira: null,
    template_rateio: null,
    devedor: null,
    natureza: null,
    quitado: null,
    historico_original: null,
};

const FiltroAvancadoRecebimentos = ({ onSuccess, url }, ref) => {
    const [visible, setVisible] = useState(false);
    const { empresaSelecionadaId } = useEmpresa();

    const abrirModal = () => {
        setVisible(true);
    };

    useImperativeHandle(ref, () => {
        return {
            abrirModal,
        };
    });

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: INITIAL_VALUES,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                competencia: Yup.number().nullable().typeError("Informe uma 'competência' válida"),
                conta_financeira: Yup.number().nullable().typeError("Informe uma 'conta financeira' válida"),
                forma_recebimento: Yup.number().nullable().typeError("Informe uma 'forma recebimento' válida"),
                historico_original: Yup.number().nullable().typeError("Informe um 'histórico' válido"),
                numero_parcela: Yup.number().nullable().typeError("Informe um 'numero parcela' válido"),
                quantidade_parcela: Yup.number().nullable().typeError("Informe uma 'quantidade pacela' válida"),
                template_rateio: Yup.number().nullable().typeError("Informe um 'template rateio' válido"),
                valor: Yup.number().nullable().typeError("Informe um 'valor' válido"),
                valor_recebido: Yup.number().nullable().typeError("Informe um 'valor recebido' válido"),
                data_emissao: Yup.date().nullable().typeError("Informe uma 'data emissão' válida"),
                data_lancamento: Yup.date().nullable().typeError("Informe uma 'data lançamento' válida"),
                data_recebimento: Yup.date().nullable().typeError("Informe uma 'data recebimento' válida"),
                vencimento: Yup.date().nullable().typeError("Informe um 'vencimento' válido"),
                devedor: Yup.object().nullable().typeError("Informe um 'devedor' válido"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const baseUrl = url;
            const params = [];

            Object.keys(values).forEach((key) => {
                if (values[key] !== null && values[key] !== "") {
                    if (
                        key === "data_emissao" ||
                        key === "data_lancamento" ||
                        key === "data_recebimento" ||
                        key === "vencimento"
                    ) {
                        return params.push(`${key}=${dataToStr(values[key], "yyyy-MM-dd")}`);
                    }
                    if (key === "devedor") {
                        console.log(values[key]);
                        return params.push(`${key}=${values[key].id}`);
                    }
                    return params.push(`${key}=${values[key]}`);
                }
            });

            if (params.length > 0) {
                const _url = baseUrl + "&" + params.join("&");
                if (typeof onSuccess === "function")
                    onSuccess(_url, totalizadorFiltrosAplicados(INITIAL_VALUES, values));
            } else {
                if (typeof onSuccess === "function")
                    onSuccess(baseUrl, totalizadorFiltrosAplicados(INITIAL_VALUES, values));
            }
            fecharModal();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const aposBuscarConta = useCallback((data) => {
        return data.map((c) => {
            c.label = `${c.codigo} - ${c.descricao}`;
            return c;
        });
    }, []);

    const fecharModal = () => {
        setVisible(false);
    };

    return (
        <Dialog baseZIndex={1000} header="Filtro avançado de parcelas" visible={visible} onHide={fecharModal}>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data_emissao">Data emissão</label>
                        <MakoCalendar
                            id="data_emissao"
                            name="data_emissao"
                            valueCalendar={formik.values.data_emissao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_emissao })}
                        />
                        {formik.errors.data_emissao && <small className="p-error">{formik.errors.data_emissao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data_lancamento">Data lançamento</label>
                        <MakoCalendar
                            id="data_lancamento"
                            name="data_lancamento"
                            valueCalendar={formik.values.data_lancamento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_lancamento })}
                        />
                        {formik.errors.data_lancamento && (
                            <small className="p-error">{formik.errors.data_lancamento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="vencimento">Data vencimento</label>
                        <MakoCalendar
                            id="vencimento"
                            name="vencimento"
                            valueCalendar={formik.values.vencimento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.vencimento })}
                        />
                        {formik.errors.vencimento && <small className="p-error">{formik.errors.vencimento}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data_recebimento">Data recebimento</label>
                        <MakoCalendar
                            id="data_recebimento"
                            name="data_recebimento"
                            valueCalendar={formik.values.data_recebimento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_recebimento })}
                        />
                        {formik.errors.data_recebimento && (
                            <small className="p-error">{formik.errors.data_recebimento}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor_recebido">Forma recebimento</label>
                        <Dropdown
                            id="forma-recebimento"
                            name="forma_recebimento"
                            url="/financeiro/formas-recebimentos/?limit=100"
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.forma_recebimento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.forma_recebimento })}
                        />
                        {formik.errors.forma_recebimento && (
                            <small className="p-error">{formik.errors.forma_recebimento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="natureza">Natureza</label>
                        <Dropdown
                            id="natureza"
                            name="natureza"
                            options={NATUREZA_FINANCEIRA}
                            optionValue="value"
                            optionLabel="label"
                            value={formik.values.natureza}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.natureza })}
                        />
                        {formik.errors.natureza && <small className="p-error">{formik.errors.natureza}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="quantidade_parcela">Quantidade parcela</label>
                        <InputNumber
                            inputId="quantidade_parcela"
                            name="quantidade_parcela"
                            value={formik.values.quantidade_parcela}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.quantidade_parcela })}
                        />
                        {formik.errors.quantidade_parcela && (
                            <small className="p-error">{formik.errors.quantidade_parcela}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="numero_parcela">Número parcela</label>
                        <InputNumber
                            inputId="numero_parcela"
                            name="numero_parcela"
                            value={formik.values.numero_parcela}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.numero_parcela })}
                        />
                        {formik.errors.numero_parcela && (
                            <small className="p-error">{formik.errors.numero_parcela}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor">Valor</label>
                        <MakoInputMoeda
                            inputId="valor"
                            name="valor"
                            valueMoeda={formik.values.valor}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor })}
                        />
                        {formik.errors.valor && <small className="p-error">{formik.errors.valor}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor_recebido">Valor recebido</label>
                        <MakoInputMoeda
                            inputId="valor_recebido"
                            name="valor_recebido"
                            valueMoeda={formik.values.valor_recebido}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor_recebido })}
                        />
                        {formik.errors.valor_recebido && (
                            <small className="p-error">{formik.errors.valor_recebido}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="competencia">Competência financeira</label>
                        <MakoDropdownCompetenciaFinanceira
                            id="competencia"
                            name="competencia"
                            value={formik.values.competencia}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.competencia })}
                        />
                        {formik.errors.competencia && <small className="p-error">{formik.errors.competencia}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="conta_financeira">Conta financeira</label>
                        <Dropdown
                            id="conta_financeira"
                            name="conta_financeira"
                            url="/financeiro/contas-financeiras/?ativa-True"
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.conta_financeira}
                            aposBuscar={aposBuscarConta}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.conta_financeira })}
                        />
                        {formik.errors.conta_financeira && (
                            <small className="p-error">{formik.errors.conta_financeira}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="template_rateio">Template de rateio</label>
                        <Dropdown
                            id="template_rateio"
                            name="template_rateio"
                            buscar={!!empresaSelecionadaId}
                            url={`/financeiro/templates-rateios/?query={id,descricao}&empresa=${empresaSelecionadaId}`}
                            disabled={!!formik.values.id}
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.template_rateio}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.template_rateio })}
                        />
                        {formik.errors.template_rateio && (
                            <small className="p-error">{formik.errors.template_rateio}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="devedor">Devedor</label>
                        <MakoInputCliente
                            id="devedor"
                            name="devedor"
                            value={formik.values.devedor}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.devedor })}
                        />
                        {formik.errors.devedor && <small className="p-error">{formik.errors.devedor}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="historico_original">Historico</label>
                        <Dropdown
                            id="historico_original"
                            name="historico_original"
                            url={`/financeiro/historico-padrao/?ativo=True&tipo=R`}
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.historico_original}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.historico_original })}
                        />
                        {formik.errors.historico_original && (
                            <small className="p-error">{formik.errors.historico_original}</small>
                        )}
                    </div>
                    <MakoInputCheckBox
                        id="quitado"
                        name="quitado"
                        checked={formik.values.quitado}
                        onChange={formik.handleChange}
                        label="Quitado"
                    />
                </div>
                <div className="p-grid p-justify-end p-mt-2">
                    <MakoButton type="submit" icon="pi pi-filter" label="Filtrar" className="p-mr-2" />
                    <MakoButton
                        type="reset"
                        icon="pi pi-trash"
                        label="Limpar"
                        onClick={formik.resetForm}
                        className="p-button-warning p-mr-2"
                    />
                    <MakoButton
                        type="reset"
                        label="Cancelar"
                        onClick={fecharModal}
                        className="p-button-danger p-mr-3"
                    />
                </div>
            </form>
        </Dialog>
    );
};

export const ModalFiltroAvancadoRecebimentos = forwardRef(FiltroAvancadoRecebimentos);
