import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { PageBase } from "@/components/PageBase";
import { MakoMultiTabs } from "@/components/MakoMultiTabs";

import { DadosBasicosDevolucaoForm } from "./formDadosBasicos";
import { ItensDevolucaoForm } from "./formItens";
import { FinanceiroDevolucaoForm } from "./formFinanceiro";
import { DocFiscalDevolucaoForm } from "./formDocFiscal";
import useDevolucao from "@/hooks/useDevolucao";

export const TabDevolucaoMercadorias = ({ devolucaoId }) => {
    const [loading, setLoading] = useState(false);
    const { submit, buscarDevolucao } = useDevolucao();
    const history = useHistory();

    const TABS = [
        {
            header: "Dados básicos",
            icon: "pi pi-fw pi-bars",
            component: <DadosBasicosDevolucaoForm />,
        },
        {
            header: "Itens da devolução",
            icon: "fas pi-fw fa-cubes",
            disabled: !submit,
            component: <ItensDevolucaoForm />,
        },
        {
            header: "Financeiro",
            icon: "pi pi-fw pi-money-bill",
            disabled: !submit,
            component: <FinanceiroDevolucaoForm />,
        },
        {
            header: "Doc. fiscal",
            icon: "pi pi-fw pi-book",
            disabled: !submit,
            component: <DocFiscalDevolucaoForm />,
        },
    ];

    const onClickCancelar = () => {
        history.push("/vendas/venda/devolucao");
    };

    const recuperarDevolucao = useCallback(async () => {
        if (!devolucaoId) return;
        setLoading(true);
        await buscarDevolucao(devolucaoId);
        setLoading(false);
    }, [devolucaoId, buscarDevolucao]);

    useEffect(() => {
        recuperarDevolucao();
    }, [recuperarDevolucao]);

    return (
        <PageBase blocked={loading}>
            <MakoMultiTabs
                tabs={TABS}
                title="Devolução de mercadorias"
                ocultarButtonFinalizar
                propsButtonProx={{ disabled: !submit }}
                propsButtonCancelar={{ onClick: onClickCancelar }}
            />
        </PageBase>
    );
};
