import React, { useRef, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { MakoSelecionarEmails } from "@/components/MakoSelecionarEmails";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import useRelatorio from "@/hooks/useRelatorio";
import useClearRefs from "@/hooks/useClearRefs";

import { RELATORIO_VENDAS_EXTRATOSINTETICOCOMISSAO } from "@/assets/constants/relatorios";

import { dataToStr } from "@/assets/util/datas";
import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";

export const RelatorioExtratoComissaoSintetico = () => {
    const [visible, setVisible] = useState(false);
    const [emails, setEmails] = useState([]);

    const { solicitarRelatorio } = useRelatorio();

    const empresaSelecionada = useRef();

    useClearRefs(empresaSelecionada);

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            data_start: null,
            data_end: null,
            empresa: null,
            situacao: -1,
        },
        onSubmit: handleSubmit,
    });

    const SITUACAO = [
        { value: -1, label: "Geral" },
        { value: 1, label: "Apurada" },
        { value: 0, label: "Gerada" },
    ];

    const filtrosTemplate = [
        {
            key: "empresa",
            label: "Empresa",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: ["data_start", "data_end"],
            label: "Período emissão",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: "situacao",
            label: "Situação",
            optionKey: "value",
            optionLabel: "label",
            options: SITUACAO,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
    ];

    async function handleSubmit(values = null) {
        try {
            const formSchema = Yup.object().shape({
                data_start: Yup.date().nullable().typeError("Informe um 'data  inicial' válido"),
                data_end: Yup.date().when("data_start", {
                    is: (val) => !!val,
                    then: Yup.date()
                        .min(values.data_start || new Date(), "O campo 'data final' não pode ser anterior a inicial")
                        .typeError("Informe uma 'data final' válida"),
                    otherwise: Yup.date().nullable(),
                }),
                empresa: Yup.number().nullable().typeError("Informe uma 'empresa' válida"),
                situacao: Yup.number()
                    .required("O campo 'situação' é obrigatório")
                    .typeError("Informe uma 'situação' válida"),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            if (dadosValidados.situacao !== 1) delete dadosValidados.situacao;
            if (dadosValidados.data_start instanceof Date)
                dadosValidados.data_start = dataToStr(dadosValidados.data_start, "yyyy-MM-dd");
            if (dadosValidados.data_end instanceof Date)
                dadosValidados.data_end = dataToStr(dadosValidados.data_end, "yyyy-MM-dd");

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                empresa: dadosValidados?.empresa ? empresaSelecionada.current : null,
            });

            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            let filtros = {};
            if (dadosValidados) {
                Object.keys(dadosValidados).forEach((key) => {
                    if (dadosValidados[key]) filtros[key] = dadosValidados[key];
                });
            }

            solicitarRelatorio({
                chave: RELATORIO_VENDAS_EXTRATOSINTETICOCOMISSAO,
                emails,
                filtros,
                filtros_template: filtrosTemplateAplicados,
            });
            formik.resetForm();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const handleConfirm = (emails) => {
        fecharModal();
        if (emails && emails.length > 0) {
            handlePreSubmit(emails);
        }
    };

    const handlePreSubmit = (emails = []) => {
        setEmails(emails);
        formik.handleSubmit();
    };

    const fecharModal = () => {
        setVisible(() => false);
    };

    const limparFiltro = () => {
        formik.resetForm();
    };

    const onChangeEmpresa = (e) => {
        empresaSelecionada.current = e?.empresa;
        setFieldValue("empresa", e.id);
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <h5>Filtro para emissão de relatório de extrato de comissão sintético</h5>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-5">
                            <label htmlFor="empresa">Empresa</label>
                            <MakoDropdownEmpresas
                                id="empresa"
                                name="empresa"
                                placeholder="Selecione uma empresa"
                                value={formik.values.empresa}
                                onChange={(e) => onChangeEmpresa(e)}
                                className={classNames({ "p-invalid": formik.errors.empresa })}
                            />
                            {formik.errors.empresa && <small className="p-error">{formik.errors.empresa}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="situacao">Situação</label>
                            <Dropdown
                                id="situacao"
                                name="situacao"
                                options={SITUACAO}
                                optionValue="value"
                                optionLabel="label"
                                placeholder="Selecione uma situacao..."
                                value={formik.values.situacao}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.situacao })}
                            />
                            {formik.errors.situacao && <small className="p-error">{formik.errors.situacao}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="data_start">Periodo</label>
                            <MakoCalendar
                                id="data_start"
                                name="data_start"
                                valueCalendar={formik.values.data_start}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.data_start })}
                            />
                            {formik.errors.data_start && <small className="p-error">{formik.errors.data_start}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2 p-mt-4" style={{ paddingTop: "5px" }}>
                            <MakoCalendar
                                id="data_end"
                                name="data_end"
                                valueCalendar={formik.values.data_end}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.data_end })}
                            />
                            {formik.errors.data_end && <small className="p-error">{formik.errors.data_end}</small>}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid"></div>
                    <div className="p-grid p-col-12 p-md-12 p-mt-2">
                        <Button
                            label="Gerar PDF"
                            icon="pi pi-file-pdf"
                            type="button"
                            onClick={() => handlePreSubmit()}
                            className="p-button-info p-mr-2"
                        />
                        <Button
                            type="button"
                            onClick={() => setVisible(true)}
                            label="Enviar por email"
                            icon="pi pi-envelope"
                            className="p-button-info p-mr-2"
                        />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            onClick={() => limparFiltro()}
                            className="p-button-warning p-mr-2"
                        />
                    </div>
                </div>
            </div>
            <Dialog
                visible={visible}
                blockScroll
                onHide={fecharModal}
                style={{ width: "60vw" }}
                header="Informar emails"
            >
                <MakoSelecionarEmails onCancel={fecharModal} onConfirm={handleConfirm} />
            </Dialog>
        </div>
    );
};
