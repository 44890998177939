import React, { useMemo } from "react";

import { PESSOAS_TAGPERFIL_PADRAO_TRANSPORTADOR } from "@/assets/constants/parametros";
import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";
import useParam from "@/hooks/useParam";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";

export const MakoInputTransportador = ({ disabled, somenteAtivo, ...props }) => {
    const { getParam } = useParam();
    const [formatarDocumento] = useFormatCNPJCPF();

    const tagTransportador = useMemo(() => {
        const tagPerfil = getParam(PESSOAS_TAGPERFIL_PADRAO_TRANSPORTADOR);
        if (tagPerfil) {
            return tagPerfil.valor;
        }
        return null;
    }, [getParam]);

    const url = useMemo(() => {
        const filtroAtivo = somenteAtivo ? "&ativo=true" : "";
        return `/pessoas/perfis?query={id,nome,identificacao,tipo_pessoa,enderecoperfil_set}${filtroAtivo}&tags=${tagTransportador}&nome__unaccent__icontains=`;
    }, [somenteAtivo, tagTransportador]);

    const autoCompleteTransportadorTemplate = (item) => {
        return <div>{`${item.nome} - ${formatarDocumento(item.identificacao)}`}</div>;
    };

    return (
        <>
            <MakoAutoComplete
                disabled={!!!tagTransportador || disabled}
                minCaracteresBusca={4}
                itemTemplate={autoCompleteTransportadorTemplate}
                field="nome"
                urlSearch={url}
                placeholder="Digite o nome do transportador para buscar... (min 4 caracteres)"
                {...props}
            />
            {!!!tagTransportador && (
                <small className="p-error">Falta parametrizar a identificação do transportador</small>
            )}
        </>
    );
};
