import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { ModalOrcRapido } from "./ModalOrcamentoRapido";
import { MakoListagem } from "@/components/MakoListagem";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { MakoInputQuantidadeSku } from "@/components/MakoInputs/MakoInputQuantidadeSku";
import { MakoDropdownEstoques } from "@/components/MakoInputs/MakoDropdownEstoques";
import { Dropdown } from "@/components/Dropdown";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { formatarCasasDecimais } from "@/assets/util/util";
import { parseNumber } from "@/assets/helpers/number";
import * as pd from "@/assets/util/persistenciaDjango";
import { PESSOAS_TAGPERFIL_PADRAO_CLIENTE, VENDA_TABELAPRECO_OBRIGATORIA } from "@/assets/constants/parametros";
import useVenda from "@/hooks/useVenda";
import useParam from "@/hooks/useParam";
import useToast from "@/hooks/useToast";

export const ItensVendaForm = () => {
    const [unidadesVenda, setUnidadesVenda] = useState([]);
    const [qtdMinimaTabelaPreco, setQtdMinimaTabelaPreco] = useState(0);
    const [loading, setLoading] = useState(false);
    const { dadosBasicos, bloqueiaItens, vendedor, handleItemVenda } = useVenda();
    const listagemRef = useRef(null);
    const modalOrcamentoRef = useRef(null);
    const { showWarning } = useToast();
    const { getParam } = useParam();

    const eTroca = false;

    const tabelaPrecoObrigatoria = useMemo(() => {
        const param = getParam(VENDA_TABELAPRECO_OBRIGATORIA);
        return !!(param?.valor === "1");
    }, [getParam]);

    const { setFieldValue, resetForm, ...formik } = useFormik({
        initialValues: {
            sku: null,
            tabela_preco: null,
            preco_tabela_preco: null,
            preco_base: 0,
            quantidade: 0,
            centro_estocagem: null,
            valor_unitario: 0,
            valor_desconto_unitario: 0,
            valor_total: 0,
            requer_aprovacao_preco: false,
            largura: 1,
            und_largura: null,
            comprimento: 1,
            und_comprimento: null,
            altura: 1,
            und_altura: null,
            cubagem: 1,
            und_cubagem: 1,
            unidade_venda: null,
            observacoes: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                sku: Yup.object()
                    .typeError("Você precisa buscar e selecionar um produto.")
                    .required("Você precisa buscar e selecionar um produto."),
                quantidade: Yup.number()
                    .when("sku", {
                        is: (val) => val && val.permite_fracionamento === true,
                        then: Yup.number().min(0.001, "O campo 'quantidade' não pode ser ZERO."),
                        otherwise: Yup.number().min(1, "O campo 'quantidade' não pode ser ZERO."),
                    })
                    .required("O campo 'quantidade' não pode ser ZERO."),
                tabela_preco: tabelaPrecoObrigatoria
                    ? Yup.object()
                          .required("O campo 'tabela de preço' é obrigatório.")
                          .typeError("O campo 'tabela de preço' é obrigatório.")
                    : Yup.object().nullable(),
                unidade_venda: Yup.number()
                    .required("Selecione uma unidade de medida.")
                    .typeError("Selecione uma unidade de medida."),
                valor_unitario: Yup.number()
                    .required("O campo 'valor unitário' é obrigatório.")
                    .min(0.01, "Esse campo não pode ser 0 (ZERO)."),
                centro_estocagem: Yup.number()
                    .nullable()
                    .when("requer_aprovacao_preco", {
                        is: true,
                        then: Yup.number()
                            .required(
                                "Esse item precisará ser submetido a aprovação e para isso informe o centro de estocagem."
                            )
                            .typeError(
                                "Esse item precisará ser submetido a aprovação e para isso informe o centro de estocagem."
                            ),
                    }),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            const { valor_total, ...rest } = values;
            const item = {
                ...rest,
                desconto: rest.quantidade * rest.valor_desconto_unitario,
                valor_total_av: valor_total,
                valor_total_ap: valor_total,
            };
            setLoading(true);
            await handleItemVenda(item, pd.OP_CRUD_DJANGO.novo);
            setLoading(false);
            listagemRef.current?.buscarDados();
            resetForm();
            setQtdMinimaTabelaPreco(0);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const obterPrecoTabela = useCallback(() => {
        if (formik.values.tabela_preco && formik.values.sku) {
            const { precostabelapreco_set } = formik.values.tabela_preco;
            const preco = precostabelapreco_set.find((el) => el.sku.id === formik.values.sku.id);
            if (preco) {
                setFieldValue("preco_tabela_preco", preco.id);
                setFieldValue("valor_unitario", parseNumber(preco.preco_arredondado));
                setFieldValue("preco_base", parseNumber(preco.preco_arredondado));
            } else {
                showWarning({
                    summary: "Aviso!",
                    detail: "O produto não possui nenhum preço, portanto não pode ser vendido.",
                    life: 5000,
                });
            }
        }
    }, [formik.values.tabela_preco, formik.values.sku, setFieldValue, showWarning]);

    useEffect(() => {
        obterPrecoTabela();
    }, [obterPrecoTabela]);

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-trash"
                    disabled={bloqueiaItens || eTroca}
                    className="p-button-rounded p-button-danger"
                    onClick={async () => {
                        await handleItemVenda(rowData, pd.OP_CRUD_DJANGO.deletar);
                        listagemRef.current?.buscarDados();
                    }}
                />
            </div>
        );
    };

    const colunas = [
        { field: "sku.codigo", header: "Código", style: { maxWidth: "8%" } },
        { field: "descricao_derivada", header: "Descrição" },
        { field: "quantidade", header: "Qtd.", decimal: true, style: { maxWidth: "8%" } },
        {
            field: "cubagem",
            header: "Cubagem",
            style: { maxWidth: "8%" },
            decimal: true,
        },
        {
            field: "preco_base",
            header: "Preço base",
            style: { maxWidth: "13%" },
            money: true,
        },
        {
            field: "valor_desconto_unitario",
            header: "Valor descontos",
            style: { maxWidth: "13%" },
            money: true,
        },
        {
            field: "valor_total_av",
            header: "Valor total",
            style: { maxWidth: "13%" },
            money: true,
        },
        {
            field: "action",
            header: "Ações",
            className: "p-text-center",
            style: { maxWidth: "10%" },
            align: "center",
            action: (e) => actionBodyTemplate(e),
        },
    ];

    const onChangeDimensao = useCallback(
        (e) => {
            setFieldValue("cubagem", e);
        },
        [setFieldValue]
    );

    const setInformacoesItem = useCallback(() => {
        if (formik.values.sku instanceof Object) {
            const {
                codigo,
                descricao_derivada,
                descricao_reduzida,
                precosku_set,
                unidademedidasku_set,
                unidade_padrao,
            } = formik.values.sku;
            if (precosku_set.length === 0) {
                showWarning({
                    summary: "Aviso!",
                    detail: "O produto não possui nenhum preço cadastrado, portanto não pode ser vendido.",
                    life: 5000,
                });
                resetForm();
                return;
            }
            const unidades = unidademedidasku_set
                .filter((und) => und.ativo && und.tipo_mov_und_medida.id === "V")
                .map((und) => ({
                    id: und.unidade.id,
                    nome: `${und.unidade.nome} (${und.unidade.sigla})`,
                }));
            if (unidades.length === 0) {
                showWarning({
                    summary: "Aviso!",
                    detail: "O produto não possui nenhuma unidade de medida cadastrada, portanto não pode ser vendido.",
                    life: 5000,
                });
                resetForm();
                return;
            }
            setUnidadesVenda(unidades);
            setFieldValue("codigo", codigo);
            setFieldValue("descricao", descricao_derivada);
            setFieldValue("unidade_venda", unidade_padrao.id);
            setFieldValue("descricao_reduzida", descricao_reduzida);
            setFieldValue("preco_base", parseNumber(precosku_set[0].preco_base));
            setFieldValue("valor_unitario", parseNumber(precosku_set[0].preco_base));
        }
    }, [formik.values.sku, resetForm, setFieldValue, showWarning]);

    useEffect(() => {
        setInformacoesItem();
    }, [setInformacoesItem]);

    const calcularValorTotalItem = useCallback(() => {
        if (formik.values.quantidade > 0 && formik.values.valor_unitario > 0) {
            const valorItem = formik.values.valor_unitario - formik.values.valor_desconto_unitario;
            const valorTotal = formik.values.cubagem * formik.values.quantidade * valorItem;
            setFieldValue("valor_total", formatarCasasDecimais(valorTotal));
        }
    }, [
        formik.values.cubagem,
        formik.values.quantidade,
        formik.values.valor_desconto_unitario,
        formik.values.valor_unitario,
        setFieldValue,
    ]);

    useEffect(() => {
        calcularValorTotalItem();
    }, [calcularValorTotalItem]);

    /*
    const calcularValorDescontoItem = useCallback(() => {
        const valorUnitarioSKU = formik.values.preco_base;
        const valorItemInput = formik.values.valor_unitario;
        if (valorUnitarioSKU >= valorItemInput) {
            const valorDesconto = valorUnitarioSKU - valorItemInput;
            if (valorDesconto >= valorItemInput) setFieldValue("valor_desconto_unitario", 0);
            else setFieldValue("valor_desconto_unitario", valorDesconto);
        } else {
            setFieldValue("valor_desconto_unitario", 0);
        }
    }, [formik.values.preco_base, formik.values.valor_unitario, setFieldValue]);

    useEffect(() => {
        calcularValorDescontoItem();
    }, [calcularValorDescontoItem]);
    */

    const urlBuscaTabelaPreco = useMemo(() => {
        if (!!dadosBasicos && formik.values.sku instanceof Object) {
            const param = getParam(PESSOAS_TAGPERFIL_PADRAO_CLIENTE);
            if (!param) return "";
            const sku = formik.values.sku?.id;
            const tagCliente = param.valor;
            const planoRecebimentoId = dadosBasicos?.plano_recebimento;
            return `/produtos/tabelas-preco/?precostabelapreco__sku=${sku}&tag_cliente=${tagCliente}&plano_recebimento=${planoRecebimentoId}`;
        }
        return "";
    }, [dadosBasicos, formik.values.sku, getParam]);

    const validarPercentualDesconto = useCallback(async () => {
        if (formik.values.valor_desconto_unitario > 0) {
            const percentDescontoConcedido = (formik.values.valor_desconto_unitario / formik.values.preco_base) * 100;
            if (percentDescontoConcedido > parseNumber(vendedor.max_desconto_avista)) {
                setFieldValue("requer_aprovacao_preco", true);
            }
        } else {
            setFieldValue("requer_aprovacao_preco", false);
        }
    }, [formik.values.valor_desconto_unitario, formik.values.preco_base, vendedor, setFieldValue]);

    useEffect(() => {
        validarPercentualDesconto();
    }, [validarPercentualDesconto]);

    const onChangeTabelaPreco = ({ value }) => {
        setFieldValue("tabela_preco", value);
        if (value) {
            const qtdMinima = parseNumber(value.qtd_minima_tag);
            setQtdMinimaTabelaPreco(qtdMinima);
            setFieldValue("quantidade", qtdMinima);
        }
    };

    const rowClass = (rowData) => {
        return {
            "table-recebimentos-overdue": rowData.requer_aprovacao_preco,
        };
    };

    return (
        <>
            {bloqueiaItens && <h5 className="p-error p-mt-0">O estágio atual não permite alterar os itens</h5>}
            <form onSubmit={formik.handleSubmit}>
                <MakoBuscaSkuPersonalizada
                    statusItem="F"
                    skuValue={formik.values.sku}
                    skuChange={(e) => setFieldValue("sku", e)}
                    skuError={formik.errors.sku}
                    disabledBusca={bloqueiaItens}
                    exibeDimensoes
                    alturaValue={formik.values.altura}
                    onChangeAltura={(e) => setFieldValue("altura", e)}
                    larguraValue={formik.values.largura}
                    onChangeLargura={(e) => setFieldValue("largura", e)}
                    comprimentoValue={formik.values.comprimento}
                    onChangeComprimento={(e) => setFieldValue("comprimento", e)}
                    dimensaoValue={formik.values.cubagem}
                    onChangeDimensao={onChangeDimensao}
                />
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="tabela-preco">{`Tabela de preço ${tabelaPrecoObrigatoria ? "*" : ""}`}</label>
                        <Dropdown
                            id="tabela-preco"
                            buscar={!!urlBuscaTabelaPreco}
                            url={urlBuscaTabelaPreco}
                            name="tabela_preco"
                            optionLabel="nome"
                            value={formik.values.tabela_preco}
                            onChange={onChangeTabelaPreco}
                            className={classNames({ "p-invalid": formik.errors.tabela_preco })}
                        />
                        {formik.errors.tabela_preco && <small className="p-error">{formik.errors.tabela_preco}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="quantidade">Quantidade *</label>
                        <MakoInputQuantidadeSku
                            id="quantidade"
                            name="quantidade"
                            min={qtdMinimaTabelaPreco}
                            permiteFracionario={formik.values.sku?.movimenta_cubagem}
                            value={formik.values.quantidade}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.quantidade })}
                        />
                        {formik.errors.quantidade && <small className="p-error">{formik.errors.quantidade}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="und-venda">Unidade de venda *</label>
                        <Dropdown
                            id="und-venda"
                            name="unidade_venda"
                            options={unidadesVenda}
                            optionValue="id"
                            optionLabel="nome"
                            value={formik.values.unidade_venda}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.unidade_venda })}
                        />
                        {formik.errors.unidade_venda && (
                            <small className="p-error">{formik.errors.unidade_venda}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-unitario">Valor unitário *</label>
                        <MakoInputMoeda
                            id="valor-unitario"
                            name="valor_unitario"
                            valueMoeda={formik.values.valor_unitario}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor_unitario })}
                        />
                        {formik.errors.valor_unitario && (
                            <small className="p-error">{formik.errors.valor_unitario}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="descontos">Desconto unitário</label>
                        <MakoInputMoeda
                            id="valor_desconto_unitario"
                            name="valor_desconto_unitario"
                            min={0}
                            max={formik.values.preco_base}
                            valueMoeda={formik.values.valor_desconto_unitario}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="total-item">Total do item</label>
                        <MakoInputMoeda id="total-item" name="valor_total" disabled value={formik.values.valor_total} />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="estoque">Centro de estocagem</label>
                        <MakoDropdownEstoques
                            id="estoque"
                            name="centro_estocagem"
                            empresaId={dadosBasicos?.empresa.id}
                            optionValue="id"
                            value={formik.values.centro_estocagem}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.centro_estocagem })}
                        />
                        {formik.errors.centro_estocagem && (
                            <small className="p-error">{formik.errors.centro_estocagem}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-8">
                        <label htmlFor="obs">Observações</label>
                        <InputText
                            id="obs"
                            name="observacoes"
                            value={formik.values.observacoes}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <CamposObrigatorios />
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button
                            type="submit"
                            icon="pi pi-plus"
                            label="Gravar item"
                            loading={loading}
                            disabled={bloqueiaItens || eTroca}
                            className="p-mr-2 p-mb-2"
                        />
                        <Button
                            type="reset"
                            label="Limpar"
                            icon="pi pi-trash"
                            disabled={bloqueiaItens || eTroca}
                            className="p-button-warning p-mr-2 p-mb-2"
                            onClick={() => resetForm()}
                        />
                        <Button
                            label="Itens do orçamento rápido"
                            icon="pi pi-book"
                            type="button"
                            className="p-button-help p-mr-2 p-mb-2"
                            disabled={bloqueiaItens || eTroca}
                            onClick={() => modalOrcamentoRef.current?.abrirModal()}
                        />
                    </div>
                </div>
            </form>
            <MakoListagem
                ref={listagemRef}
                colunas={colunas}
                urlPesquisa={`/vendas/itens-vendas/?venda=${dadosBasicos?.id}`}
                configTabela={{
                    lazy: true,
                    paginator: true,
                    rowClassName: rowClass,
                }}
            />
            <ModalOrcRapido ref={modalOrcamentoRef} />
        </>
    );
};
