import React, { useRef, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { MakoSelecionarEmails } from "@/components/MakoSelecionarEmails";
import { MakoDropdownEstoques } from "@/components/MakoInputs/MakoDropdownEstoques";
import { MakoInputFornecedor } from "@/components/MakoInputs/MakoInputFornecedor";
import { MakoInputCheckBox } from "@/components/MakoInputs/MakoInputCheckbox";
import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";
import { MakoInputNcm } from "@/components/MakoInputs/MakoInputNcm";

import { Dropdown } from "@/components/Dropdown";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import useRelatorio from "@/hooks/useRelatorio";
import useClearRefs from "@/hooks/useClearRefs";
import useEmpresa from "@/hooks/useEmpresa";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIO_ESTOQUE_SALDOINICIAL } from "@/assets/constants/relatorios";

export const RelatorioSaldoInicial = () => {
    const [visible, setVisible] = useState(false);
    const [emails, setEmails] = useState([]);

    const { empresaSelecionadaId } = useEmpresa();
    const { solicitarRelatorio } = useRelatorio();

    const categoriaRef = useRef();

    useClearRefs(categoriaRef);

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            marca: null,
            fornecedor: null,
            descricao: "",
            grupo: null,
            destinacao: null,
            ncm: null,
            preco_custo: true,
            estoque: null,
        },
        onSubmit: handleSubmit,
    });

    const filtrosTemplate = [
        {
            key: "destinacao",
            label: "Destinação",
            path: "nome",
        },
        {
            key: "marca",
            label: "Marca",
            path: "nome",
        },
        {
            key: "grupo",
            label: "Categoria",
            path: "nome",
        },
        {
            key: "estoque",
            label: "Estoque",
            path: "nome",
        },
        {
            key: "ncm",
            label: "Ncm",
            path: "codigo",
        },
        {
            key: "destinacao",
            label: "Destinaçã",
            path: "nome",
        },
        {
            key: "fornecedor",
            label: "Fornecedor",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: "descricao",
            label: "Descrição",
        },
        {
            key: "preco_custo",
            label: "Preço custo?",
            type: TIPOS_FILTROS_TEMPLATE.BOOLEAN,
        },
    ];

    const selecionarCategoria = (e) => {
        categoriaRef.current = e;
    };

    async function handleSubmit(values = null) {
        try {
            const formSchema = Yup.object().shape({
                estoque: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number().required("Informe uma 'estoque' válido."),
                    })
                    .typeError("Informe uma 'estoque' válido."),
                marca: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number().required("Informe uma 'marca' válida."),
                    })
                    .typeError("Informe uma 'marca' válida."),
                grupo: Yup.number().nullable().typeError("Informe uma 'categoria' válida."),
                fornecedor: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number().required("Informe um 'fornecedor' válida."),
                    })
                    .typeError("Informe um 'fornecedor' válida."),
                destinacao: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number().required("Informe uma 'destinação' válida."),
                    })
                    .typeError("Informe uma 'destinação' válida."),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                grupo: typeof dadosValidados.grupo === "number" ? categoriaRef.current : null,
            });
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.ncm) dadosValidados.ncm = dadosValidados.ncm.id;
            if (dadosValidados.fornecedor) dadosValidados.fornecedor = dadosValidados.fornecedor.id;
            if (dadosValidados.marca) dadosValidados.marca = dadosValidados.marca.id;
            if (dadosValidados.estoque) dadosValidados.estoque = dadosValidados.estoque.id;
            if (dadosValidados.destinacao) dadosValidados.destinacao = dadosValidados.destinacao.id;

            let filtros = {};
            if (dadosValidados) {
                Object.keys(dadosValidados).forEach((key) => {
                    if (dadosValidados[key]) filtros[key] = dadosValidados[key];
                });
            }
            solicitarRelatorio({
                chave: RELATORIO_ESTOQUE_SALDOINICIAL,
                emails,
                filtros,
                filtros_template: filtrosTemplateAplicados,
            });
            formik.resetForm();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const handleConfirm = (emails) => {
        fecharModal();
        if (emails && emails.length > 0) {
            handlePreSubmit(emails);
        }
    };

    const handlePreSubmit = (emails = []) => {
        setEmails(emails);
        formik.handleSubmit();
    };

    const fecharModal = () => {
        setVisible(() => false);
    };

    const limparFiltro = () => {
        formik.resetForm();
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <h5>Filtro para emissão de relatório de saldo inicial de produtos</h5>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="estoque">Estoque: *</label>
                            <MakoDropdownEstoques
                                id="estoque"
                                name="estoque"
                                apenasEstoqueConsolidado
                                empresaId={empresaSelecionadaId}
                                disabled={!empresaSelecionadaId}
                                value={formik.values.estoque}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.estoque })}
                            />
                            {formik.errors.estoque && <small className="p-error">{formik.errors.estoque}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="marca">Marca:</label>
                            <MakoAutoComplete
                                id="marca"
                                name="marca"
                                placeholder="Digite para pesquisar... (min 4 caracteres)"
                                minCaracteresBusca={4}
                                field="nome"
                                urlSearch="/produtos/marcas/?search="
                                value={formik.values.marca}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.marca })}
                            />
                            {formik.errors.marca && <small className="p-error">{formik.errors.marca}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="fornecedor">Fornecedor:</label>
                            <MakoInputFornecedor
                                id="fornecedor"
                                name="fornecedor"
                                value={formik.values.fornecedor}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.fornecedor })}
                            />
                            {formik.errors.fornecedor && <small className="p-error">{formik.errors.fornecedor}</small>}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="ncm">NCM:</label>
                            <MakoInputNcm
                                id="ncm"
                                name="ncm"
                                fieldValue={setFieldValue}
                                valorNcm={formik.values.ncm}
                                className={classNames({ "p-invalid": formik.errors.ncm })}
                            />
                            {formik.errors.ncm && <small className="p-error">{formik.errors.ncm}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="destinacao">Destinação:</label>
                            <Dropdown
                                id="destinacao"
                                name="destinacao"
                                url={"/compras/destinacoes-entradas/"}
                                value={formik.values.destinacao}
                                onChange={formik.handleChange}
                                filter
                                filterBy="nome"
                                optionLabel="nome"
                                placeholder="Selecione um tipo..."
                                className={classNames({ "p-invalid": formik.errors.destinacao })}
                            />
                            {formik.errors.destinacao && <small className="p-error">{formik.errors.destinacao}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="grupo">Categoria:</label>
                            <MakoDropdownCategoriasHierarquicas
                                id="grupo"
                                name="grupo"
                                value={formik.values.grupo}
                                onChange={formik.handleChange}
                                getCategoriaCompleta={selecionarCategoria}
                                apenasTitulo
                                categoriaTituloSelecionavel
                                className={classNames({ "p-invalid": formik.errors.grupo })}
                            />
                            {formik.errors.grupo && <small className="p-error">{formik.errors.grupo}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="descricao">Descrição contém: </label>
                            <InputText
                                id="descricao"
                                name="descricao"
                                value={formik.values.descricao}
                                onInput={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.descricao })}
                            />
                            {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                        </div>
                        <MakoInputCheckBox
                            id="preco_custo"
                            name="preco_custo"
                            checked={formik.values.preco_custo}
                            onChange={formik.handleChange}
                            label="Preço de custo"
                        />
                    </div>
                    <div className="p-grid p-col-12 p-md-6 p-mt-2">
                        <Button
                            label="Gerar PDF"
                            icon="pi pi-file-pdf"
                            type="button"
                            onClick={() => handlePreSubmit()}
                            className="p-button-info p-mr-2"
                        />
                        <Button
                            type="button"
                            onClick={() => setVisible(true)}
                            label="Enviar por email"
                            icon="pi pi-envelope"
                            className="p-button-info p-mr-2"
                        />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            onClick={limparFiltro}
                            className="p-button-warning p-mr-2"
                        />
                    </div>
                </div>
            </div>
            <Dialog
                visible={visible}
                blockScroll
                onHide={fecharModal}
                style={{ width: "60vw" }}
                header="Informar emails"
            >
                <MakoSelecionarEmails onCancel={fecharModal} onConfirm={handleConfirm} />
            </Dialog>
        </div>
    );
};
