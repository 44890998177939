import React, { useState, useRef, useCallback, useEffect, useMemo } from "react";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import useParam from "@/hooks/useParam";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import {
    FINANCEIRO_OBRIGAORCAMENTO,
    FINANCEIRO_PAGAMENTO_EXIGEPREVISAOA,
    FINANCEIRO_PAGAMENTO_PERMITEFRACIONAMENTO,
} from "@/assets/constants/parametros";
import useToast from "@/hooks/useToast";
import { FiltroPagamentosModalForm } from "./modals/filtroAvancadoModalForm";
import { Menu } from "primereact/menu";
import { PageBase } from "@/components/PageBase";
import { MakoCardFinanceiro } from "@/components/MakoCards";
import useEmpresa from "@/hooks/useEmpresa";
import { axiosGet } from "@/services/http";
import { Dropdown } from "@/components/Dropdown";
import { FILTRO_INDICADORES } from "@/assets/constants/financeiro";
import {
    ClienteFiltroTemplate,
    CodigoFiltroTemplate,
    DateFiltroTemplate,
    MoedaFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";

export const FinanceiroPagamentosPage = () => {
    const [indicadores, setIndicadores] = useState(null);
    const [filtroIndicadores, setFiltroIndicadores] = useState("mes");
    const [loadingIndicadores, setLoadingIndicadores] = useState(false);
    const [totalizadorFiltros, setTotalizadorFiltros] = useState(0);
    const [filtroAvancado, setFiltroAvancado] = useState(false);
    const [pagamento, sePagamento] = useState(false);
    const [parcelasSelecionadas, setParcelasSelecionadas] = useState([]);
    const [url, setUrl] = useState("/financeiro/pagamentos/");
    const { getParam } = useParam();
    const listagemRef = useRef(null);
    const menuRef = useRef(null);
    const history = useHistory();
    const { showWarning, showError } = useToast();
    const { empresaSelecionadaId } = useEmpresa();

    const actionBodyTemplate = (rowData) => {
        const items = [
            {
                label: "Editar",
                icon: "pi pi-pencil",
                disabled: rowData.quitado,
                command: () =>
                    history.push({
                        pathname: "/financeiro/financeiro/pagamentos/form",
                        documento: pagamento.documento,
                    }),
            },
            {
                label: "Fracionar",
                icon: "fas fa-chart-pie",
                disabled: rowData.quitado,
                command: () =>
                    history.push({
                        pathname: "/financeiro/financeiro/pagamentos/fracionar",
                        state: pagamento,
                    }),
            },
            {
                label: "Cancelar",
                icon: "pi pi-times",
                disabled: rowData.quitado,
                // command: () => {
                //     setRecebimento(rowData);
                //     setConfirmDialog(true);
                // },
            },
        ];
        return (
            <div className="actions">
                <Menu model={items} popup ref={menuRef} />
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_PAGAMENTOS_EDITAR]}
                    componente={Button}
                    icon="pi pi-cog"
                    tooltip="Alterar cadastro de pagamento"
                    tooltipOptions={{ position: "left" }}
                    className="p-button-rounded p-button-info"
                    onClick={(e) => {
                        sePagamento(rowData);
                        menuRef.current?.toggle(e);
                    }}
                />
            </div>
        );
    };

    const bloqueiaEfetivacao = useMemo(() => {
        if (parcelasSelecionadas?.length > 0) {
            const credores = parcelasSelecionadas
                .map((parcela) => !parcela.quitado && parcela.credor?.id)
                .filter(Boolean);
            return [...new Set(credores)].length === 1 ? false : true;
        }
        return true;
    }, [parcelasSelecionadas]);

    const painelEsquerdo = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_FINANCEIRO_PAGAMENTOS_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2 p-mb-2"
                onClick={() => history.push("/financeiro/financeiro/pagamentos/form")}
            />
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_FINANCEIRO_PAGAMENTOEFETIVADO_INCLUIR]}
                componente={Button}
                disabled={bloqueiaEfetivacao}
                label="Pagar"
                icon="pi pi-dollar"
                className="p-button-info p-mr-2 p-mb-2"
                onClick={() =>
                    history.push({
                        pathname: "/financeiro/financeiro/pagamentos/efetivados/form",
                        state: parcelasSelecionadas,
                    })
                }
            />
            <Button
                label="Filtro Avançado"
                icon="pi pi-filter"
                className="p-button-help p-mr-2 p-mb-2"
                badge={totalizadorFiltros > 0 ? totalizadorFiltros : null}
                onClick={() => setFiltroAvancado(true)}
            />
        </>
    );

    const painelDireito = (
        <>
            <Dropdown
                options={FILTRO_INDICADORES}
                placeholder="Filtro de indicadores"
                value={filtroIndicadores}
                showClear={false}
                onChange={(e) => setFiltroIndicadores(e.value)}
            />
        </>
    );

    const numeroParcelaBodyTemplate = (rowData) => {
        return <span>{`${rowData.numero_parcela}/${rowData.quantidade_parcelas}`}</span>;
    };

    const verificaNomeCurto = (empresa) => {
        if (empresa.nome_curto) return empresa.nome_curto;
        return empresa.nome;
    };

    const colunas = [
        { selectionMode: "multiple", style: { width: "5%" } },
        {
            field: "id",
            header: "Pagamento",
            minWidth: { width: "12%" },
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        // {
        //     field: "empresa.nome",
        //     header: "Empresa",
        //     minWidth: { minWidth: "15%" },
        //     action: (e) => verificaNomeCurto(e.empresa),
        // },
        {
            field: "documento",
            header: "Documento",
            filter: true,
            filterElement: TextoFiltroTemplate,
            minWidth: { width: "10%" },
        },
        {
            field: "numero_parcela",
            header: "Parcela",
            filter: true,
            filterElement: CodigoFiltroTemplate,
            action: (e) => numeroParcelaBodyTemplate(e),
        },
        {
            field: "data_emissao",
            header: "Emissão",
            filter: true,
            filterElement: DateFiltroTemplate,
            minWidth: { width: "10%" },
            dateFormat: "dd/MM/yyyy",
        },
        {
            field: "valor",
            header: "Valor",
            money: true,
            filter: true,
            filterElement: MoedaFiltroTemplate,
        },
        {
            field: "credor.nome",
            header: "Credor",
            filter: true,
            filterElement: ClienteFiltroTemplate,
            filterField: "credor",
            minWidth: { width: "15%" },
        },
        {
            field: "vencimento",
            header: "Vencimento",
            filter: true,
            filterElement: DateFiltroTemplate,
            minWidth: { width: "10%" },
            dateFormat: "dd/MM/yyyy",
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            minWidth: { width: "6%" },
        },
    ];

    const rowClass = (pagamento) => {
        const dias_atraso = pagamento.dias_atraso;
        if (pagamento.cancelado) return "table-pagamentos-overdue";
        else if (pagamento.quitado) return "table-pagamentos-effective";
        else if (!pagamento.quitado && dias_atraso > 0) return "table-pagamentos-maturity";
        else return "table-pagamentos-pending";
    };

    const verificaParametros = useCallback(async () => {
        try {
            const exigePrevisao = getParam(FINANCEIRO_PAGAMENTO_EXIGEPREVISAOA);
            if (!exigePrevisao) {
                showWarning({
                    summary: "Parâmetro não configurado!",
                    detail: "O parâmetro 'financeiro.pagamento.exigeprevisao' não foi configurado! Por favor, verifique e tente novamente.",
                    sticky: true,
                });
            }
        } catch (error) {
            showWarning({
                summary: "Parâmetro não configurado!",
                detail: "O parâmetro 'financeiro.pagamento.exigeprevisao' não foi configurado! Por favor, verifique e tente novamente.",
                sticky: true,
            });
        }
        try {
            const obrigaOrcamento = getParam(FINANCEIRO_OBRIGAORCAMENTO);
            if (!obrigaOrcamento) {
                showWarning({
                    summary: "Parâmetro não configurado!",
                    detail: "O parâmetro 'financeiro.obrigaorcamento' não foi configurado! Por favor, verifique e tente novamente.",
                    sticky: true,
                });
            }
        } catch (error) {
            showWarning({
                summary: "Parâmetro não configurado!",
                detail: "O parâmetro 'financeiro.obrigaorcamento' não foi configurado! Por favor, verifique e tente novamente.",
                sticky: true,
            });
        }
        try {
            const permiteFracionamento = getParam(FINANCEIRO_PAGAMENTO_PERMITEFRACIONAMENTO);
            if (!permiteFracionamento) {
                showWarning({
                    summary: "Parâmetro não configurado!",
                    detail: "O parâmetro 'financeiro.pagamento.permitefracionamento' não foi configurado! Por favor, verifique e tente novamente.",
                    sticky: true,
                });
            }
        } catch (error) {
            showWarning({
                summary: "Parâmetro não configurado!",
                detail: "O parâmetro 'financeiro.pagamento.permitefracionamento' não foi configurado! Por favor, verifique e tente novamente.",
                sticky: true,
            });
        }
    }, [getParam, showWarning]);

    useEffect(() => {
        verificaParametros();
    }, [verificaParametros]);

    const getIndicadoresPagamento = useCallback(async () => {
        if (!empresaSelecionadaId || !filtroIndicadores) return;
        const params = {
            empresa: empresaSelecionadaId,
            filtro: filtroIndicadores,
        };
        setLoadingIndicadores(true);
        const { status, data } = await axiosGet("/financeiro/indicadores/pagamentos/", { params });
        setLoadingIndicadores(false);
        if (status === 200) {
            setIndicadores(data.result);
        } else if (status !== 500 && data?.msg) {
            showWarning({
                summary: "Ops",
                detail: data.msg,
                life: 3000,
            });
        } else {
            showError({
                summary: "Falha nos indicadores",
                detail: "Desculpe, não foi possível exibir os indicadores dos pagamentos.",
                life: 3000,
            });
        }
    }, [empresaSelecionadaId, filtroIndicadores, showWarning, showError]);

    const onFinish = (url, contador) => {
        setUrl(url);
        setTotalizadorFiltros(contador);
    };

    useEffect(() => {
        getIndicadoresPagamento();
    }, [getIndicadoresPagamento]);

    return (
        <>
            <div className="p-fluid p-grid">
                <div className="p-col-12 p-md-3">
                    <MakoCardFinanceiro blocked={loadingIndicadores} title="A pagar" value={indicadores?.a_pagar} />
                </div>
                <div className="p-col-12 p-md-3">
                    <MakoCardFinanceiro
                        blocked={loadingIndicadores}
                        title="Para hoje"
                        value={indicadores?.pagar_hoje}
                    />
                </div>
                <div className="p-col-12 p-md-3">
                    <MakoCardFinanceiro blocked={loadingIndicadores} title="Pago hoje" value={indicadores?.pago_hoje} />
                </div>
                <div className="p-col-12 p-md-3">
                    <MakoCardFinanceiro
                        blocked={loadingIndicadores}
                        title="Total pago"
                        value={indicadores?.total_pago}
                    />
                </div>
            </div>
            <PageBase>
                <MakoListagem
                    ref={listagemRef}
                    titulo="Pagamentos"
                    colunas={colunas}
                    painelEsquerdo={painelEsquerdo}
                    painelDireito={painelDireito}
                    urlPesquisa={url}
                    botaoExportar
                    filtarPorEmpresa
                    naoBuscarSemEmpresa
                    configTabela={{
                        selection: parcelasSelecionadas,
                        onSelectionChange: (e) => setParcelasSelecionadas(e.value),
                        selectionMode: "multiple",
                        rowClassName: rowClass,
                        paginator: true,
                        lazy: true,
                        scrollable: true,
                    }}
                    filtros={{
                        id: { value: null, matchMode: "equals" },
                        documento: { value: null, matchMode: "equals" },
                        numero_parcela: { value: null, matchMode: "equals" },
                        data_emissao: { value: null, matchMode: "equals" },
                        vencimento: { value: null, matchMode: "equals" },
                        valor: { value: null, matchMode: "equals" },
                        credor: { value: null, matchMode: "equals" },
                    }}
                />
                <FiltroPagamentosModalForm
                    exibirDialog={filtroAvancado}
                    setUrl={onFinish}
                    setExibirDialog={setFiltroAvancado}
                />
            </PageBase>
        </>
    );
};
