import React, { useRef } from "react";
import { Button } from "primereact/button";

import { Delete } from "../Delete";

export const BotaoDelete = ({
    url,
    objetoId,
    disabled,
    exigeConfirmacao,
    msgConfirmacao,
    onDelete,
    msgToastErroExclusao,
    msgErro400,
    tooltip,
    tooltipOptions,
    classNames,
}) => {
    const deleteRef = useRef(null);

    return (
        <>
            <Button
                type="button"
                icon="pi pi-trash"
                disabled={disabled}
                tooltip={tooltip}
                tooltipOptions={tooltipOptions}
                className={`p-button-rounded p-button-danger ${classNames}`}
                onClick={() => deleteRef.current?.handleClick()}
            />
            <Delete
                ref={deleteRef}
                url={url}
                objetoId={objetoId}
                exigeConfirmacao={exigeConfirmacao}
                msgConfirmacao={msgConfirmacao}
                onDelete={onDelete}
                msgErro400={msgErro400}
                msgToastErroExclusao={msgToastErroExclusao}
            />
        </>
    );
};
