import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { Dropdown } from "@/components/Dropdown";
import MakoListagem from "@/components/MakoListagem";
import { formatarCasasDecimais } from "@/assets/util/util";
import { calcularJurosComposto } from "@/assets/util/calcFinanceiros";

const RecebimentoEfetivadoComponente = ({ onConfirmar }, ref) => {
    const [visible, setVisible] = useState(false);
    const [tipoDesconto, setTipoDesconto] = useState("percent");
    const [formasRecebimentoRateio, setFormasRecebimentoRateio] = useState([]);
    const [formaRecebimento, setFormaRecebimento] = useState(null);
    const [errorFormaRecebRateio, setErrorFormaRecebRateio] = useState(null);
    const [valor, setValor] = useState(0);
    const formasRecebimentoRef = useRef(null);

    const { setValues, setFieldError, setFieldValue, ...formik } = useFormik({
        initialValues: {
            documento: "",
            parcela: "",
            data_emissao: null,
            vencimento: null,
            dias: 0,
            valor: 0,
            descontos: 0,
            descontos_percent: 0,
            multa: 0,
            juros: 0,
            recebido: 0,
        },
        onSubmit: handleSubmit,
    });

    const validarDescontos = (values) => {
        if (values.descontos > 0) {
            const descontoOriginal = formatarCasasDecimais(values.valor * values.percent_descontos);
            return !(values.descontos > descontoOriginal);
        }
        return true;
    };

    const validarMulta = (values) => {
        if (values.multa > 0) {
            const multaOriginal = formatarCasasDecimais(values.valor * values.percent_multa);
            const multaDescMax = formatarCasasDecimais((multaOriginal * values.percent_multa_desc_max) / 100);
            return !(values.percent_multa < multaOriginal - multaDescMax);
        }
        return true;
    };

    const validarJuros = (values) => {
        if (values.juros > 0) {
            const { valor, percent_juros, dias, percent_juros_desc_max } = values;
            const jurosOriginal = formatarCasasDecimais(calcularJurosComposto(valor, percent_juros, dias, false));
            const jurosDescMax = formatarCasasDecimais((jurosOriginal * percent_juros_desc_max) / 100);
            return !(values.percent_juros < jurosOriginal - jurosDescMax);
        }
        return true;
    };

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descontos: Yup.number().test(
                    "Descontos validation",
                    "O valor de desconto concedido não é permitido.",
                    () => validarDescontos(values)
                ),
                multa: Yup.number().test("multa validation", "O valor aplicado de multa não é permitido.", () =>
                    validarMulta(values)
                ),
                juros: Yup.number().test("juros validation", "O valor aplicado de juros não é permitido.", () =>
                    validarJuros(values)
                ),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            const totalRateio = formasRecebimentoRateio.reduce((total, atual) => total + atual.valor, 0);
            if (totalRateio > values.recebido) {
                throw new Yup.ValidationError(
                    "A soma dos valores está maior que o total a ser recebido.",
                    null,
                    "rateio"
                );
            } else if (totalRateio < values.recebido) {
                throw new Yup.ValidationError(
                    "A soma dos valores está menor que o total a ser recebido.",
                    null,
                    "rateio"
                );
            }
            if (typeof onConfirmar === "function") {
                onConfirmar({
                    ...values,
                    forma_recebimento: formasRecebimentoRateio,
                });
            }
            setVisible(false);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                    if (err.path === "rateio") {
                        setErrorFormaRecebRateio(err.message);
                    }
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const abrirModal = (parcela) => {
        setVisible(true);
        if (parcela) {
            const { numero_parcela, quantidade_parcelas } = parcela;
            setValues({
                ...parcela,
                parcela: numero_parcela === 0 ? "ENTRADA" : `${numero_parcela}/${quantidade_parcelas}`,
                descontos_percent: 0,
            });
        }
    };

    useImperativeHandle(ref, () => {
        return {
            abrirModal,
        };
    });

    const calcularDescontos = useCallback(() => {
        if (formik.values.descontos > 0) {
            const pDescontos = (formik.values.descontos * 100) / formik.values.valor;
            if (pDescontos > formik.values.percent_descontos) {
                setFieldError("descontos_percent", "O valor de desconto concedido ultrapassa a taxa máxima.");
            }
        }
    }, [formik.values.descontos, formik.values.valor, formik.values.percent_descontos, setFieldError]);

    const calcularDescontosPercent = useCallback(() => {
        if (formik.values.descontos_percent > 0) {
            if (formik.values.descontos_percent <= formik.values.percent_descontos) {
                const descontos = formik.values.valor * (formik.values.descontos_percent / 100);
                setFieldValue("descontos", descontos);
                setTipoDesconto("valor");
            } else {
                setFieldError("descontos_percent", "Taxa de desconto não permitida.");
            }
        }
    }, [
        formik.values.descontos_percent,
        formik.values.percent_descontos,
        formik.values.valor,
        setFieldError,
        setFieldValue,
    ]);

    const calcularMulta = useCallback(() => {
        if (formik.values.multa > 0) {
            const multaOriginal = formatarCasasDecimais(formik.values.valor * formik.values.percent_multa);
            const pMulta = 100 - (100 * formik.values.multa) / multaOriginal;
            if (pMulta > formik.values.percent_multa_desc_max) {
                setFieldError("multa", "O valor aplicado de multa não é permitido.");
            } else {
                setFieldError("multa", null);
            }
        }
    }, [
        formik.values.multa,
        formik.values.valor,
        formik.values.percent_multa,
        formik.values.percent_multa_desc_max,
        setFieldError,
    ]);

    const calcularJuros = useCallback(() => {
        if (formik.values.juros > 0) {
            const jurosOriginal = formatarCasasDecimais(formik.values.valor * formik.values.percent_juros);
            const pJuros = 100 - (100 * formik.values.juros) / jurosOriginal;
            if (pJuros > formik.values.percent_juros_desc_max) {
                setFieldError("juros", "O valor aplicado de juros não é permitido.");
            } else {
                setFieldError("juros", null);
            }
        }
    }, [
        formik.values.juros,
        formik.values.valor,
        formik.values.percent_juros,
        formik.values.percent_juros_desc_max,
        setFieldError,
    ]);

    const somarTotalRecebimento = useCallback(() => {
        const total = formik.values.valor + formik.values.multa + formik.values.juros - formik.values.descontos;
        setFieldValue("recebido", formatarCasasDecimais(total));
        setFormasRecebimentoRateio([]);
    }, [formik.values.valor, formik.values.multa, formik.values.juros, formik.values.descontos, setFieldValue]);

    useEffect(() => {
        somarTotalRecebimento();
    }, [somarTotalRecebimento]);

    useEffect(() => {
        if (formik.values.forma_recebimento) {
            setFormasRecebimentoRateio(formik.values.forma_recebimento);
        }
    }, [formik.values.forma_recebimento]);

    const incluirRateioFormaRecebimento = () => {
        const total = formasRecebimentoRateio.reduce((total, atual) => total + atual.valor, 0);
        if (total + valor <= formik.values.recebido) {
            let rateios = [...formasRecebimentoRateio];
            const _formaRecebimento = formasRecebimentoRef.current?.find((fr) => fr.id === formaRecebimento);
            rateios.push({ ..._formaRecebimento, valor });
            setFormasRecebimentoRateio(rateios);
            setErrorFormaRecebRateio(null);
        } else {
            setErrorFormaRecebRateio("A soma total ultrapassa o valor a ser recebido.");
        }
    };

    const excluirRateioFormaRecebimento = (index) => {
        const rateios = [...formasRecebimentoRateio];
        rateios.splice(index, 1);
        setFormasRecebimentoRateio(rateios);
    };

    const actionBodyTemplate = (rowData, rowInfo) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    onClick={() => excluirRateioFormaRecebimento(rowInfo.rowIndex)}
                />
            </div>
        );
    };

    const colunas = [
        { field: "descricao", header: "Forma de recebimento" },
        { field: "valor", header: "Valor", style: { width: "30%" }, money: true },
        {
            field: "actions",
            header: "Ações",
            style: { width: "10%" },
            action: (e, i) => actionBodyTemplate(e, i),
        },
    ];

    const rodape = () => {
        return (
            <div>
                <Button type="submit" label="Confirmar" icon="pi pi-save" onClick={formik.handleSubmit} autoFocus />
            </div>
        );
    };

    return (
        <Dialog
            visible={visible}
            header="Editar item do recebimento"
            footer={rodape}
            onHide={() => setVisible(false)}
            style={{ width: "60vw" }}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="documento">Documento</label>
                        <InputText id="documento" name="documento" disabled value={formik.values.documento} />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="parcela">Parcela</label>
                        <InputText id="parcela" name="parcela" disabled value={formik.values.parcela} />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="data-emissao">Emissão</label>
                        <MakoCalendar
                            id="data-emissao"
                            name="data_emissao"
                            disabled
                            valueCalendar={formik.values.data_emissao}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="data-vencimento">Vencimento</label>
                        <MakoCalendar
                            id="data-vencimento"
                            name="vencimento"
                            disabled
                            valueCalendar={formik.values.vencimento}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="dias">Dias de atraso</label>
                        <InputText id="dias" name="dias" disabled value={formik.values.dias} />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="original">Valor original</label>
                        <MakoInputMoeda id="original" disabled valueMoeda={formik.values.valor} />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="total-descontos">Descontos</label>
                        <div className="p-inputgroup">
                            {tipoDesconto === "percent" ? (
                                <>
                                    <InputNumber
                                        inputId="total-descontos"
                                        name="descontos_percent"
                                        suffix=" %"
                                        minFractionDigits={2}
                                        value={formik.values.descontos_percent}
                                        onChange={(e) => setFieldValue("descontos_percent", e.value)}
                                        onBlur={calcularDescontosPercent}
                                        className={classNames({ "p-invalid": formik.errors.descontos_percent })}
                                    />
                                    <Button
                                        icon="pi pi-dollar"
                                        type="button"
                                        className="p-button-help"
                                        tooltip="Conceder desconto por valor"
                                        tooltipOptions={{ position: "top" }}
                                        onClick={() => setTipoDesconto("valor")}
                                    />
                                </>
                            ) : (
                                <>
                                    <MakoInputMoeda
                                        id="total-descontos"
                                        name="descontos"
                                        valueMoeda={formik.values.descontos}
                                        onChange={(e) => setFieldValue("descontos", e.value)}
                                        onBlur={calcularDescontos}
                                        className={classNames({ "p-invalid": formik.errors.descontos_percent })}
                                    />
                                    <Button
                                        icon="pi pi-percentage"
                                        type="button"
                                        tooltip="Conceder desconto por porcentagem"
                                        tooltipOptions={{ position: "top" }}
                                        className="p-button-help"
                                        onClick={() => setTipoDesconto("percent")}
                                    />
                                </>
                            )}
                        </div>
                        {formik.errors.descontos_percent && (
                            <small className="p-error">{formik.errors.descontos_percent}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="total-multa">Valor da multa</label>
                        <MakoInputMoeda
                            id="total-multa"
                            valueMoeda={formik.values.multa}
                            onChange={(e) => setFieldValue("multa", e.value)}
                            onBlur={calcularMulta}
                            className={classNames({ "p-invalid": formik.errors.multa })}
                        />
                        {formik.errors.multa && <small className="p-error">{formik.errors.multa}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="total-juros">Valor dos juros</label>
                        <MakoInputMoeda
                            id="total-juros"
                            valueMoeda={formik.values.juros}
                            onChange={(e) => setFieldValue("juros", e.value)}
                            onBlur={calcularJuros}
                            className={classNames({ "p-invalid": formik.errors.juros })}
                        />
                        {formik.errors.juros && <small className="p-error">{formik.errors.juros}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="total-recebimento">Total a receber</label>
                        <MakoInputMoeda id="total-recebimento" disabled valueMoeda={formik.values.recebido} />
                    </div>
                </div>
            </form>
            <Divider align="center">
                <b>Formas de recebimento</b>
            </Divider>
            {errorFormaRecebRateio && <small className="p-error">{errorFormaRecebRateio}</small>}
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-6">
                    <Dropdown
                        id="forma-recebimento"
                        placeholder="Selecione a forma de recebimento"
                        url="/financeiro/formas-recebimentos/?limit=100"
                        setObjects={(e) => (formasRecebimentoRef.current = e)}
                        optionValue="id"
                        optionLabel="descricao"
                        value={formaRecebimento}
                        onChange={(e) => setFormaRecebimento(e.value)}
                    />
                </div>
                <div className="p-field p-col-12 p-md-5">
                    <MakoInputMoeda id="valor" valueMoeda={valor} onChangeMoeda={(e) => setValor(e.value)} />
                </div>
                <div className="p-field p-col-12 p-md-1">
                    <Button
                        icon="pi pi-plus"
                        className="p-button-success"
                        onClick={() => incluirRateioFormaRecebimento()}
                    />
                </div>
            </div>
            <MakoListagem
                dadosLocal={formasRecebimentoRateio}
                colunas={colunas}
                configTabela={{
                    paginator: true,
                }}
            />
        </Dialog>
    );
};

export const ModalRecebimentoEfetivado = forwardRef(RecebimentoEfetivadoComponente);
