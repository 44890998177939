import React, { memo, useCallback, useRef, useMemo, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { DataScroller } from "primereact/datascroller";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";

import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";

import { PESSOAS_EMAILS_TIPOS_IDENTIFICACAO } from "@/assets/constants/parametros";
import { OP_CRUD_DJANGO } from "@/assets/util/persistenciaDjango";

import usePessoa from "@/hooks/usePessoa";
import useParam from "@/hooks/useParam";
import useToast from "@/hooks/useToast";

const EmailForm = () => {
    const [email, setEmail] = useState(null);

    const { handleEmail, handleEmailPrincipal, emails, checarInformacao } = usePessoa();
    const { showError, showSuccess } = useToast();
    const { getParam } = useParam();

    const menuAcoesRef = useRef(null);

    const { setValues, ...formik } = useFormik({
        initialValues: {
            identificacao: "",
            email: "",
            principal: false,
            ativo: true,
            data_alteracao: new Date(),
            _status: OP_CRUD_DJANGO.novo,
        },
        onSubmit: handleSubmit,
    });

    const tiposEmail = useMemo(() => {
        const tiposEmailParam = getParam(PESSOAS_EMAILS_TIPOS_IDENTIFICACAO);
        if (tiposEmailParam) {
            const tiposEmaill = tiposEmailParam.valor.split("|");
            return tiposEmaill.map((tipo) => ({ id: tipo, label: tipo }));
        }
        return [];
    }, [getParam]);

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                identificacao: Yup.string().required("O campo 'identificação do email' é obrigatório."),
                email: Yup.string().email("Digite um email válido.").required("O campo 'email' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            handleEmail(values, values._status);
            formik.resetForm();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const alterarDataAtualizacaoEmail = useCallback(
        async (id) => {
            const resposta = await checarInformacao("emails", id);

            if (resposta === 200) {
                showSuccess({
                    summary: "Sucesso!",
                    detail: "E-mail verificado com sucesso.",
                    life: 1500,
                });
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "A sua requisição não pode ser concluída.",
                    life: 3000,
                });
            }
        },
        [checarInformacao]
    );

    const itensMenuAcoes = useMemo(() => {
        return [
            {
                label: "Checar email",
                icon: "pi pi-check",
                disabled: !!!email?.id,
                command: () => alterarDataAtualizacaoEmail(email?.id),
            },
            {
                label: "Definir como prinicipal",
                icon: "pi pi-star-fill",
                command: () => handleEmailPrincipal(email),
            },
            {
                label: email?.ativo ? "Desativar email" : "Ativar email",
                icon: "pi pi-ban",
                command: () => handleEmail({ ...email, ativo: !email?.ativo }, OP_CRUD_DJANGO.editar),
            },
            {
                label: "Excluir email",
                icon: "pi pi-trash",
                command: () => handleEmail(email, OP_CRUD_DJANGO.deletar),
            },
        ];
    }, [email]);

    const itemTemplate = useCallback(
        (data) => {
            return (
                <div className="product-list-item">
                    <div className="product-list-detail">
                        <span className={`product-badge status-${data.principal ? "instock" : "lowstock"}`}>
                            {data.principal ? "Principal" : "Alternativo"}
                        </span>
                        <div className="product-name">{data.email}</div>
                        <i className="pi pi-tag product-category-icon" />
                        <span className="product-category">{data.identificacao}</span>
                    </div>
                    <div className="product-list-action">
                        <div className="p-text-right">
                            <Button
                                icon="pi pi-pencil"
                                className="p-button-rounded p-button-warning p-mr-2"
                                onClick={() => {
                                    setValues({
                                        ...data,
                                        _status: OP_CRUD_DJANGO.editar,
                                    });
                                }}
                            />
                            <Menu model={itensMenuAcoes} popup ref={menuAcoesRef} id="popup_menu" />
                            <Button
                                icon="pi pi-cog"
                                aria-haspopup
                                aria-controls="popup_menu_acoes"
                                className="p-button-rounded p-button-info"
                                onClick={(e) => {
                                    setEmail(data);
                                    menuAcoesRef.current?.toggle(e);
                                }}
                            />
                        </div>
                        {(data._id || (data._status && data._status !== OP_CRUD_DJANGO.novo)) && (
                            <div className="product-description p-mb-0" style={{ color: "#f00" }}>
                                **Clique em <b>Finalizar</b> para confirmar**
                            </div>
                        )}
                    </div>
                </div>
            );
        },
        [setValues, alterarDataAtualizacaoEmail, handleEmail, handleEmailPrincipal, itensMenuAcoes]
    );

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="identificacao">Identificação do e-mail *</label>
                        {tiposEmail.length > 0 ? (
                            <Dropdown
                                id="identificacao"
                                name="identificacao"
                                options={tiposEmail}
                                optionValue="id"
                                optionLabel="label"
                                value={formik.values.identificacao}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.identificacao })}
                            />
                        ) : (
                            <InputText
                                id="identificacao"
                                name="identificacao"
                                value={formik.values.identificacao}
                                onChange={formik.handleChange}
                                placeholder="Sugestão: Pessoal | Profissional"
                                className={classNames({ "p-invalid": formik.errors.identificacao })}
                            />
                        )}
                        {formik.errors.identificacao && (
                            <small className="p-error">{formik.errors.identificacao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="email">E-mail *</label>
                        <InputText
                            id="email"
                            name="email"
                            type="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.email })}
                        />
                        {formik.errors.email && <small className="p-error">{formik.errors.email}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="ult-atualizacao">Última atualização</label>
                        <MakoCalendar
                            id="ult-atualizacao"
                            name="data_alteracao"
                            disabled
                            showIcon={false}
                            valueCalendar={formik.values.data_alteracao}
                        />
                    </div>
                </div>
                {emails.length === 0 && (
                    <div className="p-fluid p-formgrid p-grid p-mb-2">
                        <div className="p-field-checkbox p-col-12 p-md-3 p-mt-2">
                            <Checkbox
                                id="principal"
                                name="principal"
                                checked={formik.values.principal}
                                onChange={formik.handleChange}
                            />
                            <label htmlFor="principal">Email principal?</label>
                        </div>
                    </div>
                )}
                <CamposObrigatorios />
                <p className="p-error">* Lembre-se de gravar os dados antes de prosseguir ou finalizar</p>
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button type="submit" icon="pi pi-check" label="Gravar" className="p-mr-2 p-mb-2" />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            className="p-button-warning p-mr-2 p-mb-2"
                            onClick={() => formik.resetForm()}
                        />
                    </div>
                </div>
            </form>
            <div className="list-demo">
                <DataScroller
                    value={emails}
                    itemTemplate={itemTemplate}
                    rows={3}
                    inline
                    scrollHeight="300px"
                    header="E-mails cadastrados"
                    emptyMessage="Nenhum e-mail encontrado"
                />
            </div>
        </>
    );
};

export default memo(EmailForm);
