import React, { useEffect, useMemo } from "react";
import classNames from "classnames";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoInputPorcentagem } from "@/components/MakoInputs/MakoInputPorcentagem";
import { Dropdown } from "@/components/Dropdown";
import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoInputFornecedor } from "@/components/MakoInputs/MakoInputFornecedor";
import { MakoInputRepresentante } from "@/components/MakoInputs/MakoInputRepresentante";
import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { COMPRAS_ENTRADA_CATEGORIA_CLASSIFICATORIA } from "@/assets/constants/parametros";
import { TIPO_STATUS_ORDEM_COMPRA_CHOICE } from "@/assets/constants/constants";
import useOrdemCompra from "@/hooks/useOrdemCompra";
import useParam from "@/hooks/useParam";

export const DadosBasicosOrdemCompraForm = () => {
    const { getParam } = useParam();
    const { dadosBasicos, permiteAlterar, handleDadosBasicos } = useOrdemCompra();

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: {
            categoria: null,
            categoria_item: null,
            fornecedor: null,
            representante: null,
            data_pedido: new Date(),
            valor_antecipado: 0,
            vencimento_antecipacao: null,
            status: "PE",
            percentual_frete_emitente: 0,
            valor_frete_previsto: 0,
            percentual_frete_destinatario: 0,
            valor_total_ipi: 0,
            valor_total_icms: 0,
            valor_total_icms_st: 0,
            quantidade_itens: 0,
            valor_total_itens: 0,
            custos_extras: 0,
            quantidade_servicos: 0,
            valor_total_servicos: 0,
            valor_total_ordem_compra: 0,
            usuario_cadastro: null,
            usuario_aprovacao: null,
            data_confirmacao: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                fornecedor: Yup.object()
                    .shape({
                        id: Yup.string().required("O campo 'fornecedor' é obrigatório."),
                    })
                    .typeError("Escolha um fornecedor"),
                data_pedido: Yup.date()
                    .required("O campo 'data do pedido' é obrigatório.")
                    .typeError("Informe um data válida."),
                valor_antecipado: Yup.number().required("O campo 'valor antecipado' é obrigatório."),
                valor_total_ipi: Yup.number().required("O campo 'valor total IPI' é obrigatório."),
                valor_total_icms: Yup.number().required("O campo 'valor total icms' é obrigatório."),
                // percentual_frete_emitente: Yup.number()
                //     .required("O campo 'frete do emitente' é obrigatório.")
                //     .test({
                //         message: "Os percentuais de frete devem totalizar 100%.",
                //         test: (value) => value + values.percentual_frete_destinatario === 100,
                //     }),
                // percentual_frete_destinatario: Yup.number()
                //     .required("O campo 'frete do destinatário' é obrigatório.")
                //     .test({
                //         message: "Os percentuais de frete devem totalizar 100%.",
                //         test: (value) => value + values.percentual_frete_emitente === 100,
                //     }),
                // valor_frete_previsto: Yup.number().required("O campo 'valor do frete previsto' é obrigatório."),
                categoria: Yup.number()
                    .required("O campo 'categoria' é obrigatório.")
                    .typeError("Selecione uma categoria de ordem de compra."),
                categoria_item: Yup.number()
                    .required("O campo 'categoria do item' é obrigatório.")
                    .typeError("Selecione uma categoria do item"),
            });
            await formSchema.validate(values, { abortEarly: false });
            handleDadosBasicos(values);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    useEffect(() => {
        if (dadosBasicos) {
            setValues({
                ...dadosBasicos,
                percentual_frete_destinatario: Number(dadosBasicos.percentual_frete_destinatario),
                percentual_frete_emitente: Number(dadosBasicos.percentual_frete_emitente),
            });
        }
    }, [dadosBasicos, setValues]);

    const categoriaClassificatoria = useMemo(() => {
        const param = getParam(COMPRAS_ENTRADA_CATEGORIA_CLASSIFICATORIA);
        if (param) return param.valor;
        return "none";
    }, [getParam]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="num-oc">Número da OC</label>
                    <InputText id="num-oc" disabled value={formik.values.id || "Nova"} />
                </div>
                <div className="p-field p-col-12 p-md-5">
                    <label htmlFor="fornecedor">Fornecedor *</label>
                    <MakoInputFornecedor
                        id="fornecedor"
                        name="fornecedor"
                        somenteAtivo
                        value={formik.values.fornecedor}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.fornecedor })}
                    />
                    {formik.errors.fornecedor && <small className="p-error">{formik.errors.fornecedor}</small>}
                </div>
                <div className="p-field p-col-12 p-md-5">
                    <label htmlFor="representante">Representante</label>
                    <MakoInputRepresentante
                        id="representante"
                        name="representante"
                        fornecedorId={formik.values.fornecedor?.id}
                        somenteAtivo
                        disabled={!formik.values.fornecedor}
                        value={formik.values.representante}
                        onChange={formik.handleChange}
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="data-pedido">Data do pedido *</label>
                    <MakoCalendar
                        id="data-pedido"
                        name="data_pedido"
                        valueCalendar={formik.values.data_pedido}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.data_pedido })}
                    />
                    {formik.errors.data_pedido && <small className="p-error">{formik.errors.data_pedido}</small>}
                </div>
                <div className="p-field p-col-12 p-md-5">
                    <label htmlFor="categoria">Categoria da ordem de compra *</label>
                    <Dropdown
                        id="categoria"
                        name="categoria"
                        url="/compras/categorias-ordens-compra/"
                        filter
                        filterby="nome"
                        optionValue="id"
                        optionLabel="nome"
                        value={formik.values.categoria}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.categoria })}
                    />
                    {formik.errors.categoria && <small className="p-error">{formik.errors.categoria}</small>}
                </div>
                <div className="p-field p-col-12 p-md-5">
                    <label htmlFor="categoria-item">Categoria do item *</label>
                    <MakoDropdownCategoriasHierarquicas
                        id="categoria-item"
                        name="categoria_item"
                        categoriaPai={categoriaClassificatoria}
                        categoriaTituloSelecionavel
                        apenasTitulo
                        onChange={formik.handleChange}
                        value={formik.values.categoria_item}
                        className={classNames({ "p-invalid": formik.errors.categoria_item })}
                    />
                    {formik.errors.categoria_item && <small className="p-error">{formik.errors.categoria_item}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="valor-antecipado">Valor antecipado</label>
                    <MakoInputMoeda
                        id="valor-antecipado"
                        name="valor_antecipado"
                        valueMoeda={formik.values.valor_antecipado}
                        onChangeMoeda={formik.handleChange}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="vencimento-antecipacao">Vencimento da antecipação</label>
                    <MakoCalendar
                        id="vencimento-antecipacao"
                        name="vencimento_antecipacao"
                        valueCalendar={formik.values.vencimento_antecipacao}
                        onChange={formik.handleChange}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="percentual-frete-emitente">Frete do emitente (%) *</label>
                    <MakoInputPorcentagem
                        id="percentual-frete-emitente"
                        name="percentual_frete_emitente"
                        disabled
                        minFractionDigits={4}
                        maxFractionDigits={4}
                        value={formik.values.percentual_frete_emitente}
                        onValueChange={formik.handleChange}
                        className={classNames({
                            "p-invalid": formik.errors.percentual_frete_emitente,
                        })}
                    />
                    {formik.errors.percentual_frete_emitente && (
                        <small className="p-error">{formik.errors.percentual_frete_emitente}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="percentual-frete-destinatario">Frete do destinatário (%) *</label>
                    <MakoInputPorcentagem
                        id="percentual-frete-destinatario"
                        name="percentual_frete_destinatario"
                        disabled
                        minFractionDigits={4}
                        maxFractionDigits={4}
                        value={formik.values.percentual_frete_destinatario}
                        onValueChange={formik.handleChange}
                        className={classNames({
                            "p-invalid": formik.errors.percentual_frete_destinatario,
                        })}
                    />
                    {formik.errors.percentual_frete_destinatario && (
                        <small className="p-error">{formik.errors.percentual_frete_destinatario}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="valor-frete-previsto">Valor do frete previsto *</label>
                    <MakoInputMoeda
                        id="valor-frete-previsto"
                        name="valor_frete_previsto"
                        disabled
                        valueMoeda={formik.values.valor_frete_previsto}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.valor_frete_previsto })}
                    />
                    {formik.errors.valor_frete_previsto && (
                        <small className="p-error">{formik.errors.valor_frete_previsto}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="quantidade-itens">Qtd. de itens</label>
                    <InputNumber
                        id="quantidade-itens"
                        name="quantidade_itens"
                        min={0}
                        disabled
                        value={formik.values.quantidade_itens}
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="valor-total-itens">Total dos itens</label>
                    <MakoInputMoeda
                        id="valor-total-itens"
                        name="valor_total_itens"
                        disabled
                        valueMoeda={formik.values.valor_total_itens}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="quantidade-servicos">Qtd. de serviços</label>
                    <InputNumber
                        id="quantidade-servicos"
                        name="quantidade_servicos"
                        min={0}
                        disabled
                        value={formik.values.quantidade_servicos}
                        onValueChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.quantidade_servicos })}
                    />
                    {formik.errors.quantidade_servicos && (
                        <small className="p-error">{formik.errors.quantidade_servicos}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="valor-total-servicos">Total dos serviços</label>
                    <MakoInputMoeda
                        id="valor-total-servicos"
                        name="valor_total_servicos"
                        disabled
                        valueMoeda={formik.values.valor_total_servicos}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.valor_total_servicos })}
                    />
                    {formik.errors.valor_total_servicos && (
                        <small className="p-error">{formik.errors.valor_total_servicos}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="valor-total-ipi">Valor total do IPI</label>
                    <MakoInputMoeda
                        id="valor-total-ipi"
                        name="valor_total_ipi"
                        disabled
                        valueMoeda={formik.values.valor_total_ipi}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="valor-total-icms">Valor total do ICMS</label>
                    <MakoInputMoeda
                        id="valor-total-icms"
                        name="valor_total_icms"
                        disabled
                        valueMoeda={formik.values.valor_total_icms}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="valor-total-icms-st">Total ICMS/ST</label>
                    <MakoInputMoeda
                        id="valor-total-icms-st"
                        name="valor_total_icms_st"
                        disabled
                        valueMoeda={formik.values.valor_total_icms_st}
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="custos-extras">Total custos extras</label>
                    <MakoInputMoeda
                        id="custos-extras"
                        name="custos_extras"
                        disabled
                        valueMoeda={formik.values.custos_extras}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="valor-total-ordem-compra">Valor total da OC</label>
                    <MakoInputMoeda
                        id="valor-total-ordem-compra"
                        name="valor_total_ordem_compra"
                        disabled
                        valueMoeda={formik.values.valor_total_ordem_compra}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="status">Status</label>
                    <Dropdown
                        id="status"
                        name="status"
                        placeholder="Selecione"
                        disabled
                        options={TIPO_STATUS_ORDEM_COMPRA_CHOICE}
                        optionValue="id"
                        optionLabel="label"
                        value={formik.values.status}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="usuario-cadastro">Usuário cadastro</label>
                    <InputText
                        id="usuario-cadastro"
                        name="usuario_cadastro"
                        disabled
                        value={formik.values.usuario_cadastro?.usuario?.username}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="usuario-aprovacao">Usuário validação</label>
                    <InputText
                        id="usuario-aprovacao"
                        name="usuario_aprovacao"
                        disabled
                        value={formik.values.usuario_aprovacao?.usuario?.username}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="data-confirmacao">Data de validação</label>
                    <MakoCalendar
                        id="data-confirmacao"
                        name="data_confirmacao"
                        disabled
                        valueCalendar={formik.values.data_confirmacao}
                    />
                </div>
            </div>
            <p>
                <b>* Campos obrigatórios.</b>
            </p>
            <div className="p-grid">
                <div className="p-col-12 p-md-6">
                    <Button
                        type="submit"
                        icon="pi pi-save"
                        disabled={!permiteAlterar}
                        label="Gravar"
                        className="p-mr-2 p-mb-2"
                    />
                    <Button
                        label="Limpar"
                        type="reset"
                        icon="pi pi-trash"
                        disabled={!permiteAlterar}
                        className="p-button-warning p-mr-2 p-mb-2"
                        onClick={() => formik.resetForm()}
                    />
                </div>
            </div>
        </form>
    );
};
