import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { RecebimentoLancamentoForm } from "./formLancamento";
import { RecebimentoParcelasForm } from "./formParcelas";
import { RecebimentoPrevisaoForm } from "./formPrevisao";
import { PageBase } from "@/components/PageBase";
import { MakoMultiTabs } from "@/components/MakoMultiTabs";
import useRecebimento from "@/hooks/useRecebimento";

export const TabRecebimentos = ({ agrupador }) => {
    const history = useHistory();
    const { loading, submit, submitParcelas, handlePersistirRecebimentos, handleBuscarRecebimentos } = useRecebimento();

    const TABS = [
        {
            header: "Lançamento",
            icon: "pi pi-fw pi-save",
            component: <RecebimentoLancamentoForm />,
        },
        {
            header: "Parcelas",
            icon: "pi pi-fw pi-copy",
            component: <RecebimentoParcelasForm />,
            disabled: !submit,
        },
        {
            header: "Previsão",
            icon: "pi pi-fw pi-calendar-times",
            component: <RecebimentoPrevisaoForm />,
            disabled: !submitParcelas,
        },
    ];

    const listarRecebimentos = useCallback(async () => {
        await handleBuscarRecebimentos(agrupador);
    }, [handleBuscarRecebimentos, agrupador]);

    useEffect(() => {
        listarRecebimentos();
    }, [listarRecebimentos]);

    const handleSubmit = useCallback(async () => {
        await handlePersistirRecebimentos();
    }, [handlePersistirRecebimentos]);

    return (
        <PageBase blocked={loading}>
            <MakoMultiTabs
                title="Recebimentos"
                tabs={TABS}
                propsButtonProx={{ disabled: !submit }}
                propsButtonFinalizar={{ label: "Gravar recebimentos", onClick: handleSubmit }}
                propsButtonCancelar={{ onClick: () => history.push("/financeiro/financeiro/recebimentos") }}
                ocultarButtonFinalizar={!submitParcelas}
            />
        </PageBase>
    );
};
