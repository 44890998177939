import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";

import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import { axiosDelete } from "@/services/http";
import { CodigoFiltroTemplate, NumeroFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import useClearRefs from "@/hooks/useClearRefs";
import useToast from "@/hooks/useToast";

const url = "/produtos/galerias-imagens-sku/";

export const ProdutoGaleriaImgSkuPage = () => {
    const [redirecionar, setRedirecionar] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);

    const [GaleriaImg, setGaleriaImg] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const { showError, showSuccess } = useToast();
    const listagemRef = useRef(null);
    useClearRefs(listagemRef)

    const confirmarDelete = (GaleriaImg) => {
        setGaleriaImg(GaleriaImg);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const deleteGaleria = async () => {
        showLoading();
        const resposta = await axiosDelete(`${url}${GaleriaImg.id}`);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            showSuccess({
                summary: "Sucesso",
                detail: "Galeria de imagem deletada com sucesso!",
                life: 3000,
            });

            listagemRef.current?.buscarDados();
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
        setDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_CADASTRO_GALERIAIMAGEMSKU_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de galeria de imagem"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        setRedirecionar({
                            pathname: "/produto/cadastros/galerias-imagens/form",
                            state: { GaleriaImg: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_CADASTRO_GALERIAIMAGEMSKU_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Deletar tag de imagem"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.PRODUTO_CADASTRO_GALERIAIMAGEMSKU_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setRedirecionar("/produto/cadastros/galerias-imagens/form")}
            />
        </>
    );

    const DimensaoFiltroTemplate = (options) => {
        return <NumeroFiltroTemplate options={options} inputProps={{ min: 0, showButtons: true }} />;
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "12%" }, filter: true, filterElement: CodigoFiltroTemplate },
        { field: "nome", header: "Nome", style: { width: "30%" }, filter: true, filterElement: TextoFiltroTemplate },
        {
            field: "altura",
            header: "Altura",
            style: { width: "10%" },
            filter: true,
            filterElement: DimensaoFiltroTemplate,
        },
        {
            field: "largura",
            header: "Largura",
            style: { width: "15%" },
            filter: true,
            filterElement: DimensaoFiltroTemplate,
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { paddingLeft: "2%", width: "20%" },
        },
    ];

    return redirecionar ? (
        <Redirect to={redirecionar} />
    ) : (
        <>
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <MakoListagem
                            ref={listagemRef}
                            titulo="Galerias de Imagens"
                            colunas={colunas}
                            painelEsquerdo={cabecalhoTabela}
                            urlPesquisa={url}
                            configTabela={{
                                paginator: true,
                                lazy: true,
                            }}
                            filtros={{ 
                                id: { value: null, matchMode: "equals" },
                                altura: { value: null, matchMode: "equals" },
                                largura: { value: null, matchMode: "equals" },
                                nome: {
                                    operator: "and",
                                    constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                                },
                            }}
                        />
                        <ConfirmDialog
                            visible={deleteDialog}
                            onHide={esconderDeleteDialog}
                            header="Confirmação"
                            message={
                                GaleriaImg && (
                                    <span>
                                        {"Deseja realmente excluir a galeria de imagem "}
                                        <b>{GaleriaImg.nome}</b>?
                                    </span>
                                )
                            }
                            icon="pi pi-info-circle p-mr-3"
                            accept={deleteGaleria}
                            acceptLabel="Sim"
                            acceptClassName="p-button-danger"
                            reject={esconderDeleteDialog}
                            rejectLabel="Não"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
