import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Dialog } from "primereact/dialog";

import { FinanceiroContaBancariaFormBase } from "../formBase";

const ContaBancariaComponente = ({ aposSalvar }, ref) => {
    const [visible, setVisible] = useState(false);

    useImperativeHandle(ref, () => {
        return {
            abrirModal: () => setVisible(true),
        };
    });

    return (
        <Dialog
            visible={visible}
            header="Nova conta bancária"
            style={{ width: "70vw" }}
            onHide={() => setVisible(false)}
        >
            <FinanceiroContaBancariaFormBase modal aposSalvar={aposSalvar} />
        </Dialog>
    );
};

export const ModalContaBancaria = forwardRef(ContaBancariaComponente);
