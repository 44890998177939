import React from "react";
import { useHistory } from "react-router-dom";

import { ParametrosGeraisForm } from "./formParametros";
import { NcmForm } from "./formNcm";
import { ProdutosMercadoriasForm } from "./formProdutosMercadorias";
import { PageBase } from "@/components/PageBase";
import { MakoMultiTabs } from "@/components/MakoMultiTabs";

export const TabOperacoes = (props) => {
    const history = useHistory();

    const navegacaoDesabilitada = !props.location?.opFiscal;

    const TABS = [
        {
            header: "Parâmetros gerais",
            icon: "pi pi-fw pi-book",
            component: <ParametrosGeraisForm location={props.location} />,
        },
        {
            header: "NCM's",
            icon: "pi pi-fw pi-user",
            component: <NcmForm location={props.location} />,
            disabled: navegacaoDesabilitada,
        },
        {
            header: "Produtos / mercadorias",
            icon: "fas pi-fw fa-cubes",
            component: <ProdutosMercadoriasForm location={props.location} />,
            disabled: navegacaoDesabilitada,
        },
    ];

    const voltarParaListagem = () => {
        history.push("/fiscal/cadastros/operacoes-fiscais");
    };

    return (
        <PageBase>
            <MakoMultiTabs
                title="Cadastro de operação fiscal"
                tabs={TABS}
                ocultarButtonFinalizar
                propsButtonAnt={{ disabled: navegacaoDesabilitada }}
                propsButtonProx={{ disabled: navegacaoDesabilitada }}
                propsButtonCancelar={{ onClick: voltarParaListagem }}
            />
        </PageBase>
    );
};
