import React, { useImperativeHandle, useState, useCallback, useEffect, forwardRef } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { TIPO_FRETE, CST_ICMS_FRETE } from "@/assets/constants/compras";
import { url } from "@/services/axios";
import { axiosGet, axiosPost, axiosPut, axiosDelete } from "@/services/http";
import { formatarCasasDecimais } from "@/assets/util/util";
import { Dropdown } from "@/components/Dropdown";
import { MakoInputPercent } from "@/components/MakoInputs/MakoInputPercent";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoFileUpload } from "@/components/MakoFileUpload";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

const Modal = (props, ref) => {
    const [visible, setVisible] = useState(false);
    const [xmlProps, setXmlProps] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showWarning, showError } = useToast();

    const { setFieldValue, ...formik } = useFormik({
        initialValues: {
            chave: "",
            tipo_frete: null,
            cst_icms_frete: "",
            red_bc_icms_frete: 0,
            bc_icms_frete: 0,
            aliquota_icms_frete: 0,
            valor_icms_frete: 0,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                chave: Yup.string()
                    .required("O campo 'chave' é obrigatório.")
                    // eslint-disable-next-line no-template-curly-in-string
                    .length(44, "Esse campo requer ${length} caracteres."),
                tipo_frete: Yup.number()
                    .required("O campo 'tipo de frete' é obrigatório.")
                    .typeError("Selecione um tipo de frete."),
                cst_icms_frete: Yup.string()
                    .required("O campo 'CST/ICMS do frete' é obrigatório.")
                    .typeError("Selecione um CST/ICMS de frete."),
            });
            await formSchema.validate(values, { abortEarly: false });
            const cte = {
                ...values,
                valor_icms_frete: formatarCasasDecimais(values.valor_icms_frete),
                entrada_xml: xmlProps.xmlId,
                chave_nf: xmlProps.xmlChave,
            };
            if (!cte.id) {
                showLoading();
                const { status } = await axiosPost("/compras/entradas-cte/", cte);
                hideLoading();
                if (status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "CTe lançado com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        formik.resetForm();
                        setVisible(false);
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não foi possível processar sua requisição",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const { status } = await axiosPut(`/compras/entradas-cte/${cte.id}/`, cte);
                hideLoading();
                if (status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "CTe alterado com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        formik.resetForm();
                        setVisible(false);
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não foi possível processar sua requisição",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    async function deletarCte(id) {
        showLoading();
        const { status } = await axiosDelete(`/compras/entradas-cte/${id}/`);
        hideLoading();
        if (status === 204) {
            showSuccess({
                summary: "Sucesso",
                detail: "CTe deletado com sucesso!",
                life: 1500,
            });
            formik.resetForm();
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível processar sua requisição",
                life: 3000,
            });
        }
    }

    const calcularValorIcmsFrete = useCallback(() => {
        if (formik.values.bc_icms_frete > 0 && formik.values.aliquota_icms_frete > 0) {
            const valorIcmsFrete = (formik.values.bc_icms_frete * formik.values.aliquota_icms_frete) / 100;
            setFieldValue("valor_icms_frete", valorIcmsFrete);
        }
    }, [formik.values.bc_icms_frete, formik.values.aliquota_icms_frete, setFieldValue]);

    useEffect(() => {
        calcularValorIcmsFrete();
    }, [calcularValorIcmsFrete]);

    async function verificarCtePreviamenteLancado(chaveNf) {
        showLoading();
        const { status, data } = await axiosGet(`/compras/entradas-cte/?chave_nf=${chaveNf}`);
        hideLoading();
        if (status === 200) {
            if (data.count > 0) {
                const [cte] = data.results;
                formik.setValues(cte);
            }
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível verificar se já existe um CTe lançado para essa NF.",
                life: 3000,
            });
        }
    }

    const abrirModal = ({ xmlId, xmlChave }) => {
        setXmlProps({ xmlId, xmlChave });
        verificarCtePreviamenteLancado(xmlChave);
        setVisible(true);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const onUploadXml = () => {
        showSuccess({
            summary: "Sucesso",
            detail: "XML enviado com sucesso!",
            life: 3000,
        });
        verificarCtePreviamenteLancado(xmlProps?.xmlChave);
    };

    const onErrorXml = (e) => {
        const { xhr } = e;
        if (xhr.status === 400 || xhr.status === 404) {
            const { msg } = JSON.parse(xhr.response);
            showWarning({
                summary: "XML não importado",
                detail: msg,
                life: 5000,
            });
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível importar o XML.",
                life: 3000,
            });
        }
    };

    const renderFooter = () => {
        return (
            <div>
                <MakoFileUpload
                    mode="basic"
                    name="file"
                    disabled={!!formik.values.id}
                    url={`${url()}/compras/upload-xml-entrada-cte/${xmlProps?.xmlChave}/`}
                    accept=".xml"
                    chooseLabel="Carregar XML"
                    className="p-d-inline-block p-mr-2"
                    chooseOptions={{ className: "p-button-help" }}
                    invalidFileSizeMessageSummary="{0}: Tamanho do arquivo inválido, "
                    invalidFileSizeMessageDetail="tamanho máximo permitido: {0}."
                    maxFileSize={100000}
                    onUpload={onUploadXml}
                    onError={onErrorXml}
                />
                <Button
                    label="Deletar"
                    icon="pi pi-times"
                    disabled={!!!formik.values.id}
                    className="p-button-danger"
                    onClick={() => deletarCte(formik.values.id)}
                />
                <Button type="submit" label="Lançar" icon="pi pi-check" onClick={formik.handleSubmit} />
            </div>
        );
    };

    const itemTipoFreteTemplate = (option) => {
        return <span>{`${option.value} - ${option.label}`}</span>;
    };

    const valueTipoFreteTemplate = (option, props) => {
        if (option) return itemTipoFreteTemplate(option);
        return <span>{props.placeholder}</span>;
    };

    return (
        <Dialog
            header="Lançamento de CTe"
            visible={visible}
            onHide={() => setVisible(false)}
            style={{ width: "60vw" }}
            footer={renderFooter}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="chave-cte">Chave CTe *</label>
                        <InputText
                            id="chave-cte"
                            name="chave"
                            value={formik.values.chave}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.chave })}
                        />
                        {formik.errors.chave && <small className="p-error">{formik.errors.chave}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="chave-nf">Chave NF</label>
                        <InputText id="chave-nf" name="chave" disabled value={xmlProps?.xmlChave} />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="tipo-frete">Tipo de frete *</label>
                        <Dropdown
                            id="tipo-frete"
                            name="tipo_frete"
                            options={TIPO_FRETE}
                            value={formik.values.tipo_frete}
                            itemTemplate={itemTipoFreteTemplate}
                            valueTemplate={valueTipoFreteTemplate}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo_frete })}
                        />
                        {formik.errors.tipo_frete && <small className="p-error">{formik.errors.tipo_frete}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="cst-icms-frete">CST/ICMS do frete *</label>
                        <Dropdown
                            id="cst-icms-frete"
                            name="cst_icms_frete"
                            options={CST_ICMS_FRETE}
                            optionLabel="value"
                            value={formik.values.cst_icms_frete}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.cst_icms_frete })}
                        />
                        {formik.errors.cst_icms_frete && (
                            <small className="p-error">{formik.errors.cst_icms_frete}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="red-bc-icms-frete">Red. BC ICMS do frete *</label>
                        <MakoInputPercent
                            id="red-bc-icms-frete"
                            name="red_bc_icms_frete"
                            value={formik.values.red_bc_icms_frete}
                            onValueChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="bc-icms-frete">BC ICMS do frete *</label>
                        <MakoInputMoeda
                            id="bc-icms-frete"
                            name="bc_icms_frete"
                            valueMoeda={formik.values.bc_icms_frete}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="aliquota-icms-frete">Alíquota ICMS de frete *</label>
                        <MakoInputPercent
                            id="aliquota-icms-frete"
                            name="aliquota_icms_frete"
                            value={formik.values.aliquota_icms_frete}
                            onValueChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="valor-icms-frete">Valor ICMS frete *</label>
                        <MakoInputMoeda
                            id="valor-icms-frete"
                            name="valor_icms_frete"
                            disabled
                            valueMoeda={formik.values.valor_icms_frete}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                </div>
            </form>
        </Dialog>
    );
};

export const ModalLancamentoCTE = forwardRef(Modal);
