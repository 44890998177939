import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { PageBase } from "@/components/PageBase";
import { axiosPost, axiosPut } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

const url = "/pessoas/tags-enderecos-perfis/";

export const PessoasTagsEnderecoForm = (props) => {
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();

    const formik = useFormik({
        initialValues: props.location.state?.tag || {
            tag: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                tag: Yup.string().required("O campo 'descrição' é obrigatório."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(`${url}`, values);
                hideLoading();
                if (resposta.status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Tag de endereço cadastrada com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        voltarParaListagem();
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(`${url}${values.id}/`, values);
                hideLoading();
                if (resposta.status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Tag de endereço alterada com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        voltarParaListagem();
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const voltarParaListagem = () => {
        history.push("/gestao/cadastros/tags-enderecos");
    };

    return (
        <PageBase>
            {<h3>{!formik.values.id ? "Nova tag de endereço" : "Manutenção de tag de endereço"}</h3>}
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="tag">Descrição *</label>
                        <InputText
                            id="tag"
                            name="tag"
                            autoComplete="off"
                            autoFocus
                            value={formik.values.tag}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tag })}
                        />
                        {formik.errors.tag && <small className="p-error">{formik.errors.tag}</small>}
                    </div>
                </div>
                <p>
                    <b>* Campos obrigatórios.</b>
                </p>
                <div className="p-grid p-col-12 p-md-6">
                    <Button icon="pi pi-save" label="Gravar" type="submit" className="p-button-info p-mr-2" />
                    <Button
                        icon="pi pi-angle-double-left"
                        label="Cancelar"
                        type="reset"
                        className="p-button-danger"
                        onClick={voltarParaListagem}
                    />
                </div>
            </form>
        </PageBase>
    );
};
