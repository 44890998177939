import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoInputParametro } from "./MakoInputParametro";
import { PageBase } from "@/components/PageBase";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { axiosGet, axiosPost, axiosPut } from "@/services/http";
import permissoes from "@/assets/constants/permissoes";
import useLoading from "@/hooks/useLoading";
import useParam from "@/hooks/useParam";
import useToast from "@/hooks/useToast";

export const FormParametrosPage = (props) => {
    const [chavesParametros, setChavesParametros] = useState([]);
    const [chaveParametroSelecionada, setChaveParametroSelecionada] = useState(null);
    const [perfis, setPerfis] = useState([]);
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { loadParams } = useParam();
    const { showSuccess, showWarning, showError } = useToast();

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: {
            chave: null,
            valor: "",
            perfil: null,
        },
        onSubmit: handleSubmit,
    });

    const parametroJaExiste = async (chaveId, perfilId) => {
        let url = `/configuracoes/parametros/?chave__id=${chaveId}`;

        if (perfilId) {
            url = `${url}&perfil=${perfilId}`;
        }

        showLoading();
        const json = await axiosGet(url);
        hideLoading();

        if (json.status === 200) {
            if (json.data.count > 0) {
                return true;
            } else {
                return false;
            }
        }

        return true;
    };

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                chave: Yup.string().required("O campo 'Chave' é obrigatório"),
                valor: Yup.string().required("O campo 'Valor' é obrigatório"),
                perfil: Yup.number()
                    .nullable()
                    .test("Perfil validation", "Esse parâmetro requer um perfil.", (value) => {
                        if (chaveParametroSelecionada.requer_perfil) {
                            return !!value;
                        }

                        return true;
                    }),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const novoParametro = {
                ...values,
                valor: !chaveParametroSelecionada.url ? values.valor : null,
                valor_id: chaveParametroSelecionada.url ? values.valor : 0,
                valor_content_type: chaveParametroSelecionada.content_type,
            };

            if (!novoParametro.id) {
                const existe = await parametroJaExiste(values.chave, values.perfil);

                if (!existe) {
                    showLoading();
                    const resposta = await axiosPost("/configuracoes/parametros/", novoParametro);
                    hideLoading();

                    if (resposta.status === 201) {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Novo parâmetro cadastrado!",
                            life: 2000,
                        });

                        formik.resetForm();
                        loadParams();

                        setTimeout(() => {
                            voltar();
                        }, 2000);
                    } else {
                        showError({
                            summary: "Erro :(",
                            detail: "Desculpe, não conseguimos processar a sua requisição.",
                            life: 3000,
                        });
                    }
                } else {
                    showWarning({
                        summary: "Atenção",
                        detail: "Já existe um parâmetro com essa chave/perfil.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(`/configuracoes/parametros/${novoParametro.id}/`, novoParametro);
                hideLoading();

                if (resposta.status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Parâmetro alterado!",
                        life: 2000,
                    });

                    formik.resetForm();
                    loadParams();

                    setTimeout(() => {
                        voltar();
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
            if (error instanceof SyntaxError) {
                showError({
                    summary: "Erro :(",
                    detail: "O formato do JSON está incorreto, por favor verifique.",
                    life: 3000,
                });
            }
        }
    }

    const listarChavesParametros = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/configuracoes/parametros-chaves/?limit=100");
        hideLoading();

        if (resposta.status === 200) {
            setChavesParametros(resposta.data.results);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível listar as chaves de parâmetros.",
                life: 3000,
            });
        }
    }, [showLoading, hideLoading, showError]);

    const listarPerfis = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/pessoas/grupos-perfis/");
        hideLoading();

        if (resposta.status === 200) {
            if (resposta.data.results.length > 0) {
                setPerfis(await resposta.data.results.map((vinculo) => vinculo.perfil));
            } else {
                showWarning({
                    summary: "Aviso!",
                    detail: "Não existem nenhum perfil vinculado ao sistema.",
                    life: 3000,
                });
            }
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível listar os perfis.",
                life: 3000,
            });
        }
    }, [showLoading, hideLoading, showWarning, showError]);

    useEffect(() => {
        listarChavesParametros();
        listarPerfis();
    }, [listarChavesParametros, listarPerfis]);

    const changeChaveParametro = useCallback(() => {
        if (formik.values.chave) {
            const chaveParametro = chavesParametros.find((chave) => chave.id === formik.values.chave);
            setChaveParametroSelecionada(chaveParametro);
        } else {
            setChaveParametroSelecionada(null);
        }
    }, [chavesParametros, formik.values.chave]);

    useEffect(() => {
        changeChaveParametro();
    }, [changeChaveParametro]);

    useEffect(() => {
        if (props.location.state) {
            const { id, chave, perfil, valor, valor_id } = props.location.state.parametro;
            setValues({
                id,
                chave: chave.id,
                perfil: perfil?.id,
                valor: valor_id > 0 ? valor_id : valor,
            });
        }
    }, [props.location.state, setValues]);

    function voltar() {
        history.push("/configuracoes/parametros");
    }

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Novo Parâmetro" : "Manutenção de Parâmetro"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="chave">Parâmetro *</label>
                        <MakoControleAcesso
                            permissao={[permissoes.OUTROS_PARAMETROS_MANUTENCAO]}
                            componente={Dropdown}
                            id="chave"
                            name="chave"
                            options={chavesParametros}
                            placeholder="Selecione..."
                            optionValue="id"
                            optionLabel="descricao"
                            filter
                            filterBy="chave,descricao"
                            showClear
                            disabled={formik.values.id}
                            value={formik.values.chave}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.chave })}
                        />
                        {formik.errors.chave && <small className="p-error">{formik.errors.chave}</small>}
                    </div>
                </div>
                {chaveParametroSelecionada && (
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="valor">Valor *</label>
                            <MakoInputParametro
                                id="valor"
                                name="valor"
                                chaveParametro={chaveParametroSelecionada}
                                value={formik.values.valor}
                                onChangeParametro={(e) => setFieldValue("valor", e)}
                                className={classNames({ "p-invalid": formik.errors.valor })}
                            />
                            {formik.errors.valor && <small className="p-error">{formik.errors.valor}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="perfil">Perfil vinculado</label>
                            <Dropdown
                                id="perfil"
                                name="perfil"
                                options={perfis}
                                optionValue="id"
                                optionLabel="nome"
                                filter
                                filterBy="nome"
                                showClear
                                disabled={!!!chaveParametroSelecionada?.requer_perfil}
                                placeholder="Selecione um perfil"
                                emptyMessage="Nenhum registro encontrado"
                                value={formik.values.perfil}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.perfil })}
                            />
                            {formik.errors.perfil && <small className="p-error">{formik.errors.perfil}</small>}
                        </div>
                    </div>
                )}
                <CamposObrigatorios />
                <div className="p-grid p-col-12 p-md-6">
                    <Button
                        label="Gravar"
                        icon="pi pi-save"
                        type="submit"
                        disabled={!!!chaveParametroSelecionada}
                        className="p-button-info p-mr-2"
                    />
                    <Button
                        label="Voltar"
                        icon="pi pi-angle-double-left"
                        type="button"
                        className="p-button-danger p-mr-2"
                        onClick={voltar}
                    />
                </div>
            </form>
        </PageBase>
    );
};
