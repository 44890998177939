import React, { useCallback, useEffect, useState, memo } from "react";
import { Dropdown } from "primereact/dropdown";

import { axiosPatch } from "@/services/http";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useAuth from "@/hooks/useAuth";
import useToast from "@/hooks/useToast";
import useAxiosPrivate from "@/hooks/useAxiosPrivate";

const Componente = ({
    placeholder,
    disabled,
    onChange,
    redefinirEmpresaPadrao = false,
    nome_curto = true,
    ...props
}) => {
    const [empresas, setEmpresas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formatarDocumento] = useFormatCNPJCPF();
    const { user, reloadPermissions } = useAuth();
    const { showError } = useToast();
    const { axiosGet } = useAxiosPrivate();

    const alterarEmpresaPadrao = useCallback(
        async (empresaPadraoId) => {
            if (redefinirEmpresaPadrao && user?.usuario_empresapadrao && empresaPadraoId) {
                const body = {
                    empresa_padrao: empresaPadraoId,
                };
                const { id } = user.usuario_empresapadrao;
                const { status } = await axiosPatch(`/pessoas/usuarios-empresa-padrao/${id}/`, body);
                if (status !== 200) {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não foi possível alterar a empresa principal.",
                        life: 3000,
                    });
                }
            }
        },
        [redefinirEmpresaPadrao, showError, user]
    );

    const buscarEmpresasVinculadas = useCallback(async () => {
        setLoading(true);
        const { status, data } = await axiosGet("/pessoas/grupos-perfis/");
        setLoading(false);
        if (status === 200) {
            const empresas = await data.results.map(({ perfil }) => {
                return {
                    ...perfil,
                    label: nome_curto && perfil.nome_curto ? perfil.nome_curto : perfil.nome,
                };
            });
            setEmpresas(empresas);
        }
    }, [nome_curto, axiosGet]);

    useEffect(() => {
        buscarEmpresasVinculadas();
    }, [buscarEmpresasVinculadas]);

    const empresaOpcaoTemplate = (option) => {
        return <span>{`${option.label} - ${formatarDocumento(option.identificacao)}`}</span>;
    };

    const empresaSelecionadaTemplate = (option, props) => {
        if (option) return empresaOpcaoTemplate(option);
        return <span>{props.placeholder}</span>;
    };

    const onChangeEmpresa = async (value) => {
        if (value) {
            const empresaFull = empresas.find((el) => el.id === value);
            onChange({ id: value, empresa: empresaFull });
            if (redefinirEmpresaPadrao) {
                await reloadPermissions(value);
                await alterarEmpresaPadrao(value);
            }
        } else {
            onChange({ id: null, empresa: null });
        }
    };

    return (
        <Dropdown
            options={empresas}
            optionValue="id"
            filter
            filterBy="nome,identificacao"
            showClear
            placeholder={loading ? "Carregando..." : placeholder || "Selecione"}
            disabled={loading || disabled}
            emptyMessage="Nenhum registro disponível"
            emptyFilterMessage="Nenhum registro encontrado"
            valueTemplate={empresaSelecionadaTemplate}
            itemTemplate={empresaOpcaoTemplate}
            onChange={(e) => onChangeEmpresa(e.value)}
            {...props}
        />
    );
};

export const MakoDropdownEmpresas = memo(Componente);
