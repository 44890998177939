import React, { useCallback, useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { useFormik } from "formik";
import { axiosGet, axiosPost, axiosPut } from "@/services/http";
import { useHistory } from "react-router-dom";

import * as Yup from "yup";
import classNames from "classnames";
import useLoading from "@/hooks/useLoading";
import { CHAVE_PAPEL_CHOICE, TIPO_MOMENTO_REGRA_APURACAO_COMISSAO_CHOICE } from "@/assets/constants/constants";

const urlvoltar = "/vendas/cadastros/regra-apuracao-comissao";

export const RegraApuracaoComissaoForm = (props) => {
    const [listaPlanos, setListaPlanos] = useState([]);
    const { showLoading, hideLoading } = useLoading();
    const history = useHistory();
    const toastRef = useRef(null);

    const regraVazia = {
        descricao: "",
        chave: null,
        momento: null,
        plano_conta_encargos: null,
        plano_conta_mercadoria: null,
        plano_conta_servico: null,
        ativo: true,
        usa_hierarquia: false,
    };

    const formik = useFormik({
        initialValues: props.location.state ? props.location.state.regra : regraVazia,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().max(30).required("O campo 'descrição' é obrigatório."),
                chave: Yup.string().required("O campo 'chave' é obrigatório."),
                momento: Yup.string().required("O campo 'momento' é obrigatório."),
                plano_conta_encargos: Yup.number().required("O campo 'plano de contas de encargo' é obrigatório."),
                plano_conta_mercadoria: Yup.number().required("O campo 'plano de contas de mercadoria' é obrigatório."),
                plano_conta_servico: Yup.number().required("O campo 'plano de contas de serviço' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(`/vendas/regra-apuracao-comissao-venda/`, values);
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Regra de apuração de comissão cadastrada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(`/vendas/regra-apuracao-comissao-venda/${values.id}/`, values);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Regra de apuração de comissão alterada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    const listarPlanos = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/financeiro/planos-contas-financeiras?limit=10000");
        hideLoading();

        if (resposta.status === 200) setListaPlanos(resposta.data.results);
    }, [showLoading, hideLoading]);

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    useEffect(() => {
        listarPlanos();
    }, [listarPlanos]);

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toastRef} />
                    <h3>{!formik.values.id ? "Nova regra de apuração" : "Manutenção de regra de apuração"}</h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="descricao">Descrição *</label>
                                <InputText
                                    id="descricao"
                                    name="descricao"
                                    value={formik.values.descricao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.descricao })}
                                />
                                {formik.errors.descricao && (
                                    <small className="p-error">{formik.errors.descricao}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="chave">Chave *</label>
                                <Dropdown
                                    id="chave"
                                    placeholder="Selecione uma chave"
                                    name="chave"
                                    onChange={formik.handleChange}
                                    value={formik.values.chave}
                                    optionLabel="label"
                                    optionValue="value"
                                    options={CHAVE_PAPEL_CHOICE}
                                />
                                {formik.errors.chave && <small className="p-error">{formik.errors.chave}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="momento">Momento *</label>
                                <Dropdown
                                    id="momento"
                                    placeholder="Selecione um momento"
                                    name="momento"
                                    onChange={formik.handleChange}
                                    value={formik.values.momento}
                                    optionLabel="label"
                                    optionValue="value"
                                    options={TIPO_MOMENTO_REGRA_APURACAO_COMISSAO_CHOICE}
                                />
                                {formik.errors.momento && <small className="p-error">{formik.errors.momento}</small>}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="plano_conta_mercadoria">Plan. contas de mercadoria *</label>
                                <Dropdown
                                    id="plano_conta_mercadoria"
                                    placeholder="Selecione um plano de contas"
                                    name="plano_conta_mercadoria"
                                    onChange={formik.handleChange}
                                    value={formik.values.plano_conta_mercadoria}
                                    filter
                                    filterBy="descricao"
                                    optionLabel="descricao"
                                    optionValue="id"
                                    options={listaPlanos}
                                />
                                {formik.errors.plano_conta_mercadoria && (
                                    <small className="p-error">{formik.errors.plano_conta_mercadoria}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="plano_conta_encargos">Plan. contas de encargos *</label>
                                <Dropdown
                                    id="plano_conta_encargos"
                                    placeholder="Selecione um plano de contas"
                                    name="plano_conta_encargos"
                                    onChange={formik.handleChange}
                                    value={formik.values.plano_conta_encargos}
                                    filter
                                    filterBy="descricao"
                                    optionLabel="descricao"
                                    optionValue="id"
                                    options={listaPlanos}
                                />
                                {formik.errors.plano_conta_encargos && (
                                    <small className="p-error">{formik.errors.plano_conta_encargos}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="plano_conta_servico">Plan. contas de serviços *</label>
                                <Dropdown
                                    id="plano_conta_servico"
                                    placeholder="Selecione um plano de contas"
                                    name="plano_conta_servico"
                                    onChange={formik.handleChange}
                                    value={formik.values.plano_conta_servico}
                                    filter
                                    filterBy="descricao"
                                    optionLabel="descricao"
                                    optionValue="id"
                                    options={listaPlanos}
                                />
                                {formik.errors.plano_conta_servico && (
                                    <small className="p-error">{formik.errors.plano_conta_servico}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field-checkbox p-col-12 p-md-2 p-ml-1">
                                <br></br>
                                <Checkbox
                                    inputId="usa_hierarquia"
                                    id="usa_hierarquia"
                                    name="usa_hierarquia"
                                    onChange={formik.handleChange}
                                    checked={formik.values.usa_hierarquia}
                                    value={formik.values.usa_hierarquia}
                                    className="p-mt-5"
                                />
                                <label htmlFor="usa_hierarquia" className="p-mt-5">
                                    Usa hierarquia?
                                </label>
                            </div>
                            <div className="p-field-checkbox p-col-12 p-md-3">
                                <br></br>
                                <Checkbox
                                    inputId="ativo"
                                    id="ativo"
                                    name="ativo"
                                    onChange={formik.handleChange}
                                    checked={formik.values.ativo}
                                    value={formik.values.ativo}
                                    className="p-mt-5"
                                />
                                <label htmlFor="ativo" className="p-mt-5">
                                    Ativo
                                </label>
                            </div>
                        </div>

                        <p>
                            <b>* Campos obrigatórios.</b>
                        </p>

                        <div className="p-grid p-col-12 p-md-6">
                            <Button label="Gravar" type="submit" className="p-button-info p-mr-2 p-mb-6" />
                            <Button
                                label="Cancelar"
                                type="reset"
                                className="p-button-danger p-mr-2 p-mb-6"
                                onClick={cancelar}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
