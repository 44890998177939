import React, { useMemo } from "react";

import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";
import { dataToStr } from "@/assets/util/datas";
import { parseMoeda } from "@/assets/util/util";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";

export const MakoInputNumVenda = ({ situacao = "F", ...props }) => {
    const [formatarDocumento] = useFormatCNPJCPF();

    const url = useMemo(() => {
        const queryParams = ["query={id,cliente,data,valor_total_ap}", `situacao=${situacao}`, "id="];
        return `/vendas/vendas?${queryParams.join("&")}`;
    }, [situacao]);

    const autoCompleteVendaTemplate = (item) => {
        const { id, cliente, data, valor_total_ap } = item;
        const { nome, identificacao } = cliente;
        return (
            <div style={{ width: "50rem" }}>
                <div className="p-grid">
                    <div className="p-col-4">
                        <span>{`Nº da venda: `}</span>
                        <b>{`${id}`.padStart(6, "0")}</b>
                    </div>
                    <div className="p-col-8">
                        <span>{`Cliente: `}</span>
                        <b>{`${nome} - (${formatarDocumento(identificacao)})`}</b>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-4">
                        <span>{`Data: `}</span>
                        <b>{`${dataToStr(data, "dd/MM/yyyy")}`}</b>
                    </div>
                    <div className="p-col-4">
                        <span>{`Valor: `}</span>
                        <b>{parseMoeda(valor_total_ap)}</b>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <MakoAutoComplete
                minCaracteresBusca={1}
                itemTemplate={autoCompleteVendaTemplate}
                field="id"
                urlSearch={url}
                placeholder="Digite o número da venda..."
                {...props}
            />
        </>
    );
};
