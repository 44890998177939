import React, { useEffect } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";

import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";
import { MakoCalendar } from "@/components/MakoCalendar";

import useCaixaMovimento from "@/hooks/useCaixaMovimento";
import useLoading from "@/hooks/useLoading";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";

import { dataToStr, parseData } from "@/assets/util/datas";
import { axiosPatch, axiosPost } from "@/services/http";
import { isAfter } from "date-fns";

export const FormFechar = ({ fecharModal, handleCaixa }) => {
    const { showLoading, hideLoading } = useLoading();
    const { caixaMov } = useCaixaMovimento();
    const { empresaSelecionadaId } = useEmpresa();
    const { showSuccess, showError } = useToast();

    const { setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            data_fechamento: new Date(),
            hora_fechamento: new Date(),
            conferido_por: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                data_fechamento: Yup.date()
                    .required("O campo 'data fechamento' é obrigatório")
                    .typeError("Seleciona um data de fechamento."),
                hora_fechamento: Yup.date()
                    .required("O campo 'hora fechamento' é obrigatório")
                    .typeError("Seleciona uma hora de fechamento."),
                conferido_por: Yup.object()
                    .required("O campo 'conferido por' é obrigatório.")
                    .typeError("Informe quem conferiu o caixa."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const _dateFechamento = parseData(
                `${dataToStr(values.data_fechamento, "yyyy-MM-dd")} ${dataToStr(values.hora_fechamento, "HH:mm:ss")}`
            );
            const _dateAbertura = parseData(`${caixaMov.data_abertura} ${caixaMov.hora_abertura}`);

            if (isAfter(_dateFechamento, _dateAbertura)) {
                const caixaMovi = {
                    conferido_por: values.conferido_por.id,
                    datahora_fechamento: `${dataToStr(values.data_fechamento, "yyyy-MM-dd")}T${dataToStr(
                        values.hora_fechamento,
                        "HH:mm:ss"
                    )}`,
                };

                showLoading();
                let resposta = await axiosPatch(`/financeiro/caixas-movimento/${caixaMov.id}/`, caixaMovi);
                hideLoading();

                if (resposta.status === 200) {
                    showLoading();
                    resposta = await axiosPost(`/financeiro/gerar-resumo-caixa-movimento/${caixaMov.id}`);
                    hideLoading();

                    if (resposta.status === 201) {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Caixa fechado com sucesso!",
                            life: 1500,
                        });

                        let copy = { ...caixaMov };

                        copy.conferido_por = caixaMovi.conferido_por;
                        copy.datahora_fechamento = caixaMovi.datahora_fechamento;

                        handleCaixa();

                        fecharModal();
                    } else {
                        throw new Error("Desculpe, não conseguimos processar sua requisição.");
                    }
                } else {
                    throw new Error("Desculpe, não conseguimos processar sua requisição.");
                }
            } else {
                throw new Error("Desculpe, não conseguimos processar sua requisição.");
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            } else {
                showError({
                    summary: "Erro :(",
                    detail: error.message,
                    life: 3000,
                });
            }
        }
    }

    const filterResults = (results) => {
        if (results.length === 0) return results;

        return results.filter((item) => {
            return (
                item.vinculoperfilempresa_set.find((relacao) => relacao.empresa === empresaSelecionadaId) !==
                "undefined"
            );
        });
    };

    useEffect(() => {
        resetForm();
    }, [resetForm]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="data_fechamento">Data Fechamento *</label>
                    <MakoCalendar
                        id="data_fechamento"
                        name="data_fechamento"
                        valueCalendar={formik.values.data_fechamento}
                        minDate={caixaMov.data_abertura}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.data_fechamento })}
                    />
                    {formik.errors.data_fechamento && (
                        <small className="p-error">{formik.errors.data_fechamento}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="hora_fechamento">Hora Fechamento *</label>
                    <Calendar
                        id="hora_fechamento"
                        name="hora_fechamento"
                        timeOnly
                        showTime
                        hourFormat="24"
                        value={formik.values.hora_fechamento}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.hora_fechamento })}
                    />
                    {formik.errors.hora_fechamento && (
                        <small className="p-error">{formik.errors.hora_fechamento}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-6">
                    <label htmlFor="conferido_por">Conferido por *</label>
                    <MakoAutoComplete
                        id="conferido_por"
                        name="conferido_por"
                        value={formik.values.conferido_por}
                        onChange={formik.handleChange}
                        aposPesquisar={filterResults}
                        minCaracteresBusca={3}
                        field="nome"
                        urlSearch="/pessoas/perfis?query={id,nome,vinculoperfilempresa_set}&nome__contains="
                        placeholder="Comece a digitar o nome para buscar... (min 4 caracteres)"
                        className={classNames({ "p-invalid": formik.errors.conferido_por })}
                    />
                    {formik.errors.conferido_por && <small className="p-error">{formik.errors.conferido_por}</small>}
                </div>
            </div>
            <div>
                <Button label="Gravar" className="p-button-success p-mr-2" type="submit" />
                <Button label="Cancelar" className="p-button-danger" onClick={fecharModal} />
            </div>
        </form>
    );
};
