import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";

import { axiosGet } from "@/services/http";

//label = Nome que aparecerá em cima do input.
//placeholder = texto que aparecerá dentro do input.
//namefield = nome do input.
//urlserach = url que deseja pesquisar.
//optionAdicionar = aparecer o botão adicionar se não encontrar resultado na pesquisa.
//optionAdicionarPessoa = Botões personalizados para cadastro de pessoa.
//setList = Se deseja que ao escolher ele incrementa uma lista
//setValue = Setar o valor do hooks
//aposPesquisar = mudar os dados apos pesquisar
//tip = String do Tooltip para exibir hints ao usuário
//focus = If definido o focus do início do formulário vai pra ele

const MakoAutoComplete = (
    {
        label,
        placeholder,
        namefield,
        urlsearch,
        optionAdicionar,
        optionAdicionarPessoa,
        setValue,
        setList,
        aposPesquisar,
        tip,
        disabled,
        focus,
    },
    ref
) => {
    const namefieldhelp = namefield + "-help";
    const [fieldValue, setFieldValue] = useState("");
    const [filtered, setFiltered] = useState([]);
    const [filteredNull, setFilteredNull] = useState(false);

    const autoCompleteRef = useRef(null);

    useImperativeHandle(ref, () => {
        return {
            onUpdate,
        };
    });

    async function search(event) {
        let _filtered = [];
        if (event.query.length > 0) {
            const resp = await axiosGet(urlsearch + event.query);

            if (resp.status === 200) {
                const { results } = resp.data;
                let r = [];

                if (aposPesquisar) {
                    _filtered = aposPesquisar(results);
                } else {
                    if (results.length > 0) {
                        for (let i = 0; i < results.length; i++) {
                            r.push({
                                value: results[i].id,
                                label: results[i].nome,
                            });
                        }
                        _filtered = r;
                        setFilteredNull(false);
                    } else {
                        _filtered = [];
                        setFilteredNull(true);
                    }
                }
            }
        }
        setFiltered(_filtered);
    }

    function onUpdate(v) {
        if (v !== undefined) {
            setFieldValue({
                value: v.id,
                label: v.nome,
            });
        }
    }

    const clear = () => {
        setFieldValue("");
        setFilteredNull(false);
        setFiltered([]);
    };

    const onChange = (e) => {
        setFieldValue(e.value);
        if (setValue) {
            setValue(e.value);
        }
    };

    const onSelect = (e) => {
        if (setList) {
            setList(e.value);
            setTimeout(() => {
                setFieldValue("");
            }, 500);
        }
    };

    return (
        <>
            <div className="p-fluid">
                <div className="p-field">
                    <label htmlFor={namefield} className="p-d-block">
                        {label}
                    </label>
                    <AutoComplete
                        ref={autoCompleteRef}
                        value={fieldValue}
                        suggestions={filtered}
                        completeMethod={search}
                        placeholder={placeholder}
                        field="label"
                        onChange={(e) => onChange(e)}
                        onSelect={(e) => {
                            onSelect(e);
                        }}
                        tooltip={tip}
                        disabled={disabled}
                        autoFocus={focus}
                        delay={600}
                    />
                    {filteredNull && (
                        <small id={namefieldhelp} className="p-error p-d-block">
                            <p>Nenhum resultado encontrado</p>
                            {optionAdicionar && !optionAdicionarPessoa && (
                                <>
                                    <p>Adicionar</p>
                                    <Button icon="pi pi-check" className="p-button-rounded p-ml-2 p-mr-2" />
                                    <Button
                                        icon="pi pi-times"
                                        className="p-button-rounded p-button-danger"
                                        onClick={() => clear()}
                                    />
                                </>
                            )}

                            {optionAdicionar && optionAdicionarPessoa && (
                                <>
                                    <p>Adicionar?</p>
                                    <Button label="Jurídica" className=" p-ml-2 p-mr-2 p-mb-2 p-button-sm" />
                                    <Button label="Física" className="p-ml-2 p-mr-2 p-mb-2  p-button-sm" />
                                    <Button label="Estrangeira" className="p-ml-2 p-mr-2 p-mb-2  p-button-sm" />
                                    <Button
                                        icon="pi pi-times"
                                        className="p-button-rounded p-button-danger"
                                        onClick={() => clear()}
                                    />
                                </>
                            )}
                        </small>
                    )}
                </div>
            </div>
        </>
    );
};

export default forwardRef(MakoAutoComplete);
