import React, { useEffect } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { Button } from "primereact/button";

import { MakoInputFornecedor } from "@/components/MakoInputs/MakoInputFornecedor";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";

import { TIPOS_TIPO_DEVOLUCAO_FORNECEDOR } from "@/assets/constants/constants";
import { dataToStr } from "@/assets/util/datas";

import useDevolucaoFornecedor from "@/hooks/useDevolucaoFornecedor";
import useToast from "@/hooks/useToast";

const FormDadosBasicos = () => {
    const { devolucao, updateDevolucao } = useDevolucaoFornecedor();
    const { showError } = useToast();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            fornecedor: devolucao?.entrada?.fornecedor,
            data_devolucao: new Date(),
            tipo: null,
            status: "P",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                data_devolucao: Yup.date()
                    .required("O campo 'data devolução' é obrigatório")
                    .typeError("Informe uma 'data' válida"),
                tipo: Yup.number().required("O campo 'tipo' é obrigatório").typeError("Informe um 'tipo' válido"),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            updateDevolucao({
                ...values,
                data_devolucao: dataToStr(values.data_devolucao, "yyyy-MM-dd"),
                fornecedor: values.fornecedor.id,
            });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, a requisição não pode ser concluída.",
                    life: 3000,
                });
            }
        }
    }

    useEffect(() => {
        if (devolucao) {
            setValues({
                fornecedor: devolucao?.entrada?.fornecedor,
                data_devolucao: devolucao.data_devolucao,
                tipo: devolucao.tipo,
                status: devolucao.status,
            });
        }
    }, [devolucao, setValues]);

    return (
        <div>
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="fornecedor">Fornecedor *</label>
                        <MakoInputFornecedor
                            id="fornecedor"
                            name="fornecedor"
                            disabled
                            value={formik.values.fornecedor}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.fornecedor })}
                        />
                        {formik.errors.fornecedor && <small className="p-error">{formik.errors.fornecedor}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="data_devolucao">Data devolução:</label>
                        <MakoCalendar
                            id="data_devolucao"
                            name="data_devolucao"
                            valueCalendar={formik.values.data_devolucao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_devolucao })}
                        />
                        {formik.errors.data_devolucao && (
                            <small className="p-error">{formik.errors.data_devolucao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="tipo">Tipo:</label>
                        <Dropdown
                            id="tipo"
                            name="tipo"
                            options={TIPOS_TIPO_DEVOLUCAO_FORNECEDOR}
                            optionLabel="label"
                            optionValue="value"
                            value={formik.values.tipo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo })}
                        />
                        {formik.errors.tipo && <small className="p-error">{formik.errors.tipo}</small>}
                    </div>
                </div>
                <p>
                    <b>* Campos obrigatórios</b>
                </p>
                <p className="p-error">* Lembre-se de gravar os dados antes de prosseguir ou finalizar</p>
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button
                            type="submit"
                            icon="pi pi-save"
                            label="Incluir dados básicos"
                            className="p-mr-2 p-mb-2"
                        />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            className="p-button-warning p-mr-2 p-mb-2"
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default React.memo(FormDadosBasicos);
