import React, { useCallback, useEffect, useImperativeHandle, useState, forwardRef } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoInputNcm } from "@/components/MakoInputs/MakoInputNcm";
import { Dropdown } from "@/components/Dropdown";
import { MakoInputPercent } from "@/components/MakoInputs/MakoInputPercent";
import { SIM_NAO_BOOLEAN, TIPO_MODALIDADE_ST_CHOICE } from "@/assets/constants/constants";
import { formatarCasasDecimais } from "@/assets/util/util";
import { axiosGet, axiosPost, axiosPut } from "@/services/http";
import useEmpresa from "@/hooks/useEmpresa";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import { Divider, SelectButton, TabPanel, TabView } from "primereact";

const Modal = ({ operacaoFiscal, onGravar }, ref) => {
    const [visible, setVisible] = useState(false);
    const [loadingEstados, setLoadingEstados] = useState(false);
    const [estados, setEstados] = useState([]);
    const { empresaSelecionadaId } = useEmpresa();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: {
            ncm: null,
            define_ipi: false,
            aliquota_ipi: 0,
            reducao_base_ipi: 0,
            cst_ipi: null,
            define_pis: false,
            aliquota_pis: 0,
            cst_pis: null,
            reducao_base_pis: 0,
            define_cofins: false,
            aliquota_cofins: 0,
            reducao_base_cofins: 0,
            cst_cofins: null,
            aliquota_fcp_origem: 0,
            aliquota_fcp_destino: 0,
            aliquota_icms_destino: 0,
            cst_icms: null,
            reducao_base_icms: 0,
            reducao_base_st: 0,
            diferimento_icms: 0,
            mva: null,
            uf_origem: null,
            uf_destino: null,
            enquadramento_ipi: null,
            aliquota_icms_origem: 0,
            modalidade_contribuinte: null,
            define_fcp: false,
            fcp: false,
            percentual_fcp: 0,
            fcp_diferimento: false,
            percentual_diferimento: 0,
            fcp_subst: false,
            percentual_sub_st: 0,
            fcp_retido_st: false,
            percentual_retido_st: 0,
            fcp_consumidor_final: false,
            aliq_consumidor_final: 0,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            console.log(values);
            const formSchema = Yup.object().shape({
                ncm: Yup.object().required("O campo é obrigatório."),
                cst_icms: Yup.number().required("O campo é obrigatório.").typeError("Informe um valor válido"),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            const regraNCM = {
                ...values,
                ncm: values.ncm.id,
                operacao_fiscal: operacaoFiscal.id,
                reducao_base_st: formatarCasasDecimais(values.reducao_base_st),
                aliquota_pis: formatarCasasDecimais(values.aliquota_pis),
                aliquota_cofins: formatarCasasDecimais(values.aliquota_cofins),
                aliquota_ipi: formatarCasasDecimais(values.aliquota_ipi),
            };

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost("/fiscal/ncm-operacoes-fiscais/", regraNCM);
                hideLoading();

                if (resposta.status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "NCM da operação inserido com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        if (typeof onGravar === "function") onGravar();
                        fecharModal();
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(`/fiscal/ncm-operacoes-fiscais/${values.id}/`, regraNCM);
                hideLoading();

                if (resposta.status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "NCM da operação alterado com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        if (typeof onGravar === "function") onGravar();
                        fecharModal();
                    }, 500);
                } else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const listarEstados = useCallback(async () => {
        setLoadingEstados(true);
        const { status, data } = await axiosGet("/pessoas/estados?limit=100");
        setLoadingEstados(false);
        if (status === 200) setEstados(data.results);
    }, []);

    useEffect(() => {
        listarEstados();
    }, [listarEstados]);

    const abrirModal = (values) => {
        if (values) setValues(values);
        setVisible(true);
    };

    const fecharModal = () => {
        formik.resetForm();
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const opcaoCSTTemplate = (option) => {
        return <span>{`${option.codigo} - ${option.descricao}`}</span>;
    };

    const cstSelecionadoTemplate = (option, props) => {
        if (option) return opcaoCSTTemplate(option);
        return <span>{props.placeholder}</span>;
    };

    const formatarMVA = useCallback((results) => {
        return results.map((mva) => ({
            value: mva.id,
            label: `${mva.valor} %`,
        }));
    }, []);

    return (
        <Dialog
            visible={visible}
            onHide={fecharModal}
            header={formik.values.id ? `Edição NCM: ${formik.values.ncm.codigo}` : "Adicionar NCM"}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "70vw", display: "block" }}
        >
            <form onSubmit={formik.handleSubmit}>
                <TabView>
                    <TabPanel header="NCM">
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="ncm">NCM *</label>
                                <MakoInputNcm
                                    fieldValue={setFieldValue}
                                    exibirBotaoItens
                                    valorNcm={formik.values.ncm}
                                    botaoDesativado={formik.values.id}
                                />
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="uf_origem">Estado de origem</label>
                                <Dropdown
                                    id="uf_origem"
                                    name="uf_origem"
                                    disabled={loadingEstados}
                                    options={estados}
                                    filter
                                    filterBy="uf,nome"
                                    optionValue="id"
                                    optionLabel="nome"
                                    value={formik.values.uf_origem}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.uf_origem })}
                                />
                                {formik.errors.uf_origem && (
                                    <small className="p-error">{formik.errors.uf_origem}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="uf_destino">Estado de destino</label>
                                <Dropdown
                                    id="uf_destino"
                                    name="uf_destino"
                                    disabled={loadingEstados}
                                    options={estados}
                                    filter
                                    filterBy="uf,nome"
                                    optionValue="id"
                                    optionLabel="nome"
                                    value={formik.values.uf_destino}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.uf_destino })}
                                />
                                {formik.errors.uf_destino && (
                                    <small className="p-error">{formik.errors.uf_destino}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="mva">MVA</label>
                                <Dropdown
                                    id="mva"
                                    name="mva"
                                    buscar={!!formik.values.ncm}
                                    url={`/fiscal/mva?limit=100&ncm=${formik.values.ncm?.id}`}
                                    aposBuscar={formatarMVA}
                                    filter
                                    filterBy="label"
                                    value={formik.values.mva}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.mva })}
                                />
                                {formik.errors.mva && <small className="p-error">{formik.errors.mva}</small>}
                            </div>
                        </div>
                        <Divider align="center">
                            <b>Define</b>
                        </Divider>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="define_pis">CST PIS</label>
                                <SelectButton
                                    id="define_pis"
                                    name="define_pis"
                                    value={formik.values.define_pis}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="define_cofins">CST Cofins</label>
                                <SelectButton
                                    id="define_cofins"
                                    name="define_cofins"
                                    value={formik.values.define_cofins}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="define_ipi">CST IPI</label>
                                <SelectButton
                                    id="define_ipi"
                                    name="define_ipi"
                                    value={formik.values.define_ipi}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="define_fcp">CST FCP</label>
                                <SelectButton
                                    id="define_fcp"
                                    name="define_fcp"
                                    value={formik.values.define_fcp}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel header="ICMS">
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="cst_icms">CST ICMS</label>
                                <Dropdown
                                    id="cst_icms"
                                    name="cst_icms"
                                    url={`/fiscal/cst-icms?limit=10000&empresa=${empresaSelecionadaId}&query={id,codigo,descricao}`}
                                    placeholder="Selecione um CST ICMS"
                                    filter
                                    filterBy="label"
                                    optionValue="id"
                                    optionLabel="descricao"
                                    valueTemplate={cstSelecionadoTemplate}
                                    itemTemplate={opcaoCSTTemplate}
                                    value={formik.values.cst_icms}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.cst_icms })}
                                />
                                {formik.errors.cst_icms && <small className="p-error">{formik.errors.cst_icms}</small>}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="aliquota_icms_origem">Alíq. ICMS origem</label>
                                <MakoInputPercent
                                    id="aliquota_icms_origem"
                                    name="aliquota_icms_origem"
                                    casasDecimais={2}
                                    value={formik.values.aliquota_icms_origem}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.aliquota_icms_origem })}
                                />
                                {formik.errors.aliquota_icms_origem && (
                                    <small className="p-error">{formik.errors.aliquota_icms_origem}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="aliquota_icms_destino">Alíq. ICMS destino</label>
                                <MakoInputPercent
                                    id="aliquota_icms_destino"
                                    name="aliquota_icms_destino"
                                    casasDecimais={2}
                                    value={formik.values.aliquota_icms_destino}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.aliquota_icms_destino })}
                                />
                                {formik.errors.aliquota_icms_destino && (
                                    <small className="p-error">{formik.errors.aliquota_icms_destino}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="reducao_base_icms">Redução de base ICMS</label>
                                <MakoInputPercent
                                    id="reducao_base_icms"
                                    name="reducao_base_icms"
                                    casasDecimais={2}
                                    value={formik.values.reducao_base_icms}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.reducao_base_icms })}
                                />
                                {formik.errors.reducao_base_icms && (
                                    <small className="p-error">{formik.errors.reducao_base_icms}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="reducao_base_st">Redução base ST.</label>
                                <MakoInputPercent
                                    id="reducao_base_st"
                                    name="reducao_base_st"
                                    casasDecimais={2}
                                    value={formik.values.reducao_base_st}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.reducao_base_st })}
                                />
                                {formik.errors.reducao_base_st && (
                                    <small className="p-error">{formik.errors.reducao_base_st}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="diferimento_icms">Diferimento ICMS</label>
                                <MakoInputPercent
                                    id="diferimento_icms"
                                    name="diferimento_icms"
                                    casasDecimais={2}
                                    value={formik.values.diferimento_icms}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.diferimento_icms })}
                                />
                                {formik.errors.diferimento_icms && (
                                    <small className="p-error">{formik.errors.diferimento_icms}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-5">
                                <label htmlFor="modalidade_contribuinte">Modalidade do contribuinte</label>
                                <Dropdown
                                    id="modalidade_contribuinte"
                                    name="modalidade_contribuinte"
                                    placeholder="Selecione uma modalidade"
                                    showClear
                                    options={TIPO_MODALIDADE_ST_CHOICE}
                                    filter
                                    filterBy="label"
                                    optionValue="id"
                                    optionLabel="label"
                                    value={formik.values.modalidade_contribuinte}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.modalidade_contribuinte })}
                                />
                                {formik.errors.modalidade_contribuinte && (
                                    <small className="p-error">{formik.errors.modalidade_contribuinte}</small>
                                )}
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel header="PIS" disabled={!formik.values.define_pis}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="cst_pis">CST PIS</label>
                                <Dropdown
                                    id="cst_pis"
                                    name="cst_pis"
                                    url={`/fiscal/cst-pis?limit=10000&empresa=${empresaSelecionadaId}&query={id,codigo,descricao}`}
                                    placeholder="Selecione um CST PIS"
                                    filter
                                    filterBy="codigo,descricao"
                                    optionValue="id"
                                    optionLabel="descricao"
                                    valueTemplate={cstSelecionadoTemplate}
                                    itemTemplate={opcaoCSTTemplate}
                                    value={formik.values.cst_pis}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.cst_pis })}
                                />
                                {formik.errors.cst_pis && <small className="p-error">{formik.errors.cst_pis}</small>}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="aliquota_pis">Alíquota PIS</label>
                                <MakoInputPercent
                                    id="aliquota_pis"
                                    name="aliquota_pis"
                                    casasDecimais={2}
                                    value={formik.values.aliquota_pis}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.aliquota_pis })}
                                />
                                {formik.errors.aliquota_pis && (
                                    <small className="p-error">{formik.errors.aliquota_pis}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="reducao_base_pis">Redução base PIS</label>
                                <MakoInputPercent
                                    id="reducao_base_pis"
                                    name="reducao_base_pis"
                                    casasDecimais={2}
                                    value={formik.values.reducao_base_pis}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.reducao_base_pis })}
                                />
                                {formik.errors.reducao_base_pis && (
                                    <small className="p-error">{formik.errors.reducao_base_pis}</small>
                                )}
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel header="Cofins" disabled={!formik.values.define_cofins}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="cst_cofins">CST Cofins</label>
                                <Dropdown
                                    id="cst_cofins"
                                    name="cst_cofins"
                                    url={`/fiscal/cst-cofins?limit=10000&empresa=${empresaSelecionadaId}&query={id,codigo,descricao}`}
                                    placeholder="Selecione um CST Cofins"
                                    filter
                                    filterBy="codigo,descricao"
                                    optionValue="id"
                                    optionLabel="descricao"
                                    valueTemplate={cstSelecionadoTemplate}
                                    itemTemplate={opcaoCSTTemplate}
                                    value={formik.values.cst_cofins}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.cst_cofins })}
                                />
                                {formik.errors.cst_cofins && (
                                    <small className="p-error">{formik.errors.cst_cofins}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="aliquota_cofins">Alíquota Cofins</label>
                                <MakoInputPercent
                                    id="aliquota_cofins"
                                    name="aliquota_cofins"
                                    casasDecimais={2}
                                    value={formik.values.aliquota_cofins}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.aliquota_cofins })}
                                />
                                {formik.errors.aliquota_cofins && (
                                    <small className="p-error">{formik.errors.aliquota_cofins}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="reducao_base_cofins">Redução base Cofins</label>
                                <MakoInputPercent
                                    id="reducao_base_cofins"
                                    name="reducao_base_cofins"
                                    casasDecimais={2}
                                    value={formik.values.reducao_base_cofins}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.reducao_base_cofins })}
                                />
                                {formik.errors.reducao_base_cofins && (
                                    <small className="p-error">{formik.errors.reducao_base_cofins}</small>
                                )}
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel header="IPI" disabled={!formik.values.define_ipi}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="cst_ipi">CST IPI</label>
                                <Dropdown
                                    id="cst_ipi"
                                    name="cst_ipi"
                                    url={`/fiscal/cst-ipi?limit=100&operacao=${operacaoFiscal?.tipo_movimento}&empresa=${empresaSelecionadaId}&query={id,codigo,descricao}`}
                                    placeholder="Selecione um CST IPI"
                                    filter
                                    filterBy="codigo,descricao"
                                    optionValue="id"
                                    optionLabel="descricao"
                                    valueTemplate={cstSelecionadoTemplate}
                                    itemTemplate={opcaoCSTTemplate}
                                    value={formik.values.cst_ipi}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.cst_ipi })}
                                />
                                {formik.errors.cst_ipi && <small className="p-error">{formik.errors.cst_ipi}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="aliquota_ipi">Alíquota IPI</label>
                                <MakoInputPercent
                                    id="aliquota_ipi"
                                    name="aliquota_ipi"
                                    casasDecimais={2}
                                    value={formik.values.aliquota_ipi}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.aliquota_ipi })}
                                />
                                {formik.errors.aliquota_ipi && (
                                    <small className="p-error">{formik.errors.aliquota_ipi}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="reducao_base_ipi">Redução base IPI</label>
                                <MakoInputPercent
                                    id="reducao_base_ipi"
                                    name="reducao_base_ipi"
                                    casasDecimais={2}
                                    value={formik.values.reducao_base_ipi}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.reducao_base_ipi })}
                                />
                                {formik.errors.reducao_base_ipi && (
                                    <small className="p-error">{formik.errors.reducao_base_ipi}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="enquadramento_ipi">Enquadramento de IPI *</label>
                                <Dropdown
                                    id="enquadramento_ipi"
                                    name="enquadramento_ipi"
                                    url="/fiscal/enquadramento-ipi?limit=10000"
                                    filter
                                    filterBy="codigo,descricao"
                                    optionValue="id"
                                    optionLabel="descricao"
                                    showClear={false}
                                    value={formik.values.enquadramento_ipi}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.enquadramento_ipi })}
                                />
                                {formik.errors.enquadramento_ipi && (
                                    <small className="p-error">{formik.errors.enquadramento_ipi}</small>
                                )}
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel header="FCP" disabled={!formik.values.define_fcp}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="aliquota_fcp_origem">Alíquota FCP origem</label>
                                <MakoInputPercent
                                    id="aliquota_fcp_origem"
                                    name="aliquota_fcp_origem"
                                    casasDecimais={2}
                                    value={formik.values.aliquota_fcp_origem}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.aliquota_fcp_origem })}
                                />
                                {formik.errors.aliquota_fcp_origem && (
                                    <small className="p-error">{formik.errors.aliquota_fcp_origem}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="aliquota_fcp_destino">Alíquota FCP destino</label>
                                <MakoInputPercent
                                    id="aliquota_fcp_destino"
                                    name="aliquota_fcp_destino"
                                    casasDecimais={2}
                                    value={formik.values.aliquota_fcp_destino}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.aliquota_fcp_destino })}
                                />
                                {formik.errors.aliquota_fcp_destino && (
                                    <small className="p-error">{formik.errors.aliquota_fcp_destino}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="percentual_fcp">% FCP</label>
                                <MakoInputPercent
                                    id="percentual_fcp"
                                    name="percentual_fcp"
                                    casasDecimais={2}
                                    value={formik.values.percentual_fcp}
                                    disabled={!formik.values.fcp}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.percentual_fcp })}
                                />
                                {formik.errors.percentual_fcp && (
                                    <small className="p-error">{formik.errors.percentual_fcp}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="fcp">FCP</label>
                                <SelectButton
                                    id="fcp"
                                    name="fcp"
                                    value={formik.values.fcp}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="percentual_diferimento">% FCP Diferimento</label>
                                <MakoInputPercent
                                    id="percentual_diferimento"
                                    name="percentual_diferimento"
                                    casasDecimais={2}
                                    value={formik.values.percentual_diferimento}
                                    disabled={!formik.values.fcp_diferimento}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.percentual_diferimento })}
                                />
                                {formik.errors.percentual_diferimento && (
                                    <small className="p-error">{formik.errors.percentual_diferimento}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="fcp_diferimento">FCP Diferimento</label>
                                <SelectButton
                                    id="fcp_diferimento"
                                    name="fcp_diferimento"
                                    value={formik.values.fcp_diferimento}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="percentual_sub_st">% FCP Sub. ST</label>
                                <MakoInputPercent
                                    id="percentual_sub_st"
                                    name="percentual_sub_st"
                                    casasDecimais={2}
                                    value={formik.values.percentual_sub_st}
                                    disabled={!formik.values.fcp_subst}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.percentual_sub_st })}
                                />
                                {formik.errors.percentual_sub_st && (
                                    <small className="p-error">{formik.errors.percentual_sub_st}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="fcp_subst">FCP Sub. ST</label>
                                <SelectButton
                                    id="fcp_subst"
                                    name="fcp_subst"
                                    value={formik.values.fcp_subst}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="percentual_retido_st">% FCP Retido ST</label>
                                <MakoInputPercent
                                    id="percentual_retido_st"
                                    name="percentual_retido_st"
                                    casasDecimais={2}
                                    value={formik.values.percentual_retido_st}
                                    disabled={!formik.values.fcp_retido_st}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.percentual_retido_st })}
                                />
                                {formik.errors.percentual_retido_st && (
                                    <small className="p-error">{formik.errors.percentual_retido_st}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="fcp_retido_st">FCP Retido ST</label>
                                <SelectButton
                                    id="fcp_retido_st"
                                    name="fcp_retido_st"
                                    value={formik.values.fcp_retido_st}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="aliq_consumidor_final">% FCP Consumidor final</label>
                                <MakoInputPercent
                                    id="aliq_consumidor_final"
                                    name="aliq_consumidor_final"
                                    casasDecimais={2}
                                    value={formik.values.aliq_consumidor_final}
                                    disabled={!formik.values.fcp_consumidor_final}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.aliq_consumidor_final })}
                                />
                                {formik.errors.aliq_consumidor_final && (
                                    <small className="p-error">{formik.errors.aliq_consumidor_final}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="fcp_consumidor_final">FCP Consumidor final</label>
                                <SelectButton
                                    id="fcp_consumidor_final"
                                    name="fcp_consumidor_final"
                                    value={formik.values.fcp_consumidor_final}
                                    onChange={formik.handleChange}
                                    optionValue="id"
                                    optionLabel="label"
                                    options={SIM_NAO_BOOLEAN}
                                />
                            </div>
                        </div>
                    </TabPanel>
                </TabView>
                <div className="p-grid p-justify-end p-mr-3 p-mt-3">
                    <Button
                        icon="pi pi-times"
                        type="reset"
                        className="p-button-danger p-button-outlined p-mr-2"
                        label="Fechar"
                        onClick={() => fecharModal()}
                    />
                    <Button icon="pi pi-save" type="submit" className="p-button-success" label="Gravar" />
                </div>
            </form>
        </Dialog>
    );
};

export const ModalRegraOperacaoFiscalNCM = forwardRef(Modal);
