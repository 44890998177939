import React, { useRef, useMemo } from "react";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";
import usePagamento from "@/hooks/usePagamento";
import MakoListagem from "@/components/MakoListagem";
import { Checkbox } from "primereact/checkbox";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";
import { Panel } from "primereact/panel";
import { useFormik } from "formik";
import { REPETICAO_DIAS_PARCELAS, TIPOS_PARCELAS } from "@/assets/constants/financeiro";
import classNames from "classnames";
import * as Yup from "yup";
import { ModalEditarPagamento } from "./modals/editarPagamentoModalForm";
import { parseMoeda } from "@/assets/util/util";
import { parseNumber } from "@/assets/helpers/number";

export const MultiplasParcelasForm = () => {
    const { pagamento, pagamentos, handleLancarParcelas, submitParcelas } = usePagamento();
    const modalRef = useRef(null);
    const toastRef = useRef(null);
    const listagemRef = useRef(null);

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: {
            tipo_parcela: null,
            repeticao: "30",
            quantidade: 1,
            data_primeira_parcela: new Date(),
            previsao: false,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                tipo_parcela: Yup.string()
                    .required("O campo 'tipo de parcela' é obrigatório.")
                    .typeError("Informe um tipo de parcela."),
                repeticao: Yup.string()
                    .required("O campo 'repetição' é obrigatório.")
                    .matches(/^\d+$/, "Para esse campo é válido apenas dígitos (0-9).")
                    .typeError("Selecione uma repetição."),
                quantidade: Yup.number()
                    .required("O campo 'quantidade' é obrigatório.")
                    .min(1, "O valor mínimo para esse campo é 1.")
                    .max(120, "O valor máximo para esse campo é 120."),
                data_primeira_parcela: Yup.date()
                    .required("O campo 'data 1ª parcela' é obrigatório.")
                    .min(pagamento.data_emissao, "A data da primeira parcela não pode ser inferior a data da emissão.")
                    .typeError("Informe uma data válida."),
                previsao: Yup.boolean().default(false),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            await handleLancarParcelas(values);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Falha ao lançar parcelas, por favor tente novamente.",
                    life: 3000,
                });
            }
        }
    }

    const booleanBodyTemplate = (value) => {
        if (value) return <span>Sim</span>;
        return <span>Não</span>;
    };

    const previsaoBodyTemplate = (rowData) => {
        return booleanBodyTemplate(rowData.previsao);
    };

    const actionBodyTemplate = (rowData, colProps) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-pencil"
                    disabled={rowData.quitado}
                    className="p-button-rounded p-button-warning"
                    onClick={() =>
                        modalRef.current?.abrirModal({
                            id: rowData.id,
                            index: colProps.rowIndex,
                            vencimento: rowData.vencimento,
                            valor: rowData.valor,
                            historico_original: rowData.historico_original,
                            previsao: rowData.previsao,
                        })
                    }
                />
            </div>
        );
    };

    const colunas = [
        {
            field: "vencimento",
            header: "Vencimento",
            dateFormat: "dd/MM/yyyy",
            style: { width: "12%" },
        },
        {
            field: "valor",
            header: "Valor",
            money: true,
            style: { width: "12%" },
        },
        { field: "historico_original", header: "Histórico" },
        {
            field: "previsao",
            header: "É previsão?",
            style: { width: "10%" },
            action: previsaoBodyTemplate,
        },
        {
            field: "actions",
            header: "Ações",
            align: "center",
            style: { width: "8%" },
            action: actionBodyTemplate,
        },
    ];

    const totalParcelas = useMemo(() => {
        return pagamentos.reduce((total, pag) => total + parseNumber(pag.valor), 0);
    }, [pagamentos]);

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toastRef} />
                    {!pagamento?.id ?
                        <Panel header="Definir parcelas" className="p-mb-3">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="p-fluid p-formgrid p-grid">
                                    <div className="p-field p-col-12 p-md-6">
                                        <label htmlFor="tipo_parcela">Tipo de parcela *</label>
                                        <Dropdown
                                            id="tipo_parcela"
                                            name="tipo_parcela"
                                            options={TIPOS_PARCELAS}
                                            value={formik.values.tipo_parcela}
                                            onChange={formik.handleChange}
                                            className={classNames({ "p-invalid": formik.errors.tipo_parcela })}
                                        />
                                        {formik.errors.tipo_parcela && (
                                            <small className="p-error">{formik.errors.tipo_parcela}</small>
                                        )}
                                    </div>
                                    <div className="p-field p-col-12 p-md-3">
                                        <label htmlFor="repeticao">Repetição (dias) *</label>
                                        <Dropdown
                                            id="repeticao"
                                            name="repeticao"
                                            options={REPETICAO_DIAS_PARCELAS}
                                            placeholder="Selecione ou digite"
                                            editable
                                            maxLength={4}
                                            value={formik.values.repeticao}
                                            onChange={formik.handleChange}
                                            className={classNames({ "p-invalid": formik.errors.repeticao })}
                                        />
                                        {formik.errors.repeticao && (
                                            <small className="p-error">{formik.errors.repeticao}</small>
                                        )}
                                    </div>
                                    <div className="p-field p-col-12 p-md-1">
                                        <label htmlFor="quantidade">Qtd. *</label>
                                        <InputNumber
                                            id="quantidade"
                                            min={1}
                                            useGrouping={false}
                                            value={formik.values.quantidade}
                                            onValueChange={formik.handleChange}
                                            className={classNames({ "p-invalid": formik.errors.quantidade })}
                                        />
                                        {formik.errors.quantidade && (
                                            <small className="p-error">{formik.errors.quantidade}</small>
                                        )}
                                    </div>
                                    <div className="p-field p-col-12 p-md-2">
                                        <label htmlFor="data_primeira_parcela">Data 1ª parcela *</label>
                                        <MakoCalendar
                                            id="data_primeira_parcela"
                                            name="data_primeira_parcela"
                                            valueCalendar={formik.values.data_primeira_parcela}
                                            onChange={formik.handleChange}
                                            className={classNames({ "p-invalid": formik.errors.data_primeira_parcela })}
                                        />
                                        {formik.errors.data_primeira_parcela && (
                                            <small className="p-error">{formik.errors.data_primeira_parcela}</small>
                                        )}
                                    </div>
                                </div>
                                <div className="p-fluid p-formgrid p-grid">
                                    <div className="p-field-checkbox p-col-12 p-md-2">
                                        <Checkbox
                                            id="previsao"
                                            name="previsao"
                                            checked={formik.values.previsao}
                                            onChange={formik.handleChange}
                                        />
                                        <label htmlFor="previsao">Gerar previsão?</label>
                                    </div>
                                </div>
                                <div className="p-fluid p-formgrid p-grid">
                                    <div className="p-field p-col-12 p-md-2 p-mt-2">
                                        <Button type="submit" icon="pi pi-refresh" label="Gerar" />
                                    </div>
                                </div>
                            </form>
                        </Panel>
                    : null}
                    {pagamentos ? (
                        <MakoListagem ref={listagemRef} titulo="Parcelas" colunas={colunas} dadosLocal={pagamentos} />
                    ) : null}
                    <div className="p-mt-3">
                        <div className={`p-text-right ${!submitParcelas && "p-error"}`}>
                            <h6>{`Total das parcelas: ${parseMoeda(totalParcelas, true)}`}</h6>
                        </div>
                        <div className="p-text-right">
                            <h6>{`Total esperado: ${parseMoeda(pagamentos[0]?.valor_total, true)}`}</h6>
                        </div>
                    </div>
                    <ModalEditarPagamento ref={modalRef} />
                </div>
            </div>
        </div>
    );
};
