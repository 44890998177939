import { ParametrosPage } from "@/pages/Configuracoes/Parametros";
import { FormParametrosPage } from "@/pages/Configuracoes/Parametros/form";
import { ApiServicosPage } from "@/pages/Configuracoes/ApiServicos";
import { ApiServicosForm } from "@/pages/Configuracoes/ApiServicos/form";
import { PermissoesPerfil } from "@/pages/Configuracoes/PermissoesPerfil";
import { PermissoesPerfilForm } from "@/pages/Configuracoes/PermissoesPerfil/form";
import { CamposPersonalizadosPage } from "@/pages/Configuracoes/CamposPersonalizados";
import { CamposPersonalizadosForm } from "@/pages/Configuracoes/CamposPersonalizados/form";
import { ImportarTabelaIbptPage } from "@/pages/Configuracoes/TabelaIbpt";
import { FeriadosPage } from "@/pages/Configuracoes/Feriados";

export const configuracoesRoutes = [
    { path: "/configuracoes/parametros", component: ParametrosPage },
    { path: "/configuracoes/parametros/form", component: FormParametrosPage },
    { path: "/configuracoes/tabela-ibpt/", component: ImportarTabelaIbptPage },
    { path: "/configuracoes/campos-personalizados", component: CamposPersonalizadosPage },
    { path: "/configuracoes/campos-personalizados/form", component: CamposPersonalizadosForm },
    { path: "/configuracoes/api-servicos", component: ApiServicosPage },
    { path: "/configuracoes/api-servicos/form", component: ApiServicosForm },
    { path: "/configuracoes/permissoes-perfis", component: PermissoesPerfil },
    { path: "/configuracoes/permissoes-perfis/form", component: PermissoesPerfilForm },
    { path: "/configuracoes/feriados", component: FeriadosPage },
];
