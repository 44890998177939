import React, { useRef } from "react";
import { Button } from "primereact/button";

import { ModalItensOrdemCompra } from "./ModalItensOrdemCompra";
import MakoListagem from "@/components/MakoListagem";
import { OP_CRUD_DJANGO } from "@/assets/util/persistenciaDjango";
import { parseMoeda } from "@/assets/util/util";
import useOrdemCompra from "@/hooks/useOrdemCompra";

export const ItensOrdemCompraForm = () => {
    const modalRef = useRef(null);
    const { dadosBasicos, itens, permiteAlterar, handleItem } = useOrdemCompra();

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-pencil"
                    disabled={!permiteAlterar}
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    onClick={() => modalRef.current?.abrirModal(rowData)}
                />
                <Button
                    icon="pi pi-trash"
                    disabled={!permiteAlterar}
                    className="p-button-rounded p-button-danger p-mb-1"
                    onClick={() => handleItem(rowData, OP_CRUD_DJANGO.deletar)}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <Button
                label="Adicionar item"
                icon="pi pi-plus"
                disabled={!permiteAlterar}
                className="p-button-success"
                onClick={() => modalRef.current?.abrirModal()}
            />
        </>
    );

    const colunas = [
        { field: "sku.codigo", header: "Código", style: { width: "8%" } },
        { field: "sku.descricao_derivada", header: "Item" },
        { field: "quantidade", header: "Qtd.", style: { width: "8%" }, decimal: true },
        { field: "cubagem", header: "Dimensão", style: { width: "8%" }, decimal: true },
        // { field: "quantidade_recebida", header: "Qtd. recebida", style: { width: "8%" } },
        { field: "valor_unitario", header: "Vlr. unitário", money: true, style: { width: "10%" } },
        { field: "unitario_outros_valores", header: "Outros valores", money: true, style: { width: "10%" } },
        { field: "total_NF_item", header: "Total na NF", money: true, style: { width: "10%" } },
        { field: "total_item", header: "Total do item", money: true, style: { width: "10%" } },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    return (
        <>
            <ModalItensOrdemCompra ref={modalRef} />
            <MakoListagem colunas={colunas} painelEsquerdo={cabecalhoTabela} dadosLocal={itens} />
            <div className="p-text-right p-mt-2">
                <h5>{`Total dos itens: ${parseMoeda(dadosBasicos.valor_total_itens, false)}`}</h5>
            </div>
        </>
    );
};
