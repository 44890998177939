import React from 'react';

import { DevolucaoFornecedorProvider } from '@/contexts/devolucaoFornecedorContext';
import { TabDevolucaoFornecedor } from './tabs';


export const VendaDevolucaoFornecedorForm = () => {

    return (
        <div className='card'>
            <DevolucaoFornecedorProvider>
                <TabDevolucaoFornecedor />
            </DevolucaoFornecedorProvider>
        </div>
    )
}