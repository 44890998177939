import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { PageBase } from "@/components/PageBase";
import { MakoInputRepresentante } from "@/components/MakoInputs/MakoInputRepresentante";
import { MakoInputFornecedor } from "@/components/MakoInputs/MakoInputFornecedor";
import { axiosPost, axiosPut } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

export const VinculoRepresentateFornecedorForm = () => {
    const { showLoading, hideLoading } = useLoading();
    const { showError, showSuccess } = useToast();
    const history = useHistory();
    const { state } = useLocation();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            id: null,
            representante: null,
            fornecedor: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                representante: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'representante' é obrigatório."),
                    })
                    .typeError("Informe um 'representante' válido."),
                fornecedor: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'fornecedor' é obrigatório."),
                    })
                    .typeError("Informe um 'fornecedor' válido."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            const vinculoRepresentanteFornecedor = {
                fornecedor: values.fornecedor.id,
                representante: values.representante.id,
            };
            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(
                    "/produtos/vinculo-fornecedor-representante/",
                    vinculoRepresentanteFornecedor
                );
                hideLoading();
                if (resposta.status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Vinculo cadastrado com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        cancelar();
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(
                    `/produtos/vinculo-fornecedor-representante/${values.id}/`,
                    vinculoRepresentanteFornecedor
                );
                hideLoading();
                if (resposta.status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Vinculo alterado com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        cancelar();
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push("/gestao/cadastros/vinculo-representante");
    };

    useState(() => {
        if (state && state.vinculo !== null) {
            setValues({ ...state.vinculo });
        }
    }, [state]);

    return (
        <PageBase>
            <h3>Formulário de vínculo representante e fornecedor</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-6">
                        <label htmlFor="representante">Representante *</label>
                        <MakoInputRepresentante
                            id="representante"
                            name="representante"
                            value={formik.values.representante}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.representante })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.representante && (
                            <small className="p-error">{formik.errors.representante}</small>
                        )}
                    </div>
                    <div className="p-field p-col-6">
                        <label htmlFor="fornecedor">Fornecedor *</label>
                        <MakoInputFornecedor
                            id="fornecedor"
                            name="fornecedor"
                            value={formik.values.fornecedor}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.fornecedor })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.fornecedor && <small className="p-error">{formik.errors.fornecedor}</small>}
                    </div>
                </div>
                <p>
                    <b>* Campos obrigatórios.</b>
                </p>
                <div className="p-grid p-col-12 p-md-6">
                    <Button label="Gravar" type="submit" className="p-button-info p-mr-2" />
                    <Button label="Cancelar" type="reset" className="p-button-danger" onClick={cancelar} />
                </div>
            </form>
        </PageBase>
    );
};
