import React, { useRef, useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputSwitch } from "primereact/inputswitch";
import { Checkbox } from "primereact/checkbox";
import { TabView, TabPanel } from "primereact/tabview";
import { MakoCalendar } from "@/components/MakoCalendar";
import { InputNumber } from "primereact/inputnumber";
import { FiscalMvaForm } from "@/pages/Fiscal/Fiscal/Ncm/mvaForm";
import { useFormik } from "formik";
import { axiosPatch, axiosPost } from "@/services/http";
import { dataToStr } from "@/assets/util/datas";
import { useHistory } from "react-router-dom";
import { FiscalCestForm } from "./cestForm";

import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";

const url = "/fiscal/ncm/";
const urlvoltar = "/fiscal/cadastros/ncm";

export const FiscalNcmForm = (props) => {
    const toastRef = useRef(null);
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();

    const ncmVazio = {
        id: "",
        codigo: "",
        descricao: "",
        aliquota_ipi: 0,
        vigencia_inicial: null,
        vigencia_final: null,
        reducao_base_ipi: 0,
        isento_ipi: false,
        ativo: true,
    };

    const { setValues, ...formik } = useFormik({
        initialValues: ncmVazio,
        onSubmit: handleSubmitNcm,
    });

    const [ativo, setAtivo] = useState(formik.values.ativo);

    async function handleSubmitNcm(values) {
        try {
            const formSchema = Yup.object().shape({
                codigo: Yup.string().required("O campo 'código' é obrigatório."),
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            values.vigencia_inicial = dataToStr(values.vigencia_inicial, "yyyy-MM-dd");
            values.vigencia_final = dataToStr(values.vigencia_final, "yyyy-MM-dd");

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(`${url}`, values);
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "NCM cadastrado com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        history.push({
                            path: "/fiscal/cadastros/ncm/form",
                            state: { ncm: resposta.data },
                        });
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                values.ativo = ativo;

                showLoading();
                const resposta = await axiosPatch(`${url}${values.id}/`, values);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "NCM alterado com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    useEffect(() => {
        if (props.location?.state && props.location.state.ncm) {
            setValues({
                ...props.location.state.ncm,
            });
        }
    }, [props.location, setValues]);

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    return (
        <div className="p-grid">
            <div className="p-col-12 tabview-demo">
                <div className="card">
                    <Toast ref={toastRef} />
                    <TabView className="tabview-custom">
                        <TabPanel header=" - NCM" leftIcon="pi pi-calendar">
                            <h5>{!formik.values.id ? "Novo NCM" : "Manutenção de NCM"}</h5>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="p-fluid p-formgrid p-grid">
                                    <div className="p-field p-col-12 p-md-2">
                                        <label htmlFor="codigo">Código *</label>
                                        <InputText
                                            id="codigo"
                                            name="codigo"
                                            value={formik.values.codigo}
                                            onChange={formik.handleChange}
                                            keyfilter={/^[0-9]/}
                                            className={classNames({
                                                "p-invalid": formik.errors.codigo,
                                            })}
                                            tooltip="Código do atributo Exemplo: Código 'T' do Atributo 'Tamanho'."
                                            autoComplete="off"
                                            autoFocus
                                        />
                                        {formik.errors.codigo && (
                                            <small className="p-error">{formik.errors.codigo}</small>
                                        )}
                                    </div>
                                    <div className="p-field p-col-12 p-md-6">
                                        <label htmlFor="descricao">Descrição *</label>
                                        <InputText
                                            id="descricao"
                                            name="descricao"
                                            value={formik.values.descricao}
                                            onChange={formik.handleChange}
                                            className={classNames({
                                                "p-invalid": formik.errors.descricao,
                                            })}
                                            tooltip="Descrição do Ncm."
                                            autoComplete="off"
                                        />
                                        {formik.errors.descricao && (
                                            <small className="p-error">{formik.errors.descricao}</small>
                                        )}
                                    </div>
                                    <div className="p-field p-col-12 p-md-2">
                                        <label htmlFor="aliquota_ipi">Alíquota IPI</label>
                                        <InputNumber
                                            id="aliquota_ipi"
                                            name="aliquota_ipi"
                                            // prefix="%"
                                            min={0}
                                            mode="decimal"
                                            minFractionDigits={2}
                                            maxFractionDigits={5}
                                            value={formik.values.aliquota_ipi}
                                            onValueChange={formik.handleChange}
                                            className={classNames({ "p-invalid": formik.errors.aliquota_ipi })}
                                            autoComplete="off"
                                            autoFocus
                                        />
                                        {formik.errors.aliquota_ipi && (
                                            <small className="p-error">{formik.errors.aliquota_ipi}</small>
                                        )}
                                    </div>
                                    <div className="p-field p-col-12 p-md-2">
                                        <label htmlFor="reducao_base_ipi">Redução base IPI</label>
                                        <InputNumber
                                            id="reducao_base_ipi"
                                            name="reducao_base_ipi"
                                            // prefix="%"
                                            min={0}
                                            mode="decimal"
                                            minFractionDigits={2}
                                            maxFractionDigits={2}
                                            value={formik.values.reducao_base_ipi}
                                            onValueChange={formik.handleChange}
                                            className={classNames({ "p-invalid": formik.errors.reducao_base_ipi })}
                                            autoComplete="off"
                                            autoFocus
                                        />
                                        {formik.errors.reducao_base_ipi && (
                                            <small className="p-error">{formik.errors.reducao_base_ipi}</small>
                                        )}
                                    </div>
                                </div>
                                <div className="p-fluid p-formgrid p-grid">
                                    <div className="p-field p-col-12 p-md-3">
                                        <label htmlFor="vigencia_inicial">Vigência inicial</label>
                                        <MakoCalendar
                                            id="vigencia_inicial"
                                            name="vigencia_inicial"
                                            valueCalendar={formik.values.vigencia_inicial}
                                            onChange={formik.handleChange}
                                            className={classNames({
                                                "p-invalid": formik.errors.vigencia_inicial,
                                            })}
                                        />
                                        {formik.errors.vigencia_inicial && (
                                            <small className="p-error">{formik.errors.vigencia_inicial}</small>
                                        )}
                                    </div>

                                    <div className="p-field p-col-12 p-md-3">
                                        <label htmlFor="vigencia_final">Vigência final</label>
                                        <MakoCalendar
                                            id="vigencia_final"
                                            name="vigencia_final"
                                            valueCalendar={formik.values.vigencia_final}
                                            onChange={formik.handleChange}
                                            className={classNames({
                                                "p-invalid": formik.errors.vigencia_final,
                                            })}
                                        />
                                        {formik.errors.vigencia_final && (
                                            <small className="p-error">{formik.errors.vigencia_final}</small>
                                        )}
                                    </div>
                                    <div className="p-field-checkbox p-col-12 p-md-3" style={{ paddingLeft: "2%" }}>
                                        <br></br>
                                        <Checkbox
                                            inputId="isento_ipi"
                                            id="isento_ipi"
                                            name="isento_ipi"
                                            onChange={formik.handleChange}
                                            checked={formik.values.isento_ipi}
                                            value={formik.values.isento_ipi}
                                            className="p-mt-5"
                                        />
                                        <label htmlFor="isento_ipi" className="p-mt-5">
                                            Isento IPI
                                        </label>
                                    </div>
                                </div>
                                <div className="p-fluid p-formgrid p-grid">
                                    <div className="p-field p-col-1 ">
                                        <h5>
                                            <label htmlFor="status">Status</label>
                                        </h5>
                                        <InputSwitch
                                            id="ativo"
                                            name="ativo"
                                            checked={ativo}
                                            disabled={formik.values.id ? false : true}
                                            value={formik.values.ativo}
                                            onChange={(e) => setAtivo(e.value)}
                                        />
                                    </div>
                                </div>

                                <p>
                                    <b>* Campos obrigatórios.</b>
                                </p>

                                <div className="p-grid p-col-12 p-md-6">
                                    <Button
                                        icon="pi pi-save"
                                        label="Gravar"
                                        type="submit"
                                        className="p-button-info p-mr-2 p-mb-6"
                                    />
                                    <Button
                                        icon="pi pi-times"
                                        label="Voltar"
                                        type="reset"
                                        className="p-button-danger p-mr-2 p-mb-6"
                                        onClick={cancelar}
                                    />
                                </div>
                            </form>
                        </TabPanel>

                        <TabPanel
                            header=" - Cest Relacionados"
                            disabled={formik.values.id ? false : true}
                            leftIcon="pi pi-calendar"
                        >
                            <FiscalCestForm ncm={formik.values} />
                        </TabPanel>
                        <TabPanel header=" - MVA" disabled={formik.values.id ? false : true} leftIcon="pi pi-calendar">
                            <FiscalMvaForm props={formik.values} />
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        </div>
    );
};
