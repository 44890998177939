import { useState } from "react";

const useLocalStorage = (key, initialValue = null) => {
    const [storedValue, setStoredValue] = useState(() => {
        if (typeof window === "undefined") return initialValue;

        try {
            const item = window.localStorage.getItem(key);
            if(key.includes("_FILTROS") && key.includes("_tabela")) {
                const parsed = JSON.parse(item);
                return parsed ? parsed : initialValue
            }
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            return initialValue;
        }
    });

    const setValue = (value) => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);

            if (typeof window !== "undefined") {
                window.localStorage.setItem(key, JSON.stringify(valueToStore));
            }
        } catch (error) {
            console.log(error);
        }
    };

    const removeValue = () => {
        window.localStorage.removeItem(key);
    };

    return [storedValue, setValue, removeValue];
};

export default useLocalStorage;
