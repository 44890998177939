import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { add, differenceInDays, parseISO, isEqual } from "date-fns";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { ModalEditarParcela } from "./ModalEditarParcela";
import { ModalTaxaParcelas } from "./ModalTaxaParcelas";
import { MakoListagem } from "@/components/MakoListagem";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE } from "@/assets/constants/financeiro";
import { VENDA_FINANCEIRO_MAXIMO_DIAS_AVISTA } from "@/assets/constants/parametros";
import { calcularJurosComposto } from "@/assets/util/calcFinanceiros";
import { parseNumber } from "@/assets/helpers/number";
import { axiosGet, axiosPut } from "@/services/http";
import useVenda from "@/hooks/useVenda";
import useToast from "@/hooks/useToast";
import useParam from "@/hooks/useParam";
import useKey from "@/hooks/useKey";

export const FinanceiroVendaForm = () => {
    const [planoRecebimento, setPlanoRecebimento] = useState(null);
    const [taxaAplicada, setTaxaAplicada] = useState(0);
    const [formasRecebimento, setFormasRecebimento] = useState([]);
    const [parcelas, setParcelas] = useState([]);
    const listagemRef = useRef(null);
    const modalParcelaRef = useRef(null);
    const modalTaxaRef = useRef(null);
    const { getParam } = useParam();
    const { dadosBasicos, vendedor, vendaFinalizada, setParcelasVenda, handleParcelasVenda, handleLimparParcelas } =
        useVenda();
    const { showWarning, showError } = useToast();

    useKey("keydown", "F2", () => modalTaxaRef.current?.abrirModal());

    const { setFieldValue, ...formik } = useFormik({
        initialValues: {
            valor_frete: 0,
            data_venc_entrada: null,
            forma_recebimento_entrada: null,
            valor_total_com_desconto: 0,
            valor_entrada: 0,
            voucher: null,
            quantidade_parcelas: null,
            data_vencimento: null,
            forma_recebimento: null,
            valor_financiado: 0,
            requer_aprovacao_desconto: false,
        },
        onSubmit: handleSubmit,
    });

    const validarMinimoEntrada = (valorEntrada, valorTotal) => {
        const { percentual_minimo_entrada } = planoRecebimento;
        return valorEntrada >= valorTotal * (parseNumber(percentual_minimo_entrada) / 100);
    };

    async function calcularEncargosItensVenda() {
        const { status } = await axiosPut(`/vendas/${dadosBasicos?.id}/calcular-encargos-itens/`);
        if (status === 500) {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível calcular os encargos dos itens.",
                life: 3000,
            });
        }
    }

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                forma_recebimento: Yup.object()
                    .nullable()
                    .when("valor_financiado", {
                        is: (val) => val > 0,
                        then: Yup.object()
                            .required("Escolha uma forma de pagamento.")
                            .typeError("Escolha uma forma de pagamento."),
                    }),
                valor_entrada: Yup.number().test(
                    "Validation min entrada",
                    `Esse plano exige ${planoRecebimento.percentual_minimo_entrada} % de entrada.`,
                    (value) => validarMinimoEntrada(value, values.valor_total_com_desconto)
                ),
                forma_recebimento_entrada: Yup.object()
                    .nullable()
                    .when("valor_entrada", {
                        is: (val) => val > 0,
                        then: Yup.object()
                            .required("Escolha uma forma de pagamento para a entrada.")
                            .typeError("Escolha uma forma de pagamento para a entrada."),
                    }),
                data_venc_entrada: Yup.date()
                    .nullable()
                    .when("valor_entrada", {
                        is: (val) => val > 0,
                        then: Yup.date()
                            .required("O campo 'vencimento da entrada' é obrigatório.")
                            .typeError("Informe uma data válida."),
                    }),
                quantidade_parcelas: Yup.number().when("valor_financiado", {
                    is: (val) => val > 0,
                    then: Yup.number()
                        .typeError("Selecione a quantidade de parcelas.")
                        .required("O campo 'quantidade de parcelas' é obrigatório."),
                    otherwise: Yup.number().nullable(),
                }),
                data_vencimento: Yup.date()
                    .required("O campo 'vencimento 1ª parcela' é obrigatório")
                    .typeError("Informe uma data válida.")
                    .notRequired()
                    .when("quantidade_parcelas", {
                        is: (val) => val === 1 && parcelas.length === 1 && parcelas[0].value === 1,
                        then: Yup.date().nullable().notRequired(),
                    })
                    .when("data_venc_entrada", {
                        is: (val) => val !== null,
                        then: Yup.date()
                            .min(
                                values.data_venc_entrada || new Date(),
                                "A data de vencimento da parcela não pode ser inferior a data de vencimento da entrada"
                            )
                            .nullable()
                            .notRequired(),
                    }),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            if (values.data_venc_entrada && isEqual(values.data_vencimento, values.data_venc_entrada)) {
                showWarning({
                    summary: "Aviso!",
                    detail: "A data de início das parcelas e da entrada são iguais. Verifique!",
                    life: 5000,
                });
            } else {
                await handleParcelasVenda(values, planoRecebimento);
                listagemRef.current?.buscarDados();
                await calcularEncargosItensVenda();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const listarFormasRecebimento = useCallback(async () => {
        if (dadosBasicos) {
            const { status, data } = await axiosGet(
                `/financeiro/planos-recebimentos/${dadosBasicos.plano_recebimento}/`
            );
            if (status === 200) {
                const { formas_recebimento, ...rest } = data;
                let _parcelas = [];
                for (let parcela = rest.minimo_parcelas; parcela <= rest.maximo_parcelas; parcela++) {
                    _parcelas.push({ value: parcela, label: parcela === 1 ? "1 parcela" : `${parcela} parcelas` });
                }
                const taxa_alvo = parseNumber(rest.taxa_alvo);
                const taxa_minima = parseNumber(rest.taxa_minima);
                if (_parcelas.length === 1) setFieldValue("quantidade_parcelas", _parcelas[0].value);
                setParcelas(_parcelas);
                setPlanoRecebimento({ ...rest, taxa_alvo, taxa_minima });
                setTaxaAplicada(taxa_alvo);
                setFormasRecebimento(formas_recebimento);
            } else {
                showError({
                    summary: "Erro!",
                    detail: "Desculpe, não conseguimos carregar os planos de recebimentos.",
                    life: 3000,
                });
            }
        }
    }, [setFieldValue, showError, dadosBasicos]);

    useEffect(() => {
        listarFormasRecebimento();
    }, [listarFormasRecebimento]);

    const projetarData1Parcela = useCallback(() => {
        if (planoRecebimento && dadosBasicos) {
            const [intervalo] = planoRecebimento.intervalo_parcelas.split(",", 1);
            const { data } = dadosBasicos;
            const dataVenc1Parcela =
                data instanceof Date ? add(data, { days: intervalo }) : add(parseISO(data), { days: intervalo });
            setFieldValue("data_vencimento", dataVenc1Parcela);
        }
    }, [dadosBasicos, planoRecebimento, setFieldValue]);

    useEffect(() => {
        projetarData1Parcela();
    }, [projetarData1Parcela]);

    const calcularValorBase = useCallback(() => {
        if (dadosBasicos) {
            const { valor_total_av } = dadosBasicos;
            const valorBase = parseNumber(valor_total_av) + formik.values.valor_frete;
            setFieldValue("valor_total_com_desconto", valorBase);
        }
    }, [dadosBasicos, formik.values.valor_frete, setFieldValue]);

    useEffect(() => {
        calcularValorBase();
    }, [calcularValorBase]);

    const calcularValorFinanciado = useCallback(() => {
        if (dadosBasicos && planoRecebimento && formik.values.data_vencimento) {
            const valorParaFinanciar =
                formik.values.valor_total_com_desconto - formik.values.valor_entrada - formik.values.valor_frete;
            if (valorParaFinanciar > 0) {
                const dataVenda = parseISO(dadosBasicos.data);
                const dataVenc1Parcela = parseISO(formik.values.data_vencimento);
                const diasEntreVendaEVenc = differenceInDays(dataVenda, dataVenc1Parcela);
                const diasVendaAVista = getParam(VENDA_FINANCEIRO_MAXIMO_DIAS_AVISTA);
                let parcelas = formik.values.quantidade_parcelas;
                if (
                    (diasVendaAVista && diasEntreVendaEVenc < parseInt(diasVendaAVista.valor)) ||
                    diasEntreVendaEVenc < 14
                ) {
                    parcelas -= 1;
                }
                const taxaAlvo = planoRecebimento?.taxa_alvo;
                const maxDescontoPermitido = taxaAlvo - (taxaAlvo * parseNumber(vendedor.max_desconto_encargos)) / 100;
                if (taxaAplicada < maxDescontoPermitido) setFieldValue("requer_aprovacao_desconto", true);
                let valorAPrazo = calcularJurosComposto(valorParaFinanciar, taxaAplicada, parcelas);
                valorAPrazo += formik.values.valor_frete;
                setFieldValue("valor_financiado", valorAPrazo);
            } else {
                setFieldValue("valor_financiado", 0);
            }
        }
    }, [
        dadosBasicos,
        vendedor,
        formik.values.data_vencimento,
        formik.values.quantidade_parcelas,
        formik.values.valor_entrada,
        formik.values.valor_total_com_desconto,
        formik.values.valor_frete,
        getParam,
        planoRecebimento,
        taxaAplicada,
        setFieldValue,
    ]);

    useEffect(() => {
        calcularValorFinanciado();
    }, [calcularValorFinanciado]);

    const parcelaTemplate = (rowData) => {
        const { numero_parcela, valor_entrada, quantidade_parcelas } = rowData;
        if (numero_parcela === 0) return <span>ENTRADA</span>;
        const qtdParcelasFinanciadas = valor_entrada > 0 ? quantidade_parcelas - 1 : quantidade_parcelas;
        return <span>{`${numero_parcela}/${qtdParcelasFinanciadas}`}</span>;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    disabled={vendaFinalizada}
                    onClick={() => {
                        const _parcela = {
                            id_parcela: rowData.id,
                            dia_vencimento: rowData.data_vencimento.split("-")[2],
                            valor_parcela: parseNumber(rowData.valor_parcela),
                            replicar: false,
                        };
                        modalParcelaRef.current?.abrirModal(_parcela);
                    }}
                />
            </div>
        );
    };

    const colunas = [
        { field: "data_vencimento", header: "Vencimento", dateFormat: "dd/MM/yyyy" },
        { field: "parcelas", header: "Nº parcela", action: (e) => parcelaTemplate(e) },
        { field: "forma_recebimento.descricao", header: "Forma de recebimento", style: { width: "50%" } },
        { field: "valor_parcela", header: "Valor", money: true },
        {
            field: "actions",
            header: "Ações",
            style: { width: "8%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    const requerVoucher = useMemo(() => {
        if (formik.values.forma_recebimento_entrada) {
            const tipoVoucher = TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE.find(
                (e) => e.label.toLowerCase() === "vale-troca"
            );
            return tipoVoucher.id === formik.values.forma_recebimento_entrada?.tipo.id;
        }
        return false;
    }, [formik.values.forma_recebimento_entrada]);

    const aposBuscarParcelas = useCallback(
        (parcelas) => {
            setParcelasVenda(parcelas);
            return parcelas;
        },
        [setParcelasVenda]
    );

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-frete">Valor do frete</label>
                        <MakoInputMoeda
                            id="valor-frete"
                            name="valor_frete"
                            valueMoeda={formik.values.valor_frete}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-total-desconto">Valor total</label>
                        <MakoInputMoeda
                            id="valor-total-desconto"
                            name="valor_total_com_desconto"
                            disabled
                            valueMoeda={formik.values.valor_total_com_desconto}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="voucher">Voucher</label>
                        <Dropdown
                            id="voucher"
                            name="voucher"
                            disabled={!requerVoucher}
                            buscar={requerVoucher}
                            url={`/vendas/voucher/?cliente=${dadosBasicos?.cliente?.id}&situacao=A`}
                            optionValue="id"
                            optionLabel="codigo"
                            value={formik.values.voucher}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.voucher })}
                        />
                        {formik.errors.voucher && <small className="p-error">{formik.errors.voucher}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-entrada">Valor recebido / entrada</label>
                        <MakoInputMoeda
                            id="valor-entrada"
                            name="valor_entrada"
                            min={0}
                            max={formik.values.valor_total_com_desconto}
                            valueMoeda={formik.values.valor_entrada}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor_entrada })}
                        />
                        {formik.errors.valor_entrada && (
                            <small className="p-error">{formik.errors.valor_entrada}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="venc-entrada">Vencimento da entrada</label>
                        <MakoCalendar
                            id="venc-entrada"
                            name="data_venc_entrada"
                            minDate={dadosBasicos ? dadosBasicos.data : new Date()}
                            disabled={formik.values.valor_entrada === 0}
                            valueCalendar={formik.values.data_venc_entrada}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_venc_entrada })}
                        />
                        {formik.errors.data_venc_entrada && (
                            <small className="p-error">{formik.errors.data_venc_entrada}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="forma-pgto-entrada">Forma de recebimento da entrada</label>
                        <Dropdown
                            id="forma-pgto-entrada"
                            name="forma_recebimento_entrada"
                            disabled={formik.values.valor_entrada === 0}
                            url="/financeiro/formas-recebimentos/?utilizar_entrada=true"
                            optionLabel="descricao"
                            value={formik.values.forma_recebimento_entrada}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.forma_recebimento_entrada })}
                        />
                        {formik.errors.forma_recebimento_entrada && (
                            <small className="p-error">{formik.errors.forma_recebimento_entrada}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="qtd-parcelas">Qtd. de parcelas</label>
                        <Dropdown
                            id="qtd-parcelas"
                            name="quantidade_parcelas"
                            options={parcelas}
                            max={120}
                            value={formik.values.quantidade_parcelas}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.quantidade_parcelas })}
                        />
                        {formik.errors.quantidade_parcelas && (
                            <small className="p-error">{formik.errors.quantidade_parcelas}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="venc-inicial">Vencimento 1ª parcela *</label>
                        <MakoCalendar
                            id="venc-inicial"
                            name="data_vencimento"
                            minDate={dadosBasicos ? dadosBasicos.data : new Date()}
                            valueCalendar={formik.values.data_vencimento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_vencimento })}
                        />
                        {formik.errors.data_vencimento && (
                            <small className="p-error">{formik.errors.data_vencimento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="forma-pgto">Forma de recebimento</label>
                        <Dropdown
                            id="forma-pgto"
                            name="forma_recebimento"
                            options={formasRecebimento}
                            optionLabel="descricao"
                            value={formik.values.forma_recebimento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.forma_recebimento })}
                        />
                        {formik.errors.forma_recebimento && (
                            <small className="p-error">{formik.errors.forma_recebimento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-financiado">Valor à receber</label>
                        <MakoInputMoeda
                            id="valor-financiado"
                            name="valor_financiado"
                            disabled
                            valueMoeda={formik.values.valor_financiado}
                        />
                    </div>
                </div>
                {planoRecebimento && (
                    <h5 className="p-m-0">
                        {`Intervalo em dias das parcelas: ${planoRecebimento.intervalo_parcelas.split(",").join(", ")}`}
                    </h5>
                )}
                <CamposObrigatorios className="p-mt-0" singular />
                <Button
                    type="submit"
                    icon="pi pi-plus"
                    label="Gerar Parcelas"
                    disabled={vendaFinalizada}
                    className="p-mr-2 p-mb-2"
                />
                <Button
                    type="button"
                    icon="pi pi-trash"
                    label="Limpar Parcelas"
                    disabled={vendaFinalizada}
                    className="p-button-warning p-mb-2"
                    onClick={() => handleLimparParcelas(listagemRef.current?.buscarDados)}
                />
            </form>
            <MakoListagem
                ref={listagemRef}
                colunas={colunas}
                urlPesquisa={`/vendas/parcelas-vendas/?venda=${dadosBasicos?.id}`}
                aposPesquisar={aposBuscarParcelas}
                configTabela={{
                    lazy: true,
                    paginator: false,
                }}
            />
            <ModalEditarParcela ref={modalParcelaRef} />
            <ModalTaxaParcelas
                ref={modalTaxaRef}
                taxaAlvo={planoRecebimento?.taxa_alvo}
                taxaMinima={planoRecebimento?.taxa_minima}
                onFechar={(e) => setTaxaAplicada(e)}
            />
        </>
    );
};
