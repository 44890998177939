import React, { useCallback, useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { useHistory } from "react-router-dom";
import { axiosPost, axiosPut, axiosGet } from "@/services/http";
import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";

import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";

const url = "/produtos/marcas-fabricantes/";
const urlvoltar = "/produto/cadastros/marcas-fabricante";

export const ProdutoMarcasFabricanteForm = (props) => {
    const history = useHistory();
    const [marcas, setMarcas] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const [formatDocumento] = useFormatCNPJCPF()

    const paramVazio = {
        id: "",
        nome_fabricante: "",
        perfil_fabricante: "",
        marca: "",
    };

    const formik = useFormik({
        initialValues: props.location.state ? props.location.state.marcasFabricante : paramVazio,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            if (values.perfil_fabricante && values.perfil_fabricante.id)
                values.perfil_fabricante = values.perfil_fabricante.id;

            const formSchema = Yup.object().shape({
                nome_fabricante: Yup.string().required("O campo 'nome do fabricante' é obrigatório."),
                perfil_fabricante: Yup.number().required("O campo 'perfil do fabricante' é obrigatório.").typeError("Informe um 'fabricante' válido."),
                marca: Yup.number().required("O campo 'marca' é obrigatório.").typeError("Informe uma 'marca' válida."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(`${url}`, values);
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Marca de fabricante cadastrada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(`${url}${values.id}/`, values);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Marca de fabricante alterada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }
    const listarMarcas = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/produtos/marcas?limit=3000");
        hideLoading();

        if (resposta.status === 200 || resposta.status === 201) setMarcas(resposta.data.results);
    }, [showLoading, hideLoading]);

    const autoCompleteFornecedorTemplate = (valor) => {
        valor.label = `${valor.nome} - ${formatDocumento(valor.identificacao)}`;
        return `${valor.nome} - ${formatDocumento(valor.identificacao)}`;
    };

    useEffect(() => {
        if (formik.values.marca.id) formik.values.marca = formik.values.marca.id;
        if (formik.values.perfil_fabricante)
            formik.values.perfil_fabricante.label = `${formik.values.perfil_fabricante.identificacao} - ${formik.values.perfil_fabricante.nome}`;
        listarMarcas();
    }, [listarMarcas, formik.values]);

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <h3>{!formik.values.id ? "Nova marca de fabricante" : "Manutenção de marca de fabricante"}</h3>

                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-6 p-md-4">
                                <label htmlFor="marca">Marca * </label>
                                <Dropdown
                                    id="marca"
                                    name="marca"
                                    placeholder="Selecione uma marca"
                                    filter
                                    filterBy="nome"
                                    options={marcas}
                                    optionValue="id"
                                    optionLabel="nome"
                                    value={formik.values.marca}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.marca })}
                                />
                                {formik.errors.marca && <small className="p-error">{formik.errors.marca}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-8">
                                <label htmlFor="nome_fabricante">Nome do fabricante *</label>
                                <InputText
                                    id="nome_fabricante"
                                    name="nome_fabricante"
                                    keyfilter={/[A-Za-z0-9- ]/}
                                    value={formik.values.nome_fabricante}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.nome_fabricante })}
                                    autoComplete="off"
                                    autoFocus
                                />
                                {formik.errors.nome_fabricante && (
                                    <small className="p-error">{formik.errors.nome_fabricante}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-8">
                                <label htmlFor="perfil_fabricante">Fabricante *</label>
                                <MakoAutoComplete
                                    id="perfil_fabricante"
                                    name="perfil_fabricante"
                                    placeholder="Busque pelo nome ou identificação... (min 4 caracteres)"
                                    minCaracteresBusca={4}
                                    value={formik.values.perfil_fabricante}
                                    onChange={formik.handleChange}
                                    itemTemplate={autoCompleteFornecedorTemplate}
                                    field="label"
                                    urlSearch={"/pessoas/perfis?search="}
                                    className={classNames({ "p-invalid": formik.errors.perfil_fabricante })}
                                />
                                {formik.errors.perfil_fabricante && (
                                    <small className="p-error">{formik.errors.perfil_fabricante}</small>
                                )}
                            </div>
                        </div>

                        <p>
                            <b>* Campos obrigatórios.</b>
                        </p>

                        <div className="p-grid p-col-12 p-md-6">
                            <Button label="Gravar" type="submit" className="p-button-info p-mr-2 p-mb-6" />
                            <Button
                                label="Cancelar"
                                type="reset"
                                className="p-button-danger p-mr-2 p-mb-6"
                                onClick={cancelar}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
