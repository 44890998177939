import React from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { Button } from "primereact/button";

import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { MakoInputQuantidadeSku } from "@/components/MakoInputs/MakoInputQuantidadeSku";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";

import useToast from "@/hooks/useToast";

import { InputNumber } from "primereact/inputnumber";

const ModalItemSemVenda = ({ fecharModal, onConfirm }) => {
    const { showError } = useToast();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            id: null,
            _id: null,
            sku: null,
            item_venda: null, //
            quantidade: 0, //
            valor_unitario: 0,
            valor_total: 0,
            largura: null,
            comprimento: null,
            altura: null,
            cubagem: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        const { sku } = values;

        values.descricao_reduzida = sku.descricao_reduzida;
        values.unidade_medida = sku.unidade_padrao;
        values.cubagem = sku.cubagem;

        try {
            const formSchema = Yup.object().shape({
                sku: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'sku' é obrigatório"),
                    })
                    .typeError("Informe um 'sku' válido"),
                quantidade: Yup.number()
                    .required("O campo 'quantidade' é obrigatório")
                    .min(0.000001, "A quantidad minima é 0.000001")
                    .typeError("Informe uma 'quantidade' válida"),
                unidade_medida: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'unidade medida' é obrigatório"),
                    })
                    .typeError("Informe uma 'unidade de medida' válida"),
                valor_unitario: Yup.number()
                    .min(0.01, "O campo 'valor unitário' não pode ser ZERO.")
                    .required("O campo 'valor unitário' é obrigatório")
                    .typeError("Informe uma 'valor unitário' válido"),
                valor_total: Yup.number()
                    .min(0.01, "O campo 'valor unitário' não pode ser ZERO.")
                    .required("O campo 'valor unitário' é obrigatório")
                    .typeError("Informe uma 'valor unitário' válido"),
            });

            if (!values.cubagem) values.cubagem = 1;

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (typeof onConfirm === "function") {
                if (onConfirm(values, false)) {
                    // formik.resetForm();
                    fecharModal();
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos processar sua requisição.",
                    life: 3000,
                });
            }
        }
    }

    const handleChangeQuantidade = (e) => {
        setFieldValue("valor_total", formik.values.valor_unitario * e.value);
        formik.handleChange(e);
    };

    const handleChangeValorUnitario = (e) => {
        setFieldValue("valor_total", formik.values.quantidade * e.value);
        formik.handleChange(e);
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <label htmlFor="sku" className="p-mt-2">
                        Informe produto o sem venda:
                    </label>
                    <MakoBuscaSkuPersonalizada
                        id="sku"
                        name="sku"
                        placeholder="Digite para pesquisar..."
                        skuValue={formik.values.sku}
                        skuChange={(e) => setFieldValue("sku", e)}
                        skuError={formik.errors.sku}
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="quantidade">Quantidade *</label>
                    <MakoInputQuantidadeSku
                        id="quantidade"
                        name="quantidade"
                        permiteFracionario
                        value={formik.values.quantidade}
                        onValueChange={handleChangeQuantidade}
                        className={classNames({ "p-invalid": formik.errors.quantidade })}
                    />
                    {formik.errors.quantidade && <small className="p-error">{formik.errors.quantidade}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="valor_unitario">Valor Unitário *</label>
                    <MakoInputMoeda
                        id="valor_unitario"
                        name="valor_unitario"
                        permiteFracionario
                        value={formik.values.valor_unitario}
                        onValueChange={handleChangeValorUnitario}
                        className={classNames({ "p-invalid": formik.errors.valor_unitario })}
                    />
                    {formik.errors.valor_unitario && <small className="p-error">{formik.errors.valor_unitario}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="valor_total">Total *</label>
                    <MakoInputMoeda
                        id="valor_total"
                        name="valor_total"
                        value={formik.values.valor_total}
                        onValueChange={formik.handleChange}
                        disabled
                        className={classNames({ "p-invalid": formik.errors.valor_total })}
                    />
                    {formik.errors.valor_total && <small className="p-error">{formik.errors.valor_total}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="cubagem">Cubagem</label>
                    <InputNumber
                        id="cubagem"
                        name="cubagem"
                        mode="decimal"
                        minFractionDigits={0}
                        maxFractionDigits={4}
                        value={formik.values.cubagem}
                        onValueChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.cubagem })}
                    />
                    {formik.errors.cubagem && <small className="p-error">{formik.errors.cubagem}</small>}
                </div>
            </div>
            <div>
                <Button label="Gravar" className="p-button-success p-mr-2" type="submit" />
                <Button label="Cancelar" className="p-button-danger" onClick={fecharModal} />
            </div>
        </form>
    );
};

export default React.memo(ModalItemSemVenda);
