import React from "react";
import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";

import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { SITUACAO_DEVOLUCAO } from "@/assets/constants/vendas";

export const ResumoDevolucao = ({ devolucao }) => {
    function traduzirSituacaoDevolucao(value) {
        const situacao = SITUACAO_DEVOLUCAO.find((s) => s.value === `${value}`.toUpperCase());
        if (!situacao) return "N/A";
        return situacao.label;
    }

    return (
        <Panel header="Resumo da devolução" toggleable className="p-mb-3">
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="n-devolução">Nº da devolução</label>
                    <InputText id="n-devolução" disabled value={devolucao?.id} />
                </div>
                <div className="p-field p-col-12 p-md-6">
                    <label htmlFor="cliente">Cliente</label>
                    <InputText id="cliente" disabled value={devolucao?.cliente.nome} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="total-devolucao">Total devolvido</label>
                    <MakoInputMoeda id="total-devolucao" disabled valueMoeda={devolucao?.valor_total} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="situacao">Situação</label>
                    <InputText id="situacao" disabled value={traduzirSituacaoDevolucao(devolucao?.status)} />
                </div>
            </div>
        </Panel>
    );
};
