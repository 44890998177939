import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { axiosDelete } from "@/services/http";
import { Dialog } from "primereact/dialog";
import { ClonarTabelaForm } from "./modals/clonarTabelaModalForm";
import { RecalcularPrecosModalForm } from "./modals/recalcularPrecosModalForm";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import { CodigoFiltroTemplate, DropdownFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { QuantidadeFiltroTemplate } from "@/components/MakoFiltrosCabecalho/QuantidadeFiltroTemplate";

export const ProdutoTabelaPrecosPage = () => {
    const [redirecionar, setRedirecionar] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [clonarTabela, setClonarTabela] = useState(false);
    const [recalculaPrecos, setRecalculaPrecos] = useState(false);
    const [tabelaPreco, setTabelaPreco] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const listagemRef = useRef(null);

    const confirmarDelete = (tabelaPreco) => {
        setTabelaPreco(tabelaPreco);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
        setTabelaPreco(null);
    };

    const deletaTabela = async () => {
        showLoading();
        const resposta = await axiosDelete(`/produtos/tabelas-preco/${tabelaPreco.id}/`);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            showSuccess({
                summary: "Sucesso",
                detail: "Tabela de preços deletada com sucesso!",
                life: 3000,
            });

            listagemRef.current?.buscarDados();
        } else {
            showError({
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
        setDeleteDialog(false);
    };

    const exibeRecalcular = (tabelaPreco) => {
        setTabelaPreco(tabelaPreco);
        setRecalculaPrecos(true);
    };

    const escondeRecalcular = () => {
        setTabelaPreco(null);
        setRecalculaPrecos(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_TABELAPRECO_EDITAR]}
                    componente={Button}
                    icon="pi pi-history"
                    className="p-button-rounded p-button-help p-mr-2 p-mb-1"
                    tooltip="Recalcular preços"
                    tooltipOptions={{ position: "left" }}
                    onClick={() => exibeRecalcular({ ...rowData, vigencia: rowData.vigencia?.id })}
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_TABELAPRECO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de tabela de preços"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        setRedirecionar({
                            pathname: "/vendas/cadastros/tabela-precos/form",
                            state: { tabelaPreco: { ...rowData, vigencia: rowData.vigencia?.id } },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_TABELAPRECO_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete({ ...rowData, vigencia: rowData.vigencia?.id })}
                    tooltip="Deletar tabela de preços"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_CADASTRO_TABELAPRECO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setRedirecionar("/vendas/cadastros/tabela-precos/form")}
            />
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_CADASTRO_TABELAPRECO_INCLUIR]}
                componente={Button}
                label="Clonar tabela"
                icon="pi pi-clone"
                className="p-button-info p-mr-2"
                onClick={() => setClonarTabela(true)}
            />
        </>
    );

    const carregaClone = () => {
        setClonarTabela(false);
        listagemRef.current?.buscarDados();
    };

    const VigenciaFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    url: "/produtos/precos-vigencia?query={id,descricao}&limit=300",
                    optionValue: "id",
                    optionLabel: "descricao",
                }}
                options={options}
            />
        );
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "8%" }, filter: true, filterElement: CodigoFiltroTemplate },
        { field: "nome", header: "Nome", filter: true, filterElement: TextoFiltroTemplate },
        { field: "qtd_minima_tag", header: "Qtd. Minima", filter: true, filterElement: QuantidadeFiltroTemplate },
        {
            field: "vigencia.descricao",
            header: "Vigência",
            filter: true,
            filterElement: VigenciaFiltroTemplate,
            filterField: "vigencia",
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "15%" },
        },
    ];

    return redirecionar ? (
        <Redirect to={redirecionar} />
    ) : (
        <>
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <MakoListagem
                            ref={listagemRef}
                            titulo="Tabela de Preços"
                            colunas={colunas}
                            painelEsquerdo={cabecalhoTabela}
                            urlPesquisa="/produtos/tabelas-preco/"
                            configTabela={{
                                paginator: true,
                                lazy: true,
                            }}
                            filtros={{
                                id: { value: null, matchMode: "equals" },
                                nome: {
                                    operator: "and",
                                    constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                                },
                                qtd_minima_tag: { value: null, matchMode: "equals" },
                                vigencia: { value: null, matchMode: "equals" },
                            }}
                        />
                        <ConfirmDialog
                            visible={deleteDialog}
                            onHide={esconderDeleteDialog}
                            header="Confirmação"
                            message={tabelaPreco && <span>{"Deseja realmente excluir a tabela de preços?"}</span>}
                            icon="pi pi-info-circle p-mr-3"
                            accept={deletaTabela}
                            acceptLabel="Sim"
                            acceptClassName="p-button-danger"
                            reject={esconderDeleteDialog}
                            rejectLabel="Não"
                        />
                        <Dialog
                            header={"Recalcular Preços da Tabela"}
                            visible={recalculaPrecos}
                            onHide={() => escondeRecalcular()}
                            breakpoints={{ "960px": "75vw" }}
                            style={{ width: "50vw", display: "block" }}
                        >
                            <RecalcularPrecosModalForm
                                tabela={tabelaPreco}
                                esconderDialog={escondeRecalcular}
                                listagem={listagemRef}
                            />
                        </Dialog>
                        <Dialog
                            header={"Clonar Tabela de Preços"}
                            visible={clonarTabela}
                            onHide={() => setClonarTabela(false)}
                            breakpoints={{ "960px": "75vw" }}
                            style={{ width: "40vw", display: "block" }}
                            closeOnEscape={true}
                        >
                            <ClonarTabelaForm callBack={carregaClone} />
                        </Dialog>
                    </div>
                </div>
            </div>
        </>
    );
};
