import { EstoquesPage } from "@/pages/Estoque/Cadastros/Estoques";
import { EstoquesForm } from "@/pages/Estoque/Cadastros/Estoques/form";
import { CentroEstocagemPage } from "@/pages/Estoque/Cadastros/CentroEstocagem";
import { CentroEstocagemForm } from "@/pages/Estoque/Cadastros/CentroEstocagem/form";
import { TipoMovimentacaoEstoquePage } from "@/pages/Estoque/Movimentacoes/TiposMovimentacao";
import { TipoMovimentacaoEstoqueForm } from "@/pages/Estoque/Movimentacoes/TiposMovimentacao/form";
import { SaldoInicialSKUPage } from "@/pages/Estoque/Movimentacoes/SaldoInicial";
import { SaldoInicialSKUForm } from "@/pages/Estoque/Movimentacoes/SaldoInicial/form";
import { AcertoEstoquePage } from "@/pages/Estoque/Movimentacoes/AcertoEstoque";
import { CompetenciaMovEstoquePage } from "@/pages/Estoque/Cadastros/CompetenciaMovEstoque";
import { CompetenciaMovEstoqueForm } from "@/pages/Estoque/Cadastros/CompetenciaMovEstoque/form";
import { LoteInventarioPage } from "@/pages/Estoque/Inventario/LotesInventario";
import { LoteInventarioForm } from "@/pages/Estoque/Inventario/LotesInventario/formLoteInventario";
import { ItemInventarioForm } from "@/pages/Estoque/Inventario/LotesInventario/formItem";
import { ConsultarMovimentacoesPage } from "@/pages/Estoque/Movimentacoes/ConsultarMovimentacoes";
import { RelatorioSaldoOC } from "@/pages/Relatorios/Estoque/SaldoOC";
import { RelatorioSaldoInicial } from "@/pages/Relatorios/Estoque/SaldoInicial";
import { RelatorioNecessidadeOC } from "@/pages/Relatorios/Estoque/NecessidadeOC";
import { RelatorioSaldoProdutos } from "@/pages/Relatorios/Estoque/SaldoProdutos";
import { EstoqueCargaPage } from "@/pages/Estoque/Cadastros/Carga";
import { EstoqueCargaForm } from "@/pages/Estoque/Cadastros/Carga/form";
import { VinculoEmpresaCompetenciaMovEstoquePage } from "@/pages/Estoque/Cadastros/VinculoEmpresaCompetenciaMovEstoque";

export const estoqueRoutes = [
    { path: "/estoque/cadastros/estoques-empresas", component: EstoquesPage },
    { path: "/estoque/cadastros/estoques-empresas/form", component: EstoquesForm },
    { path: "/estoque/cadastros/centro-estocagem", component: CentroEstocagemPage },
    { path: "/estoque/cadastros/centro-estocagem/form", component: CentroEstocagemForm },
    { path: "/estoque/cadastros/competencias-movimentacoes-estoque", component: CompetenciaMovEstoquePage },
    { path: "/estoque/cadastros/competencias-movimentacoes-estoque/form", component: CompetenciaMovEstoqueForm },
    { path: "/estoque/cadastros/carga", component: EstoqueCargaPage },
    { path: "/estoque/cadastros/carga/form", component: EstoqueCargaForm },
    { path: "/estoque/movimentacoes/tipos-movimentacoes", component: TipoMovimentacaoEstoquePage },
    { path: "/estoque/movimentacoes/tipos-movimentacoes/form", component: TipoMovimentacaoEstoqueForm },
    { path: "/estoque/movimentacoes/consultar", component: ConsultarMovimentacoesPage },
    { path: "/estoque/movimentacoes/saldo-inicial-sku", component: SaldoInicialSKUPage },
    { path: "/estoque/movimentacoes/saldo-inicial-sku/form", component: SaldoInicialSKUForm },
    { path: "/estoque/movimentacoes/acerto-estoque", component: AcertoEstoquePage },
    { path: "/estoque/inventario/lotes-inventario", component: LoteInventarioPage },
    { path: "/estoque/inventario/lotes-inventario/form", component: LoteInventarioForm },
    { path: "/estoque/inventario/lotes-inventario/itens", component: ItemInventarioForm },
    { path: "/estoque/relatorios/saldo-ordem-compra", component: RelatorioSaldoOC },
    { path: "/estoque/relatorios/saldo-inicial-produtos", component: RelatorioSaldoInicial },
    { path: "/estoque/relatorios/saldo-produtos", component: RelatorioSaldoProdutos },
    { path: "/estoque/relatorios/necessidade-compra", component: RelatorioNecessidadeOC },
    {
        path: "/estoque/cadastros/vinculo-competencias-movimentacoes-estoque",
        component: VinculoEmpresaCompetenciaMovEstoquePage,
    },
];
