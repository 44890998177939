import React, { useRef } from "react";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import MakoListagem from "@/components/MakoListagem";
import { PageBase } from "@/components/PageBase";
import { BotaoDelete } from "@/components/BotaoDelete";
import permissoes from "@/assets/constants/permissoes";

const url = "/pessoas/tags-enderecos-perfis/";

export const PessoasTagsEnderecoPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_CADASTRO_TAGSENDERECO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar Cadastro de tag"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/gestao/cadastros/tags-enderecos/form",
                            state: { tag: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_CADASTRO_TAGSENDERECO_EXCLUIR]}
                    componente={BotaoDelete}
                    url={url}
                    objetoId={rowData.id}
                    classNames="p-mb-1"
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir a tag <b>{rowData.tag}</b>?
                        </span>
                    }
                    msgToastErroExclusao="A tag de endereço não pode ser excluída."
                    tooltip="Deletar tag de endereço"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.GESTAO_CADASTRO_TAGSENDERECO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/gestao/cadastros/tags-enderecos/form")}
            />
        </>
    );

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { width: "12%" },
            filter: true,
        },
        { field: "tag", header: "Descrição", filter: true },
        {
            field: "action",
            header: "Ações",
            style: { width: "15%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Tags de Endereço"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: { value: "", matchMode: "equals" },
                    tag: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                }}
            />
        </PageBase>
    );
};
