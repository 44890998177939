const { MakoAutoComplete } = require("@/components/MakoAutoComplete/index2");

export const AutoCompleteFiltroTemplate = ({
    url = "",
    field = "",
    placeholder = "Digite para buscar...",
    minCaracteresBusca = 3,
    options,
}) => {
    return (
        <MakoAutoComplete
            urlSearch={url}
            field={field}
            minCaracteresBusca={minCaracteresBusca}
            placeholder={placeholder}
            value={options.value}
            onChange={(e) => options.filterCallback(e.value, options.index)}
            className="p-column-filter"
        />
    );
};
