import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";

import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import useAuth from "@/hooks/useAuth";

import { axiosPost, axiosPatch } from "@/services/http";
import { Dropdown } from "primereact/dropdown";
import { TIPO_BAIXA_PERFIL_CHOICE } from "@/assets/constants/constants";

const Modal = ({ onFinish, perfil }, ref) => {
    const [visible, setVisible] = useState(false);

    const { showLoading, hideLoading } = useLoading();
    const { showError, showSuccess } = useToast();
    const { user } = useAuth();

    const { setFieldValue, setFieldError, ...formik } = useFormik({
        initialValues: {
            perfil_baixa: perfil,
            usuario_responsavel: user,
            motivo: "",
            tipo: "I",
        },
        onSubmit: handleSubmit,
    });

    const fecharModal = () => {
        formik?.resetForm();
        setVisible(false);
    };

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                perfil_baixa: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'perfil da baixa' é obrigatório"),
                    })
                    .typeError("Informe um 'perfil para baixa' válido"),
                usuario_responsavel: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'usuário responsável' é obrigatório"),
                    })
                    .typeError("Informe um 'usuário responsável' válido"),
                motivo: Yup.string()
                    .required("O campo 'motivo' é obrigatório")
                    .max(100, "Tamanho máximo de caracteres alcançado: 100"),
                tipo: Yup.string()
                    .required("O campo 'tipo' é obrigatório")
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (values.perfil_baixa.id === values.usuario_responsavel.id) {
                setFieldError("usuario_responsavel", "O 'usuário responsável' não pode ser o mesmo da baixa.");
            } else {
                showLoading();
                let response = await axiosPatch(`/pessoas/perfis/${perfil.id}/`, { ativo: false });
                hideLoading();

                if (response.status === 200) {
                    showLoading();

                    let response = await axiosPost("/pessoas/motivo-baixa-perfil/", {
                        ...values,
                        perfil_baixa: values.perfil_baixa.id,
                        usuario_responsavel: values.usuario_responsavel.id,
                    });

                    hideLoading();

                    if (response.status === 201) {
                        onFinish();
                        fecharModal();
                        showSuccess({
                            summary: "Sucesso!",
                            detail: "Baixa efetuada com sucesso!",
                            life: 2000,
                        });
                    } else {
                        showError({
                            summary: "Erro :(",
                            detail: "Desculpe, não conseguimos processar a sua requisição.",
                            life: 3000,
                        });
                    }
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const abrirModal = () => {
        setVisible(true);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    useEffect(() => setFieldValue("perfil_baixa", perfil), [perfil]);

    return (
        <Dialog header="Efetuar baixa perfil" visible={visible} onHide={fecharModal} style={{ width: "70vw" }}>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="perfil_baixa">Perfil da baixa *</label>
                        <MakoAutoComplete
                            id="perfil_baixa"
                            name="perfil_baixa"
                            placeholder="Busque pelo nome... (min 3 caracteres)"
                            minCaracteresBusca={3}
                            value={formik.values.perfil_baixa}
                            onChange={formik.handleChange}
                            field="nome"
                            disabled
                            urlSearch="/pessoas/perfis?query={id,nome, ativo}&ativo=true&nome__contains="
                            className={classNames({ "p-invalid": formik.errors.perfil_baixa })}
                        />
                        {formik.errors.perfil_baixa && <small className="p-error">{formik.errors.perfil_baixa}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="usuario_responsavel">Responsável *</label>
                        <MakoAutoComplete
                            id="usuario_responsavel"
                            name="usuario_responsavel"
                            placeholder="Busque pelo nome... (min 3 caracteres)"
                            minCaracteresBusca={3}
                            value={formik.values.usuario_responsavel}
                            onChange={formik.handleChange}
                            field="nome"
                            disabled
                            urlSearch="/pessoas/perfis?query={id,nome, ativo}&ativo=true&nome__contains="
                            className={classNames({ "p-invalid": formik.errors.usuario_responsavel })}
                        />
                        {formik.errors.usuario_responsavel && (
                            <small className="p-error">{formik.errors.usuario_responsavel}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="tipo">Classificação *</label>
                        <Dropdown
                            id="tipo"
                            name="tipo"
                            options={TIPO_BAIXA_PERFIL_CHOICE}
                            value={formik.values.tipo}
                            onChange={formik.handleChange}
                            optionValue="value"
                            optionLabel="label"
                            className={classNames({ "p-invalid": formik.errors.tipo })}
                        />
                        {formik.errors.tipo && (
                            <small className="p-error">{formik.errors.tipo}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="motivo">Motivo: *</label>
                        <InputTextarea
                            id="motivo"
                            name="motivo"
                            value={formik.values.motivo}
                            onChange={formik.handleChange}
                            autoResize
                            rows={3}
                            minLength={1}
                            maxLength={100}
                            className={classNames({ "p-invalid": formik.errors.motivo })}
                        />
                        {formik.errors.motivo && <small className="p-error">{formik.errors.motivo}</small>}
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button type="submit" icon="pi pi-check" label="Gravar" className="p-mr-2 p-mb-2" />
                        <Button
                            type="reset"
                            label="Cancelar"
                            className="p-button-danger p-mb-2"
                            onClick={fecharModal}
                        />
                    </div>
                </div>
            </form>
        </Dialog>
    );
};

export const ModalMotivoBaixa = forwardRef(Modal);
