import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import { axiosPost, axiosPut } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import { useHistory } from "react-router-dom";
import { Checkbox } from "primereact/checkbox";

export const RedesSociaisForm = (props) => {
    const toastRef = useRef(null);
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            nome: "",
            url_base: "",
            icone: null,
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    useEffect(() => {
        if (props.location.state) {
            setValues(props.location.state);
        }
    }, [setValues, props]);

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo 'nome' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost("/pessoas/redes-sociais/", values);
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Rede social cadastrada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        history.push("/gestao/cadastros/redes-sociais");
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(`/pessoas/redes-sociais/${values.id}/`, values);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Rede social alterada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        history.push("/gestao/cadastros/redes-sociais");
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    {<h3>{!formik.values.id ? "Nova rede social" : "Manutenção de rede social"}</h3>}
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-5">
                                <label htmlFor="nome">Nome *</label>
                                <InputText
                                    id="nome"
                                    name="nome"
                                    value={formik.values.nome}
                                    onChange={formik.handleChange}
                                    autoComplete="off"
                                    autoFocus
                                    className={classNames({ "p-invalid": formik.errors.nome })}
                                />
                                {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-5">
                                <label htmlFor="url-base">Url</label>
                                <InputText
                                    id="url-base"
                                    name="url_base"
                                    value={formik.values.url_base}
                                    onChange={formik.handleChange}
                                    autoComplete="off"
                                />
                            </div>
                            <div className="p-field-checkbox p-col-12 p-md-2 p-mt-5">
                                <Checkbox
                                    id="ativo"
                                    name="ativo"
                                    checked={formik.values.ativo}
                                    onChange={formik.handleChange}
                                />
                                <label htmlFor="ativo">Ativa?</label>
                            </div>
                        </div>
                        <p>
                            <b>* Campos obrigatórios.</b>
                        </p>
                        <div className="p-grid p-col-12 p-md-6">
                            <Button icon="pi pi-save" label="Gravar" type="submit" className="p-button-info p-mr-2" />
                            <Button
                                icon="pi pi-times"
                                label="Cancelar"
                                type="button"
                                className="p-button-danger p-mr-2"
                                onClick={() => history.push("/gestao/cadastros/redes-sociais")}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
