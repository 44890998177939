import React from "react";
import { MakoCalendar } from "@/components/MakoCalendar";
import classNames from "classnames";

export const MakoInputPeriodo = ({
    label,
    nameInicio,
    valueInicio,
    errorInicio,
    onChangeInicio,
    nameFinal,
    valueFinal,
    errorFinal,
    onChangeFinal,
    disabled = false,
}) => {
    return (
        <>
            <div className="p-field p-col-12 p-md-2">
                <label htmlFor={nameInicio}>{label}</label>
                <MakoCalendar
                    id={nameInicio}
                    name={nameInicio}
                    valueCalendar={valueInicio}
                    onChange={onChangeInicio}
                    className={classNames({ "p-invalid": errorInicio })}
                    disabled={disabled}
                />
                {errorInicio && <small className="p-error">{errorInicio}</small>}
            </div>
            <div className="p-field p-col-12 p-md-2 p-mt-4" style={{ paddingTop: "5px" }}>
                <MakoCalendar
                    id={nameFinal}
                    name={nameFinal}
                    valueCalendar={valueFinal}
                    onChange={onChangeFinal}
                    className={classNames({ "p-invalid": errorFinal })}
                    disabled={disabled}
                />
                {errorFinal && <small className="p-error">{errorFinal}</small>}
            </div>
        </>
    );
};
