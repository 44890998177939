import React, { useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { axiosPost, axiosPut } from "@/services/http";
import { useHistory } from "react-router-dom";
import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoTime } from "@/components/MakoTime";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";
import { dataToStr, horaToStr } from "@/assets/util/datas";

import useLocalStorage from "@/hooks/useLocalStorage";
import storageKeys from "@/assets/constants/storage";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";

const url = "/vendas/consultas-spc/";

export const VendasConsultaSpcForm = (props) => {
    const [empresaSelecionada] = useLocalStorage(storageKeys.EMPRESA_KEY);
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const history = useHistory();

    const consultaVazia = {
        id: "",
        data: "",
        hora: "",
        identificador: "",
        indicador_restricao: false,
        informacoes_consulta: "",
        cliente: null,
        perfil: null,
    };

    const formik = useFormik({
        initialValues: props.location && props.location.state ? props.location.state.consulta : consultaVazia,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                identificador: Yup.string().required("O campo 'identificador' é obrigatório."),
                indicador_restricao: Yup.boolean().required("O campo 'indicador de restrição' é obrigatório."),
                cliente: Yup.object().required("O campo 'indicador de restrição' é obrigatório.").typeError("Informe um 'cliente' válido."),
                informacoes_consulta: Yup.string().required("O campo 'informações da consulta' é obrigatório."),
                data: Yup.date().required("O campo 'data' é obrigatório.").typeError("Informe uma 'data' válida."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            values.data = dataToStr(values.data, "yyyy-MM-dd");
            values.hora = horaToStr(values.hora, "HH:mm:ss");

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(`${url}`, {
                    ...values,
                    cliente: values.cliente.id,
                    perfil: empresaSelecionada.id,
                });
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Consulta SPC registrada com sucesso!",
                        life: 1500,
                    });

                    if (props && props.venda) formik.resetForm();
                    else
                        setTimeout(() => {
                            formik.resetForm();
                            history.push("/vendas/venda/consultas-spc");
                        }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(`${url}${values.id}/`, {
                    ...values,
                    cliente: values.cliente.id,
                });
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Consulta SPC alterada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push("/vendas/venda/consultas-spc");
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    const autoCompleteTemplate = (valor) => {
        valor.label = `${valor.nome} - ${valor.identificacao}`;
        return `${valor.nome} - ${valor.identificacao}`;
    };

    const cancelar = () => {
        formik.resetForm();
        history.push("/vendas/venda/consultas-spc");
    };

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <h3>
                        {!formik.values.id
                            ? "Registrar Consulta SPC"
                            : `Consulta SPC - Cliente: ${formik.values.cliente.nome} - ${formik.values.cliente.identificacao}`}
                    </h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="identificador">Protocolo *</label>
                                <InputText
                                    id="identificador"
                                    name="identificador"
                                    value={formik.values.identificador}
                                    onChange={formik.handleChange}
                                    keyfilter={/^[0-9]/}
                                    className={classNames({ "p-invalid": formik.errors.identificador })}
                                    autoComplete="off"
                                    autoFocus
                                />
                                {formik.errors.identificador && (
                                    <small className="p-error">{formik.errors.identificador}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="data">Data *</label>
                                <MakoCalendar
                                    id="data"
                                    name="data"
                                    valueCalendar={formik.values.data}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.data })}
                                />
                                {formik.errors.data && <small className="p-error">{formik.errors.data}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="hora">Hora</label>
                                <MakoTime
                                    id="hora"
                                    name="hora"
                                    valueTime={formik.values.hora}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="indicador_restricao">Situação *</label>
                                <Dropdown
                                    id="indicador_restricao"
                                    name="indicador_restricao"
                                    options={[
                                        { value: false, label: "Restrito" },
                                        { value: true, label: "Liberado" },
                                    ]}
                                    value={formik.values.indicador_restricao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.indicador_restricao })}
                                />
                                {formik.errors.indicador_restricao && (
                                    <small className="p-error">{formik.errors.indicador_restricao}</small>
                                )}
                            </div>
                        </div>

                        <div className="p-fluid p-formgrid p-grid ">
                            {formik.values.id ? null : (
                                <div className="p-field p-col-12 p-md-9">
                                    <label htmlFor="cliente">Pessoa consultada *</label>
                                    <MakoAutoComplete
                                        id="cliente"
                                        name="cliente"
                                        placeholder="Busque pelo nome ou cpf/cnpj ... (min 4 caractéres)"
                                        minCaracteresBusca={4}
                                        value={formik.values.cliente}
                                        onChange={formik.handleChange}
                                        itemTemplate={autoCompleteTemplate}
                                        field="label"
                                        urlSearch={"/pessoas/perfis?search="}
                                        className={classNames({ "p-invalid": formik.errors.cliente })}
                                    />
                                    {formik.errors.cliente && (
                                        <small className="p-error">{formik.errors.cliente}</small>
                                    )}
                                </div>
                            )}
                        </div>

                        <div className="p-fluid p-formgrid p-grid ">
                            <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="informacoes_consulta">informações da consulta *</label>
                                <InputTextarea
                                    id="informacoes_consulta"
                                    name="informacoes_consulta"
                                    value={formik.values.informacoes_consulta}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.informacoes_consulta })}
                                    rows={5}
                                />
                                {formik.errors.informacoes_consulta && (
                                    <small className="p-error">{formik.errors.informacoes_consulta}</small>
                                )}
                            </div>
                        </div>
                        <p>
                            <b>* Campos obrigatórios.</b>
                        </p>

                        <div className="p-grid p-col-12 p-md-6">
                            <Button label="Gravar" type="submit" className="p-button-info p-mr-2 p-mb-6" />
                            <Button
                                label="Voltar"
                                type="submit"
                                className="p-button-danger p-mr-2 p-mb-6"
                                onClick={() => cancelar()}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
