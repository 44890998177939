import React, { useCallback, useEffect, useRef, useState } from "react";
import { axiosGet, axiosPost } from "@/services/http";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import useLoading from "@/hooks/useLoading";

export const ImportarTabelaIbptPage = () => {
    const [versao, setVersao] = useState([]);
    const [arquivo, setArquivo] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef();

    async function importaDados() {
        let csv = arquivo.split("\n");
        csv = csv.map((linha) => {
            return linha.split(";");
        });
        csv.shift();
        showLoading();
        const resposta = await axiosPost(
            "/fiscal/importar-tabela-ibpt/",
            { arquivo: csv },
            { "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Headers": "Content-Type" }
        );
        hideLoading();
        if (resposta.status === 200) {
            toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Tabela IBPT atualizada para a versão mais recente!",
                life: 3000,
            });
            carregaTabelaRegistrada();
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não foi possível atualizar a tabela IBPT. Tente novamente mais tarde ou contate o suporte para mais informações.",
                sticky: 3000,
            });
        }
    }

    const carregaTabelaRegistrada = useCallback(async () => {
        const resposta = await axiosGet("/fiscal/tabela-ibpt?limit=1&order_by=-id");
        if (resposta.status === 200) setVersao(resposta.data.results);
    }, []);

    useEffect(() => {
        carregaTabelaRegistrada();
    }, [carregaTabelaRegistrada]);

    function readFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener("load", () => resolve(reader.result), false);
            reader.readAsText(file);
        });
    }
    const onFileChange = async (e) => {
        if (e.target.files?.length > 0) {
            const file = e.target.files[0];
            const imageDataUrl = await readFile(file);
            setArquivo(imageDataUrl);
        }
    };

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />

            <div className="p-col-12">
                <div className="card">
                    <div className="p-fluid p-formgrid p-grid p-justify-center">
                        <h5>
                            <b>Tabela IBPT</b>
                        </h5>
                    </div>
                    <div className="p-fluid p-formgrid p-grid p-justify-center">
                        <div className="p-field p-col-12 p-md-2">
                            <input
                                type="file"
                                onChange={onFileChange}
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid p-justify-center">
                        <div className="p-field p-col-12 p-md-2">
                            <Button
                                icon="pi pi-download"
                                label="Atualizar"
                                type="button"
                                onClick={() => importaDados()}
                                className="p-button-info"
                                disabled={!arquivo}
                            />
                        </div>
                    </div>
                    <h5 style={{ textAlign: "center" }}>
                        {versao?.length > 0 ? (
                            <b
                                style={{ color: "#3CB371" }}
                            >{`Versão - ${versao[0].versao} | Vigência final: ${versao[0].vigenciafim}`}</b>
                        ) : (
                            <b style={{ color: "#FF6347" }}>Não existem registros.</b>
                        )}
                    </h5>
                </div>
            </div>
        </div>
    );
};
