import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { axiosPost } from "@/services/http";
import { ConfirmDialog } from "primereact/confirmdialog";
import { ModalFotosSku } from "./modals/modalFotos";
import useLoading from "@/hooks/useLoading";
import useProduto from "@/hooks/useProduto";
import { Image } from "primereact/image";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import { Dropdown } from "@/components/Dropdown";

const FotosForm = () => {
    const [produto, setProduto] = useState(null);
    const [exibirImagem, setExibirImagem] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [imagem, setImagem] = useState(null);
    const { dadosBasicos } = useProduto();
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const listagemRef = useRef(null);

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const deletarImagem = async () => {
        showLoading();
        const resposta = await axiosPost(`/produtos/produto-delete-foto/${imagem.id}/`, {
            imagem_cloud: imagem.imagem.replace("image/upload/", ""),
        });
        hideLoading();

        if (resposta.status === 200) {
            toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Imagem deletada com sucesso!",
                life: 3000,
            });
            listagemRef.current?.buscarDados();
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
        setDeleteDialog(false);
    };

    const aposPersistirImagem = () => {
        setExibirImagem(false);
        listagemRef.current?.buscarDados();
    };

    const cabecalhoTabela = (
        <>
            <Dropdown
                id="sku"
                name="sku"
                url={`/produtos/sku?item__id=${dadosBasicos.id}`}
                style={{ width: "300px", paddingLeft: "2%" }}
                placeholder="Selecione um produto"
                optionValue="id"
                optionLabel="descricao_complementar"
                showClear={false}
                className="p-mr-2"
                value={produto}
                onChange={(e) => setProduto(e.target.value)}
            />
            <MakoControleAcesso
                permissao={[permissoes.PRODUTO_PRODUTO_IMAGENSSKU_INCLUIR]}
                componente={Button}
                label="Inserir"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setExibirImagem(true)}
                disabled={!produto}
            />
        </>
    );

    const confirmarDelete = (imagem) => {
        setImagem(imagem);
        setDeleteDialog(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_IMAGENSSKU_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Excluir imagem"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const imageBodyTemplate = (rowData) => {
        return (
            <Image
                imageClassName="product-image"
                src={rowData.imagem}
                alt={rowData.descricao}
                onError={(e) =>
                    (e.target.src = "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
                }
                width="80"
                height="80"
                preview
                downloadable
            />
        );
    };

    const colunas = [
        { field: "id", header: "#ID", style: { width: "12%" } },
        { field: "descricao", header: "Descrição", style: { width: "20%" } },
        { field: "imagem", header: "Imagem", style: { width: "25%" }, action: (e) => imageBodyTemplate(e) },
        { field: "galeria.nome", header: "Galeria", style: { width: "15%" } },
        { field: "action", header: "Ações", action: (e) => actionBodyTemplate(e), style: { width: "8%" } },
    ];

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <MakoListagem
                    ref={listagemRef}
                    colunas={colunas}
                    painelEsquerdo={cabecalhoTabela}
                    urlPesquisa={`/produtos/imagens-sku${produto ? `?sku__id=?${produto}` : "/"}`}
                    configTabela={{
                        paginator: true,
                        lazy: true,
                    }}
                    responsiveLayout="scroll"
                />
            </div>
            <Dialog
                header="Adicionar Imagem"
                visible={exibirImagem}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "55vw" }}
                onHide={() => setExibirImagem(false)}
            >
                <ModalFotosSku sku={produto} exibirDialog={aposPersistirImagem} />
            </Dialog>
            <ConfirmDialog
                visible={deleteDialog}
                onHide={esconderDeleteDialog}
                header="Confirmação"
                message={
                    imagem && (
                        <span>
                            {"Deseja realmente excluir a imagem "}
                            <b>{imagem.descricao}</b>?
                        </span>
                    )
                }
                icon="pi pi-info-circle p-mr-3"
                accept={deletarImagem}
                acceptLabel="Sim"
                acceptClassName="p-button-danger"
                reject={esconderDeleteDialog}
                rejectLabel="Não"
            />
        </div>
    );
};

export default FotosForm;
