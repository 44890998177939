import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputNumber } from "primereact/inputnumber";
import { TreeSelect } from "primereact/treeselect";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { PageBase } from "@/components/PageBase";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoInputPerfil } from "@/components/MakoInputs/MakoInputPerfil";
import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { Dropdown } from "@/components/Dropdown";
import { axiosGet, axiosPost, axiosPut } from "@/services/http";
import { dataToStr } from "@/assets/util/datas";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import useEmpresa from "@/hooks/useEmpresa";

const url = "/pessoas/hierarquia-funcional/";

export const HierarquiaFuncionalForm = (props) => {
    const [hierarquias, setHierarquias] = useState([]);
    const history = useHistory();
    const { empresaSelecionadaId } = useEmpresa();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();

    const { setFieldValue, ...formik } = useFormik({
        initialValues: props.location.state?.hierarquia || {
            perfil: null,
            nivel: 0,
            ativo: true,
            vigencia_inicial: new Date(),
            vigencia_final: null,
            pessoa: null,
            cargo: null,
            superior: null,
        },
        onSubmit: handleSubmit,
    });

    const listarHierarquias = useCallback(async () => {
        const response = await axiosGet(`/pessoas/hierarquia-funcional-menu/`);
        if (response.status === 200) {
            setHierarquias(response.data);
        } else {
            showError({
                summary: "Error",
                detail: "Desculpe, não conseguimos listar as hierarquias.",
                life: 3000,
            });
        }
    }, [showError]);

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                perfil: Yup.number().required("O campo 'empresa' é obrigatório.").typeError("Selecione uma empresa."),
                pessoa: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'pessoa' é obrigatório"),
                    })
                    .typeError("Informe um 'pessoa' válido"),
                cargo: Yup.number().required("O campo 'cargo' é obrigatório").typeError("Informe um 'cargo' válido"),
                superior: Yup.number().nullable().notRequired(),
                nivel: Yup.number().required("O campo 'nivel' é obrigatório").typeError("Informe um 'nivel' válido"),
                vigencia_inicial: Yup.date().nullable().typeError("Informe uma 'vigência inicial' válida"),
                vigencia_final: Yup.date().when("vigencia_inicial", {
                    is: (val) => !!val,
                    then: Yup.date()
                        .min(
                            Yup.ref("vigencia_inicial") || new Date(),
                            "O campo 'vigência final' não pode ser anterior a inicial"
                        )
                        .nullable()
                        .typeError("Informe uma 'vigência final' válida"),
                }),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            const body = {
                ...values,
                pessoa: values.pessoa.id,
                vigencia_inicial: dataToStr(values.vigencia_inicial, "yyyy-MM-dd"),
                vigencia_final: dataToStr(values.vigencia_final, "yyyy-MM-dd"),
            };
            if (!values.id) {
                values.perfil = empresaSelecionadaId;
                showLoading();
                const resposta = await axiosPost(url, body);
                hideLoading();
                if (resposta.status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Hierarquia cadastrada com sucesso!",
                        life: 1500,
                    });
                    voltarParaListagem();
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(`${url}${values.id}/`, body);
                hideLoading();
                if (resposta.status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Hierarquia alterada com sucesso!",
                        life: 1500,
                    });
                    voltarParaListagem();
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const voltarParaListagem = () => {
        history.push("/gestao/cadastros/hierarquia-funcional");
    };

    const buscarNivelHierarquia = (hierarquiaFuncional, key) => {
        for (let index = 0; index < hierarquiaFuncional.length; index++) {
            if (hierarquiaFuncional[index].key === key) {
                return hierarquiaFuncional[index].data.nivel;
            } else if (hierarquiaFuncional[index].children) {
                return buscarNivelHierarquia(hierarquiaFuncional[index].children, key);
            }
        }
    };

    const handleChangeSuperior = (e) => {
        const { value } = e.target;
        if (value) {
            const nivelSuperior = buscarNivelHierarquia(hierarquias, value);
            setFieldValue("nivel", nivelSuperior + 1);
        } else {
            setFieldValue("nivel", 0);
        }
        formik.handleChange(e);
    };

    useEffect(() => {
        listarHierarquias();
    }, [listarHierarquias]);

    return (
        <PageBase>
            {<h3>{!formik.values.id ? "Nova hierarquia funcional" : "Manutenção de hierarquia funcional"}</h3>}
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="perfil">Empresa *</label>
                        <MakoDropdownEmpresas
                            id="perfil"
                            name="perfil"
                            value={formik.values.perfil}
                            onChange={(e) => setFieldValue("perfil", e.id)}
                        />
                        {formik.errors.pessoa && <small className="p-error">{formik.errors.pessoa}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="pessoa">Pessoa *</label>
                        <MakoInputPerfil
                            id="pessoa"
                            name="pessoa"
                            value={formik.values.pessoa}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.pessoa })}
                            autoComplete="off"
                        />
                        {formik.errors.pessoa && <small className="p-error">{formik.errors.pessoa}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="cargo">Cargo *</label>
                        <Dropdown
                            id="cargo"
                            name="cargo"
                            url="/pessoas/cargos/"
                            optionValue="id"
                            optionLabel="nome"
                            value={formik.values.cargo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.cargo })}
                        />
                        {formik.errors.cargo && <small className="p-error">{formik.errors.cargo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="superior">Superior</label>
                        <TreeSelect
                            id="superior"
                            name="superior"
                            options={hierarquias}
                            showClear
                            filter
                            filterBy="pessoa.nome"
                            value={formik.values.superior}
                            onChange={handleChangeSuperior}
                            className={classNames({ "p-invalid": formik.errors.superior })}
                        />
                        {formik.errors.superior && <small className="p-error">{formik.errors.superior}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-1">
                        <label htmlFor="nivel">Nivel *</label>
                        <InputNumber
                            id="nivel"
                            name="nivel"
                            disabled
                            showButtons
                            min={0}
                            value={formik.values.nivel}
                            className={classNames({ "p-invalid": formik.errors.nivel })}
                        />
                        {formik.errors.nivel && <small className="p-error">{formik.errors.nivel}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="vigencia_inicial">Vigência inicial</label>
                        <MakoCalendar
                            id="vigencia_inicial"
                            name="vigencia_inicial"
                            valueCalendar={formik.values.vigencia_inicial}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.vigencia_inicial })}
                        />
                        {formik.errors.vigencia_inicial && (
                            <small className="p-error">{formik.errors.vigencia_inicial}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="vigencia_final">Vigência final</label>
                        <MakoCalendar
                            id="vigencia_final"
                            name="vigencia_final"
                            valueCalendar={formik.values.vigencia_final}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.vigencia_final })}
                        />
                        {formik.errors.vigencia_final && (
                            <small className="p-error">{formik.errors.vigencia_final}</small>
                        )}
                    </div>
                    <div className="p-field-checkbox p-mt-5 p-col-12 p-md-3">
                        <Checkbox
                            inputId="ativo"
                            name="ativo"
                            checked={formik.values.ativo}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="ativo">Ativo?</label>
                    </div>
                </div>
                <CamposObrigatorios />
                <div className="p-grid p-col-12 p-md-6">
                    <Button icon="pi pi-save" label="Gravar" type="submit" className="p-button-info p-mr-2" />
                    <Button
                        type="button"
                        icon="pi pi-angle-double-left"
                        label="Sair"
                        className="p-button-danger"
                        onClick={voltarParaListagem}
                    />
                </div>
            </form>
        </PageBase>
    );
};
