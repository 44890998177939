import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import * as Yup from "yup";

import { TIPO_MOVIMENTACAO_ESTOQUE_CHOICE, CLASSE_MOVIMENTACAO_ESTOQUE } from "@/assets/constants/constants";
import useLoading from "@/hooks/useLoading";
import { axiosPost, axiosPut, axiosGet } from "@/services/http";

export const TipoMovimentacaoEstoqueForm = (props) => {
    const [contentTypes, setContentTypes] = useState([]);
    const toastRef = useRef(null);
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            codigo: "",
            nome: "",
            tipo: "",
            classe_movimentacao: null,
            content_type: null,
            altera_custo: false,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                codigo: Yup.string()
                    .min(1, "O campo precisa de no mínimo 1 caracter.")
                    .max(3, "O campo pode receber no máximo 3 caracteres.")
                    .required("O campo 'código' é obrigatório!"),
                nome: Yup.string().required("O campo 'nome' é obrigatório!"),
                tipo: Yup.string().required("Selecione um tipo de movimentação de estoque."),
                classe_movimentacao: Yup.number()
                    .required("Selecione uma classe de movimentação.")
                    .typeError("Informe uma 'classe' válida."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                showLoading();
                const resp = await axiosPost("/produtos/tipos-movimentacoes-estoques/", values);
                hideLoading();

                if (resp.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Novo tipo de movimentação de estoque cadastrado!",
                        life: 3000,
                    });

                    formik.resetForm();
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const resp = await axiosPut(`/produtos/tipos-movimentacoes-estoques/${values.id}/`, values);
                hideLoading();

                if (resp.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Tipo de movimentação de estoque alterado!",
                        life: 2000,
                    });

                    setTimeout(() => {
                        history.push("/estoque/movimentacoes/tipos-movimentacoes");
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const listarContentTypes = useCallback(async () => {
        showLoading();
        const json = await axiosGet("/custom-fields/content-types/?limit=300");
        hideLoading();

        if (json.status === 200) {
            setContentTypes(json.data.results);
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
        }
    }, [showLoading, hideLoading]);

    useEffect(() => {
        listarContentTypes();
    }, [listarContentTypes]);

    useEffect(() => {
        if (props.location.state) {
            setValues(props.location.state);
        }
    }, [props.location.state, setValues]);

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <h3>
                        {!formik.values.id
                            ? "Novo tipo de movimentação de estoque"
                            : "Manutenção de tipo de movimentação de estoque"}
                    </h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="codigo">Código *</label>
                                <InputText
                                    id="codigo"
                                    name="codigo"
                                    value={formik.values.codigo}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.codigo })}
                                />
                                {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-10">
                                <label htmlFor="nome">Nome *</label>
                                <InputText
                                    id="nome"
                                    name="nome"
                                    value={formik.values.nome}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.nome })}
                                />
                                {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="tipo">Natureza *</label>
                                <Dropdown
                                    id="tipo"
                                    name="tipo"
                                    options={TIPO_MOVIMENTACAO_ESTOQUE_CHOICE}
                                    optionValue="id"
                                    optionLabel="label"
                                    placeholder="Selecione"
                                    value={formik.values.tipo}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.tipo })}
                                />
                                {formik.errors.tipo && <small className="p-error">{formik.errors.tipo}</small>}
                            </div>

                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="content-type">Tabela</label>
                                <Dropdown
                                    id="content-type"
                                    name="content_type"
                                    options={contentTypes}
                                    optionValue="id"
                                    optionLabel="model"
                                    filter
                                    showClear
                                    placeholder="Selecione"
                                    value={formik.values.content_type}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="classe-movimentacao">Classe de movimentação *</label>
                                <Dropdown
                                    id="classe-movimentacao"
                                    name="classe_movimentacao"
                                    options={CLASSE_MOVIMENTACAO_ESTOQUE}
                                    placeholder="Selecione"
                                    value={formik.values.classe_movimentacao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.classe_movimentacao })}
                                />
                                {formik.errors.classe_movimentacao && (
                                    <small className="p-error">{formik.errors.classe_movimentacao}</small>
                                )}
                            </div>
                            <div className="p-field-checkbox p-col-12 p-md-3 p-mt-auto">
                                <Checkbox
                                    inputId="altera-custo"
                                    name="altera_custo"
                                    checked={formik.values.altera_custo}
                                    onChange={formik.handleChange}
                                />
                                <label htmlFor="altera-custo">Altera o custo?</label>
                            </div>
                        </div>
                        <p>* Campos obrigatórios.</p>
                        <div className="p-grid p-col-12 p-md-6">
                            <Button label="Gravar" type="submit" icon="pi pi-save" className="p-button-info p-mr-2" />
                            <Button
                                label="Voltar"
                                type="button"
                                icon="pi pi-angle-double-left"
                                className="p-button-danger p-mr-2"
                                onClick={() => history.push("/estoque/movimentacoes/tipos-movimentacoes")}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
