import { useCallback } from "react";

import { axiosGet, axiosPost } from "@/services/http";
import useLoading from "./useLoading";
import useToast from "./useToast";
import useEmpresa from "./useEmpresa";

const useRelatorio = () => {
    const { showSuccess, showWarning } = useToast();
    const { showLoading, hideLoading } = useLoading();
    const { empresaSelecionadaId } = useEmpresa();

    const abrirRelatorio = useCallback((data, contentType) => {
        const file = new Blob([data], { type: contentType });

        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    }, []);

    const solicitarRelatorio = useCallback(
        async ({ chave, emails = [], filtros, versao = "1", filtros_template = [] }) => {
            const body = { chave, versao, emails, filtros, filtros_template, empresa: empresaSelecionadaId };

            showLoading();
            const { status, data, headers } = await axiosPost("/relatorios/gerar/", body, {
                responseType: "blob",
            });
            hideLoading();

            if (status === 200) {
                const contentType = headers["content-type"] || "application/json";

                if (contentType !== "application/json") {
                    abrirRelatorio(data, contentType);
                } else {
                    showSuccess({
                        summary: "Sucesso!",
                        detail: JSON.parse(await data.text()).msg,
                        life: 1500,
                    });
                }
            } else {
                showWarning({
                    summary: "Falhou...",
                    detail: JSON.parse(await data.text()).msg,
                    life: 3000,
                });
            }
        },
        [showLoading, hideLoading, showSuccess, showWarning, abrirRelatorio, empresaSelecionadaId]
    );

    const carregarRelatorio = useCallback(
        async (id) => {
            showLoading();
            const { status, data, headers } = await axiosGet(`/relatorios/relatorios-emitidos-full/${id}/`, {
                responseType: "blob",
            });
            hideLoading();

            if (status === 200) {
                abrirRelatorio(data, headers["content-type"]);
            } else {
                showWarning({
                    summary: "Falhou...",
                    detail: JSON.parse(await data.text()).msg,
                    life: 3000,
                });
            }
        },
        [showLoading, hideLoading, showWarning, abrirRelatorio]
    );

    return { solicitarRelatorio, carregarRelatorio };
};

export default useRelatorio;
