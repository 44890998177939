import React, { useState, useRef, useCallback, useEffect } from "react";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { axiosDelete, axiosGet, axiosPost } from "@/services/http";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";

const url = "/financeiro/vinculos-planos-financeiro-contabil/";

export const VincularPlanosPage = () => {
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [adicionarVinculo, setAdicionarVinculo] = useState(false);
    const [vinculo, setVinculo] = useState(null);
    const [planoContabil, setPlanoContabil] = useState(null);
    const [planoFinanceiro, setPlanoFinanceiro] = useState(null);
    const [listaContabeis, setListaContabeis] = useState([]);
    const [listaFinanceiros, setListaFinanceiros] = useState([]);
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const listagemRef = useRef(null);

    async function handleVinculo() {
        try {
            showLoading();
            const resposta = await axiosPost(`${url}`, {
                plano_contabil: planoContabil,
                plano_financeiro: planoFinanceiro,
            });
            hideLoading();

            if (resposta.status === 201) {
                toastRef.current.show({
                    severity: "success",
                    summary: "Sucesso",
                    detail: "Vínculo de plano de contas adicionado com sucesso!",
                    life: 3000,
                });
                listagemRef.current?.buscarDados();
                setPlanoContabil(null);
                setPlanoFinanceiro(null);
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 3000,
                });
            }
        } catch {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
    }

    const deleteCfop = async () => {
        showLoading();
        const resposta = await axiosDelete(`${url}${vinculo.id}`);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Vínculo removido com sucesso!",
                life: 3000,
            });
            listagemRef.current?.buscarDados();
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
        setDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.CONTABIL_CONTABIL_VINCULARPLANOS_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Remover vínculo"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.CONTABIL_CONTABIL_VINCULARPLANOS_INCLUIR]}
                componente={Button}
                label="Adicionar vínculo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setAdicionarVinculo(true)}
            />
        </>
    );

    const colunas = [
        { field: "id", header: "Código Vinc.", style: { width: "18%" } },
        { field: "plano_financeiro.descricao", header: "Plano Financeiro" },
        { field: "plano_contabil.descricao", header: "Plano Contábil" },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "12%" },
        },
    ];

    const listarPlanosCon = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/financeiro/planos-contas-contabeis?limit=10000");
        hideLoading();

        if (resposta.status === 200) setListaContabeis(resposta.data.results);
    }, [showLoading, hideLoading]);

    const listarPlanosFin = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/financeiro/planos-contas-financeiras?limit=10000");
        hideLoading();

        if (resposta.status === 200) setListaFinanceiros(resposta.data.results);
    }, [showLoading, hideLoading]);

    useEffect(() => {
        listarPlanosCon();
        listarPlanosFin();
    }, [listarPlanosCon, listarPlanosFin]);

    const cancelarAdicionar = () => {
        setAdicionarVinculo(false);
        setPlanoContabil(null);
        setPlanoFinanceiro(null);
    };

    const confirmarDelete = (vinculo) => {
        setVinculo(vinculo);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Vínculos de Planos de Contas Financeiros e Contábeis"
                        colunas={colunas}
                        painelEsquerdo={cabecalhoTabela}
                        urlPesquisa={url}
                        responsiva
                        configTabela={{
                            paginator: true,
                            lazy: true,
                        }}
                    />
                    <ConfirmDialog
                        visible={deleteDialog}
                        onHide={esconderDeleteDialog}
                        header="Confirmação"
                        message={
                            vinculo && (
                                <span>
                                    {"Deseja realmente remover o vínculo código "}
                                    <b>{vinculo.id}</b>?
                                </span>
                            )
                        }
                        icon="pi pi-info-circle p-mr-3"
                        accept={deleteCfop}
                        acceptLabel="Sim"
                        acceptClassName="p-button-danger"
                        reject={esconderDeleteDialog}
                        rejectLabel="Não"
                    />
                </div>
                <Dialog
                    header="Adicionar Vínculo de Planos de Contas"
                    visible={adicionarVinculo}
                    style={{ width: "40vw" }}
                    onHide={() => cancelarAdicionar()}
                >
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="plano_financeiro">Plano de Contas Financeiro *</label>
                            <Dropdown
                                id="plano_financeiro"
                                name="plano_financeiro"
                                value={planoFinanceiro}
                                onChange={(e) => setPlanoFinanceiro(e.target.value)}
                                filter
                                filterBy="descricao"
                                placeholder="Selecione um plano de contas"
                                optionLabel="descricao"
                                optionValue="id"
                                options={listaFinanceiros}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="plano_financeiro">Plano de Contas Contábil *</label>
                            <Dropdown
                                id="plano_contabil"
                                name="plano_contabil"
                                value={planoContabil}
                                onChange={(e) => setPlanoContabil(e.target.value)}
                                filter
                                filterBy="descricao"
                                placeholder="Selecione um plano de contas"
                                optionLabel="descricao"
                                optionValue="id"
                                options={listaContabeis}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid p-mt-4">
                        <div className="p-field p-col-12 p-md-3">
                            <Button
                                label="Adicionar"
                                type="button"
                                className="p-button-info"
                                icon="pi pi-save"
                                onClick={() => handleVinculo()}
                                disabled={!planoContabil || !planoFinanceiro}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <Button
                                label="Fechar"
                                type="reset"
                                icon="pi pi-times"
                                className="p-button-danger"
                                onClick={cancelarAdicionar}
                            />
                        </div>
                    </div>
                </Dialog>
            </div>
        </div>
    );
};
