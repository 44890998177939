import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";
import { axiosDelete } from "@/services/http";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { Tag } from "primereact/tag";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import { parseNumberToMoneyHTML } from "@/assets/util/util";
import useRelatorio from "@/hooks/useRelatorio";
import { RELATORIO_VENDAS_ORCAMENTORAPIDO } from "@/assets/constants/relatorios";
import useClearRefs from "@/hooks/useClearRefs";
import useToast from "@/hooks/useToast";
import { BooleanFiltroTemplate, ClienteFiltroTemplate, CodigoFiltroTemplate, DateFiltroTemplate, MoedaFiltroTemplate, TextoFiltroTemplate, TimeFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";

export const OrcamentoRapidoPage = () => {
    const [redirecionar, setRedirecionar] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [arquivoDialog, setArquivoDialog] = useState(false);
    const [orcamento, setOrcamento] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const { solicitarRelatorio } = useRelatorio();
    const [formatDocumento] = useFormatCNPJCPF()
    const listagemRef = useRef(null);

    useClearRefs(listagemRef);

    const confirmarDelete = (orcamento) => {
        setOrcamento(orcamento);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const deleteOrcamento = async () => {
        showLoading();
        const resposta = await axiosDelete(`/vendas/orcamento-rapido/${orcamento.id}`);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            showSuccess({
                summary: "Sucesso",
                detail: "Orcamento deletado com sucesso!",
                life: 3000,
            });

            listagemRef.current?.buscarDados();
        } else {
            showError({
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
        setDeleteDialog(false);
    };

    const confirmarArquivo = (orcamento) => {
        setOrcamento(orcamento);
        setArquivoDialog(true);
    };

    const esconderArquivoDialog = () => {
        setArquivoDialog(false);
    };

    const gerarArquivo = () => {
        solicitarRelatorio({ chave: RELATORIO_VENDAS_ORCAMENTORAPIDO, filtros: { id: orcamento.id } });
    };

    const copiarAreaTransferencia = (orcamento) => {
        const cliente = orcamento.cliente;
        const cabecalho = `Orçamento: ${orcamento.id}\nCliente..: ${cliente.nome}\nDoc......: ${cliente.identificacao}\nData.....: ${orcamento.data}\n\n`;
        const colunas = "Descrição		             Quant Unitário Subtotal";
        const separador = "\n-------------------------------------------------------------";

        let itens = "";
        orcamento.itemorcamentorapido_set.forEach((item) => {
            itens += `${item.sku.descricao_reduzida}  ${item.quantidade} ${
                parseNumberToMoneyHTML(item.valor_unitario).props.children
            }  ${parseNumberToMoneyHTML(item.valor_total_ap).props.children}`;
        });
        const mensagem = `${cabecalho}${colunas}${separador}\n${itens}${separador}\nTotal: ${
            parseNumberToMoneyHTML(orcamento.valor_total).props.children
        }\nPreço sujeito a alteração ou até quando durar o estoque`;

        const format = "```";
        window.prompt("Copie para área de transferência: Ctrl+C e tecle Enter", format + mensagem + format);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_ORCAMENTORAPIDO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de orçamento"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        setRedirecionar({
                            pathname: "/vendas/venda/orcamento-rapido/form",
                            state: { orcamento: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_ORCAMENTORAPIDO_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Deletar orçamento"
                    tooltipOptions={{ position: "left" }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_ORCAMENTORAPIDO_CONSULTAR]}
                    componente={Button}
                    icon="pi pi-copy"
                    className="p-button-rounded p-mr-2 p-mb-1"
                    onClick={() => copiarAreaTransferencia(rowData)}
                    tooltip="Copiar para área de transferência"
                    tooltipOptions={{ position: "left" }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.RELATORIOS_EMITIR_VENDAS_ORCAMENTORAPIDO]}
                    componente={Button}
                    icon="pi pi-file"
                    className="p-button-rounded p-mr-2 p-mb-1"
                    onClick={() => confirmarArquivo(rowData)}
                    tooltip="Gerar arquivo"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_VENDA_ORCAMENTORAPIDO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setRedirecionar("/vendas/venda/orcamento-rapido/form")}
            />
        </>
    );

    const retornaSimOuNao = (dia) => {
        if (dia) return <Tag severity="success" value="Sim" style={{ width: "60px" }} />;
        return <Tag severity="danger" value="Não" style={{ width: "60px" }} />;
    };

    const HoraFiltroTemplate = (options) => {
        return (
            <TimeFiltroTemplate inputProps={{ showSeconds: true, placeholder: "HH:mm:ss" }} {...options} />
        )
    }

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { minWidth: "50px" },
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "cliente",
            header: "Cliente",
            style: { minWidth: "300px" },
            action: ({ cliente }) => `${cliente?.nome} - ${formatDocumento(cliente?.identificacao)}`,
            filter: true,
            filterElement: ClienteFiltroTemplate,
        },
        { field: "identificacao", header: "Identificação", style: { minWidth: "180px" }, filter: true, filterElement: TextoFiltroTemplate },
        {
            field: "valor_total",
            header: "Valor total",
            style: { minWidth: "150px" },
            money: true,
            filter: true, 
            filterElement: MoedaFiltroTemplate
        },
        { field: "data", header: "Data", style: { minWidth: "150px" }, dateFormat: "dd/MM/yyyy", filter: true, filterElement: DateFiltroTemplate },
        { field: "hora", header: "Hora", style: { minWidth: "150px" }, filter: true, filterElement: HoraFiltroTemplate },
        {
            field: "baixado",
            header: "Baixado",
            style: { minWidth: "60px" },
            action: (e) => retornaSimOuNao(e.segunda),
            filter: true,
            filterElement: BooleanFiltroTemplate
        },
        {
            field: "action",
            header: "Ações",
            style: { minWidth: "180px" },
            action: (rowData) => actionBodyTemplate(rowData),
            frozen: true,
            alignFrozen: "right",
        },
    ];

    return redirecionar ? (
        <Redirect to={redirecionar} />
    ) : (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Orçamento Rápido"
                        colunas={colunas}
                        painelEsquerdo={cabecalhoTabela}
                        urlPesquisa={"/vendas/orcamento-rapido/"}
                        filtarPorEmpresa
                        naoBuscarSemEmpresa
                        configTabela={{
                            paginator: true,
                            lazy: true,
                            scrollable: true,
                            scrollHeight: "400px",
                            frozenWidth: "0.5vw",
                            flexGrow: 1,
                            flexBasis: "12rem",
                        }}
                        filtros={{
                            id: { value: null, matchMode: "equals" },
                            cliente: { value: null, matchMode: "equals" },
                            identificacao: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                            },
                            valor_total: { value: null, matchMode: "equals" },
                            data: { value: null, matchMode: "equals" },
                            hora: { value: null, matchMode: "equals" },
                            baixado: { value: null, matchMode: "equals" },
                        }}
                    />
                    <ConfirmDialog
                        visible={deleteDialog}
                        onHide={esconderDeleteDialog}
                        header="Confirmação"
                        message={
                            orcamento && (
                                <span>
                                    {"Deseja realmente excluir o orçamento "}
                                    <b>{orcamento.descricao}</b>?
                                </span>
                            )
                        }
                        icon="pi pi-info-circle p-mr-3"
                        accept={deleteOrcamento}
                        acceptLabel="Sim"
                        acceptClassName="p-button-danger"
                        reject={esconderDeleteDialog}
                        rejectLabel="Não"
                    />
                    <ConfirmDialog
                        visible={arquivoDialog}
                        onHide={esconderArquivoDialog}
                        header="Confirmação"
                        message={
                            orcamento && (
                                <span>
                                    {"Deseja gerar documento do orçamento de código: "}
                                    <b>{orcamento.id}</b>?
                                </span>
                            )
                        }
                        icon="pi pi-info-circle p-mr-3"
                        accept={gerarArquivo}
                        acceptLabel="Sim"
                        acceptClassName="p-button-danger"
                        reject={esconderDeleteDialog}
                        rejectLabel="Não"
                    />
                </div>
            </div>
        </div>
    );
};
