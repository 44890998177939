import React, { useCallback, useEffect, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";

import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";

import useCaixaMovimento from "@/hooks/useCaixaMovimento";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import useAuth from "@/hooks/useAuth";

import { NATUREZA_PLANO_CONTAS } from "@/assets/constants/constants";
import { TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE } from "@/assets/constants/financeiro";
import { axiosGet, axiosPost } from "@/services/http";
import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";
import { MakoInputPerfil } from "@/components/MakoInputs/MakoInputPerfil";

export const FormTransferencia = ({ fecharModal }) => {
    const [caixaTr, setCaixaTr] = useState(null);
    const [possuiCaixa, setPossuiCaixa] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const { showLoading, hideLoading } = useLoading();
    const { caixaMov, padraoPlanos } = useCaixaMovimento();
    const { showSuccess, showError } = useToast();
    const { user } = useAuth();

    const { setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            valor: 0,
            tipo_movimento: "D",
            data_movimentacao: new Date(),
            caixa_movimento: caixaMov?.id,
            historico: "Efetuação de transferência",
            conta_financeira: caixaMov?.caixa.conta_financeira.id,
            plano_contas: null,
            compensado: false,
            cancelado: false,
            usuario_lancamento: user.id,
            operador: null,
            forma_pagamento: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                valor: Yup.number().required("O campo 'valor' é obrigatório").typeError("Informe um 'valor' válido"),
                data_movimentacao: Yup.date()
                    .required("O campo 'data da movimentação' é obrigatório")
                    .typeError("Informe uma 'data' válida"),
                historico: Yup.string()
                    .max(60, "Quantidade máxima de caracteres atingida: 60")
                    .required("O campo 'histórico' é obrigatório.")
                    .typeError("Informe um 'historico' válido"),
                tipo_movimento: Yup.string()
                    .required("O campo 'tipo' é obrigatório.")
                    .typeError("Informe um 'tipo' válido"),
                forma_pagamento: Yup.number()
                    .required("O campo 'forma' é obrigatório")
                    .typeError("Informe uma 'forma' válido"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const saldo_caixa =
                parseFloat(caixaMov.dinheiro_inicial) +
                caixaMov.resumos.reduce((total, item) => total + parseFloat(item.valor), 0);

            if (values.valor <= saldo_caixa) {
                const lançamentoCaixa = {
                    valor: values.valor * -1,
                    tipo_movimento: values.tipo_movimento,
                    data_movimentacao: values.data_movimentacao,
                    caixa_movimento: values.caixa_movimento,
                    historico: values.historico,
                    conta_financeira: values.conta_financeira,
                    plano_contas: padraoPlanos["transferencia"],
                    compensado: values.compensado,
                    cancelado: values.cancelado,
                    usuario_lancamento: values.usuario_lancamento,
                };

                const lancamentoTransferencia = {
                    valor: values.valor,
                    tipo_movimento: values.tipo_movimento,
                    data_movimentacao: values.data_movimentacao,
                    caixa_movimento: caixaTr.id,
                    historico: values.historico,
                    conta_financeira: caixaTr?.caixa.conta_financeira.id,
                    plano_contas: padraoPlanos["transferencia"],
                    compensado: values.compensado,
                    cancelado: values.cancelado,
                    usuario_lancamento: values.usuario_lancamento,
                };

                showLoading();
                let resposta = await axiosPost(`/financeiro/movimentacao-contas-corrente/`, lançamentoCaixa);
                hideLoading();

                if (resposta.status === 201) {
                    showLoading();
                    let resposta = await axiosPost(
                        `/financeiro/movimentacao-contas-corrente/`,
                        lancamentoTransferencia
                    );
                    hideLoading();

                    if (resposta.status === 201) {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Transferência efetuada com sucesso!",
                            life: 1500,
                        });

                        fecharModal();
                    } else {
                        throw new Error("Desculpe, não conseguimos processar sua requisição.");
                    }
                } else {
                    throw new Error("Desculpe, não conseguimos processar sua requisição.");
                }
            } else {
                throw new Error("Desculpe, o caixa não possui saldo suficiente para essa transação.");
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            } else {
                showError({
                    summary: "Erro :(",
                    detail: error.message,
                    life: 3000,
                });
            }
        }
    }

    const aposPesquisarOperador = useCallback(
        (values) => {
            if (values.length === 0) return values;

            return values.filter((item) => item.id !== caixaMov.operador.id);
        },
        [caixaMov]
    );

    const aposPesquisarCaixa = useCallback(
        (values) => {
            if (values.length === 0) return values;

            return values.filter((item) => item.id !== caixaMov.caixa.id);
        },
        [caixaMov]
    );

    const validarCaixa = async () => {
        setCaixaTr(null);
        setPossuiCaixa(false);

        if (typeof formik.values.operador !== "object" || typeof formik.values.caixa !== "object") {
            setErrorMessage("Informe o campo 'operador' e o campo 'caixa'!");
        } else {
            const _operador = formik.values.operador.id;
            const _caixa = formik.values.caixa.id;

            showLoading();
            const response = await axiosGet(
                `/financeiro/caixas-movimento/?caixa=${_caixa}&operador=${_operador}&datahora_fechamento__isnull=true`
            );
            hideLoading();

            if (response.status === 200) {
                if (response.data.results.length > 0) {
                    setErrorMessage(null);
                    setCaixaTr(response.data.results[0]);
                    setPossuiCaixa(true);

                    showSuccess({
                        summary: "Sucesso :)",
                        detail: "Vincúlo de caixa válido encontrado.",
                        life: 3000,
                    });
                } else {
                    setErrorMessage("Não foi encontrado nenhum registro de caixa para este operador");
                }
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos validar o caixa.",
                    life: 3000,
                });
            }
        }
    };

    const caixaAutoCompleteTemplate = (caixa) => {
        caixa.label = `${caixa.id} - ${caixa.nome}`;
        return <div>{`${caixa.id} - ${caixa.nome}`}</div>;
    };

    useEffect(() => {
        resetForm();
    }, [resetForm]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-5">
                    <label htmlFor="operador">Operador do caixa *</label>
                    <MakoInputPerfil
                        id="operador"
                        name="operador"
                        placeholder="Busque pelo nome ou identificador ... (min 4 caracteres)"
                        minCaracteresBusca={4}
                        aposPesquisar={aposPesquisarOperador}
                        value={formik.values.operador}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.operador })}
                    />
                    {formik.errors.operador && <small className="p-error">{formik.errors.operador}</small>}
                </div>
                <div className="p-field p-col-12 p-md-5">
                    <label htmlFor="caixa">Caixa *</label>
                    <MakoAutoComplete
                        id="caixa"
                        name="caixa"
                        placeholder="Digite para buscar ... (min 3 caracteres)"
                        minCaracteresBusca={3}
                        aposPesquisar={aposPesquisarCaixa}
                        itemTemplate={caixaAutoCompleteTemplate}
                        value={formik.values.caixa}
                        onChange={formik.handleChange}
                        field="label"
                        urlSearch="/financeiro/caixas/?search="
                        className={classNames({ "p-invalid": formik.errors.caixa })}
                    />
                    {formik.errors.caixa && <small className="p-error">{formik.errors.caixa}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Button
                        icon="pi pi-search"
                        label="Validar caixa"
                        className="p-button-info p-mt-5"
                        type="button"
                        onClick={validarCaixa}
                    />
                </div>
            </div>
            {errorMessage !== null && (
                <div className="p-mb-2">
                    <span className="p-error">{errorMessage}</span>
                </div>
            )}
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="data_movimentacao">Data *</label>
                    <MakoCalendar
                        id="data_movimentacao"
                        name="data_movimentacao"
                        valueCalendar={formik.values.data_movimentacao}
                        minDate={caixaMov.data_abertura}
                        onChange={formik.handleChange}
                        disabled={!!!possuiCaixa}
                        className={classNames({ "p-invalid": formik.errors.data_movimentacao })}
                    />
                    {formik.errors.data_movimentacao && (
                        <small className="p-error">{formik.errors.data_movimentacao}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="tipo_movimento">Tipo *</label>
                    <Dropdown
                        id="tipo_movimento"
                        name="tipo_movimento"
                        placeholder="Selecione uma conta financeira"
                        options={NATUREZA_PLANO_CONTAS}
                        filter
                        filterBy="label"
                        optionValue="id"
                        optionLabel="label"
                        disabled={!!!possuiCaixa}
                        value={formik.values.tipo_movimento}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.tipo_movimento })}
                    />
                    {formik.errors.tipo_movimento && <small className="p-error">{formik.errors.tipo_movimento}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="forma_pagamento">Forma *</label>
                    <Dropdown
                        id="forma_pagamento"
                        name="forma_pagamento"
                        placeholder="Selecione uma forma"
                        options={TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE}
                        optionValue="id"
                        optionLabel="label"
                        value={formik.values.forma_pagamento}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.forma_pagamento })}
                    />
                    {formik.errors.forma_pagamento && (
                        <small className="p-error">{formik.errors.forma_pagamento}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="valor">Valor *</label>
                    <MakoInputMoeda
                        id="valor"
                        name="valor"
                        somentePositivo
                        disabled={!!!possuiCaixa}
                        valueMoeda={formik.values.valor}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.valor })}
                    />
                    {formik.errors.valor && <small className="p-error">{formik.errors.valor}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <label htmlFor="historico">Histórico *</label>
                    <InputTextarea
                        id="historico"
                        name="historico"
                        rows={4}
                        maxLength={60}
                        disabled={!!!possuiCaixa}
                        value={formik.values.historico}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.historico })}
                    />
                    {formik.errors.historico && <small className="p-error">{formik.errors.historico}</small>}
                </div>
            </div>
            <div>
                <Button label="Gravar" className="p-button-success p-mr-2" type="submit" disabled={!!!possuiCaixa} />
                <Button label="Cancelar" className="p-button-danger" onClick={fecharModal} />
            </div>
        </form>
    );
};
