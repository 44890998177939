import React, { useCallback, useEffect, useState, useImperativeHandle, forwardRef } from "react";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Dropdown } from "@/components/Dropdown";
import { axiosGet, axiosPost } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

const Modal = (props, ref) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [versoesPlanoConta, setVersoesPlanoConta] = useState([]);
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();

    const formik = useFormik({
        initialValues: {
            id_origem: null,
            id_destino: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                id_origem: Yup.number()
                    .required("O campo 'versão de origem' é obrigatório.")
                    .typeError("Selecione uma versão de origem."),
                id_destino: Yup.number()
                    .required("O campo 'versão de destino' é obrigatório.")
                    .typeError("Selecione uma versão de destino."),
            });
            await formSchema.validate(values, { abortEarly: false });
            showLoading();
            const { status } = await axiosPost("/financeiro/importar-versao-plano-conta-financeira/", values);
            hideLoading();
            if (status === 200) {
                showSuccess({
                    summary: "Sucesso!",
                    detail: "Replicação realizada com êxito.",
                    life: 1500,
                });
                formik.setFieldValue("id_origem");
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos replicar o plano de contas.",
                    life: 3000,
                });
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const buscarVersoesPlanoConta = useCallback(async () => {
        setLoading(true);
        const { status, data } = await axiosGet("/financeiro/versoes-planos-contas-contabeis/");
        setLoading(false);
        if (status === 200) {
            setVersoesPlanoConta(data);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não listar as versões do plano de contas.",
                life: 3000,
            });
        }
    }, [showError]);

    useEffect(() => {
        buscarVersoesPlanoConta();
    }, [buscarVersoesPlanoConta]);

    const abrirModal = (versaoPlanoContas) => {
        if (versaoPlanoContas) formik.setFieldValue("id_destino", versaoPlanoContas);
        setVisible(true);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    return (
        <Dialog
            header="Replicar plano de contas"
            visible={visible}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "40vw", display: "block" }}
            onHide={() => setVisible(false)}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-grid p-fluid p-formgrid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="versao-destino">Versão de destino</label>
                        <Dropdown
                            id="versao-destino"
                            name="id_destino"
                            options={versoesPlanoConta}
                            placeholder={!loading ? "Selecione" : "Buscando..."}
                            disabled={loading || true}
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.id_destino}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="versao-origem">Versão de origem *</label>
                        <Dropdown
                            id="versao-origem"
                            name="id_origem"
                            options={versoesPlanoConta}
                            placeholder={!loading ? "Selecione" : "Buscando..."}
                            disabled={loading}
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.id_origem}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
            </form>
        </Dialog>
    );
};

export const ModalReplicarPlanoContas = forwardRef(Modal);
