import React, { useCallback, useEffect, useRef, useState } from "react";

import { Toast } from "primereact/toast";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import { Dropdown } from "@/components/Dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { parseNumberToMoneyHTML } from "@/assets/util/util";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { MakoInputQuantidadeSku } from "@/components/MakoInputs/MakoInputQuantidadeSku";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useTroca from "@/hooks/useTroca";
import classNames from "classnames";
import * as Yup from "yup";
import { ConfirmDialog } from "primereact/confirmdialog";

export const ItensSaidaForm = (props) => {
    const [inserirItem, setInserirItem] = useState(false);
    const [removerItem, setRemoverItem] = useState(false);
    const [item, setItem] = useState(null);
    const [unidadesVenda, setUnidadesVenda] = useState([]);
    const [loading, setLoading] = useState(false);
    const { handleItemSaidaTroca, itenssaida, totalSaida } = useTroca();
    // const { showLoading, hideLoading } = useLoading();
    const listagemRef = useRef(null);
    const toastRef = useRef(null);

    const { setFieldValue, setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            status: "novo",
            sku: null,
            quantidade: null,
            valor_unitario: null,
            encargos: 0,
            subtotal: 1,
            largura: 1,
            altura: 1,
            desconto: 0,
            comprimento: null,
            observacoes: "",
            unidade_venda: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                unidade_venda: Yup.object().required("O campo 'unidade de venda' é obrigatório."),
                quantidade: Yup.number().min(0).required("O campo 'quantidade' é obrigatório."),
                valor_unitario: Yup.number().required("O campo 'valor unitário' é obrigatório."),
                subtotal: Yup.number().required("O campo 'subtotal' é obrigatório."),
                encargos: Yup.number().required("O campo 'encargos' é obrigatório."),
                sku: Yup.object().required("O campo 'item' é obrigatório."),
            });

            values.descricao_reduzida = values.sku.descricao_reduzida;

            if (!values.cubagem) values.cubagem = 1;

            await formSchema.validate(values, {
                abortEarly: false,
            });

            handleItemSaidaTroca(values.status, values);
            if (values.status === "editar") setInserirItem(false);
            resetForm();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const deletarItem = async () => {
        try {
            handleItemSaidaTroca("deletar", { ...item, status: "deletar" });

            toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Item removido com sucesso!",
                life: 3000,
            });
        } catch (error) {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Falha ao remover item!",
                life: 3000,
            });
        }
    };

    const editarItem = (item) => {
        setValues({ ...item, status: "editar" });
        setInserirItem(true);
    };

    const confirmarDelete = (data) => {
        setItem(data);
        setRemoverItem(true);
    };

    const esconderDelete = (data) => {
        setItem(null);
        setRemoverItem(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_PRODUTOS_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar informações do item"
                    onClick={() => editarItem(rowData)}
                    tooltipOptions={{ position: "left" }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_PRODUTOS_EDITAR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Remover item"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    useEffect(() => {
        setFieldValue(
            "subtotal",
            parseFloat(formik.values.valor_unitario) * formik.values.quantidade +
                parseFloat(formik.values.encargos) -
                parseFloat(formik.values.desconto)
        );
    }, [
        setFieldValue,
        formik.values.valor_unitario,
        formik.values.quantidade,
        formik.values.encargos,
        formik.values.desconto,
    ]);

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_CADASTRO_SERVICO_INCLUIR]}
                componente={Button}
                label="Adicionar"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setInserirItem(true)}
            />
        </>
    );

    const colunas = [
        { field: "id", header: "Código", style: { width: "100px" } },
        { field: "descricao_reduzida", header: "Descrição reduzida", style: { minWidth: "250px" } },
        { field: "unidade_venda.nome", header: "Unidade venda", style: { minWidth: "150px" } },
        { field: "quantidade", header: "Quantidade", style: { minWidth: "150px" } },
        {
            field: "valor_unitario",
            header: "Valor unitário",
            action: (e) => parseNumberToMoneyHTML(e.valor_unitario),
            style: { minWidth: "150px" },
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "15%" },
        },
    ];

    const esconderDialog = () => {
        resetForm();
        setInserirItem(false);
    };

    const handleChangeQuantidade = (e) => {
        setFieldValue("subtotal", formik.values.valor_unitario * e.value * formik.values.cubagem || 1);
        formik.handleChange(e);
    };

    const onChangeDimensao = useCallback(
        (e) => {
            setFieldValue("cubagem", e);
        },
        [setFieldValue]
    );

    useEffect(() => {
        if (typeof formik.values?.sku === "object") {
            if (formik.values.sku?.unidademedidasku_set) {
                setLoading(true);
                setUnidadesVenda(formik.values.sku.unidademedidasku_set);
                setLoading(false);
            }
        }
    }, [formik.values?.sku, setUnidadesVenda]);

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        colunas={colunas}
                        painelEsquerdo={cabecalhoTabela}
                        dadosLocal={itenssaida}
                        responsiva
                    />
                    <br />
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor_total">
                                <b>Valor total:</b>
                            </label>
                            <MakoInputMoeda id="valor_total" name="valor_total" disabled value={totalSaida} />
                        </div>
                    </div>
                </div>
                <ConfirmDialog
                    visible={removerItem}
                    onHide={esconderDelete}
                    header="Confirmação"
                    message={
                        item && (
                            <span>
                                {"Deseja realmente remover o item "}
                                <b>{item.descricao_reduzida}</b>?
                            </span>
                        )
                    }
                    icon="pi pi-info-circle p-mr-3"
                    accept={deletarItem}
                    acceptLabel="Sim"
                    acceptClassName="p-button-danger"
                    reject={esconderDelete}
                    rejectLabel="Não"
                />
            </div>
            <Dialog
                header="Inserir Item da Troca"
                visible={inserirItem}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "80vw" }}
                onHide={() => esconderDialog()}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="sku">Item *</label>
                            <MakoBuscaSkuPersonalizada
                                id="sku"
                                name="sku"
                                skuValue={formik.values.sku}
                                skuChange={(e) => {
                                    setFieldValue("sku", e);
                                    setFieldValue("sku_movimenta_estoque", {
                                        ...e.sku_controle_saldo,
                                        label:
                                            typeof e.sku_controle_saldo === "object"
                                                ? `${e.sku_controle_saldo.codigo} - ${e.sku_controle_saldo.descricao_complementar}`
                                                : null,
                                    });
                                }}
                                skuError={formik.errors.sku}
                                exibeDimensoes
                                onChangeAltura={(e) => setFieldValue("altura", e)}
                                alturaValue={formik.values.altura}
                                onChangeLargura={(e) => setFieldValue("largura", e)}
                                larguraValue={formik.values.largura}
                                onChangeComprimento={(e) => setFieldValue("comprimento", e)}
                                comprimentoValue={formik.values.comprimento}
                                dimensaoChange={onChangeDimensao}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="unidade_venda">Unidade de venda *</label>
                            <Dropdown
                                id="unidade_venda"
                                name="unidade_venda"
                                options={unidadesVenda}
                                optionValue="unidade"
                                optionLabel="unidade.nome"
                                placeholder={loading ? "Carregando unidades..." : "Selecione..."}
                                disabled={loading}
                                emptyMessage="Nenhuma unidade disponível."
                                value={formik.values.unidade_venda}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.unidade_venda })}
                            />
                            {formik.errors.unidade_venda && (
                                <small className="p-error">{formik.errors.unidade_venda}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="valor_unitario">Valor Unitário *</label>
                            <MakoInputMoeda
                                id="valor_unitario"
                                name="valor_unitario"
                                value={formik.values.valor_unitario}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.valor_unitario })}
                            />
                            {formik.errors.valor_unitario && (
                                <small className="p-error">{formik.errors.valor_unitario}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="quantidade">Quantidade * </label>
                            <MakoInputQuantidadeSku
                                id="quantidade"
                                name="quantidade"
                                permiteFracionario
                                showButtons
                                value={formik.values.quantidade}
                                onValueChange={handleChangeQuantidade}
                                className={classNames({ "p-invalid": formik.errors.quantidade })}
                            />
                            {formik.errors.quantidade && <small className="p-error">{formik.errors.quantidade}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="desconto">Desconto *</label>
                            <InputNumber
                                id="desconto"
                                name="desconto"
                                mode="decimal"
                                minFractionDigits={0}
                                maxFractionDigits={4}
                                value={formik.values.desconto}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.desconto })}
                            />
                            {formik.errors.desconto && <small className="p-error">{formik.errors.desconto}</small>}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="encargos">Total Encargos *</label>
                            <MakoInputMoeda
                                id="encargos"
                                name="encargos"
                                value={formik.values.encargos}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.encargos })}
                            />
                            {formik.errors.encargos && <small className="p-error">{formik.errors.encargos}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="subtotal">Subtotal *</label>
                            <MakoInputMoeda
                                id="subtotal"
                                name="subtotal"
                                value={formik.values.subtotal}
                                disabled
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.subtotal })}
                            />
                            {formik.errors.subtotal && <small className="p-error">{formik.errors.subtotal}</small>}
                        </div>
                    </div>

                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="observacoes">Observações</label>
                            <InputText
                                id="observacoes"
                                name="observacoes"
                                value={formik.values.observacoes}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                    <div className="p-grid p-col-12 p-md-12 p-justify-end p-mt-1">
                        <Button icon="pi pi-plus" label="Inserir" type="submit" className="p-button-success p-mr-2" />
                        <Button
                            icon="pi pi-times"
                            label="Fechar"
                            type="reset"
                            className="p-button-danger"
                            onClick={() => esconderDialog()}
                        />
                    </div>
                </form>
            </Dialog>
        </div>
    );
};
