import React from "react";
import { InputNumber } from "primereact/inputnumber";

export const MakoInputPercent = ({ casasDecimais = 4, ...props }) => {
    return (
        <InputNumber
            min={0}
            {...props}
            suffix=" %"
            mode="decimal"
            minFractionDigits={casasDecimais}
            maxFractionDigits={casasDecimais}
            autoComplete="off"
        />
    );
};
