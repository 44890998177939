import React from "react";

import { useFormik } from "formik";
import * as Yup from "yup";
import classNames from "classnames";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";

import useCaixaMovimento from "@/hooks/useCaixaMovimento";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import useAuth from "@/hooks/useAuth";

import { axiosPatch } from "@/services/http";
import permissoes from "@/assets/constants/permissoes";

export const FormDesbloquear = ({ fecharModal, handleCaixa }) => {
    const { showLoading, hideLoading } = useLoading();
    const { caixaMov, updateCaixa } = useCaixaMovimento();
    const { showSuccess } = useToast();
    const { user } = useAuth();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            operador_bloqueou: user.nome,
            operador_novo: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                operador_bloqueou: Yup.string()
                    .required("O campo 'operador' é obrigatório.")
                    .typeError("Informe um operador"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            let data = {};

            if (values.operador_novo != null || values.operador_novo === "") {
                data = {
                    bloqueado: false,
                    operador: values.operador_novo.id,
                };
            } else {
                data = {
                    bloqueado: false,
                };
            }

            showLoading();
            const resposta = await axiosPatch(`/financeiro/caixas-movimento/${caixaMov.id}/`, data);
            hideLoading();

            if (resposta.status === 200) {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Caixa desbloqueado com sucesso!",
                    life: 1500,
                });

                updateCaixa(resposta.data);

                if (values.operador_novo != null || values.operador_novo === "") {
                    handleCaixa();
                }

                formik.resetForm();
                fecharModal();
            } else {
                showSuccess({
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos processar sua requisição.",
                    life: 3000,
                });
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-5">
                    <label htmlFor="motivo">Operador que bloqueou o caixa *</label>
                    <InputText
                        id="operador_bloqueou"
                        name="operador_bloqueou"
                        value={formik.values.operador_bloqueou}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.operador_bloqueou })}
                        disabled
                    />
                    {formik.errors.operador_bloqueou && (
                        <small className="p-error">{formik.errors.operador_bloqueou}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-7">
                    <label htmlFor="operador_novo">Novo Operador *</label>
                    <MakoControleAcesso
                        componente={MakoAutoComplete}
                        permissao={[permissoes.FINANCEIRO_FINANCEIRO_CAIXAMOV_DESBLOQUEAR_TERCEIRO]}
                        exibirDesabilitadoSemPermissao={true}
                        id="operador_novo"
                        name="operador_novo"
                        placeholder="Busque pelo nome ou identificador ... (min 4 caracteres)"
                        minCaracteresBusca={4}
                        value={formik.values.operador_novo}
                        onChange={formik.handleChange}
                        field="nome"
                        urlSearch="/pessoas/perfis?query={id,nome,vinculoperfilempresa_set}&nome__contains="
                        className={classNames({ "p-invalid": formik.errors.operador_novo })}
                    />
                    {formik.errors.operador_novo && <small className="p-error">{formik.errors.operador_novo}</small>}
                </div>
            </div>
            <div>
                <Button label="Desbloquear" className="p-button-success p-mr-2" type="submit" />
                <Button label="Cancelar" className="p-button-danger" onClick={fecharModal} />
            </div>
        </form>
    );
};
