import React, { useCallback, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";

import { ModalFiltroAvancadoRecebimentos } from "./ModalFiltroAvancadoRecebimentos";
import { ModalLerCodigoBarras } from "./ModalLerCodigoBarras";
import { PageBase } from "@/components/PageBase";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoCardFinanceiro } from "@/components/MakoCards";
import { Dropdown } from "@/components/Dropdown";
import permissoes from "@/assets/constants/permissoes";
import { parseMoeda } from "@/assets/util/util";
import { axiosGet } from "@/services/http";
import useToast from "@/hooks/useToast";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useEmpresa from "@/hooks/useEmpresa";
import { FILTRO_INDICADORES } from "@/assets/constants/financeiro";
import useClearRefs from "@/hooks/useClearRefs";
import {
    ClienteFiltroTemplate,
    CodigoFiltroTemplate,
    DateFiltroTemplate,
    MoedaFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";

const URL_BACKEND = "/financeiro/recebimentos/?cancelado=False";

export const FinanceiroRecebimentosPage = () => {
    const [urlPesquisa, setUrlPesquisa] = useState(URL_BACKEND);
    const [parcelasSelecionadas, setParcelasSelecionadas] = useState(null);
    const [totalizadorFiltros, setTotalizadorFiltros] = useState(0);
    const [bloquearRecebimentos, setBloquearRecebimentos] = useState(true);
    const [recebimento, setRecebimento] = useState(null);
    const [indicadores, setIndicadores] = useState(null);
    const [filtroIndicadores, setFiltroIndicadores] = useState("mes");
    const [loadingIndicadores, setLoadingIndicadores] = useState(false);
    const listagemRef = useRef(null);
    const modalRef = useRef(null);
    const modalCodBarrasRef = useRef(null);
    const menuRef = useRef(null);
    const modalFiltroAvancadoRef = useRef(null);
    const history = useHistory();
    const { showError, showWarning } = useToast();
    const [formatDocumento] = useFormatCNPJCPF();
    const { empresaSelecionadaId } = useEmpresa();

    useClearRefs(listagemRef, modalRef, modalCodBarrasRef, menuRef, modalFiltroAvancadoRef);

    const painelEsquerdoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_FINANCEIRO_RECEBIMENTOS_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2 p-mb-2"
                onClick={() => history.push("/financeiro/financeiro/recebimentos/form")}
            />
            <MakoControleAcesso
                permissao={[
                    permissoes.FINANCEIRO_FINANCEIRO_RECEBIMENTOS_EFETIVAR,
                    permissoes.FINANCEIRO_FINANCEIRO_CAIXA_MOVIMENTAR,
                ]}
                componente={Button}
                disabled={bloquearRecebimentos}
                label="Receber"
                icon="pi pi-dollar"
                className="p-button-info p-mr-2 p-mb-2"
                onClick={() =>
                    history.push({
                        pathname: "/financeiro/financeiro/recebimentos/efetivados/form",
                        state: parcelasSelecionadas,
                    })
                }
            />
            <Button
                label="Ler código de barras"
                icon="fas fa-barcode"
                className="p-button-help p-mr-2 p-mb-2"
                onClick={() => modalCodBarrasRef.current?.abrirModal()}
            />
            <Button
                label="Filtro avançado"
                icon="pi pi-filter"
                className="p-button-help p-mr-2 p-mb-2"
                badge={totalizadorFiltros > 0 ? totalizadorFiltros : null}
                onClick={() => modalFiltroAvancadoRef.current?.abrirModal()}
            />
            <Button
                label="Limpar filtro"
                icon="pi pi-filter-slash"
                className="p-button-outlined p-mr-2 p-mb-2"
                onClick={() => {
                    setUrlPesquisa(URL_BACKEND);
                    setTotalizadorFiltros(0);
                }}
            />
        </>
    );

    const painelDireito = (
        <Dropdown
            options={FILTRO_INDICADORES}
            placeholder="Filtro de indicadores"
            value={filtroIndicadores}
            onChange={(e) => setFiltroIndicadores(e.value)}
        />
    );

    const validarRecebimentosSelecionados = useCallback(() => {
        if (parcelasSelecionadas) {
            const devedores = parcelasSelecionadas
                .map((parcela) => !parcela.quitado && parcela.devedor.id)
                .filter(Boolean);
            const boletos = parcelasSelecionadas.map((parcela) => !parcela.quitado && parcela.boleto);
            const blockDevedores = [...new Set(devedores)].length > 1;
            const blockBoletos = [...new Set(boletos)].length > 1;
            setBloquearRecebimentos(blockBoletos || blockDevedores);
        } else {
            setBloquearRecebimentos(true);
        }
    }, [parcelasSelecionadas]);

    useEffect(() => {
        validarRecebimentosSelecionados();
    }, [validarRecebimentosSelecionados]);

    const rowClass = (rowData) => {
        return {
            "table-recebimentos-overdue": !rowData.quitado && rowData.dias_atraso > 0,
            "table-recebimentos-pending": !rowData.quitado && rowData.dias_atraso < 0,
            "table-recebimentos-effective": rowData.quitado,
        };
    };

    const numeroParcelaBodyTemplate = (rowData) => {
        if (rowData.numero_parcela === 0) return <span>ENTRADA</span>;
        return <span>{`${rowData.numero_parcela}/${rowData.quantidade_parcelas}`}</span>;
    };

    const acrescimosBodyTemplate = (rowData) => {
        return <span>{parseMoeda(rowData.juros + rowData.multa, false)}</span>;
    };

    const actionBodyTemplate = (rowData) => {
        const items = [
            {
                label: "Editar",
                icon: "pi pi-pencil",
                disabled: rowData.quitado,
                command: () =>
                    history.push({
                        pathname: "/financeiro/financeiro/recebimentos/form",
                        state: recebimento.agrupador,
                    }),
            },
            {
                label: "Fracionar",
                icon: "fas fa-chart-pie",
                disabled: rowData.quitado,
                command: () =>
                    history.push({
                        pathname: "/financeiro/financeiro/recebimentos/fracionar",
                        state: recebimento,
                    }),
            },
            {
                label: "Cancelar",
                icon: "pi pi-times",
                disabled: rowData.quitado,
                // command: () => {
                //     setRecebimento(rowData);
                //     setConfirmDialog(true);
                // },
            },
            {
                label: "Imprimir recibo",
                icon: "pi pi-print",
                disabled: !rowData.quitado,
                command: () => {},
            },
        ];

        return (
            <div className="actions">
                <Menu model={items} popup ref={menuRef} />
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_RECEBIMENTOS_EDITAR]}
                    componente={Button}
                    icon="pi pi-cog"
                    className="p-button-rounded p-button-info"
                    onClick={(e) => {
                        setRecebimento(rowData);
                        menuRef.current?.toggle(e);
                    }}
                />
            </div>
        );
    };

    const empresaBodyTemplate = (rowData) => {
        const { empresa } = rowData;
        if (empresa?.nome_curto?.trim().length > 0) return <span>{empresa.nome_curto}</span>;
        return <span>{formatDocumento(empresa.identificacao)}</span>;
    };

    const getIndicadoresRecebimento = useCallback(async () => {
        if (!empresaSelecionadaId || !filtroIndicadores) return;
        const params = {
            empresa: empresaSelecionadaId,
            filtro: filtroIndicadores,
        };
        setLoadingIndicadores(true);
        const { status, data } = await axiosGet("/financeiro/indicadores/recebimentos/", { params });
        setLoadingIndicadores(false);
        if (status === 200) {
            setIndicadores(data.result);
        } else if (status !== 500 && data?.msg) {
            showWarning({
                summary: "Oopss",
                detail: data.msg,
                life: 3000,
            });
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos processar sua requisição.",
                life: 3000,
            });
        }
    }, [empresaSelecionadaId, filtroIndicadores, showWarning, showError]);

    useEffect(() => {
        getIndicadoresRecebimento();
    }, [getIndicadoresRecebimento]);

    const colunas = [
        { selectionMode: "multiple", style: { width: "5%" } },
        { field: "id", header: "Lanc.", filter: true, filterElement: CodigoFiltroTemplate },
        // {
        //     field: "empresa",
        //     header: "Empresa",
        //     action: (e) => empresaBodyTemplate(e),
        // },
        { field: "documento", header: "Documento", filter: true, filterElement: TextoFiltroTemplate },
        {
            field: "numero_parcela",
            header: "Parcela",
            filter: true,
            filterElement: CodigoFiltroTemplate,
            style: { width: "8%" },
            action: (e) => numeroParcelaBodyTemplate(e),
        },
        {
            field: "devedor.nome",
            header: "Cliente",
            filter: true,
            filterElement: ClienteFiltroTemplate,
            filterField: "devedor",
        },
        {
            field: "vencimento",
            header: "Vencimento",
            style: { width: "9%" },
            dateFormat: "dd/MM/yyyy",
            filter: true,
            filterElement: DateFiltroTemplate,
        },
        { field: "dias_atraso", header: "Dias", style: { width: "5%" } },
        { field: "valor", header: "Valor", money: true, filter: true, filterElement: MoedaFiltroTemplate },
        { field: "acrescimos", header: "Acrés.", align: "right", action: (e) => acrescimosBodyTemplate(e) },
        {
            field: "actions",
            header: "Ações",
            style: { width: "5%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    const onFinish = (url, contador) => {
        setUrlPesquisa(url);
        setTotalizadorFiltros(contador);
    };

    return (
        <>
            <div className="p-fluid p-grid">
                <div className="p-col-12 p-md-3">
                    <MakoCardFinanceiro blocked={loadingIndicadores} title="A receber" value={indicadores?.a_receber} />
                </div>
                <div className="p-col-12 p-md-3">
                    <MakoCardFinanceiro
                        blocked={loadingIndicadores}
                        title="Para hoje"
                        value={indicadores?.receber_hoje}
                    />
                </div>
                <div className="p-col-12 p-md-3">
                    <MakoCardFinanceiro
                        blocked={loadingIndicadores}
                        title="Recebido hoje"
                        value={indicadores?.recebido_hoje}
                    />
                </div>
                <div className="p-col-12 p-md-3">
                    <MakoCardFinanceiro
                        blocked={loadingIndicadores}
                        title="Total recebido"
                        value={indicadores?.total_recebido}
                    />
                </div>
            </div>
            <ModalFiltroAvancadoRecebimentos ref={modalFiltroAvancadoRef} url={URL_BACKEND} onSuccess={onFinish} />
            <PageBase>
                <MakoListagem
                    ref={listagemRef}
                    titulo="Recebimentos"
                    colunas={colunas}
                    urlPesquisa={urlPesquisa}
                    painelEsquerdo={painelEsquerdoTabela}
                    painelDireito={painelDireito}
                    filtarPorEmpresa
                    naoBuscarSemEmpresa
                    configTabela={{
                        lazy: true,
                        paginator: true,
                        rowClassName: rowClass,
                        selection: parcelasSelecionadas,
                        onSelectionChange: (e) => setParcelasSelecionadas(e.value),
                        selectionMode: "multiple",
                    }}
                    filtros={{
                        id: { value: null, matchMode: "equals" },
                        documento: { value: null, matchMode: "equals" },
                        numero_parcela: { value: null, matchMode: "equals" },
                        data_emissao: { value: null, matchMode: "equals" },
                        vencimento: { value: null, matchMode: "equals" },
                        valor: { value: null, matchMode: "equals" },
                        devedor: { value: null, matchMode: "equals" },
                    }}
                />
                <ModalLerCodigoBarras
                    ref={modalCodBarrasRef}
                    onLer={(e) => setUrlPesquisa(`${URL_BACKEND}&boleto__codigo_barras=${e}`)}
                />
            </PageBase>
        </>
    );
};
