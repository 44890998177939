import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { axiosDelete } from "@/services/http";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import useClearRefs from "@/hooks/useClearRefs";
import {
    AtivoFiltroTemplate,
    ClienteFiltroTemplate,
    NumeroFiltroTemplate,
    PerfilFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";

export const CarteiraClientePage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess } = useToast();
    useClearRefs(listagemRef);

    const deletarCarteiraClientes = async (value) => {
        showLoading();
        const json = await axiosDelete(`/pessoas/carteiras-clientes/${value.id}/`);
        hideLoading();

        if (json.status === 204) {
            listagemRef.current?.buscarDados();
        } else {
            showSuccess({
                severity: "error",
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
        }
    };

    const confirmarDelete = (value) => {
        confirmDialog({
            message: "Deseja mesmo excluir a carteira de clientes?",
            header: "Confirmação de exclusão",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Deletar",
            accept: () => deletarCarteiraClientes(value),
            rejectLabel: "Cancelar",
        });
    };

    const cabecalhoTabela = (
        <MakoControleAcesso
            permissao={[permissoes.VENDAS_CADASTRO_CARTEIRACLIENTE_INCLUIR]}
            componente={Button}
            label="Nova"
            icon="pi pi-plus"
            className="p-button-success p-mr-2"
            onClick={() => history.push("/vendas/cadastros/carteira-clientes/form")}
        />
    );

    const statusBodyTemplate = (rowData) => {
        if (rowData.ativo) {
            return <Tag severity="success" value="ATIVO" />;
        }

        return <Tag severity="danger" value="INATIVO" />;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_CARTEIRACLIENTE_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    onClick={() =>
                        history.push({
                            pathname: "/vendas/cadastros/carteira-clientes/form",
                            state: rowData,
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_CARTEIRACLIENTE_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                />
            </div>
        );
    };

    const PrioridadeFiltroTemplate = (options) => {
        return (
            <NumeroFiltroTemplate
                inputProps={{
                    mode: "decimal",
                    useGrouping: false,
                    min: 1,
                }}
                options={options}
            />
        );
    };

    const colunas = [
        {
            field: "profissional.nome",
            header: "Profissional",
            style: { width: "35%" },
            filter: true,
            filterElement: PerfilFiltroTemplate,
            filterField: "profissional",
        },
        {
            field: "cliente.nome",
            header: "Cliente",
            style: { width: "35%" },
            filter: true,
            filterElement: ClienteFiltroTemplate,
            filterField: "cliente",
        },
        {
            field: "prioridade",
            header: "Prioridade",
            style: { width: "10%" },
            filter: true,
            filterElement: PrioridadeFiltroTemplate,
        },
        {
            field: "ativo",
            header: "Status",
            style: { width: "10%" },
            action: (e) => statusBodyTemplate(e),
            filter: true,
            filterElement: AtivoFiltroTemplate,
        },
        { field: "action", header: "Ações", style: { width: "10%" }, action: (e) => actionBodyTemplate(e) },
    ];

    return (
        <div className="p-grid">
            <ConfirmDialog />
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Carteira de Clientes"
                        colunas={colunas}
                        painelEsquerdo={cabecalhoTabela}
                        urlPesquisa="/pessoas/carteiras-clientes/"
                        configTabela={{
                            paginator: true,
                            lazy: true,
                        }}
                        filtros={{
                            profissional: { value: null, matchMode: "equals" },
                            cliente: { value: null, matchMode: "equals" },
                            prioridade: { value: null, matchMode: "equals" },
                            ativo: { value: true, matchMode: "equals" },
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
