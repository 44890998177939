import React, { useCallback, useEffect, useState, memo } from "react";
import { Dropdown as DropdownPR } from "primereact/dropdown";

import { axiosGet } from "@/services/http";
import useToast from "@/hooks/useToast";
import axios from "axios";

// IMPORTANTE
// aposBuscar -> sempre utilize useMemo ou useCallback nesta função

const MakoDropdown = ({
    url,
    buscar = true,
    options,
    disabled,
    setObjects,
    aposBuscar,
    placeholder = "Selecione",
    ...props
}) => {
    const [dados, setDados] = useState([]);
    const [loading, setLoading] = useState(false);
    const { showWarning, showError } = useToast();

    const fetchDados = useCallback(
        async (cancelToken) => {
            if (!url || !buscar) return null;

            setLoading(true);
            const { status, data } = await axiosGet(url, { cancelToken });
            setLoading(false);

            if (status === 200) {
                let { results } = data;
                if (typeof aposBuscar === "function") {
                    results = await aposBuscar(results);
                }
                setDados(results);
                if (typeof setObjects === "function") {
                    setObjects(results);
                }
            } else if (status === 401) {
                showWarning({
                    summary: "Acesso expirado!",
                    detail: "Você não está autenticado, faça login novamente.",
                    life: 3000,
                });
            } else if (status !== 500) {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos listar suas informações.",
                    life: 3000,
                });
            }
        },
        [url, buscar, setObjects, aposBuscar, showWarning, showError]
    );

    useEffect(() => {
        const { token, ...cancelToken } = axios.CancelToken.source();
        if (options instanceof Array) {
            setDados(options);
        } else {
            fetchDados(token);
        }
        return function clear() {
            cancelToken.cancel();
        };
    }, [fetchDados, options]);

    return (
        <DropdownPR
            options={dados}
            placeholder={!loading ? placeholder : "Carregando..."}
            disabled={loading || disabled}
            emptyMessage="Nenhum registro disponível"
            emptyFilterMessage="Nenhum registro encontrado"
            showClear
            {...props}
        />
    );
};

export const Dropdown = memo(MakoDropdown);
