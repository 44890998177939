import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";
import { SEXO, ESTADO_CIVIL } from "@/assets/constants/constants";
import { dataToStr } from "@/assets/util/datas";
import { axiosGet } from "@/services/http";
import useToast from "@/hooks/useToast";
import { Panel } from "primereact/panel";
import { Ripple } from "primereact/ripple";
import { totalizadorFiltrosAplicados } from "@/assets/util/util";

const INITIAL_VALUES = {
    perfil_pf__data_nascimento_inicio__gte: null,
    perfil_pf__data_nascimento_fim__lte: null,
    mes_aniversario: null,
    tags: [],
    nome_curto__icontains: "",
    perfil_pf__nome_social__unaccent__icontains: "",
    cidade_endereco_principal: null,
    telefone: "",
    email: "",
    perfil_pj__inscricao_estadual__icontains: "",
    perfil_pf__rg__icontains: "",
    perfil_pf__estado_civil: "",
    perfil_pf__sexo: "",
    perfil_pf__nome_pai__unaccent__icontains: "",
    perfil_pf__nome_mae__unaccent__icontains: "",
    observacao__unaccent__icontains: "",
    perfil_pj__nome_fantasia__icontains: "",
};

const Modal = ({ onFilter, baseUrl, filtros, setFiltro, removerFiltro }, ref) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tags, setTags] = useState([]);
    const { showError } = useToast();

    const MES_ANIVERSARIO = [
        { value: 1, label: "Janeiro" },
        { value: 2, label: "Fevereiro" },
        { value: 3, label: "Março" },
        { value: 4, label: "Abril" },
        { value: 5, label: "Maio" },
        { value: 6, label: "Junho" },
        { value: 7, label: "Julho" },
        { value: 8, label: "Agosto" },
        { value: 9, label: "Setembro" },
        { value: 10, label: "Outubro" },
        { value: 11, label: "Novembro" },
        { value: 12, label: "Dezembro" },
    ];

    const formik = useFormik({
        initialValues: filtros ? { ...INITIAL_VALUES, ...filtros } : INITIAL_VALUES,
        onSubmit: handleSubmit,
        enableReinitialize: true,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                perfil_pf__data_nascimento_inicio__gte: Yup.date().nullable().typeError("Informe uma data válida."),
                perfil_pf__data_nascimento_fim__lte: Yup.date().when("data_nascimento_inicio", {
                    is: (val) => !!val,
                    then: Yup.date()
                        .min(
                            values.perfil_pf__data_nascimento_inicio__gte || new Date(),
                            "A data de nascimento final não pode ser inferior a data de nascimento inicial."
                        )
                        .typeError("Informe uma data válida."),
                    otherwise: Yup.date().nullable(),
                }),
                mes_aniversario: Yup.number().nullable().default(null),
                nome_curto__icontains: Yup.string().default(""),
                perfil_pf__nome_social__unaccent__icontains: Yup.string().default(""),
                cidade_endereco_principal: Yup.object().nullable().default(null),
                telefone: Yup.string().default(""),
                email: Yup.string().email("Informe um email válido."),
                perfil_pj__inscricao_estadual__icontains: Yup.string().default(""),
                perfil_pf__rg__icontains: Yup.string().default(""),
                perfil_pf__estado_civil: Yup.string().default(""),
                perfil_pf__sexo: Yup.string().default(""),
                perfil_pf__nome_pai__unaccent__icontains: Yup.string().default(""),
                perfil_pf__nome_mae__unaccent__icontains: Yup.string().default(""),
                observacao__unaccent__icontains: Yup.string().default(""),
                perfil_pj__nome_fantasia__icontains: Yup.string().default(""),
            });

            const dadosValidados = await formSchema.validate(values, { abortEarly: false });

            if (typeof onFilter === "function") {
                let filtros = {};
                let params = [];
                Object.keys(dadosValidados).map((key) => {
                    if (dadosValidados[key] && dadosValidados[key] !== "") {
                        if (key === "cliente") {
                            filtros[key] = dadosValidados[key].id;
                            return params.push(`${key}=${dadosValidados[key].id}`);
                        }
                        if (key === "cidade_endereco_principal") {
                            filtros[key] = dadosValidados[key].id;
                            return params.push(`${key}=${dadosValidados[key].id}`);
                        }
                        if (
                            key === "perfil_pf__data_nascimento_inicio__gte" ||
                            key === "perfil_pf__data_nascimento_fim__lte"
                        ) {
                            filtros[key] = dataToStr(dadosValidados[key], "yyyy-MM-dd");
                            return params.push(`${key}=${dataToStr(dadosValidados[key], "yyyy-MM-dd")}`);
                        }
                        filtros[key] = dadosValidados[key];
                        params.push(`${key}=${dadosValidados[key]}`);
                    }
                });
                if (params.length > 0) {
                    onFilter(
                        baseUrl + `&${params.join("&")}`,
                        totalizadorFiltrosAplicados(INITIAL_VALUES, dadosValidados)
                    );
                    setFiltro(filtros);
                }
            }
            setVisible(false);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const abrirModal = () => {
        setVisible(true);
    };

    const buscarTagsPerfis = useCallback(async () => {
        setLoading(true);
        const { status, data } = await axiosGet("/pessoas/tags-perfis/?limit=100");
        setLoading(false);

        if (status === 200) {
            setTags(data.results);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos listar suas tags de perfis.",
                life: 3000,
            });
        }
    }, [showError]);

    useEffect(() => {
        buscarTagsPerfis();
    }, [buscarTagsPerfis]);

    useImperativeHandle(ref, () => ({ abrirModal, limparFiltros }));

    const autoCompleteCidadeTemplate = (item) => {
        return <div>{`${item.nome} - ${item.estado.uf}`}</div>;
    };

    const onCancel = () => {
        setVisible(false);
    };

    const limparFiltros = () => {
        formik.resetForm();
    };

    const template = (options, title) => {
        const toggleIcon = options.collapsed ? "pi pi-chevron-down" : "pi pi-chevron-up";
        const className = `${options.className} justify-content-start`;
        const titleClassName = `${options.titleClassName} pl-1`;

        return (
            <div className={className}>
                <span className={titleClassName}>{title}</span>
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        );
    };

    const limparForm = () => {
        removerFiltro();
        limparFiltros();
    };

    return (
        <Dialog
            header="Filtro avançado de pessoas"
            visible={visible}
            onHide={() => setVisible(false)}
            style={{ width: "60vw" }}
        >
            <form>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="nome-curto">Nome curto</label>
                        <InputText
                            id="nome-curto"
                            name="nome_curto__icontains"
                            value={formik.values.nome_curto__icontains}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="telefone">Telefone</label>
                        <InputText
                            id="telefone"
                            name="telefone"
                            value={formik.values.telefone}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="mes-aniversario">Mês aniversário</label>
                        <Dropdown
                            id="mes-aniversario"
                            name="mes_aniversario"
                            placeholder="Selecione"
                            options={MES_ANIVERSARIO}
                            value={formik.values.mes_aniversario}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="tags">Tags de perfil</label>
                        <MultiSelect
                            id="tags"
                            name="tags"
                            options={tags}
                            disabled={loading}
                            placeholder={!loading ? "Selecione" : "Buscando..."}
                            optionValue="id"
                            optionLabel="descricao"
                            maxSelectedLabels={2}
                            selectedItemsLabel="{0} tags selecionadas"
                            value={formik.values.tags}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="estado-civil">Estado civil</label>
                        <Dropdown
                            id="estado-civil"
                            name="perfil_pf__estado_civil"
                            options={ESTADO_CIVIL}
                            placeholder="Selecione"
                            optionValue="id"
                            value={formik.values.perfil_pf__estado_civil}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="email">Email</label>
                        <InputText
                            id="email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.email,
                            })}
                        />
                        {formik.errors.email && <small className="p-error">{formik.errors.email}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="cidade-endereco-principal">Cidade do end. principal</label>
                        <MakoAutoComplete
                            id="cidade-endereco-principal"
                            name="cidade_endereco_principal"
                            urlSearch="/pessoas/cidades?limit=20&nome__unaccent__icontains="
                            field="nome"
                            itemTemplate={autoCompleteCidadeTemplate}
                            minCaracteresBusca={3}
                            placeholder="Digite o nome da cidade para buscar..."
                            value={formik.values.cidade_endereco_principal}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="obs">Observação</label>
                        <InputText
                            id="obs"
                            name="observacao__unaccent__icontains"
                            value={formik.values.observacao__unaccent__icontains}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <Panel headerTemplate={(e) => template(e, "Opções Pessoa Física:")} toggleable collapsed={true}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="perfil_pf__sexo">Sexo</label>
                            <Dropdown
                                id="perfil_pf__sexo"
                                name="perfil_pf__sexo"
                                options={SEXO}
                                placeholder="Selecione"
                                optionValue="id"
                                value={formik.values.perfil_pf__sexo}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="data-nascimento-inicio">Data de nascimento (início)</label>
                            <MakoCalendar
                                id="data-nascimento-inicio"
                                name="perfil_pf__data_nascimento_inicio__gte"
                                maxDate={new Date()}
                                valueCalendar={formik.values.perfil_pf__data_nascimento_inicio__gte}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.perfil_pf__data_nascimento_inicio__gte,
                                })}
                            />
                            {formik.errors.perfil_pf__data_nascimento_inicio__gte && (
                                <small className="p-error">
                                    {formik.errors.perfil_pf__data_nascimento_inicio__gte}
                                </small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="data-nascimento-fim">Data de nascimento (fim)</label>
                            <MakoCalendar
                                id="data-nascimento-fim"
                                name="perfil_pf__data_nascimento_fim__lte"
                                maxDate={new Date()}
                                valueCalendar={formik.values.perfil_pf__data_nascimento_fim__lte}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.perfil_pf__data_nascimento_fim__lte,
                                })}
                            />
                            {formik.errors.perfil_pf__data_nascimento_fim__lte && (
                                <small className="p-error">{formik.errors.perfil_pf__data_nascimento_fim__lte}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="nome-social">Nome social</label>
                            <InputText
                                id="nome-social"
                                name="perfil_pf__nome_social__unaccent__icontains"
                                value={formik.values.perfil_pf__nome_social__unaccent__icontains}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="nome-pai">Nome do pai</label>
                            <InputText
                                id="nome-pai"
                                name="perfil_pf__nome_pai__unaccent__icontains"
                                value={formik.values.perfil_pf__nome_pai__unaccent__icontains}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="nome-mae">Nome da mãe</label>
                            <InputText
                                id="nome-mae"
                                name="perfil_pf__nome_mae__unaccent__icontains"
                                value={formik.values.perfil_pf__nome_mae__unaccent__icontains}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="perfil_pf__rg">RG</label>
                            <InputText
                                id="perfil_pf__rg"
                                name="perfil_pf__rg__icontains"
                                value={formik.values.perfil_pf__rg__icontains}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                </Panel>
                <Panel headerTemplate={(e) => template(e, "Opções Pessoa Juridica:")} toggleable collapsed={true}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="perfil_pj__nome_fantasia">Nome fantasia:</label>
                            <InputText
                                id="perfil_pj__nome_fantasia"
                                name="perfil_pj__nome_fantasia__icontains"
                                value={formik.values.perfil_pj__nome_fantasia__icontains}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="perfil_pj__inscricao_estadual">Inscrição estadual</label>
                            <InputText
                                id="perfil_pj__inscricao_estadual"
                                name="perfil_pj__inscricao_estadual__icontains"
                                value={formik.values.perfil_pj__inscricao_estadual__icontains}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                </Panel>
                <div className="p-grid p-justify-end p-mt-4">
                    <Button
                        type="submit"
                        label="Filtrar"
                        icon="pi pi-filter"
                        className="p-mr-2"
                        onClick={formik.handleSubmit}
                    />
                    <Button
                        type="reset"
                        icon="pi pi-trash"
                        label="Limpar"
                        onClick={limparForm}
                        className="p-button-warning p-mr-2"
                    />
                    <Button
                        type="reset"
                        label="Cancelar"
                        onClick={() => onCancel()}
                        className="p-button-danger p-mr-3"
                    />
                </div>
            </form>
        </Dialog>
    );
};

export const ModalFiltroAvancadoPessoas = forwardRef(Modal);
