import React, { memo, useCallback, useEffect, useState, useRef } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoUploadPreviewImage } from "@/components/MakoUploadPreviewImage";
import { dataToStr } from "@/assets/util/datas";
import { SEXO, ESTADO_CIVIL } from "@/assets/constants/constants";
import usePessoa from "@/hooks/usePessoa";
import useLoading from "@/hooks/useLoading";
import useFormatCnpjCpf from "@/hooks/useFomatCNPJCPF";
import { url } from "@/services/axios";
import { axiosGet } from "@/services/http";

const IdentificacaoPEForm = () => {
    const [loading, setLoading] = useState(false);
    const [passaporteDuplicado, setPassaporteDuplicado] = useState(false);
    const [paises, setPaises] = useState([]);
    const toastRef = useRef(null);
    const { handlePessoaEstrangeira, handleFotoPerfil, pessoa, setSubmit } = usePessoa();
    const { showLoading, hideLoading } = useLoading();
    const [, limparDocumento] = useFormatCnpjCpf();

    const listarPaises = useCallback(async () => {
        showLoading();
        const json = await axiosGet("/pessoas/paises/?limit=300");
        hideLoading();

        if (json.status === 200) {
            setPaises(json.data.results);
        }
    }, [showLoading, hideLoading]);

    useEffect(() => {
        listarPaises();
    }, [listarPaises]);

    const { setValues, ...formik } = useFormik({
        initialValues: {
            nome: "",
            sobrenome: "",
            nacionalidade: "",
            passaporte: "",
            estado_civil: "",
            sexo: "",
            data_nascimento: null,
            nome_curto: "",
            nome_pai: "",
            nome_mae: "",
            obs: "",
        },
        onSubmit: handleSubmit,
    });

    useEffect(() => {
        if (pessoa.perfil_pe) {
            setValues(pessoa.perfil_pe);
        }
    }, [pessoa.perfil_pe, setValues]);

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo 'nome' é obrigatório."),
                sobrenome: Yup.string().required("O campo 'sobrenome' é obrigatório."),
                nacionalidade: Yup.string().required("O campo 'nacionalidade' é obrigatório."),
                passaporte: Yup.string().required("O campo 'passaporte' é obrigatório."),
                estado_civil: Yup.string().required("O campo 'estado civil' é obrigatório."),
                nome_curto: Yup.string().max(25, "Informe no máximo 25 caracteres."),
                sexo: Yup.string().required("O campo 'sexo' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const perfil_pe = {
                ...values,
                passaporte: limparDocumento(values.passaporte),
                data_nascimento: dataToStr(values.data_nascimento, "yyyy-MM-dd"),
            };

            handlePessoaEstrangeira(perfil_pe);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    function resetForm() {
        formik.resetForm();
        handlePessoaEstrangeira(null);
        setSubmit(false);
    }

    const verificarPassaporteExiste = async (value) => {
        const passaporte = limparDocumento(value);

        if (passaporte.length > 0) {
            setLoading(true);
            const json = await axiosGet(
                `/pessoas/perfis/?query={nome,identificacao,ativo}&identificacao=${passaporte}`
            );
            setLoading(false);

            if (json.status === 200) {
                if (json.data.results.length > 0) {
                    if (!json.data.results[0].ativo) {
                        toastRef.current.show({
                            severity: "warn",
                            life: 5000,
                            summary: "Aviso!",
                            detail: "Esse passaporte já se encontra cadastrado na base de dados, porém está desativado.",
                        });
                    } else {
                        toastRef.current.show({
                            severity: "warn",
                            life: 5000,
                            summary: "Aviso!",
                            detail: "Esse passaporte já se encontra cadastrado na base de dados.",
                        });
                    }

                    setPassaporteDuplicado(true);
                    setSubmit(false);
                } else {
                    setPassaporteDuplicado(false);
                }
            }
        }
    };

    const onUploadFotoPerfil = (e) => {
        const { xhr } = e;
        const { data } = JSON.parse(xhr.response);

        handleFotoPerfil(data.foto);

        toastRef.current.show({
            severity: "success",
            summary: "Sucesso",
            detail: "Foto de perfil enviada com sucesso!",
            life: 1500,
        });
    };

    const onErrorFotoPerfil = (e) => {
        const { xhr } = e;

        if (xhr.status === 400) {
            const { msg } = JSON.parse(xhr.response);

            toastRef.current.show({
                severity: "warn",
                summary: "Falha",
                detail: msg,
                life: 3000,
            });
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro :(",
                detail: "Desculpe, não foi possível enviar a foto de perfil.",
                life: 3000,
            });
        }
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <Toast ref={toastRef} />
            <div className="p-grid">
                <div className="p-col-12 p-md-2">
                    <div className="p-fluid p-formgrid p-grid">
                        <MakoUploadPreviewImage
                            ocultarImage={!!!pessoa?.foto}
                            imageConfig={{
                                src: pessoa.foto,
                                alt: "Foto de perfil",
                                width: "180",
                            }}
                            uploadConfig={{
                                url: `${url()}/pessoas/perfis-upload-foto/${pessoa?.id}/`,
                                name: "foto",
                                disabled: !!!pessoa?.id,
                                chooseLabel: "Selecionar foto",
                                maxFileSize: 1000000,
                                invalidFileSizeMessageSummary: "{0}: Tamanho do arquivo inválido, ",
                                invalidFileSizeMessageDetail: "tamanho máximo permitido: {0}.",
                                onUpload: onUploadFotoPerfil,
                                onError: onErrorFotoPerfil,
                            }}
                        />
                        {!!!pessoa?.id && (
                            <small className="p-error">
                                ** Poderá selecionar uma foto para o perfil após finalizar o cadastro. **
                            </small>
                        )}
                    </div>
                </div>
                <div className="p-col-12 p-md-10">
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="nome">Nome *</label>
                            <InputText
                                id="nome"
                                name="nome"
                                value={formik.values.nome}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.nome })}
                            />
                            {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="sobrenome">Sobrenome *</label>
                            <InputText
                                id="sobrenome"
                                name="sobrenome"
                                value={formik.values.sobrenome}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.sobrenome,
                                })}
                            />
                            {formik.errors.sobrenome && <small className="p-error">{formik.errors.sobrenome}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="nome_curto">Nome curto</label>
                            <InputText
                                id="nome_curto"
                                name="nome_curto"
                                value={formik.values.nome_curto}
                                onChange={formik.handleChange}
                            />
                            {formik.errors.nome_curto && <small className="p-error">{formik.errors.nome_curto}</small>}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="nacionalidade">Nacionalidade *</label>
                            <Dropdown
                                id="nacionalidade"
                                name="nacionalidade"
                                options={paises}
                                optionValue="id"
                                optionLabel="nome"
                                placeholder="Selecione..."
                                filter
                                showClear
                                filterBy="nome"
                                value={formik.values.nacionalidade}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.nacionalidade,
                                })}
                            />
                            {formik.errors.nacionalidade && (
                                <small className="p-error">{formik.errors.nacionalidade}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="passaporte">Número do passaporte *</label>
                            <span className="p-input-icon-right">
                                {loading && <i className="pi pi-spin pi-spinner" />}
                                <InputText
                                    id="passaporte"
                                    name="passaporte"
                                    keyfilter="num"
                                    value={formik.values.passaporte}
                                    onBlur={(e) => verificarPassaporteExiste(e.target.value)}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.passaporte })}
                                />
                                {formik.errors.passaporte && (
                                    <small className="p-error">{formik.errors.passaporte}</small>
                                )}
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="estado-civil">Estado Civil *</label>
                            <Dropdown
                                id="estado-civil"
                                name="estado_civil"
                                options={ESTADO_CIVIL}
                                placeholder="Selecione..."
                                optionValue="id"
                                value={formik.values.estado_civil}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.estado_civil,
                                })}
                            />
                            {formik.errors.estado_civil && (
                                <small className="p-error">{formik.errors.estado_civil}</small>
                            )}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="sexo">Sexo *</label>
                            <Dropdown
                                id="sexo"
                                name="sexo"
                                options={SEXO}
                                placeholder="Selecione..."
                                optionValue="id"
                                value={formik.values.sexo}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.sexo })}
                            />
                            {formik.errors.sexo && <small className="p-error">{formik.errors.sexo}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="data-nascimento">Data de Nascimento</label>
                            <MakoCalendar
                                id="data-nascimento"
                                name="data_nascimento"
                                maxDate={new Date()}
                                valueCalendar={formik.values.data_nascimento}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="nome_pai">Nome do pai</label>
                            <InputText
                                id="nome_pai"
                                name="nome_pai"
                                value={formik.values.nome_pai}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="nome_mae">Nome da mãe</label>
                            <InputText
                                id="nome_mae"
                                name="nome_mae"
                                value={formik.values.nome_mae}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="obs">Observação</label>
                            <InputText id="obs" name="obs" value={formik.values.obs} onChange={formik.handleChange} />
                        </div>
                    </div>
                </div>
            </div>
            <p>
                <b>* Campos obrigatórios</b>
            </p>
            <p className="p-error">* Lembre-se de gravar os dados antes de prosseguir ou finalizar</p>
            <div className="p-grid">
                <div className="p-col-12 p-md-6">
                    <Button
                        type="submit"
                        icon={`pi ${!passaporteDuplicado ? "pi-check" : "pi-times"}`}
                        label={!passaporteDuplicado ? "Gravar" : "Passaporte já cadastrado"}
                        className={`${passaporteDuplicado ? "p-button-danger" : ""} p-mr-2 p-mb-2`}
                        disabled={passaporteDuplicado}
                    />
                    <Button
                        type="reset"
                        icon="pi pi-trash"
                        label="Limpar"
                        className="p-button-warning p-mr-2 p-mb-2"
                        onClick={() => resetForm()}
                    />
                </div>
            </div>
        </form>
    );
};

export default memo(IdentificacaoPEForm);
