import React, { useMemo } from "react";

import { PESSOAS_TAGPERFIL_PADRAO_FORNECEDOR } from "@/assets/constants/parametros";
import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";
import useParam from "@/hooks/useParam";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";

export const MakoInputFornecedor = ({ disabled, somenteAtivo, ...props }) => {
    const { getParam } = useParam();
    const [formatarDocumento] = useFormatCNPJCPF();

    const autoCompleteFornecedorTemplate = (item) => {
        return <div>{`${item.nome} - ${formatarDocumento(item.identificacao)}`}</div>;
    };

    const tagFornecedor = useMemo(() => {
        const tagPerfilFornec = getParam(PESSOAS_TAGPERFIL_PADRAO_FORNECEDOR);
        if (tagPerfilFornec) {
            return tagPerfilFornec.valor;
        }
        return null;
    }, [getParam]);

    const url = useMemo(() => {
        const filtroAtivo = somenteAtivo ? "&ativo=true" : "";
        return `/pessoas/perfis?query={id,nome,identificacao,tipo_pessoa,enderecoperfil_set}${filtroAtivo}&tags=${tagFornecedor}&nome__unaccent__icontains=`;
    }, [somenteAtivo, tagFornecedor]);

    return (
        <>
            <MakoAutoComplete
                disabled={!!!tagFornecedor || disabled}
                minCaracteresBusca={4}
                itemTemplate={autoCompleteFornecedorTemplate}
                field="nome"
                urlSearch={url}
                placeholder="Digite o nome do fornecedor para buscar... (min 4 caracteres)"
                {...props}
            />
            {!!!tagFornecedor && <small className="p-error">Falta parametrizar a identificação do fornecedor</small>}
        </>
    );
};
