import React, { useEffect, useRef, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useHistory } from "react-router-dom";
import { TrocaProvider } from "@/contexts/trocaContext";
import { TrocaDadosBasicosForm } from "./formDadosBasicos";
import { ItensTrocaForm } from "./FormItensTroca/formItensTroca";
import { ItensSaidaForm } from "./formItensSaida";
import useTroca from "@/hooks/useTroca";
import useLoading from "@/hooks/useLoading";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";

const urlvoltar = "/vendas/venda/trocas";

const TabTrocas = (props) => {
    const [activeTab, setActiveTab] = useState(0);
    const toastRef = useRef(null);
    const history = useHistory();
    const { handleTroca, handlePreencherTroca, troca, valorTotal } = useTroca();
    const { showLoading, hideLoading } = useLoading();

    async function carregaTroca(id) {
        try {
            showLoading();
            await handlePreencherTroca(id);
            hideLoading();
        } catch (error) {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não foi possível carregar as informações do crediário.",
                life: 2000,
            });
        }
    }

    useEffect(() => {
        if (props.props?.location.state?.troca?.id && !troca) {
            carregaTroca(props.props?.location.state?.troca?.id);
        }
    }, []);

    async function persistirDados() {
        showLoading();
        const resposta = await handleTroca();
        hideLoading();

        if (resposta.status === 201 || resposta.status === 200) {
            toastRef.current.show({
                severity: "success",
                summary: "Sucesso!",
                detail: "Troca salva com sucesso.",
                life: 3000,
            });
            setTimeout(() => {
                history.push(urlvoltar);
            }, [2000]);
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 2000,
            });
        }
    }

    const alterarTab = (aba) => {
        if (aba === -1) {
            if (activeTab > 0) {
                setActiveTab(activeTab - 1);
            }
        } else {
            if (activeTab < 6) {
                setActiveTab(activeTab + 1);
            }
        }
    };

    const cancelar = () => {
        history.push(urlvoltar);
    };

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <h5>Trocas</h5>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor_total">
                                <b>Valor total:</b>
                            </label>
                            <MakoInputMoeda id="valor_total" name="valor_total" disabled value={valorTotal} />
                        </div>
                    </div>
                    <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
                        <TabPanel header="Dados Básicos" leftIcon="pi pi-save">
                            <TrocaDadosBasicosForm cliente={props.props?.location.state?.cliente || null} />
                        </TabPanel>
                        <TabPanel header="Itens Troca" leftIcon="pi pi-sync" disabled={!troca}>
                            <ItensTrocaForm />
                        </TabPanel>
                        <TabPanel header="Itens Saída" leftIcon="pi pi-reply" disabled={!troca}>
                            <ItensSaidaForm />
                        </TabPanel>
                    </TabView>
                    <div className="p-grid p-fluid p-justify-start">
                        {activeTab > 0 ? (
                            <div className="p-col-6 p-md-2">
                                <Button
                                    label="Anterior"
                                    icon="pi pi-angle-left"
                                    className="p-button-info"
                                    onClick={() => alterarTab(-1)}
                                />
                            </div>
                        ) : null}
                        {activeTab < 2 ? (
                            <div className="p-col-6 p-md-2">
                                <Button
                                    label="Próximo"
                                    icon="pi pi-angle-right"
                                    iconPos="right"
                                    className="p-button-info"
                                    onClick={() => alterarTab(1)}
                                />
                            </div>
                        ) : null}
                        <div className="p-col-6 p-md-2">
                            <Button
                                label="Finalizar"
                                className="p-button-success"
                                type="button"
                                onClick={() => persistirDados()}
                            />
                        </div>
                        <div className="p-col-6 p-md-2">
                            <Button label="Voltar" className="p-button-danger" onClick={cancelar} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const TabTrocaContext = (props) => {
    return (
        <TrocaProvider>
            <TabTrocas props={props} />
        </TrocaProvider>
    );
};
