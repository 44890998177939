import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useHistory } from "react-router-dom";
import { axiosPost } from "@/services/http";
import { Password } from "primereact/password";
import { LOGO_SHARKDEV } from "@/assets/constants/constants";
import useLoading from "@/hooks/useLoading";

export const ConfirmarSenhaPage = (props) => {
    const [novaSenha, setNovaSenha] = useState("");
    const toastRef = useRef(null);
    const { showLoading, hideLoading } = useLoading();
    const history = useHistory();

    async function confirmarSenha() {
        showLoading();
        const resposta = await axiosPost("/auth/users/reset_password_confirm/", {
            uid: props.match.params.id,
            new_password: novaSenha,
            token: props.match.params.token,
        });
        hideLoading();

        if (resposta.status === 201) {
            toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: `Nova senha registrada com sucesso.`,
                life: 4000,
            });

            setTimeout(() => {
                history.push("/login");
            }, 4000);
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "A sua requisição não pode ser concluída.",
                life: 3000,
            });
        }
    }

    return (
        <div className="login-body">
            <Toast ref={toastRef} />
            <div className="login-wrapper">
                <div className="login-panel">
                    <img src={LOGO_SHARKDEV} className="logo" alt="sharkdev-logo" />
                    <div className="login-form" style={{ width: "90%" }}>
                        <label>
                            <b>Insira sua nova senha:</b>
                        </label>
                        <Password
                            id="password"
                            placeholder="Nova senha"
                            promptLabel="Informe a senha"
                            weakLabel="Fraca"
                            mediumLabel="Média"
                            strongLabel="Forte"
                            feedback={false}
                            value={novaSenha}
                            onChange={(e) => setNovaSenha(e.target.value)}
                        />
                        <Button label="Gravar" icon="pi pi-save" type="button" onClick={confirmarSenha} />
                    </div>
                    <p>
                        <a href="/login">Voltar a página inicial.</a>
                    </p>
                </div>
                <div className="login-image">
                    <div className="login-image-content">
                        <h1>Olá,</h1>
                        <h1>seja muito bem vindo </h1>
                        <h1>ao Mako!</h1>
                    </div>
                    <div className="image-footer">
                        <p>FACEBOOK</p>
                        <p>INSTAGRAM</p>
                        <p>
                            {`${new Date().getFullYear()} - Desenvolvido por `}
                            <a href="https://sharkdev.com.br" target="blank">
                                SharkDev
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
