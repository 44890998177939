import React, { useCallback, useEffect, useRef, useState } from "react";

import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { MakoCalendar } from "@/components/MakoCalendar";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";
import { axiosPost, axiosDelete, axiosPatch, axiosGet } from "@/services/http";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useHistory } from "react-router-dom";
import {
    TIPO_ESFERA_FISCAL_CHOICE,
    TIPO_MOVIMENTO_FISCAL_CHOICE,
    TIPO_OPERACAO_ENTRADA_FISCAL_CHOICE,
} from "@/assets/constants/constants";
import { dataToStr } from "@/assets/util/datas";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import useLocalStorage from "@/hooks/useLocalStorage";
import storageKeys from "@/assets/constants/storage";
import * as Yup from "yup";

export const ParametrosGeraisForm = (props) => {
    const [adicionaCfop, setAdicionaCfop] = useState(false);
    const [cfop, setCfop] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [destinacoes, setDestinacoes] = useState([]);
    const [empresaSelecionada] = useLocalStorage(storageKeys.EMPRESA_KEY);
    const { showLoading, hideLoading } = useLoading();

    const history = useHistory();
    const toastRef = useRef(null);
    const listagemRef = useRef(null);

    const operacaoVazia = {
        empresa: empresaSelecionada?.id,
        descricao: "",
        tipo_movimento: null,
        esfera: null,
        vigencia_final: null,
        destinacao: null,
        operacao_entrada: null,
    };

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: operacaoVazia,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo é obrigatório.").max(60),
                esfera: Yup.string().required("O campo é obrigatório.").typeError("Informe uma 'esfera' válida."),
                tipo_movimento: Yup.string().required("O campo é obrigatório.").typeError("Informe um 'tipo' válido."),
                destinacao: Yup.string()
                    .nullable()
                    .when("tipo_movimento", {
                        is: (val) => val === "E",
                        then: Yup.string().required("O campo é obrigatório."),
                    }),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            values.vigencia_final = dataToStr(values.vigencia_final, "yyyy-MM-dd");
            if (values.tipo_movimento === "S") values.destinacao = null;
            if (!values.id) {
                showLoading();
                const resposta = await axiosPost("/fiscal/operacoes-fiscais/", values);
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Operação financeira criada com sucesso!",
                        life: 1500,
                    });

                    toastRef.current.show({
                        severity: "info",
                        summary: "Operação cadastrada!",
                        detail: "Agora você já pode configurar as outras informações da operação fiscal.",
                        sticky: true,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push({
                            pathname: "/fiscal/cadastros/operacoes-fiscais/form",
                            opFiscal: resposta.data,
                        });
                    }, 100);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                delete values.ncmoperacaofiscal_set;
                delete values.itemoperacaofiscal_set;
                delete values.cfopoperacaofiscal_set;
                showLoading();
                const resposta = await axiosPatch(`/fiscal/operacoes-fiscais/${values.id}/`, {
                    operacao_entrada: values.operacao_entrada || null,
                    ...values,
                });
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Operação financeira alterada com sucesso!",
                        life: 1500,
                    });
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    useEffect(() => {
        if (props.location?.opFiscal) {
            setValues({ ...props.location.opFiscal, destinacao: props.location.opFiscal.destinacao?.id || null });
        }
    }, [props.location?.opFiscal, setValues]);

    async function handleInsertCfop() {
        try {
            const body = {
                operacao_fiscal: formik.values.id,
                cfop: cfop.id,
            };

            showLoading();
            const resposta = await axiosPost("/fiscal/cfop-operacoes-fiscais/", body);
            hideLoading();

            if (resposta.status === 201) {
                toastRef.current.show({
                    severity: "success",
                    summary: "Sucesso",
                    detail: "Cfop inserido com sucesso!",
                    life: 1500,
                });
                setCfop(null);
                setFieldValue("cfopoperacaofiscal_set", [resposta.data]);
                listagemRef.current?.buscarDados();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
            }
        } catch (error) {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 1500,
            });
            hideLoading();
        }
    }

    const confirmarDelete = (cfop) => {
        setCfop(cfop);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
        setCfop(null);
    };

    const deleteParametro = async () => {
        showLoading();
        const resposta = await axiosDelete(`/fiscal/cfop-operacoes-fiscais/${cfop.id}/`);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Cfop removido com sucesso!",
                life: 3000,
            });

            listagemRef.current?.buscarDados();
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
        setDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_PRODUTOS_EDITAR]}
                    componente={Button}
                    icon="pi pi-times"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Remover"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <Button
                label="Adicionar"
                icon="pi pi-plus"
                className="p-button-success p-button-outlined p-mr-2"
                onClick={() => setAdicionaCfop(true)}
            />
        </>
    );

    const listarDestinacoes = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/compras/destinacoes-entradas?limit=3000&query={id, nome}");
        hideLoading();

        if (resposta.status === 200 || resposta.status === 201) {
            setDestinacoes(resposta.data.results);
        }
    }, [showLoading, hideLoading]);

    const colunas = [
        { field: "cfop.codigo", header: "Código", style: { width: "15%" } },
        { field: "cfop.descricao", header: "Descrição" },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "15%" },
        },
    ];

    const verificaDigito = (data) => {
        if (formik.values.operacao_entrada) {
            let lista = [];
            data.forEach((cfop) => {
                if (formik.values.operacao_entrada === parseInt(cfop.codigo[1])) lista.push(cfop);
            });
            return lista;
        }
        return data;
    };

    const autoCompleteCfopTemplate = (cfop) => {
        cfop.label = `${cfop.codigo} - ${cfop.descricao}`;
        return <div>{`${cfop.codigo} - ${cfop.descricao}`}</div>;
    };

    const limparFormulario = () => {
        formik.resetForm();
    };

    const bloqueiaCampos = () => {
        return (
            formik.values.itemoperacaofiscal_set?.length > 0 ||
            formik.values.ncmoperacaofiscal_set?.length > 0 ||
            formik.values.cfopoperacaofiscal_set?.length > 0
        );
    };

    useEffect(() => {
        listarDestinacoes();
    }, [listarDestinacoes]);

    return (
        <>
            <Toast ref={toastRef} />
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="descricao">Descrição *</label>
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="tipo_movimento">Entrada / Saída *</label>
                        <Dropdown
                            id="tipo_movimento"
                            name="tipo_movimento"
                            placeholder="Selecione um tipo de movimentação"
                            options={TIPO_MOVIMENTO_FISCAL_CHOICE}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.tipo_movimento}
                            disabled={bloqueiaCampos()}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo_movimento })}
                        />
                        {formik.errors.tipo_movimento && (
                            <small className="p-error">{formik.errors.tipo_movimento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="esfera">Esfera *</label>
                        <Dropdown
                            id="esfera"
                            name="esfera"
                            placeholder="Selecione uma esfera"
                            options={TIPO_ESFERA_FISCAL_CHOICE}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.esfera}
                            disabled={bloqueiaCampos()}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.esfera })}
                        />
                        {formik.errors.esfera && <small className="p-error">{formik.errors.esfera}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="vigencia_final">Vigência final</label>
                        <MakoCalendar
                            id="vigencia_final"
                            name="vigencia_final"
                            valueCalendar={formik.values.vigencia_final}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.vigencia_final,
                            })}
                        />
                        {formik.errors.vigencia_final && (
                            <small className="p-error">{formik.errors.vigencia_final}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="operacao_entrada">Classe de movimento fiscal</label>
                        <Dropdown
                            id="operacao_entrada"
                            name="operacao_entrada"
                            placeholder="Selecione uma operação"
                            options={TIPO_OPERACAO_ENTRADA_FISCAL_CHOICE}
                            optionValue="value"
                            optionLabel="label"
                            showClear
                            disabled={bloqueiaCampos()}
                            value={formik.values.operacao_entrada}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.operacao_entrada })}
                        />
                        {formik.errors.operacao_entrada && (
                            <small className="p-error">{formik.errors.operacao_entrada}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    {formik.values.tipo_movimento === "E" ? (
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="destinacao">Destinação da entrada *</label>
                            <Dropdown
                                id="destinacao"
                                name="destinacao"
                                placeholder="Selecione uma destinação"
                                options={destinacoes}
                                filter
                                filterBy="nome"
                                optionValue="id"
                                optionLabel="nome"
                                disabled={bloqueiaCampos()}
                                value={formik.values.destinacao}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.destinacao })}
                            />
                            {formik.errors.destinacao && <small className="p-error">{formik.errors.destinacao}</small>}
                        </div>
                    ) : null}
                </div>
                <p></p>
                <Button
                    label={formik.values.id ? "Salvar" : "Criar Operação"}
                    icon="pi pi-save"
                    className="p-button-success p-mr-2"
                    type="submit"
                />
                <Button
                    label="Resetar"
                    className="p-button-warning p-mr-2"
                    icon="pi pi-trash"
                    type="reset"
                    disabled={formik.values?.id}
                    onClick={() => limparFormulario()}
                />
            </form>
            {formik.values.id ? (
                <MakoListagem
                    ref={listagemRef}
                    titulo="CFOP's permitidos"
                    colunas={colunas}
                    painelEsquerdo={cabecalhoTabela}
                    urlPesquisa={`/fiscal/cfop-operacoes-fiscais?operacao_fiscal=${formik.values?.id || null}`}
                    responsiva
                    configTabela={{
                        paginator: true,
                        lazy: true,
                    }}
                />
            ) : null}
            <Dialog
                header="Adicionar CFOP"
                visible={adicionaCfop}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "45vw", display: "block" }}
                onHide={() => setAdicionaCfop(false)}
            >
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="cfop">CFOP *</label>
                        <MakoAutoComplete
                            id="cfop"
                            name="cfop"
                            placeholder="Busque pelo código ou descrição ... (min 3 caractéres)"
                            minCaracteresBusca={3}
                            value={cfop}
                            onChange={(e) => setCfop(e.target.value)}
                            itemTemplate={autoCompleteCfopTemplate}
                            aposPesquisar={(e) => verificaDigito(e)}
                            field="label"
                            urlSearch={`/fiscal/cfop?tipo_movimentacao=S&search=`}
                        />
                    </div>
                </div>
                {formik.values.tipo_movimento === "E" ? (
                    <h7>
                        <b>OBS:</b> O código selecionado deverá ser o código informado pelo seu fornecedor.
                    </h7>
                ) : null}
                <div className="p-grid p-ml-1 p-mt-3">
                    <Button
                        label="Inserir"
                        icon="pi pi-plus"
                        className="p-button-success"
                        type="button"
                        onClick={() => handleInsertCfop()}
                        disabled={!cfop}
                    />
                </div>
            </Dialog>
            <ConfirmDialog
                visible={deleteDialog}
                onHide={esconderDeleteDialog}
                header="Confirmação"
                message={
                    cfop && (
                        <span>
                            {"Deseja realmente remover o Cfop "}
                            <b>{cfop.descricao}</b>?
                        </span>
                    )
                }
                icon="pi pi-info-circle p-mr-3"
                accept={deleteParametro}
                acceptLabel="Sim"
                acceptClassName="p-button-danger"
                reject={esconderDeleteDialog}
                rejectLabel="Não"
            />
        </>
    );
};
