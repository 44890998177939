import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { Toast } from "primereact/toast";
import { Tag } from "primereact/tag";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";

export const VendasConsultaSpcPage = (props) => {
    const [cliente, setCliente] = useState(null);
    const [url, setUrl] = useState("/vendas/consultas-spc/");
    const [redirecionar, setRedirecionar] = useState(null);

    const toastRef = useRef(null);
    const listagemRef = useRef(null);

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_CONSULTASPC_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de consulta"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        setRedirecionar({
                            pathname: "/vendas/venda/consultas-spc/form",
                            state: { consulta: rowData },
                        })
                    }
                />
            </div>
        );
    };

    const autoCompleteTemplate = (valor) => {
        valor.label = `${valor.nome} - ${valor.identificacao}`;
        return `${valor.nome} - ${valor.identificacao}`;
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_VENDA_CONSULTASPC_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setRedirecionar("/vendas/venda/consultas-spc/form")}
            />
        </>
    );

    const statusBodyTemplate = (rowData) => {
        if (rowData.indicador_restrição) {
            return <Tag severity="danger" value="RESTRITO" />;
        }

        return <Tag severity="success" value="LIBERADO" />;
    };

    const colunas = [
        { field: "identificador", header: "Identificador", style: { width: "12%" } },
        { field: "informacoes_consulta", header: "Informações consulta" },
        { field: "cliente.nome", header: "Cliente" },
        { field: "data", header: "Data", style: { width: "10%" } },
        { field: "hora", header: "Hora", style: { width: "7%" } },
        {
            header: "Restrição",
            field: "indicador_restricao",
            style: { width: "12%" },
            showFilterMatchModes: false,
            showFilterMenu: false,
            action: (e) => statusBodyTemplate(e),
        },

        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "7%" },
        },
    ];

    const selecionaCliente = (usuario) => {
        setCliente(usuario);
        if (typeof usuario === "object") {
            setUrl(`/vendas/consultas-spc?cliente__id=${usuario.id}`);
        } else setUrl(`/vendas/consultas-spc/`);
    };

    const limparFiltro = () => {
        setCliente("");
        setUrl(`/vendas/consultas-spc/`);
    };

    return redirecionar ? (
        <Redirect to={redirecionar} />
    ) : (
        <>
            <div className="p-grid">
                <Toast ref={toastRef} />
                <div className="p-col-12">
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-ml-3">
                            {cliente ? (
                                <Button
                                    icon="pi pi-times"
                                    className="p-button-text p-button-danger p-button-outlined"
                                    onClick={() => limparFiltro()}
                                />
                            ) : null}
                        </div>
                        <div className={`p-field p-col-12 ${cliente ? "p-md-11" : "p-md-12"}`}>
                            <MakoAutoComplete
                                id="destinatario"
                                name="destinatario"
                                placeholder="Busque pelo nome ou cpf/cnpj ... (min 4 caractéres)"
                                minCaracteresBusca={4}
                                value={cliente}
                                onChange={(e) => selecionaCliente(e.target.value)}
                                itemTemplate={autoCompleteTemplate}
                                field="label"
                                urlSearch={"/pessoas/perfis?query={id, nome, identificacao}&search="}
                            />
                        </div>
                    </div>
                    <div className="card">
                        <MakoListagem
                            titulo={
                                props && props.venda
                                    ? null
                                    : cliente && typeof cliente === "object"
                                    ? `Histórico de Consultas SPC: ${cliente.label}`
                                    : "Histórico de Consultas SPC"
                            }
                            painelEsquerdo={props && props.venda ? null : cabecalhoTabela}
                            ref={listagemRef}
                            colunas={colunas}
                            urlPesquisa={url}
                            responsiva
                            configTabela={{
                                paginator: true,
                                lazy: true,
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
