import React, { useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { SIM_NAO_BOOLEAN, TIPO_PRECO_SERVICO } from "@/assets/constants/constants";
import { axiosPost, axiosPut } from "@/services/http";
import useLoading from "@/hooks/useLoading";

const url = "/vendas/servicos-vendas/";

export const VendasServicosForm = (props) => {
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);

    const { setValues, ...formik } = useFormik({
        initialValues: {
            id: "",
            nome: "",
            descricao: "",
            incorporado_sku: false,
            tipo_preco_servico: "F",
            valor: 0,
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo 'nome' é obrigatório."),
                valor: Yup.number()
                    .min(0, "O campo 'valor' não pode ser ZERO.")
                    .required("O campo 'valor' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(`${url}`, values);
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Serviço cadastrado com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        history.push("/vendas/cadastros/servicos");
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(`${url}${values.id}/`, values);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Serviço alterado com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        history.push("/vendas/cadastros/servicos");
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    useEffect(() => {
        if (props.location.state && props.location.state.servico) {
            setValues(props.location.state.servico);
        }
    }, [props.location.state, setValues]);

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <h3>{!formik.values.id ? "Novo Serviço" : "Manutenção de Serviço"}</h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="nome">Nome *</label>
                                <InputText
                                    id="nome"
                                    name="nome"
                                    value={formik.values.nome}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.nome })}
                                />
                                {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="descricao">Descrição</label>
                                <InputTextarea
                                    id="descricao"
                                    name="descricao"
                                    rows={3}
                                    cols={20}
                                    value={formik.values.descricao}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="incorporado_sku">Incoporado ao preço do produto? *</label>
                                <Dropdown
                                    id="incorporado_sku"
                                    name="incorporado_sku"
                                    value={formik.values.incorporado_sku}
                                    options={SIM_NAO_BOOLEAN}
                                    placeholder="Selecione..."
                                    optionLabel="label"
                                    optionValue="id"
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.incorporado_sku })}
                                    tooltip="Marque essa opção como 'Sim', se o serviço for oferecido
                                    somente com um produto agregado."
                                />
                                {formik.errors.incorporado_sku && (
                                    <small className="p-error">{formik.errors.incorporado_sku}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="tipo-preco-servico">Tipo de precificação do serviço *</label>
                                <Dropdown
                                    id="tipo-preco-servico"
                                    name="tipo_preco_servico"
                                    options={TIPO_PRECO_SERVICO}
                                    optionValue="id"
                                    optionLabel="label"
                                    placeholder="Selecione"
                                    value={formik.values.tipo_preco_servico}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.tipo_preco_servico })}
                                />
                                {formik.errors.tipo_preco_servico && (
                                    <small className="p-error">{formik.errors.tipo_preco_servico}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="valor">
                                    {formik.values.tipo_preco_servico === "P" ? "Percentual (%) *" : "Valor (R$) *"}
                                </label>
                                {formik.values.tipo_preco_servico === "P" ? (
                                    <InputNumber
                                        id="valor"
                                        name="valor"
                                        suffix="%"
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        min={0}
                                        value={formik.values.valor}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.valor })}
                                    />
                                ) : (
                                    <MakoInputMoeda
                                        id="valor"
                                        name="valor"
                                        onChangeMoeda={formik.handleChange}
                                        valueMoeda={formik.values.valor}
                                        className={classNames({ "p-invalid": formik.errors.valor })}
                                        tooltip="Definir Preço Medida caso o valor do serviço for calculado posteriormente com as medidas do produto."
                                    />
                                )}
                                {formik.errors.valor && <small className="p-error">{formik.errors.valor}</small>}
                            </div>
                            <div className="p-field-checkbox p-col-12 p-md-3 p-mt-4">
                                <Checkbox
                                    id="ativo"
                                    name="ativo"
                                    checked={formik.values.ativo}
                                    onChange={formik.handleChange}
                                />
                                <label htmlFor="ativo">{formik.values.ativo ? "Ativo" : "Inativo"}</label>
                            </div>
                        </div>
                        <p>
                            <b>* Campos obrigatórios.</b>
                        </p>
                        <div className="p-grid p-col-12 p-md-6">
                            <Button icon="pi pi-save" label="Gravar" type="submit" className="p-button-info p-mr-2" />
                            <Button
                                icon="pi pi-angle-double-left"
                                label="Voltar"
                                type="button"
                                className="p-button-danger p-mr-2"
                                onClick={() => history.push("/vendas/cadastros/servicos")}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
