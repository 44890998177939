import React from "react";
import { InputNumber } from "primereact/inputnumber";

export const MakoInputMoeda = ({ valueMoeda, somentePositivo, quatroCasasDecimais, onChangeMoeda, ...props }) => {
    const handleFocus = (event) => {
        event.target.select();
    };

    const onValueChange = (event) => {
        if (onChangeMoeda) {
            onChangeMoeda(event);
        }
    };

    return (
        <InputNumber
            mode="currency"
            currency="BRL"
            locale="pt-BR"
            minFractionDigits={2}
            maxFractionDigits={quatroCasasDecimais ? 4 : 2}
            min={somentePositivo ? 0 : null}
            onFocus={handleFocus}
            value={valueMoeda}
            onValueChange={onValueChange}
            {...props}
        />
    );
};
