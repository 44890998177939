import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";

import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import { axiosDelete } from "@/services/http";
import { Tag } from "primereact/tag";
import useClearRefs from "@/hooks/useClearRefs";
import useToast from "@/hooks/useToast";
import useEmpresa from "@/hooks/useEmpresa";

const url = "/fiscal/cst-ipi/";

export const FiscalIpiPage = () => {
    const [redirecionar, setRedirecionar] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [ipi, setIpi] = useState(null);
    
    const { showLoading, hideLoading } = useLoading();
    const { empresaSelecionadaId } = useEmpresa();
    const { showSuccess, showError } = useToast();
    const listagemRef = useRef(null);

    useClearRefs(listagemRef)

    const STATUS_IPI = [
        { value: true, label: "Ativo" },
        { value: false, label: "Inativo" },
    ];

    const confirmarDelete = (ipi) => {
        setIpi(ipi);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const deleteParametro = async () => {
        showLoading();
        const resposta = await axiosDelete(`${url}${ipi.id}`);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            showSuccess({
                summary: "Sucesso",
                detail: "IPI deletado com sucesso!",
                life: 3000,
            });

            listagemRef.current?.buscarDados();
        } else {
            showError({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
        setDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_FISCAL_IPI_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar Cadastro de IPÍ"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        setRedirecionar({
                            pathname: "/fiscal/cadastros/outros/ipi/form",
                            state: {
                                ipi: {
                                    ...rowData,
                                    reverso: rowData.reverso?.id,
                                },
                            },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_FISCAL_IPI_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Deletar IPI"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FISCAL_FISCAL_IPI_INCLUIR]}
                componente={Button}
                label="Novo"
                disabled={!empresaSelecionadaId}
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setRedirecionar("/fiscal/cadastros/outros/ipi/form")}
            />
        </>
    );

    const statusFiltroTemplate = (options) => {
        return (
            <Dropdown
                placeholder="Selecione"
                showClear
                value={options.value}
                options={STATUS_IPI}
                onChange={(e) => options.filterCallback(e.value)}
            />
        );
    };

    const statusBodyTemplate = (rowData) => {
        if (rowData.ativo) {
            return <Tag severity="success" value="ATIVO" />;
        }

        return <Tag severity="danger" value="DESATIVADO" />;
    };

    const colunas = [
        {
            field: "id",
            header: "#ID",
            style: { width: "10%" },
            filter: true,
        },
        {
            field: "codigo",
            header: "Código",
            style: { width: "10%" },
            filter: true,
        },
        {
            field: "descricao",
            header: "Descrição",
            filter: true,
        },
        {
            header: "Status",
            field: "ativo",
            filter: true,
            filterElement: statusFiltroTemplate,
            action: (e) => statusBodyTemplate(e),
            style: { width: "10%" },
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    return redirecionar ? (
        <Redirect to={redirecionar} />
    ) : (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="IPI"
                        colunas={colunas}
                        painelEsquerdo={cabecalhoTabela}
                        urlPesquisa={url}
                        responsiva
                        filtarPorEmpresa
                        filtros={{
                            id: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "icontains" }],
                            },
                            codigo: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "icontains" }],
                            },
                            descricao: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "icontains" }],
                            },
                            ativo: { value: "", matchMode: "equals" },
                        }}
                        configTabela={{
                            paginator: true,
                            lazy: true,
                            filterDisplay: "menu",
                        }}
                    />
                    <ConfirmDialog
                        visible={deleteDialog}
                        onHide={esconderDeleteDialog}
                        header="Confirmação"
                        message={
                            ipi && (
                                <span>
                                    {"Deseja realmente excluir o IPI "}
                                    <b>{ipi.descricao}</b>?
                                </span>
                            )
                        }
                        icon="pi pi-info-circle p-mr-3"
                        accept={deleteParametro}
                        acceptLabel="Sim"
                        acceptClassName="p-button-danger"
                        reject={esconderDeleteDialog}
                        rejectLabel="Não"
                    />
                </div>
            </div>
        </div>
    );
};
