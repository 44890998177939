import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";

import MakoListagem from "@/components/MakoListagem";
import { PageBase } from "@/components/PageBase";
import { BotaoDelete } from "@/components/BotaoDelete";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import {
    CodigoFiltroTemplate,
    EstoqueFiltroTemplate,
    MoedaFiltroTemplate,
    UnidadeMedidaFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import { QuantidadeFiltroTemplate } from "@/components/MakoFiltrosCabecalho/QuantidadeFiltroTemplate";

export const SaldoInicialSKUPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();

    const painelEsquerdo = (
        <>
            <Button
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2 p-mb-2"
                onClick={() => history.push("/estoque/movimentacoes/saldo-inicial-sku/form")}
            />
            <Button label="Filtro Avançado" icon="pi pi-filter" disabled className="p-button-help p-mr-2 p-mb-2" />
            <Button label="Importar" icon="pi pi-upload" disabled className="p-button-info p-mr-2 p-mb-2" />
        </>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.ESTOQUE_MOVIMENTACOES_SALDOINICIAL_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    onClick={() =>
                        history.push({
                            pathname: "/estoque/movimentacoes/saldo-inicial-sku/form",
                            state: { saldo: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.ESTOQUE_MOVIMENTACOES_SALDOINICIAL_EXCLUIR]}
                    componente={BotaoDelete}
                    url="/produtos/movimentacoes-estoques-sku/"
                    objetoId={rowData.id}
                    classNames="p-mb-1"
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir o saldo inicial do produto <b>{rowData.sku.descricao_derivada}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O saldo inicial não pode ser excluído."
                    tooltip="Deletar saldo inicial"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const EstoquesFiltroTemplate = (options) => {
        return <EstoqueFiltroTemplate options={options} />;
    };

    const colunas = [
        {
            field: "sku.codigo",
            header: "Cód. produto",
            style: { width: "10%" },
            filter: true,
            filterElement: CodigoFiltroTemplate,
            filterField: "sku__codigo",
        },
        {
            field: "sku.descricao_derivada",
            header: "Descrição",
            filter: true,
            filterElement: CodigoFiltroTemplate,
            filterField: "sku__descricao_reduzida",
        },
        {
            field: "centro_estocagem.nome",
            header: "Centro de Estocagem",
            style: { width: "20%" },
            filter: true,
            filterElement: EstoquesFiltroTemplate,
            filterField: "centro_estocagem"
        },
        {
            field: "unidade_medida.nome",
            header: "Unidade",
            style: { width: "10%" },
            filter: true,
            filterElement: UnidadeMedidaFiltroTemplate,
            filterField: "unidade_medida"
        },
        {
            field: "quantidade",
            header: "Quantidade",
            decimal: true,
            style: { width: "10%" },
            filter: true,
            filterElement: QuantidadeFiltroTemplate,
        },
        {
            field: "valor",
            header: "Valor",
            money: true,
            style: { width: "10%" },
            filter: true,
            filterElement: MoedaFiltroTemplate,
        },
        { field: "action", header: "Ações", action: (e) => actionBodyTemplate(e), style: { width: "10%" } },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Lançamento de saldo inicial de produtos"
                colunas={colunas}
                painelEsquerdo={painelEsquerdo}
                urlPesquisa="/produtos/movimentacoes-estoques-sku?tipo_movimentacao__classe_movimentacao=10"
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    sku__codigo: { value: null, matchMode: "equals" },
                    sku__descricao_reduzida: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    unidade_medida: { value: null, matchMode: "equals" },
                    centro_estocagem: { value: null, matchMode: "equals" },
                    quantidade: { value: null, matchMode: "equals" },
                    valor: { value: null, matchMode: "equals" },
                }}
            />
        </PageBase>
    );
};
