import React, { useMemo } from "react";

import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";

export const MakoInputPerfil = ({ query, somenteAtivo, ...props }) => {
    const [formatarDocumento] = useFormatCNPJCPF();

    const url = useMemo(() => {
        const queryString = query ? `query={${query}}&` : "";
        const filtroAtivo = somenteAtivo ? "ativo=true&" : "";
        return `/pessoas/perfis?${queryString}${filtroAtivo}search=`;
    }, [somenteAtivo, query]);

    const autoCompletePerfilTemplate = (item) => {
        if (item.tipo_pessoa === "PF" || item.tipo_pessoa === "PJ") {
            return <div>{`${item.nome} - ${formatarDocumento(item.identificacao)}`}</div>;
        }
        return <span>{`${item.nome} - ${item.identificacao}`}</span>;
    };

    return (
        <MakoAutoComplete
            urlSearch={url}
            itemTemplate={autoCompletePerfilTemplate}
            minCaracteresBusca={4}
            field="nome"
            placeholder="Comece a digitar o nome ou CPF/CNPJ para buscar... (min 3 caracteres)"
            {...props}
        />
    );
};
