import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";

import { MakoMultiTabs } from "@/components/MakoMultiTabs";

import FormDadosBasicos from "./formDadosBasicos";
import FormDadosEntrega from "./formDadosEntrega";
import FormDadosParcela from "./formDadosParcela";
import FormDadosItems from "./formDadosItems";

import useDevolucaoFornecedor from "@/hooks/useDevolucaoFornecedor";

export const TabDevolucaoFornecedor = () => {
    const { devolucao } = useDevolucaoFornecedor();
    const history = useHistory();

    const voltarParaListagem = () => {
        history.push("/compras/entrada/devolucao-fornecedor/");
    };

    const titleTemplate = useMemo(() => {
        if (!devolucao) return "Cadastro de devolução para fornecedor";
        return `Edição devolucao: ${devolucao.id}`;
    }, [devolucao]);

    const handleTabs = useMemo(() => {
        return [
            {
                header: "Dados Básicos",
                icon: "pi pi-fw pi-bookmark",
                component: <FormDadosBasicos />,
            },
            {
                header: "Itens Devolução",
                icon: "pi pi-fw pi-undo",
                component: <FormDadosItems />,
                visible: !!devolucao?.id,
            },
            {
                header: "Dados Entrega",
                icon: "fas pi-fw fa-truck",
                component: <FormDadosEntrega />,
                visible: !!devolucao?.id,
            },
            {
                header: "Dados Financeiro",
                icon: "pi pi-fw pi-money-bill",
                component: <FormDadosParcela />,
                visible: !!devolucao?.id,
            },
        ];
    }, [devolucao]);

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div>
                    <MakoMultiTabs
                        tabs={handleTabs}
                        title={titleTemplate}
                        propsButtonProx={{ disabled: false }}
                        ocultarButtonFinalizar
                        propsButtonCancelar={{ onClick: voltarParaListagem }}
                    />
                </div>
            </div>
        </div>
    );
};
