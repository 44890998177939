import React, { useCallback, useEffect, useMemo } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoDropdownCompetenciaFinanceira } from "@/components/MakoInputs/MakoDropdownCompetenciaFinanceira";
import { MakoInputPerfil } from "@/components/MakoInputs/MakoInputPerfil";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { FormikAutoSave } from "@/components/FormikAutoSave";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";
import {
    FINANCEIRO_MOVIMENTACAO_CAIXA,
    FINANCEIRO_RECEBIMENTO_TEMPLATERATEIO_PADRAO,
} from "@/assets/constants/parametros";
import useParam from "@/hooks/useParam";
import useRecebimento from "@/hooks/useRecebimento";

export const RecebimentoLancamentoForm = () => {
    const { getParam } = useParam();
    const { submit, recebimento, handleSubmitRecebimento } = useRecebimento();

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: {
            id: 0,
            empresa: null,
            devedor: null,
            conta_financeira: null,
            venda: null,
            forma_recebimento: null,
            documento: "",
            historico_padrao: null,
            valor_total: 0,
            data_emissao: null,
            data_lancamento: new Date(),
            competencia_financeira: null,
            template_rateio: null,
            origem_fracionamento: null,
            plano_orcamentario: null,
        },
        onSubmit: handleSubmit,
    });

    function handleSubmit(values, formikHelpers) {
        try {
            const formSchema = Yup.object().shape({
                empresa: Yup.number().required("O campo 'empresa' é obrigatório.").typeError("Selecione uma empresa."),
                devedor: Yup.object().required("O campo 'sacado' é obrigatório.").typeError("Selecione um sacado."),
                conta_financeira: Yup.number().nullable().notRequired().default(null),
                venda: Yup.number().nullable().notRequired().default(null),
                forma_recebimento: Yup.number().nullable().notRequired().default(null),
                documento: Yup.string()
                    .required("O campo 'documento' é obrigatório.")
                    .max(30, "O comprimento máximo para esse campo é 30 caracteres."),
                historico_padrao: Yup.object()
                    .required("O campo 'histórico' é obrigatório.")
                    .typeError("Selecione um histórico."),
                valor_total: Yup.number()
                    .required("O campo 'valor bruto' é obrigatório.")
                    .min(0.01, "O campo 'valor bruto' não pode ser ZERO."),
                data_lancamento: Yup.date()
                    .required("O campo 'data de lançamento' é obrigatório.")
                    .typeError("Informe uma data válida."),
                data_emissao: Yup.date()
                    .required("O campo 'data de emissão' é obrigatório.")
                    .max(Yup.ref("data_lancamento"), "A data de emissão não pode superior a data de lançamento.")
                    .typeError("Informe uma data válida."),
                competencia_financeira: Yup.number().nullable().notRequired().default(null),
                template_rateio: Yup.number().required("O campo 'template de rateio' é obrigatório."),
                plano_orcamentario: Yup.number().nullable().notRequired().default(null),
            });
            const dadosValidos = formSchema.validateSync(values, { abortEarly: false });
            handleSubmitRecebimento(dadosValidos);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formikHelpers.setErrors(errorMessages);
            }
        } finally {
            formikHelpers.setSubmitting(false);
        }
    }

    const urlContasFinanceira = useMemo(() => {
        const param = getParam(FINANCEIRO_MOVIMENTACAO_CAIXA);
        if (param?.valor === "1") return "/financeiro/contas-financeiras/?query={id,descricao}&tipo_conta__in=2,3,4";
        return "/financeiro/contas-financeiras/?query={id,descricao}";
    }, [getParam]);

    const definirTemplateRateioPadrao = useCallback(
        (value) => {
            setFieldValue("empresa", value.id);
            const param = getParam(FINANCEIRO_RECEBIMENTO_TEMPLATERATEIO_PADRAO, value.id);
            if (param) setFieldValue("template_rateio", param.valor);
        },
        [setFieldValue, getParam]
    );

    useEffect(() => {
        if (recebimento) setValues(recebimento);
    }, [recebimento, setValues]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-6">
                    <label htmlFor="empresa">Empresa *</label>
                    <MakoDropdownEmpresas
                        id="empresa"
                        name="empresa"
                        value={formik.values.empresa}
                        disabled={!!formik.values.id}
                        onChange={definirTemplateRateioPadrao}
                        className={classNames({ "p-invalid": formik.errors.empresa })}
                    />
                    {formik.errors.empresa && <small className="p-error">{formik.errors.empresa}</small>}
                </div>
                <div className="p-field p-col-12 p-md-6">
                    <label htmlFor="sacado">Sacado *</label>
                    <MakoInputPerfil
                        id="sacado"
                        name="devedor"
                        disabled={!!formik.values.id}
                        somenteAtivo
                        query="id,nome,identificacao,tipo_pessoa"
                        value={formik.values.devedor}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.devedor })}
                    />
                    {formik.errors.devedor && <small className="p-error">{formik.errors.devedor}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="conta-financeira">Conta financeira</label>
                    <Dropdown
                        id="conta-financeira"
                        name="conta_financeira"
                        disabled={!!formik.values.id}
                        buscar={!!formik.values.empresa}
                        url={`${urlContasFinanceira}&perfil=${formik.values.empresa}`}
                        optionValue="id"
                        optionLabel="descricao"
                        value={formik.values.conta_financeira}
                        onChange={formik.handleChange}
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="venda">Número da venda</label>
                    <InputText id="venda" disabled value={formik.values.venda} />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="forma-recebimento">Forma de recebimento</label>
                    <Dropdown
                        id="forma-recebimento"
                        name="forma_recebimento"
                        url="/financeiro/formas-recebimentos/?query={id,descricao}&ativo=true&limit=50"
                        disabled={!!formik.values.id}
                        optionValue="id"
                        optionLabel="descricao"
                        value={formik.values.forma_recebimento}
                        onChange={formik.handleChange}
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="documento">Documento *</label>
                    <InputText
                        id="documento"
                        name="documento"
                        disabled={!!formik.values.id}
                        value={formik.values.documento}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.documento })}
                    />
                    {formik.errors.documento && <small className="p-error">{formik.errors.documento}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-6">
                    <label htmlFor="historico">Histórico *</label>
                    <div className="p-inputgroup">
                        <MakoAutoComplete
                            id="historico"
                            name="historico_padrao"
                            disabled={!!formik.values.id}
                            urlSearch="/financeiro/historico-padrao/?descricao__unaccent__icontains="
                            field="descricao"
                            value={formik.values.historico_padrao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.historico_padrao })}
                        />
                        <Button
                            type="button"
                            icon="pi pi-info"
                            tooltip={recebimento?.historico_padrao.descricao || ""}
                            tooltipOptions={{ position: "top" }}
                        />
                    </div>
                    {formik.errors.historico_padrao && (
                        <small className="p-error">{formik.errors.historico_padrao}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="valor-bruto">Valor bruto *</label>
                    <MakoInputMoeda
                        id="valor-bruto"
                        name="valor_total"
                        min={0}
                        disabled={!!formik.values.id}
                        valueMoeda={formik.values.valor_total}
                        onChangeMoeda={formik.handleChange}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="data-lancamento">Data de lançamento *</label>
                    <MakoCalendar
                        id="data-lancamento"
                        name="data_lancamento"
                        disabled={!!formik.values.id}
                        maxDate={new Date()}
                        valueCalendar={formik.values.data_lancamento}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.data_lancamento })}
                    />
                    {formik.errors.data_lancamento && (
                        <small className="p-error">{formik.errors.data_lancamento}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="data-emissao">Data de emissão *</label>
                    <MakoCalendar
                        id="data-emissao"
                        name="data_emissao"
                        disabled={!!formik.values.id}
                        maxDate={new Date()}
                        valueCalendar={formik.values.data_emissao}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.data_emissao })}
                    />
                    {formik.errors.data_emissao && <small className="p-error">{formik.errors.data_emissao}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="competencia">Competência financeira</label>
                    <MakoDropdownCompetenciaFinanceira
                        id="competencia"
                        name="competencia"
                        showClear
                        disabled={!!formik.values.id}
                        value={formik.values.competencia}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.competencia })}
                    />
                    {formik.errors.competencia && <small className="p-error">{formik.errors.competencia}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="template-rateio">Template de rateio *</label>
                    <Dropdown
                        id="template-rateio"
                        name="template_rateio"
                        buscar={!!formik.values.empresa}
                        url={`/financeiro/templates-rateios/?query={id,descricao}&empresa=${formik.values.empresa}`}
                        disabled={!!formik.values.id}
                        optionValue="id"
                        optionLabel="descricao"
                        value={formik.values.template_rateio}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.template_rateio })}
                    />
                    {formik.errors.template_rateio && (
                        <small className="p-error">{formik.errors.template_rateio}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="origem-fracionamento">Origem fracionamento</label>
                    <InputText
                        id="origem-fracionamento"
                        name="origem_fracionamento"
                        disabled
                        value={formik.values.origem_fracionamento}
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="plano-orcamentario">Plano orçamentário</label>
                    <Dropdown
                        id="plano-orcamentario"
                        name="plano_orcamentario"
                        buscar={!!formik.values.empresa}
                        disabled={!!formik.values.id}
                        url={`/financeiro/planos-orcamentarios/?query={id,descricao}&perfil=${formik.values.empresa}`}
                        optionValue="id"
                        optionLabel="descricao"
                        value={formik.values.plano_orcamentario}
                        onChange={formik.handleChange}
                    />
                </div>
            </div>
            <CamposObrigatorios />
            {!submit && (
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button
                            type="submit"
                            icon="pi pi-save"
                            label="Incluir dados básicos"
                            className="p-mr-2 p-mb-2"
                        />
                    </div>
                </div>
            )}
            <FormikAutoSave intervalo={500} autosave={submit && recebimento?.id === 0} formik={formik} />
        </form>
    );
};
