import { FiscalNcmPage } from "@/pages/Fiscal/Fiscal/Ncm";
import { FiscalNcmForm } from "@/pages/Fiscal/Fiscal/Ncm/form";
import { FiscalPisPage } from "@/pages/Fiscal/Fiscal/Outros/Pis";
import { FiscalPisForm } from "@/pages/Fiscal/Fiscal/Outros/Pis/form";
import { FiscalCofinsPage } from "@/pages/Fiscal/Fiscal/Outros/Cofins";
import { FiscalCofinsForm } from "@/pages/Fiscal/Fiscal/Outros/Cofins/form";
import { FiscalIpiPage } from "@/pages/Fiscal/Fiscal/Outros/Ipi";
import { FiscalIpiForm } from "@/pages/Fiscal/Fiscal/Outros/Ipi/form";
import { FiscalIcmsPage } from "@/pages/Fiscal/Fiscal/Outros/Icms";
import { FiscalIcmsForm } from "@/pages/Fiscal/Fiscal/Outros/Icms/form";
import { FiscalCfopPage } from "@/pages/Fiscal/Fiscal/Cfop";
import { FiscalCfopForm } from "@/pages/Fiscal/Fiscal/Cfop/form";
import { FiscalMvaForm } from "@/pages/Fiscal/Fiscal/Ncm/mvaForm";
import { OperacoesFiscaisPage } from "@/pages/Fiscal/Cadastros/OperacoesFiscaisNFe";
import { TabOperacoes } from "@/pages/Fiscal/Cadastros/OperacoesFiscaisNFe/tabOperacoes";
import { TabNotaFiscalContext } from "@/pages/Fiscal/NotaSaida/EmitirNota/tabEmissao";
import { ListaNotaFiscalPage } from "@/pages/Fiscal/NotaSaida/ListarNotas";
import { InutilizacaoPage } from "@/pages/Fiscal/NotaSaida/Inutilizacao";
import { FiscalSped } from "@/pages/Fiscal/Sped";

export const fiscalRoutes = [
    { path: "/fiscal/cadastros/ncm", component: FiscalNcmPage },
    { path: "/fiscal/cadastros/ncm/form", component: FiscalNcmForm },
    { path: "/fiscal/cadastros/outros/pis", component: FiscalPisPage },
    { path: "/fiscal/cadastros/outros/pis/form", component: FiscalPisForm },
    { path: "/fiscal/cadastros/outros/cofins", component: FiscalCofinsPage },
    { path: "/fiscal/cadastros/outros/cofins/form", component: FiscalCofinsForm },
    { path: "/fiscal/cadastros/outros/ipi", component: FiscalIpiPage },
    { path: "/fiscal/cadastros/outros/ipi/form", component: FiscalIpiForm },
    { path: "/fiscal/cadastros/outros/icms", component: FiscalIcmsPage },
    { path: "/fiscal/cadastros/outros/icms/form", component: FiscalIcmsForm },
    { path: "/fiscal/cadastros/cfop", component: FiscalCfopPage },
    { path: "/fiscal/cadastros/cfop/form", component: FiscalCfopForm },
    { path: "/fiscal/cadastros/ncm/mva", component: FiscalMvaForm },
    { path: "/fiscal/gerar-sped", component: FiscalSped },
    { path: "/fiscal/nota-saida/lista-notas", component: ListaNotaFiscalPage },
    { path: "/fiscal/nota-saida/emissao-nota", component: TabNotaFiscalContext },
    { path: "/fiscal/nota-saida/inutilizacao", component: InutilizacaoPage },
    { path: "/fiscal/cadastros/operacoes-fiscais", component: OperacoesFiscaisPage },
    { path: "/fiscal/cadastros/operacoes-fiscais/form", component: TabOperacoes },
];
