import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";

import { ModalVincularPessoas } from "./ModalVincularPessoas";
import { PageBase } from "@/components/PageBase";
import { MakoListagem } from "@/components/MakoListagem";
import { MAKO_LICENCA_ADICIONAL } from "@/assets/constants/parametros";
import { parseNumber } from "@/assets/helpers/number";
import { axiosPatch, axiosDelete, axiosGet } from "@/services/http";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useClearRefs from "@/hooks/useClearRefs";
import useParam from "@/hooks/useParam";
import useToast from "@/hooks/useToast";
import { BooleanFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";

export const VinculacaoPessoasPage = () => {
    const [loading, setLoading] = useState(false);
    const [empresas, setEmpresas] = useState([]);
    const modalPessoaSecundariaRef = useRef(null);
    const [formatarDocumento] = useFormatCNPJCPF();
    const { getParam } = useParam();
    const { showSuccess, showError } = useToast();
    const listagemRef = useRef(null);

    useClearRefs(modalPessoaSecundariaRef, listagemRef);

    const totalLicencas = useMemo(() => {
        const param = getParam(MAKO_LICENCA_ADICIONAL);
        if (param) return parseNumber(param.valor);
        return 0;
    }, [getParam]);

    const licencasDisponiveis = totalLicencas - empresas;

    const buscarPessoasVinculadas = useCallback(async (signal) => {
        setLoading(true);
        const { status, data } = await axiosGet("/pessoas/grupos-perfis/", { signal });
        setLoading(false);
        if (status === 200) {
            setEmpresas(data.results.length);
        }
    }, []);

    const deletarEmpresa = async (empresaId) => {
        setLoading(true);
        const { status } = await axiosDelete(`/pessoas/grupos-perfis/${empresaId}/`);
        setLoading(false);
        if (status === 204) {
            showSuccess({
                summary: "Sucesso!",
                detail: "Empresa deletada com sucesso.",
                life: 1500,
            });
            setTimeout(() => {
                listagemRef.current?.buscarDados();
            }, 2000);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível deletar a empresa do grupo.",
                life: 3000,
            });
        }
    };

    const alterarEmpresaPrincipal = async (empresaId) => {
        const body = { principal: true };
        setLoading(true);
        const { status } = await axiosPatch(`/pessoas/grupos-perfis/${empresaId}/`, body);
        setLoading(false);
        if (status === 204) {
            showSuccess({
                summary: "Sucesso!",
                detail: "Empresa principal alterada com sucesso.",
                life: 1500,
            });
            setTimeout(() => {
                listagemRef.current?.buscarDados();
            }, 2000);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível alterar a empresa principal.",
                life: 3000,
            });
        }
    };

    const confirmarAlteracaoEmpresaPrincipal = (novaEmpresaPrincipal) => {
        confirmDialog({
            message: (
                <span>
                    Deseja tornar a empresa <b>{novaEmpresaPrincipal.nome} a principal do sistema</b>?
                </span>
            ),
            header: "Confirmação",
            icon: "pi pi-exclamation-triangle",
            accept: () => alterarEmpresaPrincipal(novaEmpresaPrincipal.id),
            acceptLabel: "Confirmar",
            rejectLabel: "Cancelar",
        });
    };

    const confirmarDelete = (empresa) => {
        confirmDialog({
            message: (
                <span>
                    Confirma a exclusão da empresa <b>{empresa.nome}</b> do grupo?
                </span>
            ),
            header: "Confirmação",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept: () => deletarEmpresa(empresa.id),
            acceptLabel: "Confirmar",
            rejectLabel: "Cancelar",
        });
    };

    const identificacaoBodyTemplate = (rowData) => {
        return <span>{formatarDocumento(rowData.perfil.identificacao)}</span>;
    };

    const hierarquiaBodyTemplate = (rowData) => {
        if (rowData.principal) return <Tag severity="success" value="PRINCIPAL" />;
        return <Tag style={{ background: "#64748B" }} value="ADICIONAL" />;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-star"
                    disabled={rowData.principal}
                    tooltip="Tornar principal"
                    onClick={() => confirmarAlteracaoEmpresaPrincipal(rowData)}
                    className="p-button-rounded p-button-help p-mr-2 p-mt-1"
                />
                <Button
                    icon="pi pi-trash"
                    disabled={rowData.principal}
                    onClick={() => confirmarDelete(rowData)}
                    className="p-button-rounded p-button-danger p-mr-2 p-mt-1"
                />
            </div>
        );
    };

    const colunas = [
        {
            field: "perfil.identificacao",
            header: "CPF / CNPJ",
            style: { width: "25%" },
            action: (e) => identificacaoBodyTemplate(e),
            filter: true,
            filterElemente: TextoFiltroTemplate,
            filterField: "perfil__identificacao",
        },
        {
            field: "perfil.nome",
            header: "Nome / Razão Social",
            filter: true,
            filterElemente: TextoFiltroTemplate,
            filterField: "perfil__perfil_pj__razao_social",
        },
        {
            field: "principal",
            header: "Participação",
            action: (e) => hierarquiaBodyTemplate(e),
            filter: true,
            filterElement: BooleanFiltroTemplate,
        },
        { field: "action", header: "Ações", style: { width: "10%" }, action: (e) => actionBodyTemplate(e) },
    ];

    const painelEsquerdoTabela = (
        <>
            {licencasDisponiveis >= 1 ? (
                <Button
                    icon="pi pi-plus"
                    label={`Vincular pessoas (${licencasDisponiveis} ${
                        licencasDisponiveis === 1 ? "licença disponível" : "licenças disponíveis"
                    })`}
                    disabled={licencasDisponiveis <= 0 || loading}
                    onClick={() => modalPessoaSecundariaRef.current?.abrirModal()}
                    className="p-button-success"
                />
            ) : (
                <span className="p-error">Número máximo de licenças atingido ou não configurado.</span>
            )}
        </>
    );

    useEffect(() => {
        const abortController = new AbortController();
        buscarPessoasVinculadas(abortController.signal);
        return () => {
            abortController.abort();
        };
    }, [buscarPessoasVinculadas]);

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Vinculação de pessoas físicas e jurídicas"
                colunas={colunas}
                painelEsquerdo={painelEsquerdoTabela}
                urlPesquisa={"/pessoas/grupos-perfis/"}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    perfil__identificacao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    perfil__perfil_pj__razao_social: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    principal: { value: null, matchMode: "equals" },
                }}
            />
            <ModalVincularPessoas
                ref={modalPessoaSecundariaRef}
                principal={empresas.length === 0}
                onGravar={() => listagemRef.current?.buscarDados()}
            />
            <ConfirmDialog />
        </PageBase>
    );
};
