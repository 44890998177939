import React, { useState } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoSelecionarEmails } from "@/components/MakoSelecionarEmails";
import { FormCustomFields } from "@/components/FormCustomFields";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";

import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import useRelatorio from "@/hooks/useRelatorio";

import { PESSOAS_PESSOAFISICA, PESSOAS_PESSOAJURIDICA } from "@/assets/constants/contentTypes";
import { ESTADO_CIVIL, SEXO, TIPO_PESSOA_PESQUISA } from "@/assets/constants/constants";
import { RELATORIO_PESSOAS_RELACAOPESSOAS } from "@/assets/constants/relatorios";
import { dataToStr } from "@/assets/util/datas";
import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";

export const RelatorioPessoasPage = () => {
    const [visible, setVisible] = useState(false);
    const [emails, setEmails] = useState([]);

    const { solicitarRelatorio } = useRelatorio();

    const filtrosTemplate = [
        {
            key: "tipo_pessoa",
            label: "Tipo pessoa",
            optionLabel: "label",
            optionKey: "id",
            options: TIPO_PESSOA_PESQUISA,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "perfil_pj__indicador_inscricao_estadual",
            label: "Indicativo IE",
            path: "nome",
        },
        {
            key: "perfil_pf__estado_civil",
            label: "Estado civil",
            optionLabel: "label",
            optionKey: "id",
            options: ESTADO_CIVIL,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "perfil_pj__regime_tributario__id",
            label: "Regime tributário",
            path: "nome",
        },
        {
            key: "nome__contains",
            label: "Nome",
        },
        {
            key: "tag",
            label: "Tag",
            path: "descricao",
        },
        {
            key: ["perfil_pf__data_nascimento__gte", "perfil_pf__data_nascimento__lte"],
            label: "Data nascimento",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
            dateFormat: "dd/MM",
        },
        {
            key: "perfil_pf__sexo",
            label: "Sexo",
            optionLabel: "label",
            optionKey: "id",
            options: SEXO,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "nome_curto__icontains",
            label: "Nome curto",
        },
        {
            key: "endereco__isnull",
            label: "Sem endereço?",
            type: TIPOS_FILTROS_TEMPLATE.BOOLEAN,
        },
        {
            key: "telefone__isnull",
            label: "Sem telefone?",
            type: TIPOS_FILTROS_TEMPLATE.BOOLEAN,
        },
        {
            key: "email__isnull",
            label: "Sem email?",
            type: TIPOS_FILTROS_TEMPLATE.BOOLEAN,
        },
        {
            key: "redesocial__isnull",
            label: "Sem rede social?",
            type: TIPOS_FILTROS_TEMPLATE.BOOLEAN,
        },
        {
            key: "tag__isnull",
            label: "Sem tag?",
            type: TIPOS_FILTROS_TEMPLATE.BOOLEAN,
        },
    ];

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            tipo_pessoa: null,
            perfil_pj__indicador_inscricao_estadual: null,
            perfil_pf__estado_civil: null,
            perfil_pj__regime_tributario__id: null,
            nome__contains: "",
            tag: null,
            perfil_pf__data_nascimento__gte: null,
            perfil_pf__data_nascimento__lte: null,
            perfil_pf__sexo: null,
            nome_curto__icontains: "",
            endereco__isnull: false,
            telefone__isnull: false,
            email__isnull: false,
            redesocial__isnull: false,
            tag__isnull: false,
            campo_personalizado: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values = null) {
        try {
            const formSchema = Yup.object().shape({});

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, dadosValidados);
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.tag) dadosValidados.tag = dadosValidados.tag.id;
            if (dadosValidados.perfil_pj__regime_tributario__id)
                dadosValidados.perfil_pj__regime_tributario__id = dadosValidados.perfil_pj__regime_tributario__id.id;
            if (dadosValidados.perfil_pj__indicador_inscricao_estadual)
                dadosValidados.perfil_pj__indicador_inscricao_estadual =
                    dadosValidados.perfil_pj__indicador_inscricao_estadual.id;

            let filtros = {};
            if (dadosValidados) {
                Object.keys(dadosValidados).forEach((key) => {
                    if (dadosValidados[key]) {
                        if (key === "perfil_pf__data_nascimento__gte")
                            return (filtros[key] = dataToStr(dadosValidados[key], "MM-dd"));

                        if (key === "perfil_pf__data_nascimento__lte")
                            return (filtros[key] = dataToStr(dadosValidados[key], "MM-dd"));

                        if (key === "campo_personalizado") {
                            filtros[key] = [];

                            return (filtros[key] = dadosValidados[key].map((item) => ({
                                id: item.value.field,
                                value: item.value.value,
                            })));
                        }

                        return (filtros[key] = dadosValidados[key]);
                    }
                });
            }

            solicitarRelatorio({
                chave: RELATORIO_PESSOAS_RELACAOPESSOAS,
                emails,
                filtros,
                filtros_template: filtrosTemplateAplicados,
            });
            formik.resetForm();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const handleConfirm = (emails) => {
        fecharModal();
        if (emails && emails.length > 0) {
            handlePreSubmit(emails);
        }
    };

    const handlePreSubmit = (emails) => {
        setEmails(emails);
        formik.handleSubmit();
    };

    const limparFiltro = () => {
        formik.resetForm();
    };

    const fecharModal = () => {
        setVisible(() => false);
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <form onSubmit={formik.handleSubmit}>
                    <div className="card">
                        <h5>Filtro para emissão de relatório de relação de pessoas:</h5>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="nome__contains">Contém no nome</label>
                                <InputText
                                    id="nome__contains"
                                    name="nome__contains"
                                    keyfilter={/^[a-zA-Z ]/}
                                    value={formik.values.nome__contains}
                                    onInput={formik.handleChange}
                                    autoComplete="off"
                                />
                                {formik.errors.nome__contains && (
                                    <small className="p-error">{formik.errors.nome__contains}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="tipo_pessoa">Tipo de pessoa</label>
                                <Dropdown
                                    id="tipo_pessoa"
                                    name="tipo_pessoa"
                                    placeholder="Qualquer"
                                    showClear
                                    options={TIPO_PESSOA_PESQUISA}
                                    optionValue="id"
                                    optionLabel="label"
                                    value={formik.values.tipo_pessoa}
                                    onChange={formik.handleChange}
                                />
                                {formik.errors.tipo_pessoa && (
                                    <small className="p-error">{formik.errors.tipo_pessoa}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="tag">Contém a TAG</label>
                                <Dropdown
                                    id="tag"
                                    name="tag"
                                    placeholder="Selecione uma tag"
                                    url={"/pessoas/tags-perfis?limit=300"}
                                    showClear
                                    filter
                                    filterBy="descricao"
                                    optionLabel="descricao"
                                    disabled={formik.values.tag__isnull}
                                    value={formik.values.tag}
                                    onChange={formik.handleChange}
                                />
                                {formik.errors.tag && <small className="p-error">{formik.errors.tag}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="perfil_pf__estado_civil">Estado civil</label>
                                <Dropdown
                                    id="perfil_pf__estado_civil"
                                    name="perfil_pf__estado_civil"
                                    placeholder="Qualquer"
                                    options={ESTADO_CIVIL}
                                    optionValue="id"
                                    optionLabel="label"
                                    disabled={formik.values.tipo_pessoa === "PJ"}
                                    value={formik.values.perfil_pf__estado_civil}
                                    onChange={formik.handleChange}
                                />
                                {formik.errors.perfil_pf__estado_civil && (
                                    <small className="p-error">{formik.errors.perfil_pf__estado_civil}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="perfil_pj__indicador_inscricao_estadual">Indicativo IE</label>
                                <Dropdown
                                    id="perfil_pj__indicador_inscricao_estadual"
                                    name="perfil_pj__indicador_inscricao_estadual"
                                    placeholder="Qualquer"
                                    url={"/pessoas/indicadores-inscricao-estadual/"}
                                    optionLabel="descricao"
                                    disabled={formik.values.tipo_pessoa !== "PJ"}
                                    value={formik.values.perfil_pj__indicador_inscricao_estadual}
                                    onChange={formik.handleChange}
                                />
                                {formik.errors.perfil_pj__indicador_inscricao_estadual && (
                                    <small className="p-error">
                                        {formik.errors.perfil_pj__indicador_inscricao_estadual}
                                    </small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="perfil_pj__regime_tributario__id">Regime tributário</label>
                                <Dropdown
                                    id="perfil_pj__regime_tributario__id"
                                    name="perfil_pj__regime_tributario__id"
                                    placeholder="Qualquer"
                                    url={"/pessoas/regime-tributario/"}
                                    optionLabel="descricao"
                                    disabled={formik.values.tipo_pessoa !== "PJ"}
                                    value={formik.values.perfil_pj__regime_tributario__id}
                                    onChange={formik.handleChange}
                                />
                                {formik.errors.perfil_pj__regime_tributario__id && (
                                    <small className="p-error">{formik.errors.perfil_pj__regime_tributario__id}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="perfil_pf__data_nascimento__gte">Dia/mês nasc. inicial</label>
                                <MakoCalendar
                                    id="perfil_pf__data_nascimento__gte"
                                    name="perfil_pf__data_nascimento__gte"
                                    dateFormat="dd/mm"
                                    mask="99/99"
                                    placeholder="dd/mm"
                                    valueCalendar={formik.values.perfil_pf__data_nascimento__gte}
                                    onChange={formik.handleChange}
                                />
                                {formik.errors.perfil_pf__data_nascimento__gte && (
                                    <small className="p-error">{formik.errors.perfil_pf__data_nascimento__gte}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="data_inicio">Dia/mês nasc. final</label>
                                <MakoCalendar
                                    id="perfil_pf__data_nascimento__lte"
                                    name="perfil_pf__data_nascimento__lte"
                                    dateFormat="dd/mm"
                                    mask="99/99"
                                    placeholder="dd/mm"
                                    valueCalendar={formik.values.perfil_pf__data_nascimento__lte}
                                    onChange={formik.handleChange}
                                />
                                {formik.errors.perfil_pf__data_nascimento__lte && (
                                    <small className="p-error">{formik.errors.perfil_pf__data_nascimento__lte}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="perfil_pf__sexo">Sexo</label>
                                <Dropdown
                                    id="perfil_pf__sexo"
                                    name="perfil_pf__sexo"
                                    options={SEXO}
                                    optionValue="id"
                                    optionLabel="label"
                                    placeholder="Qualquer"
                                    disabled={formik.values.tipo_pessoa !== "PF"}
                                    value={formik.values.perfil_pf__sexo}
                                    onChange={formik.handleChange}
                                />
                                {formik.errors.perfil_pf__sexo && (
                                    <small className="p-error">{formik.errors.perfil_pf__sexo}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="nome_curto__icontains">Contém no nome curto</label>
                                <InputText
                                    id="nome_curto__icontains"
                                    name="nome_curto__icontains"
                                    keyfilter={/^[a-zA-Z ]/}
                                    value={formik.values.nome_curto__icontains}
                                    onInput={formik.handleChange}
                                    autoComplete="off"
                                />
                                {formik.errors.nome_curto__icontains && (
                                    <small className="p-error">{formik.errors.nome_curto__icontains}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field-checkbox p-col-12 p-md-2 p-mt-2">
                                <Checkbox
                                    id="endereco__isnull"
                                    name="endereco__isnull"
                                    checked={formik.values.endereco__isnull}
                                    onChange={formik.handleChange}
                                />
                                <label htmlFor="endereco__isnull">Sem endereço?</label>
                            </div>
                            <div className="p-field-checkbox p-col-12 p-md-2 p-mt-2">
                                <Checkbox
                                    id="telefone__isnull"
                                    name="telefone__isnull"
                                    checked={formik.values.telefone__isnull}
                                    onChange={formik.handleChange}
                                />
                                <label htmlFor="telefone__isnull">Sem telefone?</label>
                            </div>
                            <div className="p-field-checkbox p-col-12 p-md-2 p-mt-2">
                                <Checkbox
                                    id="email__isnull"
                                    name="email__isnull"
                                    checked={formik.values.email__isnull}
                                    onChange={formik.handleChange}
                                />
                                <label htmlFor="email__isnull">Sem email?</label>
                            </div>
                            <div className="p-field-checkbox p-col-12 p-md-2 p-mt-2">
                                <Checkbox
                                    id="redesocial__isnull"
                                    name="redesocial__isnull"
                                    checked={formik.values.redesocial__isnull}
                                    onChange={formik.handleChange}
                                />
                                <label htmlFor="redesocial__isnull">Sem rede social?</label>
                            </div>
                            <div className="p-field-checkbox p-col-12 p-md-2 p-mt-2">
                                <Checkbox
                                    id="tag__isnull"
                                    name="tag__isnull"
                                    checked={formik.values.tag__isnull}
                                    onChange={formik.handleChange}
                                />
                                <label htmlFor="tag__isnull">Sem tag?</label>
                            </div>
                        </div>
                        {/* <div className="p-fluid p-formgrid p-grid"> */}
                        <div className="p-mt-2">
                            <h5 htmlFor="campo_personalizado">
                                <b>Campos Personalizados</b>
                            </h5>
                            {formik.values.tipo_pessoa !== null && (
                                <FormCustomFields
                                    localValues={formik.values.campo_personalizado}
                                    contentType={
                                        formik.values?.tipo_pessoa === "PF"
                                            ? PESSOAS_PESSOAFISICA
                                            : formik.values?.tipo_pessoa === "PJ"
                                            ? PESSOAS_PESSOAJURIDICA
                                            : null
                                    }
                                    instance={null}
                                    onSubmit={(e) => setFieldValue("campo_personalizado", e)}
                                />
                            )}
                        </div>
                        {/* </div> */}
                        <div className="p-grid p-col-12 p-md-6 p-mt-2">
                            <Button
                                label="Gerar PDF"
                                icon="pi pi-file-pdf"
                                type="button"
                                onClick={() => handlePreSubmit()}
                                className="p-button-info p-mr-2"
                            />
                            <Button
                                type="button"
                                onClick={() => setVisible(true)}
                                label="Enviar por email"
                                icon="pi pi-envelope"
                                className="p-button-info p-mr-2"
                            />
                            <Button
                                type="reset"
                                icon="pi pi-trash"
                                label="Limpar"
                                onClick={() => limparFiltro()}
                                className="p-button-warning p-mr-2"
                            />
                        </div>
                    </div>
                </form>
            </div>
            <Dialog
                visible={visible}
                blockScroll
                onHide={fecharModal}
                style={{ width: "60vw" }}
                header="Informar emails"
            >
                <MakoSelecionarEmails onCancel={fecharModal} onConfirm={handleConfirm} />
            </Dialog>
        </div>
    );
};
