import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { Dropdown } from "primereact/dropdown";

import { PageBase } from "@/components/PageBase";
import MakoListagem from "@/components/MakoListagem";
import { BotaoDelete } from "@/components/BotaoDelete";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";

export const CamposPersonalizadosPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.OUTROS_CAMPOSPERSONALIZADOS_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar campo personalizado"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/configuracoes/campos-personalizados/form",
                            state: { campos: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.OUTROS_CAMPOSPERSONALIZADOS_EXCLUIR]}
                    componente={BotaoDelete}
                    url="/custom-fields/fields"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir o campo personalizado <b>{rowData.label}</b>?
                        </span>
                    }
                    classNames="p-mb-1"
                    tooltip="Deletar campo personalizado"
                    tooltipOptions={{ position: "left" }}
                    msgToastErroExclusao="O campo personalizado não pode ser excluído."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.OUTROS_CAMPOSPERSONALIZADOS_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/configuracoes/campos-personalizados/form")}
            />
        </>
    );

    const STATUS_PERFIL = [
        { value: true, label: "Obrigatório" },
        { value: false, label: "Opcional" },
    ];

    const requerBodyTemplate = (rowData) => {
        if (rowData.is_required) return <Tag severity="success" value="OBRIGATÓRIO" />;
        return <Tag severity="danger" value="OPCIONAL" />;
    };

    const requerFiltroTemplate = (options) => {
        return (
            <Dropdown
                placeholder="Selecione"
                showClear
                value={options.value}
                options={STATUS_PERFIL}
                onChange={(e) => options.filterCallback(e.value)}
            />
        );
    };

    const formataTipoCampo = (tipo) => {
        if (tipo === "t") return "Texto";
        else if (tipo === "a") return "Campo de texto grande";
        else if (tipo === "i") return "Inteiro";
        else if (tipo === "f") return "Decimal";
        else if (tipo === "b") return "Booleano";
        else if (tipo === "m") return "Opções fixas";
        else if (tipo === "d") return "Data";
        else if (tipo === "h") return "Data e tempo";
    };

    const colunas = [
        { field: "id", header: "Código", style: { minWidth: "90px" }, filter: true },
        { field: "name", header: "Nome", style: { minWidth: "250px" }, filter: true },
        { field: "label", header: "Label", style: { minWidth: "250px" }, filter: true },
        {
            field: "is_required",
            header: "Campo obrigatório",
            style: { minWidth: "200px" },
            filter: true,
            filterElement: requerFiltroTemplate,
            action: (e) => requerBodyTemplate(e),
        },
        {
            field: "field_type",
            header: "Tipo de campo",
            action: (e) => formataTipoCampo(e.field_type),
            style: { minWidth: "150px" },
        },
        { field: "default_value", header: "Valor padrão", style: { minWidth: "150px" } },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "120px" },
            frozen: true,
            alignFrozen: "right",
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Campos Personalizados"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={`/custom-fields/fields/`}
                responsiva
                filtros={{
                    id: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "equals" }],
                    },
                    name: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    label: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    is_required: { value: null, matchMode: "equals" },
                }}
                configTabela={{
                    paginator: true,
                    lazy: true,
                    filterDisplay: "menu",
                    scrollable: true,
                    frozenWidth: "0.5vw",
                    flexGrow: 1,
                    flexBasis: "12rem",
                }}
            />
        </PageBase>
    );
};
