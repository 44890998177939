import React, { useState, useRef, useEffect, useCallback } from "react";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { axiosDelete, axiosGet } from "@/services/http";
import { MakoListagemHierarquica } from "@/components/MakoListagemHierarquica";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import permissoes from "@/assets/constants/permissoes";
import useLoading from "@/hooks/useLoading";
import { dataToStr } from "@/assets/util/datas";

const url = "/produtos/categorias-menu?limit=100000";

export const CategoriasPage = () => {
    const [redirecionar, setRedirecionar] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [categoriaSelecionada, setCategoriaSelecionada] = useState(null);
    const [categoria, setCategoria] = useState(null);
    const [dados, setDados] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const listagemRef = useRef(null);

    const handleDados = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet(url);
        hideLoading();

        if (resposta.status === 200) {
            setDados(resposta.data);
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Mako",
                detail: "Não conseguimos processar a sua requisição.",
                life: 3000,
            });
        }
    }, [showLoading, hideLoading]);

    const confirmarDelete = (categoria) => {
        setCategoria(categoria);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const deletarCategoria = async () => {
        showLoading();
        const resposta = await axiosDelete(`/produtos/categorias/${categoria.id}/`);
        hideLoading();

        if (resposta.status === 204) {
            toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Categoria deletada com sucesso!",
                life: 1500,
            });

            setTimeout(() => {
                listagemRef.current?.buscarDados();
            }, 1500);
        } else {
            const message =
                typeof resposta.data === "string" && resposta.data.includes("protected foreign keys")
                    ? "Você não pode exluir está categoria, ela esta sendo utilizada por outros recursos do sistema."
                    : "Desculpe, não conseguimos processar a sua requisição";

            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: message,
                life: 3000,
            });
        }

        setDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_CATEGORIAS_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar Cadastro de categoria"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        setRedirecionar({
                            pathname: "/produto/produto/categorias/form",
                            state: { categoria: rowData.data },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_CATEGORIAS_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData.data)}
                    tooltip="Deletar categoria"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.PRODUTO_PRODUTO_CATEGORIAS_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() =>
                    setRedirecionar({ pathname: "/produto/produto/categorias/form", categoria: categoriaSelecionada })
                }
            />
        </>
    );

    const rowClass = (node) => {
        return {
            "p-text-bold": node.data.tipo === "T",
        };
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "120px" }, filter: true, expander: true },
        { field: "nome", header: "Nome", filter: true, style: { width: "220px" } },
        { field: "grupo.descricao", header: "Grupo", filter: true, style: { width: "220px" } },
        {
            field: "vigencia_inicial",
            header: "Vigência Inicial",
            style: { width: "140px" },
            filter: true,
            action: (e) => dataToStr(e.data.vigencia_inicial, "dd/MM/yyyy"),
        },
        {
            field: "vigencia_final",
            header: "Vigência Final",
            style: { width: "120px" },
            filter: true,
            action: (e) => dataToStr(e.data.vigencia_final, "dd/MM/yyyy"),
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "100px" },
        },
    ];

    useEffect(() => {
        handleDados();
    }, [handleDados]);

    return redirecionar ? (
        <Redirect to={redirecionar} />
    ) : (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    {dados ? (
                        <MakoListagemHierarquica
                            dados={dados}
                            titulo="Categorias"
                            colunas={colunas}
                            cabecalho={cabecalhoTabela}
                            mostrarFiltroGlobal
                            responsiva
                            tabelaConfig={{
                                selectionMode: "single",
                                selectionKeys: categoriaSelecionada,
                                selection: categoriaSelecionada,
                                onSelectionChange: (e) => setCategoriaSelecionada(e.value),
                                rowClassName: rowClass,
                                scrollable: true,
                            }}
                        />
                    ) : null}
                </div>
            </div>
            <ConfirmDialog
                visible={deleteDialog}
                onHide={esconderDeleteDialog}
                header="Confirmação"
                message={
                    categoria && (
                        <span>
                            Deseja mesmo deletar a categoria <b>{categoria.descricao}</b>?
                        </span>
                    )
                }
                icon="pi pi-info-circle p-mr-3"
                accept={deletarCategoria}
                acceptLabel="Sim"
                acceptClassName="p-button-danger"
                reject={esconderDeleteDialog}
                rejectLabel="Não"
            />
        </div>
    );
};
