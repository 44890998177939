import React, { useRef } from "react";

import { useHistory } from "react-router-dom";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { BotaoDelete } from "@/components/BotaoDelete";
import { PageBase } from "@/components/PageBase";
import MakoListagem from "@/components/MakoListagem";

import { Button } from "primereact/button";
import { Tag } from "primereact/tag";

import { TIPO_CARGO_CHOICE } from "@/assets/constants/constants";
import permissoes from "@/assets/constants/permissoes";
import useClearRefs from "@/hooks/useClearRefs";
import { AtivoFiltroTemplate, CodigoFiltroTemplate, DropdownFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";

const url = "/pessoas/cargos/";

export const CargosPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();

    useClearRefs(listagemRef);

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_CADASTRO_CARGOS_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar Cargo"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/gestao/cadastros/cargos/form",
                            state: { tag: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_CADASTRO_CARGOS_EXCLUIR]}
                    componente={BotaoDelete}
                    url={url}
                    objetoId={rowData.id}
                    classNames="p-mb-1"
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir o cargo <b>{rowData.nome}</b>?
                        </span>
                    }
                    msgToastErroExclusao="o cargo não pode ser excluída."
                    tooltip="Deletar cargo"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.GESTAO_CADASTRO_CARGOS_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/gestao/cadastros/cargos/form")}
            />
        </>
    );

    const statusBodyTemplate = (rowData) => {
        if (rowData) {
            return <Tag severity="success" value="ATIVO" />;
        }

        return <Tag severity="danger" value="DESATIVADO" />;
    };

    const NivelFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate 
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: TIPO_CARGO_CHOICE
                }}
                options={options}
            />
        )
    }

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { width: "8%" },
            filter: true,
            filterElement: CodigoFiltroTemplate
        },
        { 
            field: "nome", 
            header: "Nome",
            filter: true,
            filterElement: TextoFiltroTemplate 
        },
        { 
            field: "nivel", 
            header: "Nível", 
            action: ({ nivel }) => {
                const _nivel = TIPO_CARGO_CHOICE.find(item => item.value === nivel);
                if (_nivel) return _nivel.label;
                return nivel;
            },
            filter: true,
            filterElement: NivelFiltroTemplate
        },
        {
            field: "ativo",
            header: "Ativo",
            style: { width: "10%" },
            action: ({ ativo }) => statusBodyTemplate(ativo),
            filter: true,
            filterElement: AtivoFiltroTemplate
        },
        {
            field: "action",
            header: "Ações",
            style: { width: "15%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Cargos"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    nome: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    nivel: { value: null, matchMode: "equals" },
                    ativo: { value: null, matchMode: "equals" }
                }}
            />
        </PageBase>
    );
};
