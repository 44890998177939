import React, { useRef } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import * as Yup from "yup";

import { axiosPost, axiosPut } from "@/services/http";
import useLoading from "@/hooks/useLoading";

const url = "/produtos/atributos/";
const urlvoltar = "/produto/cadastros/atributos";

export const ProdutoAtributosForm = (props) => {
    const history = useHistory();
    const toastRef = useRef(null);
    const { showLoading, hideLoading } = useLoading();

    const formik = useFormik({
        initialValues: props.location.state
            ? props.location.state.atributo
            : {
                  codigo: "",
                  nome: "",
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                codigo: Yup.string()
                    .min(2, "O código precisa ter no mínimo 2 dígitos.")
                    .required("O campo 'código' é obrigatório.")
                    .typeError("Informe um código válido."),
                nome: Yup.string().required("O campo 'nome' é obrigatório."),
            });

            await formSchema.validate(values, { abortEarly: false });

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(`${url}`, values);
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Atributo cadastrado com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(`${url}${values.id}/`, values);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Atributo alterado com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <h3>{!formik.values.id ? "Novo Atributo" : "Manutenção de Atributo"}</h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="codigo">Código *</label>
                                <InputText
                                    id="codigo"
                                    name="codigo"
                                    value={formik.values.codigo}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.codigo })}
                                    tooltip="Código do atributo Exemplo: Código 'T' do Atributo 'Tamanho'."
                                    autoComplete="off"
                                    autoFocus
                                />
                                {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="nome">Nome *</label>
                                <InputText
                                    id="nome"
                                    name="nome"
                                    value={formik.values.nome}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.nome })}
                                    tooltip="Nome que será dado ao atributo."
                                    autoComplete="off"
                                />
                                {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                            </div>
                        </div>
                        <p>
                            <b>* Campos obrigatórios.</b>
                        </p>

                        <div className="p-grid p-col-12 p-md-6">
                            <Button label="Gravar" type="submit" className="p-button-info p-mr-2" />
                            <Button label="Cancelar" type="reset" className="p-button-danger" onClick={cancelar} />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
