import React, { useState, useRef, useCallback, useEffect } from "react";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { axiosGet, axiosPatch } from "@/services/http";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";
import { Menu } from "primereact/menu";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "@/components/Dropdown";
import { ClienteFiltroTemplate, CodigoFiltroTemplate, DateFiltroTemplate, DropdownFiltroTemplate } from "@/components/MakoFiltrosCabecalho";

export const VendaTrocaPage = () => {
    const [redirecionar, setRedirecionar] = useState(null);
    const [cliente, setCliente] = useState(null);
    const [listaOpFiscal, setListaOpFiscal] = useState([]);
    const [operacaoFiscal, setOperacaoFiscal] = useState(null);
    const [validarDialog, setValidarDialog] = useState(false);
    const [cancelarDialog, setCancelarDialog] = useState(false);
    const [devolverDialog, setDevolverDialog] = useState(false);
    const [vendaDialog, setVendaDialog] = useState(false);
    const [cadastraNotaDialog, setCadastraNotaDialog] = useState(false);
    const [exibeForm, setExibeForm] = useState(false);
    const [troca, setTroca] = useState(null);
    const [status, setStatus] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const menu = useRef(null);
    const toastRef = useRef(null);
    const listagemRef = useRef(null);

    const botoesTroca = [
        {
            label: "Ações",
            items: [
                {
                    label: "Validar",
                    icon: "pi pi-check",
                    disabled: troca?.status.id === "V" || troca?.status.id === "F" || troca?.status.id === "C",
                    command: () => {
                        setValidarDialog(true);
                        setStatus("V");
                    },
                },
                {
                    label: "Emitir devolução",
                    icon: "pi pi-sync",
                    disabled: troca?.status.id === "P" || troca?.status.id === "F" || troca?.status.id === "C",
                    command: () => {
                        setDevolverDialog(true);
                    },
                },
                {
                    label: "Gerar troca",
                    icon: "pi pi-ticket",
                    disabled: troca?.status.id === "P" || troca?.status.id === "F" || troca?.status.id === "C",
                    // || !troca?.nota_fiscal,
                    command: () => {
                        setVendaDialog(true);
                    },
                },
                {
                    label: "Cancelar",
                    icon: "pi pi-times",
                    disabled: troca?.status.id === "C" || troca?.status.id === "F",
                    command: () => {
                        setCancelarDialog(true);
                        setStatus("C");
                    },
                },
            ],
        },
    ];

    const esconderValidarDialog = () => {
        setValidarDialog(false);
        setTroca(null);
    };

    const esconderCancelarDialog = () => {
        setCancelarDialog(false);
        setTroca(null);
    };

    const esconderDevolverDialog = () => {
        setDevolverDialog(false);
        setOperacaoFiscal(null);
        setTroca(null);
    };

    const esconderCadastraDialog = () => {
        setCadastraNotaDialog(false);
    };

    const esconderVendaDialog = () => {
        setVendaDialog(false);
        setTroca(null);
    };

    const editarStatus = async () => {
        showLoading();
        const resposta = await axiosPatch(`/vendas/troca/${troca.id}/`, {
            status: status,
        });
        hideLoading();

        if (resposta.status === 200) {
            toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Troca validada com sucesso!",
                life: 3000,
            });

            listagemRef.current?.buscarDados();
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não foi possível processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
        setValidarDialog(false);
    };

    const cadastraDevolucao = async () => {
        setRedirecionar({
            pathname: "/fiscal/nota-saida/emissao-nota",
            state: {
                itensVenda: troca.itemtrocadevolucao_set || [],
                operacao: operacaoFiscal,
                cfop: "5202",
                devolucao_troca: troca.id,
                requer_info: true,
                modelo: "55",
                venda: {
                    empresa: troca.empresa,
                    cliente: troca.cliente,
                },
            },
        });
    };

    const cadastraVenda = async () => {
        setRedirecionar({
            pathname: "/vendas/venda/form",
            state: { troca },
        });
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_TROCA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar informações da troca"
                    tooltipOptions={{ position: "left" }}
                    disabled={rowData.status?.id === "C"}
                    onClick={() =>
                        setRedirecionar({
                            pathname: "/vendas/venda/trocas/form",
                            state: { troca: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_TROCA_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-cog"
                    className="p-button-rounded p-button-info p-mr-2 p-mb-1"
                    model={botoesTroca}
                    tooltip="Operações da troca"
                    tooltipOptions={{ position: "left" }}
                    disabled={rowData.status?.id === "C"}
                    onClick={(event) => {
                        menu.current.toggle(event);
                        setTroca(rowData);
                    }}
                />
            </div>
        );
    };

    const listarOperacoesFiscais = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/fiscal/operacoes-fiscais/");
        hideLoading();

        if (resposta.status === 200) {
            setListaOpFiscal(resposta.data.results);
        }
    }, [showLoading, hideLoading]);

    const autoCompleteTemplate = (cliente) => {
        cliente.label = `${cliente.nome} - ${cliente.identificacao}`;
        return <div>{`${cliente.nome} - ${cliente.identificacao}`}</div>;
    };

    const rowClass = (troca) => {
        return {
            "mako-table-overdue": troca.status?.id === "C",
        };
    };

    const StatusFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: [
                        { value: "P", label: "Pendente" },
                        { value: "v", label: "Validada" },
                        { value: "F", label: "Finalizada" },
                        { value: "C", label: "Cancelada" },
                    ],
                }}
                options={options}
            />
        );
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "12%" }, filter: true, filterElement: CodigoFiltroTemplate },
        {
            field: "cliente.nome",
            header: "Cliente",
            filter: true,
            filterElement: ClienteFiltroTemplate,
            filterField: "cliente",
        },
        {
            field: "data_venda",
            header: "Data",
            style: { width: "15%" },
            filter: true,
            filterElement: DateFiltroTemplate,
        },
        {
            field: "status.descricao",
            header: "Status",
            style: { width: "15%" },
            filter: true,
            filterElement: StatusFiltroTemplate,
            filterField: "status",
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "15%" },
        },
    ];

    useEffect(() => {
        listarOperacoesFiscais();
    }, [listarOperacoesFiscais]);

    return redirecionar ? (
        <Redirect to={redirecionar} />
    ) : (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <Menu model={botoesTroca} popup ref={menu} id="popup_menu" />
            <div className="p-col-12">
                <h5>Trocas</h5>
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_TROCA_INCLUIR]}
                    componente={Button}
                    icon="pi pi-sync"
                    label="Realizar Troca"
                    className="p-button-info p-mr-2"
                    type="button"
                    onClick={() => setExibeForm(!exibeForm)}
                />
                <div className="p-grid">
                    <div className="p-col-12">
                        {exibeForm ? (
                            <div className="card">
                                <div className="p-fluid p-formgrid p-grid">
                                    <div className="p-field p-col-12 p-md-12">
                                        <label>Selecione um cliente: </label>
                                        <div className="p-inputgroup">
                                            <MakoAutoComplete
                                                id="cliente"
                                                name="cliente"
                                                value={cliente}
                                                onChange={(e) => setCliente(e.target.value)}
                                                minCaracteresBusca={1}
                                                itemTemplate={autoCompleteTemplate}
                                                field="label"
                                                urlSearch={`/pessoas/perfis?search=`}
                                                placeholder="Comece a digitar informações do cliente para buscar...)"
                                            />
                                            <Button
                                                icon="pi pi-plus"
                                                label="Incluir troca"
                                                type="button"
                                                className="p-button-success p-ml-1"
                                                disabled={typeof cliente !== "object"}
                                                onClick={() =>
                                                    setRedirecionar({
                                                        pathname: "/vendas/venda/trocas/form",
                                                        state: { cliente: cliente },
                                                    })
                                                }
                                            />
                                            <Button
                                                icon="pi pi-trash"
                                                label="Limpar"
                                                type="reset"
                                                className="p-button-warning p-ml-1"
                                                onClick={() => {
                                                    setCliente(null);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        colunas={colunas}
                        urlPesquisa={"/vendas/troca/"}
                        configTabela={{
                            paginator: true,
                            lazy: true,
                            rowClassName: rowClass,
                        }}
                        filtros={{
                            id: { value: null, matchMode: "equals" },
                            cliente: { value: null, matchMode: "equals" },
                            data_venda: { value: null, matchMode: "equals" },
                            status: { value: null, matchMode: "equals" },
                        }}
                    />
                    <ConfirmDialog
                        visible={validarDialog}
                        onHide={esconderValidarDialog}
                        header="Confirmação"
                        message={
                            troca && (
                                <span>
                                    {"Deseja realmente validar a troca número "}
                                    <b>{troca.id}</b>?
                                </span>
                            )
                        }
                        icon="pi pi-info-circle p-mr-3"
                        accept={editarStatus}
                        acceptLabel="Sim"
                        acceptClassName="p-button-success"
                        reject={esconderValidarDialog}
                        rejectLabel="Não"
                    />
                    <ConfirmDialog
                        visible={cancelarDialog}
                        onHide={esconderCancelarDialog}
                        header="Confirmação"
                        message={
                            troca && (
                                <span>
                                    {"Deseja realmente cancelar a troca número "}
                                    <b>{troca.id}</b>?
                                </span>
                            )
                        }
                        icon="pi pi-info-circle p-mr-3"
                        accept={editarStatus}
                        acceptLabel="Sim"
                        acceptClassName="p-button-danger"
                        reject={esconderCancelarDialog}
                        rejectLabel="Não"
                    />
                    <ConfirmDialog
                        visible={cadastraNotaDialog}
                        onHide={esconderCadastraDialog}
                        header="Confirmação"
                        message={
                            troca && (
                                <span>
                                    {"Deseja realmente cadastrar uma nota de devolução para a troca número "}
                                    <b>{troca.id}</b>?
                                </span>
                            )
                        }
                        icon="pi pi-info-circle p-mr-3"
                        accept={cadastraDevolucao}
                        acceptLabel="Sim"
                        acceptClassName="p-button-success"
                        reject={esconderCadastraDialog}
                        rejectLabel="Não"
                    />
                    <ConfirmDialog
                        visible={vendaDialog}
                        onHide={esconderVendaDialog}
                        header="Confirmação"
                        message={
                            troca && (
                                <span>
                                    {"Deseja realmente gerar uma venda para a troca número "}
                                    <b>{troca.id}</b>?
                                </span>
                            )
                        }
                        icon="pi pi-info-circle p-mr-3"
                        accept={cadastraVenda}
                        acceptLabel="Sim"
                        acceptClassName="p-button-success"
                        reject={esconderVendaDialog}
                        rejectLabel="Não"
                    />
                </div>
                <Dialog
                    header={"Selecione uma operação fiscal"}
                    visible={devolverDialog}
                    breakpoints={{ "960px": "75vw" }}
                    style={{ width: "60w", display: "block" }}
                    onHide={() => esconderDevolverDialog()}
                >
                    <form>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-12">
                                <Dropdown
                                    id="operacao_fiscal"
                                    name="operacao_fiscal"
                                    placeholder="Selecione..."
                                    optionValue="id"
                                    optionLabel="descricao"
                                    options={listaOpFiscal}
                                    value={operacaoFiscal}
                                    onChange={(e) => setOperacaoFiscal(e.value)}
                                />
                            </div>
                        </div>
                        <div className="p-grid p-justify-center p-mt-2">
                            <Button
                                icon="pi pi-times"
                                className="p-button-danger p-mr-2"
                                type="button"
                                label="Cancelar"
                                onClick={() => esconderDevolverDialog()}
                            />
                            <Button
                                icon="pi pi-save"
                                type="button"
                                label="Cadastrar"
                                className="p-button-success"
                                disabled={operacaoFiscal ? false : true}
                                onClick={() => setCadastraNotaDialog(true)}
                            />
                        </div>
                    </form>
                </Dialog>
            </div>
        </div>
    );
};
