import React from "react";

import { VendaProvider } from "@/contexts/vendaContext";
import { TabVendas } from "./tabs";

export const TabVendasContext = (props) => {
    const idVenda = props.location.state?.idVenda;
    const troca = props.location.state?.troca;

    return (
        <VendaProvider>
            <TabVendas vendaId={idVenda} trocaId={troca?.id} />
        </VendaProvider>
    );
};
