import React, { useCallback, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { SelectButton } from "primereact/selectbutton";
import { axiosGet, axiosPost, axiosPut } from "@/services/http";
import { PickList } from "primereact/picklist";
import { useFormik } from "formik";
import useLoading from "@/hooks/useLoading";
import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";

export const ItemTabelaModalForm = ({
    tabela,
    acrescimo,
    toastRef,
    listagemRef,
    setExibirDialog,
    exibirDialog = false,
    vigencia,
}) => {
    const [produtos, setProdutos] = useState([]);
    const [opcaoExibicao, setOpcaoExibicao] = useState("");
    const [produtosSelecionados, setProdutosSelecionados] = useState([]);

    const { showLoading, hideLoading } = useLoading();

    const opcoesPesquisa = ["Categoria", "Pesquisa avançada", "Todos"];

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            categoria_selecionada: null,
            categoria: null,
            sku: null,
        },
        onSubmit: handleSubmit,
    });

    async function arredondaValores() {
        let lista = [];
        produtosSelecionados.forEach((item) => {
            lista.push(
                item.precosku_set?.length > 0 ? parseFloat(item.precosku_set[0].preco_base) * (acrescimo / 100 + 1) : 0
            );
        });
        const resposta = await axiosPut("/vendas/arredondar-precos/", {
            precos: lista,
        });
        return resposta.status === 200 ? lista : [];
    }

    async function handleSubmit() {
        try {
            const valorArredondado = await arredondaValores();

            if (valorArredondado.length > 0) {
                const itens = produtosSelecionados.map((item, index) => {
                    return {
                        sku: item.sku?.id || item.id,
                    };
                });

                showLoading();
                const resposta = await axiosPost(`/vendas/incluir-itens-tabela/${tabela}/`, {
                    vigencia_preco: vigencia,
                    lista_itens: itens,
                });
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Alteração gravada com sucesso!",
                        life: 1500,
                    });
                    setProdutosSelecionados([]);
                    listagemRef.current?.buscarDados();
                    setExibirDialog(false);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 1500,
            });
            hideLoading();
        }
    }

    const selecionarPesquisa = (opcao = "") => {
        setOpcaoExibicao(opcao);
        if (opcao === "Todos") {
            setFieldValue("sku", null);
            listarProdutos();
        } else if (opcao === "Categoria") setProdutos([]);
        else if (opcao === "Pesquisa avançada") setProdutos([]);
        else setProdutosSelecionados([]);
    };

    const listarProdutos = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/produtos/sku?query={id,descricao_reduzida}&limit=1000000");
        hideLoading();
        if (resposta.status === 200) setProdutos(resposta.data.results);
    }, [showLoading, hideLoading]);

    const esconderDialogProdutos = () => {
        setProdutosSelecionados([]);
        setProdutos([]);
        setOpcaoExibicao("");
        setFieldValue("sku", null);
        setExibirDialog(false);
    };

    const insereProduto = () => {
        setProdutosSelecionados([...produtosSelecionados, formik.values.sku]);
        setFieldValue("sku", null);
    };

    const selecionaCategoria = async (categoria) => {
        setFieldValue("categoria", categoria);
        if (categoria) {
            showLoading();
            const resposta = await axiosGet(`/produtos/categorias-skus?categoria__id=${categoria}&limit=10000`);
            hideLoading();
            if (resposta.status === 200) {
                let lista = resposta.data.results;
                lista.forEach((item) => {
                    item.descricao_reduzida = item.sku.descricao_reduzida;
                });
                setProdutos(lista);
            }
        } else setProdutos([]);
    };

    const onChangeCategoria = useCallback(
        (e) => {
            setFieldValue("categoria", e);
        },
        [setFieldValue]
    );

    return (
        <Dialog
            header={"Adicionar produtos a tabela"}
            visible={exibirDialog}
            onHide={() => esconderDialogProdutos()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "65vw" }}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-grid p-fluid p-justify-center">
                    <div className="p-col-12 p-md-12">
                        <SelectButton
                            value={opcaoExibicao}
                            options={opcoesPesquisa}
                            onChange={(e) => selecionarPesquisa(e.value)}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    {opcaoExibicao === "Pesquisa avançada" ? (
                        <div className="p-field p-col-12 p-md-12">
                            <MakoBuscaSkuPersonalizada
                                skuValue={formik.values.sku}
                                skuChange={(e) => setFieldValue("sku", e)}
                                skuError={formik.errors.sku}
                            />
                        </div>
                    ) : null}

                    {opcaoExibicao === "Categoria" ? (
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="categoria">Selecione uma categoria *</label>
                            <MakoDropdownCategoriasHierarquicas
                                id="categoria"
                                name="categoria"
                                value={formik.values.categoria_selecionada}
                                getCategoriaCompleta={onChangeCategoria}
                                showClear
                                onChange={(e) => {
                                    setFieldValue("categoria_selecionada", e.value);
                                    selecionaCategoria(e.value);
                                }}
                            />
                        </div>
                    ) : null}
                </div>
                {opcaoExibicao === "Pesquisa avançada" ? (
                    <div className="p-grid p-justify-end p-mb-3 p-mr-2">
                        <Button
                            type="button"
                            icon="pi pi-plus"
                            label="Incluir"
                            className="p-button-success"
                            disabled={!formik.values.sku?.id}
                            onClick={() => insereProduto()}
                        />
                    </div>
                ) : null}

                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <PickList
                            source={produtos}
                            target={produtosSelecionados}
                            sourceHeader="Produtos"
                            targetHeader="Selecionados"
                            showSourceControls={false}
                            showTargetControls={false}
                            itemTemplate={(item) => <div>{item.descricao_reduzida}</div>}
                            onChange={(e) => {
                                setProdutos(e.source);
                                setProdutosSelecionados(e.target);
                            }}
                            sourceStyle={{ height: "200px" }}
                            targetStyle={{ height: "200px" }}
                        ></PickList>
                    </div>
                </div>
                <ul className="widget-person-list p-mt-5">
                    <div className="p-grid p-justify-center">
                        <Button
                            type="submit"
                            label="Adicionar produtos"
                            disabled={produtosSelecionados.length > 0 ? false : true}
                        />
                    </div>
                </ul>
            </form>
        </Dialog>
    );
};
