import React, { useRef } from 'react';

import { useHistory } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';

import { MakoControleAcesso } from '@/components/MakoControleAcesso';
import MakoListagem from '@/components/MakoListagem';

import { TIPO_PRIORIDADE_VINCULO_FORNECEDORPRODUTO } from '@/assets/constants/constants';
import permissoes from '@/assets/constants/permissoes';

import { axiosDelete } from '@/services/http';

import useLoading from '@/hooks/useLoading';
import useToast from '@/hooks/useToast';
import useClearRefs from '@/hooks/useClearRefs';
import { CodigoFiltroTemplate, DropdownFiltroTemplate, FornecedorFiltroTemplate, MoedaFiltroTemplate, NumeroFiltroTemplate, SkuFiltroTemplate } from '@/components/MakoFiltrosCabecalho';

export const VinculoFornecedor = () => {

    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const history = useHistory();

    const listagemRef = useRef();
    useClearRefs(listagemRef)

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.PRODUTO_CADASTRO_PRODUTOFORNECEDOR_VINCULARFORNECEDOR_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/produto/cadastros/vinculo-fornecedor/form")}
            />
        </>
    );

    const prioridadeTemplate = (value) => {
        const tipo = TIPO_PRIORIDADE_VINCULO_FORNECEDORPRODUTO.filter(item => item.value === value)[0];
        return <Badge value={tipo.label} severity={tipo.badge} />
    }

    const handleDelete = async (id) => {
        showLoading();

        const response = await axiosDelete(`/produtos/vinculo-produtos-fornecedor/${id}`);

        if (response.status === 204) {
            showSuccess({
                summary: "Sucesso, :)",
                detail: "Vinculo deletado com sucesso",
                life: 3000,
            })

            listagemRef.current?.buscarDados();
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos listas os centros de resultados.",
                life: 3000,
            })
        }

        hideLoading();
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_CADASTRO_PRODUTOFORNECEDOR_VINCULARFORNECEDOR_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    tooltip="Editar"
                    tooltipOptions={{ position: "left" }}
                    onClick={() => history.push({
                        pathname: "/produto/cadastros/vinculo-fornecedor/form",
                        state: rowData
                    })}
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_CADASTRO_PRODUTOFORNECEDOR_VINCULARFORNECEDOR_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    tooltip="Excluir"
                    tooltipOptions={{ position: "left" }}
                    onClick={() => handleDelete(rowData.id)}
                />
            </div>
        );
    };

    const PrioridadeFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: TIPO_PRIORIDADE_VINCULO_FORNECEDORPRODUTO
                }} 
                options={options}
            />
        )
    }

    const MediaFiltroTemplate = (options) => {
        return <NumeroFiltroTemplate options={options} inputProps={{ showButtons: true }} />;
    };

    const ProdutoFiltroTemplate = (options) => {
        return (
            <SkuFiltroTemplate options={options} />
        )
    }

    const colunas = [
        {
            field: "id",
            header: "id",
            style: { width: "10%" },
            filter: true,
            filterElement: CodigoFiltroTemplate
        },
        {
            field: "sku",
            header: "Produto",
            action: ({ sku }) => sku.descricao_reduzida,
            filter: true,
            filterElement: ProdutoFiltroTemplate
        },
        {
            field: "fornecedor",
            header: "Fornecedor",
            action: ({ fornecedor }) => fornecedor.nome,
            filter: true,
            filterElement: FornecedorFiltroTemplate
        },
        {
            field: "preco_compra",
            header: "Preço Compra",
            style: { width: "10%" },
            money: true,
            filter: true,
            filterElement: MoedaFiltroTemplate
        },
        {
            field: "media_entrega",
            header: "Media de entrega",
            style: { width: "10%" },
            filter: true,
            filterElement: MediaFiltroTemplate
        },
        {
            field: "prioridade",
            header: "Prioridade",
            style: { width: "12%" },
            action: ({ prioridade }) => prioridadeTemplate(prioridade),
            filter: true,
            filterElement: PrioridadeFiltroTemplate
        },
        {
            field: "",
            header: "Ações",
            style: { width: "10%" },
            action: (e) => actionBodyTemplate(e)
        }
    ]

    return (
        <div className='card'>
            <MakoListagem
                ref={listagemRef}
                titulo="Vinculos produto fornecedor"
                urlPesquisa="/produtos/vinculo-produtos-fornecedor/"
                painelEsquerdo={cabecalhoTabela}
                colunas={colunas}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    sku: { value: null, matchMode: "equals" },
                    fornecedor: { value: null, matchMode: "equals" },
                    preco_compra: { value: null, matchMode: "equals" },
                    media_entrega: { value: null, matchMode: "equals" },
                    prioridade: { value: null, matchMode: "equals" },
                }}
            />
        </div>
    )
}