import { dataToStr } from "@/assets/util/datas";
import { MakoTime } from "@/components/MakoTime";

export const TimeFiltroTemplate = ({ inputProps, ...options }) => {
    return (
        <MakoTime
            valueTime={options.value}
            onChange={(e) => options.filterCallback(dataToStr(e.value, "HH:mm:ss"))}
            {...inputProps}
            className="p-column-filter"
        />
    );
};
