import React, { useImperativeHandle, useState, forwardRef } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { Dialog } from "primereact/dialog";

import useAxiosPrivate from "@/hooks/useAxiosPrivate";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";
import { MakoButton } from "@/components/MakoButton";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { MakoDropdownCompetenciaEstoque } from "@/components/MakoInputs/MakoDropdownCompetenciaEstoque";

const Modal = ({ onSuccess }, ref) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const [visible, setVisible] = useState(false);

    const { showSuccess, showError } = useToast();
    const { empresaSelecionadaId } = useEmpresa();
    const { axiosPost, axiosPatch } = useAxiosPrivate();

    const fecharModal = () => {
        setVisible(() => false);
        formik.resetForm();
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: {
            competencia: null,
            empresa: empresaSelecionadaId,
        },
        onSubmit: handleSubmit,
    });

    const abrirModal = (competencia = null) => {
        if (competencia)
            setValues({
                ...competencia,
                empresa: competencia.empresa.id,
                competencia: competencia.competencia.id,
            });
        setVisible(() => true);
    };

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                competencia: Yup.number()
                    .required("O campo 'competência' é obrigatório")
                    .typeError("Informe um 'competência' válido"),
                empresa: Yup.number()
                    .required("O campo 'empresa' é obrigatório")
                    .typeError("Informe um 'empresa' válido"),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values?.id) {
                showLoading();
                const { status, data } = await axiosPost(
                    "/produtos/competencias-movimentacoes-estoques-empresas/",
                    dadosValidados
                );
                hideLoading();
                if (status === 201) {
                    showSuccess({
                        summary: "Sucesso!",
                        detail: data?.msg || "Vinculo cadastrado com sucesso.",
                        life: 2000,
                    });
                    if (typeof onSuccess === "function") onSuccess();
                    fecharModal();
                } else if (status === 400 && typeof data === "object") {
                    showError({
                        summary: "Erro :(",
                        detail: Object.keys(data).map((key) => (
                            <>
                                <span>{data[key]}</span>
                                <br />
                            </>
                        )),
                        life: 3000,
                    });
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: data?.msg || "Desculpe, não foi possível processar sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const { status, data } = await axiosPatch(
                    `/produtos/competencias-movimentacoes-estoques-empresas/${values.id}/`,
                    dadosValidados
                );
                hideLoading();
                if (status === 200) {
                    showSuccess({
                        summary: "Sucesso!",
                        detail: data?.msg || "Vinculo alterado com sucesso.",
                        life: 2000,
                    });
                    if (typeof onSuccess === "function") onSuccess();
                    fecharModal();
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não foi possível processar sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    return (
        <Dialog header="Cadastrar vinculo" visible={visible} onHide={fecharModal} style={{ width: "80rem" }}>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="competencia">Competência Estoque *</label>
                        <MakoDropdownCompetenciaEstoque
                            id="competencia"
                            name="competencia"
                            buscarPorEmpresa={false}
                            value={formik.values.competencia}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.competencia })}
                        />
                        {formik.errors.competencia && <small className="p-error">{formik.errors.competencia}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="empresa">Empresa *</label>
                        <MakoDropdownEmpresas
                            id="empresa"
                            name="empresa"
                            value={formik.values.empresa}
                            disabled
                            onChange={(e) => setFieldValue("empresa", e.id)}
                        />
                        {formik.errors.empresa && <small className="p-error">{formik.errors.empresa}</small>}
                    </div>
                </div>
                <div className="p-grid p-col-12 p-md-6">
                    <MakoButton
                        loading={loading}
                        icon="pi pi-save"
                        label="Gravar"
                        type="submit"
                        className="p-button-info p-mr-2"
                    />
                    <MakoButton
                        icon="pi pi-trash"
                        label="Limpar"
                        type="reset"
                        className="p-button-warning p-mr-2"
                        onClick={formik.resetForm}
                        loading={loading}
                    />
                    <MakoButton
                        loading={loading}
                        label="Cancelar"
                        type="button"
                        className="p-button-danger"
                        onClick={fecharModal}
                    />
                </div>
            </form>
        </Dialog>
    );
};

export const FormVinculoCompetenciaMovEstoqueModal = forwardRef(Modal);
