import React, { createContext, useCallback, useEffect, useState } from "react";
import { axiosGet, axiosPost, axiosPut } from "@/services/http";
import { gerarId } from "@/assets/util/util";
import useLoading from "@/hooks/useLoading";

const TrocaContext = createContext({});

export const TrocaProvider = ({ children }) => {
    const [submit, setSubmit] = useState(false);
    const [troca, setTroca] = useState(null);
    const [itenstroca, setItensTroca] = useState([]);
    const [itenssaida, setItensSaida] = useState([]);
    const [totalTroca, setTotalTroca] = useState(0);
    const [totalSaida, setTotalSaida] = useState(0);
    const [valorTotal, setValorTotal] = useState(0);
    const { showLoading, hideLoading } = useLoading();

    const gerenciarOperacoesCrud = (lista, obj, op) => {
        switch (op) {
            case "novo":
                lista.push({ ...obj, id: gerarId() });

                break;

            case "editar":
                if (typeof obj.id === "number") {
                    if (lista.length > 0) {
                        const index = lista.findIndex((e) => e.id === obj.id);
                        lista[index] = obj;
                    }
                } else if (typeof obj.id === "string") {
                    if (lista.length > 0) {
                        const index = lista.findIndex((e) => e.id === obj.id);
                        obj.status = "novo";
                        lista[index] = obj;
                    }
                }

                break;

            case "deletar":
                if (typeof obj.id === "number") {
                    if (lista.length > 0) {
                        const index = lista.findIndex((e) => e.id === obj.id);
                        obj.status = "deletar";
                        lista[index] = obj;
                    }
                } else if (typeof obj.id === "string") {
                    if (lista.length > 0) {
                        const index = lista.findIndex((e) => e.id === obj.id);
                        lista.splice(index, 1);
                    }
                }

                break;

            default:
                break;
        }

        return lista;
    };

    const handleDadosTroca = useCallback(
        (dados) => {
            setTroca({
                ...troca,
                ...dados,
            });
        },
        [troca, setTroca]
    );

    const handleItemSaidaTroca = useCallback(
        (op, dados) => {
            let dado = troca.itemsaidatroca_set ? troca.itemsaidatroca_set : [];

            dado = gerenciarOperacoesCrud(dado, dados, op);

            let lista = [];
            dado.forEach((aux) => {
                if (aux.status !== "deletar") lista.push(aux);
            });
            setItensSaida(lista);

            setTroca({
                ...troca,
                itemsaidatroca_set: dado,
            });
        },
        [troca]
    );

    const handleItemTrocaDevolucao = useCallback(
        (op, dados) => {
            let dado = troca.itemtrocadevolucao_set ? troca.itemtrocadevolucao_set : [];

            dado = gerenciarOperacoesCrud(dado, dados, op);
            let lista = [];

            dado.forEach((aux) => {
                if (aux.status !== "deletar") lista.push(aux);
            });

            setItensTroca(lista);

            setTroca({
                ...troca,
                itemtrocadevolucao_set: dado,
            });
        },
        [troca]
    );

    useEffect(() => {
        let total = 0,
            valor_troca = 0,
            valor_saida = 0;
        if (troca?.itemtrocadevolucao_set?.length > 0)
            troca.itemtrocadevolucao_set.forEach((item) => {
                valor_troca += parseFloat(item.subtotal);
                total += parseFloat(item.subtotal);
            });
        if (troca?.itemsaidatroca_set?.length > 0)
            troca.itemsaidatroca_set.forEach((item) => {
                valor_saida += parseFloat(item.subtotal);
                total += parseFloat(item.subtotal);
            });
        setValorTotal(total);
        setTotalTroca(valor_troca);
        setTotalSaida(valor_saida);
    }, [troca?.itemtrocadevolucao_set, troca?.itemsaidatroca_set, handleItemSaidaTroca, handleItemTrocaDevolucao]);

    const formatarObjCrud = (itens) => {
        let crud = {};

        itens.forEach((item) => {
            if (item.status === "novo") {
                if (crud.create) {
                    crud.create.push(item);
                } else {
                    crud = {
                        ...crud,
                        create: [item],
                    };
                }
            } else if (item.status === "deletar") {
                if (crud.remove) {
                    crud.remove.push(item.id);
                } else {
                    crud = {
                        ...crud,
                        remove: [item.id],
                    };
                }
            } else if (item.status === "editar") {
                if (crud.update) {
                    crud = {
                        ...crud,
                        update: {
                            ...crud.update,
                            [item.id]: item,
                        },
                    };
                } else {
                    crud = {
                        ...crud,
                        update: {
                            [item.id]: item,
                        },
                    };
                }
            }
        });

        return crud;
    };

    const handlePreencherTroca = useCallback(
        async (idTroca) => {
            showLoading();
            const resposta = await axiosGet(`/vendas/troca/${idTroca}/`);
            hideLoading();

            if (resposta.status === 200) {
                const { itemtrocadevolucao_set = [], itemsaidatroca_set = [], ...json } = resposta.data;

                setItensSaida(itemsaidatroca_set);
                setItensTroca(itemtrocadevolucao_set);
                setTroca(json);
                setSubmit(true);
            }
        },
        [showLoading, hideLoading]
    );

    const handleTroca = async () => {
        const {
            id,
            status,
            itemtrocadevolucao_set = [],
            itemsaidatroca_set = [],
            estagio_venda,
            cliente,
            ...teste
        } = troca;

        let postTroca = {
            ...teste,
            id: id,
            cliente: cliente.id,
            status: id ? status?.id : "P",
            estagio_venda: estagio_venda?.id,
            valor_total: valorTotal,
        };

        if (itemtrocadevolucao_set.length > 0) {
            let lista = [];
            await itemtrocadevolucao_set.forEach(async (item) => {
                lista.push({
                    ...item,
                    unidade_medida: item.unidade_medida?.id,
                    sku_movimenta_estoque: item.sku_movimenta_estoque.id,
                    item_venda: item.item_venda?.sku?.id || null,
                    sku: item.sku?.id,
                });
            });
            const itens_troca = formatarObjCrud(lista);

            postTroca = {
                ...postTroca,
                itemtrocadevolucao_set: itens_troca,
            };
        }

        if (itemsaidatroca_set.length > 0) {
            let lista = [];
            await itemsaidatroca_set.forEach(async (item) => {
                lista.push({
                    ...item,
                    unidade_venda: item.unidade_venda?.id,
                    sku: item.sku.id,
                });
            });
            const itens_saida = formatarObjCrud(lista);

            postTroca = {
                ...postTroca,
                itemsaidatroca_set: itens_saida,
            };
        }

        if (!postTroca.id) {
            const { status, data } = await axiosPost("/vendas/troca/", postTroca);
            return { status, data };
        } else {
            const { status, data } = await axiosPut(`/vendas/troca/${postTroca.id}/`, postTroca);
            return { status, data };
        }
    };

    return (
        <TrocaContext.Provider
            value={{
                submit,
                troca,
                itenstroca,
                itenssaida,
                valorTotal,
                totalTroca,
                totalSaida,
                setTroca,
                setSubmit,
                setValorTotal,
                setTotalSaida,
                setTotalTroca,
                handleDadosTroca,
                handleItemSaidaTroca,
                handleItemTrocaDevolucao,
                handlePreencherTroca,
                handleTroca,
            }}
        >
            {children}
        </TrocaContext.Provider>
    );
};

export default TrocaContext;
