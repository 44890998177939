import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { PageBase } from "@/components/PageBase";
import { MakoInputCFOP } from "@/components/MakoInputCFOP";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { Dropdown } from "@/components/Dropdown";
import { TIPO_CHAVE_DESTINACAO_SKU_CHOICE, TIPO_OPERACAO_ENTRADA_FISCAL_CHOICE } from "@/assets/constants/constants";
import { axiosPost, axiosPut } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import useEmpresa from "@/hooks/useEmpresa";

export const DestinacaoEntradaForm = ({ location }) => {
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const { empresaSelecionadaId } = useEmpresa();

    const { setValues, ...formik } = useFormik({
        initialValues: location?.state
            ? {
                  ...location.state,
                  centro_estocagem_destino: location.state.centro_estocagem_destino?.id,
                  categoria: location.state.categoria?.id,
                  chave: location.state.chave?.id,
              }
            : {
                  chave: null,
                  nome: "",
                  descricao: "",
                  operacao: null,
                  cfop_interno: null,
                  cfop_interestadual: null,
                  centro_estocagem_destino: null,
                  categoria: null,
                  empresa: empresaSelecionadaId
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                chave: Yup.string().required("O campo 'chave' é obrigatório.").typeError("Selecione uma chave."),
                nome: Yup.string().required("O campo 'descricao' é obrigatório."),
                descricao: Yup.string().notRequired().default(""),
                operacao: Yup.number()
                    .required("O campo 'operação' é obrigatório.")
                    .typeError("Selecione uma operação."),
                cfop_interno: Yup.object()
                    .shape({
                        id: Yup.number().nullable(),
                    })
                    .nullable(),
                cfop_interestadual: Yup.object()
                    .shape({
                        id: Yup.number().nullable(),
                    })
                    .nullable(),
                centro_estocagem_destino: Yup.number()
                    .required("Selecione um centro de estocagem.")
                    .typeError("Selecione um centro de estocagem."),
                categoria: Yup.number().notRequired().nullable().default(null),
            });
            const { cfop_interno, cfop_interestadual, ...rest } = await formSchema.validate(values, {
                abortEarly: false,
            });
            const dadosValidos = {
                ...rest,
                cfop_interno: cfop_interno?.id,
                cfop_interestadual: cfop_interestadual?.id,
            };
            if (!values.id) {
                showLoading();
                const resposta = await axiosPost("/compras/destinacoes-entradas/", dadosValidos);
                hideLoading();
                if (resposta.status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Destinação de entrada cadastrada com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        history.push("/compras/cadastros/destinacoes");
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(`/compras/destinacoes-entradas/${values.id}/`, dadosValidos);
                hideLoading();
                if (resposta.status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Destinação de entrada alterada com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        history.push("/compras/cadastros/destinacoes");
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Nova destinação de entrada" : "Manutenção de destinação de entrada"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="nome">Nome *</label>
                        <InputText
                            id="nome"
                            name="nome"
                            value={formik.values.nome}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.nome })}
                        />
                        {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="descricao">Descrição *</label>
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="centro-estocagem">Centro de estocagem destino *</label>
                        <Dropdown
                            id="centro-estocagem"
                            name="centro_estocagem_destino"
                            url="/produtos/centros-estocagem/?query={id,nome}"
                            optionValue="id"
                            optionLabel="nome"
                            value={formik.values.centro_estocagem_destino}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.centro_estocagem_destino })}
                        />
                        {formik.errors.centro_estocagem_destino && (
                            <small className="p-error">{formik.errors.centro_estocagem_destino}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="categoria">Categoria</label>
                        <MakoDropdownCategoriasHierarquicas
                            id="categoria"
                            name="categoria"
                            categoriaTituloSelecionavel
                            apenasTitulo
                            showClear
                            value={formik.values.categoria}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="chave">Tipo de produto *</label>
                        <Dropdown
                            id="chave"
                            name="chave"
                            options={TIPO_CHAVE_DESTINACAO_SKU_CHOICE}
                            value={formik.values.chave}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.chave })}
                        />
                        {formik.errors.chave && <small className="p-error">{formik.errors.chave}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="operacao">Classe de movimento fiscal *</label>
                        <Dropdown
                            id="operacao"
                            name="operacao"
                            options={TIPO_OPERACAO_ENTRADA_FISCAL_CHOICE}
                            value={formik.values.operacao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.operacao })}
                        />
                        {formik.errors.operacao && <small className="p-error">{formik.errors.operacao}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="cfop-interno">CFOP interno</label>
                        <MakoInputCFOP
                            id="cfop-interno"
                            name="cfop_interno"
                            value={formik.values.cfop_interno}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="cfop-interestadual">CFOP interestadual</label>
                        <MakoInputCFOP
                            id="cfop-interestadual"
                            name="cfop_interestadual"
                            value={formik.values.cfop_interestadual}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <CamposObrigatorios />
                <div className="p-grid p-col-12 p-md-6">
                    <Button label="Gravar" icon="pi pi-save" type="submit" className="p-button-info p-mr-2" />
                    <Button
                        label="Voltar"
                        icon="pi pi-angle-double-left"
                        type="button"
                        className="p-button-danger p-mr-2"
                        onClick={() => history.push("/compras/cadastros/destinacoes")}
                    />
                </div>
            </form>
        </PageBase>
    );
};
