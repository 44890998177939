import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { axiosGet, axiosPost } from "@/services/http";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

import useLoading from "@/hooks/useLoading";

export const ClonarOperacaoModalForm = (props) => {
    const [empresa, setEmpresa] = useState(props.empresa?.id || null);
    const [listaEmpresas, setListaEmpresas] = useState([]);
    const [novaDescricao, setNovaDescricao] = useState("");
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);

    async function clonarOperacao() {
        try {
            showLoading();
            const dadosOperacao = await axiosGet(`/fiscal/operacoes-fiscais/${props.opFiscal?.id}/`);
            hideLoading();

            let listaNcm = [],
                listaItem = [],
                listaCfop = [];

            await dadosOperacao.data.ncmoperacaofiscal_set.forEach((ncmop) => {
                listaNcm.push({
                    ...ncmop,
                    ncm: ncmop.ncm.id,
                    cst_icms: ncmop.cst_icms,
                    cst_ipi: ncmop.cst_ipi,
                    cst_pis: ncmop.cst_pis,
                    cst_cofins: ncmop.cst_cofins,
                    enquadramento_ipi: ncmop.enquadramento_ipi?.id || null,
                });
            });

            await dadosOperacao.data.itemoperacaofiscal_set.forEach(async (item) => {
                listaItem.push({
                    sku: item.sku.id,
                    skuoperacaofiscalbr: {
                        ...item.skuoperacaofiscalbr_set[0],
                        enquadramento_ipi: item.skuoperacaofiscalbr_set[0].enquadramento_ipi?.id || null,
                    },
                    skuoperacaofiscaluf: {
                        ...item.skuoperacaofiscaluf_set[0],
                        enquadramento_ipi: item.skuoperacaofiscaluf_set[0].enquadramento_ipi?.id || null,
                    },
                });
            });

            await dadosOperacao.data.cfopoperacaofiscal_set.forEach((cfop) => {
                listaCfop.push({
                    cfop: cfop.cfop.id,
                });
            });

            showLoading();
            const resposta = await axiosPost("/fiscal/clonar-operacao-fiscal/", {
                nova_empresa: empresa,
                dados_basicos: {
                    ...dadosOperacao.data,
                    id: null,
                    descricao: novaDescricao,
                    destinacao: dadosOperacao.data.destinacao?.id || dadosOperacao.data.destinacao,
                    ncmoperacaofiscal_set: null,
                    itemoperacaofiscal_set: null,
                    cfopoperacaofiscal_set: null,
                },
                ncmoperacaofiscal: listaNcm,
                cfopoperacaofiscal: listaCfop,
                itemoperacaofiscal: listaItem,
            });
            hideLoading();

            if (resposta.data.success) {
                toastRef.current.show({
                    severity: "success",
                    summary: "Sucesso",
                    detail: "Operação clonada com sucesso!",
                    life: 2500,
                });
                props.esconderDialog();
            } else {
                toastRef?.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: resposta.data.msg,
                    life: 3000,
                });
            }
        } catch (error) {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 1500,
            });
            hideLoading();
        }
    }

    const listarEmpresas = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/pessoas/grupos-perfis/");
        hideLoading();

        if (resposta.status === 200) {
            if (resposta.data.results.length > 0)
                setListaEmpresas(await resposta.data.results.map((vinculo) => vinculo.perfil));
        }
    }, [showLoading, hideLoading]);

    useEffect(() => {
        listarEmpresas();
    }, [listarEmpresas]);

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <form>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="natureza_operacao">Operação fiscal: *</label>
                            <InputText id="tabela" name="tabela" value={props.opFiscal?.descricao} disabled />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="empresa">Selecione uma empresa: *</label>
                            <Dropdown
                                id="empresa"
                                name="empresa"
                                options={listaEmpresas}
                                optionValue="id"
                                optionLabel="nome"
                                filter
                                filterBy="nome"
                                placeholder="Selecione uma empresa"
                                value={empresa}
                                onChange={(e) => setEmpresa(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="descricao">Descrição da nova operação *</label>
                            <InputText
                                id="descricao"
                                name="descricao"
                                value={novaDescricao}
                                onChange={(e) => setNovaDescricao(e.target.value)}
                                autoComplete="off"
                                autoFocus
                            />
                        </div>
                    </div>
                    <br />
                    <div className="p-grid p-justify-center p-col-12 p-md-12">
                        <Button
                            label="Clonar operação"
                            type="button"
                            icon="pi pi-align-justify"
                            className="p-button-info p-mr-2"
                            disabled={!novaDescricao}
                            onClick={() => clonarOperacao()}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};
