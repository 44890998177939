import React, { useCallback, useRef } from "react";
import classNames from "classnames";
import { addDays } from "date-fns";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { ModalEditarParcela } from "./ModalEditarParcela";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import {
    MakoInputIntervaloParcelas,
    validarProgressaoIntervaloParcelas,
} from "@/components/MakoInputs/MakoInputIntervaloParcelas";
import MakoListagem from "@/components/MakoListagem";
import { TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE } from "@/assets/constants/financeiro";
import useOrdemCompra from "@/hooks/useOrdemCompra";
import { dataToStr, parseData } from "@/assets/util/datas";
import { OP_CRUD_DJANGO } from "@/assets/util/persistenciaDjango";

export const ParcelasOrdemCompraForm = () => {
    const { dadosBasicos, permiteAlterar, parcelas, handleParcelas, handleRemoverParcelas } = useOrdemCompra();
    const modalParcelaRef = useRef(null);

    const formik = useFormik({
        initialValues: {
            vencimento_inicial: dadosBasicos.vencimento_inicial || null,
            intervalo_parcelas: "",
            valor_total_parcelas: dadosBasicos.valor_total_parcelas || 0,
            forma_pagamento: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                forma_pagamento: Yup.string()
                    .required("O campo 'forma de pagamnto' é obrigatório.")
                    .typeError("Selecione uma forma de pagamento."),
                intervalo_parcelas: Yup.string()
                    .required("O campo 'intervalo de parcelas' é obrigatório.")
                    .test("Validation_1", "A progressão do intervalo não está correta.", (value) =>
                        validarProgressaoIntervaloParcelas(value)
                    ),
            });

            await formSchema.validate(values, { abortEarly: false });

            handleParcelas(values);
            formik.resetForm();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const numeroParcelaBodyTemplate = (rowData) => {
        if (rowData.numero_parcela === 0) return <span>ENTRADA</span>;
        return <span>{rowData.numero_parcela}</span>;
    };

    const parseDiasAposVencimentoEmData = (dias) => {
        const { data_pedido } = dadosBasicos;
        const data = typeof data_pedido === "string" ? parseData(data_pedido) : data_pedido;
        return addDays(data, parseInt(dias));
    };

    const vencimentoEstimadoBodyTemplate = (rowData) => {
        const vencimentoEstimado = parseDiasAposVencimentoEmData(rowData.dias_apos_pedido);
        return dataToStr(vencimentoEstimado, "dd/MM/yyyy");
    };

    const formaPgtoBodyTemplate = (rowData) => {
        const formaPgto = TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE.find((e) => e.id === rowData.forma_pagamento);
        if (!formaPgto) return <span>N/A</span>;
        return <span>{formaPgto.label}</span>;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    disabled={!permiteAlterar}
                    onClick={() => {
                        const keyId = rowData.id ? "id" : "localId";
                        const _parcela = {
                            id_parcela: rowData[keyId],
                            vencimento: parseDiasAposVencimentoEmData(rowData.dias_apos_pedido),
                            ...rowData,
                        };
                        modalParcelaRef.current?.abrirModal(_parcela);
                    }}
                />
            </div>
        );
    };

    const colunas = [
        { field: "numero_parcela", header: "Nº da parcela", action: (e) => numeroParcelaBodyTemplate(e) },
        { field: "valor_parcela", header: "Valor", money: true },
        { field: "dias_apos_pedido", header: "Dias após o pedido" },
        {
            field: "vencimento_estimado",
            header: "Vencimento estimado",
            action: (e) => vencimentoEstimadoBodyTemplate(e),
        },
        { fiedl: "forma_pagamento", header: "Forma de pagamento", action: (e) => formaPgtoBodyTemplate(e) },
        {
            field: "actions",
            header: "Ações",
            style: { width: "8%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    const removerTodasParcelas = () => {
        formik.resetForm();
        handleRemoverParcelas();
    };

    const exibirParcelasAtivas = useCallback(() => {
        return parcelas.filter((parcela) => parcela._status !== OP_CRUD_DJANGO.deletar);
    }, [parcelas]);

    return (
        <>
            <ModalEditarParcela ref={modalParcelaRef} />
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="vencimento-inicial">Vencimento inicial *</label>
                        <MakoCalendar
                            id="vencimento-inicial"
                            name="vencimento_inicial"
                            disabled
                            valueCalendar={formik.values.vencimento_inicial}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="forma-pagamento">Forma de pagamento *</label>
                        <Dropdown
                            id="forma-pagamento"
                            name="forma_pagamento"
                            placeholder="Selecione"
                            options={TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE}
                            optionValue="id"
                            value={formik.values.forma_pagamento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.forma_pagamento })}
                        />
                        {formik.errors.forma_pagamento && (
                            <small className="p-error">{formik.errors.forma_pagamento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="intervalo-parcelas">Dias entre as parcelas *</label>
                        <MakoInputIntervaloParcelas
                            id="intervalo-parcelas"
                            name="intervalo_parcelas"
                            value={formik.values.intervalo_parcelas}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.intervalo_parcelas })}
                        />
                        {formik.errors.intervalo_parcelas && (
                            <small className="p-error">{formik.errors.intervalo_parcelas}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-antecipação">Valor da antecipação</label>
                        <MakoInputMoeda
                            id="valor-antecipação"
                            name="valor_antecipado"
                            disabled
                            valueMoeda={dadosBasicos.valor_antecipado || 0}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-total-parcelas">Valor total das parcelas</label>
                        <MakoInputMoeda
                            id="valor-total-parcelas"
                            name="valor_total_parcelas"
                            disabled
                            valueMoeda={formik.values.valor_total_parcelas}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-total-oc">Valor total da OC</label>
                        <MakoInputMoeda
                            id="valor-total-oc"
                            name="valor_total_oc"
                            disabled
                            valueMoeda={dadosBasicos.valor_total_ordem_compra || 0}
                        />
                    </div>
                </div>
                <CamposObrigatorios />
                <div className="p-grid">
                    <div className="p-col-12 p-md-12">
                        <Button
                            type="submit"
                            icon="pi pi-save"
                            disabled={!permiteAlterar}
                            label="Gerar parcelas"
                            className="p-mr-2 p-mb-2"
                        />
                        <Button
                            label="Limpar parcelas"
                            type="reset"
                            icon="pi pi-trash"
                            disabled={!permiteAlterar}
                            className="p-button-warning p-mr-2 p-mb-2"
                            onClick={removerTodasParcelas}
                        />
                    </div>
                </div>
            </form>
            <MakoListagem
                colunas={colunas}
                dadosLocal={parcelas}
                aposPesquisar={exibirParcelasAtivas}
                configTabela={{
                    paginator: true,
                }}
            />
        </>
    );
};
