import React, { useCallback, useEffect, useRef, useState } from "react";
import { ConfirmDialog } from "primereact/confirmdialog";
import { axiosGet, axiosPost } from "@/services/http";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";

export const CalculoComissaoForm = (props) => {
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [listaApuracoes, setListaApuracoes] = useState([]);
    const { showLoading, hideLoading } = useLoading();
    const listagemRef = useRef(null);

    async function handleApurar() {
        showLoading();
        const resposta = await axiosPost(`/vendas/apurar-comissoes/`, {
            ...props.apuracao,
            tipo_apuracao: props.apuracao.tipo_apuracao?.codigo || props.apuracao.tipo_apuracao,
        });
        hideLoading();

        if (resposta.status === 200) {
            props.toast.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Comissões calculadas com sucesso!",
                life: 2500,
            });

            setTimeout(() => {
                setConfirmDialog(false);
            }, 2000);
        } else {
            props.toast.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 1500,
            });
        }
    }

    const listarComissoesApuradas = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet(`/vendas/comissoes-apuradas?apuracao=${props.apuracao.id}&limit=3000`);
        hideLoading();

        if (resposta.status === 200) {
            if (resposta.data.results?.length > 0) setListaApuracoes(resposta.data.results);
            else setConfirmDialog(true);
        }
    }, [showLoading, hideLoading, props.apuracao.id]);

    // const esconderCalculo = () => {
    //     setConfirmDialog(false);
    //     props.dialog(false);
    // };

    const colunas = [
        { field: "id", header: "Código" },
        { field: "comissionado.nome", header: "Comissionado", style: { minWidth: "150px" } },
        { field: "comissionado.identificacao", header: "CPF" },
        { field: "chave", header: "Chave" },
        { field: "escopo", header: "Escopo" },
        { field: "soma_base", header: "Base" },
        { field: "valor_comissao", header: "Comissão" },
    ];

    useEffect(() => {
        listarComissoesApuradas();
    }, [listarComissoesApuradas]);

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <MakoListagem ref={listagemRef} colunas={colunas} dadosLocal={listaApuracoes} />
                    </div>
                </div>
                <ConfirmDialog
                    visible={confirmDialog}
                    onHide={() => setConfirmDialog(false)}
                    header="ATENÇÃO!"
                    message={
                        "As comissões desta apuração ainda não foram cálculadas. Deseja realmente realizar o cálculo das comissões?"
                    }
                    icon="pi pi-info-circle p-mr-3"
                    accept={() => handleApurar()}
                    acceptLabel="Sim"
                    acceptClassName="p-button-success"
                    reject={() => setConfirmDialog(false)}
                    rejectLabel="Não"
                    rejectClassName="p-button-danger"
                />
            </div>
        </div>
    );
};
