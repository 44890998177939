import React, { useRef } from "react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";

import { DateTimeFiltroTemplate, DropdownFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import MakoListagem from "@/components/MakoListagem";
import useRelatorio from "@/hooks/useRelatorio";
import useToast from "@/hooks/useToast";
import useAuth from "@/hooks/useAuth";

import useClearRefs from "@/hooks/useClearRefs";
import useAxiosPrivate from "@/hooks/useAxiosPrivate";

export const RelatoriosEmitidosPage = () => {
    const { carregarRelatorio } = useRelatorio();
    const { axiosDelete } = useAxiosPrivate();
    const { showError } = useToast();
    const { user } = useAuth();

    const listagemRef = useRef(null);

    useClearRefs(listagemRef);

    const deletarRelatorio = async (relatorio) => {
        const resposta = await axiosDelete(`/relatorios/relatorios-emitidos/${relatorio.id}`);
        if (resposta.status === 204) {
            listagemRef.current?.buscarDados();
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
        }
    };

    const confirmarDeleteRelatorio = (relatorio) => {
        confirmDialog({
            message: "Confirma a exclusão do relatório?",
            header: "Confirmação",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Deletar",
            accept: () => deletarRelatorio(relatorio),
            rejectLabel: "Cancelar",
        });
    };

    const abrirRelatorio = async (id) => {
        await carregarRelatorio(id);
    };

    const statusBodyTemplate = (rowData) => {
        const { status } = rowData;

        if (status === "pendente") {
            return <Tag severity="warn" value={status.toUpperCase()} />;
        }

        if (status === "gerando") {
            return <Tag value={status.toUpperCase()} />;
        }

        if (status === "pronto") {
            return <Tag severity="success" value={status.toUpperCase()} />;
        }

        return <Tag severity="danger" value={status.toUpperCase()} />;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-download"
                    className="p-button-rounded p-button-info p-mb-1 p-mr-2"
                    disabled={rowData.status !== "pronto"}
                    onClick={() => abrirRelatorio(rowData.id)}
                />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mb-1"
                    disabled={rowData.status !== "pronto"}
                    onClick={() => confirmarDeleteRelatorio(rowData)}
                />
            </div>
        );
    };

    const statusFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "id",
                    optionLabel: "label",
                    options: [
                        {
                            id: "pendente",
                            label: "Pendente",
                        },
                        {
                            id: "gerando",
                            label: "Gerando",
                        },
                        {
                            id: "pronto",
                            label: "Pronto",
                        },
                        {
                            id: "erro",
                            label: "Erro",
                        },
                    ],
                }}
                options={options}
            />
        );
    };

    const colunas = [
        { field: "nome", header: "Nome do relatório", filter: true },
        {
            field: "data_hora",
            header: "Data / Hora da emissão",
            dateFormat: "dd/MM/yyyy HH:mm:ss",
            filter: true,
            filterElement: DateTimeFiltroTemplate,
        },
        { field: "logs.msg", header: "Mensagem" },
        {
            field: "status",
            header: "Status",
            style: { width: "10%" },
            action: (e) => statusBodyTemplate(e),
            filter: true,
            filterElement: statusFiltroTemplate,
        },
        { field: "actions", header: "Ações", style: { width: "10%" }, action: (e) => actionBodyTemplate(e) },
    ];

    return (
        <div className="p-grid">
            <ConfirmDialog />
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Relatórios emitidos"
                        colunas={colunas}
                        urlPesquisa={`/relatorios/relatorios-emitidos/?usuario=${user.id}`}
                        configTabela={{ lazy: true, paginator: true }}
                        filtros={{
                            nome: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                            },
                            data_hora: { value: null, matchMode: "equals" },
                            status: { value: null, matchMode: "equals" },
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
