import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";
import { Dropdown } from "@/components/Dropdown";
import { axiosPatch } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";

const Modal = ({ onCreate }, ref) => {
    const [visible, setVisible] = useState(false);
    const [emails, setEmails] = useState([]);
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();

    const formik = useFormik({
        initialValues: {
            perfil: null,
            email: "",
            username: "",
            password: "",
            confirmpass: "",
            empresa_padrao: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                perfil: Yup.object()
                    .typeError("Você precisa selecionar um perfil.")
                    .required("O campo 'perfil' é obrigatório."),
                email: Yup.string().required("O campo 'email' é obrigatório."),
                username: Yup.string()
                    .trim("Não pode ser em branco.")
                    .required("O campo 'nome de usuário' é obrigatório."),
                password: Yup.string()
                    .trim("Não pode ser em branco.")
                    .matches(
                        /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})./,
                        "Senha fraca"
                    )
                    .required("O campo 'senha' é obrigatório."),
                confirmpass: Yup.string()
                    .required("O campo 'confirmar senha' é obrigatório.")
                    .oneOf([formik.values.password], "As senhas não são iguais."),
                empresa_padrao: Yup.number().required("O campo 'empresa padrão' é obrigatório."),
            });
            await formSchema.validate(values, { abortEarly: false });

            const usuario = {
                perfil: values.perfil.id,
                email: values.email,
                username: values.username,
                password: values.password,
                empresa_padrao: values.empresa_padrao,
            };

            showLoading();
            const { status } = await axiosPatch(`/pessoas/perfis/${values.perfil.id}/`, { usuario });
            hideLoading();

            if (status === 200) {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Usuário cadastrado com sucesso!",
                    life: 1500,
                });
                setTimeout(() => {
                    formik.resetForm();
                    setVisible(false);
                    if (typeof onCreate === "function") onCreate();
                }, 2000);
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 3000,
                });
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const autoCompletePerfilTemplate = (row) => {
        return `${row.nome} - ${row.identificacao}`;
    };

    const abrirModal = () => {
        setVisible(true);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const listarEmailsPerfilSelecionado = useCallback(() => {
        if (!formik.values.perfil) return;
        const { emailperfil_set } = formik.values.perfil;
        setEmails(emailperfil_set);
    }, [formik.values.perfil]);

    useEffect(() => {
        listarEmailsPerfilSelecionado();
    }, [listarEmailsPerfilSelecionado]);

    return (
        <Dialog
            header="Cadastro de usuário"
            visible={visible}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "30vw" }}
            onHide={() => setVisible(false)}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid ">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="empresa_padrao">Empresa padrão *</label>
                        <MakoDropdownEmpresas
                            id="empresa_padrao"
                            name="empresa_padrao"
                            showClear={false}
                            value={formik.values.empresa_padrao}
                            onChange={(e) => formik.setFieldValue("empresa_padrao", e.id)}
                            className={classNames({ "p-invalid": formik.errors.empresa_padrao })}
                        />
                        {formik.errors.empresa_padrao && (
                            <small className="p-error">{formik.errors.empresa_padrao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12">
                        <label htmlFor="perfil">Pessoa cadastrada *</label>
                        <MakoAutoComplete
                            id="perfil"
                            name="perfil"
                            placeholder="Busque pelo nome ou CPF ... (min 4 caracteres)"
                            minCaracteresBusca={3}
                            urlSearch={
                                "/pessoas/perfis?query={id,nome,identificacao,emailperfil_set}&tipo_pessoa=PF&usuario__isnull=true&search="
                            }
                            field="nome"
                            value={formik.values.perfil}
                            onChange={formik.handleChange}
                            itemTemplate={autoCompletePerfilTemplate}
                        />
                        {formik.errors.perfil && <small className="p-error">{formik.errors.perfil}</small>}
                    </div>
                    <div className="p-field p-col-12">
                        <label htmlFor="email">Email *</label>
                        <Dropdown
                            id="email"
                            name="email"
                            options={emails}
                            disabled={formik.values.perfil ? false : true}
                            optionValue="email"
                            optionLabel="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                        />
                        {formik.errors.email && <small className="p-error">{formik.errors.email}</small>}
                    </div>
                    <div className="p-field p-col-12">
                        <label htmlFor="username">Nome de usuário *</label>
                        <InputText
                            id="username"
                            name="username"
                            disabled={formik.values.perfil ? false : true}
                            autoComplete="off"
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.username })}
                        />
                        {formik.errors.username && <small className="p-error">{formik.errors.username}</small>}
                    </div>
                    <div className="p-field p-col-12">
                        <label htmlFor="password">Senha *</label>
                        <Password
                            id="password"
                            name="password"
                            toggleMask
                            mediumRegex
                            disabled={formik.values.perfil ? false : true}
                            promptLabel="Informe a senha"
                            weakLabel="Fraca"
                            mediumLabel="Média"
                            strongLabel="Forte"
                            autoComplete="off"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.password })}
                        />
                        {formik.errors.password && <small className="p-error">{formik.errors.password}</small>}
                    </div>
                    <div className="p-field p-col-12">
                        <label htmlFor="confirmpass">Confirmar senha *</label>
                        <Password
                            id="confirmpass"
                            name="confirmpass"
                            feedback={false}
                            disabled={formik.values.perfil ? false : true}
                            autoComplete="off"
                            value={formik.values.confirmpass}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.confirmpass })}
                        />
                        {formik.errors.confirmpass && <small className="p-error">{formik.errors.confirmpass}</small>}
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-md-2">
                        <Button type="submit" icon="pi pi-plus" label="Criar" />
                    </div>
                </div>
            </form>
        </Dialog>
    );
};

export const ModalCadastroUsuario = forwardRef(Modal);
