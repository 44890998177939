import React, { useRef, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { MakoSelecionarEmails } from "@/components/MakoSelecionarEmails";
import { MakoInputFornecedor } from "@/components/MakoInputs/MakoInputFornecedor";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";

import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import useRelatorio from "@/hooks/useRelatorio";
import useClearRefs from "@/hooks/useClearRefs";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIO_FINANCEIRO_CONTASAPAGAR } from "@/assets/constants/relatorios";
import { TIPO_FORMAS_PAGAMENTO_CHOICE } from "@/assets/constants/constants";
import { dataToStr } from "@/assets/util/datas";

export const RelatorioAPagar = () => {
    const [visible, setVisible] = useState(false);
    const [emails, setEmails] = useState([]);

    const empresaSelecionada = useRef();

    useClearRefs(empresaSelecionada);

    const { solicitarRelatorio } = useRelatorio();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            periodo_emissao_start: null,
            periodo_emissao_end: null,
            periodo_vencimento_start: null,
            periodo_vencimento_end: null,
            empresa: null,
            fornec: "",
            faixa_valor_start: 0,
            faixa_valor_end: 0,
            forma_pgto: null,
            parcela: null,
            codigo: "",
            orderBy: "",
        },
        onSubmit: handleSubmit,
    });

    const ORDENACAO_FILTROS = [
        { value: "emissao", label: "Emissão (crescente)" },
        { value: "-emissao", label: "Emissão (decrescente)" },
        { value: "empresa", label: "Empresa" },
        { value: "credor", label: "Fornecedor" },
        { value: "forma_pagamento", label: "Forma Pagamento" },
        { value: "parcela", label: "Parcela" },
        { value: "valor", label: "Valor (crescente)" },
        { value: "-valor", label: "Valor (decrescente)" },
    ];

    const filtrosTemplate = [
        {
            key: "empresa",
            label: "Empresa",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: ["periodo_emissao_start", "periodo_emissao_end"],
            label: "Período emissão",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: ["periodo_vencimento_start", "periodo_vencimento_end"],
            label: "Período vencimento",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: "fornec",
            label: "Fornecedor",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: "forma_pgto",
            label: "Forma Pgto",
            optionKey: "id",
            optionLabel: "label",
            options: TIPO_FORMAS_PAGAMENTO_CHOICE,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "codigo",
            label: "Código",
        },
        {
            key: "parcela",
            label: "Parcela",
        },
        {
            key: ["faixa_valor_start", "faixa_valor_end"],
            label: "Faixa valor",
            type: TIPOS_FILTROS_TEMPLATE.MONEYPERIODO,
        },
        {
            key: "orderBy",
            label: "Ordenado por",
            optionKey: "value",
            optionLabel: "label",
            options: ORDENACAO_FILTROS,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
    ];

    async function handleSubmit(values = null) {
        try {
            const formSchema = Yup.object().shape({
                periodo_emissao_start: Yup.date()
                    .required("O campo 'periodo de emissao inicial' é obrigatório")
                    .typeError("Informe um 'periodo de emissão inicial' válido"),
                periodo_emissao_end: Yup.date()
                    .when("periodo_emissao_start", {
                        is: (val) => !!val,
                        then: Yup.date()
                            .min(
                                values.periodo_emissao_start || new Date(),
                                "A 'data de emissao final' não pode ser anterior a inicial"
                            )

                            .typeError("Informe um 'periodo de emissao final' válido"),
                        otherwise: Yup.date().nullable(),
                    })
                    .required("O campo 'periodo de emissao final' é obrigatório"),
                periodo_vencimento_start: Yup.date()
                    .nullable()
                    .typeError("Informe um 'periodo de vencimento inicial' válido"),
                periodo_vencimento_end: Yup.date()
                    .when("periodo_vencimento_start", {
                        is: (val) => !!val,
                        then: Yup.date()
                            .min(
                                values.periodo_vencimento_start || new Date(),
                                "A 'data de vencimento final' não pode ser anterior a inicial"
                            )
                            .typeError("Informe um 'periodo de vencimento final' válido"),
                        otherwise: Yup.date().nullable(),
                    })
                    .nullable()
                    .typeError("Informe um 'periodo de vencimento final' válido"),
                empresa: Yup.number()
                    .required("O campo 'empresa' é obrigatório")
                    .typeError("Informe uma 'empresa' válida"),
                fornec: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number(),
                    })
                    .typeError("Informe um 'fornecedor' válida."),
                faixa_valor_start: Yup.number().nullable().typeError("Informe uma 'faixa de valor inicial' válida"),
                faixa_valor_end: Yup.number()
                    .when("faixa_valor_start", {
                        is: (val) => val != null,
                        then: Yup.number()
                            .test(
                                "valor validation",
                                "A 'faixa de valor final' não pode ser menor que a inicial",
                                (val) => {
                                    if (val == null) return true;

                                    return typeof val === "number" && val >= values.faixa_valor_start;
                                }
                            )
                            .typeError("Informe um 'faixa de valor final' válido"),
                    })
                    .nullable()
                    .typeError("Informe uma 'faixa de valor final' válida"),
                forma_pgto: Yup.string().nullable().typeError("Informe uma 'forma de pagamento' válida"),
                parcela: Yup.number()
                    .nullable()
                    .test("parcela validation", "A 'parcela' deve ser maior que 0", (val) => {
                        if (val == null) return true;

                        return typeof val === "number" && val > 0;
                    })
                    .typeError("Informe uma 'quantidade de parcela' válida"),
                codigo: Yup.string()
                    .nullable()
                    .test("codigo validation", "O 'codigo' deve ser maior que 0", (val) => {
                        if (val?.length >= 1 && parseInt(val) > 0) return true;
                        return !val;
                    })
                    .typeError("Informe um 'código' válido"),
                orderBy: Yup.string().nullable().typeError("Seleciona uma 'ordenação' válida."),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                empresa: dadosValidados?.empresa ? empresaSelecionada.current : null,
                fornec: dadosValidados?.fornec?.id ? dadosValidados?.fornec : null,
            });

            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.fornec !== null) dadosValidados.fornec = dadosValidados.fornec.id;
            if (dadosValidados.periodo_emissao_start !== null)
                dadosValidados.periodo_emissao_start = dataToStr(dadosValidados.periodo_emissao_start, "yyyy-MM-dd");
            if (dadosValidados.periodo_emissao_end !== null)
                dadosValidados.periodo_emissao_end = dataToStr(dadosValidados.periodo_emissao_end, "yyyy-MM-dd");
            if (dadosValidados.periodo_vencimento_start !== null)
                dadosValidados.periodo_vencimento_start = dataToStr(
                    dadosValidados.periodo_vencimento_start,
                    "yyyy-MM-dd"
                );
            if (dadosValidados.periodo_vencimento_end !== null)
                dadosValidados.periodo_vencimento_end = dataToStr(dadosValidados.periodo_vencimento_end, "yyyy-MM-dd");

            let filtros = {};
            if (dadosValidados) {
                Object.keys(dadosValidados).forEach((key) => {
                    if (dadosValidados[key]) filtros[key] = dadosValidados[key];
                });
            }
            solicitarRelatorio({
                chave: RELATORIO_FINANCEIRO_CONTASAPAGAR,
                emails,
                filtros,
                filtros_template: filtrosTemplateAplicados,
            });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const handleConfirm = (emails) => {
        fecharModal();
        if (emails && emails.length > 0) {
            handlePreSubmit(emails);
        }
    };

    const handlePreSubmit = (emails = []) => {
        setEmails(emails);
        formik.handleSubmit();
    };

    const fecharModal = () => {
        setVisible(() => false);
    };

    const limparFiltro = () => {
        formik.resetForm();
    };

    const onChangeEmpresa = (e) => {
        empresaSelecionada.current = e?.empresa;
        setValues({ empresa: e.id });
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <h5>Filtro para emissão de relatório contas a pagar</h5>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="empresa">Empresa: *</label>
                            <MakoDropdownEmpresas
                                id="empresa"
                                name="empresa"
                                placeholder="Selecione uma empresa"
                                value={formik.values.empresa}
                                onChange={(e) => onChangeEmpresa(e)}
                                className={classNames({ "p-invalid": formik.errors.empresa })}
                            />
                            {formik.errors.empresa && <small className="p-error">{formik.errors.empresa}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="periodo_emissao_start">Periodo emissão: *</label>
                            <MakoCalendar
                                id="periodo_emissao_start"
                                name="periodo_emissao_start"
                                valueCalendar={formik.values.periodo_emissao_start}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.periodo_emissao_start })}
                            />
                            {formik.errors.periodo_emissao_start && (
                                <small className="p-error">{formik.errors.periodo_emissao_start}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2 p-mt-4" style={{ paddingTop: "5px" }}>
                            <MakoCalendar
                                id="periodo_emissao_end"
                                name="periodo_emissao_end"
                                valueCalendar={formik.values.periodo_emissao_end}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.periodo_emissao_end })}
                            />
                            {formik.errors.periodo_emissao_end && (
                                <small className="p-error">{formik.errors.periodo_emissao_end}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="periodo_vencimento_start">Periodo vencimento:</label>
                            <MakoCalendar
                                id="periodo_vencimento_start"
                                name="periodo_vencimento_start"
                                valueCalendar={formik.values.periodo_vencimento_start}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.periodo_vencimento_start })}
                            />
                            {formik.errors.periodo_vencimento_start && (
                                <small className="p-error">{formik.errors.periodo_vencimento_start}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2 p-mt-4" style={{ paddingTop: "5px" }}>
                            <MakoCalendar
                                id="periodo_vencimento_end"
                                name="periodo_vencimento_end"
                                valueCalendar={formik.values.periodo_vencimento_end}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.periodo_vencimento_end })}
                            />
                            {formik.errors.periodo_vencimento_end && (
                                <small className="p-error">{formik.errors.periodo_vencimento_end}</small>
                            )}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-mb-4">
                            <label htmlFor="fornec">Fornecedor:</label>
                            <MakoInputFornecedor
                                id="fornec"
                                name="fornec"
                                value={formik.values.fornec}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.fornec })}
                            />
                            {formik.errors.fornec && <small className="p-error">{formik.errors.fornec}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="codigo">Código:</label>
                            <InputText
                                id="codigo"
                                name="codigo"
                                value={formik.values.codigo}
                                onInput={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.codigo })}
                            />
                            {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="parcela">Parcela:</label>
                            <InputNumber
                                id="parcela"
                                name="parcela"
                                allowEmpty
                                min={0}
                                mode="decimal"
                                useGrouping={false}
                                value={formik.values.parcela}
                                onValueChange={formik.handleChange}
                            />
                            {formik.errors.parcela && <small className="p-error">{formik.errors.parcela}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="forma_pgto">Forma de pagamento:</label>
                            <Dropdown
                                id="forma_pgto"
                                name="forma_pgto"
                                placeholder="Selecione uma forma de pagamento"
                                filter
                                filterBy="label"
                                optionLabel="label"
                                optionValue="id"
                                options={TIPO_FORMAS_PAGAMENTO_CHOICE}
                                value={formik.values.forma_pgto}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.forma_pgto })}
                            />
                            {formik.errors.forma_pgto && <small className="p-error">{formik.errors.forma_pgto}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-1">
                            <label htmlFor="faixa_valor_start">Faixa de valor:</label>
                            <MakoInputMoeda
                                id="faixa_valor_start"
                                name="faixa_valor_start"
                                valueMoeda={formik.values.faixa_valor_start}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.faixa_valor_start })}
                            />
                            {formik.errors.faixa_valor_start && (
                                <small className="p-error">{formik.errors.faixa_valor_start}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-1 p-mt-4" style={{ paddingTop: "5px" }}>
                            <MakoInputMoeda
                                id="faixa_valor_end"
                                name="faixa_valor_end"
                                valueMoeda={formik.values.faixa_valor_end}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.faixa_valor_end })}
                            />
                            {formik.errors.faixa_valor_end && (
                                <small className="p-error">{formik.errors.faixa_valor_end}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="order-by">Ordenado por:</label>
                            <Dropdown
                                id="orderBy"
                                name="orderBy"
                                placeholder="Selecione uma forma de pagamento"
                                filter
                                filterBy="label"
                                optionLabel="label"
                                optionValue="value"
                                options={ORDENACAO_FILTROS}
                                value={formik.values.orderBy}
                                onChange={formik.handleChange}
                            />
                            {formik.errors.orderBy && <small className="p-error">{formik.errors.orderBy}</small>}
                        </div>
                    </div>
                    <div className="p-grid p-col-12 p-md-6 p-mt-2">
                        <Button
                            label="Gerar PDF"
                            icon="pi pi-file-pdf"
                            type="button"
                            onClick={() => handlePreSubmit()}
                            className="p-button-info p-mr-2"
                        />
                        <Button
                            type="button"
                            onClick={() => setVisible(true)}
                            label="Enviar por email"
                            icon="pi pi-envelope"
                            className="p-button-info p-mr-2"
                        />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            onClick={limparFiltro}
                            className="p-button-warning p-mr-2"
                        />
                    </div>
                </div>
            </div>
            <Dialog
                visible={visible}
                blockScroll
                onHide={fecharModal}
                style={{ width: "60vw" }}
                header="Informar emails"
            >
                <MakoSelecionarEmails onCancel={fecharModal} onConfirm={handleConfirm} />
            </Dialog>
        </div>
    );
};
