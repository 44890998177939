import React from "react";

import { MakoListagem } from "@/components/MakoListagem";
import useRecebimento from "@/hooks/useRecebimento";

export const RecebimentoPrevisaoForm = () => {
    const {
        recebimentos: [recebimento],
    } = useRecebimento();

    const colunas = [
        { field: "data_prevista", header: "Data prevista", style: { width: "10%" }, dateFormat: "dd/MM/yyyy" },
        { field: "valor_previsto", header: "Valor previsto", style: { width: "10%" }, money: true },
        { field: "plano_orcamentario.descricao", header: "Plano orçamentário" },
    ];

    return (
        <MakoListagem
            urlPesquisa={`/financeiro/previsoes-recebimentos/?recebimento__agrupador=${recebimento?.agrupador}`}
            colunas={colunas}
        />
    );
};
