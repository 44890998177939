import React from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "@/components/Dropdown";
import { MakoCalendar } from "@/components/MakoCalendar";
import { InputNumber } from "primereact/inputnumber";
import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import { TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE } from "@/assets/constants/financeiro";
import { DJANGO_FILTERS_MATCHMODE } from "@/assets/constants/constants";
import { totalizadorFiltrosAplicados } from "@/assets/util/util";

const INITIAL_VALUES = {
    id: null,
    credor: null,
    historico: "",
    documento: "",
    valor_minimo: null,
    valor_maximo: null,
    lancamento_inicial: null,
    lancamento_final: null,
    emissao_inicial: null,
    emissao_final: null,
    vencimento_inicial: null,
    vencimento_final: null,
    liquidacao_inicial: null,
    liquidacao_final: null,
    conta_financeira: null,
    forma_pagamento: null,
    competencia: null,
};

export const FiltroPagamentosModalForm = (props) => {
    const [formatarDocumento] = useFormatCNPJCPF();

    const { setFieldValue, ...formik } = useFormik({
        initialValues: INITIAL_VALUES,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                id: Yup.number().nullable().default(null),
                credor: Yup.number().nullable().default(null),
                historico: Yup.string().nullable().default(""),
                documento: Yup.string().nullable().default(""),
                valor_minimo: Yup.number().nullable().default(null),
                valor_maximo: Yup.number().nullable().default(null),
                lancamento_inicial: Yup.date().nullable().typeError("Informe uma data válida."),
                lancamento_final: Yup.date().nullable().typeError("Informe uma data válida."),
                emissao_inicial: Yup.date().nullable().typeError("Informe uma data válida."),
                emissao_final: Yup.date().nullable().typeError("Informe uma data válida."),
                vencimento_inicial: Yup.date().nullable().typeError("Informe uma data válida."),
                vencimento_final: Yup.date().nullable().typeError("Informe uma data válida."),
                liquidacao_inicial: Yup.date().nullable().typeError("Informe uma data válida."),
                liquidacao_final: Yup.date().nullable().typeError("Informe uma data válida."),
                conta_financeira: Yup.number().nullable().default(null),
                forma_pagamento: Yup.number().nullable().default(null),
                competencia: Yup.number().nullable().default(null),
            });

            await formSchema.validate(values, { abortEarly: false });

            const matchModes = {
                id: DJANGO_FILTERS_MATCHMODE.contains,
                credor: DJANGO_FILTERS_MATCHMODE.equals,
                historico: DJANGO_FILTERS_MATCHMODE.icontains,
                documento: DJANGO_FILTERS_MATCHMODE.icontains,
                valor_minimo: DJANGO_FILTERS_MATCHMODE.gte,
                valor_maximo: DJANGO_FILTERS_MATCHMODE.lte,
                lancamento_inicial: DJANGO_FILTERS_MATCHMODE.gte,
                lancamento_final: DJANGO_FILTERS_MATCHMODE.lte,
                emissao_inicial: DJANGO_FILTERS_MATCHMODE.gte,
                emissao_final: DJANGO_FILTERS_MATCHMODE.lte,
                vencimento_inicial: DJANGO_FILTERS_MATCHMODE.gte,
                vencimento_final: DJANGO_FILTERS_MATCHMODE.lte,
                liquidacao_inicial: DJANGO_FILTERS_MATCHMODE.gte,
                liquidacao_final: DJANGO_FILTERS_MATCHMODE.lte,
                conta_financeira: DJANGO_FILTERS_MATCHMODE.equals,
                forma_pagamento: DJANGO_FILTERS_MATCHMODE.equals,
                competencia: DJANGO_FILTERS_MATCHMODE.equals,
            };

            let params = [];

            Object.keys(values).map((key) => {
                if (values[key] !== null && values[key] !== "") {
                    if (key === "credor") {
                        return params.push(`${key}${matchModes[key]}=${values[key].id}`);
                    }

                    return typeof matchModes[key] != "undefined"
                        ? params.push(`${key}${matchModes[key]}=${values[key]}`)
                        : params.push(`${key}=${values[key]}`);
                }
            });
            if (params.length > 0)
                props.setUrl(
                    `/financeiro/pagamentos?query={id,empresa,documento,numero_parcela,data_emissao,valor,credor,vencimento}&${params.join(
                        "&"
                    )}`,
                    totalizadorFiltrosAplicados(INITIAL_VALUES, values)
                );

            props.setExibirDialog(false);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const autoCompleteCredorTemplate = (item) => {
        return <div>{`${item.nome} - ${formatarDocumento(item.identificacao)}`}</div>;
    };

    const autoCompleteHistoricoTemplate = (item) => {
        return <div>{`${item.descricao}`}</div>;
    };

    return (
        <Dialog
            header="Filtro avançado"
            visible={props.exibirDialog}
            style={{ width: "65vw", display: "block" }}
            onHide={() => props.setExibirDialog(false)}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="credor">Credor *</label>
                        <MakoAutoComplete
                            id="credor"
                            name="credor"
                            value={formik.values.credor}
                            onChange={formik.handleChange}
                            minCaracteresBusca={4}
                            itemTemplate={autoCompleteCredorTemplate}
                            field="nome"
                            urlSearch={`/pessoas/perfis?query={id,nome,identificacao}&nome__unaccent__icontains=`}
                            placeholder="Comece a digitar o nome do credor para buscar... (min 4 caracteres)"
                            className={classNames({ "p-invalid": formik.errors.credor })}
                        />
                        {formik.errors.credor && <small className="p-error">{formik.errors.credor}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="historico">Histórico</label>
                        <MakoAutoComplete
                            id="historico_padrao"
                            name="historico_padrao"
                            value={formik.values.historico_padrao}
                            onChange={formik.handleChange}
                            field="descricao"
                            minCaracteresBusca={4}
                            itemTemplate={autoCompleteHistoricoTemplate}
                            urlSearch="/financeiro/historico-padrao?tipo=P&descricao_icontains="
                            placeholder="Digite para começar a buscar... (min 4 caracteres)"
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="id">Pagamento</label>
                        <InputText
                            id="id"
                            name="id"
                            placeholder="Digite o código do pagamento"
                            value={formik.values.id}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="documento">Documento</label>
                        <InputText
                            id="documento"
                            name="documento"
                            placeholder="Digite um documento"
                            value={formik.values.documento}
                            onChange={formik.handleChange}
                            autoFocus
                        />
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                        <label htmlFor="valor_minimo">Valor mínimo</label>
                        <InputNumber
                            id="valor_minimo"
                            name="valor_minimo"
                            min={0}
                            mode="currency"
                            currency="BRL"
                            onValueChange={formik.handleChange}
                            value={formik.values.valor_minimo}
                        />
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                        <label htmlFor="valor_maximo">Valor máximo</label>
                        <InputNumber
                            id="valor_maximo"
                            name="valor_maximo"
                            min={0}
                            mode="currency"
                            currency="BRL"
                            onValueChange={formik.handleChange}
                            value={formik.values.valor_maximo}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-6 p-md-3">
                        <label htmlFor="lancamento_inicial">Lançamento inicial</label>
                        <MakoCalendar
                            id="lancamento_inicial"
                            name="lancamento_inicial"
                            valueCalendar={formik.values.lancamento_inicial}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                        <label htmlFor="lancamento_final">Lançamento final</label>
                        <MakoCalendar
                            id="lancamento_final"
                            name="lancamento_final"
                            valueCalendar={formik.values.lancamento_final}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                        <label htmlFor="emissao_inicial">Emissão inicial</label>
                        <MakoCalendar
                            id="emissao_inicial"
                            name="emissao_inicial"
                            valueCalendar={formik.values.emissao_inicial}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                        <label htmlFor="emissao_final">Emissão final</label>
                        <MakoCalendar
                            id="emissao_final"
                            name="emissao_final"
                            valueCalendar={formik.values.emissao_final}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-6 p-md-3">
                        <label htmlFor="vencimento_inicial">Vencimento inicial</label>
                        <MakoCalendar
                            id="vencimento_inicial"
                            name="vencimento_inicial"
                            valueCalendar={formik.values.vencimento_inicial}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                        <label htmlFor="vencimento_final">Vencimento final</label>
                        <MakoCalendar
                            id="vencimento_final"
                            name="vencimento_final"
                            valueCalendar={formik.values.vencimento_final}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                        <label htmlFor="liquidacao_inicial">Liquidação inicial</label>
                        <MakoCalendar
                            id="liquidacao_inicial"
                            name="liquidacao_inicial"
                            valueCalendar={formik.values.liquidacao_inicial}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                        <label htmlFor="liquidacao_final">Liquidação final</label>
                        <MakoCalendar
                            id="liquidacao_final"
                            name="liquidacao_final"
                            valueCalendar={formik.values.liquidacao_final}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>

                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="conta-financeira">Conta financeira</label>
                        <Dropdown
                            id="conta-financeira"
                            name="conta_financeira"
                            url="/financeiro/contas-financeiras/?query={id,descricao}"
                            placeholder="Selecione uma conta financeira"
                            emptyMessage="Não existem contas cadastradas."
                            showClear
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.conta_financeira}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="forma_pagamento">Forma de pagamento </label>
                        <Dropdown
                            id="forma_pagamento"
                            name="forma_pagamento"
                            placeholder="Selecione o pagamento"
                            options={TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.forma_pagamento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.forma_pagamento })}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="competencia">Competência</label>
                        <Dropdown
                            id="competencia"
                            name="competencia"
                            placeholder="Selecione uma competencia"
                            url={"/financeiro/competencias?limit=1000"}
                            filter
                            filterBy="grupo_competencia"
                            showClear
                            optionValue="id"
                            optionLabel="grupo_competencia"
                            emptyMessage="Não existem registros cadastrados"
                            value={formik.values.competencia}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.competencia })}
                        />
                    </div>
                </div>
                <br />
                <div className="p-grid p-justify-end">
                    <Button type="submit" icon="pi pi-filter" label="Filtrar" className="p-mr-2" />
                    <Button type="reset" icon="pi pi-trash" label="Limpar" className="p-button-warning p-mr-2 " />
                    <Button
                        type="reset"
                        label="Cancelar"
                        onClick={() => props.setExibirDialog(false)}
                        className="p-button-danger p-mr-3"
                    />
                </div>
            </form>
        </Dialog>
    );
};
