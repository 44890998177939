import { VinculacaoPessoasPage } from "@/pages/Gestao/Empresas/VinculacaoPessoas";
import { GestaoGerenciarUsuariosPage } from "@/pages/Gestao/Usuarios/GerenciarUsuarios";
import { GestaoGerenciarUsuariosForm } from "@/pages/Gestao/Usuarios/GerenciarUsuarios/form";
import { PessoasPapeisPerfisPage } from "@/pages/Gestao/Usuarios/PapeisPerfis";
import { PessoasPapeisPerfisForm } from "@/pages/Gestao/Usuarios/PapeisPerfis/form";
import { PessoasPage } from "@/pages/Gestao/Pessoas";
import { TabPessoasContext } from "@/pages/Gestao/Pessoas/form";
import { RelatorioPessoasPage } from "@/pages/Gestao/Relatorios/RelacaoPessoas/index";
import { PessoasTagsPerfisPage } from "@/pages/Gestao/Cadastros/TagsPerfis";
import { PessoasTagsPerfisForm } from "@/pages/Gestao/Cadastros/TagsPerfis/form";
import { RedesSociaisPage } from "@/pages/Gestao/Cadastros/RedesSociais";
import { RedesSociaisForm } from "@/pages/Gestao/Cadastros/RedesSociais/form";
import { TokensIntegracaoPage } from "@/pages/Gestao/Usuarios/TokensIntegracao";
import { TokensIntegracaoForm } from "@/pages/Gestao/Usuarios/TokensIntegracao/form";
import { PessoasTagsEnderecoPage } from "@/pages/Gestao/Cadastros/TagsEndereco";
import { PessoasTagsEnderecoForm } from "@/pages/Gestao/Cadastros/TagsEndereco/form";
import { VinculoRepresentanteFornecedorPage } from "@/pages/Gestao/Cadastros/VinculoRepresentateFornecedor";
import { VinculoRepresentateFornecedorForm } from "@/pages/Gestao/Cadastros/VinculoRepresentateFornecedor/form";
import { VeiculoEntregaPage } from "@/pages/Gestao/Cadastros/VeiculoEntrega";
import { VeiculoEntregaForm } from "@/pages/Gestao/Cadastros/VeiculoEntrega/form";
import { VinculoMotoristaVeiculoPage } from "@/pages/Gestao/Cadastros/VinculoMotoristaVeiculo";
import { VinculoMotoristaVeiculoForm } from "@/pages/Gestao/Cadastros/VinculoMotoristaVeiculo/form";
import { HierarquiaFuncionalPage } from "@/pages/Gestao/Cadastros/HierarquiaFuncional";
import { HierarquiaFuncionalForm } from "@/pages/Gestao/Cadastros/HierarquiaFuncional/form";
import { CargosPage } from "@/pages/Gestao/Cadastros/Cargo";
import { CargosForm } from "@/pages/Gestao/Cadastros/Cargo/form";

export const gestaoRoutes = [
    { path: "/gestao/empresas/vinculacao-pessoas", component: VinculacaoPessoasPage },
    { path: "/gestao/pessoas/pessoas", component: PessoasPage },
    { path: "/gestao/pessoas/pessoas/form", component: TabPessoasContext },
    { path: "/gestao/usuarios/papeis-perfis", component: PessoasPapeisPerfisPage },
    { path: "/gestao/usuarios/papeis-perfis/form", component: PessoasPapeisPerfisForm },
    { path: "/gestao/usuarios/gerenciar-usuarios", component: GestaoGerenciarUsuariosPage },
    { path: "/gestao/usuarios/gerenciar-usuarios/form", component: GestaoGerenciarUsuariosForm },
    { path: "/gestao/usuarios/tokens", component: TokensIntegracaoPage },
    { path: "/gestao/usuarios/tokens/form", component: TokensIntegracaoForm },
    { path: "/gestao/relatorios/relacoes-pessoas", component: RelatorioPessoasPage },
    { path: "/gestao/cadastros/tags-enderecos", component: PessoasTagsEnderecoPage },
    { path: "/gestao/cadastros/tags-enderecos/form", component: PessoasTagsEnderecoForm },
    { path: "/gestao/cadastros/tags-perfis", component: PessoasTagsPerfisPage },
    { path: "/gestao/cadastros/tags-perfis/form", component: PessoasTagsPerfisForm },
    { path: "/gestao/cadastros/redes-sociais", component: RedesSociaisPage },
    { path: "/gestao/cadastros/redes-sociais/form", component: RedesSociaisForm },
    { path: "/gestao/cadastros/veiculo-entrega", component: VeiculoEntregaPage },
    { path: "/gestao/cadastros/veiculo-entrega/form", component: VeiculoEntregaForm },
    { path: "/gestao/cadastros/vinculo-motorista-veiculo", component: VinculoMotoristaVeiculoPage },
    { path: "/gestao/cadastros/vinculo-motorista-veiculo/form", component: VinculoMotoristaVeiculoForm },
    { path: "/gestao/cadastros/vinculo-representante", component: VinculoRepresentanteFornecedorPage },
    { path: "/gestao/cadastros/vinculo-representante/form", component: VinculoRepresentateFornecedorForm },
    { path: "/gestao/cadastros/hierarquia-funcional", component: HierarquiaFuncionalPage },
    { path: "/gestao/cadastros/hierarquia-funcional/form", component: HierarquiaFuncionalForm },
    { path: "/gestao/cadastros/cargos", component: CargosPage },
    { path: "/gestao/cadastros/cargos/form", component: CargosForm },
];
