import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";

import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import { axiosDelete } from "@/services/http";
import useClearRefs from "@/hooks/useClearRefs";
import useToast from "@/hooks/useToast";
import {
    ContaFinanceiraFiltroTemplate,
    DropdownFiltroTemplate,
    MoedaFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";

const url = "/financeiro/contas-bancarias/";

const opcoesPesquisa = [
    { id: "todos", label: "Todos", value: "" },
    { id: "ativos", label: "Ativos", value: "true" },
    { id: "inativos", label: "Inativos", value: "false" },
];

export const FinanceiroContaBancariaPage = () => {
    const [redirecionar, setRedirecionar] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [filtro, setFiltro] = useState("");

    const [contaBancaria, setContaBancaria] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const listagemRef = useRef(null);

    useClearRefs(listagemRef);

    const confirmarDelete = (contaBancaria) => {
        setContaBancaria(contaBancaria);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const deleteParametro = async () => {
        showLoading();
        const resposta = await axiosDelete(`${url}${contaBancaria.id}`);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            showSuccess({
                summary: "Sucesso",
                detail: "Conta bancária deletada com sucesso!",
                life: 3000,
            });

            listagemRef.current?.buscarDados();
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
        setDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTRO_CONTABANCARIA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro da conta bancária"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        setRedirecionar({
                            pathname: "/financeiro/cadastros/contas-bancarias/form",
                            state: { contaBancaria: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTRO_CONTABANCARIA_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Deletar conta bancária"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const filtroStatus = (status) => {
        listagemRef.current.filter(status.value, "ativa", "contains");
        setFiltro(status.value);
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_CADASTRO_CONTABANCARIA_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setRedirecionar("/financeiro/cadastros/contas-bancarias/form")}
            />
            <Dropdown
                id="opcoesPesquisa"
                name="opcoesPesquisa"
                style={{ width: "120px" }}
                value={filtro}
                options={opcoesPesquisa}
                optionLabel="label"
                placeholder={!filtro ? ("" ? filtro : "Todos") : filtro ? "Ativos" : false ? filtro : "Inativos"}
                onChange={filtroStatus}
            />
        </>
    );

    const AgenciaFiltroTemplate = (props) => {
        return <TextoFiltroTemplate keyfilter={/^[0-9]/} maxLength={8} {...props} />;
    };

    const NumeroContaFiltroTemplate = (props) => {
        return <TextoFiltroTemplate keyfilter={/^[0-9]/} maxLength={1} {...props} />;
    };

    const VariacaoFiltroTemplate = (props) => {
        return <TextoFiltroTemplate keyfilter={/^[0-9]/} maxLength={3} {...props} />;
    };

    const BancoFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "id",
                    optionLabel: "descricao",
                    url: "/financeiro/bancos?limit=300",
                }}
                options={options}
            />
        );
    };

    const colunas = [
        { field: "agencia", header: "Agência", filter: true, filterElement: AgenciaFiltroTemplate },
        //{ field: "agencia_dv", header: "Agência DV" },
        { field: "numero_conta", header: "Número da conta", filter: true, filterElement: NumeroContaFiltroTemplate },
        //{ field: "numero_conta_dv", header: "Número da conta DV" },
        { field: "variacao", header: "Variação", filter: true, filterElement: VariacaoFiltroTemplate },
        { field: "limite", header: "Limite", filter: true, filterElement: MoedaFiltroTemplate },
        {
            field: "conta_financeira.descricao",
            header: "Conta financeira",
            style: { width: "20%" },
            filter: true,
            filterElement: ContaFinanceiraFiltroTemplate,
            filterField: "conta_financeira",
        },
        {
            field: "banco.descricao",
            header: "Banco",
            filter: true,
            filterElement: BancoFiltroTemplate,
            filterField: "banco",
        },
        { field: "ativa", style: { width: "0" } },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    return redirecionar ? (
        <Redirect to={redirecionar} />
    ) : (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Contas Bancárias"
                        colunas={colunas}
                        painelEsquerdo={cabecalhoTabela}
                        urlPesquisa={url}
                        responsiva
                        configTabela={{
                            paginator: true,
                            lazy: true,
                        }}
                        filtros={{
                            agencia: { value: null, matchMode: "equals" },
                            numero_conta: { value: null, matchMode: "equals" },
                            variacao: { value: null, matchMode: "equals" },
                            limite: { value: null, matchMode: "equals" },
                            conta_financeira: { value: null, matchMode: "equals" },
                            banco: { value: null, matchMode: "equals" },
                        }}
                    />
                    <ConfirmDialog
                        visible={deleteDialog}
                        onHide={esconderDeleteDialog}
                        header="Confirmação"
                        message={
                            contaBancaria && (
                                <span>
                                    {"Deseja realmente excluir a conta bancária "}
                                    <b>{contaBancaria.descricao}</b>?
                                </span>
                            )
                        }
                        icon="pi pi-info-circle p-mr-3"
                        accept={deleteParametro}
                        acceptLabel="Sim"
                        acceptClassName="p-button-danger"
                        reject={esconderDeleteDialog}
                        rejectLabel="Não"
                    />
                </div>
            </div>
        </div>
    );
};
