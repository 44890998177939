import React from "react";

import { FinanceiroContaBancariaFormBase } from "./formBase";

export const FinanceiroContaBancariaForm = (props) => {
    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <FinanceiroContaBancariaFormBase contaBancaria={props.location.state?.contaBancaria} />
                </div>
            </div>
        </div>
    );
};
