import { useContext } from "react";

import DevolucaoFornecedorContext from "@/contexts/devolucaoFornecedorContext";

const useDevolucaoFornecedor = () => {
     const { 
          devolucao,
          vendas,
          selecionados,
          parcelas,
          valorTotal,
          updateDevolucao,
          updateSelecionados,
          resetSelecionados,
          resetParcelas,
          updateParcelas,
          persistirDevolucao 
     } = useContext(DevolucaoFornecedorContext);

     return {
          devolucao,
          vendas,
          selecionados,
          parcelas,
          valorTotal,
          updateDevolucao,
          updateSelecionados,
          resetSelecionados,
          resetParcelas,
          updateParcelas,
          persistirDevolucao
     };
};

export default useDevolucaoFornecedor;
