import React, { useCallback, useEffect, useState, useMemo } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { PageBase } from "@/components/PageBase";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { Dropdown } from "@/components/Dropdown";
import permissoes from "@/assets/constants/permissoes";
import { url } from "@/services/axios";
import { axiosGet, axiosPost, axiosPut } from "@/services/http";
import useAuth from "@/hooks/useAuth";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

export const TokensIntegracaoForm = (props) => {
    const [usuarios, setUsuarios] = useState([]);
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { verifyPermission } = useAuth();
    const { showSuccess, showWarning, showError } = useToast();

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: {
            usuario: null,
            token: "",
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                usuario: Yup.number().required("O campo 'usuário' é obrigatório."),
            });
            await formSchema.validate(values, { abortEarly: false });
            if (!values.id) {
                const tokenUsuario = {
                    ...values,
                    token: url(),
                };
                showLoading();
                const { status, data } = await axiosPost("/integracoes/tokens-usuario/", tokenUsuario);
                hideLoading();
                if (status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Token cadastrado com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        voltar();
                    }, 2000);
                } else if (status === 400 && data.message) {
                    showWarning({
                        summary: "Aviso",
                        detail: data.message,
                        life: 3000,
                    });
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const { status } = await axiosPut(`/integracoes/tokens-usuario/${values.id}/`, values);
                hideLoading();
                if (status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Token atualizado com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        voltar();
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const selecionaUsuario = useMemo(() => {
        const temPermissao = verifyPermission([permissoes.GESTAO_USUARIOS_TOKENINTEGRACAO_INCLUIR_TERCEIRO]);
        return temPermissao && !!formik.values.id;
    }, [verifyPermission, formik.values.id]);

    const buscarUsuarios = useCallback(async () => {
        showLoading();
        const json = await axiosGet(
            "/pessoas/perfis/?usuario__isnull=False&query={id,usuario,nome,identificacao}&limit=500"
        );
        hideLoading();

        if (json.status === 200) {
            setUsuarios(json.data.results);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos listar os usuários.",
                life: 3000,
            });
        }
    }, [showLoading, hideLoading, showError]);

    useEffect(() => {
        buscarUsuarios();
    }, [buscarUsuarios]);

    const usuarioSelecionadoTemplate = (option, props) => {
        if (option) {
            return <span>{`${option.nome} (${option.usuario.username})`}</span>;
        }

        return <span>{props.placeholder}</span>;
    };

    const opcaoUsuarioTemplate = (option) => {
        return <span>{`${option.nome} (${option.usuario.username})`}</span>;
    };

    useEffect(() => {
        if (props.location.state) {
            setValues({
                ...props.location.state,
                usuario: props.location.state.usuario.id,
            });
        }
    }, [props, setValues]);

    function voltar() {
        history.push("/gestao/usuarios/tokens");
    }

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Novo token de integração" : "Manutenção de token de integração"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="usuario">Usuário *</label>
                        <Dropdown
                            id="usuario"
                            name="usuario"
                            filter
                            filterBy="nome,usuario.username"
                            options={usuarios}
                            disabled={selecionaUsuario}
                            optionValue="id"
                            optionLabel="nome"
                            valueTemplate={usuarioSelecionadoTemplate}
                            itemTemplate={opcaoUsuarioTemplate}
                            value={formik.values.usuario}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.usuario })}
                        />
                        {formik.errors.usuario && <small className="p-error">{formik.errors.usuario}</small>}
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-1 p-mt-5">
                        <Checkbox
                            inputId="ativo"
                            name="ativo"
                            checked={formik.values.ativo}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="ativo">Ativo?</label>
                    </div>
                    <div className="p-field p-col-12 p-md-8">
                        <label htmlFor="token">Token</label>
                        <InputText id="token" name="token" disabled value={formik.values.token} />
                    </div>
                </div>
                <CamposObrigatorios />
                <div className="p-grid p-col-12 p-md-6">
                    <Button label="Gravar" icon="pi pi-save" type="submit" className="p-button-info p-mr-2" />
                    <Button
                        label="Voltar"
                        icon="pi pi-angle-double-left"
                        type="button"
                        className="p-button-danger p-mr-2"
                        onClick={voltar}
                    />
                </div>
            </form>
        </PageBase>
    );
};
