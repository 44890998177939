import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { PageBase } from "@/components/PageBase";
import { axiosPost, axiosPut } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import { Dropdown } from "@/components/Dropdown";
import { TIPO_CATEGORIA_CARTEIRA_CHOICE, TIPO_VEICULO_ENTREGA_CHOICE } from "@/assets/constants/constants";
import { InputNumber } from "primereact/inputnumber";
import { MakoCalendar } from "@/components/MakoCalendar";
import { InputTextarea } from "primereact/inputtextarea";
import { dataToStr } from "@/assets/util/datas";

export const VeiculoEntregaForm = (props) => {
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();

    const formik = useFormik({
        initialValues: props.location.state?.veiculo
            ? {
                  ...props.location.state?.veiculo,
                  tipo: props.location.state?.veiculo.tipo?.id,
                  categoria_exigida: props.location.state?.veiculo.categoria_exigida?.id,
              }
            : {
                  placa: "",
                  chassi: "",
                  renavan: "",
                  tipo: null,
                  categoria_exigida: "",
                  maximo_passageiros: null,
                  area_maxima_m3: null,
                  carga_maxima: null,
                  ano_fabricacao: null,
                  ano_modelo: null,
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                placa: Yup.string().min(7).max(7).required("O campo 'placa' é obrigatório."),
                chassi: Yup.string().min(17).max(17).required("O campo 'chassi' é obrigatório."),
                renavan: Yup.string().min(11).max(11).required("O campo 'renavan' é obrigatório."),
                tipo: Yup.number().required("O campo 'tipo de veículo' é obrigatório.").typeError("Informe um 'tipo' válido."),
                categoria_exigida: Yup.string().required("O campo 'categoria cnh' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            values.ano_fabricacao = dataToStr(values.ano_fabricacao, "yyyy-MM-dd");
            values.ano_modelo = dataToStr(values.ano_modelo, "yyyy-MM-dd");

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(`/pessoas/veiculo-entrega/`, values);
                hideLoading();

                if (resposta.status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Veículo de entrega cadastrado com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        voltarParaListagem();
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(`/pessoas/veiculo-entrega/${values.id}/`, values);
                hideLoading();

                if (resposta.status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Veículo de entrega alterado com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        voltarParaListagem();
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const voltarParaListagem = () => {
        history.push("/gestao/cadastros/veiculo-entrega");
    };

    return (
        <PageBase>
            {<h3>{!formik.values.id ? "Novo Veículo de Entrega" : "Manutenção de Veículo de Entrega"}</h3>}
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="placa">Placa *</label>
                        <InputText
                            id="placa"
                            name="placa"
                            value={formik.values.placa}
                            onChange={formik.handleChange}
                            placeholder="Ex: AAA1111"
                            className={classNames({ "p-invalid": formik.errors.placa })}
                        />
                        {formik.errors.placa && <small className="p-error">{formik.errors.placa}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="chassi">Chassi *</label>
                        <InputText
                            id="chassi"
                            name="chassi"
                            placeholder="Ex: 8aE AnTnpA j2 V28918"
                            value={formik.values.chassi}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.chassi })}
                        />
                        {formik.errors.chassi && <small className="p-error">{formik.errors.chassi}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="renavan">Renavan *</label>
                        <InputText
                            id="renavan"
                            name="renavan"
                            placeholder="Ex: 96606352648"
                            value={formik.values.renavan}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.renavan })}
                        />
                        {formik.errors.renavan && <small className="p-error">{formik.errors.renavan}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="tipo">Tipo de veículo *</label>
                        <Dropdown
                            id="tipo"
                            name="tipo"
                            placeholder="Selecione"
                            options={TIPO_VEICULO_ENTREGA_CHOICE}
                            optionValue="value"
                            optionLabel="label"
                            onChange={formik.handleChange}
                            value={formik.values.tipo}
                        />
                        {formik.errors.tipo && <small className="p-error">{formik.errors.tipo}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="categoria_exigida">Categoria CNH *</label>
                        <Dropdown
                            id="categoria_exigida"
                            name="categoria_exigida"
                            placeholder="Selecione"
                            options={TIPO_CATEGORIA_CARTEIRA_CHOICE}
                            optionValue="value"
                            optionLabel="label"
                            onChange={formik.handleChange}
                            value={formik.values.categoria_exigida}
                            className={classNames({
                                "p-invalid": formik.errors.categoria_exigida,
                            })}
                        />
                        {formik.errors.categoria_exigida && (
                            <small className="p-error">{formik.errors.categoria_exigida}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="maximo_passageiros">Máximo de Passageiros</label>
                        <InputNumber
                            inputId="maximo_passageiros"
                            name="maximo_passageiros"
                            value={formik.values.maximo_passageiros}
                            onValueChange={formik.handleChange}
                            showButtons
                            min={0}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="carga_maxima">Carga máxima</label>
                        <InputNumber
                            inputId="carga_maxima"
                            name="carga_maxima"
                            value={formik.values.carga_maxima}
                            onValueChange={formik.handleChange}
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            suffix="kg"
                            min={0}
                        />
                        {formik.errors.carga_maxima && <small className="p-error">{formik.errors.carga_maxima}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="area_maxima_m3">Área máxima em m3</label>
                        <InputNumber
                            inputId="area_maxima_m3"
                            name="area_maxima_m3"
                            value={formik.values.area_maxima_m3}
                            onValueChange={formik.handleChange}
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            suffix="m3"
                            min={0}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="ano_fabricacao">Ano fabricação</label>
                        <MakoCalendar
                            id="ano_fabricacao"
                            name="ano_fabricacao"
                            valueCalendar={formik.values.ano_fabricacao}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="ano_modelo">Ano modelo</label>
                        <MakoCalendar
                            id="ano_modelo"
                            name="ano_modelo"
                            valueCalendar={formik.values.ano_modelo}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="observacao">Observação</label>
                        <InputTextarea
                            id="observacao"
                            name="observacao"
                            value={formik.values.observacao}
                            onChange={formik.handleChange}
                            autoResize
                            rows={3}
                            maxLength={100}
                        />
                    </div>
                </div>

                <p>
                    <b>* Campos obrigatórios.</b>
                </p>
                <div className="p-grid p-col-12 p-md-6">
                    <Button icon="pi pi-save" label="Gravar" type="submit" className="p-button-info p-mr-2" />
                    <Button
                        icon="pi pi-angle-double-left"
                        label="Cancelar"
                        type="reset"
                        className="p-button-danger"
                        onClick={voltarParaListagem}
                    />
                </div>
            </form>
        </PageBase>
    );
};
