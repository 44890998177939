import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";

import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import { axiosDelete } from "@/services/http";
import useClearRefs from "@/hooks/useClearRefs";
import useToast from "@/hooks/useToast";
import { CodigoFiltroTemplate, DropdownFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { OPCOES_PESQUISA_BOOLEAN } from "@/assets/constants/constants";

const url = "/financeiro/carteiras-contas-financeiras/";

export const FinanceiroCarteiraFinanceiraPage = () => {
    const [redirecionar, setRedirecionar] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);

    const [carteiras, setCarteiras] = useState(null);
    const { showSuccess, showError } = useToast();
    const { showLoading, hideLoading } = useLoading();
    const listagemRef = useRef(null);

    useClearRefs(listagemRef);

    const confirmarDelete = (carteiras) => {
        setCarteiras(carteiras);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const deleteParametro = async () => {
        showLoading();
        const resposta = await axiosDelete(`${url}${carteiras.id}`);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            showSuccess({
                summary: "Sucesso",
                detail: "Carteira de contas financeiras deletada com sucesso!",
                life: 3000,
            });

            listagemRef.current?.buscarDados();
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
        setDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTRO_CARTEIRACONTAFINANCEIRA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de carteira de contas financeiras"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        setRedirecionar({
                            pathname: "/financeiro/cadastros/carteira-conta-financeira/form",
                            state: { carteiras: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTRO_CARTEIRACONTAFINANCEIRA_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Deletar carteira de contas financeiras"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_CADASTRO_CARTEIRACONTAFINANCEIRA_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setRedirecionar("/financeiro/cadastros/carteira-conta-financeira/form")}
            />
        </>
    );

    const statusFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: OPCOES_PESQUISA_BOOLEAN.splice(1),
                }}
                options={options}
            />
        );
    };

    const colunas = [
        {
            field: "codigo",
            header: "Código",
            style: { width: "10%" },
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "descricao",
            header: "Descrição",
            style: { width: "30%" },
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        { field: "ativo", style: { width: "0" } },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "20%" },
        },
    ];

    return redirecionar ? (
        <Redirect to={redirecionar} />
    ) : (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Carteiras de Contas Financeiras"
                        colunas={colunas}
                        painelEsquerdo={cabecalhoTabela}
                        urlPesquisa={url}
                        responsiva
                        configTabela={{
                            paginator: true,
                            lazy: true,
                        }}
                        filtros={{
                            codigo: { value: null, matchMode: "equals" },
                            descricao: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                            },
                        }}
                    />
                    <ConfirmDialog
                        visible={deleteDialog}
                        onHide={esconderDeleteDialog}
                        header="Confirmação"
                        message={
                            carteiras && (
                                <span>
                                    {"Deseja realmente excluir a carteira de contas financeiras "}
                                    <b>{carteiras.descricao}</b>?
                                </span>
                            )
                        }
                        icon="pi pi-info-circle p-mr-3"
                        accept={deleteParametro}
                        acceptLabel="Sim"
                        acceptClassName="p-button-danger"
                        reject={esconderDeleteDialog}
                        rejectLabel="Não"
                    />
                </div>
            </div>
        </div>
    );
};
