import React, { useState, useCallback } from "react";
import { Redirect } from "react-router";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";

import { LOGO_SHARKDEV } from "@/assets/constants/constants";
import { LINK_SUPORTE_SHARKDEV } from "@/assets/constants/constants";
import useLoading from "@/hooks/useLoading";
import useAuth from "@/hooks/useAuth";

export const LoginPage = () => {
    const [usuario, setUsuario] = useState("");
    const [senha, setSenha] = useState("");
    const { showLoading, hideLoading } = useLoading();
    const { signed, signIn } = useAuth();

    const fazerLogin = useCallback(async () => {
        showLoading();
        await signIn(usuario, senha);
        hideLoading();
    }, [signIn, usuario, senha, showLoading, hideLoading]);

    return signed ? (
        <Redirect to="/" />
    ) : (
        <div className="login-body">
            <div className="login-wrapper">
                <div className="login-panel">
                    <img src={LOGO_SHARKDEV} className="logo" alt="sharkdev-logo" />
                    <div className="login-form" style={{ width: "70%" }}>
                        <h2>Login</h2>
                        <InputText
                            id="username"
                            placeholder="Usuário"
                            value={usuario}
                            onChange={(e) => setUsuario(e.target.value)}
                        />
                        <Password
                            id="password"
                            placeholder="Senha"
                            promptLabel="Informe a senha"
                            weakLabel="Fraca"
                            mediumLabel="Média"
                            strongLabel="Forte"
                            feedback={false}
                            value={senha}
                            onChange={(e) => setSenha(e.target.value)}
                        />

                        <Button label="ENTRAR" type="button" onClick={fazerLogin} />
                        <p>
                            Esqueceu a senha? <a href="/recuperar-senha">Clique aqui.</a>
                        </p>
                    </div>

                    <p>
                        Algum problema?{" "}
                        <a
                            href={`${LINK_SUPORTE_SHARKDEV}${window.location.href.replace("/login", "")}`}
                            target="blank"
                        >
                            Fale com o suporte.
                        </a>
                    </p>
                </div>
                <div className="login-image">
                    <div className="login-image-content">
                        <h1>Olá,</h1>
                        <h1>seja muito bem vindo </h1>
                        <h1>ao Mako!</h1>
                    </div>
                    <div className="image-footer">
                        <p>FACEBOOK</p>
                        <p>INSTAGRAM</p>
                        <p>
                            {`${new Date().getFullYear()} - Desenvolvido por `}
                            <a href="https://sharkdev.com.br" target="blank">
                                SharkDev
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
