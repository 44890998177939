import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useFormik } from 'formik';
import classNames from "classnames";
import * as Yup from "yup";

import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { MakoInputMoeda } from '@/components/MakoInputMoeda';
import { Dropdown } from '@/components/Dropdown';

import { TIPOS_FORMA_PAGAMENTO_DEVOLUCAO_FORNECEDOR } from '@/assets/constants/constants';
import { OP_CRUD_DJANGO } from '@/assets/util/persistenciaDjango';

import useDevolucaoFornecedor from '@/hooks/useDevolucaoFornecedor';
import MakoListagem from '@/components/MakoListagem';
import useToast from '@/hooks/useToast';

import ModalParcela from './modalParcela';

const FormDadosParcela = ({ handleAbleButton }) => {
    const [visible, setVisible] = useState(false);

    const { devolucao, valorTotal, parcelas, updateParcelas, updateDevolucao } = useDevolucaoFornecedor();
    const { showError } = useToast();

    const listagemRef = useRef();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            quantidade_parcelas: null,
            forma_pagamento: null,
            gera_financeiro: false,
            valor_total_itens: 0,
            outros_valores: 0,
            info_complementares_nota: ''
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                quantidade_parcelas: Yup.number()
                    .notRequired()
                    .typeError("Informe uma 'quantidade de parcelas' válida"),
                forma_pagamento: Yup.number()
                    .notRequired()
                    .typeError("Informe uma 'forma de pagamento' válida"),
                outros_valores: Yup.number()
                    .notRequired()
                    .typeError("Informe 'outros valores' válidos"),
                info_complementares_nota: Yup.string().typeError("Informe 'informações complementares' válidas")
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            updateDevolucao(values);

            handleAbleButton(true);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, a requisição não pode ser concluída.",
                    life: 3000,
                });
            }
            handleAbleButton(false);
        }
    }

    const handleReset = useCallback(() => {
        formik.resetForm();
        handleAbleButton(false);
    }, [])

    const handleRemove = (rowData) => {
        updateParcelas(rowData, OP_CRUD_DJANGO.deletar);
    }

    const actionBodyTemplate = (rowData) => {
        return <>
            <Button
                icon="pi pi-trash"
                className="p-button-rounded p-button-danger"
                tooltip="Remover item"
                type='button'
                onClick={() => handleRemove(rowData)}
            />
        </>
    }

    const fecharModal = () => {
        setVisible(() => false);
    }

    const handleConfirm = (value) => {
        if (value.id == null && value._id == null) {
            delete value.id;
            delete value._id;

            updateParcelas(value, OP_CRUD_DJANGO.novo)
        } else {
            if (value.id == null) {
                delete value.id;
            } else {
                delete value._id;
            }

            updateParcelas(value, OP_CRUD_DJANGO.editar)
        }
        return true;
    }

    const colunas = [
        {
            field: "venda",
            header: "Cod. Venda",
            style: { width: "10%" },
        },
        {
            field: "perfil_credor",
            header: "Perfil credor",
            action: ({ perfil_credor }) => perfil_credor.label
        },
        {
            field: "numero_parcela",
            header: "N° Parcela",
        },
        {
            field: "data_vencimento",
            header: "Data Recebimento",
            dateFormat: "dd/MM/yyyy"
        },
        {
            field: "",
            header: "Ações",
            style: { width: "10%" },
            action: (rowData) => actionBodyTemplate(rowData)
        }
    ];

    const painelEsquerdo = (
        <Button
            type="button"
            icon="pi pi-plus"
            label="Informar parcelas"
            onClick={() => setVisible(true)}
            className="p-button-success p-button p-mb-2"
            disabled={parcelas.length === formik.values.quantidade_parcelas}
        />
    )

    useEffect(() => {
        if (devolucao !== null) {
            setValues({
                quantidade_parcelas: devolucao?.quantidade_parcelas,
                forma_pagamento: devolucao?.forma_pagamento,
                gera_financeiro: devolucao?.gera_financeiro,
                valor_total_itens: devolucao?.valor_total_itens,
                outros_valores: devolucao?.outros_valores,
                info_complementares_nota: devolucao?.info_complementares_nota,
            })
        }
    }, [devolucao])

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="quantidade_parcelas">Quantidade de parcelas:</label>
                        <InputNumber
                            id="quantidade_parcelas"
                            name="quantidade_parcelas"
                            value={formik.values.quantidade_parcelas}
                            onValueChange={formik.handleChange}
                            showButtons
                            max={120}
                            className={classNames({ "p-invalid": formik.errors.quantidade_parcelas })}
                        />
                        {formik.errors.quantidade_parcelas && <small className="p-error">{formik.errors.quantidade_parcelas}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="forma_pagamento">Forma de pagamento:</label>
                        <Dropdown
                            id="forma_pagamento"
                            name="forma_pagamento"
                            options={TIPOS_FORMA_PAGAMENTO_DEVOLUCAO_FORNECEDOR}
                            optionLabel="label"
                            optionValue="value"
                            value={formik.values.forma_pagamento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.forma_pagamento })}
                        />
                        {formik.errors.forma_pagamento && (
                            <small className="p-error">{formik.errors.forma_pagamento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor_total_itens">Valor total itens:</label>
                        <MakoInputMoeda
                            id="valor_total_itens"
                            name="valor_total_itens"
                            disabled
                            value={valorTotal}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor_total_itens })}
                        />
                        {formik.errors.valor_total_itens && (
                            <small className="p-error">{formik.errors.valor_total_itens}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="outros_valores">Outros valores:</label>
                        <MakoInputMoeda
                            id="outros_valores"
                            name="outros_valores"
                            value={formik.values.outros_valores}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.outros_valores })}
                        />
                        {formik.errors.outros_valores && (
                            <small className="p-error">{formik.errors.outros_valores}</small>
                        )}
                    </div>
                    <div className="p-field p-col-2 p-mt-2" style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <Checkbox
                            id="gera_financeiro"
                            name="gera_financeiro"
                            checked={formik.values.gera_financeiro}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="gera_financeiro" className="p-mt-2">Gera financeiro?</label>
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="info_complementares_nota">Informações complementares da nota: *</label>
                        <InputTextarea
                            id="info_complementares_nota"
                            name="info_complementares_nota"
                            value={formik.values.info_complementares_nota}
                            onChange={formik.handleChange}
                            autoResize
                            rows={3}
                            className={classNames({ "p-invalid": formik.errors.info_complementares_nota })}
                        />
                        {formik.errors.info_complementares_nota && (
                            <small className="p-error">{formik.errors.info_complementares_nota}</small>
                        )}
                    </div>

                </div>
                <p>
                    <b>* Campos obrigatórios</b>
                </p>
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button
                            type="submit"
                            icon="pi pi-check"
                            label="Gravar"
                            className="p-mr-2 p-mb-2"
                        />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            className="p-button-warning p-mr-2 p-mb-2"
                            onClick={handleReset}
                        />
                    </div>
                </div>
            </form>
            <div className='p-mb-4'>
                <MakoListagem
                    titulo="Parcelas informadas"
                    ref={listagemRef}
                    painelEsquerdo={painelEsquerdo}
                    colunas={colunas}
                    dadosLocal={parcelas}
                />
            </div>
            <Dialog
                visible={visible}
                onHide={fecharModal}
                style={{ width: "80vw" }}
                header="Informar parcela"
            >
                <ModalParcela fecharModal={fecharModal} onConfirm={handleConfirm} />
            </Dialog>
        </div>
    )
}

export default React.memo(FormDadosParcela);