import React from "react";
import { InputNumber } from "primereact/inputnumber";

export const MakoInputQuantidadeSku = ({
    permiteQuantidadeNegativa,
    permiteFracionario,
    value,
    onValueChange,
    min = 0,
    ...props
}) => {
    const MAX_DIGITOS_DECIMAIS = 6;

    return (
        <InputNumber
            {...props}
            mode="decimal"
            min={!!!permiteQuantidadeNegativa && min < 0 ? 0 : min}
            minFractionDigits={permiteFracionario ? MAX_DIGITOS_DECIMAIS : 0}
            maxFractionDigits={permiteFracionario ? MAX_DIGITOS_DECIMAIS : 0}
            value={value}
            onValueChange={onValueChange}
        />
    );
};
