import React, { useCallback, useEffect, useState } from "react";
import { ConfirmDialog } from "primereact/confirmdialog";
import { axiosGet, axiosPost } from "@/services/http";
import useLoading from "@/hooks/useLoading";

export const ProcessamentoComissoesForm = (props) => {
    const [confirmDialog, setConfirmDialog] = useState(true);
    const [comissoes, setComissoes] = useState([]);
    const { showLoading, hideLoading } = useLoading();

    async function handleProcessar() {
        showLoading();
        const resposta = await axiosPost(``, {});
        hideLoading();

        if (resposta.status === 200) {
            props.toast.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Comissões processadas com sucesso! Verifique o financeiro para mais informações.",
                life: 2500,
            });

            setTimeout(() => {
                setConfirmDialog(false);
            }, 2000);
        } else {
            props.toast.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 1500,
            });
        }
    }

    const listarComissoesApuradas = useCallback(async () => {
        const resposta = await axiosGet(`/vendas/comissoes-apuradas?apuracao=${props.apuracao.id}&limit=3000`);

        if (resposta.status === 200) {
            if (resposta.data.results?.length > 0) setComissoes(resposta.data.results);
            else setConfirmDialog(true);
        }
    }, [props.apuracao?.id]);

    useEffect(() => {
        if (props.apuracao?.id) listarComissoesApuradas();
    }, [listarComissoesApuradas, props.apuracao?.id]);

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <ConfirmDialog
                    onHide={() => props.setConfirmacao(false)}
                    visible={confirmDialog}
                    header="Confirmação de Processamento"
                    message={
                        <div>
                            <h6>
                                Deseja realmente processar as comissões?{" "}
                                <b>{`(Existe(m) ${comissoes?.length} registro(s))`}</b>
                            </h6>
                            <h5>
                                <b>
                                    ATENÇÃO: Essa operação irá gerar <b>contas a receber</b> para seus devidos
                                    comissionados.{" "}
                                </b>
                            </h5>
                        </div>
                    }
                    icon="pi pi-info-circle p-mr-3"
                    accept={() => handleProcessar()}
                    acceptLabel="Processar"
                    acceptClassName="p-button-success"
                    reject={() => props.setConfirmacao(false)}
                    rejectLabel="Cancelar"
                    rejectClassName="p-button-danger"
                />
            </div>
        </div>
    );
};
