import { useCallback, useEffect, useState } from "react";

import useLocalStorage from "./useLocalStorage";
import useToast from "./useToast";
import useAuth from "./useAuth";
import storageKeys from "@/assets/constants/storage";

const useNotificacao = () => {
    const [notifications, setNotifications] = useState([]);
    const { user } = useAuth();
    const { showInfo } = useToast();
    const [storageNotifications, setStorageNotifications] = useLocalStorage(storageKeys.NOTIFICACOES_KEY, []);

    useEffect(() => {
        if (storageNotifications) {
            setNotifications(storageNotifications);
        }
    }, [storageNotifications]);

    const marcarTodasMsgComoLidas = useCallback(() => {
        setStorageNotifications([]);
    }, [setStorageNotifications]);

    const addNotificacao = useCallback(
        (novaMsg) => {
            if (novaMsg.destinatario === user.id) {
                setStorageNotifications([...storageNotifications, novaMsg]);

                showInfo({
                    summary: "Info.",
                    detail: "Você tem uma nova notificação.",
                    life: 2000,
                });
            }
        },
        [storageNotifications, setStorageNotifications, user, showInfo]
    );

    const marcarMsgComoLida = useCallback(
        (msg) => {
            const messages = [...storageNotifications].filter((el) => el._id !== msg._id);
            setStorageNotifications(messages);
        },
        [storageNotifications, setStorageNotifications]
    );

    return { addNotificacao, marcarMsgComoLida, marcarTodasMsgComoLidas, notifications };
};

export default useNotificacao;
