import React, { useRef } from "react";

import { useHistory } from "react-router-dom";

import { Button } from "primereact/button";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";

import permissoes from "@/assets/constants/permissoes";

import { axiosDelete } from "@/services/http";

import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import useClearRefs from "@/hooks/useClearRefs";
import { CodigoFiltroTemplate, DropdownFiltroTemplate, EstoqueFiltroTemplate } from "@/components/MakoFiltrosCabecalho";

export const VinculoCentroEstocagemResultado = () => {
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const history = useHistory();

    const listagemRef = useRef();
    useClearRefs(listagemRef);

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.PRODUTO_CADASTRO_CENTROESTOCAGEM_CENTRORESULTADO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/produto/cadastros/vinculo-estocagem-resultado/form")}
            />
        </>
    );

    const handleDelete = async (id) => {
        showLoading();

        const response = await axiosDelete(`/produtos/vinculo-centros-estocagem-resultados/${id}/`);

        if (response.status === 204) {
            showSuccess({
                summary: "Sucesso, :)",
                detail: "Vinculo deletado com sucesso",
                life: 3000,
            });

            listagemRef.current?.buscarDados();
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos processar sua requisição.",
                life: 3000,
            });
        }

        hideLoading();
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_CADASTRO_CENTROESTOCAGEM_CENTRORESULTADO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    tooltip="Editar"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/produto/cadastros/vinculo-estocagem-resultado/form",
                            state: rowData,
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_CADASTRO_CENTROESTOCAGEM_CENTRORESULTADO_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    tooltip="Excluir"
                    tooltipOptions={{ position: "left" }}
                    onClick={() => handleDelete(rowData.id)}
                />
            </div>
        );
    };

    const EstoquesFiltroTemplate = (options) => {
        return <EstoqueFiltroTemplate options={options} />;
    };

    const CentroResultadoFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate 
                dropdownProps={{ 
                    optionValue: "id", 
                    optionLabel: "nome", 
                    url: "/plano-operacional/centros-resultados/?limit=500" 
                }} 
                options={options} 
            />
        );
    };

    const colunas = [
        {
            field: "id",
            header: "id",
            style: { width: "10%" },
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "centro_estocagem",
            header: "Centro estocagem",
            action: ({ centro_estocagem }) => centro_estocagem.nome,
            filter: true,
            filterElement: EstoquesFiltroTemplate,
        },
        {
            field: "centro_resultado",
            header: "Centro Resultado",
            action: ({ centro_resultado }) => centro_resultado.nome,
            filter: true,
            filterElement: CentroResultadoFiltroTemplate,
        },
        {
            field: "",
            header: "Ações",
            style: { width: "10%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    return (
        <div className="card">
            <MakoListagem
                ref={listagemRef}
                titulo="Vinculos produto centro de resultado a um centro de estocagem"
                urlPesquisa="/produtos/vinculo-centros-estocagem-resultados/"
                painelEsquerdo={cabecalhoTabela}
                colunas={colunas}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    centro_estocagem: { value: null, matchMode: "equals" },
                    centro_resultado: { value: null, matchMode: "equals" },
                }}
            />
        </div>
    );
};
