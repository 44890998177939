import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { PageBase } from "@/components/PageBase";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { Dropdown } from "@/components/Dropdown";
import { TIPO_CAMPO_PERSONALIZADO_CHOICE } from "@/assets/constants/constants";
import { CONTENT_TYPES } from "@/assets/constants/contentTypes";
import { axiosPatch, axiosPost } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

const urlvoltar = "/configuracoes/campos-personalizados";

export const CamposPersonalizadosForm = (props) => {
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();

    const campoVazio = {
        name: "",
        label: "",
        mask: "",
        default_value: "",
        field_choices: "",
        field_type: "t",
        is_required: false,
        content_type: null,
    };

    const formik = useFormik({
        initialValues: props.location.state ? props.location.state.campos : campoVazio,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                name: Yup.string().max(150).required("O campo 'nome' é obrigatório."),
                label: Yup.string().max(150).required("O campo 'label' é obrigatório."),
                content_type: Yup.number().required("O campo 'tipo de conteúdo' é obrigatório."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(`/custom-fields/fields/`, values);
                hideLoading();
                if (resposta.status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Campo personalizado cadastrado com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPatch(`/custom-fields/fields/${values.id}/`, values);
                hideLoading();
                if (resposta.status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Campo personalizado alterado com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Novo campo personalizado" : "Manutenção de campo personalizado"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="name">Nome *</label>
                        <InputText
                            id="name"
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.name })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.name && <small className="p-error">{formik.errors.name}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="label">Label *</label>
                        <InputText
                            id="label"
                            name="label"
                            value={formik.values.label}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.label })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.label && <small className="p-error">{formik.errors.label}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="content_type">Modelo atribuído *</label>
                        <Dropdown
                            id="content_type"
                            name="content_type"
                            url={`/custom-fields/content-types?limit=1000&model__in=${CONTENT_TYPES.map(
                                (el) => el.split(".")[1]
                            ).join(",")}`}
                            filter
                            filterBy="model"
                            optionValue="id"
                            optionLabel="model"
                            value={formik.values.content_type}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="field_type">Tipo de campo *</label>
                        <Dropdown
                            id="field_type"
                            name="field_type"
                            options={TIPO_CAMPO_PERSONALIZADO_CHOICE}
                            filter
                            filterBy="label"
                            optionValue="value"
                            optionLabel="label"
                            value={formik.values.field_type}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="default_value">Valor padrão</label>
                        <InputText
                            id="default_value"
                            name="default_value"
                            value={formik.values.default_value}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.default_value })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.default_value && (
                            <small className="p-error">{formik.errors.default_value}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="mask">Máscara</label>
                        <InputText
                            id="mask"
                            name="mask"
                            value={formik.values.mask}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.mask })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.mask && <small className="p-error">{formik.errors.mask}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="field_choices">Opções do campo</label>
                        <InputText
                            id="field_choices"
                            name="field_choices"
                            value={formik.values.field_choices}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.field_choices })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.field_choices && (
                            <small className="p-error">{formik.errors.field_choices}</small>
                        )}
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-3 p-mt-5">
                        <br></br>
                        <Checkbox
                            inputId="is_required"
                            id="is_required"
                            name="is_required"
                            onChange={formik.handleChange}
                            checked={formik.values.is_required}
                            value={formik.values.cubagem}
                        />
                        <label htmlFor="is_required">Campo obrigatório?</label>
                    </div>
                </div>
                <CamposObrigatorios />
                <div className="p-grid p-col-12 p-md-6">
                    <Button icon="pi pi-save" label="Gravar" type="submit" className="p-button-info p-mr-2" />
                    <Button
                        icon="pi pi-angle-double-left"
                        label="Voltar"
                        type="reset"
                        className="p-button-danger p-mr-2"
                        onClick={cancelar}
                    />
                </div>
            </form>
        </PageBase>
    );
};
