import React, { useRef } from "react";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { Checkbox } from "primereact/checkbox";
import { useHistory } from "react-router-dom";
import { MakoCalendar } from "@/components/MakoCalendar";
import { dataToStr } from "@/assets/util/datas";
import { InputNumber } from "primereact/inputnumber";
import { axiosPatch, axiosPost } from "@/services/http";
import { TIPO_SERVICO_API } from "@/assets/constants/constants";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";

import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import { validarCNPJ } from "@/assets/util/validacoes";
import useLocalStorage from "@/hooks/useLocalStorage";
import storageKeys from "@/assets/constants/storage";

const url = "/configuracoes/api-servicos/";
const urlvoltar = "/configuracoes/api-servicos";

export const ApiServicosForm = (props) => {
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const history = useHistory();
    const [empresaSelecionada] = useLocalStorage(storageKeys.EMPRESA_KEY);

    const apiVazia = {
        provedor: null,
        tipo_servico: null,
        valor_mensal: 0,
        descricao: "",
        data_inicio: null,
        parametros_acesso: "",
        valor_fixo: false,
        url_integracao: "",
        contrato_geral: false,
        ativo: true,
        cnpj_integrado: "",
        chave_acesso: "",
    };

    const { setFieldValue, ...formik } = useFormik({
        initialValues: props.location.state
            ? {
                  ...props.location.state.apiServico,
                  tipo_servico: props.location.state.apiServico.tipo_servico.id,
                  parametros_acesso: props.location.state.apiServico.parametros_acesso || "",
              }
            : apiVazia,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            if (values.provedor?.id) values.provedor = values.provedor.id;

            const formSchema = Yup.object().shape({
                provedor: Yup.number().required("O campo 'provedor' é obrigatório."),
                tipo_servico: Yup.string().required("O campo 'provedor' é obrigatório."),
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                valor_mensal: Yup.number().required("O campo 'valor mensal' é obrigatório."),
                data_inicio: Yup.date().required("O campo 'data início' é obrigatório."),
                url_integracao: Yup.string().required("O campo 'url integração' é obrigatório."),
                cnpj_integrado: Yup.string()
                    .required("O campo 'cnpj de integração' é obrigatório.")
                    .test("Cnpj validation", "O 'cnpj' é inválido.", (value) => validarCNPJ(value) === true),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            values.data_inicio = dataToStr(values.data_inicio, "yyyy-MM-dd");

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(`${url}`, { empresa: empresaSelecionada.id, ...values });
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "API de serviços cadastrada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPatch(`${url}${values.id}/`, values);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "API de serviços alterada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    const autoCompleteProvedorTemplate = (provedor) => {
        provedor.label = `${provedor.identificacao} - ${provedor.nome}`;
        return <div>{`${provedor.identificacao} - ${provedor.nome}`}</div>;
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toastRef} />
                    <h5>{!formik.values.id ? "Nova API de serviços" : "Manutenção de API de serviços"}</h5>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="descricao">Descrição *</label>
                                <InputText
                                    id="descricao"
                                    name="descricao"
                                    value={formik.values.descricao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.descricao })}
                                    autoComplete="off"
                                />
                                {formik.errors.descricao && (
                                    <small className="p-error">{formik.errors.descricao}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="provedor">Provedor *</label>
                                <MakoAutoComplete
                                    id="provedor"
                                    name="provedor"
                                    placeholder="Digite para buscar... (min 4 caracteres)"
                                    minCaracteresBusca={4}
                                    value={formik.values.provedor}
                                    onChange={formik.handleChange}
                                    itemTemplate={autoCompleteProvedorTemplate}
                                    field="nome"
                                    urlSearch={`/pessoas/perfis?search=`}
                                    className={classNames({ "p-invalid": formik.errors.provedor })}
                                />
                                {formik.errors.provedor && <small className="p-error">{formik.errors.provedor}</small>}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="tipo_servico">Tipo de serviço *</label>
                                <Dropdown
                                    id="tipo_servico"
                                    name="tipo_servico"
                                    placeholder="Selecione um tipo de serviço"
                                    options={TIPO_SERVICO_API}
                                    optionValue="id"
                                    optionLabel="label"
                                    value={formik.values.tipo_servico}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.tipo_servico })}
                                />
                                {formik.errors.tipo_servico && (
                                    <small className="p-error">{formik.errors.tipo_servico}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="valor_mensal">Valor Mensal</label>
                                <InputNumber
                                    id="valor_mensal"
                                    name="valor_mensal"
                                    mode="currency"
                                    minFractionDigits={2}
                                    maxFractionDigits={4}
                                    currency="BRL"
                                    value={formik.values.valor_mensal}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.valor_mensal })}
                                />
                                {formik.errors.valor_mensal && (
                                    <small className="p-error">{formik.errors.valor_mensal}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="data_inicio">Data início *</label>
                                <MakoCalendar
                                    id="data_inicio"
                                    name="data_inicio"
                                    valueCalendar={formik.values.data_inicio}
                                    onChange={formik.handleChange}
                                    className={classNames({
                                        "p-invalid": formik.errors.data_inicio,
                                    })}
                                />
                                {formik.errors.data_inicio && (
                                    <small className="p-error">{formik.errors.data_inicio}</small>
                                )}
                            </div>
                            <div className="p-field-checkbox p-col-12 p-md-2">
                                <br></br>
                                <Checkbox
                                    inputId="valor_fixo"
                                    id="valor_fixo"
                                    name="valor_fixo"
                                    onChange={formik.handleChange}
                                    checked={formik.values.valor_fixo}
                                    value={formik.values.valor_fixo}
                                    className="p-mt-5"
                                />
                                <label htmlFor="valor_fixo" className="p-mt-5">
                                    <b>Valor fixo</b>
                                </label>
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field-checkbox p-col-12 p-md-2 p-ml-1">
                                <br></br>
                                <Checkbox
                                    inputId="contrato_geral"
                                    id="contrato_geral"
                                    name="contrato_geral"
                                    onChange={formik.handleChange}
                                    checked={formik.values.contrato_geral}
                                    value={formik.values.contrato_geral}
                                    className="p-mt-5"
                                />
                                <label htmlFor="contrato_geral" className="p-mt-5">
                                    <b>Contrato geral</b>
                                </label>
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="url_integracao">Url Integração *</label>
                                <InputText
                                    id="url_integracao"
                                    name="url_integracao"
                                    value={formik.values.url_integracao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.url_integracao })}
                                    autoComplete="off"
                                />
                                {formik.errors.url_integracao && (
                                    <small className="p-error">{formik.errors.url_integracao}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="cnpj_integrado">Cnpj Integrado *</label>
                                <InputText
                                    id="cnpj_integrado"
                                    name="cnpj_integrado"
                                    keyfilter={/^[0-9]/}
                                    value={formik.values.cnpj_integrado}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.cnpj_integrado })}
                                    autoComplete="off"
                                />
                                {formik.errors.cnpj_integrado && (
                                    <small className="p-error">{formik.errors.cnpj_integrado}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-7">
                                <label htmlFor="chave_acesso">Chave de acesso</label>
                                <InputText
                                    id="chave_acesso"
                                    name="chave_acesso"
                                    value={formik.values.chave_acesso}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.chave_acesso })}
                                    autoComplete="off"
                                />
                                {formik.errors.chave_acesso && (
                                    <small className="p-error">{formik.errors.chave_acesso}</small>
                                )}
                            </div>
                        </div>

                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-7">
                                <label htmlFor="parametros_acesso">Parâmetros de acesso</label>
                                <InputTextarea
                                    id="parametros_acesso"
                                    name="parametros_acesso"
                                    value={formik.values.parametros_acesso}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.parametros_acesso })}
                                    rows={30}
                                    autoComplete="off"
                                    autoResize
                                />
                                {formik.errors.parametros_acesso && (
                                    <small className="p-error">{formik.errors.parametros_acesso}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-1 ">
                                <h5>
                                    <label htmlFor="status">Status</label>
                                </h5>
                                <InputSwitch
                                    id="ativo"
                                    name="ativo"
                                    checked={formik.values.ativo}
                                    disabled={formik.values.id ? false : true}
                                    value={formik.values.ativo}
                                    onChange={(e) => setFieldValue("ativo", e.value)}
                                />
                            </div>
                        </div>
                        <p>
                            <b>* Campos obrigatórios.</b>
                        </p>

                        <div className="p-grid p-col-12 p-md-6">
                            <Button label="Gravar" type="submit" className="p-button-info p-mr-2 p-mb-6" />
                            <Button
                                label="Cancelar"
                                type="reset"
                                className="p-button-danger p-mr-2 p-mb-6"
                                onClick={cancelar}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
