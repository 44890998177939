import React, { createContext, useCallback, useEffect, useState } from "react";

import storageKeys from "@/assets/constants/storage";
import useLocalStorage from "@/hooks/useLocalStorage";

const EmpresaContext = createContext({});

export const EmpresaProvider = ({ children }) => {
    const [empresaSelecionada, setEmpresaSelecionada] = useState(null);
    const [empresaSelecionadaId, setEmpresaSelecionadaId] = useState(null);
    const [storageEmpresaSelecionada, setStorageEmpresaSelecionada, removeStorageEmpresaSelecionada] = useLocalStorage(
        storageKeys.EMPRESA_KEY
    );

    useEffect(() => {
        if (storageEmpresaSelecionada) {
            setEmpresaSelecionada(storageEmpresaSelecionada);
            setEmpresaSelecionadaId(storageEmpresaSelecionada.id);
        } else {
            setEmpresaSelecionada(null);
            setEmpresaSelecionadaId(null);
        }
    }, [storageEmpresaSelecionada]);

    const alterarEmpresaSelecionada = useCallback(
        (empresa) => {
            setStorageEmpresaSelecionada(empresa);
            setEmpresaSelecionadaId(empresa?.id);
        },
        [setStorageEmpresaSelecionada]
    );

    const removerEmpresaSelecionada = useCallback(() => {
        removeStorageEmpresaSelecionada();
    }, [removeStorageEmpresaSelecionada]);

    return (
        <EmpresaContext.Provider
            value={{ empresaSelecionada, empresaSelecionadaId, alterarEmpresaSelecionada, removerEmpresaSelecionada }}
        >
            {children}
        </EmpresaContext.Provider>
    );
};

export default EmpresaContext;
