import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { axiosGet, axiosPost, axiosPut } from "@/services/http";
import { useHistory } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";

import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import { TIPO_APURACAO_COMISSAO_CHOICE, TIPO_STATUS_APURACAO_CHOICE } from "@/assets/constants/constants";
import { MakoCalendar } from "@/components/MakoCalendar";
import { dataToStr } from "@/assets/util/datas";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import { Dialog } from "primereact/dialog";
import { CalculoComissaoForm } from "./modals/calculoComissao";

const url = "/vendas/apuracao-comissao-venda/";
const urlvoltar = "/vendas/cadastros/apuracao-comissao";

export const ApuracaoComissaoForm = (props) => {
    const [listaRegras, setListaRegras] = useState([]);
    const [listaHierarquia, setListaHierarquia] = useState([]);
    const [usuario, setUsuario] = useState(null);
    const [calcularComissao, setCalcularComissao] = useState(false);
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const history = useHistory();

    const apuracaoVazia = {
        id: null,
        regra_apuracao: null,
        tipo_apuracao: "",
        status: "A",
        data_final: null,
        data_inicial: null,
        hierarquia: null,
    };

    const formik = useFormik({
        initialValues: props.location.state
            ? {
                  ...props.location.state.apuracao,
                  regra_apuracao: props.location.state.apuracao.regra_apuracao?.id,
                  tipo_apuracao: props.location.state.apuracao.tipo_apuracao?.codigo,
                  status: props.location.state.apuracao.status?.codigo,
                  hierarquia: props.location.state.apuracao.hierarquia?.id || null,
              }
            : apuracaoVazia,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            values.usuario_criacao = usuario.id;

            const formSchema = Yup.object().shape({
                regra_apuracao: Yup.number().required("O campo 'regra de apuração' é obrigatório."),
                tipo_apuracao: Yup.string().required("O campo 'tipo' é obrigatório."),
                data_inicial: Yup.date().required("O campo 'data inicial' é obrigatório."),
                data_final: Yup.date().required("O campo 'data final' é obrigatório."),
                usuario_criacao: Yup.number().required("O campo 'usuário de criação' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            values.data_inicial = dataToStr(values.data_inicial, "yyyy-MM-dd");
            values.data_final = dataToStr(values.data_final, "yyyy-MM-dd");

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(`${url}`, values);
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Apuração de comissão cadastrada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(`${url}${values.id}/`, values);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Ocupação alterada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    const listarRegrasApuracao = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/vendas/regra-apuracao-comissao-venda?limit=1000");
        hideLoading();

        if (resposta.status === 200) {
            setListaRegras(resposta.data.results);
        }
    }, [showLoading, hideLoading]);

    const listarHierarquia = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/pessoas/hierarquia-funcional?limit=3000");
        hideLoading();

        if (resposta.status === 200) {
            setListaHierarquia(resposta.data.results);
        }
    }, [showLoading, hideLoading]);

    const carregaUsuario = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/auth/users/me/");
        hideLoading();

        if (resposta.status === 200) {
            setUsuario(resposta.data);
        }
    }, [showLoading, hideLoading]);

    useEffect(() => {
        listarRegrasApuracao();
        listarHierarquia();
        carregaUsuario();
    }, [listarRegrasApuracao, listarHierarquia, carregaUsuario]);

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toastRef} />
                    <h3>{!formik.values.id ? "Nova Apuração de Comissão" : "Manutenção de Apuração de Comissão"}</h3>
                    <form onSubmit={formik.handleSubmit}>
                        {formik.values?.id ? (
                            <MakoControleAcesso
                                permissao={[permissoes.VENDAS_COMISSAO_APURACAOCOMISSAO_CALCULAR]}
                                componente={Button}
                                icon="pi pi-percentage"
                                className="p-button-help p-mr-2 p-mb-2"
                                onClick={() => setCalcularComissao(true)}
                                label="Cálculo"
                                type="button"
                            />
                        ) : null}
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="regra_apuracao">Regra *</label>
                                <Dropdown
                                    id="regra_apuracao"
                                    name="regra_apuracao"
                                    placeholder="Selecione uma natureza"
                                    options={listaRegras}
                                    filter
                                    filterBy="descricao"
                                    optionValue="id"
                                    optionLabel="descricao"
                                    value={formik.values.regra_apuracao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.regra_apuracao })}
                                />
                                {formik.errors.regra_apuracao && (
                                    <small className="p-error">{formik.errors.regra_apuracao}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="tipo_apuracao">Tipo *</label>
                                <Dropdown
                                    id="tipo_apuracao"
                                    name="tipo_apuracao"
                                    placeholder="Selecione um tipo"
                                    options={TIPO_APURACAO_COMISSAO_CHOICE}
                                    optionValue="value"
                                    optionLabel="label"
                                    value={formik.values.tipo_apuracao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.tipo_apuracao })}
                                />
                                {formik.errors.tipo_apuracao && (
                                    <small className="p-error">{formik.errors.tipo_apuracao}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="data_inicial">Data Inicial *</label>
                                <MakoCalendar
                                    id="data_inicial"
                                    name="data_inicial"
                                    valueCalendar={formik.values.data_inicial}
                                    onChange={formik.handleChange}
                                    className={classNames({
                                        "p-invalid": formik.errors.data_inicial,
                                    })}
                                />
                                {formik.errors.data_inicial && (
                                    <small className="p-error">{formik.errors.data_inicial}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="data_final">Data Final *</label>
                                <MakoCalendar
                                    id="data_final"
                                    name="data_final"
                                    valueCalendar={formik.values.data_final}
                                    onChange={formik.handleChange}
                                    className={classNames({
                                        "p-invalid": formik.errors.data_final,
                                    })}
                                />
                                {formik.errors.data_final && (
                                    <small className="p-error">{formik.errors.data_final}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="status">Status *</label>
                                <Dropdown
                                    id="status"
                                    name="status"
                                    options={TIPO_STATUS_APURACAO_CHOICE}
                                    optionValue="value"
                                    optionLabel="label"
                                    disabled
                                    value={formik.values.status}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.status })}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="hierarquia">Hierarquia funcional *</label>
                                <Dropdown
                                    id="hierarquia"
                                    name="hierarquia"
                                    placeholder="Selecione uma hierarquia"
                                    options={listaHierarquia}
                                    filter
                                    filterBy="perfil.nome"
                                    optionValue="id"
                                    optionLabel="perfil.nome"
                                    value={formik.values.hierarquia}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.hierarquia })}
                                />
                                {formik.errors.hierarquia && (
                                    <small className="p-error">{formik.errors.hierarquia}</small>
                                )}
                            </div>
                        </div>

                        <p>
                            <b>* Campos obrigatórios.</b>
                        </p>
                        <div className="p-grid p-col-12 p-md-6">
                            <Button label="Gravar" type="submit" className="p-button-info p-mr-2 p-mb-6" />
                            <Button
                                label="Cancelar"
                                type="reset"
                                className="p-button-danger p-mr-2 p-mb-6"
                                onClick={cancelar}
                            />
                        </div>
                    </form>
                </div>
                <Dialog
                    header="Cálculo de Comissão"
                    visible={calcularComissao}
                    breakpoints={{ "960px": "75vw" }}
                    style={{ width: "50vw", display: "block" }}
                    onHide={() => setCalcularComissao(false)}
                >
                    <CalculoComissaoForm
                        apuracao={formik.values}
                        dialog={() => setCalcularComissao(false)}
                        toast={toastRef}
                    />
                </Dialog>
            </div>
        </div>
    );
};
