import React, { createContext, useCallback, useState } from "react";
import { addDays, differenceInDays } from "date-fns";

import { axiosGet, axiosPost, axiosPatch } from "@/services/http";
import { dataToStr, parseData } from "@/assets/util/datas";
import * as pd from "@/assets/util/persistenciaDjango";
import { gerarParcelas } from "@/assets/util/calcFinanceiros";
import { formatarCasasDecimais } from "@/assets/util/util";
import useAuth from "@/hooks/useAuth";
import useEmpresa from "@/hooks/useEmpresa";

const OrdemCompraContext = createContext({});

export const OrdemCompraProvider = ({ children }) => {
    const [dadosBasicos, setDadosBasicos] = useState(null);
    const [fornecedorOC, setFornecedorOC] = useState(null);
    const [parcelas, setParcelas] = useState([]);
    const [itens, setItens] = useState([]);
    const [transportadores, setTransportadores] = useState([]);
    const [submit, setSubmit] = useState(false);
    const [loadingOC, setLoadingOC] = useState(false);
    const [permiteAlterar, setPermiteAlterar] = useState(true);
    const { user } = useAuth();
    const { empresaSelecionadaId } = useEmpresa();

    const totalizarOrdemCompra = useCallback((_dadosBasicos, _itens = []) => {
        if (_dadosBasicos) {
            const valor_total_itens = _itens.reduce((total, item) => total + Number(item.total_item), 0);
            const custos_extras = _itens.reduce(
                (total, item) =>
                    total + Number(item.unitario_outros_valores) * Number(item.quantidade) * Number(item.cubagem),
                0
            );
            const valor_total_ipi = 0; // calcular total do ipi
            const valor_total_icms = 0; // calcular total do icms
            const valor_total_icms_st = _itens.reduce((total, item) => total + Number(item.valor_total_icms_st), 0);

            const { percentual_frete_destinatario, valor_frete_previsto, valor_total_servicos, valor_antecipado } =
                _dadosBasicos;

            const valor_total_ordem_compra =
                valor_total_itens +
                (percentual_frete_destinatario * valor_frete_previsto) / 100 +
                Number(valor_total_servicos) +
                Number(custos_extras);

            const valor_total_parcelas = valor_total_ordem_compra - valor_antecipado;

            setDadosBasicos({
                ..._dadosBasicos,
                quantidade_itens: _itens.length,
                valor_total_itens: formatarCasasDecimais(valor_total_itens),
                custos_extras,
                valor_total_ipi,
                valor_total_icms,
                valor_total_icms_st,
                valor_total_ordem_compra: formatarCasasDecimais(valor_total_ordem_compra),
                valor_total_parcelas: formatarCasasDecimais(valor_total_parcelas),
            });
        }
    }, []);

    const handleDadosBasicos = useCallback(
        (data) => {
            if (!data.id) data.usuario_cadastro = user.id;

            const dadosBasicosOC = {
                empresa: dadosBasicos?.empresa ? dadosBasicos.empresa : empresaSelecionadaId,
                ...dadosBasicos,
                ...data,
            };

            totalizarOrdemCompra(dadosBasicosOC, itens);
            setFornecedorOC(dadosBasicosOC.fornecedor);
            setSubmit(true);
        },
        [dadosBasicos, itens, user, empresaSelecionadaId, totalizarOrdemCompra]
    );

    const handleParcelas = useCallback(
        (data) => {
            const intervalo = data.intervalo_parcelas.split(",");
            const valoresParcelas = gerarParcelas(dadosBasicos.valor_total_parcelas, intervalo.length);

            let _parcelas = [];

            for (let x = 0; x < intervalo.length; x++) {
                const parcela = {
                    numero_parcela: intervalo[0] === "0" ? x : x + 1,
                    valor_parcela: valoresParcelas[x],
                    dias_apos_pedido: intervalo[x],
                    forma_pagamento: data.forma_pagamento,
                };

                _parcelas = pd.criarListaProvisoriaParaPersistencia(_parcelas, parcela, pd.OP_CRUD_DJANGO.novo);
            }

            setParcelas(_parcelas);

            setDadosBasicos({
                ...dadosBasicos,
                vencimento_inicial: addDays(dadosBasicos.data_pedido, intervalo[0]),
                quantidade_parcelas: _parcelas.length,
                forma_pagamento: data.forma_pagamento,
                intervalo_parcelas: data.intervalo_parcelas,
            });
        },
        [dadosBasicos]
    );

    const handleRemoverParcelas = useCallback(() => {
        let _parcelas = [...parcelas];

        parcelas.forEach((parcela) => {
            _parcelas = pd.criarListaProvisoriaParaPersistencia(_parcelas, parcela, pd.OP_CRUD_DJANGO.deletar);
        });

        setParcelas(_parcelas);
    }, [parcelas]);

    const handleAlterarParcela = useCallback(
        (values) => {
            let _parcelas = [...parcelas];
            const valorAtualParcelas = _parcelas.reduce((total, p) => {
                const keyId = p.id ? "id" : "localId";
                if (p._status !== pd.OP_CRUD_DJANGO.deletar && p[keyId] !== values.id_parcela) {
                    return total + Number(p.valor_parcela);
                }
                return total;
            }, 0);
            const { valor_total_ordem_compra, data_pedido } = dadosBasicos;
            if (valorAtualParcelas + values.valor_parcela > Number(valor_total_ordem_compra)) {
                return false;
            }
            let _parcela = _parcelas.find((p) => {
                const keyId = p.id ? "id" : "localId";
                if (p[keyId] === values.id_parcela) return p;
                return null;
            });
            const data = typeof data_pedido === "string" ? parseData(data_pedido) : data_pedido;
            const vencimentoOriginal = addDays(data, parseInt(_parcela.dias_apos_pedido));
            const diff = differenceInDays(vencimentoOriginal, values.vencimento);
            _parcela.dias_apos_pedido = Number(_parcela.dias_apos_pedido) - diff;
            _parcela.valor_parcela = values.valor_parcela;
            _parcela.forma_pagamento = values.forma_pagamento;
            _parcelas = pd.criarListaProvisoriaParaPersistencia(_parcelas, _parcela, pd.OP_CRUD_DJANGO.editar);
            setParcelas(_parcelas);
            return true;
        },
        [dadosBasicos, parcelas]
    );

    const handleItem = useCallback(
        (item, op) => {
            let _itens = [...itens];

            const _item = { ...item, datahora_gravacao: new Date() };

            if (!_item.id) {
                const novoItem = {
                    ..._item,
                    total_item: formatarCasasDecimais(_item.total_item),
                    usuario_cadastro: user.id,
                    datahora_gravacao: new Date(),
                };

                _itens = pd.criarListaProvisoriaParaPersistencia(_itens, novoItem, op);
            } else {
                const itemAlterado = {
                    ..._item,
                    total_item: formatarCasasDecimais(_item.total_item),
                    usuario_alteracao: user.id,
                };

                _itens = pd.criarListaProvisoriaParaPersistencia(_itens, itemAlterado, op);
            }

            setItens(_itens);
            totalizarOrdemCompra(dadosBasicos, _itens);
        },
        [dadosBasicos, itens, user, totalizarOrdemCompra]
    );

    const formatarTransportadorParaAPI = (transportadores) => {
        return transportadores.map((_) => {
            const { transportador, ...rest } = _;

            return {
                transportador: transportador.id,
                ...rest,
            };
        });
    };

    const handleTransportador = useCallback(
        (transportador, op) => {
            let _transportadores = [...transportadores];
            _transportadores = pd.criarListaProvisoriaParaPersistencia(_transportadores, transportador, op);
            const totalFreteCif = _transportadores.reduce(
                (total, atual) => (atual.cif ? total + Number(atual.valor_frete) : total),
                0
            );
            const totalFreteFob = _transportadores.reduce(
                (total, atual) => (!atual.cif ? total + Number(atual.valor_frete) : total),
                0
            );
            const totalFrete = totalFreteCif + totalFreteFob;
            setTransportadores(_transportadores);

            const _dadosBasicos = {
                ...dadosBasicos,
                valor_frete_previsto: totalFrete,
                percentual_frete_destinatario: (totalFreteFob / totalFrete) * 100,
                percentual_frete_emitente: (totalFreteCif / totalFrete) * 100,
            };

            totalizarOrdemCompra(_dadosBasicos, itens);
        },
        [transportadores, totalizarOrdemCompra, dadosBasicos, itens]
    );

    const handleAnotacoes = useCallback((anotacao) => {
        setDadosBasicos((old) => ({ ...old, anotacoes: old?.anotacoes ? `${old.anotacoes}\n${anotacao}` : anotacao }));
    }, []);

    const handlePreencherOrdemCompra = useCallback(async (ordemCompraId) => {
        setLoadingOC(true);
        const json = await axiosGet(`/compras/ordens-compra/${ordemCompraId}/`);
        setLoadingOC(false);
        if (json.status === 200) {
            const { vinculotransportadorordemcompra_set, itemordemcompra_set, parcelaordemcompra_set, ...rest } =
                json.data;
            setDadosBasicos(rest);
            setFornecedorOC(rest.fornecedor);
            setTransportadores(
                vinculotransportadorordemcompra_set.map((transp) => ({
                    ...transp,
                    _status: pd.OP_CRUD_DJANGO.editar,
                }))
            );
            setItens(itemordemcompra_set);
            setParcelas(parcelaordemcompra_set);
            setSubmit(true);
            setPermiteAlterar(!rest.vinculado_entrada);
        }
    }, []);

    const handleOrdemCompra = async () => {
        const _itens = itens.map((item) => ({ ...item, sku: item.sku.id }));
        const itemordemcompra_set = pd.montarObjetoParaPersistenciaDjango(_itens);
        const vinculotransportadorordemcompra_set = pd.montarObjetoParaPersistenciaDjango(
            formatarTransportadorParaAPI(transportadores)
        );
        const parcelaordemcompra_set = pd.montarObjetoParaPersistenciaDjango(parcelas);
        const _ordemCompra = {
            ...dadosBasicos,
            status: "PE",
            usuario_cadastro:
                dadosBasicos.usuario_cadastro instanceof Object
                    ? dadosBasicos.usuario_cadastro.id
                    : dadosBasicos.usuario_cadastro,
            usuario_aprovacao:
                dadosBasicos.usuario_aprovacao instanceof Object
                    ? dadosBasicos.usuario_aprovacao.id
                    : dadosBasicos.usuario_aprovacao,
            data_pedido: dataToStr(dadosBasicos.data_pedido, "yyyy-MM-dd"),
            vencimento_inicial: dataToStr(dadosBasicos.vencimento_inicial, "yyyy-MM-dd"),
            vencimento_antecipacao: dataToStr(dadosBasicos.vencimento_antecipacao, "yyyy-MM-dd"),
            fornecedor: dadosBasicos.fornecedor.id,
            representante: dadosBasicos.representante?.id,
            itemordemcompra_set,
            vinculotransportadorordemcompra_set,
            parcelaordemcompra_set,
        };
        if (!_ordemCompra.id) {
            const { status } = await axiosPost("/compras/ordens-compra/", _ordemCompra);
            setSubmit(false);
            return status;
        } else {
            const { status } = await axiosPatch(`/compras/ordens-compra/${_ordemCompra.id}/`, _ordemCompra);
            setSubmit(false);
            return status;
        }
    };

    return (
        <OrdemCompraContext.Provider
            value={{
                submit,
                loadingOC,
                permiteAlterar,
                dadosBasicos,
                fornecedorOC,
                itens,
                transportadores,
                parcelas,
                handleOrdemCompra,
                handleParcelas,
                handleRemoverParcelas,
                handleAlterarParcela,
                handleItem,
                handleTransportador,
                handleAnotacoes,
                handlePreencherOrdemCompra,
                handleDadosBasicos,
                setSubmit,
                setParcelas,
            }}
        >
            {children}
        </OrdemCompraContext.Provider>
    );
};

export default OrdemCompraContext;
