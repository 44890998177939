import React, { createContext, useState, useEffect, useCallback } from "react";

import { ROOT } from "@/assets/constants/permissoes";
import api from "@/services/axios";
import storageKeys from "@/assets/constants/storage";
import { axiosGet, axiosPost } from "@/services/http";
import useLocalStorage from "@/hooks/useLocalStorage";
import useToast from "@/hooks/useToast";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [params, setParams] = useState([]);
    const [caixa, setCaixa] = useState(null);
    const [storageUser, setStorageUser] = useLocalStorage(storageKeys.USER_KEY);
    const [storageToken, setStorageToken] = useLocalStorage(storageKeys.TOKEN_KEY);
    const [storageParams, setStorageParams] = useLocalStorage(storageKeys.PARAMS_KEY);
    const [storageCaixa, setStorageCaixa] = useLocalStorage(storageKeys.CAIXA_KEY);
    const { showWarning, showError } = useToast();

    useEffect(() => {
        if (storageToken) {
            api.defaults.headers["Authorization"] = `token ${storageToken}`;
        }
    }, [storageToken]);

    useEffect(() => {
        if (storageParams) {
            setParams(storageParams);
        }
    }, [storageParams]);

    useEffect(() => {
        // if (storageCaixa) {
        setCaixa(storageCaixa);
        // }
    }, [storageCaixa]);

    const loadParams = useCallback(async () => {
        const { data } = await axiosGet("/configuracoes/parametros/?limit=100");
        setStorageParams(data.results);
    }, [setStorageParams]);

    const setCaixaUsuario = useCallback(
        (cx, clear = false) => {
            setStorageCaixa(cx);
        },
        [setStorageCaixa]
    );

    const clearCaixa = useCallback(() => {
        setStorageCaixa(null);
        setCaixa(null);
    }, [setStorageCaixa]);

    const loadCaixa = useCallback(async () => {
        if (storageUser) {
            const { status, data } = await axiosGet(
                `/financeiro/caixas-movimento/?operador=${storageUser.id}&datahora_fechamento__isnull=True`
            );
            let _caixa = null;
            if (status === 200 && data.results?.length) {
                _caixa = data.results[0];
            }
            setCaixaUsuario(_caixa);
            return _caixa;
        }
    }, [setCaixaUsuario, storageUser]);

    const signIn = useCallback(
        async (username, password) => {
            const { status: statusToken, data: dataToken } = await axiosPost("/auth/token/login", {
                username,
                password,
            });

            if (statusToken !== 200) {
                if (statusToken === 400) {
                    showWarning({
                        summary: "Credenciais inválidas!",
                        detail: "Impossível fazer login com as credenciais fornecidas.",
                        life: 3000,
                    });
                } else {
                    showError({
                        summary: "Erro",
                        detail: "A sua requisição não pode ser concluída.",
                        life: 3000,
                    });
                }

                return null;
            }

            const { auth_token } = dataToken;
            api.defaults.headers["Authorization"] = `token ${auth_token}`;
            setStorageToken(auth_token);

            const { status: statusMe, data: dataMe } = await axiosGet("/auth/users/me");

            if (statusMe !== 200) {
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não foi possível encontrar os dados do perfil.",
                    life: 3000,
                });
                return null;
            }
            const params = {
                usuario__id: dataMe.id,
                query: "{id,usuario,usuario_empresapadrao,perfil_pf,permissoes_vigentes,papeis_vigentes,papeis_empresas_vigentes,foto,nome,identificacao,tipo_pessoa,ativo}",
            };
            const { status: statuPerfil, data: dataPerfil } = await axiosGet("/pessoas/perfis/", { params });

            if (statuPerfil !== 200 || dataPerfil?.results?.length === 0) {
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não foi possível encontrar os dados do perfil.",
                    life: 3000,
                });
                return null;
            }

            const [perfil] = dataPerfil.results;

            setStorageUser(perfil);
            loadParams();
            loadCaixa();
            if (perfil.permissoes_vigentes?.length === 0 && perfil.papeis_vigentes?.length === 0)
                showWarning({
                    summary: "Aviso!",
                    detail: "O usuário atual não possui permissões / papéis vigentes.",
                    sticky: true,
                });
        },
        [setStorageUser, setStorageToken, loadParams, loadCaixa, showWarning, showError]
    );

    const getParam = useCallback(
        (param, perfilId) => {
            if (param) {
                let parametro = params.find((p) => p.chave.chave === param);

                if (perfilId) {
                    parametro = params.find((p) => p.chave.chave === param && p.perfil.id === perfilId);
                }

                return parametro
                    ? {
                          id: parametro.id,
                          chave: parametro.chave?.chave,
                          valor: parametro.valor_id ? parametro.valor_id : parametro.valor,
                          perfil: parametro.perfil,
                      }
                    : null;
            }

            return null;
        },
        [params]
    );

    const clearStorage = useCallback(() => {
        setStorageUser(null);
        setStorageToken(null);
        setStorageParams(null);
        setStorageCaixa(null);
    }, [setStorageUser, setStorageToken, setStorageParams, setStorageCaixa]);

    const signOut = useCallback(() => {
        setParams([]);
        setCaixa(null);
        clearStorage();
    }, [clearStorage]);

    const verifyPermission = useCallback(
        (permissions) => {
            for (let x = 0; x < storageUser.permissoes_vigentes.length; x++) {
                const userPermission = storageUser.permissoes_vigentes[x].chave;
                for (let y = 0; y < permissions.length; y++) {
                    if (permissions[y] === userPermission || userPermission === ROOT) {
                        return true;
                    }
                }
            }

            return false;
        },
        [storageUser]
    );

    const reloadPermissions = useCallback(
        async (empresaId) => {
            let url = "/pessoas/permissoes-perfis-vigentes/";
            if (empresaId) url = `${url}?empresa=${empresaId}`;
            const { status, data } = await axiosGet(url);
            if (status === 200) {
                setStorageUser({
                    ...storageUser,
                    permissoes_vigentes: data,
                });
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não foi possível recarregar as suas permissões.",
                    life: 3000,
                });
            }
        },
        [storageUser, setStorageUser, showError]
    );

    return (
        <AuthContext.Provider
            value={{
                signed: !!storageUser,
                user: storageUser,
                token: storageToken,
                params,
                caixa,
                signIn,
                signOut,
                verifyPermission,
                reloadPermissions,
                loadParams,
                getParam,
                loadCaixa,
                setCaixaUsuario,
                clearCaixa,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
