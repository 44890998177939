import { useContext } from "react";

import CaixaMovimentoContext from "@/contexts/caixaMovimentoContext";

const useCaixaMovimento = () => {
    const { caixaMov, fetchCaixa, updateCaixa, padraoPlanos, clearCaixa } = useContext(CaixaMovimentoContext);

    return { caixaMov, fetchCaixa, updateCaixa, padraoPlanos, clearCaixa };
};

export default useCaixaMovimento;
