import React, { useEffect } from "react";

import { useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { Button } from 'primereact/button';

import { MakoInputFornecedor } from "@/components/MakoInputs/MakoInputFornecedor";
import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";

import { TIPO_PRIORIDADE_VINCULO_FORNECEDORPRODUTO } from "@/assets/constants/constants";

import { axiosPost, axiosPut } from "@/services/http";

import useLoading from "@/hooks/useLoading";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";

export const VinculoFornecedorForm = () => {

    const { empresaSelecionadaId } = useEmpresa();

    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();

    const history = useHistory();

    const { state } = useLocation();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            id: null,
            sku: null,
            fornecedor: null,
            prioridade: null,
            media_entrega: 0,
            preco_compra: 0
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                sku: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'sku' é obrigatório")
                    })
                    .typeError("Informe um 'sku' válido"),
                fornecedor: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'fornecedor' é obrigatório")
                    })
                    .typeError("Informe um 'fornecedor' válido"),
                prioridade: Yup.string()
                    .max(1)
                    .required("O campo 'prioridade' é obrigatório")
                    .typeError("Informe uma 'prioridade' válida"),
                media_entrega: Yup.number()
                    .positive("Informe um valor positivo")
                    .typeError("Informe uma 'media de entrega' válida"),
                preco_compra: Yup.number()
                    .typeError("Informe um 'preço de compra' válido")
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            values.fornecedor = values.fornecedor.id;
            values.sku = values.sku.id;

            if (values.id === null) {
                showLoading();

                const response = await axiosPost("/produtos/vinculo-produtos-fornecedor/", values);

                if (response.status === 201) {
                    showSuccess({
                        summary: "Sucesso, :)",
                        detail: "Vinculo criado com sucesso",
                        life: 3000,
                    })

                    history.push("/produto/cadastros/vinculo-fornecedor");
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar sua requisição.",
                        life: 3000,
                    })
                }

                hideLoading();

            } else {
                const response = await axiosPut(`/produtos/vinculo-produtos-fornecedor/${values.id}/`, values);

                if (response.status === 200) {
                    showSuccess({
                        summary: "Sucesso, :)",
                        detail: "Vinculo atualizado com sucesso",
                        life: 3000,
                    })
                    history.push("/produto/cadastros/vinculo-fornecedor");

                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar sua requisição.",
                        life: 3000,
                    })
                }

                hideLoading();
            }

        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const handleReset = () => {
        formik.resetForm()
    }

    useEffect(() => {
        if(state != null && state.id != null) {
            setValues({ ...state });
        }
    }, [state])

    return (
        <div className="card">
            <h5>Vincular produto ao fornecedor:</h5>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-6">
                        <label htmlFor="sku">Produto: *</label>
                        <MakoAutoComplete
                            id="sku"
                            name="sku"
                            minCaracteresBusca={3}
                            key="id"
                            field="descricao_reduzida"
                            placeholder="Digite para começar a pesquisar..."
                            urlSearch={`/produtos/sku/?item__empresa=${empresaSelecionadaId}&ativo=true&search=`}
                            value={formik.values.sku}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.sku })}
                        />
                        {formik.errors.sku && <small className="p-error">{formik.errors.sku}</small>}
                    </div>

                    <div className="p-field p-col-6">
                        <label htmlFor="fornecedor">Fornecedor: *</label>
                        <MakoInputFornecedor
                            id="fornecedor"
                            name="fornecedor"
                            key="id"
                            value={formik.values.fornecedor}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.fornecedor })}
                        />
                        {formik.errors.fornecedor && <small className="p-error">{formik.errors.fornecedor}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="prioridade">Prioridade de fornecimento *</label>
                        <Dropdown
                            id="prioridade"
                            name="prioridade"
                            options={TIPO_PRIORIDADE_VINCULO_FORNECEDORPRODUTO}
                            optionValue="value"
                            optionLabel="label"
                            filter
                            filterBy="label"
                            placeholder="Selecione um prioridade..."
                            value={formik.values.prioridade}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.prioridade })}
                        />
                        {formik.errors.prioridade && (
                            <small className="p-error">{formik.errors.prioridade}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="preco_compra">Preço da compra: </label>
                        <MakoInputMoeda
                            id="preco_compra"
                            name="preco_compra"
                            valueMoeda={formik.values.preco_compra}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.preco_compra })}
                        />
                        {formik.errors.preco_compra && (
                            <small className="p-error">{formik.errors.preco_compra}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="media_entrega">Tempo médio de entrega em dias: </label>
                        <InputNumber
                            id="media_entrega"
                            name="media_entrega"
                            value={formik.values.media_entrega}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.media_entrega })}
                        />
                        {formik.errors.media_entrega && (
                            <small className="p-error">{formik.errors.media_entrega}</small>
                        )}
                    </div>
                </div>
                <p>
                    <b>* Campos obrigatórios</b>
                </p>
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button
                            type="submit"
                            icon="pi pi-check"
                            label="Gravar"
                            className="p-mr-2 p-mb-2"
                        />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            className="p-button-warning p-mr-2 p-mb-2"
                            onClick={handleReset}
                        />
                        <Button
                            type="reset"
                            label="Cancelar"
                            className="p-button-danger p-mb-2"
                            onClick={() => history.goBack()}
                        />
                    </div>
                </div>
            </form>
        </div>
    )
}