import React, { useImperativeHandle, useState, forwardRef } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

const Modal = ({ onLer }, ref) => {
    const [visible, setVisible] = useState(false);

    const formik = useFormik({
        initialValues: {
            codigo_barras: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                codigo_barras: Yup.string()
                    .required("O campo 'código de barras' é obrigatório.")
                    .min(47, "Esse campo não pode possui MENOS que 47 caracteres.")
                    .max(47, "Esse campo não pode possui MAIS que 47 caracteres."),
            });
            const dadosValidos = await formSchema.validate(values, { abortEarly: false });
            if (typeof onLer === "function") onLer(dadosValidos.codigo_barras);
            fecharModal();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const abrirModal = () => {
        setVisible(true);
    };

    const fecharModal = () => {
        formik.resetForm();
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const footer = (
        <div>
            <Button
                type="reset"
                label="Limpar"
                icon="pi pi-trash"
                className="p-button-warning"
                onClick={formik.resetForm}
            />
            <Button type="submit" label="Confirmar" icon="pi pi-check" onClick={formik.handleSubmit} />
        </div>
    );

    return (
        <Dialog
            header="Digite ou leia um código de barras"
            visible={visible}
            onHide={fecharModal}
            style={{ width: "40vw" }}
            footer={footer}
            blockScroll
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="codigo-barras">Código de barras</label>
                        <InputText
                            id="codigo-barras"
                            name="codigo_barras"
                            value={formik.values.codigo_barras}
                            autoComplete="off"
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.codigo_barras })}
                        />
                        {formik.errors.codigo_barras && (
                            <small className="p-error">{formik.errors.codigo_barras}</small>
                        )}
                    </div>
                </div>
            </form>
        </Dialog>
    );
};

export const ModalLerCodigoBarras = forwardRef(Modal);
