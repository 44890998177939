import React, { useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { parseNumberToMoneyHTML } from "@/assets/util/util";
import { ItemAvulsoModalForm } from "./modals/itemAvulsoModalForm";

import useTroca from "@/hooks/useTroca";
import MakoListagem from "@/components/MakoListagem";
import permissoes from "@/assets/constants/permissoes";
import { ItemVendaModalForm } from "./modals/itemVendaModalForm";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { ConfirmDialog } from "primereact/confirmdialog";

export const ItensTrocaForm = () => {
    const [inserirItemAvulso, setInserirItemAvulso] = useState(false);
    const [inserirItemVenda, setInserirItemVenda] = useState(false);
    const [removerItem, setRemoverItem] = useState(false);
    const [item, setItem] = useState(null);
    const { itenstroca, totalTroca, handleItemTrocaDevolucao } = useTroca();
    const listagemRef = useRef(null);
    const toastRef = useRef(null);

    const deletarItem = async () => {
        try {
            handleItemTrocaDevolucao("deletar", { ...item, status: "deletar" });

            toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Item removido com sucesso!",
                life: 3000,
            });
        } catch (error) {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Falha ao remover item!",
                life: 3000,
            });
        }
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_VENDA_TROCA_EDITAR]}
                componente={Button}
                label="Item Venda"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setInserirItemVenda(true)}
            />
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_VENDA_TROCA_EDITAR]}
                componente={Button}
                label="Item Avulso"
                icon="pi pi-plus"
                className="p-button-success p-button-outlined p-mr-2"
                onClick={() => setInserirItemAvulso(true)}
            />
        </>
    );

    const confirmarDelete = (data) => {
        setItem(data);
        setRemoverItem(true);
    };

    const esconderDelete = () => {
        setItem(null);
        setRemoverItem(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_TROCA_EDITAR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Remover item"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "100px" } },
        { field: "descricao_reduzida", header: "Descrição reduzida", style: { minWidth: "250px" } },
        { field: "unidade_medida.nome", header: "Unidade medida", style: { minWidth: "150px" } },
        {
            field: "valor_unitario",
            header: "Valor unitário",
            action: (e) => parseNumberToMoneyHTML(e.valor_unitario),
            style: { minWidth: "150px" },
        },
        { field: "sku_movimenta_estoque.descricao_reduzida", header: "Item Movimentado", style: { minWidth: "250px" } },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "15%" },
        },
    ];

    const esconderDialogAvulso = () => {
        setInserirItemAvulso(false);
    };

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        colunas={colunas}
                        painelEsquerdo={cabecalhoTabela}
                        dadosLocal={itenstroca || []}
                        responsiva
                        configTabela={{ scrollable: true, frozenWidth: "0.5vw" }}
                    />
                    <br />
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor_total">
                                <b>Valor total:</b>
                            </label>
                            <MakoInputMoeda id="valor_total" name="valor_total" disabled value={totalTroca} />
                        </div>
                    </div>
                </div>
                <Dialog
                    header="Inserir Item da Avulso"
                    visible={inserirItemAvulso}
                    breakpoints={{ "960px": "75vw" }}
                    style={{ width: "80vw", display: "block" }}
                    onHide={() => setInserirItemAvulso(false)}
                >
                    <ItemAvulsoModalForm esconderDialog={esconderDialogAvulso} />
                </Dialog>
                <Dialog
                    header="Inserir Item da Venda"
                    visible={inserirItemVenda}
                    breakpoints={{ "960px": "75vw" }}
                    style={{ width: "80vw", display: "block" }}
                    onHide={() => setInserirItemVenda(false)}
                >
                    <ItemVendaModalForm dialog={setInserirItemVenda} toast={toastRef} />
                </Dialog>
                <ConfirmDialog
                    visible={removerItem}
                    onHide={esconderDelete}
                    header="Confirmação"
                    message={
                        item && (
                            <span>
                                {"Deseja realmente remover o item "}
                                <b>{item.descricao_reduzida}</b>?
                            </span>
                        )
                    }
                    icon="pi pi-info-circle p-mr-3"
                    accept={deletarItem}
                    acceptLabel="Sim"
                    acceptClassName="p-button-danger"
                    reject={esconderDelete}
                    rejectLabel="Não"
                />
            </div>
        </div>
    );
};
