import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import { BotaoDelete } from "@/components/BotaoDelete";
import useToast from "@/hooks/useToast";
import useClearRefs from "@/hooks/useClearRefs";
import { Dialog } from "primereact/dialog";
import { VisualizarPermissoes } from "./visualizarPermissoes";
import {
    CodigoFiltroTemplate,
    DropdownFiltroTemplate,
    PorcentagemFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import { CHAVE_PAPEL_CHOICE } from "@/assets/constants/constants";

export const PessoasPapeisPerfisPage = () => {
    const [url, setUrl] = useState("/pessoas/papeis-perfis/");
    const [redirecionar, setRedirecionar] = useState(null);
    const [papelPerfil, setPapelPerfil] = useState(false);
    const [show, setShow] = useState(false);
    const [filtro, setFiltro] = useState(1);
    const { showSuccess } = useToast();
    const listagemRef = useRef(null);

    useClearRefs(listagemRef);

    const opcoesPesquisa = [
        { label: "Todos", value: 0 },
        { label: "Ativos", value: 1 },
        { label: "Inativos", value: 2 },
    ];

    const deletePapelPerfil = () => {
        showSuccess({
            summary: "Sucesso",
            detail: "Papel de perfil deletado com sucesso!",
            life: 3000,
        });
        listagemRef.current?.buscarDados();
    };

    const abrirModal = (perfil) => {
        setPapelPerfil(perfil);
        setShow(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-eye"
                    className="p-button-rounded p-button-info p-mr-2"
                    onClick={() => abrirModal(rowData)}
                />
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_USUARIOS_PAPEISPERFIS_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    onClick={() =>
                        setRedirecionar({
                            pathname: "/gestao/usuarios/papeis-perfis/form",
                            state: { papeisPerfis: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_USUARIOS_PAPEISPERFIS_EXCLUIR]}
                    componente={BotaoDelete}
                    url={"/pessoas/papeis-perfis/"}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir o papel de perfil <b>{rowData.descricao}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O papel de perfil não pode ser excluído, verifique se
                     o mesmo está vinculado a um usuário e tente novamente."
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => deletePapelPerfil()}
                />
            </div>
        );
    };

    const filtroStatus = (status) => {
        setFiltro(status.value);

        if (status.value === 0) {
            setUrl("/pessoas/papeis-perfis/");
        } else if (status.value === 1) {
            setUrl("/pessoas/papeis-perfis/?ativo=True");
        } else {
            setUrl("/pessoas/papeis-perfis/?ativo=False");
        }
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.GESTAO_USUARIOS_PAPEISPERFIS_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setRedirecionar("/gestao/usuarios/papeis-perfis/form")}
            />
            <Dropdown value={filtro} options={opcoesPesquisa} placeholder="Selecione" onChange={filtroStatus} />
        </>
    );

    const chaveFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: CHAVE_PAPEL_CHOICE,
                }}
                options={options}
            />
        );
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "10%" }, filter: true, filterElement: CodigoFiltroTemplate },
        { field: "nome", header: "Nome", filter: true, filterElement: TextoFiltroTemplate },
        {
            field: "chave.nome",
            header: "Função",
            style: { width: "10%" },
            filter: true,
            filterElement: chaveFiltroTemplate,
            filterField: "chave",
        },
        {
            field: "max_desconto_avista",
            header: "Max. desc. avista",
            style: { width: "15%" },
            filter: true,
            filterElement: PorcentagemFiltroTemplate,
        },
        {
            field: "max_desconto_encargos",
            header: "Max. desc. encargos",
            style: { width: "15%" },
            filter: true,
            filterElement: PorcentagemFiltroTemplate,
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    const fecharModal = () => {
        setShow(false);
    };

    return redirecionar ? (
        <Redirect to={redirecionar} />
    ) : (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Papéis de Perfis"
                        colunas={colunas}
                        painelEsquerdo={cabecalhoTabela}
                        urlPesquisa={url}
                        responsiva
                        configTabela={{
                            paginator: true,
                            lazy: true,
                        }}
                        filtros={{
                            id: { value: "", matchMode: "equals" },
                            nome: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                            },
                            chave: { value: "", matchMode: "equals" },
                            max_desconto_avista: { value: "", matchMode: "equals" },
                            max_desconto_encargos: { value: "", matchMode: "equals" },
                        }}
                    />
                </div>
            </div>
            <Dialog
                header={`Permissões do papel: ${papelPerfil?.nome}`}
                visible={show}
                onHide={fecharModal}
                style={{ minWidth: "60rem" }}
            >
                <VisualizarPermissoes papel={papelPerfil} />
            </Dialog>
        </div>
    );
};
