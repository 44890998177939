import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";

import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import { axiosPatch } from "@/services/http";
import { parseNumberToMoneyHTML } from "@/assets/util/util";
import { SIM_NAO_BOOLEAN, TIPO_PRECO_SERVICO } from "@/assets/constants/constants";

const URL = "/vendas/servicos-vendas/";

export const VendasServicosPage = () => {
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [servico, setServico] = useState(null);
    const [valueAtivo, setValueAtivo] = useState(false);
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const listagemRef = useRef(null);
    const history = useHistory();

    const confirmarDelete = (servico, value) => {
        setServico(servico);
        setValueAtivo(value);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const deleteServicoVenda = async () => {
        showLoading();
        const resposta = await axiosPatch(`${URL}${servico.id}/`, { ativo: valueAtivo });
        hideLoading();

        if (resposta.status === 200) {
            toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: valueAtivo ? "Serviço ativado com sucesso!" : "Serviço desativado com sucesso!",
                life: 1500,
            });

            setTimeout(() => {
                listagemRef.current?.buscarDados();
            }, 2000);
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
        }

        setDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        let tooltip = "";
        let icon = "";
        let button = "";
        let value = "";

        if (rowData.ativo === true) {
            tooltip = "Desativar Serviço";
            icon = "pi pi-minus";
            button = "p-button-rounded p-button-danger p-mr-2 p-mb-1";
            value = false;
        } else {
            tooltip = "Ativar Serviço";
            icon = "pi pi-plus";
            button = "p-button-rounded p-button-primary p-mr-2 p-mb-1";
            value = true;
        }

        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_SERVICO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar Cadastro de Serviço"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/vendas/cadastros/servicos/form",
                            state: { servico: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_SERVICO_EXCLUIR]}
                    componente={Button}
                    icon={icon}
                    className={button}
                    onClick={() => confirmarDelete(rowData, value)}
                    tooltip={tooltip}
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_CADASTRO_SERVICO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2 p-mb-2"
                onClick={() => history.push("/vendas/cadastros/servicos/form")}
            />
        </>
    );

    const ativoBodyTemplate = (rowData) => {
        if (rowData.ativo) {
            return <span>Sim</span>;
        }

        return <span>Não</span>;
    };

    const incorporaSkuBodyTemplate = (rowData) => {
        if (rowData.incorporado_sku === true) {
            return <span>Sim</span>;
        }

        return <span>Não</span>;
    };

    const tipoPrecoBodyTemplate = (rowData) => {
        if (rowData.tipo_preco_servico === "F") {
            return <span>Preço fixo</span>;
        }

        if (rowData.tipo_preco_servico === "M") {
            return <span>Conforme medida</span>;
        }

        return <span>Percentual do produto</span>;
    };

    const valorBodyTemplate = (rowData) => {
        if (rowData.tipo_preco_servico === "P") {
            return <span>{`${rowData.valor} %`}</span>;
        }

        return parseNumberToMoneyHTML(rowData.valor);
    };

    const tipoPrecoFilterTemplate = (options) => {
        return (
            <Dropdown
                options={TIPO_PRECO_SERVICO}
                value={options.value}
                placeholder="Selecione"
                optionValue="id"
                optionLabel="label"
                showClear
                onChange={(e) => options.filterCallback(e.value, options.index)}
                className="p-column-filter"
            />
        );
    };

    const statusFilterTemplate = (options) => {
        return (
            <Dropdown
                options={SIM_NAO_BOOLEAN}
                value={options.value}
                placeholder="Selecione"
                optionValue="id"
                optionLabel="label"
                showClear
                onChange={(e) => options.filterCallback(e.value, options.index)}
                className="p-column-filter"
            />
        );
    };

    const incorporadoSKUFilterTemplate = (options) => {
        return (
            <Dropdown
                options={SIM_NAO_BOOLEAN}
                value={options.value}
                placeholder="Selecione"
                optionValue="id"
                optionLabel="label"
                showClear
                onChange={(e) => options.filterCallback(e.value, options.index)}
                className="p-column-filter"
            />
        );
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { width: "8%" },
            filter: true,
        },
        { field: "nome", header: "Nome", filter: true },
        {
            field: "tipo_preco_servico",
            header: "Tipo de preço",
            style: { width: "12%" },
            filter: true,
            filterElement: tipoPrecoFilterTemplate,
            action: (e) => tipoPrecoBodyTemplate(e),
        },
        {
            field: "valor",
            header: "Valor",
            action: (e) => valorBodyTemplate(e),
            style: { width: "10%" },
        },
        {
            field: "incorporado_sku",
            header: "Incorporado no produto?",
            filter: true,
            filterElement: (e) => incorporadoSKUFilterTemplate(e),
            action: (e) => incorporaSkuBodyTemplate(e),
            style: { width: "18%" },
        },
        {
            field: "ativo",
            header: "Ativo",
            filter: true,
            filterElement: (e) => statusFilterTemplate(e),
            action: (e) => ativoBodyTemplate(e),
            style: { width: "8%" },
        },
        { field: "action", header: "Ações", action: (e) => actionBodyTemplate(e), style: { width: "10%" } },
    ];

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Listagem de Serviços de Vendas"
                        colunas={colunas}
                        painelEsquerdo={cabecalhoTabela}
                        urlPesquisa={URL}
                        filtros={{
                            id: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "equals" }],
                            },
                            nome: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "icontains" }],
                            },
                            tipo_preco_servico: { value: null, matchMode: "equals" },
                            incorporado_sku: { value: null, matchMode: "equals" },
                            ativo: { value: null, matchMode: "equals" },
                        }}
                        configTabela={{
                            lazy: true,
                            paginator: true,
                            filterDisplay: "menu",
                        }}
                    />
                    <ConfirmDialog
                        visible={deleteDialog}
                        onHide={esconderDeleteDialog}
                        header="Confirmação"
                        message={
                            servico && (
                                <span>
                                    {valueAtivo
                                        ? "Deseja realmente ativar o serviço "
                                        : "Deseja realmente desativar o serviço "}
                                    <b>{servico.nome}</b>?
                                </span>
                            )
                        }
                        icon="pi pi-info-circle p-mr-3"
                        accept={deleteServicoVenda}
                        acceptLabel="Sim"
                        acceptClassName="p-button-danger"
                        reject={esconderDeleteDialog}
                        rejectLabel="Não"
                    />
                </div>
            </div>
        </div>
    );
};
