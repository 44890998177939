import { useCallback, useState } from "react";

const useLoadingLocal = () => {
    const [loading, setLoading] = useState(false);
    const showLoading = useCallback(() => {
        setLoading(true);
    }, [])
    const hideLoading = useCallback(() => {
        setLoading(false)
    }, [])
    return [loading, showLoading, hideLoading];
};

export default useLoadingLocal;
