import React from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { useFormik } from "formik";
import * as Yup from "yup";

import { DetalhesTemplateRateioFormBase } from "./detalhes";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { Dropdown } from "@/components/Dropdown";
import { NATUREZA_FINANCEIRA } from "@/assets/constants/financeiro";
import { parseNumber } from "@/assets/helpers/number";
import { axiosPost, axiosPut } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

const URL_API = "/financeiro/templates-rateios/";

export const TemplateRateioFormBase = ({ derivar, onSubmit, propsButtonCancelar, ...props }) => {
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();

    const templateRateio = props.location?.state
        ? {
              ...props.location.state,
              empresa: props.location.state.empresa.id,
          }
        : null;

    const formik = useFormik({
        initialValues: templateRateio || {
            descricao: "",
            empresa: null,
            ratear_fase_projeto: false,
            ratear_centro_resultado: false,
            validar_percentual: true,
            natureza: null,
            template: true,
            ativo: true,
            templatedetalherateio_set: [],
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                empresa: Yup.number().required("O campo 'empresa' é obrigatório.").typeError("Selecione uma empresa."),
                natureza: Yup.string()
                    .required("O campo 'natureza' é obrigatório.")
                    .typeError("Selecione uma natureza para o rateio."),
                templatedetalherateio_set: Yup.array()
                    .min(1, "Você precisa inserir ao menos 1 detalhe para o rateio.")
                    .when("ratear_fase_projeto", {
                        is: true,
                        then: Yup.array().test({
                            name: "teste_ratear_fase_projeto",
                            test: (detalhes) => !!detalhes.find((d) => d.fase_projeto && !d._deletar),
                            message: "Você precisa inserir ao menos 1 detalhe com fase de projeto.",
                        }),
                    })
                    .when("ratear_centro_resultado", {
                        is: true,
                        then: Yup.array().test({
                            name: "teste_ratear_centro_resultado",
                            test: (detalhes) => !!detalhes.find((d) => d.centro_resultado && !d._deletar),
                            message: "Você precisa inserir ao menos 1 detalhe com centro de resultado.",
                        }),
                    })
                    .when("validar_percentual", {
                        is: true,
                        then: Yup.array().test({
                            name: "teste_percentual",
                            test: (detalhes) =>
                                detalhes.reduce(
                                    (total, detalhe) =>
                                        !detalhe._deletar ? total + parseNumber(detalhe.percentual) : total,
                                    0
                                ) === 100,
                            message: "O percentual precisa totalizar exatamente 100%.",
                        }),
                    }),
            });
            formSchema.validateSync(values, { abortEarly: false });
            let create = [];
            let update = {};
            let remove = [];
            if (derivar && values.id) delete values.id;
            values.templatedetalherateio_set.forEach((detalhe) => {
                if (derivar && detalhe.id) delete detalhe.id;
                if (detalhe._editar && detalhe.id) {
                    update[detalhe.id] = detalhe;
                } else if (detalhe._deletar && detalhe.id) {
                    remove.push(detalhe.id);
                } else if (!detalhe.id && !detalhe._deletar) {
                    create.push(detalhe);
                }
            });
            let templatedetalherateio_set = {};
            if (create.length > 0) templatedetalherateio_set.create = create;
            if (Object.keys(update).length > 0) templatedetalherateio_set.update = update;
            if (remove.length > 0) templatedetalherateio_set.remove = remove;
            const templateRateio = {
                ...values,
                template: !!!derivar,
                templatedetalherateio_set,
            };
            if (!values.id) {
                
                showLoading();
                const { status, data } = await axiosPost(URL_API, templateRateio);
                hideLoading();
                if (status === 201) {
                    showSuccess({
                        summary: "Sucesso!",
                        detail: "Regra de rateio cadastrada com sucesso.",
                        life: 1500,
                    });
                    setTimeout(() => formik.resetForm({ values: { ...data, empresa: data.empresa.id } }), 2000);
                    if (typeof onSubmit === "function") onSubmit();
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const { status, data } = await axiosPut(`${URL_API}${values.id}/`, templateRateio);
                hideLoading();
                if (status === 200) {
                    showSuccess({
                        summary: "Sucesso!",
                        detail: "Regra de rateio alterada com sucesso.",
                        life: 1500,
                    });
                    setTimeout(() => formik.resetForm({ values: { ...data, empresa: data.empresa.id } }), 2000);
                    if (typeof onSubmit === "function") onSubmit();
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    return (
        <>
            <h3>{!formik.values.id ? "Nova regra de rateio" : "Manutenção de regra de rateio"}</h3>
            <form>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="empresa">Empresa *</label>
                        <MakoDropdownEmpresas
                            id="empresa"
                            name="empresa"
                            value={formik.values.empresa}
                            onChange={(e) => formik.setFieldValue("empresa", e.id)}
                            className={classNames({ "p-invalid": formik.errors.empresa })}
                        />
                        {formik.errors.empresa && <small className="p-error">{formik.errors.empresa}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="descricao">Descrição *</label>
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="natureza">Natureza *</label>
                        <Dropdown
                            id="natureza"
                            name="natureza"
                            options={NATUREZA_FINANCEIRA}
                            value={formik.values.natureza}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.natureza })}
                        />
                        {formik.errors.natureza && <small className="p-error">{formik.errors.natureza}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field-checkbox p-col-12 p-md-3">
                        <Checkbox
                            inputId="ratear-fase-projeto"
                            name="ratear_fase_projeto"
                            checked={formik.values.ratear_fase_projeto}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="ratear-fase-projeto">Ratear fase de projeto?</label>
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-3">
                        <Checkbox
                            inputId="ratear-centro-resultado"
                            name="ratear_centro_resultado"
                            checked={formik.values.ratear_centro_resultado}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="ratear-centro-resultado">Ratear centro de resultado?</label>
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-2">
                        <Checkbox
                            inputId="validar-percentual"
                            name="validar_percentual"
                            checked={formik.values.validar_percentual}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="validar-percentual">Validar o percentual?</label>
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-2">
                        <Checkbox
                            inputId="ativo"
                            name="ativo"
                            checked={formik.values.ativo}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="ativo">Ativo?</label>
                    </div>
                </div>
                {formik.errors.templatedetalherateio_set && (
                    <small className="p-error">{formik.errors.templatedetalherateio_set}</small>
                )}
            </form>
            <DetalhesTemplateRateioFormBase
                detalhesTemplateRateio={formik.values.templatedetalherateio_set}
                onSubmit={(e) => formik.setFieldValue("templatedetalherateio_set", e)}
            />
            <div className="p-grid p-mt-2">
                <div className="p-col-12 p-md-6">
                    <Button
                        type="submit"
                        icon="pi pi-save"
                        label="Gravar"
                        className="p-mr-2"
                        onClick={formik.handleSubmit}
                    />
                    <Button type="button" className="p-button-danger" {...propsButtonCancelar} />
                </div>
            </div>
        </>
    );
};
