import React, { useRef } from "react";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";

import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { PageBase } from "@/components/PageBase";
import { BotaoDelete } from "@/components/BotaoDelete";
import permissoes from "@/assets/constants/permissoes";
import useClearRefs from "@/hooks/useClearRefs";
import { CodigoFiltroTemplate, DropdownFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import useEmpresa from "@/hooks/useEmpresa";

export const EstoquesPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();
    const { empresaSelecionada } = useEmpresa();
    useClearRefs(listagemRef);

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.ESTOQUE_CADASTRO_ESTOQUES_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de estoque"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/estoque/cadastros/estoques-empresas/form",
                            state: { estoque: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.ESTOQUE_CADASTRO_ESTOQUES_EXCLUIR]}
                    componente={BotaoDelete}
                    url="/produtos/estoques-empresas/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir o estoque <b>{rowData.nome}</b>?
                        </span>
                    }
                    classNames="p-mb-1"
                    tooltip="Deletar estoque"
                    tooltipOptions={{ position: "left" }}
                    msgToastErroExclusao="O estoque não pode ser excluído."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <Button
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/estoque/cadastros/estoques-empresas/form")}
            />
        </>
    );

    const enderecoTemplate = (option) => {
        return (
            <span>{`${option.logradouro} - ${option.numero} - ${option.bairro} - ${option.cep}`}</span>
        );
    }

    const EnderecoFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "id",
                    optionLabel: "logradouro",
                    options: empresaSelecionada.enderecoperfil_set,
                    itemTemplate: enderecoTemplate,
                    valueTemplate: (option, props) => {
                        if (option) return enderecoTemplate(option);
                        return <span>{props.placeholder}</span>;
                    }
                }}
                options={options}
            />
        );
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "12%" }, filter: true, filterElement: CodigoFiltroTemplate },
        { field: "nome", header: "Nome", filter: true, filterElement: TextoFiltroTemplate },
        { field: "descricao", header: "Descrição", filter: true, filterElement: TextoFiltroTemplate },
        { field: "endereco.logradouro", header: "Endereço", filter: true, filterElement: EnderecoFiltroTemplate, filterField: "endereco" },
        {
            field: "action",
            header: "Ações",
            style: { width: "10%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Listagem de estoques"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa="/produtos/estoques-empresas/"
                filtarPorEmpresa
                fieldFiltroEmpresa="empresa__id"
                naoBuscarSemEmpresa
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    nome: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    endereco: { value: null, matchMode: "equals" },
                }}
            />
        </PageBase>
    );
};
