import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";
import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { axiosGet } from "@/services/http";
import useDevolucaoFornecedor from "@/hooks/useDevolucaoFornecedor";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

const ModalParcela = ({ fecharModal, onConfirm }) => {
    const [planos, setPlanos] = useState([]);
    const [pagamentos, setPagamentos] = useState([]);
    const [recebimentos, setRecebimentos] = useState([]);

    const { hideLoading, showLoading } = useLoading();
    const { vendas, parcelas } = useDevolucaoFornecedor();
    const { showError } = useToast();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            id: null,
            _id: null,
            venda: null, //
            numero_parcela: null,
            forma_recebimento: null, //
            data_vencimento: null, //
            valor: null, //
            pagamento: null, //
            plano_contas: null, //
            custo_devolucao: false, //
            perfil_credor: null, //
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                venda: Yup.number()
                    .required("O campo 'venda' é obrigatório")
                    .typeError("Informe uma 'quantidade' válida"),
                forma_recebimento: Yup.number()
                    .required("O campo 'forma de recebimento' é obrigatório")
                    .typeError("Informe uma 'forma de recebimento' válida"),
                data_vencimento: Yup.date()
                    .required("O campo 'data de vencimento' é obrigatório")
                    .typeError("Informe uma 'data de vencimento' válida"),
                valor: Yup.number()
                    .nullable()
                    .min(0.01, "O campo 'valor unitário' não pode ser ZERO.")
                    .notRequired()
                    .typeError("Informe uma 'valor unitário' válido"),
                pagamento: Yup.number()
                    .nullable()
                    // .required("O campo 'pagamento   ' é obrigatório")
                    .typeError("Informe um 'pagamento   ' válido"),
                plano_contas: Yup.number()
                    .required("O campo 'plano de contas' é obrigatório")
                    .typeError("Informe um 'plano de contas' válido"),
                perfil_credor: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'perfil credor' é obrigatório"),
                    })
                    .typeError("Informe um 'perfil credor' válido"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            values.numero_parcela = parcelas.length + 1;

            if (typeof onConfirm === "function") {
                if (onConfirm(values)) {
                    formik.resetForm();
                    fecharModal();
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos processar sua requisição.",
                    life: 3000,
                });
            }
        }
    }

    const autoCompleteTemplate = (cliente) => {
        cliente.label = `${cliente.nome} - ${cliente.identificacao}`;
        return <div>{`${cliente.nome} - ${cliente.identificacao}`}</div>;
    };

    const fetchPlanosContas = useCallback(async () => {
        showLoading();
        const response = await axiosGet(`/financeiro/planos-contas-financeiras/?ativo=True`);
        hideLoading();

        if (response.status === 200) {
            setPlanos(response.data.results);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos processar sua requisição.",
                life: 3000,
            });
        }
    }, [setPlanos, showLoading, hideLoading, showError]);

    const fetchPagamentos = useCallback(async () => {
        if (typeof formik.values.perfil_credor !== "string" && formik.values.perfil_credor === null) {
            showLoading();
            const response = await axiosGet(`/financeiro/pagamentos/?credor=${formik.values.perfil_credor?.id}`);
            hideLoading();

            if (response.status === 200) {
                setPagamentos(response.data.results);
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos processar sua requisição.",
                    life: 3000,
                });
            }
        }
    }, [setPagamentos, showLoading, hideLoading, showError, formik.values.perfil_credor]);

    const fetchRecebimentos = useCallback(async () => {
        showLoading();
        const response = await axiosGet(`/financeiro/formas-recebimentos/`);
        hideLoading();

        if (response.status === 200) {
            setRecebimentos(response.data.results);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos processar sua requisição.",
                life: 3000,
            });
        }
    }, [setRecebimentos, showLoading, hideLoading, showError]);

    useEffect(() => {
        fetchPlanosContas();
        fetchPagamentos();
        fetchRecebimentos();
    }, [fetchPlanosContas, fetchPagamentos, fetchRecebimentos]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="venda">Venda *</label>
                    <Dropdown
                        id="venda"
                        name="venda"
                        options={vendas}
                        optionValue="id_venda"
                        optionLabel="label"
                        value={formik.values.venda}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.venda })}
                    />
                    {formik.errors.venda && <small className="p-error">{formik.errors.venda}</small>}
                </div>
                <div className="p-field p-col-12 p-md-6">
                    <label htmlFor="perfil_credor">Credor: *</label>
                    <MakoAutoComplete
                        id="perfil_credor"
                        name="perfil_credor"
                        value={formik.values.perfil_credor}
                        onChange={formik.handleChange}
                        minCaracteresBusca={1}
                        itemTemplate={autoCompleteTemplate}
                        field="label"
                        urlSearch={`/pessoas/perfis?search=`}
                        placeholder="Comece a digitar informações do perfil para buscar..."
                    />
                    {formik.errors.perfil_credor && <small className="p-error">{formik.errors.perfil_credor}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="plano_contas">Plano de contas *</label>
                    <Dropdown
                        id="plano_contas"
                        name="plano_contas"
                        options={planos}
                        optionValue="id"
                        optionLabel="descricao"
                        value={formik.values.plano_contas}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.plano_contas })}
                    />
                    {formik.errors.plano_contas && <small className="p-error">{formik.errors.plano_contas}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="data_vencimento">Data Vencimento:</label>
                    <MakoCalendar
                        id="data_vencimento"
                        name="data_vencimento"
                        minDate={new Date()}
                        valueCalendar={formik.values.data_vencimento}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.data_vencimento })}
                    />
                    {formik.errors.data_vencimento && (
                        <small className="p-error">{formik.errors.data_vencimento}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="valor">Valor *</label>
                    <MakoInputMoeda
                        id="valor"
                        name="valor"
                        value={formik.values.valor}
                        onValueChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.valor })}
                    />
                    {formik.errors.valor && <small className="p-error">{formik.errors.valor}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="pagamento">Pagamento *</label>
                    <Dropdown
                        id="pagamento"
                        name="pagamento"
                        options={pagamentos}
                        optionValue="id"
                        optionLabel="valor"
                        value={formik.values.pagamento}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.pagamento })}
                    />
                    {formik.errors.pagamento && <small className="p-error">{formik.errors.pagamento}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="forma_recebimento">Forma recebimento *</label>
                    <Dropdown
                        id="forma_recebimento"
                        name="forma_recebimento"
                        options={recebimentos}
                        optionValue="id"
                        optionLabel="descricao"
                        value={formik.values.forma_recebimento}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.forma_recebimento })}
                    />
                    {formik.errors.forma_recebimento && (
                        <small className="p-error">{formik.errors.forma_recebimento}</small>
                    )}
                </div>
                <div className="p-field p-col-2 p-mt-2" style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <Checkbox
                        id="custo_devolucao"
                        name="custo_devolucao"
                        value={!formik.values.custo_devolucao}
                        checked={formik.values.custo_devolucao}
                        onChange={formik.handleChange}
                    />
                    <label htmlFor="custo_devolucao" className="p-mt-2">
                        Custo de devolução?
                    </label>
                </div>
            </div>
            <div>
                <Button label="Gravar" className="p-button-success p-mr-2" type="submit" />
                <Button label="Cancelar" className="p-button-danger" onClick={fecharModal} />
            </div>
        </form>
    );
};

export default React.memo(ModalParcela);
