import React, { useEffect, useRef, useState, useCallback } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { axiosGet } from "@/services/http";
import { SelectButton } from "primereact/selectbutton";
import { Dropdown } from "primereact/dropdown";

import useLocalStorage from "@/hooks/useLocalStorage";
import storageKeys from "@/assets/constants/storage";
import useLoading from "@/hooks/useLoading";
import axios from "axios";

export const EnvioEmailFormModal = (props) => {
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const [emailNaoCadastrado, setEmailNaoCadastrado] = useState("");
    const [emailCadastrado, setEmailCadastrado] = useState("");
    const [emailManual, setEmailManual] = useState(false);
    const [listaEmails, setListaEmails] = useState([]);
    const [empresaSelecionada] = useLocalStorage(storageKeys.EMPRESA_KEY);

    async function handleSubmit() {
        try {
            showLoading();
            const apiServico = await axiosGet("/configuracoes/api-servicos/");
            hideLoading();

            const body = [
                {
                    EmpCNPJ: empresaSelecionada.identificacao,
                    Modulo: "NFSe",
                    NFSe: {
                        Email: emailManual ? emailNaoCadastrado : "email@email.info",
                        NumeroRPSInicial: "1000",
                        NumeroRPSFinal: "1100",
                        Serie: "S",
                    },
                },
            ];

            showLoading();
            const resposta = await axios.post(
                `http://localhost:4000/documents/${apiServico.data.results[0].chave_acesso}/${apiServico.data.results[0].cnpj_integrado}/enviar-email`,
                body
            );
            hideLoading();

            if (resposta.data && resposta.data[0])
                if (resposta.data[0].Codigo === 100 || resposta.data[0].Codigo === 101) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso!",
                        detail: `${resposta.data[0].Descricao}!
                         \n
                         Código: ${resposta.data[0].Codigo}`,
                        life: 5000,
                    });
                    setTimeout(() => {
                        props.dialog(false);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "warn",
                        summary: "Mensagem",
                        detail: `${resposta.data[0].Descricao} \n
                    Código: ${resposta.data[0].Codigo}`,
                        life: 5000,
                    });
                }
            else if (resposta.data)
                toastRef.current.show({
                    severity: "warn",
                    summary: "Mensagem",
                    detail: `${resposta.data.Descricao} \n
                    Código: ${resposta.data.Codigo}`,
                    life: 5000,
                });
        } catch (error) {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: `Falha ao comunicar com o agente fiscal.`,
                life: 3000,
            });
        }
    }

    function validateForm() {
        return true;
    }

    const carregarEmails = useCallback(async () => {
        const resposta = await axiosGet(
            `/fiscal/notas-fiscais?id=${props.nota.destinatario.id}&query={id, identificacao}`
        );

        if (resposta.status === 200) setListaEmails(resposta.data.results);
    }, [props.nota.destinatario.id]);

    const EMAIL_OPCOES = [
        { label: "Email cadastrado", value: false },
        { label: "Envio manual", value: true },
    ];

    useEffect(() => {
        if (props.nota?.destinatario) carregarEmails();
    }, [carregarEmails, props.nota]);

    useEffect(() => {
        if (emailManual) setEmailCadastrado(null);
        else setEmailNaoCadastrado("");
    }, [emailManual]);

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <form>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <SelectButton
                                value={emailManual}
                                options={EMAIL_OPCOES}
                                onChange={(e) => setEmailManual(e.value)}
                            />
                        </div>
                        {!emailManual ? (
                            <div className="p-field p-col-12 p-md-12">
                                <Dropdown
                                    id="email_cadastrado"
                                    value={emailCadastrado}
                                    options={listaEmails}
                                    placeholder="Selecione um email"
                                    showClear
                                    onChange={(e) => setEmailCadastrado(e.target.value)}
                                    optionLabel="identificacao"
                                    optionValue="identificacao"
                                    emptyMessage="Destinatário não possui emails cadastrados"
                                />
                            </div>
                        ) : (
                            <div className="p-field p-col-12 p-md-12">
                                <InputText
                                    id="conteudo"
                                    placeholder="Digite um email válido"
                                    value={emailNaoCadastrado}
                                    onChange={(e) => setEmailNaoCadastrado(e.target.value)}
                                    autoComplete="off"
                                />
                            </div>
                        )}
                    </div>
                    <div className="p-grid p-col-12 p-md-12">
                        <Button
                            label="Enviar"
                            type="button"
                            className="p-button-info"
                            disabled={!validateForm()}
                            onClick={handleSubmit}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};
