import React, { useImperativeHandle, useState, forwardRef } from "react";
import { Dialog } from "primereact/dialog";
import { Slider } from "primereact/slider";

import { MakoInputPercent } from "@/components/MakoInputs/MakoInputPercent";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";

const Modal = ({ taxaAlvo, taxaMinima, onFechar }, ref) => {
    const [visible, setVisible] = useState(false);
    const [inputTaxa, setInputTaxa] = useState(taxaAlvo);

    const abrirModal = () => {
        setVisible(true);
    };

    const fecharModal = () => {
        if (typeof onFechar === "function") onFechar(inputTaxa);
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    return (
        <Dialog header="Taxa alvo das parcelas" visible={visible} onHide={fecharModal}>
            <div className="p-fluid">
                <p>{`Taxa mínima: ${taxaMinima}% *`}</p>
                <MakoInputPercent
                    min={taxaMinima}
                    casasDecimais={3}
                    value={inputTaxa}
                    onValueChange={(e) => setInputTaxa(e.value)}
                    className="p-fluid"
                />
                <Slider
                    value={inputTaxa}
                    min={taxaMinima}
                    onChange={(e) => setInputTaxa(e.value)}
                    className="p-w-full"
                />
                <CamposObrigatorios className="p-mt-2">* Taxas menores estão sujeitas a aprovação.</CamposObrigatorios>
            </div>
        </Dialog>
    );
};

export const ModalTaxaParcelas = forwardRef(Modal);
