import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { axiosPatch } from "@/services/http";
import { ToggleButton } from "primereact/togglebutton";

import { ModalFiltroItem } from "./filtrosAvancados/filtroItem";
import { PageBase } from "@/components/PageBase";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import { STATUS_ITEM } from "@/assets/constants/constants";
import { Tag } from "primereact/tag";
import { key_filtros } from "@/assets/constants/filtros";
import { useLocalFiltro } from "@/hooks/useLocalFiltro";
import { ModalFiltroSku } from "./filtrosAvancados/filtroSku";
import useClearRefs from "@/hooks/useClearRefs";
import {
    CodigoFiltroTemplate,
    DropdownFiltroTemplate,
    TextoFiltroTemplate,
    UnidadeMedidaFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";

const urlLimpaSku = `/produtos/sku?query={id,item,descricao_derivada,descricao_reduzida,descricao_complementar,unidade_padrao,ativo,codigo}&ativo=true`;
const urlLimpaItem = `/produtos/itens?query={id,nome,ncm,marca,status}&ativo=true`;

export const ProdutoProdutosPage = ({ location }) => {
    const [filtros, setFiltro, removerFiltro, filtroString] = useLocalFiltro(
        key_filtros.PRODUTOS_PRODUTOS_SKU_AVANCADO
    );
    const [filtrosItem, setFiltroItem, removerFiltroItem, filtroStringItem] = useLocalFiltro(
        key_filtros.PRODUTOS_PRODUTOS_SKU_TABELA
    );
    const [urlSku, setUrlSku] = useState(() => {
        const url = location?.flagDescricao === false ? location?.url : urlLimpaSku;
        if (filtros) return `${url}&${filtroString}`;
        return url;
    });
    const [urlItem, setUrlItem] = useState(() => {
        const url = location?.flagDescricao === false ? location?.url : urlLimpaItem;
        if (filtros) return `${url}&${filtroStringItem}`;
        return url;
    });
    const [descricaoExibida, setDescricaoExibida] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [produto, setProduto] = useState(null);

    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();

    const modalFiltroSku = useRef(null);
    const modalFiltroItem = useRef(null);
    const listagemRef = useRef(null);
    const history = useHistory();

    useClearRefs(listagemRef, modalFiltroSku, modalFiltroItem);

    const confirmarDelete = (produto) => {
        setProduto(produto);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const alteraStatusProduto = async () => {
        showLoading();
        const resposta = await axiosPatch(`/produtos/sku/${produto.id}/`, {
            ativo: !produto.ativo,
            descricao_reduzida: produto.descricao_reduzida,
        });
        hideLoading();

        if (resposta.status === 200) {
            showSuccess({
                summary: "Sucesso",
                detail: `Produto ${!produto.ativo ? "ativado" : "desativado"} com sucesso!`,
                life: 1500,
            });
            listagemRef.current?.buscarDados();
        } else {
            showError({
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
        }
        setDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_PRODUTOS_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar Cadastro de Produto"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/produto/produto/produtos/form",
                            state: {
                                id: rowData.item?.id || rowData.id,
                                flagDescricao: descricaoExibida,
                                url: descricaoExibida ? urlItem : urlSku,
                            },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_PRODUTOS_EXCLUIR]}
                    componente={Button}
                    icon={rowData.ativo ? "pi pi-unlock" : "pi pi-lock"}
                    className={
                        rowData.ativo
                            ? "p-button-rounded p-button-success p-mr-2 p-mb-1"
                            : "p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    }
                    onClick={() => confirmarDelete(rowData)}
                    tooltip={rowData.ativo ? "Alterar status (DESATIVAR)" : "Alterar status (ATIVAR)"}
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const alteraListagem = (valor) => {
        setDescricaoExibida(valor);
        if (!valor) {
            setUrlSku(urlLimpaSku);
        } else {
            setUrlItem(urlLimpaItem);
        }
    };

    const limparFiltro = useCallback(() => {
        if (descricaoExibida) {
            modalFiltroItem.current.limparFiltro();
            setUrlItem(urlLimpaItem);
        } else {
            modalFiltroSku.current.limparFiltro();
            setUrlSku(urlLimpaSku);
        }
    }, [descricaoExibida]);

    const handleModal = useCallback(() => {
        descricaoExibida ? modalFiltroItem.current?.abrirModal() : modalFiltroSku.current?.abrirModal();
    }, [descricaoExibida]);

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.PRODUTO_PRODUTO_PRODUTOS_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2 p-mb-2"
                onClick={() =>
                    history.push({
                        pathname: "/produto/produto/produtos/form",
                        state: { url: descricaoExibida ? urlItem : urlSku },
                    })
                }
            />
            <Button
                label="Filtro Avançado"
                icon="pi pi-filter"
                className="p-button-help p-mr-2 p-mb-2"
                onClick={handleModal}
            />
            <ToggleButton
                checked={descricaoExibida}
                onChange={(e) => alteraListagem(e.value)}
                onLabel="Exibir desagrupado"
                offLabel="Exibir agrupado"
                onIcon="pi pi-th-large"
                offIcon="pi pi-stop"
                className="p-mr-2 p-mb-2"
                style={{ width: "15em" }}
            />
            {urlSku !== urlLimpaSku || urlItem !== urlLimpaItem ? (
                <Button
                    label="Limpar filtros"
                    icon="pi pi-trash"
                    className="p-button-warning p-mr-2 p-mb-2"
                    onClick={limparFiltro}
                />
            ) : null}
        </>
    );

    const rowClass = (rowData) => {
        return {
            "mako-table-overdue-text": rowData.status !== "F" && rowData.item?.status !== "F",
        };
    };

    const statusFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "id",
                    optionLabel: "label",
                    options: STATUS_ITEM,
                }}
                options={options}
            />
        );
    };

    const marcaFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "id",
                    optionLabel: "nome",
                    url: "/produtos/marcas/",
                }}
                options={options}
            />
        );
    };

    const colunasDinamica = [
        {
            field: "codigo",
            header: "Código",
            filter: true,
            filterElement: CodigoFiltroTemplate,
            style: { width: "10%" },
        },
        {
            field: "item.id",
            header: "ID",
            filter: true,
            filterElement: CodigoFiltroTemplate,
            filterField: "item__id",
            style: { width: "8%" },
        },
        {
            field: "descricao_derivada",
            header: "Descrição",
            filter: true,
            filterElement: TextoFiltroTemplate,
            filterField: "descricao_derivada",
        },
        {
            field: "unidade_padrao.sigla",
            header: "Unidade",
            filter: true,
            filterElement: UnidadeMedidaFiltroTemplate,
            filterField: "unidade_padrao__id",
            style: { width: "18%" },
        },
        {
            field: "item.status",
            header: "Status",
            align: "center",
            filter: true,
            filterElement: statusFiltroTemplate,
            filterField: "item__status",
            style: { width: "10%" },
            action: ({ item }) => {
                const _status = STATUS_ITEM.find(({ id }) => id === item?.status);
                if (_status) return <Tag severity={_status.tag} value={_status.label.toLocaleUpperCase()} />;
            },
        },
        { field: "action", header: "Ações", action: (e) => actionBodyTemplate(e), style: { width: "10%" } },
    ];

    const colunasGenerica = [
        {
            field: "id",
            header: "ID",
            filter: true,
            filterElement: CodigoFiltroTemplate,
            style: { width: "10%" },
        },
        { field: "nome", header: "Descrição", filter: true, filterElement: TextoFiltroTemplate },
        {
            field: "marca.nome",
            header: "Marca",
            filter: true,
            filterField: "marca",
            filterElement: marcaFiltroTemplate,
        },
        {
            field: "ncm.codigo",
            header: "NCM",
            filterField: "ncm__codigo",
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "item.status",
            header: "Status",
            align: "center",
            filter: true,
            filterElement: statusFiltroTemplate,
            filterField: "status",
            style: { width: "10%" },
            action: ({ status }) => {
                const _status = STATUS_ITEM.find(({ id }) => id === status);
                if (_status) return <Tag severity={_status.tag} value={_status.label.toLocaleUpperCase()} />;
            },
        },
        { field: "action", header: "Ações", action: (e) => actionBodyTemplate(e), style: { width: "10%" } },
    ];

    const filtrosCabecalho = useMemo(() => {
        return descricaoExibida
            ? {
                  id: { value: null, matchMode: "equals" },
                  nome: { value: "", matchMode: "icontains" },
                  marca: { value: null, matchMode: "equals" },
                  ncm__codigo: { value: "", matchMode: "icontains" },
                  status: { value: null, matchMode: "equals" },
              }
            : {
                  codigo: { value: null, matchMode: "equals" },
                  item__id: { value: null, matchMode: "equals" },
                  descricao_derivada: { value: "", matchMode: "equals" },
                  unidade_padrao__id: { value: null, matchMode: "equals" },
                  item__status: { value: null, matchMode: "equals" },
              };
    }, [descricaoExibida]);

    useEffect(() => listagemRef.current?.trocarFilters(), [descricaoExibida]);

    return (
        <PageBase>
            {descricaoExibida ? (
                <MakoListagem
                    ref={listagemRef}
                    titulo="Produtos e mercadorias"
                    colunas={colunasGenerica}
                    painelEsquerdo={cabecalhoTabela}
                    urlPesquisa={urlItem}
                    filtarPorEmpresa
                    naoBuscarSemEmpresa
                    filtros={filtrosCabecalho}
                    fieldFiltroEmpresa={"empresa"}
                    configTabela={{
                        paginator: true,
                        lazy: true,
                        rowClassName: rowClass,
                    }}
                />
            ) : (
                <MakoListagem
                    ref={listagemRef}
                    titulo="Produtos e mercadorias"
                    colunas={colunasDinamica}
                    painelEsquerdo={cabecalhoTabela}
                    urlPesquisa={urlSku}
                    filtarPorEmpresa
                    naoBuscarSemEmpresa
                    filtros={filtrosCabecalho}
                    fieldFiltroEmpresa={"item_empresa"}
                    configTabela={{
                        paginator: true,
                        lazy: true,
                        rowClassName: rowClass,
                    }}
                />
            )}
            <ConfirmDialog
                visible={deleteDialog}
                onHide={esconderDeleteDialog}
                header="Confirmação"
                message={
                    produto && (
                        <span>
                            {produto.ativo
                                ? "Deseja realmente desativar o produto "
                                : "Deseja realmente ativar o produto "}
                            <b>{produto.nome}</b>?
                        </span>
                    )
                }
                icon="pi pi-info-circle p-mr-3"
                accept={alteraStatusProduto}
                acceptLabel="Sim"
                acceptClassName="p-button-danger"
                reject={esconderDeleteDialog}
                rejectLabel="Não"
            />
            <ModalFiltroSku
                ref={modalFiltroSku}
                setUrl={setUrlSku}
                filtros={filtros}
                setFiltro={setFiltro}
                removerFiltro={removerFiltro}
            />
            <ModalFiltroItem
                ref={modalFiltroItem}
                setUrl={setUrlItem}
                filtros={filtrosItem}
                setFiltro={setFiltroItem}
                removerFiltro={removerFiltroItem}
            />
        </PageBase>
    );
};
