import React, { useState } from "react";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import MakoListagem from "../MakoListagem";

//DOCS
//onCancel -> funcção executada ao clicar em cancelar
//onConfirm -> função executada ao confirmar, retornando os email selecionados

export const MakoSelecionarEmails = ({ onCancel = null, onConfirm = null }) => {
    const [textEmail, setTextEmail] = useState("");
    const [toEmail, setToEmail] = useState([]);
    const [noValidate, setNoValidate] = useState(false);

    const regexEmail =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    const handleAddEmail = () => {
        if (regexEmail.test(textEmail)) {
            setToEmail((prev) => [...prev, textEmail]);
            setTextEmail("");
        } else {
            setNoValidate(true);
            setTimeout(() => setNoValidate(false), [2000]);
        }
    };

    const handleCancelEmail = () => {
        setToEmail([]);
        setTextEmail("");

        if (onCancel && typeof onCancel === "function") {
            onCancel();
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <Button
                    type="button"
                    icon="pi pi-trash"
                    onClick={() => setToEmail((prev) => prev.filter((item) => item !== rowData))}
                    className="p-button-rounded p-button-danger"
                />
            </>
        );
    };

    const colunas = [
        { field: "", header: "", action: (rowData) => rowData },
        {
            field: "action",
            header: "Ações",
            style: { width: "10%" },
            action: (rowData) => actionBodyTemplate(rowData, "sku"),
        },
    ];

    return (
        <>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-9">
                    <InputText
                        placeholder="Digite o email"
                        type="email"
                        value={textEmail}
                        onChange={(e) => setTextEmail(e.target.value)}
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <Button
                        type="button"
                        label="Adicionar"
                        onClick={handleAddEmail}
                        icon="pi pi-plus"
                        className="p-button-success"
                    />
                </div>
            </div>
            {noValidate && <Message severity="error" text="Informe um email valido." className="p-mb-4" />}
            <div className="p-mb-4">
                <MakoListagem titulo="Email selecionados:" dadosLocal={toEmail} colunas={colunas} />
            </div>
            <div>
                <Button
                    type="button"
                    label="Concluir"
                    onClick={() => onConfirm(toEmail)}
                    disabled={!toEmail.length > 0}
                    className="p-button-success p-mr-2"
                />
                <Button type="button" label="Cancelar" onClick={handleCancelEmail} className="p-button-danger p-mr-2" />
            </div>
        </>
    );
};
