import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { axiosPatch } from "@/services/http";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import { Tag } from "primereact/tag";
import useToast from "@/hooks/useToast";
import {
    CodigoFiltroTemplate,
    ContaFinanceiraFiltroTemplate,
    DropdownFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";

const url = "/financeiro/caixas/";

export const CaixaPage = () => {
    const [redirecionar, setRedirecionar] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [caixa, setcaixa] = useState(null);

    const { showLoading, hideLoading } = useLoading();
    const { showError, showSuccess } = useToast();

    const listagemRef = useRef(null);

    const confirmarDelete = (caixa) => {
        setcaixa(caixa);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const desativarCaixa = async (status) => {
        showLoading();
        const resposta = await axiosPatch(`${url}${caixa.id}/`, { ativo: status });
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            showSuccess({
                summary: "Sucesso",
                detail: `Caixa ${!status ? "desativado" : "ativado"} com sucesso!`,
                life: 3000,
            });

            listagemRef.current?.buscarDados();
        } else {
            showError({
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
        }
        setDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_CAIXA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    tooltip="Editar"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        setRedirecionar({
                            pathname: "/financeiro/cadastros/caixas/form",
                            state: { caixa: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_CAIXA_MANUTENCAO]}
                    componente={Button}
                    icon={rowData.ativo ? "pi pi-unlock" : "pi pi-lock"}
                    className={`p-button-rounded p-button-${rowData.ativo ? "danger" : "success"}`}
                    onClick={() => confirmarDelete(rowData)}
                    tooltip={`${rowData.ativo ? "Desativar" : "Ativar"} caixa`}
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_FINANCEIRO_CAIXA_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setRedirecionar("/financeiro/cadastros/caixas/form")}
            />
        </>
    );

    const STATUS = [
        { value: true, label: "Ativo", tag: "success" },
        { value: false, label: "Desativado", tag: "danger" },
    ];

    const statusFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: STATUS,
                }}
                options={options}
            />
        );
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "5%" }, filter: true, filterElement: CodigoFiltroTemplate },
        { field: "nome", header: "Nome", filter: true, filterElement: TextoFiltroTemplate },
        {
            field: "conta_financeira",
            header: "Conta financeira",
            action: ({ conta_financeira }) => `${conta_financeira.codigo} - ${conta_financeira.descricao}`,
            filter: true,
            filterElement: ContaFinanceiraFiltroTemplate,
        },
        {
            field: "ativo",
            header: "Ativo",
            align: "center",
            style: { width: "10%" },
            action: ({ ativo }) => {
                const _tag = STATUS.find((item) => item.value === ativo);
                return <Tag severity={_tag.tag} value={_tag.label.toLocaleUpperCase()} />;
            },
            filter: true,
            filterElement: statusFiltroTemplate,
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    return redirecionar ? (
        <Redirect to={redirecionar} />
    ) : (
        <>
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <MakoListagem
                            ref={listagemRef}
                            titulo="Caixas"
                            colunas={colunas}
                            painelEsquerdo={cabecalhoTabela}
                            urlPesquisa={url}
                            responsiva
                            configTabela={{
                                paginator: true,
                                lazy: true,
                            }}
                            filtros={{
                                id: { value: null, matchMode: "equals" },
                                nome: {
                                    operator: "and",
                                    constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                                },
                                ativo: { value: null, matchMode: "equals" },
                                conta_financeira: { value: null, matchMode: "equals" },
                            }}
                        />
                        <ConfirmDialog
                            visible={deleteDialog}
                            onHide={esconderDeleteDialog}
                            header="Confirmação"
                            message={
                                caixa && (
                                    <span>
                                        {`Deseja realmente ${caixa.status ? "ativar" : "desativar"} o caixa: `}
                                        <b>{caixa.nome}</b>?
                                    </span>
                                )
                            }
                            icon="pi pi-info-circle p-mr-3"
                            accept={() => desativarCaixa(!caixa.ativo)}
                            acceptLabel="Sim"
                            acceptClassName="p-button-danger"
                            reject={esconderDeleteDialog}
                            rejectLabel="Não"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
