import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { OP_CRUD_DJANGO } from "@/assets/util/persistenciaDjango";
import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";
import { Dropdown } from "@/components/Dropdown";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoInputQuantidadeSku } from "@/components/MakoInputs/MakoInputQuantidadeSku";
import useEntrada from "@/hooks/useEntrada";

export const TabItemEntrada = ({ itemEntrada }) => {
    const [destinacoesEntrada, setDestinacoesEntrada] = useState([]);
    const [unidadesCompra, setUnidadesCompra] = useState([]);
    const { dadosBasicos, handleItemEntrada } = useEntrada();

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: {
            sku: null,
            destinacao: null,
            item_nota_fiscal: null,
            item_ordem_compra: null,
            altera_estoque: true,
            quantidade: 0,
            valor_unitario: 0,
            valor_total_item: 0,
            largura: 1,
            altura: 1,
            comprimento: 1,
            cubagem: 1,
            unidade_medida: null,
            descricao_complementar: "",
            custo_liquido: 0,
            custo_bruto: 0,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                sku: Yup.object()
                    .typeError("Você precisa buscar e selecionar um produto.")
                    .required("Você precisa buscar e selecionar um produto."),
                destinacao: Yup.number()
                    .required("O campo 'destinação' é obrigatório")
                    .typeError("Selecione uma destinação."),
                altera_estoque: Yup.bool()
                    .required("Informe se o estoque deve ser alterado ou não.")
                    .typeError("Informe se o estoque deve ser alterado ou não."),
                quantidade: Yup.number()
                    .when("sku", {
                        is: (value) => value?.movimenta_cubagem,
                        then: Yup.number().min(0.000001, "O campo 'quantidade' não pode ser ZERO."),
                        otherwise: Yup.number().min(1, "O campo 'quantidade' não pode ser ZERO."),
                    })
                    .required("O campo 'quantidade' não pode ser ZERO."),
                valor_unitario: Yup.number().min(0.01, "O campo 'valor unitário' não pode ser ZERO."),
                valor_total_item: Yup.number().min(0.01, "O campo 'valor total do item' não pode ser ZERO."),
                unidade_medida: Yup.number()
                    .required("Selecione uma unidade de medida.")
                    .typeError("Selecione uma unidade de medida."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            const destinacao = destinacoesEntrada.find((e) => e.id === values.destinacao);
            const itemEntrada = {
                ...values,
                estoque_empresa: destinacao.centro_estocagem_destino.estoque_empresa.id,
                centro_estocagem: destinacao.centro_estocagem_destino.id,
            };
            const op = !values.id ? OP_CRUD_DJANGO.novo : OP_CRUD_DJANGO.editar;
            handleItemEntrada(itemEntrada, op);
            formik.resetForm();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const setInformacoesItem = useCallback(() => {
        if (formik.values.sku instanceof Object) {
            const { unidademedidasku_set } = formik.values.sku;
            const unidades = unidademedidasku_set
                .map((und) => {
                    if (und.ativo && und.tipo_mov_und_medida.id === "C") {
                        return {
                            id: und.unidade.id,
                            nome: `${und.unidade.nome} (${und.unidade.sigla})`,
                        };
                    }
                    return null;
                })
                .filter(Boolean);
            setUnidadesCompra(unidades);
            if (unidades.length === 1) {
                setFieldValue("unidade_medida", unidades[0].id);
            }
        } else if (formik.values.sku === null) {
            setUnidadesCompra([]);
        }
    }, [setFieldValue, formik.values.sku]);

    useEffect(() => {
        setInformacoesItem();
    }, [setInformacoesItem]);

    const calcularValorTotalItem = useCallback(() => {
        if (formik.values.quantidade > 0 && formik.values.valor_unitario > 0) {
            const total = formik.values.quantidade * formik.values.valor_unitario * formik.values.cubagem;
            setFieldValue("valor_total_item", total);
        }
    }, [formik.values.quantidade, formik.values.valor_unitario, formik.values.cubagem, setFieldValue]);

    useEffect(() => {
        calcularValorTotalItem();
    }, [calcularValorTotalItem]);

    useEffect(() => {
        if (itemEntrada) setValues(itemEntrada);
    }, [setValues, itemEntrada]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <MakoBuscaSkuPersonalizada
                skuValue={formik.values.sku}
                skuChange={(e) => {
                    setFieldValue("sku", e);
                    setInformacoesItem(e);
                }}
                skuError={formik.errors.sku}
                disabledBusca={!!formik.values.id}
                exibeDimensoes
                categoriaId={dadosBasicos?.categoria_titulo}
                onChangeAltura={(e) => setFieldValue("altura", e)}
                onChangeLargura={(e) => setFieldValue("largura", e)}
                onChangeComprimento={(e) => setFieldValue("comprimento", e)}
            />
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="altera-estoque">Altera estoque ? *</label>
                    <Dropdown
                        id="altera-estoque"
                        name="altera_estoque"
                        options={SIM_NAO_BOOLEAN}
                        optionValue="id"
                        optionLabel="label"
                        placeholder="Selecione"
                        onChange={formik.handleChange}
                        value={formik.values.altera_estoque}
                        className={classNames({ "p-invalid": formik.errors.altera_estoque })}
                    />
                    {formik.errors.altera_estoque && <small className="p-error">{formik.errors.altera_estoque}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="quantidade">Quantidade *</label>
                    <MakoInputQuantidadeSku
                        id="quantidade"
                        name="quantidade"
                        permiteFracionario={formik.values.sku?.movimenta_cubagem}
                        value={formik.values.quantidade}
                        onValueChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.quantidade })}
                    />
                    {formik.errors.quantidade && <small className="p-error">{formik.errors.quantidade}</small>}
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <label htmlFor="unidade">Unidade de compra *</label>
                    <Dropdown
                        id="unidade"
                        name="unidade_medida"
                        optionValue="id"
                        optionLabel="nome"
                        options={unidadesCompra}
                        placeholder="Selecione"
                        emptyMessage="Sem registros para listar"
                        onChange={formik.handleChange}
                        value={formik.values.unidade_medida}
                        className={classNames({ "p-invalid": formik.errors.unidade_medida })}
                    />
                    {formik.errors.unidade_medida && <small className="p-error">{formik.errors.unidade_medida}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="valor-unitario">Valor unitário *</label>
                    <MakoInputMoeda
                        id="valor-unitario"
                        name="valor_unitario"
                        valueMoeda={formik.values.valor_unitario}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.valor_unitario })}
                    />
                    {formik.errors.valor_unitario && <small className="p-error">{formik.errors.valor_unitario}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="valor-total">Valor total do item</label>
                    <MakoInputMoeda
                        id="valor-total"
                        name="valor_total_item"
                        disabled
                        valueMoeda={formik.values.valor_total_item}
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="destinacao">Destinação *</label>
                    <Dropdown
                        id="destinacao"
                        name="destinacao"
                        buscar={!!dadosBasicos}
                        url={`/compras/destinacoes-entradas/?tipo_entrada=${dadosBasicos?.tipo_entrada}&query={id,nome,centro_estocagem_destino}&limit=100`}
                        setObjects={setDestinacoesEntrada}
                        optionValue="id"
                        optionLabel="nome"
                        value={formik.values.destinacao}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.destinacao })}
                    />
                    {formik.errors.destinacao && <small className="p-error">{formik.errors.destinacao}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="custo-liquido">Custo líquido *</label>
                    <MakoInputMoeda
                        id="custo-liquido"
                        name="custo_liquido"
                        disabled
                        valueMoeda={formik.values.custo_liquido}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="custo-bruto">Custo bruto *</label>
                    <MakoInputMoeda
                        id="custo-bruto"
                        name="custo_bruto"
                        disabled
                        valueMoeda={formik.values.custo_bruto}
                    />
                </div>
                <div className="p-field p-col-12 p-md-5">
                    <label htmlFor="descricao-complementar">Descrição complementar</label>
                    <InputText
                        id="descricao-complementar"
                        name="descricao_complementar"
                        value={formik.values.descricao_complementar}
                        onChange={formik.handleChange}
                    />
                </div>
            </div>
            <CamposObrigatorios />
            <div className="p-grid p-col-12">
                <Button label="Gravar" icon="pi pi-save" type="submit" className="p-button-info p-mr-2" />
            </div>
        </form>
    );
};
