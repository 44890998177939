import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { TreeSelect } from "primereact/treeselect";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { PageBase } from "@/components/PageBase";
import { Dropdown } from "@/components/Dropdown";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { NATUREZA_FINANCEIRA } from "@/assets/constants/financeiro";
import { axiosGet, axiosPost, axiosPut } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

export const MapeadorTemplateImportacaoExtratoForm = (props) => {
    const [planosContas, setPlanosContas] = useState([]);
    const [versaoPlanoContas, setVersaoPlanoContas] = useState(null);
    const [loadingPlanoContas, setLoadingPlanoContas] = useState(false);
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            template: null,
            plano_contas: null,
            natureza: null,
            deve_conter: "",
            nao_deve_conter: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                template: Yup.number()
                    .required("O campo 'template' é obrigatório.")
                    .typeError("Selecione uma template."),
                plano_contas: Yup.number()
                    .required("O campo 'plano de contas' é obrigatório.")
                    .typeError("Selecione uma plano de contas."),
                natureza: Yup.string()
                    .required("O campo 'natureza' é obrigatório.")
                    .typeError("Selecione uma natureza."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            if (!values.id) {
                showLoading();
                const resposta = await axiosPost("/financeiro/templates-importacao-extratos-mapeadores/", values);
                hideLoading();
                if (resposta.status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Mapeador de importação de extrato cadastrado com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        voltarParaListagem();
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(
                    `/financeiro/templates-importacao-extratos-mapeadores/${values.id}/`,
                    values
                );
                hideLoading();
                if (resposta.status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Mapeador de importação de extrato alterado com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        voltarParaListagem();
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const voltarParaListagem = () => {
        history.push("/financeiro/cadastros/mapeador-templates-importacao-extratos");
    };

    const buscarPlanosContas = useCallback(async () => {
        if (versaoPlanoContas) {
            setLoadingPlanoContas(true);
            const { status, data } = await axiosGet(
                `/financeiro/planos-contas-financeiras-estruturas/?id_versao=${versaoPlanoContas}`
            );
            setLoadingPlanoContas(false);
            if (status === 200) {
                setPlanosContas(data);
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos listar o plano de contas.",
                    life: 3000,
                });
            }
        }
    }, [versaoPlanoContas, showError]);

    useEffect(() => {
        buscarPlanosContas();
    }, [buscarPlanosContas]);

    useEffect(() => {
        if (props.location.state) {
            const { template, plano_contas, ...rest } = props.location.state;
            setVersaoPlanoContas(plano_contas.versao.id);
            setValues({
                ...rest,
                template: template.id,
                plano_contas: plano_contas.id,
            });
        }
    }, [props, setValues]);

    return (
        <PageBase>
            <h3>
                {!formik.values.id
                    ? "Novo mapeador de importação de extrato"
                    : "Manutenção de mapeador de importação de extrato"}
            </h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="template">Template *</label>
                        <Dropdown
                            id="template"
                            name="template"
                            url="/financeiro/templates-importacao-extratos/"
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.template}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.template })}
                        />
                        {formik.errors.template && <small className="p-error">{formik.errors.template}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="versao-plano-contas">Versão do plano de contas *</label>
                        <Dropdown
                            id="versao-plano-contas"
                            name="versao_plano_contas"
                            url="/financeiro/versoes-planos-contas-financeiras/"
                            optionValue="id"
                            optionLabel="descricao"
                            value={versaoPlanoContas}
                            onChange={(e) => setVersaoPlanoContas(e.value)}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="plano-contas">Plano de contas *</label>
                        <TreeSelect
                            id="plano-contas"
                            name="plano_contas"
                            options={planosContas}
                            disabled={loadingPlanoContas}
                            placeholder={!loadingPlanoContas ? "Selecione" : "Buscando..."}
                            emptyMessage="Nenhum registro encontrado."
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.plano_contas}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.plano_contas })}
                        />
                        {formik.errors.conta_financeira && (
                            <small className="p-error">{formik.errors.conta_financeira}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="contanaturezaceira">Natureza *</label>
                        <Dropdown
                            id="natureza"
                            name="natureza"
                            options={NATUREZA_FINANCEIRA}
                            value={formik.values.natureza}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.natureza })}
                        />
                        {formik.errors.natureza && <small className="p-error">{formik.errors.natureza}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="deve-conter">Deve conter</label>
                        <InputText
                            id="deve-conter"
                            name="deve_conter"
                            value={formik.values.deve_conter}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.deve_conter })}
                            autoComplete="off"
                        />
                        {formik.errors.deve_conter && <small className="p-error">{formik.errors.deve_conter}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="nao-deve-conter">Não deve conter</label>
                        <InputText
                            id="nao-deve-conter"
                            name="nao_deve_conter"
                            value={formik.values.nao_deve_conter}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.nao_deve_conter })}
                            autoComplete="off"
                        />
                        {formik.errors.nao_deve_conter && (
                            <small className="p-error">{formik.errors.nao_deve_conter}</small>
                        )}
                    </div>
                </div>
                <CamposObrigatorios />
                <div className="p-grid p-col-12 p-md-6">
                    <Button label="Gravar" icon="pi pi-save" type="submit" className="p-button-info p-mr-2" />
                    <Button
                        label="Voltar"
                        icon="pi pi-angle-double-left"
                        type="reset"
                        className="p-button-danger"
                        onClick={voltarParaListagem}
                    />
                </div>
            </form>
        </PageBase>
    );
};
