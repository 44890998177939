import React, { useCallback, useEffect, useRef, useState } from "react";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { axiosGet, axiosPatch, axiosPost } from "@/services/http";
import { PageBase } from "@/components/PageBase";
import { MakoInputMotorista } from "@/components/MakoInputs/MakoInputMotorista";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";

import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import { dataToStr } from "@/assets/util/datas";

const urlvoltar = "/estoque/cadastros/carga";

export const EstoqueCargaForm = (props) => {
    const [listaRotas, setListaRotas] = useState([]);
    const [listaVeiculos, setListaVeiculos] = useState([]);
    const [usuario, setUsuario] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const history = useHistory();

    const { setFieldValue, ...formik } = useFormik({
        initialValues: props.location.state
            ? {
                  ...props.location.state.carga,
                  veiculo: props.location.state.carga.veiculo?.id || null,
                  rota_transf: props.location.state.carga.rota_transf?.id || null,
              }
            : {
                  id: null,
                  motorista: null,
                  veiculo: null,
                  data: new Date(),
                  rota_transf: null,
                  ativa: true,
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                data: Yup.date().required("O campo 'data' é obrigatório."),
                rota_transf: Yup.number().required("O campo 'rota de transferência' é obrigatório.").typeError("Informe uma 'rota' válida."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(`/transferencias/carga/`, {
                    ...values,
                    motorista: values.motorista?.id || null,
                    usuario_criou: usuario.id,
                    data: dataToStr(values.data, "yyyy-MM-dd"),
                });
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Carga cadastrada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPatch(`/transferencias/carga/${values.id}/`, {
                    ...values,
                    motorista: values.motorista?.id || null,
                });
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Carga alterada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    const listarVeiculos = useCallback(async () => {
        showLoading();
        const { status, data } = await axiosGet("/pessoas/veiculo-entrega/");
        hideLoading();

        if (status === 200) {
            let lista = [];
            await data.results.forEach((veiculo) => {
                lista.push({
                    ...veiculo,
                    label: `Código: ${veiculo.id} - Placa: ${veiculo.placa} - Chassi: ${veiculo.chassi}`,
                });
            });
            setListaVeiculos(lista);
        }
    }, [showLoading, hideLoading]);

    const listarRotas = useCallback(async () => {
        showLoading();
        const { status, data } = await axiosGet("/vendas/rotas-entrega/");
        hideLoading();

        if (status === 200) {
            setListaRotas(data.results);
        }
    }, [showLoading, hideLoading]);

    const carregaUsuario = useCallback(async () => {
        showLoading();
        const { status, data } = await axiosGet("/auth/users/me/");
        hideLoading();

        if (status === 200) {
            setUsuario(data);
        }
    }, [showLoading, hideLoading]);

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    useEffect(() => {
        listarVeiculos();
        listarRotas();
        carregaUsuario();
    }, [listarVeiculos, listarRotas, carregaUsuario]);

    return (
        <PageBase>
            <Toast ref={toastRef} />
            <h3>{!formik.values.id ? "Nova Carga" : "Manutenção de Carga"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data">Data *</label>
                        <MakoCalendar
                            id="data"
                            name="data"
                            valueCalendar={formik.values.data}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.data,
                            })}
                        />
                        {formik.errors.data && <small className="p-error">{formik.errors.data}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-9">
                        <label htmlFor="motorista">Motorista</label>
                        <MakoInputMotorista
                            id="motorista"
                            name="motorista"
                            value={formik.values.motorista}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.motorista })}
                        />
                        {formik.errors.motorista && <small className="p-error">{formik.errors.motorista}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="veiculo">Veículo</label>
                        <Dropdown
                            id="veiculo"
                            name="veiculo"
                            placeholder="Selecione um veículo"
                            showClear
                            options={listaVeiculos}
                            filter
                            filterBy="label"
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.veiculo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.veiculo })}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="rota_transf">Rota de Transferência *</label>
                        <Dropdown
                            id="rota_transf"
                            name="rota_transf"
                            placeholder="Selecione uma rota"
                            options={listaRotas}
                            filter
                            filterBy="descricao"
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.rota_transf}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.rota_transf })}
                        />
                        {formik.errors.rota_transf && <small className="p-error">{formik.errors.rota_transf}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-1 ">
                        <h5>
                            <label htmlFor="ativa">Status</label>
                        </h5>
                        <InputSwitch
                            id="ativa"
                            name="ativa"
                            checked={formik.values.ativa}
                            disabled={!formik.values.id}
                            value={formik.values.ativa}
                            onChange={(e) => setFieldValue("ativa", e.value)}
                            autoFocus
                        />
                    </div>
                </div>
                <p>
                    <b>* Campos obrigatórios.</b>
                </p>

                <div className="p-grid p-col-12 p-md-6">
                    <Button label="Gravar" type="submit" className="p-button-info p-mr-2 p-mb-6" />
                    <Button
                        label="Cancelar"
                        type="reset"
                        className="p-button-danger p-mr-2 p-mb-6"
                        onClick={cancelar}
                    />
                </div>
            </form>
        </PageBase>
    );
};
