import React, { useCallback, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Menu } from "primereact/menu";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import {
    AutoCompleteFiltroTemplate,
    ClienteFiltroTemplate,
    CodigoFiltroTemplate,
    DateFiltroTemplate,
    DropdownFiltroTemplate,
    TimeFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import MakoListagem from "@/components/MakoListagem";

import { VisualizarEntrega } from "./visualizarEntrega";

import { RELATORIO_VENDAS_TABELAROMANEIO, RELATORIO_VENDAS_TABELACONTROLEENTREGA } from "@/assets/constants/relatorios";
import { dataToStr } from "@/assets/util/datas";
import permissoes from "@/assets/constants/permissoes";

import useClearRefs from "@/hooks/useClearRefs";
import useRelatorio from "@/hooks/useRelatorio";
import useToast from "@/hooks/useToast";
import { FormConfirmarEntrega } from "./formConfirmarEntrega";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";

export const EntregasPage = () => {
    const [selecionados, setSelecionados] = useState(null);
    const [visibleForm, setVisibleForm] = useState(false);
    const [visible, setVisible] = useState(false);
    const [vendaId, setVendaId] = useState(null);
    const [formatDocumento] = useFormatCNPJCPF();

    const { solicitarRelatorio } = useRelatorio();
    const { showWarning } = useToast();
    const listagemRef = useRef();
    const menuRef = useRef();

    useClearRefs(listagemRef, menuRef);

    const gerarRelatorio = useCallback(
        (chave, ids) => {
            const filtered = ids.filter((item, index) => ids.indexOf(item) === index);
            if (chave === RELATORIO_VENDAS_TABELACONTROLEENTREGA)
                solicitarRelatorio({ chave, filtros: { ids: filtered } });
            else solicitarRelatorio({ chave, filtros: { rota_entrega: filtered } });
        },
        [solicitarRelatorio]
    );

    const items = [
        {
            label: "Selecione um tipo de documento para gerar",
            items: [
                {
                    label: "Romaneio",
                    command: () =>
                        gerarRelatorio(
                            RELATORIO_VENDAS_TABELAROMANEIO,
                            selecionados.map((item) => item.venda_id)
                        ),
                },
                {
                    label: "Controle de entrega",
                    command: () =>
                        gerarRelatorio(
                            RELATORIO_VENDAS_TABELACONTROLEENTREGA,
                            selecionados.map((item) => item.rota_entrega.id)
                        ),
                },
            ],
        },
    ];

    const handleOpenModal = (id) => {
        setVendaId(id);
        setVisible(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-eye"
                    className="p-button-rounded p-button-info p-mr-2 p-mb-1"
                    onClick={() => handleOpenModal(rowData.venda_id)}
                />
            </div>
        );
    };

    const painelEsquerdoTemplate = (
        <>
            <Menu model={items} popup ref={menuRef} id="popup_menu" />
            <Button
                label="Confirmar entregas"
                icon="pi pi-check"
                className="p-button p-component p-button-success p-mr-2"
                disabled={!selecionados?.length}
                onClick={() => setVisibleForm(true)}
            />
            <MakoControleAcesso
                componente={Button}
                permissao={[
                    permissoes.RELATORIOS_EMITIR_VENDAS_ROMANEIO,
                    permissoes.RELATORIOS_EMITIR_VENDAS_CONTROLEENTREGA,
                ]}
                label="Gerar documento"
                icon="pi pi-search"
                className="p-button-info p-mr-2"
                aria-controls="popup_menu"
                aria-haspopup
                disabled={!selecionados?.length}
                onClick={(e) => menuRef.current.toggle(e)}
            />
            <Button
                label="Limpar selecionados"
                icon="pi pi-trash"
                className="p-button p-component p-button-warning p-m-0"
                disabled={!selecionados?.length}
                onClick={() => setSelecionados(null)}
            />
        </>
    );

    const rotaEntregaFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    url: "/vendas/rotas-entrega/?limit=300",
                    optionValue: "id",
                    optionLabel: "descricao",
                }}
                options={options}
            />
        );
    };

    const cidadeFiltroTemplate = (options) => {
        return (
            <AutoCompleteFiltroTemplate
                url="/pessoas/cidades?limit=20&query={id,nome}&nome__unaccent__icontains="
                field="nome"
                minCaracteresBusca={3}
                placeholder="Digite o nome da cidade para buscar..."
                options={options}
            />
        );
    };

    const colunas = [
        {
            field: "venda_id",
            header: "Orçamento",
            filter: true,
            filterElement: CodigoFiltroTemplate,
            filterField: "item_venda__venda",
            style: { width: "8%" },
        },
        {
            field: "cliente",
            header: "Cliente",
            filter: true,
            filterElement: ClienteFiltroTemplate,
            filterField: "item_venda__venda__cliente_id",
            action: ({ cliente }) => `${cliente.nome} - ${formatDocumento(cliente.identificacao)}`,
        },
        {
            field: "rota_entrega.descricao",
            header: "Rota",
            filter: true,
            filterElement: rotaEntregaFiltroTemplate,
            filterField: "rota_entrega",
        },
        {
            field: "endereco_entrega",
            header: "Cidade",
            filter: true,
            filterElement: cidadeFiltroTemplate,
            filterField: "endereco_entrega__cidade_id",
            action: ({ endereco_entrega }) =>
                `${endereco_entrega.cidade.estado.nome} - ${endereco_entrega.cidade.nome}`,
        },
        {
            field: "data_previsao",
            header: "Data previsão",
            filter: true,
            filterElement: DateFiltroTemplate,
            style: { width: "10%" },
            action: ({ data_previsao }) => dataToStr(data_previsao, "dd/MM/yyyy"),
        },
        {
            field: "hora_previsao",
            header: "Hora previsão",
            filter: true,
            filterElement: TimeFiltroTemplate,
            style: { width: "10%" },
        },
        {
            field: "action",
            header: "Ações",
            style: { width: "10%" },
            action: (rowData) => actionBodyTemplate(rowData),
        },
    ];

    const fecharModal = () => {
        setVisible(false);
    };

    const handleSelection = useCallback(
        (value, type = "new") => {
            if (type === "new") {
                if (!selecionados) {
                    setSelecionados([value]);
                    return true;
                }
                if (!selecionados.find((item) => item.id === value.id)) {
                    setSelecionados([...selecionados, value]);
                    return true;
                }
            } else {
                setSelecionados((prev) => prev.filter((items) => items.id !== value.id));
                return true;
            }
            showWarning({
                summary: "Erro :(",
                detail: "Você ja inseriu esse item.",
                life: 3000,
            });
            return false;
        },
        [selecionados, setSelecionados]
    );

    const getSelecionados = useCallback(() => {
        return selecionados;
    }, [selecionados]);

    const fecharModalForm = (atualizarListagem = false) => {
        setVisibleForm(false);
        if (atualizarListagem) {
            listagemRef.current?.buscarDados();
            setSelecionados(null);
        }
    };

    return (
        <div className="card">
            <MakoListagem
                titulo="Listagem de entregas"
                painelEsquerdo={painelEsquerdoTemplate}
                colunas={colunas}
                ref={listagemRef}
                urlPesquisa="/vendas/entregas-previsao/?query={id,data_previsao,hora_previsao,venda_id,endereco_entrega,rota_entrega,datahora_entrega}&rota_entrega__isnull=false&datahora_entrega__isnull=true&retirar=false"
                configTabela={{
                    lazy: true,
                    paginator: true,
                }}
                filtros={{
                    item_venda__venda: { value: null, matchMode: "equals" },
                    item_venda__venda__cliente_id: { value: null, matchMode: "equals" },
                    rota_entrega: { value: null, matchMode: "equals" },
                    endereco_entrega__cidade_id: { value: null, matchMode: "equals" },
                    data_previsao: { value: null, matchMode: "equals" },
                    hora_previsao: { value: null, matchMode: "equals" },
                }}
            />
            <Dialog
                header={`Entrega de orçamento: ${vendaId}`}
                visible={visible}
                style={{ width: "70vw" }}
                onHide={fecharModal}
            >
                <VisualizarEntrega
                    venda_id={vendaId}
                    selecionados={selecionados}
                    handleSelection={handleSelection}
                    getSelecionados={getSelecionados}
                />
            </Dialog>
            <Dialog
                header={"Confirmar entregas"}
                visible={visibleForm}
                style={{ width: "90vw" }}
                onHide={fecharModalForm}
            >
                <FormConfirmarEntrega
                    previsoes={selecionados}
                    onFinish={() => fecharModalForm(true)}
                    onCancel={fecharModalForm}
                />
            </Dialog>
        </div>
    );
};
