import React, { useState, useCallback, useEffect, useRef, memo } from "react";
import { TreeSelect } from "primereact/treeselect";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

import { axiosGet } from "@/services/http";

const Componente = ({
    grupoId,
    categoriaPai,
    buscarTodas,
    categoriaTituloSelecionavel,
    showClear,
    apenasTitulo,
    getCategoriaCompleta,
    ...props
}) => {
    const [categorias, setCategorias] = useState([]);
    const toastRef = useRef(null);

    const percorreListaSelecionavel = useCallback(
        (lista) => {
            lista.forEach((l) => {
                l.label = l.data.nome;
                if (l.data.tipo === "T" && !categoriaTituloSelecionavel) {
                    l.selectable = false;
                } else {
                    l.selectable = true;
                }

                if (l.children) {
                    percorreListaSelecionavel(l.children);
                }
            });

            return lista;
        },
        [categoriaTituloSelecionavel]
    );

    const localizaCategoria = useCallback((lista, key) => {
        let categoria = null;
        for (let x = 0; x < lista.length; x++) {
            if (lista[x].key === key) {
                return lista[x].data;
            }

            if (lista[x].children) {
                categoria = localizaCategoria(lista[x].children, key);
            }

            if (categoria) {
                break;
            }
        }

        return categoria;
    }, []);

    const listarCategoriasHierarquicas = useCallback(async () => {
        let url = "/produtos/categorias-menu?";
        if (buscarTodas) {
            url = "/produtos/categorias-menu?grupo_id=False";
        } else if (grupoId) {
            url = `/produtos/categorias-menu?grupo_id=${grupoId}`;
        } else if (categoriaPai) {
            url = `/produtos/categorias-menu?categoria_pai=${categoriaPai}`;
        }
        if (apenasTitulo) {
            if (url[url.length - 1] === "/") {
                url = `${url}?&tipo=T`;
            } else {
                url = `${url}&tipo=T`;
            }
        }
        const { status, data } = await axiosGet(url);
        if (status === 200) {
            setCategorias(percorreListaSelecionavel(data));
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos listar suas categorias.",
                life: 3000,
            });
        }
    }, [grupoId, categoriaPai, buscarTodas, apenasTitulo, percorreListaSelecionavel]);

    useEffect(() => {
        listarCategoriasHierarquicas();
    }, [listarCategoriasHierarquicas]);

    const limparSelecao = (e) => {
        if (props?.onChange) {
            if(props?.name && props?.id) {
                props.onChange({
                    ...e,
                    target: { 
                        name: props?.name,  
                        id: props?.id, 
                    }
                }, null);
            } else {
                props.onChange(e, null);
            }
        }
    };

    const onChangeCategoria = (e) => {
        if (props.onChange) {
            props.onChange(e);
        }

        if (typeof getCategoriaCompleta === "function") {
            getCategoriaCompleta(localizaCategoria(categorias, e.value));
        }
    };

    return (
        <>
            <Toast ref={toastRef} />
            <div className="p-inputgroup">
                <TreeSelect
                    placeholder={props?.placeholder || "Selecione uma categoria"}
                    filter
                    filterBy="data.nome"
                    emptyMessage="Nenhuma categoria encontrada."
                    options={categorias}
                    {...props}
                    onChange={onChangeCategoria}
                />
                {showClear && (
                    <Button
                        type="button"
                        icon="pi pi-times"
                        onClick={(e) => limparSelecao(e)}
                        className="p-button-secondary"
                    />
                )}
            </div>
        </>
    );
};

export const MakoDropdownCategoriasHierarquicas = memo(Componente);
