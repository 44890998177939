import React, { useEffect } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { PageBase } from "@/components/PageBase";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { axiosGet, axiosPost, axiosPut } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

export const TipoEstagioForm = (props) => {
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showWarning, showError } = useToast();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            descricao: "",
            ativo: true,
            baixa_estoque: null,
            gera_financeiro: null,
            gera_documento_fiscal: null,
            reserva_estoque: null,
            gera_comissao: null,
        },
        onSubmit: handleSubmit,
    });

    useEffect(() => {
        if (props.location.state) {
            setValues(props.location.state.tipoEstagio);
        }
    }, [props.location.state, setValues]);

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                baixa_estoque: Yup.bool()
                    .nullable()
                    .test({
                        name: "baixa_estoque_test",
                        test: (value) => !(value && values.reserva_estoque),
                        message:
                            "As opções 'reserva estoque' e 'baixa estoque' não podem ser marcados simultâneamente.",
                    }),
                reserva_estoque: Yup.bool()
                    .nullable()
                    .test({
                        name: "reserva_estoque_test",
                        test: (value) => !(value && values.baixa_estoque),
                        message:
                            "As opções 'reserva estoque' e 'baixa estoque' não podem ser marcados simultâneamente.",
                    }),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            const { status, data } = await axiosGet(
                `/vendas/tipos-estagios-vendas/?descricao__unaccent__icontains=${values.descricao}&ativo=True`
            );
            let existe = false;
            if (status === 200) {
                const [estagio] = data.results;
                existe = data.count > 0 && values.id !== estagio.id;
            }
            if (!existe && !values.id) {
                showLoading();
                const json = await axiosPost("/vendas/tipos-estagios-vendas/", values);
                hideLoading();
                if (json.status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Novo tipo de estágio cadastrado!",
                        life: 1500,
                    });
                    formik.resetForm();
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            } else if (values.id) {
                showLoading();
                const json = await axiosPut(`/vendas/tipos-estagios-vendas/${values.id}/`, values);
                hideLoading();
                if (json.status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Tipo de estágio alterado!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        history.push("/vendas/cadastros/tipos-estagios");
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showWarning({
                    summary: "Aviso!",
                    detail: "Já existe um tipo de estágio com essa descrição, tente outra.",
                    life: 3000,
                });
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const statusTipoEstagio = (value) => {
        if (value) return "Sim";
        else if (value === false) return "Não";
        return "Mantém";
    };

    return (
        <PageBase>
            <form onSubmit={formik.handleSubmit}>
                <h3>{!formik.values.id ? "Novo tipo de estágio" : "Manutenção de tipo de estágio"}</h3>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="descricao">Descrição *</label>
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-2 p-mt-4">
                        <Checkbox
                            id="ativo"
                            name="ativo"
                            checked={formik.values.ativo}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="ativo">{formik.values.ativo ? "Ativo" : "Inativo"}</label>
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-3 p-mt-4">
                        <TriStateCheckbox
                            id="baixa-estoque"
                            name="baixa_estoque"
                            value={formik.values.baixa_estoque}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.baixa_estoque })}
                        />
                        <label htmlFor="baixa-estoque">
                            Baixa estoque: <b>{statusTipoEstagio(formik.values.baixa_estoque)}</b>
                        </label>
                        {formik.errors.baixa_estoque && (
                            <small className="p-error">{formik.errors.baixa_estoque}</small>
                        )}
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-3 p-mt-4">
                        <TriStateCheckbox
                            id="gera-financeiro"
                            name="gera_financeiro"
                            value={formik.values.gera_financeiro}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="gera-financeiro">
                            Gera financeiro: <b>{statusTipoEstagio(formik.values.gera_financeiro)}</b>
                        </label>
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field-checkbox p-col-12 p-md-4">
                        <TriStateCheckbox
                            id="gera-doc-fiscal"
                            name="gera_documento_fiscal"
                            value={formik.values.gera_documento_fiscal}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="gera-doc-fiscal">
                            Gera doc. fiscal: <b>{statusTipoEstagio(formik.values.gera_documento_fiscal)}</b>
                        </label>
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-4">
                        <TriStateCheckbox
                            id="reserva-estoque"
                            name="reserva_estoque"
                            value={formik.values.reserva_estoque}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.reserva_estoque })}
                        />
                        <label htmlFor="reserva-estoque">
                            Reserva estoque: <b>{statusTipoEstagio(formik.values.reserva_estoque)}</b>
                        </label>
                        {formik.errors.reserva_estoque && (
                            <small className="p-error">{formik.errors.reserva_estoque}</small>
                        )}
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-4">
                        <TriStateCheckbox
                            id="gera-comissao"
                            name="gera_comissao"
                            value={formik.values.gera_comissao}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="gera-comissao">
                            Gera comissão: <b>{statusTipoEstagio(formik.values.gera_comissao)}</b>
                        </label>
                    </div>
                </div>
                <CamposObrigatorios singular />
                <div className="p-grid p-col-12 p-md-6">
                    <Button icon="pi pi-save" label="Gravar" type="submit" className="p-button-info p-mr-2" />
                    <Button
                        icon="pi pi-angle-double-left"
                        label="Voltar"
                        type="button"
                        className="p-button-danger p-mr-2"
                        onClick={() => history.push("/vendas/cadastros/tipos-estagios")}
                    />
                </div>
            </form>
        </PageBase>
    );
};
