import React, { useEffect, useRef, useState, useCallback } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { axiosGet, axiosPatch } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import { useHistory, useLocation } from "react-router-dom";
import permissoes from "@/assets/constants/permissoes";
import { MakoCalendar } from "@/components/MakoCalendar";
import { dataToStr } from "@/assets/util/datas";
import { MultiSelect } from "primereact/multiselect";
import { Dialog } from "primereact/dialog";
import { ClonarPermissoesForm } from "./modal/ClonarPermissoes/formClonarPermissoes";
import useToast from "@/hooks/useToast";
import axios from "axios";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { PageBase } from "@/components/PageBase";
import { Divider } from "primereact/divider";
import { Panel } from "primereact/panel";
import { Ripple } from "primereact";

const urlvoltar = "/gestao/usuarios/gerenciar-usuarios";

export const GestaoGerenciarUsuariosForm = () => {
    const [listagens, setListagens] = useState([]);
    const [papeisUser, setPapeisUser] = useState([]);
    const [permissoesUser, setPermissoesUser] = useState([]);
    const [clonarPermissoes, setClonarPermissoes] = useState(false);
    const [papel, setPapel] = useState(null);
    const [addPermissao, setAddPermissao] = useState([]);
    const [optionsPapeis, setOptionsPapeis] = useState([]);
    const [optionsPermissoes, setOptionsPermissoes] = useState([]);
    const [dataFinalPapel, setDataFinalPapel] = useState(null);
    const [dataFinalPermissao, setDataFinalPermissao] = useState(null);
    const [empresaPapel, setEmpresaPapel] = useState(null);
    const [empresaPerfil, setEmpresaPerfil] = useState(null);

    const { showLoading, hideLoading } = useLoading();
    const { showError, showSuccess } = useToast();
    const { state } = useLocation();

    const listagemRef = useRef(null);
    const history = useHistory();

    const formik = useFormik({
        initialValues: state.gerenciarUsuario,
    });

    const titulo = formik.values.id
        ? `Edição do usuário: (${formik.values.id}) ${formik.values.nome}`
        : "Gerenciamento de usuário";

    const getListagens = useCallback(
        async (cancelToken) => {
            if (cancelToken) {
                const { status, data } = await axiosGet(`/pessoas/perfis/${formik.values.id}/`, { cancelToken });
                return { status, data: data };
            }
            showLoading();
            const { status, data } = await axiosGet(`/pessoas/perfis/${formik.values.id}/`);
            hideLoading();

            if (status === 200) setListagens(data);
        },
        [showLoading, hideLoading, formik.values.id]
    );

    const getPermissoes = useCallback(async (cancelToken) => {
        const { status, data } = await axiosGet("/pessoas/permissoes-perfis/?limit=10000&query={id, descricao}", {
            cancelToken,
        });
        return { status, data: data.results };
    }, []);

    const getPapeis = useCallback(async (cancelToken) => {
        const { status, data } = await axiosGet("/pessoas/papeis-perfis/?limit=10000&query={id, nome}", {
            cancelToken,
        });
        return { status, data: data.results };
    }, []);

    const fetchDados = useCallback(
        async (cancelToken) => {
            showLoading();
            const [respostaPapeis, respostaPermissoes, respostaListagens] = await Promise.all([
                getPapeis(cancelToken),
                getPermissoes(cancelToken),
                getListagens(cancelToken),
            ]);
            hideLoading();
            if (respostaPapeis.status === 200) setOptionsPapeis(respostaPapeis.data);
            if (respostaPermissoes.status === 200) setOptionsPermissoes(respostaPermissoes.data);
            if (respostaListagens.status === 200) setListagens(respostaListagens.data);
        },
        [showLoading, hideLoading, getPapeis, getPermissoes, getListagens]
    );

    async function handlePapel(op, idPapel) {
        try {
            if (op === "ADD") {
                let data_final = dataToStr(dataFinalPapel, "yyyy-MM-dd");
                const body = {
                    vinculoperfilpapel_set: {
                        create: [
                            {
                                papel: papel,
                                vigencia_final: data_final,
                                empresa: empresaPapel,
                            },
                        ],
                    },
                };

                showLoading();
                const resposta = await axiosPatch(`/pessoas/perfis/${formik.values.id}/`, body);
                hideLoading();

                if (resposta.status === 200 || resposta.status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Papel adicionado com sucesso!",
                        life: 1500,
                    });
                    setPapel(null);
                    setDataFinalPapel(null);
                    setListagens([]);
                    getListagens();
                } else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else if (op === "REMOVE") {
                const body = {
                    vinculoperfilpapel_set: { remove: [idPapel] },
                };

                showLoading();
                const resposta = await axiosPatch(`/pessoas/perfis/${formik.values.id}/`, body);
                hideLoading();

                if (resposta.status === 200 || resposta.status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Papel removido com sucesso!",
                        life: 1500,
                    });
                    setPapel(null);
                    getListagens();
                } else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            showError({
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 1500,
            });
            hideLoading();
        }
    }

    async function handlePermissao(op, idPermissao) {
        try {
            if (op === "ADD") {
                let data_final = dataToStr(dataFinalPermissao, "yyyy-MM-dd");
                let listaAdd = [];

                addPermissao.forEach((id) => {
                    listaAdd.push({
                        permissao: id,
                        vigencia_final: data_final,
                        empresa: empresaPerfil,
                    });
                });
                const body = {
                    vinculoperfilpermissao_set: {
                        create: listaAdd,
                    },
                };

                showLoading();
                const resposta = await axiosPatch(`/pessoas/perfis/${formik.values.id}/`, body);
                hideLoading();

                if (resposta.status === 200 || resposta.status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Permissão adicionada com sucesso!",
                        life: 1500,
                    });
                    setAddPermissao([]);
                    getListagens();
                    setDataFinalPermissao(null);
                } else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else if (op === "REMOVE") {
                const body = {
                    vinculoperfilpermissao_set: { remove: [idPermissao] },
                };

                showLoading();
                const resposta = await axiosPatch(`/pessoas/perfis/${formik.values.id}/`, body);
                hideLoading();

                if (resposta.status === 201 || resposta.status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Permissão removida com sucesso!",
                        life: 1500,
                    });
                    getListagens();
                } else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            showError({
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 1500,
            });
            hideLoading();
        }
    }

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        fetchDados(cancelToken.token);
        return function clear() {
            cancelToken.cancel();
            listagemRef.current = null;
        };
    }, [fetchDados]);

    useEffect(() => {
        setPermissoesUser(listagens?.vinculoperfilpermissao_set || []);
        setPapeisUser(listagens?.vinculoperfilpapel_set || []);
    }, [listagens]);

    const esconderClonar = () => {
        setClonarPermissoes(false);
        getListagens();
    };

    const actionBodyPapelTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_USUARIOS_GERENCIARUSUARIO_EDITAR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    tooltip="Remover"
                    tooltipOptions={{ position: "left" }}
                    onClick={() => {
                        handlePapel("REMOVE", rowData.id);
                    }}
                />
            </div>
        );
    };

    const actionBodyPermissaoTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[
                        permissoes.GESTAO_USUARIOS_GERENCIARUSUARIO_EDITAR,
                        permissoes.GESTAO_USUARIOS_GERENCIARUSUARIO_VINCULARPERMISSAOAVULSA_EXCLUIR,
                    ]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mb-1"
                    tooltip="Remover"
                    tooltipOptions={{ position: "left" }}
                    onClick={() => {
                        handlePermissao("REMOVE", rowData.id);
                    }}
                />
            </div>
        );
    };

    const colunaPapel = [
        { field: "id", header: "Código", style: { width: "8%" } },
        { field: "papel.nome", header: "Papel" },
        { field: "empresa.nome", header: "Empresa" },
        {
            field: "vigencia_final",
            header: "Vigência final",
            style: { width: "15%" },
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyPapelTemplate(e),
            style: { width: "10%" },
        },
    ];

    const colunaPermissao = [
        { field: "id", header: "Código", style: { width: "8%" } },
        { field: "permissao.descricao", header: "Permissão" },
        { field: "empresa.nome", header: "Empresa" },
        {
            field: "vigencia_final",
            header: "Vigência final",
            style: { width: "15%" },
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyPermissaoTemplate(e),
            style: { width: "10%" },
        },
    ];

    const template = (options) => {
        const className = `${options.className} justify-content-start`;
        return (
            <div className={className}>
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <Button severity="success" icon="pi pi-plus" className="p-button-success p-button-outlined" />
                    <Ripple />
                </button>
                <span className="text-primary" style={{ fontSize: "1.25rem" }} onClick={options.onTogglerClick}>
                    <Button label={<u>{options.props.header}</u>} className="p-button-text p-button-success" />
                </span>
            </div>
        );
    };

    return (
        <PageBase>
            <h5>
                <b>{titulo.toUpperCase()}</b>
            </h5>
            <Divider align="center" className="p-mt-6">
                <b>Papeis do perfil</b>
            </Divider>
            <Panel header="Incluir papel de perfil" headerTemplate={template} toggleable collapsed>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <Dropdown
                            id="papel"
                            name="papel"
                            placeholder="Selecione um papel"
                            onChange={(e) => setPapel(e.value)}
                            value={papel}
                            filter
                            filterBy="nome"
                            optionLabel="nome"
                            optionValue="id"
                            showClear
                            options={optionsPapeis}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-5">
                        <MakoDropdownEmpresas
                            id="empresa"
                            name="empresa"
                            nome_curto={false}
                            value={empresaPapel}
                            placeholder="Selecione uma empresa"
                            onChange={(e) => setEmpresaPapel(e.id)}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <MakoCalendar
                            id="data_final_papel"
                            name="data_final_papel"
                            valueCalendar={dataFinalPapel}
                            onChange={(e) => setDataFinalPapel(e.target.value)}
                            tooltip="Vigência final"
                            tooltipOptions={{
                                position: "top",
                            }}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Button
                            label="Adicionar"
                            icon="pi pi-plus"
                            type="button"
                            className="p-button-success"
                            onClick={() => handlePapel("ADD")}
                            disabled={!papel || !empresaPapel}
                        />
                    </div>
                </div>
            </Panel>
            {listagens ? <MakoListagem ref={listagemRef} colunas={colunaPapel} dadosLocal={papeisUser} /> : null}
            {listagens ? (
                <card>
                    <Divider align="center" className="p-mt-6">
                        <b>Permissões do perfil</b>
                    </Divider>
                    <Panel header="Incluir permissão" headerTemplate={template} toggleable collapsed>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-4">
                                <MultiSelect
                                    id="permissao"
                                    name="permissao"
                                    placeholder="Selecione uma permissão"
                                    onChange={(e) => setAddPermissao(e.value)}
                                    value={addPermissao}
                                    filter
                                    filterBy="descricao"
                                    optionLabel="descricao"
                                    optionValue="id"
                                    options={optionsPermissoes}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-5">
                                <MakoDropdownEmpresas
                                    id="empresa"
                                    name="empresa"
                                    nome_curto={false}
                                    value={empresaPerfil}
                                    placeholder="Selecione uma empresa"
                                    onChange={(e) => setEmpresaPerfil(e.id)}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <MakoCalendar
                                    id="data_final_permissao"
                                    name="data_final_permissao"
                                    valueCalendar={dataFinalPermissao}
                                    onChange={(e) => setDataFinalPermissao(e.target.value)}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <MakoControleAcesso
                                    permissao={[
                                        permissoes.GESTAO_USUARIOS_GERENCIARUSUARIO_EDITAR,
                                        permissoes.GESTAO_USUARIOS_GERENCIARUSUARIO_VINCULARPERMISSAOAVULSA_INCLUIR,
                                    ]}
                                    componente={Button}
                                    label="Adicionar"
                                    icon="pi pi-plus"
                                    type="button"
                                    className="p-button-success"
                                    onClick={() => handlePermissao("ADD")}
                                    disabled={!addPermissao.length || !empresaPerfil}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <MakoControleAcesso
                                    permissao={[
                                        permissoes.GESTAO_USUARIOS_GERENCIARUSUARIO_EDITAR,
                                        permissoes.GESTAO_USUARIOS_GERENCIARUSUARIO_VINCULARPERMISSAOAVULSA_INCLUIR,
                                    ]}
                                    componente={Button}
                                    label="Clonar"
                                    icon="pi pi-users"
                                    className="p-button-info"
                                    onClick={() => setClonarPermissoes(true)}
                                />
                            </div>
                        </div>
                    </Panel>
                    <MakoListagem ref={listagemRef} colunas={colunaPermissao} dadosLocal={permissoesUser} />
                </card>
            ) : null}
            <div className="p-grid p-fluid p-justify-start p-mt-2">
                <div className="p-col-6 p-md-2">
                    <Button
                        label="Voltar"
                        type="reset"
                        className="p-button-danger"
                        onClick={() => history.push(urlvoltar)}
                    />
                </div>
            </div>
            <Dialog
                header="Clonar Permissões de Usuário"
                visible={clonarPermissoes}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "30vw", display: "block" }}
                onHide={() => setClonarPermissoes(false)}
            >
                <ClonarPermissoesForm usuario={formik.values?.usuario || null} esconderClonar={esconderClonar} />
            </Dialog>
        </PageBase>
    );
};
