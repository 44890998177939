import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Dialog } from "primereact/dialog";
import { TabView, TabPanel } from "primereact/tabview";

import { TabItemEntrada } from "./tabItemEntrada";
import { TabComplementoItem } from "./tabComplementoItem";

const EntradaSkuManual = ({ itemEntrada, onFechar }, ref) => {
    const [visible, setVisible] = useState(false);

    const abrirModal = () => {
        setVisible(true);
    };

    const fecharModal = () => {
        if (typeof onFechar === "function") onFechar();
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    return (
        <Dialog header="Lançamento manual de produto" visible={visible} onHide={fecharModal} style={{ width: "80vw" }}>
            <TabView>
                <TabPanel header="Item de entrada">
                    <TabItemEntrada itemEntrada={itemEntrada} />
                </TabPanel>
                <TabPanel header="Complemento" disabled={!!!itemEntrada?.item_nota_fiscal}>
                    <TabComplementoItem
                        itemNotaFiscalId={itemEntrada?.item_nota_fiscal}
                        skuItemEntrada={itemEntrada?.sku}
                        itemEntradaId={itemEntrada?.id}
                    />
                </TabPanel>
            </TabView>
        </Dialog>
    );
};

export const ModalEntradaSkuManual = forwardRef(EntradaSkuManual);
