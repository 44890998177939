import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";

import MakoListagem from "@/components/MakoListagem";
import { PageBase } from "@/components/PageBase";
import { BotaoDelete } from "@/components/BotaoDelete";
import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { BooleanFiltroTemplate, CodigoFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";

export const EstagioVendaPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_CADASTRO_ESTAGIOVENDA_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/vendas/cadastros/estagios-vendas/form")}
            />
        </>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_TIPOESTAGIO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    onClick={() =>
                        history.push({
                            pathname: "/vendas/cadastros/estagios-vendas/form",
                            state: rowData,
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_ESTAGIOVENDA_SEQUENCIA_MANUTENCAO]}
                    componente={Button}
                    icon="pi pi-sort"
                    className="p-button-rounded p-button-info p-mr-2 p-mb-1"
                    tooltip="Alterar fluxo."
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/vendas/cadastros/estagios-vendas/sequencia",
                            state: {
                                estagioVenda: rowData,
                            },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_TIPOESTAGIO_EXCLUIR]}
                    componente={BotaoDelete}
                    url="/vendas/estagios-vendas/"
                    objetoId={rowData.id}
                    classNames="p-mb-1"
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja mesmo apagar o estágio de venda <b>{rowData.descricao}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O fluxo de venda não pode ser excluído."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const ativoBodyTemplate = (rowData) => {
        if (rowData.ativo) return <span>Sim</span>;
        return <span>Não</span>;
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "10%" }, filter: true, filterElement: CodigoFiltroTemplate },
        { field: "descricao", header: "Descrição", filter: true, filterElement: TextoFiltroTemplate },
        {
            field: "ativo",
            header: "Ativo",
            style: { width: "15%" },
            action: (e) => ativoBodyTemplate(e),
            filter: true,
            filterElement: BooleanFiltroTemplate,
        },
        { field: "action", header: "Ações", style: { width: "15%" }, action: (e) => actionBodyTemplate(e) },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Fluxos de vendas"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa="/vendas/estagios-vendas/"
                configTabela={{ lazy: true, paginator: true }}
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    ativo: { value: true, matchMode: "equals" },
                }}
            />
        </PageBase>
    );
};
