import React, { useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { axiosPut } from "@/services/http";

import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";

const url = "/pessoas/permissoes-perfis/";

export const PermissoesPerfilForm = (props) => {
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);

    const formik = useFormik({
        initialValues: {
            descricao: props?.location.state.permissao.descricao,
            registro: props?.location.state.permissao.registro ? props.location.state.permissao.registro : "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (values.registro === "") values.registro = null;

            values.chave = props?.location.state.permissao.chave;

            const id = props?.location.state.permissao.id;
            showLoading();
            const resposta = await axiosPut(`${url}${id}/`, values);
            hideLoading();

            if (resposta.status === 200) {
                toastRef.current.show({
                    severity: "success",
                    summary: "Sucesso",
                    detail: "Permissao atualizada com sucesso!",
                    life: 1500,
                });

                setTimeout(() => {
                    formik.resetForm();
                    history.push("/configuracoes/permissoes-perfis/");
                }, 2000);
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push("/configuracoes/permissoes-perfis/");
    };

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <h3>Alterar permissão</h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-8 p-md-4">
                                <label htmlFor="descricao">Descricao *</label>
                                <InputText
                                    id="descricao"
                                    name="descricao"
                                    value={formik.values.descricao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.descricao })}
                                    autoComplete="off"
                                    autoFocus
                                />
                                {formik.errors.descricao && (
                                    <small className="p-error">{formik.errors.descricao}</small>
                                )}
                            </div>
                            <div className="p-field p-col-4 p-md-4">
                                <label htmlFor="registro">Registro</label>
                                <InputText
                                    id="registro"
                                    name="registro"
                                    disabled={!props?.location.state?.permissao.content_type}
                                    value={formik.values.registro}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.registro })}
                                    autoComplete="off"
                                    autoFocus
                                />
                                {formik.errors.registro && <small className="p-error">{formik.errors.registro}</small>}
                            </div>
                        </div>

                        <div className="p-grid p-col-12 p-md-6">
                            <Button label="Gravar" type="submit" className="p-button-info p-mr-2 p-mb-6" />

                            <Button
                                label="Cancelar"
                                type="reset"
                                className="p-button-danger p-mr-2 p-mb-6"
                                onClick={cancelar}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
