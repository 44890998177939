import React, { useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { axiosPatch, axiosPost } from "@/services/http";

import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import { Dropdown } from "@/components/Dropdown";
import { TIPO_HISTORICO_PADRAO } from "@/assets/constants/financeiro";
import { Checkbox } from "primereact/checkbox";

export const FinanceiroHistoricoPadraoForm = (props) => {
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const history = useHistory();

    const formik = useFormik({
        initialValues: props.location.state
            ? { ...props.location.state.historico, tipo: props.location.state.historico.tipo.value }
            : {
                  descricao: "",
                  tipo: "P",
                  ativo: true,
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                tipo: Yup.string().required("O campo 'tipo' é obrigatório."),
                descricao: Yup.string().max(140).required("O campo 'descrição' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(`/financeiro/historico-padrao/`, values);
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Histórico cadastrado com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push("/financeiro/cadastros/historico-padrao");
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPatch(`/financeiro/historico-padrao/${values.id}/`, values);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Histórico alterado com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push("/financeiro/cadastros/historico-padrao");
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push("/financeiro/cadastros/historico-padrao");
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toastRef} />
                    <h3>{!formik.values.id ? "Novo histórico padrão" : "Manutenção de histórico padrão"}</h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-8">
                                <label htmlFor="descricao">Descrição *</label>
                                <InputText
                                    id="descricao"
                                    name="descricao"
                                    value={formik.values.descricao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.descricao })}
                                    autoComplete="off"
                                    autoFocus
                                />
                                {formik.errors.descricao && (
                                    <small className="p-error">{formik.errors.descricao}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="tipo">Tipo de histórico</label>
                                <Dropdown
                                    id="tipo"
                                    name="tipo"
                                    placeholder="Selecione um tipo"
                                    options={TIPO_HISTORICO_PADRAO}
                                    optionValue="value"
                                    optionLabel="label"
                                    value={formik.values.tipo}
                                    onChange={formik.handleChange}
                                />
                                {formik.errors.tipo && <small className="p-error">{formik.errors.tipo}</small>}
                            </div>
                            <div className="p-field-checkbox p-col-12 p-md-1 p-mt-5">
                                <Checkbox
                                    inputId="ativo"
                                    name="ativo"
                                    checked={formik.values.ativo}
                                    onChange={formik.handleChange}
                                />
                                <label htmlFor="ativo">Ativo?</label>
                            </div>
                        </div>

                        <p>
                            <b>* Campos obrigatórios.</b>
                        </p>

                        <div className="p-grid p-col-12 p-md-6">
                            <Button label="Gravar" type="submit" className="p-button-info p-mr-2 p-mb-6" />
                            <Button
                                label="Cancelar"
                                type="reset"
                                className="p-button-danger p-mr-2 p-mb-6"
                                onClick={cancelar}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
