import React, { useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

import { DadosBasicosEntradaForm } from "./formDadosBasicos";
import { OrdemCompraEntradaForm } from "./formOrdemCompra";
import { XMLForm } from "./formXML";
import { DocumentosFiscaisForm } from "./formDocsFiscais";
import { MercadoriasForm } from "./formMercadorias";
import { FinanceiroEntradaForm } from "./formFinanceiro";
import { PageBase } from "@/components/PageBase";
import { MakoMultiTabs } from "@/components/MakoMultiTabs";
import { parseMoeda } from "@/assets/util/util";
import useEntrada from "@/hooks/useEntrada";

export const TabEntradaProdutos = ({ entradaId }) => {
    const history = useHistory();
    const { loadingEntrada, dadosBasicos, handleGetEntrada } = useEntrada();

    const TABS = [
        {
            header: "Dados básicos",
            icon: "pi pi-fw pi-align-left",
            component: <DadosBasicosEntradaForm />,
        },
        {
            header: "Ordem de compra",
            icon: "fas pi-fw fa-clipboard-list",
            component: <OrdemCompraEntradaForm />,
            disabled: !!!dadosBasicos,
        },
        {
            header: "XML's",
            icon: "fas pi-fw fa-cloud-upload-alt",
            component: <XMLForm />,
            disabled: !!!dadosBasicos,
        },
        {
            header: "Documentos fiscais",
            icon: "pi pi-fw pi-book",
            component: <DocumentosFiscaisForm />,
            disabled: !!!dadosBasicos,
        },
        {
            header: "Produtos / mercadorias",
            icon: "pi pi-fw pi-shopping-cart",
            component: <MercadoriasForm />,
            disabled: !!!dadosBasicos,
        },
        {
            header: "Financeiro",
            icon: "pi pi-fw pi-dollar",
            component: <FinanceiroEntradaForm />,
            disabled: !!!dadosBasicos,
        },
    ];

    const carregarEntrada = useCallback(
        async (id) => {
            await handleGetEntrada(id);
        },
        [handleGetEntrada]
    );

    useEffect(() => {
        if (entradaId) {
            carregarEntrada(entradaId);
        }
    }, [entradaId, carregarEntrada]);

    const header = dadosBasicos?.id
        ? `: (${dadosBasicos.id}) - ${dadosBasicos.fornecedor.nome} - ${parseMoeda(
              dadosBasicos.valor_total_entrada,
              true
          )}`
        : "";

    return (
        <PageBase blocked={loadingEntrada}>
            <MakoMultiTabs
                tabs={TABS}
                title={`Lançamento de entrada${header}`}
                propsButtonProx={{ disabled: !!!dadosBasicos }}
                ocultarButtonFinalizar
                propsButtonCancelar={{ onClick: () => history.push("/compras/entrada/entrada-produtos") }}
            />
        </PageBase>
    );
};
