import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useFormik } from "formik";

import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { axiosPut } from "@/services/http";
import useToast from "@/hooks/useToast";
import useEntrada from "@/hooks/useEntrada";

const EntradaComplementoNfManual = ({ onSave }, ref) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notaFiscalEntrada, setNotaFiscalEntrada] = useState(null);
    const { entradaPendente } = useEntrada();
    const { showError } = useToast();

    const formik = useFormik({
        initialValues: {
            entrada: null,
            nota_fiscal: null,
            outros_valores: 0,
            custos_extras: 0,
            total_custos: 0,
            valor_total_fin: 0,
            pis_cofins_frete_aprop: 0,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        setLoading(true);
        const { status } = await axiosPut(`/compras/notafiscal-entradas/${values.id}/`, values);
        setLoading(false);
        if (status === 200) {
            if (typeof onSave === "function") onSave();
            setVisible(false);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível processar a requisição.",
                life: 3000,
            });
        }
    }

    const abrirModal = (nfEntrada) => {
        if (nfEntrada) {
            setNotaFiscalEntrada(nfEntrada);
            formik.setValues({
                id: nfEntrada.id,
                entrada: nfEntrada.entrada,
                nota_fiscal: nfEntrada.nota_fiscal.id,
                outros_valores: nfEntrada.outros_valores,
                custos_extras: nfEntrada.custos_extras,
                total_custos: nfEntrada.total_custos,
                valor_total_fin: nfEntrada.valor_total_fin,
                pis_cofins_frete_aprop: nfEntrada.pis_cofins_frete_aprop,
            });
        }
        setVisible(true);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const renderFooter = () => {
        return (
            <div>
                <Button
                    type="submit"
                    label="Gravar"
                    icon="pi pi-save"
                    disabled={!entradaPendente}
                    loading={loading}
                    onClick={formik.handleSubmit}
                />
            </div>
        );
    };

    return (
        <Dialog
            header={`Complemento de nota fiscal: ${notaFiscalEntrada?.nota_fiscal?.numero}`}
            visible={visible}
            onHide={() => setVisible(false)}
            style={{ width: "50vw" }}
            footer={renderFooter}
            blockScroll
        >
            <form>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="outros-valores">Outros valores</label>
                        <MakoInputMoeda
                            id="outros-valores"
                            name="outros_valores"
                            valueMoeda={formik.values.outros_valores}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="custos-extras">Custos extras</label>
                        <MakoInputMoeda
                            id="custos-extras"
                            name="custos_extras"
                            valueMoeda={formik.values.custos_extras}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="total-custos">Total dos custos</label>
                        <MakoInputMoeda
                            id="total-custos"
                            name="total_custos"
                            valueMoeda={formik.values.total_custos}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="valor-total-financeiro">Valor total financeiro</label>
                        <MakoInputMoeda
                            id="valor-total-financeiro"
                            name="valor_total_fin"
                            valueMoeda={formik.values.valor_total_fin}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="pis-cofins-frete-apropriado">PIS/COFINS sobre frete aprop.</label>
                        <MakoInputMoeda
                            id="pis-cofins-frete-apropriado"
                            name="pis_cofins_frete_aprop"
                            valueMoeda={formik.values.pis_cofins_frete_aprop}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                </div>
            </form>
        </Dialog>
    );
};

export const ModalEntradaComplementoNfManual = forwardRef(EntradaComplementoNfManual);
