import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { DadosBasicosOrdemCompraForm } from "./formDadosBasicos";
import { ParcelasOrdemCompraForm } from "./formParcelas";
import { ItensOrdemCompraForm } from "./formItens";
import { TransportadoresOrdemCompraForm } from "./formTransportadores";
import { AnotacoesOrdemCompraForm } from "./formAnotacoes";
import { PageBase } from "@/components/PageBase";
import { MakoMultiTabs } from "@/components/MakoMultiTabs";
import useLoading from "@/hooks/useLoading";
import useOrdemCompra from "@/hooks/useOrdemCompra";
import useToast from "@/hooks/useToast";

export const TabOrdemCompra = ({ ordemCompraId }) => {
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const { submit, loadingOC, dadosBasicos, handleOrdemCompra, handlePreencherOrdemCompra } = useOrdemCompra();

    const TABS = [
        {
            header: "Informações Básicas",
            icon: "pi pi-fw pi-book",
            component: <DadosBasicosOrdemCompraForm />,
        },
        {
            header: "Itens",
            icon: "fas pi-fw fa-cubes",
            disabled: !submit,
            component: <ItensOrdemCompraForm />,
        },
        {
            header: "Transportadores",
            icon: "fas pi-fw fa-truck",
            disabled: !submit,
            component: <TransportadoresOrdemCompraForm />,
        },
        {
            header: "Parcelas",
            icon: "pi pi-fw pi-money-bill",
            disabled: !submit,
            component: <ParcelasOrdemCompraForm />,
        },
        {
            header: "Anotações",
            icon: "pi pi-fw pi-pencil",
            component: <AnotacoesOrdemCompraForm />,
        },
    ];

    const voltar = () => {
        history.push("/compras/ordens-compra/ordens-compra");
    };

    const persistirOrdemCompra = async () => {
        showLoading();
        const status = await handleOrdemCompra();
        hideLoading();
        if (status === 201) {
            showSuccess({
                summary: "Sucesso!",
                detail: "Ordem de Compra cadastrada com sucesso!",
                life: 1500,
            });

            setTimeout(() => {
                voltar();
            }, 2000);
        } else if (status === 200) {
            showSuccess({
                summary: "Sucesso!",
                detail: "Ordem de Compra alterada com sucesso!",
                life: 1500,
            });
            setTimeout(() => {
                voltar();
            }, 2000);
        } else {
            showError({
                summary: "Erro :(",
                detail: "A sua requisição não pode ser concluída.",
                life: 3000,
            });
        }
    };

    const carregarOrdemCompra = useCallback(async () => {
        if (ordemCompraId) {
            await handlePreencherOrdemCompra(ordemCompraId);
        }
    }, [ordemCompraId, handlePreencherOrdemCompra]);

    useEffect(() => {
        carregarOrdemCompra();
    }, [carregarOrdemCompra]);

    return (
        <PageBase blocked={loadingOC}>
            <MakoMultiTabs
                title={!dadosBasicos?.id ? "Nova ordem de compra" : "Manutenção de ordem de compra"}
                tabs={TABS}
                propsButtonProx={{ disabled: !submit }}
                propsButtonCancelar={{ onClick: voltar }}
                propsButtonFinalizar={{ disabled: !submit, onClick: persistirOrdemCompra }}
            />
        </PageBase>
    );
};
