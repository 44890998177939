import React, { useEffect, useRef, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { MakoSelecionarEmails } from "@/components/MakoSelecionarEmails";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";

import useCaixaMovimento from "@/hooks/useCaixaMovimento";
import useRelatorio from "@/hooks/useRelatorio";
import useEmpresa from "@/hooks/useEmpresa";

import { RELATORIO_FINANCEIRO_MOVIMENTACAOCAIXA } from "@/assets/constants/relatorios";

export const FormResumoCaixa = ({ onFinish }) => {
    const [visible, setVisible] = useState(false);
    const [emails, setEmails] = useState([]);

    const { solicitarRelatorio } = useRelatorio();
    const { caixaMov } = useCaixaMovimento();
    const { empresaSelecionadaId } = useEmpresa();

    const toastRef = useRef();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            empresa: null,
            caixa: null,
            controle: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values = null) {
        try {
            values.caixa = caixaMov.caixa.id;

            const formSchema = Yup.object().shape({
                empresa: Yup.number()
                    .integer()
                    .required("O campo 'empresa' é obrigatório.")
                    .typeError("Selecione uma empresa"),
                caixa: Yup.number()
                    .integer()
                    .required("O campo 'caixa' é obrigatório.")
                    .typeError("Selecione uma caixa"),
                controle: Yup.string().required("O campo 'controle' é obrigatório.").typeError("Informe um controle"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtros = {};
            if (values) {
                Object.keys(values).forEach((key) => {
                    if (values[key]) filtros[key] = values[key];
                });
            }

            if (onFinish && typeof onFinish === "function") onFinish();

            solicitarRelatorio({ chave: RELATORIO_FINANCEIRO_MOVIMENTACAOCAIXA, emails, filtros });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const handleConfirm = (emails) => {
        fecharModal();
        if (emails && emails.length > 0) {
            handlePreSubmit(emails);
        }
    };

    const handlePreSubmit = (emails = []) => {
        setEmails(emails);
        formik.handleSubmit();
    };

    const limparFiltro = () => {
        formik.resetForm();
    };

    const fecharModal = () => {
        setVisible(() => false);
    };

    useEffect(() => {
        setValues({
            caixa: caixaMov?.caixa.nome,
            empresa: empresaSelecionadaId,
            controle: caixaMov.id,
        });
    }, [setValues, caixaMov, empresaSelecionadaId]);

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <form onSubmit={formik.handleSubmit}>
                    <h5>Filtro para emissão de relatório de resumo de movimentação de caixa</h5>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="empresa">Empresa: *</label>
                            <MakoDropdownEmpresas
                                id="empresa"
                                name="empresa"
                                disabled
                                placeholder="Selecione uma empresa"
                                value={formik.values.empresa}
                                onChange={(e) => setValues({ empresa: e.id })}
                                className={classNames({ "p-invalid": formik.errors.empresa })}
                            />
                            {formik.errors.empresa && <small className="p-error">{formik.errors.empresa}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="caixa">Caixa *</label>
                            <InputText
                                id="caixa"
                                name="caixa"
                                placeholder={caixaMov?.caixa.nome}
                                value={formik.values.caixa}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.caixa })}
                                disabled
                            />
                            {formik.errors.caixa && <small className="p-error">{formik.errors.caixa}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="controle">Controle: *</label>
                            <InputText
                                id="controle"
                                name="controle"
                                disabled
                                value={formik.values.controle}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.controle })}
                            />
                            {formik.errors.controle && <small className="p-error">{formik.errors.controle}</small>}
                        </div>
                    </div>
                    <div className="p-grid p-col-12 p-md-6 p-mt-2">
                        <Button
                            label="Gerar PDF"
                            icon="pi pi-file-pdf"
                            type="button"
                            onClick={() => handlePreSubmit()}
                            className="p-button-info p-mr-2"
                        />
                        <Button
                            type="button"
                            onClick={() => setVisible(true)}
                            label="Enviar por email"
                            icon="pi pi-envelope"
                            className="p-button-info p-mr-2"
                        />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            onClick={() => limparFiltro()}
                            className="p-button-warning p-mr-2"
                        />
                    </div>
                </form>
            </div>
            <Dialog visible={visible} onHide={fecharModal} style={{ width: "60vw" }} header="Informar emails">
                <MakoSelecionarEmails onCancel={fecharModal} onConfirm={handleConfirm} />
            </Dialog>
        </div>
    );
};
