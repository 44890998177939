import React, { useRef } from "react";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import { InputNumber } from "primereact/inputnumber";
import { PageBase } from "@/components/PageBase";
import { BotaoDelete } from "@/components/BotaoDelete";

export const FinanceiroHistoricoPadraoPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTROS_HISTORICOPADRAO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de histórico padrão"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/financeiro/cadastros/historico-padrao/form",
                            state: { historico: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTROS_HISTORICOPADRAO_EXCLUIR]}
                    componente={BotaoDelete}
                    url="/financeiro/historico-padrao/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir o histórico <b>{rowData.descricao}</b>?
                        </span>
                    }
                    classNames="p-mb-1"
                    tooltip="Deletar histórico"
                    tooltipOptions={{ position: "left" }}
                    msgToastErroExclusao="O histórico padrão não pode ser excluído."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_CADASTROS_HISTORICOPADRAO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/financeiro/cadastros/historico-padrao/form")}
            />
        </>
    );

    const codigoFiltroTemplate = (options) => {
        return <InputNumber value={options.value} onValueChange={(e) => options.filterCallback(e.value)} />;
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { minWidth: "10%" },
            filter: true,
            filterElement: codigoFiltroTemplate,
        },
        { field: "descricao", header: "Descrição", filter: true },
        { field: "tipo.label", header: "Tipo", style: { minWidth: "10%" } },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "10%" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Históricos Padrões"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={"/financeiro/historico-padrao/"}
                responsiva
                configTabela={{
                    filterDisplay: "menu",
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: { value: "", matchMode: "equals" },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                }}
            />
        </PageBase>
    );
};
