import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import { differenceInDays } from "date-fns";
import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";

import { ModalRecebimentoEfetivado } from "./ModalRecebimentoEfetivado";
import { PageBase } from "@/components/PageBase";
import MakoListagem from "@/components/MakoListagem";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";
import { ModalTemplateRateio } from "@/pages/Financeiro/Financeiro/TemplateRateio/ModalTemplateRateio";
import {
    FINANCEIRO_RECEBIMENTO_ACRESCIMO_CARENCIA,
    FINANCEIRO_RECEBIMENTO_ACRESCIMO_PERCENTUAL,
    FINANCEIRO_RECEBIMENTO_ACRESCIMO_DESCONTO_PERCENTUAL_MAX,
    FINANCEIRO_RECEBIMENTO_ANTECIPACAO_PERCENTUAL,
    FINANCEIRO_RECEBIMENTO_ANTECIPACAO_DIAS,
    FINANCEIRO_RECEBIMENTO_DESCONTO_PERCENTUAL,
    FINANCEIRO_RECEBIMENTO_MULTA_PERCENTUAL,
    FINANCEIRO_RECEBIMENTO_MULTA_DESCONTO_PERCENTUAL_MAX,
    FINANCEIRO_MOVIMENTACAO_CAIXA,
} from "@/assets/constants/parametros";
import { parseData, dataToStr } from "@/assets/util/datas";
import { formatarCasasDecimais } from "@/assets/util/util";
import { axiosPost } from "@/services/http";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useLoading from "@/hooks/useLoading";
import useParam from "@/hooks/useParam";
import useCaixa from "@/hooks/useCaixa";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";
import { parseNumber } from "@/assets/helpers/number";

export const RecebimentosEfetivadosPage = (props) => {
    const { caixa } = useCaixa();
    const [parcelas, setParcelas] = useState([]);
    const [resultadoRateio, setResultadoRateio] = useState([]);
    const [templatesRateio, setTemplatesRateio] = useState([]);
    const [templateRateioSelecionado, setTemplateRateioSelecionado] = useState(null);
    const [devedor, setDevedor] = useState({ doc: "", nome: "", empresa: null });
    const [submitted, setSubmitted] = useState(false);
    const [permitirRecebimentos, setPermitirRecebimentos] = useState(true);
    const [dataRecebimento, setDataRecebimento] = useState(parseData(caixa?.data_abertura) || new Date());
    const [contaFinanceiraSelecionada, setContaFinanceiraSelecionada] = useState(caixa?.conta_financeira.id);
    const [totalOriginal, setTotalOriginal] = useState(0);
    const [totalDescontos, setTotalDescontos] = useState(0);
    const [totalMulta, setTotalMulta] = useState(0);
    const [totalJuros, setTotalJuros] = useState(0);
    const [totalAcrescimos, setTotalAcrescimos] = useState(0);
    const [totalRecebido, setTotalRecebido] = useState(0);
    const [linhasExpandidas, setLinhasExpandidas] = useState(null);
    const modalRef = useRef(null);
    const modalTemplateRateioRef = useRef(null);
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const [formatarDocumento] = useFormatCNPJCPF();
    const { getParam } = useParam();
    const { empresaSelecionadaId } = useEmpresa();
    const { showSuccess, showWarning, showError } = useToast();

    async function registrarRecebimentos() {
        setSubmitted(true);

        if (contaFinanceiraSelecionada && dataRecebimento) {
            const recebimentos = parcelas.map((parcela) => {
                const formas_recebimento = parcela.forma_recebimento.map((fr) => ({
                    forma_recebimento: fr.id,
                    valor: formatarCasasDecimais(fr.valor),
                }));

                return {
                    id: parcela.id,
                    caixa: caixa ? caixa.id : null,
                    formas_recebimento,
                    documento: parcela.documento,
                    vencimento: parcela.vencimento,
                    data_recebimento: dataToStr(dataRecebimento, "yyyy-MM-dd"),
                    conta_financeira: contaFinanceiraSelecionada,
                    template_rateio:
                        resultadoRateio.length > 0 ? templateRateioSelecionado : parcela.template_rateio.id,
                    quantidade_parcelas: parcela.quantidade_parcelas,
                    numero_parcela: parcela.numero_parcela,
                    valor: formatarCasasDecimais(parcela.valor),
                    em_aberto: formatarCasasDecimais(parcela.valor),
                    multa: formatarCasasDecimais(parcela.multa),
                    juros: formatarCasasDecimais(parcela.juros),
                    descontos: formatarCasasDecimais(parcela.descontos),
                    recebido: formatarCasasDecimais(parcela.recebido),
                };
            });
            showLoading();
            const { status, data } = await axiosPost("/financeiro/registrar-recebimentos/", { recebimentos });
            hideLoading();
            if (status === 200) {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Recebimentos registrados com sucesso!",
                    life: 1500,
                });
                setSubmitted(false);
            } else if (status === 400 && !data.err) {
                showWarning({
                    summary: "Aviso",
                    detail: data.msg,
                    life: 3000,
                });
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não foi possível registrar os recebimentos!",
                    life: 3000,
                });
            }
        }
    }

    const calcularRecebimentos = useCallback(() => {
        if (props.location.state) {
            const recebimentos = props.location.state.map((recebimento) => {
                const { empresa, valor, dias_atraso } = recebimento;
                const percentJuros =
                    parseFloat(getParam(FINANCEIRO_RECEBIMENTO_ACRESCIMO_PERCENTUAL, empresa.id)?.valor) || 0;
                const carenciaJuros =
                    parseInt(getParam(FINANCEIRO_RECEBIMENTO_ACRESCIMO_CARENCIA, empresa.id)?.valor) || 0;
                const carenciaAntecipacao =
                    parseInt(getParam(FINANCEIRO_RECEBIMENTO_ANTECIPACAO_DIAS, empresa.id)?.valor) || 0;
                const percentDescontos =
                    parseFloat(getParam(FINANCEIRO_RECEBIMENTO_DESCONTO_PERCENTUAL, empresa.id)?.valor) || 0;
                const percentAntecipacao =
                    parseFloat(getParam(FINANCEIRO_RECEBIMENTO_ANTECIPACAO_PERCENTUAL, empresa.id)?.valor) || 0;
                const percentMulta =
                    parseFloat(getParam(FINANCEIRO_RECEBIMENTO_MULTA_PERCENTUAL, empresa.id)?.valor) || 0;
                const percentDescJuros =
                    parseFloat(getParam(FINANCEIRO_RECEBIMENTO_ACRESCIMO_DESCONTO_PERCENTUAL_MAX, empresa.id)?.valor) ||
                    0;
                const percentDescMulta =
                    parseFloat(getParam(FINANCEIRO_RECEBIMENTO_MULTA_DESCONTO_PERCENTUAL_MAX, empresa.id)?.valor) || 0;
                const saldo = parseFloat(valor);
                const descontos =
                    dias_atraso < 0 && carenciaAntecipacao > dias_atraso ? (saldo * percentAntecipacao) / 100 : 0;
                const recebido = formatarCasasDecimais(saldo + recebimento.multa + recebimento.juros - descontos);
                return {
                    ...recebimento,
                    forma_recebimento: [{ ...recebimento.forma_recebimento, valor: recebido }],
                    percent_juros: percentJuros,
                    percent_juros_desc_max: percentDescJuros,
                    carencia_juros: carenciaJuros,
                    carencia_antecipacao: carenciaAntecipacao,
                    percent_antecipacao: percentAntecipacao,
                    percent_descontos: percentDescontos,
                    percent_multa: percentMulta,
                    percent_multa_desc_max: percentDescMulta,
                    dias: dias_atraso,
                    valor: saldo,
                    descontos,
                    recebido,
                };
            });
            setParcelas(recebimentos);
            setTemplateRateioSelecionado(recebimentos[0].template_rateio.id);
            setDevedor({
                nome: props.location.state[0].devedor.nome,
                doc: formatarDocumento(props.location.state[0].devedor.identificacao),
                empresa: props.location.state[0].empresa,
            });
        }
    }, [props.location.state, formatarDocumento, getParam]);

    useEffect(() => {
        calcularRecebimentos();
    }, [calcularRecebimentos]);

    const calcularValoresRateados = useCallback(() => {
        const templatesId = parcelas.map((parcela) => parcela.template_rateio.id);
        if (templatesId.length >= 1 && [...new Set(templatesId)].length === 1 && templatesRateio.length > 0) {
            const template_rateio = templatesRateio.find(
                (tr) => tr.id === (templateRateioSelecionado || templatesId[0])
            );
            const template = {
                descricao: template_rateio.descricao,
                percentual_plano_conta_financeira: template_rateio.percentual_plano_conta_financeira,
                percentual_plano_conta_contabil: template_rateio.percentual_plano_conta_contabil,
                percentual_fase_projeto: template_rateio.percentual_fase_projeto,
                percentual_centro_resultado: template_rateio.percentual_centro_resultado,
            };
            let data = template_rateio.templatedetalherateio_set?.map((detalhe) => {
                const percentual = parseNumber(detalhe.percentual);
                const TIPOS_DETALHES = {
                    1: "plano_conta_contabil",
                    2: "plano_conta_financeira",
                    3: "centro_resultado",
                    4: "fase_projeto",
                };
                return {
                    tipo_detalhe: detalhe.tipo_detalhe.descricao,
                    detalhe: detalhe[TIPOS_DETALHES[detalhe.tipo_detalhe.id]]?.descricao,
                    percentual,
                    valor: 0,
                };
            });
            parcelas.forEach((parcela) => {
                data.forEach((detalhe) => {
                    detalhe.valor += (parcela.recebido * detalhe.percentual) / 100;
                });
            });
            setResultadoRateio([{ ...template, detalhe: data }]);
        }
    }, [parcelas, templateRateioSelecionado, templatesRateio]);

    useEffect(() => {
        calcularValoresRateados();
    }, [calcularValoresRateados]);

    const habilitarRecebimentos = useCallback(() => {
        if (!empresaSelecionadaId) {
            setPermitirRecebimentos(false);
            showWarning({
                summary: "Aviso!",
                detail: "Você não possui uma empresa selecionada.",
                life: 3000,
            });
            return null;
        }
        const param = getParam(FINANCEIRO_MOVIMENTACAO_CAIXA, empresaSelecionadaId);
        if (param && param.valor && caixa && caixa.bloqueado) {
            setPermitirRecebimentos(false);
            showWarning({
                summary: "Oops!",
                detail: "No momento você não pode efetuar recebimentos pois seu caixa está bloqueado.",
                life: 5000,
            });
        }
    }, [caixa, empresaSelecionadaId, getParam, showWarning]);

    useEffect(() => {
        habilitarRecebimentos();
    }, [habilitarRecebimentos]);

    const calcularTotais = useCallback(() => {
        let _original = 0,
            _descontos = 0,
            _juros = 0,
            _multa = 0,
            _recebido = 0;

        parcelas.forEach((parcela) => {
            _original += parcela.valor;
            _descontos += parcela.descontos;
            _multa += parcela.multa;
            _juros += parcela.juros;
            _recebido += parcela.recebido;
        });

        setTotalOriginal(_original);
        setTotalDescontos(_descontos);
        setTotalMulta(_multa);
        setTotalJuros(_juros);
        setTotalAcrescimos(_multa + _juros);
        setTotalRecebido(_recebido);
    }, [parcelas]);

    useEffect(() => {
        calcularTotais();
    }, [calcularTotais]);

    const editarRecebimento = (novoRecebimento) => {
        const index = parcelas.findIndex((p) => p.id === novoRecebimento.id);
        let _parcelas = [...parcelas];
        _parcelas[index] = novoRecebimento;
        setParcelas(_parcelas);
    };

    const numeroParcelaBodyTemplate = (rowData) => {
        if (rowData.numero_parcela === 0) return <span>ENTRADA</span>;
        return <span>{`${rowData.numero_parcela}/${rowData.quantidade_parcelas}`}</span>;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    onClick={() => modalRef.current?.abrirModal(rowData)}
                />
            </div>
        );
    };

    const colunas = [
        { field: "documento", header: "Documento" },
        {
            field: "numero_parcela",
            header: "Parcela",
            style: { width: "8%" },
            action: (e) => numeroParcelaBodyTemplate(e),
        },
        { field: "data_emissao", header: "Emissão", style: { width: "9%" }, dateFormat: "dd/MM/yyyy" },
        { field: "vencimento", header: "Vencimento", style: { width: "9%" }, dateFormat: "dd/MM/yyyy" },
        { field: "dias", header: "Dias", style: { width: "5%" } },
        { field: "valor", header: "Valor", money: true },
        { field: "descontos", header: "Descontos", money: true },
        { field: "multa", header: "Multa", money: true },
        { field: "juros", header: "Juros", money: true },
        { field: "recebido", header: "Recebido", money: true },
        {
            field: "actions",
            header: "Ações",
            style: { width: "6%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    const rowClass = (rowData) => {
        return {
            "table-recebimentos-overdue": differenceInDays(dataRecebimento, parseData(rowData.vencimento)) > 0,
            "table-recebimentos-pending": differenceInDays(dataRecebimento, parseData(rowData.vencimento)) < 0,
        };
    };

    const rowExpansionTemplate = (data) => {
        const colunas = [
            { field: "tipo_detalhe", header: "Tipo de detalhe" },
            { field: "detalhe", header: "Detalhe" },
            {
                field: "percentual",
                header: "Percentual",
                decimal: true,
                action: (e) => <span>{`${e.percentual} %`}</span>,
            },
            { field: "valor", header: "Valor do rateio", money: true },
        ];
        return (
            <div className="p-p-3">
                <MakoListagem colunas={colunas} dadosLocal={data.detalhe} />
            </div>
        );
    };

    const colunasTemplateRateio = [
        { expander: true, style: { width: "5rem" } },
        { field: "descricao", header: "Regra de rateio" },
        { field: "percentual_plano_conta_financeira", header: "% conta financeira", decimal: true },
        { field: "percentual_plano_conta_contabil", header: "% conta contábil", decimal: true },
        { field: "percentual_fase_projeto", header: "% fase de projeto", decimal: true },
        { field: "percentual_centro_resultado", header: "% centro de resultado", decimal: true },
    ];

    return (
        <PageBase>
            <ModalRecebimentoEfetivado ref={modalRef} onConfirmar={(e) => editarRecebimento(e)} />
            <ModalTemplateRateio
                ref={modalTemplateRateioRef}
                formProps={{
                    derivar: true,
                    location: { state: templatesRateio.find((tr) => tr.id === templateRateioSelecionado) },
                }}
            />
            <h5>Recebendo parcelas</h5>
            <Panel header="Sacado" toggleable>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="doc">CPF / CNPJ</label>
                        <InputText id="doc" disabled value={devedor.doc} />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="nome-completo">Nome completo</label>
                        <InputText id="nome-completo" disabled value={devedor.nome} />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="caixa">Conta / Caixa *</label>
                        <Dropdown
                            id="caixa"
                            url={`/financeiro/contas-financeiras/?query={id,descricao}&limit=50`}
                            disabled={!!caixa}
                            filter
                            filterBy="descricao"
                            optionValue="id"
                            optionLabel="descricao"
                            value={contaFinanceiraSelecionada}
                            onChange={(e) => setContaFinanceiraSelecionada(e.value)}
                        />
                        {submitted && !contaFinanceiraSelecionada && (
                            <small className="p-error">O campo 'conta / caixa' é obrigatório.</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data-recebimento">Data do recebimento *</label>
                        <MakoCalendar
                            id="data-recebimento"
                            minDate={new Date()}
                            disabled={!!caixa}
                            valueCalendar={dataRecebimento}
                            onChange={(e) => setDataRecebimento(e.value)}
                        />
                        {submitted && !dataRecebimento && (
                            <small className="p-error">O campo 'data do recebimento' é obrigatório.</small>
                        )}
                    </div>
                </div>
            </Panel>
            <Panel header="Resultado do rateio" toggleable collapsed className="p-mt-3">
                <div className="p-inputgroup p-mb-2">
                    <Dropdown
                        url="/financeiro/templates-rateios/"
                        setObjects={setTemplatesRateio}
                        optionValue="id"
                        optionLabel="descricao"
                        disabled={resultadoRateio.length === 0}
                        showClear={false}
                        value={templateRateioSelecionado}
                        onChange={(e) => setTemplateRateioSelecionado(e.value)}
                    />
                    <Button
                        type="button"
                        label="Derivar"
                        icon="pi pi-copy"
                        onClick={() => modalTemplateRateioRef.current?.abrirModal()}
                    />
                </div>
                <MakoListagem
                    colunas={colunasTemplateRateio}
                    dadosLocal={resultadoRateio}
                    configTabela={{
                        expandedRows: linhasExpandidas,
                        onRowToggle: (e) => setLinhasExpandidas(e.data),
                        rowExpansionTemplate,
                    }}
                />
            </Panel>
            <MakoListagem
                titulo="Parcelas à receber"
                colunas={colunas}
                dadosLocal={parcelas}
                configTabela={{
                    paginator: true,
                    rowClassName: rowClass,
                }}
            />
            <Divider align="center">
                <b>Totais</b>
            </Divider>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="original">Total valor original</label>
                    <MakoInputMoeda id="original" disabled valueMoeda={totalOriginal} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="total-descontos">Total dos descontos</label>
                    <MakoInputMoeda id="total-descontos" disabled valueMoeda={totalDescontos} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="total-multa">Total de multa</label>
                    <MakoInputMoeda id="total-multa" disabled valueMoeda={totalMulta} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="total-juros">Total de juros</label>
                    <MakoInputMoeda id="total-juros" disabled valueMoeda={totalJuros} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="total-acrescimos">Total dos acréscimos</label>
                    <MakoInputMoeda id="total-acrescimos" disabled valueMoeda={totalAcrescimos} />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="total-recebimento">Total do recebimento</label>
                    <MakoInputMoeda id="total-recebimento" disabled valueMoeda={totalRecebido} />
                </div>
            </div>
            <Button
                label="Registrar recebimentos"
                icon="pi pi-dollar"
                disabled={!permitirRecebimentos}
                onClick={registrarRecebimentos}
            />
            <Button
                label="Cancelar"
                icon="pi pi-times"
                className="p-button-danger p-ml-2"
                onClick={() => history.push("/financeiro/financeiro/recebimentos")}
            />
        </PageBase>
    );
};
