import React, { memo, useCallback, useEffect, useRef, useState, useMemo } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { DataScroller } from "primereact/datascroller";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";

import { MakoCalendar } from "@/components/MakoCalendar";

import { PESSOAS_ENDERECOS_TIPOS_IDENTIFICACAO } from "@/assets/constants/parametros";
import { OP_CRUD_DJANGO } from "@/assets/util/persistenciaDjango";
import { axiosGet } from "@/services/http";

import usePessoa from "@/hooks/usePessoa";
import useParam from "@/hooks/useParam";
import useToast from "@/hooks/useToast";

const EnderecoForm = () => {
    const [loadingMunicipios, setLoadingMunicipio] = useState(false);
    const [loadingEstados, setLoadingEstados] = useState(false);
    const [loadingPaises, setLoadingPaises] = useState(false);
    const [tiposEndereco, setTiposEndereco] = useState([]);
    const [loadingTags, setLoadingTags] = useState(false);
    const [tagsEndereco, setTagsEndereco] = useState([]);
    const [loadingCEP, setLoadingCEP] = useState(false);
    const [municipios, setMunicipios] = useState([]);
    const [endereco, setEndereco] = useState(null);
    const [estados, setEstados] = useState([]);
    const [paises, setPaises] = useState([]);

    const menuAcoesRef = useRef(null);

    const { enderecos, handleEndereco, handleEnderecoPrincipal, checarInformacao } = usePessoa();
    const { showSuccess, showError } = useToast();
    const { getParam } = useParam();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            identificacao: "",
            pais: "BR",
            cep: "",
            logradouro: "",
            numero: "",
            complemento: "",
            bairro: "",
            estado: null,
            cidade: null,
            ponto_referencia: "",
            principal: false,
            data_alteracao: new Date(),
            ativo: true,
            _status: OP_CRUD_DJANGO.novo,
            tag_endereco: null,
        },
        onSubmit: handleSubmit,
    });

    const listarPaises = useCallback(async () => {
        setLoadingPaises(true);
        const json = await axiosGet("/pessoas/paises?limit=300");
        setLoadingPaises(false);

        if (json.status === 200) {
            setPaises(json.data.results);
        }
    }, []);

    useEffect(() => {
        listarPaises();
    }, [listarPaises]);

    const listarEstados = useCallback(async () => {
        if (formik.values.pais) {
            const pais = paises.find((item) => item.sigla === formik.values.pais);
            if (pais) {
                setLoadingEstados(true);
                const json = await axiosGet(
                    `/pessoas/estados?query={id,nome,uf,codigo_uf}&pais__sigla=${pais.sigla}&limit=100`
                );
                setLoadingEstados(false);

                if (json.status === 200) {
                    setEstados(json.data.results);
                }
            }
        }
    }, [formik.values.pais, paises]);

    const listarTagsEndereco = useCallback(async () => {
        setLoadingTags(true);
        const json = await axiosGet(`/pessoas/tags-enderecos-perfis?limit=300`);
        setLoadingTags(false);

        if (json.status === 200) {
            setTagsEndereco(json.data.results);
        }
    }, []);

    useEffect(() => {
        listarTagsEndereco();
        listarEstados();
    }, [listarEstados, listarTagsEndereco]);

    const listarCidadesPorEstado = useCallback(async () => {
        if (formik.values.estado) {
            setLoadingMunicipio(true);
            const json = await axiosGet(
                `/pessoas/cidades/?estado=${formik.values.estado}&query={id,nome}&limit=1000&ordering=nome`
            );
            setLoadingMunicipio(false);

            if (json.status === 200) {
                setMunicipios(json.data.results);
            }
        }
    }, [formik.values.estado]);

    useEffect(() => {
        listarCidadesPorEstado();
    }, [listarCidadesPorEstado]);

    useEffect(() => {
        const tiposEnderecoParam = getParam(PESSOAS_ENDERECOS_TIPOS_IDENTIFICACAO);

        if (tiposEnderecoParam) {
            const tiposEnd = tiposEnderecoParam.valor.split("|");

            setTiposEndereco(tiposEnd.map((tipo) => ({ id: tipo, label: tipo })));
        }
    }, [getParam]);

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                identificacao: Yup.string().required("O campo 'identificação do endereço' é obrigatório."),
                cep: Yup.string().required("O campo 'cep' é obrigatório."),
                logradouro: Yup.string().required("O campo 'logradouro' é obrigatório."),
                numero: Yup.string().required("O campo 'numero' é obrigatório."),
                bairro: Yup.string().required("O campo 'bairro' é obrigatório."),
                pais: Yup.string().required("O campo 'país' é obrigatório.").typeError("Selecione um país."),
                estado: Yup.number().required("O campo 'estado' é obrigatório.").typeError("Selecione um estado."),
                cidade: Yup.number()
                    .required("O campo 'municipio' é obrigatório.")
                    .typeError("Selecione um municipio."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            let estado = estados.find((el) => el.id === values.estado);
            const pais = paises.find((item) => item.sigla === values.pais);
            estado = {
                ...estado,
                pais: pais,
            };

            let cidade = municipios.find((el) => el.id === values.cidade);
            cidade = {
                ...cidade,
                estado,
            };

            const endereco = {
                ...values,
                localidade: cidade,
            };

            handleEndereco(endereco, values._status);
            formik.resetForm();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const alterarDataAtualizacaoEndereco = useCallback(
        async (id) => {
            const resposta = await checarInformacao("enderecos", id);

            if (resposta === 200) {
                showSuccess({
                    summary: "Sucesso!",
                    detail: "Endereço verificado com sucesso.",
                    life: 1500,
                });
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "A sua requisição não pode ser concluída.",
                    life: 3000,
                });
            }
        },
        [checarInformacao, showSuccess, showError]
    );

    const consultarCEP = async (value) => {
        if (formik.values.pais === "BR") {
            setLoadingCEP(true);
            const json = await axiosGet(`/pessoas/consultar-cep/${value}/`);
            setLoadingCEP(false);

            if (json.status === 200) {
                const { logradouro, bairro, municipio, estado } = json.data;

                formik.setFieldValue("estado", estado.id);
                formik.setFieldValue("cidade", municipio.id);
                formik.setFieldValue("logradouro", logradouro);
                formik.setFieldValue("bairro", bairro);
            }
        }
    };

    const itensMenuAcoes = useMemo(() => {
        return [
            {
                label: "Checar endereço",
                icon: "pi pi-check",
                disabled: !!!endereco?.id,
                command: () => alterarDataAtualizacaoEndereco(endereco?.id),
            },
            {
                label: "Definir como prinicipal",
                icon: "pi pi-star-fill",
                command: () => handleEnderecoPrincipal(endereco),
            },
            {
                label: endereco?.ativo ? "Desativar endereço" : "Ativar endereço",
                icon: "pi pi-ban",
                command: () => handleEndereco({ ...endereco, ativo: !endereco.ativo }, OP_CRUD_DJANGO.editar),
            },
            {
                label: "Excluir endereço",
                icon: "pi pi-trash",
                command: () => handleEndereco(endereco, OP_CRUD_DJANGO.deletar),
            },
        ];
    }, [endereco, handleEndereco, alterarDataAtualizacaoEndereco, handleEnderecoPrincipal]);

    const itemTemplate = useCallback(
        (data) => {
            return (
                <div className="product-list-item">
                    <div className="product-list-detail">
                        <span className={`product-badge status-${data.principal ? "instock" : "lowstock"}`}>
                            {data.principal ? "Principal" : "Alternativo"}
                        </span>
                        <div className="product-name">{`${data.logradouro}, ${data.numero} - ${data.bairro}`}</div>
                        <div className="product-description">{`${data.localidade.nome} / ${data.localidade.estado.uf} - ${data.cep} - ${data.localidade.estado.pais.nome}`}</div>
                        <i className="pi pi-tag product-category-icon" />
                        <span className="product-category">{data.identificacao}</span>
                        {data.tag_endereco?.tag ? (
                            <span className="product-category">
                                {" "}
                                | <i className="pi pi-tags product-category-icon" />
                                {data.tag_endereco?.tag}
                            </span>
                        ) : null}
                    </div>
                    <div className="product-list-action">
                        <div className="p-text-right">
                            <Button
                                icon="pi pi-pencil"
                                className="p-button-rounded p-button-warning p-mr-2"
                                tooltipOptions={{ position: "bottom" }}
                                onClick={() => {
                                    setValues({
                                        ...data,
                                        pais: data.pais.sigla,
                                        tag_endereco: data.tag_endereco || null,
                                        _status: OP_CRUD_DJANGO.editar,
                                    });
                                }}
                            />
                            <Menu model={itensMenuAcoes} popup ref={menuAcoesRef} id="popup_menu" />
                            <Button
                                icon="pi pi-cog"
                                aria-haspopup
                                aria-controls="popup_menu_acoes"
                                className="p-button-rounded p-button-info"
                                onClick={(e) => {
                                    setEndereco(data);
                                    menuAcoesRef.current?.toggle(e);
                                }}
                            />
                        </div>
                        {(data._id || (data._status && data._status !== OP_CRUD_DJANGO.novo)) && (
                            <div className="product-description p-mb-0" style={{ color: "#f00" }}>
                                **Clique em <b>Finalizar</b> para confirmar**
                            </div>
                        )}
                    </div>
                </div>
            );
        },
        [setValues, itensMenuAcoes]
    );

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="identificacao">Identificação do endereço *</label>
                        {tiposEndereco.length > 0 ? (
                            <Dropdown
                                id="identificacao"
                                name="identificacao"
                                options={tiposEndereco}
                                optionValue="id"
                                optionLabel="label"
                                value={formik.values.identificacao}
                                onChange={formik.handleChange}
                                placeholder="Selecione..."
                                className={classNames({ "p-invalid": formik.errors.identificacao })}
                            />
                        ) : (
                            <InputText
                                id="identificacao"
                                name="identificacao"
                                value={formik.values.identificacao}
                                onChange={formik.handleChange}
                                placeholder="Sugestão: Residencial | Profissional"
                                className={classNames({ "p-invalid": formik.errors.identificacao })}
                            />
                        )}
                        {formik.errors.identificacao && (
                            <small className="p-error">{formik.errors.identificacao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="pais">País *</label>
                        <Dropdown
                            id="pais"
                            name="pais"
                            disabled={loadingPaises}
                            placeholder={!loadingPaises ? "Selecione" : "Buscando..."}
                            options={paises}
                            optionLabel="nome"
                            optionValue="sigla"
                            filter
                            filterBy="nome"
                            value={formik.values.pais}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.pais })}
                        />
                        {formik.errors.pais && <small className="p-error">{formik.errors.pais}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="cep">CEP *</label>
                        <span className="p-input-icon-right">
                            {loadingCEP && <i className="pi pi-spin pi-spinner" />}
                            {formik.values.pais === "BR" ? (
                                <InputMask
                                    id="cep"
                                    name="cep"
                                    disabled={!!!formik.values.pais}
                                    value={formik.values.cep}
                                    onChange={formik.handleChange}
                                    onComplete={(e) => consultarCEP(e.value)}
                                    mask="99.999-999"
                                    className={classNames({ "p-invalid": formik.errors.cep })}
                                />
                            ) : (
                                <InputText
                                    id="cep"
                                    name="cep"
                                    disabled={!!!formik.values.pais}
                                    value={formik.values.cep}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.cep })}
                                />
                            )}
                            {formik.errors.cep && <small className="p-error">{formik.errors.cep}</small>}
                        </span>
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="estado">Estado / Província *</label>
                        <Dropdown
                            id="estado"
                            name="estado"
                            options={estados}
                            disabled={loadingEstados}
                            placeholder={!loadingEstados ? "Selecione" : "Buscando..."}
                            optionLabel="nome"
                            optionValue="id"
                            filter
                            showClear
                            filterBy="nome"
                            value={formik.values.estado}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.estado,
                            })}
                        />
                        {formik.errors.estado && <small className="p-error">{formik.errors.estado}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="cidade">Município *</label>
                        <Dropdown
                            id="cidade"
                            name="cidade"
                            options={municipios}
                            disabled={loadingMunicipios}
                            placeholder={loadingMunicipios ? "Buscando os municípios..." : "Selecione..."}
                            optionLabel="nome"
                            optionValue="id"
                            filter
                            showClear
                            filterBy="nome"
                            emptyMessage="Nenhuma cidade encontrada."
                            emptyFilterMessage="Nenhuma cidade encontrada."
                            value={formik.values.cidade}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.cidade,
                            })}
                        />
                        {formik.errors.cidade && <small className="p-error">{formik.errors.cidade}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="logradouro">Logradouro *</label>
                        <InputText
                            id="logradouro"
                            name="logradouro"
                            value={formik.values.logradouro}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.logradouro })}
                        />
                        {formik.errors.logradouro && <small className="p-error">{formik.errors.logradouro}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="numero">Numero *</label>
                        <InputText
                            id="numero"
                            name="numero"
                            value={formik.values.numero}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.numero })}
                        />
                        {formik.errors.numero && <small className="p-error">{formik.errors.numero}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="complemento">Complemento</label>
                        <InputText
                            id="complemento"
                            name="complemento"
                            value={formik.values.complemento}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="bairro">Bairro *</label>
                        <InputText
                            id="bairro"
                            name="bairro"
                            value={formik.values.bairro}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.bairro })}
                        />
                        {formik.errors.bairro && <small className="p-error">{formik.errors.bairro}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="ref">Ponto de referência</label>
                        <InputText
                            id="ref"
                            name="ponto_referencia"
                            value={formik.values.ponto_referencia}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="tag_endereco">Tag</label>
                        <Dropdown
                            id="tag_endereco"
                            name="tag_endereco"
                            options={tagsEndereco}
                            disabled={loadingTags}
                            placeholder={loadingTags ? "Buscando..." : "Selecione"}
                            optionLabel="tag"
                            filter
                            filterBy="nome"
                            showClear
                            emptyMessage="Nenhuma tag encontrada."
                            emptyFilterMessage="Nenhuma tag encontrada."
                            value={formik.values.tag_endereco}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data-atualizacao">Última atualização</label>
                        <MakoCalendar
                            id="data-atualizacao"
                            name="data_alteracao"
                            disabled
                            showIcon={false}
                            valueCalendar={formik.values.data_alteracao}
                        />
                    </div>
                </div>
                {enderecos.length === 0 && (
                    <div className="p-fluid p-formgrid p-grid p-mb-2">
                        <div className="p-field-checkbox p-col-12 p-md-3 p-mt-2">
                            <Checkbox
                                id="principal"
                                name="principal"
                                checked={formik.values.principal}
                                onChange={formik.handleChange}
                            />
                            <label htmlFor="principal">Endereço principal?</label>
                        </div>
                    </div>
                )}
                <p>
                    <b>* Campos obrigatórios</b>
                </p>
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button type="submit" icon="pi pi-check" label="Gravar" className="p-mr-2 p-mb-2" />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            className="p-button-warning p-mr-2 p-mb-2"
                            onClick={() => formik.resetForm()}
                        />
                    </div>
                </div>
            </form>
            <div className="list-demo">
                <DataScroller
                    value={enderecos}
                    itemTemplate={itemTemplate}
                    rows={3}
                    inline
                    scrollHeight="300px"
                    header="Endereços cadastrados"
                    emptyMessage="Nenhum endereço encontrado"
                />
            </div>
        </>
    );
};

export default memo(EnderecoForm);
