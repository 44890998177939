import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "@/components/Dropdown";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import MakoListagem from "@/components/MakoListagem";
import useEmpresa from "@/hooks/useEmpresa";
import classNames from "classnames";
import { TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE } from "@/assets/constants/financeiro";
import useToast from "@/hooks/useToast";

const TIPOS_VALIDOS_FORMA_RECEBIMENTO_QUALQUER = TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE.filter(
    ({ id }) => ![8, 7, 5].includes(id)
).map(({ id }) => id);

const TIPOS_VALIDOS_FORMA_RECEBIMENTO_CONTA_BANCARIA = TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE.filter(
    ({ id }) => ![8, 5].includes(id)
).map(({ id }) => id);

const PagamentoEfetivadoModalForm = ({ onConfirmar }, ref) => {
    const [visible, setVisible] = useState(false);
    const [formasPagamentoRateio, setFormasPagamentoRateio] = useState([]);
    const [formasPagamento, setFormasPagamento] = useState([]);
    const [formaPagamento, setFormaPagamento] = useState(null);
    const [errorFormaRecebRateio, setErrorFormaRecebRateio] = useState(null);
    const [contas, setContas] = useState([]);
    const [valor, setValor] = useState(0);

    const { empresaSelecionadaId } = useEmpresa();
    const { showWarning } = useToast();

    const { setValues, setFieldError, setFieldValue, ...formik } = useFormik({
        initialValues: {
            documento: "",
            parcela: "",
            data_emissao: null,
            vencimento: null,
            dias: 0,
            descontos: 0,
            acrescimo: 0,
            valor: 0,
            conta_financeira: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                conta_financeira: Yup.number().required("O campo 'conta financeira' é obrigatório").typeError("O campo 'conta financeira' é obrigatório"),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            const totalRateio = formasPagamentoRateio.reduce((total, atual) => total + atual.valor, 0);
            if (totalRateio > values.valor)
                return showWarning({
                    summary: "Alerta!",
                    detail: "A soma dos valores está maior que o total a ser pago.",
                    life: 5000,
                });
            else if (totalRateio < values.valor)
                return showWarning({
                    summary: "Alerta!",
                    detail: "A soma dos valores está menor que o total a ser pago.",
                    life: 5000,
                });
            if (onConfirmar)
                onConfirmar({
                    ...values,
                    forma_recebimento: formasPagamentoRateio,
                });
            setVisible(false);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;

                    if (err.path === "rateio") {
                        setErrorFormaRecebRateio(err.message);
                    }
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const abrirModal = (parcela) => {
        setVisible(true);
        if (parcela) {
            const { numero_parcela, quantidade_parcelas } = parcela;
            setValues({
                ...parcela,
                parcela: numero_parcela === 0 ? "ENTRADA" : `${numero_parcela}/${quantidade_parcelas}`,
                descontos_percent: 0,
            });
        }
        if (!formik.values.conta_financeira)
            setFieldError("conta_financeira", "O campo 'conta financeira' é obrigatório");
    };

    useImperativeHandle(ref, () => {
        return {
            abrirModal,
        };
    });

    useEffect(() => {
        if (formik.values.forma_recebimento) setFormasPagamentoRateio(formik.values.forma_recebimento);
    }, [formik.values.forma_recebimento]);

    const incluirRateioFormaPagamento = () => {
        const total = formasPagamentoRateio.reduce((total, atual) => total + atual.valor, 0);
        if (total + valor <= formik.values.valor) {
            let rateios = [...formasPagamentoRateio];
            const _formaPagamento = formasPagamento.find((fr) => fr.id === formaPagamento);
            rateios.push({ ..._formaPagamento, valor });
            setFormasPagamentoRateio(rateios);
            setErrorFormaRecebRateio(null);
        } else {
            setErrorFormaRecebRateio("A soma total ultrapassa o valor a ser pago.");
        }
    };

    const excluirRateioFormaPagamento = (index) => {
        const rateios = [...formasPagamentoRateio];
        rateios.splice(index, 1);

        setFormasPagamentoRateio(rateios);
    };

    const actionBodyTemplate = (rowData, rowInfo) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    onClick={() => excluirRateioFormaPagamento(rowInfo.rowIndex)}
                />
            </div>
        );
    };

    const colunas = [
        { field: "descricao", header: "Forma de recebimento" },
        { field: "valor", header: "Valor", style: { width: "30%" }, money: true },
        {
            field: "actions",
            header: "Ações",
            style: { width: "10%" },
            action: (e, i) => actionBodyTemplate(e, i),
        },
    ];

    const rodape = () => {
        return (
            <div>
                <Button type="submit" label="Confirmar" icon="pi pi-save" onClick={formik.handleSubmit} autoFocus />
            </div>
        );
    };

    const aposBuscar = useCallback((data) => {
        setContas(data);
        return data;
    }, []);

    const aposBuscarFormas = useCallback((data) => {
        setFormasPagamento(data);
        return data;
    }, []);

    const tiposFormas = useMemo(() => {
        const _conta = contas.find(({ id }) => id === formik.values.conta_financeira);
        const tipo = _conta?.tipo_conta?.id;
        const tipos =
            tipo === 2 ? TIPOS_VALIDOS_FORMA_RECEBIMENTO_CONTA_BANCARIA : TIPOS_VALIDOS_FORMA_RECEBIMENTO_QUALQUER;
        return tipos.join(",");
    }, [contas, formik.values.conta_financeira]);

    return (
        <Dialog
            visible={visible}
            header="Editar item do pagamento"
            footer={rodape}
            onHide={() => setVisible(false)}
            style={{ width: "60vw" }}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="conta_financeira">Conta financeira *</label>
                        <Dropdown
                            id="conta_financeira"
                            name="conta_financeira"
                            url={`/financeiro/contas-financeiras/?query={id,descricao}&perfil=${empresaSelecionadaId}`}
                            placeholder="Selecione uma conta financeira"
                            emptyMessage="Não existem contas cadastradas."
                            showClear
                            aposBuscar={aposBuscar}
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.conta_financeira}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.conta_financeira })}
                        />
                        {formik.errors.conta_financeira && (
                            <small className="p-error">{formik.errors.conta_financeira}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="documento">Documento</label>
                        <InputText id="documento" name="documento" disabled value={formik.values.documento} />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="parcela">Parcela</label>
                        <InputText id="parcela" name="parcela" disabled value={formik.values.parcela} />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="data-emissao">Emissão</label>
                        <MakoCalendar
                            id="data-emissao"
                            name="data_emissao"
                            disabled
                            valueCalendar={formik.values.data_emissao}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="data-vencimento">Vencimento</label>
                        <MakoCalendar
                            id="data-vencimento"
                            name="vencimento"
                            disabled
                            valueCalendar={formik.values.vencimento}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="dias">Dias de atraso</label>
                        <InputText id="dias" name="dias" disabled value={formik.values.dias} />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="total-pagamento">Total a pagar</label>
                        <MakoInputMoeda id="total-pagamento" disabled valueMoeda={formik.values.valor} />
                    </div>
                </div>
            </form>
            <Divider align="center">
                <b>Formas de recebimento</b>
            </Divider>
            {errorFormaRecebRateio && <small className="p-error">{errorFormaRecebRateio}</small>}
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-6">
                    <Dropdown
                        id="forma-pagamento"
                        placeholder="Selecione a forma de pagamento"
                        url={`/financeiro/formas-recebimentos/?limit=300&escopo__in=X,P&tipo__in=${tiposFormas}`}
                        filter
                        filterBy="id,descricao"
                        optionValue="id"
                        optionLabel="descricao"
                        value={formaPagamento}
                        aposBuscar={aposBuscarFormas}
                        onChange={(e) => setFormaPagamento(e.value)}
                    />
                </div>
                <div className="p-field p-col-12 p-md-5">
                    <MakoInputMoeda id="valor" valueMoeda={valor} onChangeMoeda={(e) => setValor(e.value)} />
                </div>
                <div className="p-field p-col-12 p-md-1">
                    <Button
                        icon="pi pi-plus"
                        className="p-button-success"
                        onClick={() => incluirRateioFormaPagamento()}
                    />
                </div>
            </div>
            {/* <h5 className="p-mt-0">{`Valor restante: ${parseMoeda(totalParcela)}`}</h5> */}
            <MakoListagem
                dadosLocal={formasPagamentoRateio}
                colunas={colunas}
                configTabela={{
                    paginator: true,
                }}
            />
        </Dialog>
    );
};

export const ModalPagamentoEfetivado = forwardRef(PagamentoEfetivadoModalForm);
