import React, { useState, useImperativeHandle, forwardRef } from "react";
import classNames from "classnames";
import { compareAsc } from "date-fns";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import useRecebimento from "@/hooks/useRecebimento";
import { parseData } from "@/assets/util/datas";

const Modal = (props, ref) => {
    const [visible, setVisible] = useState(false);
    const { recebimentos, handleEditarRecebimento } = useRecebimento();

    const formik = useFormik({
        initialValues: {
            index: 0,
            vencimento: null,
            valor: 0,
            historico_original: "",
            paga_comissao_recebimento: false,
            previsao: false,
        },
        onSubmit: handleSubmit,
    });

    const validarVencimento = (data, rowIndex) => {
        if (rowIndex === 0) {
            const prox = recebimentos[rowIndex + 1].vencimento;
            const proxVencimento = prox instanceof Date ? prox : parseData(prox);
            return compareAsc(proxVencimento, data) > 0;
        } else if (rowIndex === recebimentos.length - 1) {
            const ant = recebimentos[rowIndex - 1].vencimento;
            const antVencimento = ant instanceof Date ? ant : parseData(ant);
            return compareAsc(data, antVencimento) > 0;
        } else {
            const prox = recebimentos[rowIndex + 1].vencimento;
            const proxVencimento = prox instanceof Date ? prox : parseData(prox);
            const ant = recebimentos[rowIndex - 1].vencimento;
            const antVencimento = ant instanceof Date ? ant : parseData(ant);
            return compareAsc(data, antVencimento) > 0 && compareAsc(proxVencimento, data) > 0;
        }
    };

    function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                vencimento: Yup.date()
                    .required("O campo 'vencimento' é obrigatório.")
                    .test({
                        test: (e) => validarVencimento(e, values.index),
                        message: "A data de vencimento não é válida para esse recebimento.",
                    })
                    .typeError("Informe uma data válida."),
                valor: Yup.number()
                    .required("O campo 'valor' é obrigatório.")
                    .min(0.01, "Esse campo não pode ser ZERO."),
                historico_original: Yup.string().required("O campo 'histórico' é obrigatório."),
                paga_comissao_recebimento: Yup.boolean().default(false),
                previsao: Yup.boolean().default(false),
            });
            const dadosValidos = formSchema.validateSync(values, { abortEarly: false });
            handleEditarRecebimento(dadosValidos, values.index);
            formik.resetForm();
            setVisible(false);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const abrirModal = (recebimento) => {
        if (recebimento) formik.setValues(recebimento);
        setVisible(true);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    return (
        <Dialog
            header="Editar recebimento"
            visible={visible}
            onHide={() => setVisible(false)}
            style={{ width: "60vw" }}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="vencimento">Vencimento</label>
                        <MakoCalendar
                            id="vencimento"
                            name="vencimento"
                            valueCalendar={formik.values.vencimento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.vencimento })}
                        />
                        {formik.errors.vencimento && <small className="p-error">{formik.errors.vencimento}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor">Valor</label>
                        <MakoInputMoeda
                            id="valor"
                            name="valor"
                            min={0}
                            valueMoeda={formik.values.valor}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor })}
                        />
                        {formik.errors.valor && <small className="p-error">{formik.errors.valor}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-7">
                        <label htmlFor="historico-original">Histórico</label>
                        <InputText
                            id="historico-original"
                            name="historico_original"
                            value={formik.values.historico_original}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.historico_original })}
                        />
                        {formik.errors.historico_original && (
                            <small className="p-error">{formik.errors.historico_original}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field-checkbox p-col-12 p-md-4">
                        <Checkbox
                            id="paga-comissao-recebimento"
                            name="paga_comissao_recebimento"
                            checked={formik.values.paga_comissao_recebimento}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="paga-comissao-recebimento">Paga comissão no recebimento?</label>
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-2">
                        <Checkbox
                            id="previsao"
                            name="previsao"
                            checked={formik.values.previsao}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="previsao">Gerar previsão?</label>
                    </div>
                </div>
                <div className="p-grid p-col-12 p-md-2">
                    <Button type="submit" icon="pi pi-pencil" label="Gravar" />
                </div>
            </form>
        </Dialog>
    );
};

export const ModalEditarRecebimento = forwardRef(Modal);
