import React from "react";
import { Image } from "primereact/image";

import { MakoFileUpload } from "@/components/MakoFileUpload";

export const MakoUploadPreviewImage = ({ uploadConfig, imageConfig, ocultarImage = false }) => {
    return (
        <div>
            {!ocultarImage && <Image {...imageConfig} />}
            <MakoFileUpload {...uploadConfig} mode="basic" accept="image/*" className="p-text-center" />
        </div>
    );
};
