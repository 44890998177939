import React, { useState, useRef, useCallback, useEffect, forwardRef } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { useFormik } from "formik";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { Dialog } from "primereact/dialog";
import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";
import { SIM_NAO_BOOLEAN, TIPO_DIMENSAO_GRADE } from "@/assets/constants/constants";
import { Checkbox } from "primereact/checkbox";
import * as pd from "@/assets/util/persistenciaDjango";
import permissoes from "@/assets/constants/permissoes";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import useProduto from "@/hooks/useProduto";
import MakoListagem from "@/components/MakoListagem";
import * as Yup from "yup";
import { Dropdown } from "@/components/Dropdown";
import { BotaoDelete } from "@/components/BotaoDelete";
import useToast from "@/hooks/useToast";
import { Tag } from "primereact/tag";
import { MakoErrosPersonalizados } from "@/components/MakoErrosPersonalizados";

const GradeAtributosModalForm = ({ detalheSelecionado, toastRef, visible, setVisible }) => {
    const { handleInfo, dadosBasicos } = useProduto();
    const [exibirIncluir, setExibirIncluir] = useState(false);
    const [clonarGrade, setClonarGrade] = useState(false);
    const [produtoDestino, setProdutoDestino] = useState(null);
    const [gradeAtributo, setGradeAtributo] = useState(null);
    const [opcoesGrade, setOpcoesGrade] = useState([]);
    const { showLoading, hideLoading } = useLoading();
    const listagemRef = useRef(null);
    const modalErrosRef = useRef(null);
    const { showSuccess } = useToast();

    const { resetForm, setFieldValue, setValues, ...formik } = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: "",
            grade: null,
            dimensao: "",
            valor: null,
            status: "novo",
            valor_grade_variavel: false,
            calcula_medida: false,
            unidade_padrao: null,
            valor_minimo: null,
            valor_maximo: null,
            valor_conteudo: null,
            todos: false,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchemaText = Yup.object().shape({
                grade: Yup.object().required("O campo é obrigatório."),
                valor_minimo: Yup.number()
                    .when("valor_grade_variavel", {
                        is: (val) => val,
                        then: Yup.number().required("O campo é obrigatório."),
                    })
                    .nullable(),
                valor_maximo: Yup.number()
                    .when("valor_grade_variavel", {
                        is: (val) => val,
                        then: Yup.number().required("O campo é obrigatório."),
                    })
                    .nullable(),
                valor_conteudo: Yup.string()
                    .when("grade", {
                        is: (val) => !val.valor_numerico,
                        then: Yup.string().required("O campo é obrigatório."),
                    })
                    .nullable(),
                unidade_padrao: Yup.number()
                    .when("grade", {
                        is: (val) => val.valor_numerico,
                        then: Yup.number().required("O campo é obrigatório."),
                    })
                    .when("valor_grade_variavel", {
                        is: (val) => !val,
                        then: Yup.number()
                            .when("grade", {
                                is: (val) => val.valor_numerico,
                                then: Yup.number().required("O campo é obrigatório."),
                            })
                            .nullable(),
                    })
                    .nullable(),
            });

            if (!values.valor_grade_variavel) {
                values.valor_maximo = values.valor;
                values.valor_minimo = values.valor;
            }

            await formSchemaText.validate(values, {
                abortEarly: false,
            });

            showLoading();
            const { status, data } = await handleInfo(
                "/produtos/grades-atributos-sku/",
                {
                    ...values,
                    sku: detalheSelecionado.id,
                    grade: values.grade.id,
                    valor_conteudo: values.valor_grade_variavel ? null : values.valor_conteudo,
                    valor_minimo: !values.valor_grade_variavel ? null : values.valor_minimo,
                    valor_maximo: !values.valor_grade_variavel ? null : values.valor_maximo,
                },
                values.id ? pd.OP_CRUD_DJANGO.editar : pd.OP_CRUD_DJANGO.novo
            );
            hideLoading();

            if (status === 200 || status === 201) {
                setExibirIncluir(false);
                listagemRef.current?.buscarDados();
                resetForm();
            } else {
                const keys = Object.keys(data);
                const values = Object.values(data);
                modalErrosRef.current?.abrirModal(keys.map((key, index) => ({ erro: `${key}: ${values[index]}` })));
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const handleClonar = async () => {
        const { status } = await handleInfo(
            "/produtos/grades-atributos-sku/",
            {
                ...gradeAtributo,
                sku: produtoDestino,
                grade: gradeAtributo.grade.id,
            },
            pd.OP_CRUD_DJANGO.novo
        );
        if (status === 201) {
            setClonarGrade(false);
            listagemRef.current?.buscarDados();
        }
    };

    const handleStatus = async (grade) => {
        const { status } = await handleInfo(
            "/produtos/grades-atributos-sku/",
            { id: grade.id, ativo: !grade.ativo },
            pd.OP_CRUD_DJANGO.editar
        );
        if (status === 200) listagemRef.current?.buscarDados();
    };

    const exibirEditar = (obj) => {
        setValues({
            ...obj,
            unidade_padrao: obj.unidade_padrao?.id || null,
            grade: { ...obj.grade, label: `Atributo: ${obj.grade.atributo?.nome} | Grade: ${obj.grade.codigo}` },
        });
        setExibirIncluir(true);
    };

    const esconderDialogGrade = () => {
        setExibirIncluir(false);
        resetForm();
    };

    const esconderDialogClonar = () => {
        setClonarGrade(false);
        setProdutoDestino(null);
        setGradeAtributo(null);
    };

    const actionsBody = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_GRADEATRIBUTOSKU_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar"
                    onClick={() => exibirEditar(rowData)}
                    tooltipOptions={{ position: "left" }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_GRADEATRIBUTOSKU_INCLUIR]}
                    componente={Button}
                    icon="pi pi-clone"
                    className="p-button-rounded p-button-info p-mr-2 p-mb-1"
                    tooltip="Clonar"
                    onClick={() => {
                        setGradeAtributo(rowData);
                        setClonarGrade(true);
                    }}
                    tooltipOptions={{ position: "left" }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_GRADEATRIBUTOSKU_EDITAR]}
                    componente={Button}
                    icon="pi pi-cog"
                    className="p-button-rounded p-button-help p-mr-2 p-mb-1"
                    onClick={() => handleStatus(rowData)}
                    tooltip="Alterar status"
                    tooltipOptions={{ position: "left" }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_GRADEATRIBUTOSKU_EXCLUIR]}
                    componente={BotaoDelete}
                    url="/produtos/grades-atributos-sku"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente remover a grade de atributos código <b>{rowData.id}</b>?
                        </span>
                    }
                    classNames="p-mb-1"
                    tooltip="Deletar parâmetro"
                    tooltipOptions={{ position: "left" }}
                    msgToastErroExclusao="A grade de atributos não pode ser removida."
                    onDelete={() => {
                        listagemRef.current?.buscarDados();
                        showSuccess({
                            summary: "Sucesso!",
                            detail: "Grade de atributos removida com sucesso.",
                            life: 3000,
                        });
                    }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.PRODUTO_PRODUTO_GRADEATRIBUTOSKU_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setExibirIncluir(true)}
            />
        </>
    );

    const autoCompleteTemplate = (grade) => {
        grade.label = `Atributo: ${grade.atributo.nome} | Grade: ${grade.codigo}`;
        return `Atributo: ${grade.atributo.nome} | Grade: ${grade.codigo}`;
    };

    const verificaValor = (valor) => {
        if (!valor) return "Variável";
        return valor;
    };

    const statusBodyTemplate = (status) => {
        if (status) return <Tag severity="success" value="ATIVO" />;
        return <Tag severity="danger" value="DESATIVADO" />;
    };

    const colunaGradeSku = [
        { field: "id", header: "Codigo", style: { minWidth: "5%" } },
        { field: "grade.codigo", header: "Grade" },
        { field: "grade.atributo.nome", header: "Atributo" },
        { field: "unidade_padrao.sigla", header: "Und. Padrão" },
        { field: "valor_conteudo", header: "Valor", action: (e) => verificaValor(e.valor_conteudo) },
        {
            field: "ativo",
            header: "Status",
            action: (e) => statusBodyTemplate(e.ativo),
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionsBody(e),
            style: { minWidth: "10%" },
        },
    ];

    const listarOpcoes = useCallback(async () => {
        setOpcoesGrade(formik.values.grade.opcoes_valor.split("|"));
    }, [formik.values.grade?.opcoes_valor]);

    useEffect(() => {
        if (formik.values.grade && typeof formik.values.grade === "object") listarOpcoes();
    }, [formik.values.grade, listarOpcoes]);

    return (
        <>
            <Dialog
                header={`Grade de Atributos do Produto (${detalheSelecionado?.descricao_complementar})`}
                visible={visible}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "70vw", display: "block" }}
                onHide={() => setVisible(false)}
            >
                <div className="p-col-12">
                    <MakoListagem
                        ref={listagemRef}
                        colunas={colunaGradeSku}
                        responsiva
                        urlPesquisa={`/produtos/grades-atributos-sku?sku=${detalheSelecionado?.id}`}
                        painelEsquerdo={cabecalhoTabela}
                        configTabela={{
                            paginator: true,
                            lazy: true,
                        }}
                    />
                    <Dialog
                        header={"Grade de Atributos do Produto"}
                        visible={exibirIncluir}
                        breakpoints={{ "960px": "75vw" }}
                        style={{ width: "60vw", display: "block" }}
                        onHide={() => esconderDialogGrade()}
                    >
                        <form onSubmit={formik.handleSubmit}>
                            <div className="p-fluid p-formgrid p-grid ">
                                <div className="p-field p-col-12 p-md-12">
                                    <label htmlFor="grade">Grade *</label>
                                    <MakoAutoComplete
                                        id="grade"
                                        name="grade"
                                        minCaracteresBusca={2}
                                        urlSearch={`/produtos/grades-atributos?limit=60&search=`}
                                        placeholder="Busque pelo código ou descrição... (min 2 caracteres)"
                                        value={formik.values.grade}
                                        onChange={formik.handleChange}
                                        itemTemplate={autoCompleteTemplate}
                                        className={classNames({ "p-invalid": formik.errors.grade })}
                                        field="label"
                                    />
                                    {formik.errors.grade && <small className="p-error">{formik.errors.grade}</small>}
                                </div>
                            </div>
                            {formik.values.grade?.valor_numerico ? (
                                <div className="p-fluid p-formgrid p-grid">
                                    <div className="p-field p-col-12 p-md-6">
                                        <label htmlFor="valor_grade_variavel">Valor da grade variável?</label>
                                        <Dropdown
                                            id="valor_grade_variavel"
                                            name="valor_grade_variavel"
                                            options={SIM_NAO_BOOLEAN}
                                            optionLabel="label"
                                            optionValue="id"
                                            value={formik.values.valor_grade_variavel}
                                            onChange={formik.handleChange}
                                            autoFocus
                                        />
                                        {formik.errors.valor_grade_variavel && (
                                            <small className="p-error">{formik.errors.valor_grade_variavel}</small>
                                        )}
                                    </div>
                                    <div className="p-field p-col-12 p-md-6">
                                        <label htmlFor="calcula_medida">Calcula medida?</label>
                                        <Dropdown
                                            id="calcula_medida"
                                            name="calcula_medida"
                                            onChange={formik.handleChange}
                                            value={formik.values.calcula_medida}
                                            optionLabel="label"
                                            optionValue="id"
                                            options={SIM_NAO_BOOLEAN}
                                        />
                                        {formik.errors.calcula_medida && (
                                            <small className="p-error">{formik.errors.calcula_medida}</small>
                                        )}
                                    </div>
                                </div>
                            ) : null}
                            <div className="p-fluid p-formgrid p-grid">
                                {formik.values.grade?.valor_numerico && formik.values.valor_grade_variavel ? (
                                    <div className="p-field p-col-12 p-md-4">
                                        <label htmlFor="valor_minimo">Valor mínimo *</label>
                                        <InputNumber
                                            id="valor_minimo"
                                            name="valor_minimo"
                                            mode="decimal"
                                            minFractionDigits={0}
                                            maxFractionDigits={4}
                                            value={formik.values.valor_minimo}
                                            onValueChange={formik.handleChange}
                                            className={classNames({ "p-invalid": formik.errors.valor_minimo })}
                                        />
                                        {formik.errors.valor_minimo && (
                                            <small className="p-error">{formik.errors.valor_minimo}</small>
                                        )}
                                    </div>
                                ) : null}
                                {formik.values.grade?.valor_numerico && formik.values.valor_grade_variavel ? (
                                    <div className="p-field p-col-12 p-md-4">
                                        <label htmlFor="valor_maximo">Valor máximo *</label>
                                        <InputNumber
                                            id="valor_maximo"
                                            name="valor_maximo"
                                            mode="decimal"
                                            minFractionDigits={0}
                                            maxFractionDigits={4}
                                            value={formik.values.valor_maximo}
                                            onValueChange={formik.handleChange}
                                            className={classNames({ "p-invalid": formik.errors.valor_maximo })}
                                        />
                                        {formik.errors.valor_maximo && (
                                            <small className="p-error">{formik.errors.valor_maximo}</small>
                                        )}
                                    </div>
                                ) : formik.values.grade?.valor_numerico && !formik.values.valor_grade_variavel ? (
                                    <div className="p-field p-col-12 p-md-4">
                                        <label htmlFor="valor_conteudo">Valor *</label>
                                        <InputNumber
                                            id="valor_conteudo"
                                            name="valor_conteudo"
                                            mode="decimal"
                                            minFractionDigits={0}
                                            maxFractionDigits={4}
                                            value={formik.values.valor_conteudo}
                                            onValueChange={formik.handleChange}
                                            className={classNames({ "p-invalid": formik.errors.valor_conteudo })}
                                        />
                                        {formik.errors.valor_conteudo && (
                                            <small className="p-error">{formik.errors.valor_conteudo}</small>
                                        )}
                                    </div>
                                ) : null}
                                {formik.values.grade?.valor_numerico && formik.values.calcula_medida ? (
                                    <div className="p-field p-col-12 p-md-4">
                                        <label htmlFor="dimensao">Dimensão *</label>
                                        <Dropdown
                                            id="dimensao"
                                            placeholder="Selecione uma dimensao"
                                            name="dimensao"
                                            options={TIPO_DIMENSAO_GRADE}
                                            optionValue="id"
                                            optionLabel="label"
                                            onChange={formik.handleChange}
                                            value={formik.values.dimensao}
                                        />
                                        {formik.errors.dimensao && (
                                            <small className="p-error">{formik.errors.dimensao}</small>
                                        )}
                                    </div>
                                ) : null}
                                {formik.values.grade?.valor_numerico ? (
                                    <div className="p-field p-col-12 p-md-4">
                                        <label htmlFor="unidade_padrao">Unidade padrão *</label>
                                        <Dropdown
                                            id="unidade_padrao"
                                            placeholder="Selecione uma medida"
                                            name="unidade_padrao"
                                            url="/produtos/unidades-medida/"
                                            onChange={formik.handleChange}
                                            value={formik.values.unidade_padrao}
                                            optionValue="id"
                                            optionLabel="nome"
                                            filter
                                            filterBy="nome"
                                        />
                                        {formik.errors.unidade_padrao && (
                                            <small className="p-error">{formik.errors.unidade_padrao}</small>
                                        )}
                                    </div>
                                ) : null}
                            </div>
                            {formik.values.grade &&
                            typeof formik.values.grade === "object" &&
                            !formik.values.grade.valor_numerico ? (
                                <div className="p-fluid p-formgrid p-grid">
                                    {formik.values.grade.opcoes ? (
                                        <div className="p-field p-col-12 p-md-12">
                                            <label htmlFor="valor_conteudo">Valor conteúdo *</label>
                                            <InputText
                                                id="valor_conteudo"
                                                name="valor_conteudo"
                                                onChange={formik.handleChange}
                                                value={formik.values.valor_conteudo}
                                                className={classNames({ "p-invalid": formik.errors.valor_conteudo })}
                                            />
                                            {formik.errors.valor_conteudo && (
                                                <small className="p-error">{formik.errors.valor_conteudo}</small>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="p-field p-col-12 p-md-12">
                                            <label htmlFor="valor_conteudo">Valor conteúdo *</label>
                                            <Dropdown
                                                id="valor_conteudo"
                                                name="valor_conteudo"
                                                placeholder="Selecione um valor"
                                                onChange={formik.handleChange}
                                                value={formik.values.valor_conteudo}
                                                options={opcoesGrade}
                                                autoComplete="off"
                                            />
                                            {formik.errors.valor_conteudo && (
                                                <small className="p-error">{formik.errors.valor_conteudo}</small>
                                            )}
                                        </div>
                                    )}
                                </div>
                            ) : null}
                            <p>
                                <b>* Campos obrigatórios.</b>
                            </p>
                            <div className="p-fluid p-formgrid p-grid p-mt-2">
                                <div className="p-field-checkbox p-col-12 p-md-12">
                                    <br></br>
                                    <Checkbox
                                        inputId="todos"
                                        id="todos"
                                        name="todos"
                                        onChange={formik.handleChange}
                                        checked={formik.values.todos}
                                        value={formik.values.todos}
                                    />
                                    <label htmlFor="todos">
                                        <b>Adicionar em todos os produtos</b>
                                    </label>
                                </div>
                            </div>
                            {formik.values.todos ? (
                                <p style={{ color: "#FF0000" }}>
                                    <b>AVISO:</b> Com essa opção selecionada, a grade será inserida a todos os produtos
                                    deste cadastro.
                                </p>
                            ) : null}
                            <div className="p-grid p-justify-end p-mt-4 p-mr-1">
                                <Button
                                    className="p-button-danger p-button-text"
                                    type="reset"
                                    icon="pi pi-times"
                                    label="Fechar"
                                    onClick={() => {
                                        setExibirIncluir(false);
                                        resetForm();
                                    }}
                                />
                                <Button
                                    type="submit"
                                    icon="pi pi-check-circle"
                                    label={formik.values.id ? "Salvar" : "Inserir"}
                                />
                            </div>
                        </form>
                    </Dialog>
                    <Dialog
                        header={"Clonar Grade de Atributos"}
                        visible={clonarGrade}
                        breakpoints={{ "960px": "75vw" }}
                        style={{ width: "40vw", display: "block" }}
                        onHide={() => esconderDialogClonar()}
                    >
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="produto_destino">Produto de destino:</label>
                                <Dropdown
                                    id="produto_destino"
                                    name="produto_destino"
                                    placeholder="Selecione um produto..."
                                    url={`/produtos/sku?item__id=${dadosBasicos.id}`}
                                    optionLabel="descricao_complementar"
                                    optionValue="id"
                                    value={produtoDestino}
                                    onChange={(e) => setProdutoDestino(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="p-grid p-justify-end p-mt-4" style={{ paddingRight: "2%" }}>
                            <Button
                                className="p-button-danger p-mr-2"
                                type="reset"
                                icon="pi pi-times"
                                label="Fechar"
                                onClick={() => esconderDialogClonar(false)}
                            />
                            <Button type="button" icon="pi pi-save" label="Clonar" onClick={() => handleClonar()} />
                        </div>
                    </Dialog>
                    <MakoErrosPersonalizados
                        ref={modalErrosRef}
                        titulo="Inconsistências encontradas"
                        colunas={[{ field: "erro", header: "Descrição da pendência" }]}
                    />
                </div>
            </Dialog>
        </>
    );
};

export const ModalGradeAtributos = forwardRef(GradeAtributosModalForm);
