import React, { useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { MakoSelecionarEmails } from "@/components/MakoSelecionarEmails";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import useRelatorio from "@/hooks/useRelatorio";

import { RELATORIO_FINANCEIRO_EXTRATOCONTA } from "@/assets/constants/relatorios";

import { dataToStr } from "@/assets/util/datas";
import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";

export const RelatorioExtratoConta = () => {
    const [visible, setVisible] = useState(false);
    const [emails, setEmails] = useState(null);

    const { solicitarRelatorio } = useRelatorio();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            conta: null,
            data: null,
        },
        onSubmit: handleSubmit,
    });

    const filtrosTemplate = [
        {
            key: "conta",
            label: "Conta",
            path: "descricao",
        },
        {
            key: "data",
            label: "Data",
            type: TIPOS_FILTROS_TEMPLATE.DATE,
        },
    ];

    async function handleSubmit(values = null) {
        try {
            const formSchema = Yup.object().shape({
                conta: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'conta' é obrigatório."),
                    })
                    .typeError("Selecione uma 'conta' válida"),
                data: Yup.date()
                    .nullable()
                    .notRequired("O campo 'data' é obrigatório")
                    .typeError("Selecione uma 'data' válida"),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, dadosValidados);
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.data !== null) dadosValidados.data = dataToStr(dadosValidados.data, "yyyy-MM-dd");
            if (dadosValidados.conta) dadosValidados.conta = dadosValidados.conta.id;

            let filtros = {};
            if (dadosValidados) {
                Object.keys(dadosValidados).forEach((key) => {
                    if (dadosValidados[key]) filtros[key] = dadosValidados[key];
                });
            }

            solicitarRelatorio({
                chave: RELATORIO_FINANCEIRO_EXTRATOCONTA,
                emails,
                filtros,
                filtros_template: filtrosTemplateAplicados,
            });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const handleConfirm = (emails) => {
        fecharModal();
        if (emails && emails.length > 0) {
            handlePreSubmit(emails);
        }
    };

    const handlePreSubmit = (emails = []) => {
        setEmails(emails);
        formik.handleSubmit();
    };

    const fecharModal = () => {
        setVisible(() => false);
    };

    const limparFiltro = () => {
        formik.resetForm();
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <h5>Filtro para emissão de relatório de extrato de conta</h5>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="conta">Conta Financeira *</label>
                            <Dropdown
                                id="conta"
                                name="conta"
                                url={"/financeiro/contas-financeiras?limit=1000"}
                                placeholder="Selecione uma conta financeira"
                                filter
                                filterBy="descricao"
                                optionLabel="descricao"
                                value={formik.values.conta}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.conta })}
                            />
                            {formik.errors.conta && <small className="p-error">{formik.errors.conta}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="data">Data:</label>
                            <MakoCalendar
                                id="data"
                                name="data"
                                valueCalendar={formik.values.data_start}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.data_start })}
                            />
                            {formik.errors.data && <small className="p-error">{formik.errors.date_start}</small>}
                        </div>
                    </div>
                    <div className="p-grid p-col-12 p-md-6 p-mt-2">
                        <Button
                            label="Gerar PDF"
                            icon="pi pi-file-pdf"
                            type="button"
                            onClick={() => handlePreSubmit()}
                            className="p-button-info p-mr-2"
                        />
                        <Button
                            type="button"
                            onClick={() => setVisible(true)}
                            label="Enviar por email"
                            icon="pi pi-envelope"
                            className="p-button-info p-mr-2"
                        />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            onClick={() => limparFiltro()}
                            className="p-button-warning p-mr-2"
                        />
                    </div>
                </div>
            </div>
            <Dialog
                visible={visible}
                blockScroll
                onHide={fecharModal}
                style={{ width: "60vw" }}
                header="Informar emails"
            >
                <MakoSelecionarEmails onCancel={fecharModal} onConfirm={handleConfirm} />
            </Dialog>
        </div>
    );
};
