import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoUploadPreviewImage } from "@/components/MakoUploadPreviewImage";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { dataToStr, parseData } from "@/assets/util/datas";
import { validarCNPJ } from "@/assets/util/validacoes";
import usePessoa from "@/hooks/usePessoa";
import useLoading from "@/hooks/useLoading";
import useFormatCnpjCpf from "@/hooks/useFomatCNPJCPF";
import { url } from "@/services/axios";
import { axiosGet } from "@/services/http";
import { SIM_NAO_BOOLEAN, TIPO_SIM_NAO_CHAR_CHOICE } from "@/assets/constants/constants";
import { PESSOAS_PERFIL_CADASTRO_PESSOASJURIDICA_OBRIGATORIEDADE } from "@/assets/constants/parametros";
import { Label } from "@/components/Label";
import useParam from "@/hooks/useParam";
import useToast from "@/hooks/useToast";

const IdentificacaoPJForm = () => {
    const [loading, setLoading] = useState(false);
    const [estados, setEstados] = useState([]);
    const [cnpjDuplicado, setCnpjDuplicado] = useState(false);
    const [regimesTributarios, setRegimesTributarios] = useState([]);
    const [indicadoresIE, setIndicadoresIE] = useState([]);
    const [confirmDialog, setConfirmDialog] = useState(false);
    const { handlePessoaJuridica, handleFotoPerfil, pessoa, setSubmit } = usePessoa();
    const { showLoading, hideLoading } = useLoading();
    const [, limparDocumento] = useFormatCnpjCpf();
    const { showWarning, showError, showSuccess } = useToast();
    const { getParam } = useParam();

    const listarEstados = useCallback(async () => {
        showLoading();
        const json = await axiosGet("/pessoas/estados?query={id,nome,uf,codigo_uf}&pais__sigla=BR&limit=100");
        hideLoading();

        if (json.status === 200) {
            setEstados(json.data.results);
        }
    }, [showLoading, hideLoading]);

    const listarRegimesTributarios = useCallback(async () => {
        showLoading();
        const json = await axiosGet("/pessoas/regime-tributario/");
        hideLoading();

        if (json.status === 200) {
            setRegimesTributarios(json.data.results);
        }
    }, [showLoading, hideLoading]);

    const listarIndicadoresIE = useCallback(async () => {
        showLoading();
        const json = await axiosGet("/pessoas/indicadores-inscricao-estadual/");
        hideLoading();

        if (json.status === 200) {
            setIndicadoresIE(json.data.results);
        }
    }, [showLoading, hideLoading]);

    useEffect(() => {
        listarEstados();
        listarRegimesTributarios();
        listarIndicadoresIE();
    }, [listarEstados, listarRegimesTributarios, listarIndicadoresIE]);

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            cnpj: "",
            indicador_inscricao_estadual: "",
            estado: "",
            inscricao_estadual: "",
            inscricao_municipal: "",
            razao_social: "",
            nome_fantasia: "",
            regime_tributario: "",
            data_constituicao: null,
            data_alteracao_contratual: null,
            optante_simples: null,
            substituto_issqn: null,
            nome_curto: "",
            obs: "",
        },
        onSubmit: handleSubmit,
    });

    const camposBasicosObrigatorios = useMemo(() => {
        const param = getParam(PESSOAS_PERFIL_CADASTRO_PESSOASJURIDICA_OBRIGATORIEDADE);
        if (!param) return false;
        return param?.valor === "1";
    }, [getParam]);

    useEffect(() => {
        if (pessoa.perfil_pj) setValues(pessoa.perfil_pj);
    }, [pessoa.perfil_pj, setValues]);

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                cnpj: Yup.string()
                    .required("O campo 'cnpj' é obrigatório.")
                    .test("CNPJ validation", "O 'cnpj' é inválido.", (value) => validarCNPJ(value) === true),
                indicador_inscricao_estadual: Yup.string().required("O campo 'indicativo ie' é obrigatório."),
                inscricao_estadual: Yup.string().max(14, "Informe no máximo 14 caracteres."),
                inscricao_municipal: Yup.string().max(11, "Informe no máximo 11 caracteres.").nullable(),
                nome_curto: Yup.string()
                    .when({
                        is: () => camposBasicosObrigatorios,
                        then: Yup.string()
                            .required("O campo 'nome curto' é obrigatório.")
                            .typeError("Informe um 'nome curto' válido."),
                        otherwise: Yup.string().nullable().typeError("Informe um 'nome curto' válido."),
                    })
                    .max(25, "Informe no máximo 25 caracteres."),
                optante_simples: Yup.string().required("O campo 'optante pelo simples nacional' é obrigatório."),
                razao_social: Yup.string().required("O campo 'razão social' é obrigatório."),
                nome_fantasia: Yup.string().required("O campo 'nome fantasia' é obrigatório."),
                data_constituicao: Yup.date().when({
                    is: () => camposBasicosObrigatorios,
                    then: Yup.date()
                        .max(new Date(), "A data de constituição não pode ser posterior ao dia de hoje.")
                        .typeError("Informe uma 'data' válida."),
                    otherwise: Yup.date().nullable().typeError("Informe uma 'data' válida"),
                }),
                data_alteracao_contratual: Yup.date()
                    .nullable()
                    .when("data_constituicao", {
                        is: (val) => val !== null,
                        then: Yup.date()
                            .min(
                                Yup.ref("data_constituicao") || new Date(),
                                "A data da última alteração contratual não pode ser menor que a data de constituição."
                            )
                            .max(
                                new Date(),
                                "A data da última alteração contratual não pode ser posterior ao dia de hoje."
                            )
                            .typeError("Informe uma data válida."),
                        otherwise: Yup.date().nullable(),
                    }),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const perfil_pj = {
                ...values,
                cnpj: limparDocumento(values.cnpj),
                inscricao_estadual:
                    values.indicador_inscricao_estadual === 1
                        ? limparDocumento(values.inscricao_estadual)
                        : values.inscricao_estadual,
                data_constituicao: dataToStr(values.data_constituicao, "yyyy-MM-dd"),
                data_alteracao_contratual: dataToStr(values.data_alteracao_contratual, "yyyy-MM-dd"),
            };

            handlePessoaJuridica(perfil_pj);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    function resetForm() {
        formik.resetForm();
        handlePessoaJuridica(null);
        setSubmit(false);
    }

    const verificarCnpjExiste = async (value) => {
        const cnpj = limparDocumento(value);

        setLoading(true);
        const json = await axiosGet(`/pessoas/perfis/?query={nome,identificacao,ativo}&identificacao=${cnpj}`);
        setLoading(false);

        if (json.status === 200) {
            if (json.data.results.length > 0) {
                if (!json.data.results[0].ativo) {
                    showWarning({
                        life: 5000,
                        summary: "Aviso!",
                        detail: "Esse CNPJ já se encontra cadastrado na base de dados, porém está desativado.",
                    });
                } else {
                    showWarning({
                        life: 5000,
                        summary: "Aviso!",
                        detail: "Esse CNPJ já se encontra cadastrado na base de dados.",
                    });
                }

                setCnpjDuplicado(true);
                setSubmit(false);
            } else {
                setCnpjDuplicado(false);
                setConfirmDialog(true);
            }
        }
    };

    const validarIE = (indIeId) => {
        setFieldValue("indicador_inscricao_estadual", indIeId);

        const index = indicadoresIE.findIndex((e) => e.id === indIeId);

        if (indicadoresIE[index].codigo === "1") {
            setFieldValue("estado", "");
            setFieldValue("inscricao_estadual", "");
        } else if (indicadoresIE[index].codigo === "2") {
            setFieldValue("estado", "");
            setFieldValue("inscricao_estadual", "");
        } else if (indicadoresIE[index].codigo === "3") {
            setFieldValue("inscricao_estadual", "ISENTO");
        }
    };

    const onUploadFotoPerfil = (e) => {
        const { xhr } = e;
        const { data } = JSON.parse(xhr.response);

        handleFotoPerfil(data.foto);

        showSuccess({
            summary: "Sucesso",
            detail: "Foto de perfil enviada com sucesso!",
            life: 1500,
        });
    };

    const onErrorFotoPerfil = (e) => {
        const { xhr } = e;

        if (xhr.status === 400) {
            const { msg } = JSON.parse(xhr.response);

            showWarning({
                summary: "Falha",
                detail: msg,
                life: 3000,
            });
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível enviar a foto de perfil.",
                life: 3000,
            });
        }
    };

    const autoPreencherCNPJ = useCallback(async () => {
        const cnpj = limparDocumento(formik.values.cnpj);
        showLoading();
        const response = await axiosGet(`/pessoas/consultar-cnpj/${cnpj}/`);
        hideLoading();

        if (response.status === 200) {
            const { result } = response.data;
            setFieldValue("nome_fantasia", result.nome_fantasia);
            setFieldValue("razao_social", result.razao_social);
            setFieldValue("data_constituicao", parseData(result.data_constituicao));
            setFieldValue(
                "data_alteracao_contratual",
                result.data_alteracao_contratual ? parseData(result.data_alteracao_contratual) : null
            );
            setConfirmDialog(false);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível consultar o CNPJ.",
                life: 3000,
            });
        }
    }, [showLoading, hideLoading, formik.values.cnpj, setFieldValue, limparDocumento, showError]);

    const selecionaRegime = (regime) => {
        setFieldValue("regime_tributario", regime);
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-grid">
                <div className="p-col-12 p-md-2">
                    <div className="p-fluid p-formgrid p-grid">
                        <MakoUploadPreviewImage
                            ocultarImage={!!!pessoa?.foto}
                            imageConfig={{
                                src: pessoa.foto,
                                alt: "Foto de perfil",
                                width: "180",
                            }}
                            uploadConfig={{
                                url: `${url()}/pessoas/perfis-upload-foto/${pessoa?.id}/`,
                                name: "foto",
                                disabled: !!!pessoa?.id,
                                chooseLabel: "Selecionar logo",
                                maxFileSize: 1000000,
                                invalidFileSizeMessageSummary: "{0}: Tamanho do arquivo inválido, ",
                                invalidFileSizeMessageDetail: "tamanho máximo permitido: {0}.",
                                onUpload: onUploadFotoPerfil,
                                onError: onErrorFotoPerfil,
                            }}
                        />
                        {!!!pessoa?.id && (
                            <small className="p-error">
                                ** Poderá selecionar uma logo para o perfil após finalizar o cadastro. **
                            </small>
                        )}
                    </div>
                </div>
                <div className="p-col-12 p-md-10">
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="cnpj">CNPJ *</label>
                            <span className="p-input-icon-right">
                                {loading && <i className="pi pi-spin pi-spinner" />}
                                <InputMask
                                    id="cnpj"
                                    name="cnpj"
                                    mask="99.999.999/9999-99"
                                    autoClear={false}
                                    onComplete={(e) => verificarCnpjExiste(e.value)}
                                    value={formik.values.cnpj}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.cnpj })}
                                />
                                {formik.errors.cnpj && <small className="p-error">{formik.errors.cnpj}</small>}
                            </span>
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="indicador-ie">Indicativo IE *</label>
                            <Dropdown
                                id="indicador-ie"
                                name="indicador_inscricao_estadual"
                                options={indicadoresIE}
                                optionLabel="nome"
                                optionValue="id"
                                placeholder="Selecione..."
                                value={formik.values.indicador_inscricao_estadual}
                                onChange={(e) => validarIE(e.value)}
                                className={classNames({
                                    "p-invalid": formik.errors.indicador_inscricao_estadual,
                                })}
                            />
                            {formik.errors.indicador_inscricao_estadual && (
                                <small className="p-error">{formik.errors.indicador_inscricao_estadual}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="estado-ie">Estado IE</label>
                            <Dropdown
                                id="estado-ie"
                                name="estado"
                                options={estados}
                                optionLabel="nome"
                                optionValue="id"
                                placeholder="Selecione..."
                                filter
                                showClear
                                filterBy="nome"
                                value={formik.values.estado}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.estado,
                                })}
                            />
                            {formik.errors.estado && <small className="p-error">{formik.errors.estado}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="inscricao-estadual">Inscrição Estadual</label>
                            <InputText
                                id="inscricao-estadual"
                                name="inscricao_estadual"
                                value={formik.values.inscricao_estadual}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.inscricao_estadual,
                                })}
                            />
                            {formik.errors.inscricao_estadual && (
                                <small className="p-error">{formik.errors.inscricao_estadual}</small>
                            )}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="razao-social">Razão Social *</label>
                            <InputText
                                id="razao-social"
                                name="razao_social"
                                value={formik.values.razao_social}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.razao_social })}
                            />
                            {formik.errors.razao_social && (
                                <small className="p-error">{formik.errors.razao_social}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="nome-fantasia">Nome de fantasia *</label>
                            <InputText
                                id="nome-fantasia"
                                name="nome_fantasia"
                                value={formik.values.nome_fantasia}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.nome_fantasia,
                                })}
                            />
                            {formik.errors.nome_fantasia && (
                                <small className="p-error">{formik.errors.nome_fantasia}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <Label htmlFor="nome_curto" label="Nome curto" obrigatorio={camposBasicosObrigatorios} />
                            <InputText
                                id="nome_curto"
                                name="nome_curto"
                                value={formik.values.nome_curto}
                                onChange={formik.handleChange}
                            />
                            {formik.errors.nome_curto && <small className="p-error">{formik.errors.nome_curto}</small>}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="inscricao-municipal">Inscrição Municipal</label>
                            <InputText
                                id="inscricao-municipal"
                                name="inscricao_municipal"
                                value={formik.values.inscricao_municipal}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": formik.errors.inscricao_municipal,
                                })}
                            />
                            {formik.errors.inscricao_municipal && (
                                <small className="p-error">{formik.errors.inscricao_municipal}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="regime-tributario">Regime tributário</label>
                            <Dropdown
                                id="regime-tributario"
                                name="regime_tributario"
                                options={regimesTributarios}
                                placeholder="Selecione..."
                                optionValue="id"
                                optionLabel="nome"
                                value={formik.values.regime_tributario}
                                onChange={(e) => selecionaRegime(e.target.value)}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="optante_simples">Optante pelo Simples Nacional *</label>
                            <Dropdown
                                id="optante_simples"
                                name="optante_simples"
                                options={TIPO_SIM_NAO_CHAR_CHOICE}
                                placeholder="Selecione..."
                                optionValue="id"
                                optionLabel="label"
                                value={formik.values.optante_simples}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.optante_simples })}
                            />
                            {formik.errors.optante_simples && (
                                <small className="p-error">{formik.errors.optante_simples}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="substituto_issqn">Substituto tributário ISSQN *</label>
                            <Dropdown
                                id="substituto_issqn"
                                name="substituto_issqn"
                                options={SIM_NAO_BOOLEAN}
                                placeholder="Selecione..."
                                optionValue="id"
                                optionLabel="label"
                                value={formik.values.substituto_issqn}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <Label
                                htmlFor="data-constituicao"
                                label="Data de constituição"
                                obrigatorio={camposBasicosObrigatorios}
                            />
                            <MakoCalendar
                                id="data-constituicao"
                                name="data_constituicao"
                                maxDate={new Date()}
                                valueCalendar={formik.values.data_constituicao}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.data_constituicao })}
                            />
                            {formik.errors.data_constituicao && (
                                <small className="p-error">{formik.errors.data_constituicao}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="ult-alteracao_contratual">Última alteração contratual</label>
                            <MakoCalendar
                                id="ult-alteracao_contratual"
                                name="data_alteracao_contratual"
                                maxDate={new Date()}
                                valueCalendar={formik.values.data_alteracao_contratual}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.data_alteracao_contratual })}
                            />
                            {formik.errors.data_alteracao_contratual && (
                                <small className="p-error">{formik.errors.data_alteracao_contratual}</small>
                            )}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="obs">Observação</label>
                            <InputText id="obs" name="obs" value={formik.values.obs} onChange={formik.handleChange} />
                        </div>
                    </div>
                </div>
            </div>
            <CamposObrigatorios />
            <p className="p-error">* Lembre-se de gravar os dados antes de prosseguir ou finalizar</p>
            <div className="p-grid">
                <div className="p-col-12 p-md-6">
                    <Button
                        type="submit"
                        icon={`pi ${!cnpjDuplicado ? "pi-check" : "pi-times"}`}
                        label={!cnpjDuplicado ? "Gravar" : "CNPJ já cadastrado"}
                        className={`${cnpjDuplicado ? "p-button-danger" : ""} p-mr-2 p-mb-2`}
                        disabled={cnpjDuplicado}
                    />
                    <Button
                        type="reset"
                        icon="pi pi-trash"
                        label="Limpar"
                        className="p-button-warning p-mr-2 p-mb-2"
                        onClick={() => resetForm()}
                    />
                </div>
            </div>
            <ConfirmDialog
                visible={confirmDialog}
                onHide={() => setConfirmDialog(false)}
                header="Confirmação"
                message="Deseja consultar os dados para preenchimento do formulário?"
                icon="pi pi-info-circle p-mr-3"
                accept={autoPreencherCNPJ}
                acceptLabel="Sim"
                acceptClassName="p-button-danger"
                reject={() => setConfirmDialog(false)}
                rejectLabel="Não"
            />
        </form>
    );
};

export default memo(IdentificacaoPJForm);
