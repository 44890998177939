import React, { useRef, useState, useCallback, useEffect } from "react";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { axiosGet, axiosPost } from "@/services/http";
import { useFormik } from "formik";
import { TIPO_MODELO_NOTA_FISCAL } from "@/assets/constants/constants";
import { Toast } from "primereact/toast";
import { PickList } from "primereact/picklist";
import { Dialog } from "primereact/dialog";
import { TabPanel, TabView } from "primereact/tabview";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import { EMPRESA_NFCE_SERIE, EMPRESA_NFE_SERIE } from "@/assets/constants/parametros";
import useParam from "@/hooks/useParam";
import useEmpresa from "@/hooks/useEmpresa";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import { BotaoDelete } from "@/components/BotaoDelete";
import { CodigoFiltroTemplate, DropdownFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";

export const InutilizacaoPage = () => {
    const [confirmInutilizacao, setConfirmInutilizacao] = useState(false);
    const { empresaSelecionadaId } = useEmpresa();
    const [registraInutilizacao, setRegistraInutilizacao] = useState(false);
    const [quebrasSelecionadas, setQuebrasSelecionadas] = useState([]);
    const [quebras, setQuebras] = useState([]);
    const [quebra, setQuebra] = useState([]);
    const [numerosNfe, setNumerosNfe] = useState([]);
    const [numerosNfce, setNumerosNfce] = useState([]);
    const { showLoading, hideLoading } = useLoading();
    const { getParam } = useParam();
    const toastRef = useRef(null);
    const listagemRef = useRef(null);

    const { resetForm, ...formik } = useFormik({
        initialValues: {
            id: "",
            serie: "",
            numeracao_inicial: "",
            numeracao_final: "",
            modelo: "",
            justificativa: "",
        },
    });

    async function handleGravaManual(values) {
        try {
            if (empresaSelecionadaId) {
                const formSchema = Yup.object().shape({
                    serie: Yup.string().required("O campo 'série' é obrigatório."),
                    numeracao_inicial: Yup.string().required("O campo 'numeração inicial' é obrigatório."),
                    numeracao_final: Yup.string().required("O campo 'numeração final' é obrigatório."),
                    modelo: Yup.string().required("O campo 'modelo' é obrigatório."),
                    justificativa: Yup.string().required("O campo 'justificativa' é obrigatório."),
                });

                await formSchema.validate(
                    { ...values, empresa: empresaSelecionadaId },
                    {
                        abortEarly: false,
                    }
                );

                showLoading();
                const resposta = await axiosPost("/fiscal/inutilizacao/", values);
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Inutilização gravada com sucesso!",
                        life: 3000,
                    });
                    listagemRef.current?.buscarDados();
                    resetForm();
                    setRegistraInutilizacao(false);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Empresa não selecionada",
                    detail: "Por favor, selecione uma empresa antes de realizar essa ação.",
                    life: 1500,
                });
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    async function handleGravaInutilizacao() {
        try {
            if (empresaSelecionadaId) {
                showLoading();
                const resposta = await axiosPost(
                    "/fiscal/inutilizacao/",
                    quebrasSelecionadas.map((quebra) => {
                        return {
                            serie: quebra.serie,
                            numeracao_inicial: quebra.numero,
                            numeracao_final: quebra.numero,
                            modelo: quebra.modelo,
                            justificativa: "Quebra de sequência.",
                            empresa: empresaSelecionadaId,
                        };
                    })
                );
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: `Inutilização gravada com sucesso!`,
                        life: 3000,
                    });
                    listagemRef.current?.buscarDados();
                    setRegistraInutilizacao(false);
                }
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Empresa não selecionada",
                    detail: "Por favor, selecione uma empresa antes de realizar essa ação.",
                    life: 1500,
                });
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    async function handleEnviaInutilizacao() {
        try {
            showLoading();
            const apiServico = await axiosGet("/configuracoes/api-servicos/");

            const corpoIntegracao = [
                {
                    ModeloDocumento: quebra.modelo === "55" ? "NFE" : "NFCE",
                    Versao: "1.00",
                    CnpjEmissor: apiServico.data.results[0].cnpj_integrado,
                    tpAmb: "2",
                    UfEmissor: 39,
                    NumeroInicial: quebra.numero,
                    NumeroFinal: quebra.numero,
                    Serie: quebra.serie,
                    Justificativa: quebra.justificativa,
                },
            ];

            const inutilizacao = await axiosPost(
                `http://localhost:4000/documents/${apiServico.data.results[0].chave_acesso}/${
                    apiServico.data.results[0].cnpj_integrado
                }/${quebra.modelo === "55" ? "nfe" : "nfce"}/inutilizar`,
                corpoIntegracao
            );
            hideLoading();

            if (inutilizacao?.data?.length > 0)
                if (inutilizacao?.data[0].Codigo === 100)
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: `Inutilização transmitida com sucesso! SEFAZ: ${inutilizacao.data[0].Descricao}`,
                        sticky: true,
                    });
                else
                    toastRef.current.show({
                        severity: "warn",
                        summary: "Falha no envio",
                        detail: `${inutilizacao.data[0].Descricao}`,
                        sticky: true,
                    });
            else
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    const carregarQuebras = useCallback(async () => {
        const serie_nf = getParam(EMPRESA_NFE_SERIE);
        //const serie_nfce = getParam(EMPRESA_NFCE_SERIE);
        const resposta = await axiosGet(
            `/fiscal/verificar-quebras-nf/${empresaSelecionadaId}/${serie_nf?.valor || "1"}/`
        );
        if (resposta.status === 200) {
            setNumerosNfce(resposta.data.results.nfce);
            setNumerosNfe(resposta.data.results.nfe);
        }
    }, [empresaSelecionadaId, getParam]);

    useEffect(() => {
        carregarQuebras();
    }, [carregarQuebras]);

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_NOTASAIDA_INUTILIZAR]}
                    componente={Button}
                    icon="pi pi-sync"
                    className="p-button-rounded p-button-info p-mr-1"
                    tooltip="Enviar inutilização"
                    tooltipOptions={{ position: "left" }}
                    onClick={() => {
                        setQuebra(rowData);
                        setConfirmInutilizacao(true);
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_NOTASAIDA_INUTILIZAR]}
                    componente={BotaoDelete}
                    url={`/fiscal/inutilizacao/`}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={<span>Deseja mesmo excluir a inutlização registrada?</span>}
                    msgToastErroExclusao="A nota fiscal não pode ser excluída."
                    className="p-mt-1"
                    onDelete={() => {
                        toastRef.current.show({
                            severity: "success",
                            summary: "Sucesso",
                            detail: `Registro de inutilização removido com sucesso!`,
                            life: 3000,
                        });
                        listagemRef.current?.buscarDados();
                    }}
                />
            </div>
        );
    };

    const ModeloFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: TIPO_MODELO_NOTA_FISCAL,
                }}
                options={options}
            />
        );
    };

    const colunas = [
        {
            field: "serie",
            header: "Série",
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "numeracao_inicial",
            header: "Númeração inicial",
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "numeracao_final",
            header: "Númeração final",
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "modelo",
            header: "Modelo",
            filter: true,
            filterElement: ModeloFiltroTemplate
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "12%" },
        },
    ];

    const cabecalhoTabela = (
        <>
            <Button
                label="Registrar inutilização"
                icon="pi pi-plus"
                className="p-button-success p-button-outlined p-mr-2"
                onClick={(e) => setRegistraInutilizacao(true)}
            />
        </>
    );

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Inutilizações registradas"
                        colunas={colunas}
                        painelEsquerdo={cabecalhoTabela}
                        urlPesquisa="/fiscal/inutilizacao/"
                        mostrarGlobalSearch={false}
                        configTabela={{
                            paginator: true,
                            lazy: true,
                        }}
                        filter={{
                            serie: { value: null, matchMode: "equals" },
                            numeracao_inicial: { value: null, matchMode: "equals" },
                            numeracao_final: { value: null, matchMode: "equals" },
                            modelo: { value: null, matchMode: "equals" }
                        }}
                    />
                </div>
            </div>
            <Dialog
                // header="Confirmação de envio"
                visible={registraInutilizacao}
                breakpoints={{ "960px": "80vw" }}
                style={{ width: "70vw" }}
                onHide={() => setRegistraInutilizacao(false)}
            >
                <TabView className="tabview-custom">
                    <TabPanel header="Inutilização manual" leftIcon="pi pi-plus-circle">
                        <div className="p-col-12 p-md-12 p-lg-12">
                            <div className="card" style={{ height: "100%" }}>
                                <h4 style={{ textAlign: "center" }}>Cadastrar inutilização</h4>
                                <div className="p-grid p-justify-center p-m-2">
                                    <div className="p-fluid p-formgrid p-grid">
                                        <div className="p-field p-col-12 p-md-3">
                                            <label htmlFor="serie">Série *</label>
                                            <InputText
                                                id="serie"
                                                name="serie"
                                                value={formik.values.serie}
                                                onChange={formik.handleChange}
                                                className={classNames({ "p-invalid": formik.errors.serie })}
                                                autoComplete="off"
                                                autoFocus
                                            />
                                            {formik.errors.serie && (
                                                <small className="p-error">{formik.errors.serie}</small>
                                            )}
                                        </div>
                                        <div className="p-field p-col-12 p-md-3">
                                            <label htmlFor="numeracao_inicial">Numeração inicial *</label>
                                            <InputText
                                                id="numeracao_inicial"
                                                name="numeracao_inicial"
                                                value={formik.values.numeracao_inicial}
                                                onChange={formik.handleChange}
                                                className={classNames({ "p-invalid": formik.errors.numeracao_inicial })}
                                                autoComplete="off"
                                                autoFocus
                                            />
                                            {formik.errors.numeracao_inicial && (
                                                <small className="p-error">{formik.errors.numeracao_inicial}</small>
                                            )}
                                        </div>
                                        <div className="p-field p-col-12 p-md-3">
                                            <label htmlFor="numeracao_final">Numeração final *</label>
                                            <InputText
                                                id="numeracao_final"
                                                name="numeracao_final"
                                                value={formik.values.numeracao_final}
                                                onChange={formik.handleChange}
                                                className={classNames({ "p-invalid": formik.errors.numeracao_final })}
                                                autoComplete="off"
                                                autoFocus
                                            />
                                            {formik.errors.numeracao_final && (
                                                <small className="p-error">{formik.errors.numeracao_final}</small>
                                            )}
                                        </div>
                                        <div className="p-field p-col-12 p-md-3">
                                            <label htmlFor="modelo">Modelo *</label>
                                            <Dropdown
                                                id="modelo"
                                                name="modelo"
                                                options={TIPO_MODELO_NOTA_FISCAL}
                                                optionValue="value"
                                                optionLabel="label"
                                                placeholder="Selecione um modelo"
                                                value={formik.values.modelo}
                                                onChange={formik.handleChange}
                                            />
                                            {formik.errors.modelo && (
                                                <small className="p-error">{formik.errors.modelo}</small>
                                            )}
                                        </div>

                                        <div className="p-field p-col-12 p-md-12">
                                            <label htmlFor="justificativa">Justificativa *</label>
                                            <InputTextarea
                                                id="justificativa"
                                                name="justificativa"
                                                value={formik.values.justificativa}
                                                onChange={formik.handleChange}
                                                className={classNames({ "p-invalid": formik.errors.justificativa })}
                                                rows={5}
                                                autoComplete="off"
                                                autoFocus
                                                autoResize
                                            />
                                            {formik.errors.justificativa && (
                                                <small className="p-error">{formik.errors.justificativa}</small>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <ul className="widget-person-list">
                                    <div className="p-grid p-justify-center">
                                        <Button label="Gravar" onClick={() => handleGravaManual(formik.values)} />
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel header="Quebras de sequência" leftIcon="pi pi-times-circle">
                        <div className="p-col-12">
                            <h6>Carregar quebras:</h6>
                            <Button className="p-mr-1" label="NFe" onClick={() => setQuebras(numerosNfe)} />
                            <Button label="NFCe" onClick={() => setQuebras(numerosNfce)} />
                            <div className="card">
                                <h4 style={{ textAlign: "center" }}>Quebras de sequência</h4>
                                <PickList
                                    source={quebras}
                                    target={quebrasSelecionadas}
                                    sourceHeader="Quebras localizadas"
                                    targetHeader="Quebras selecionadas"
                                    showSourceControls={false}
                                    showTargetControls={false}
                                    itemTemplate={(quebra) => <div>{quebra.quebra}</div>}
                                    onChange={(e) => {
                                        setQuebras(e.source);
                                        setQuebrasSelecionadas(e.target);
                                    }}
                                    sourceStyle={{ height: "200px" }}
                                    targetStyle={{ height: "200px" }}
                                ></PickList>
                                <ul className="widget-person-list p-mt-5">
                                    <div className="p-grid p-justify-center">
                                        <Button
                                            label="Gravar"
                                            disabled={quebrasSelecionadas.length > 0 ? false : true}
                                            onClick={() => handleGravaInutilizacao()}
                                        />
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </TabPanel>
                </TabView>
            </Dialog>
            <ConfirmDialog
                visible={confirmInutilizacao}
                onHide={() => setConfirmInutilizacao(false)}
                message={
                    <>
                        <p className="p-m-0">Deseja realmente inutilizar a(s) numeração(ões)?</p>
                        <p className="p-m-0" style={{ color: "#FF0000" }}>
                            <b>ATENÇÃO: </b>Essa ação não pode ser revertida.
                        </p>
                    </>
                }
                header="Confirmação de inutilização"
                icon="pi pi-exclamation-triangle"
                accept={() => handleEnviaInutilizacao()}
                acceptLabel="Confirmar"
                reject={() => setConfirmInutilizacao(false)}
                rejectLabel="Cancelar"
            />
        </div>
    );
};
