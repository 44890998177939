import React, { useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";

// DOCS...
// tabs: (array) Lista de abas que serão exibidas
//      -> EXEMPLO: [{
//                      header: (string),
//                      icon: (string),
//                      disabled: (boolean | null | undefined),
//                      component: (Component) <Componente />,
//                      visible: (boolean | null | undefined),
//                  }]
// title: (string) Titulo que será exibido na parte superior
// ocultarButtonFinalizar: (boolean) Define se o botão de finalizar deve aparecer ou não
// ocultarButtonCancelar: (boolean) Define se o botão de cancelar deve aparecer ou não
// propsButtonProx: (obj) Propriedades válidas para o componente Button do Primereact para o botão de avançar aba.
// propsButtonAnt: (obj) Propriedades válidas para o componente Button do Primereact para o botão de voltar aba.
// propsButtonFinalizar: (obj) Propriedades válidas para o componente Button do Primereact para o botão de finalizar.
// propsButtonCancelar: (obj) Propriedades válidas para o componente Button do Primereact para o botão de cancelar.
// confirmDialogAoFinalizar: (boolean) Exibibe um dialog ao clickar no botão finalizar, caso true, não passar função de accept em propsButtonFinalizar, é sim em propsConfirmDialog
// propsConfirmDialog: (obj) ->
// EXEMPLO: {
//     header: (string),
//     message: (string || <Componente />),
//     accept: (function),
//     onHide: (function) || null,
//     onReject (function) || null,
// }

export const MakoMultiTabs = ({
    tabs,
    title,
    ocultarButtonFinalizar,
    ocultarButtonCancelar,
    propsButtonProx,
    propsButtonAnt,
    propsButtonFinalizar,
    propsButtonCancelar,
    confirmDialogAoFinalizar = false,
    propsConfirmDialog,
    tabAtiva = 0,
}) => {
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [activeTab, setActiveTab] = useState(tabAtiva);

    const TABS_VISIVEIS = tabs.filter((tab) => tab.visible !== false);

    const avancarAba = () => {
        if (activeTab !== TABS_VISIVEIS.length - 1) {
            setActiveTab(activeTab + 1);
        }
    };

    const voltarAba = () => {
        if (activeTab > 0) {
            setActiveTab(activeTab - 1);
        }
    };

    const onHideDialog = (onHide = null) => {
        if (typeof onHide === "function") onHide();

        setConfirmDialog(false);
    };

    const onRejectDialog = (onReject = null) => {
        if (typeof onReject === "function") onReject();

        setConfirmDialog(false);
    };

    const showDialog = () => {
        setConfirmDialog(true);
    };

    return (
        <>
            {title && <h5>{title}</h5>}
            <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
                {TABS_VISIVEIS.map((tab, i) => (
                    <TabPanel key={i} header={tab.header} leftIcon={tab.icon} disabled={tab.disabled}>
                        {tab.component}
                    </TabPanel>
                ))}
            </TabView>
            <div className="p-grid p-fluid">
                {activeTab > 0 ? (
                    <div className="p-col-6 p-md-3">
                        <Button
                            {...propsButtonAnt}
                            label="Anterior"
                            icon="pi pi-angle-left"
                            className="p-button-info"
                            onClick={voltarAba}
                        />
                    </div>
                ) : null}
                {activeTab !== TABS_VISIVEIS.length - 1 && (
                    <div className="p-col-6 p-md-3">
                        <Button
                            {...propsButtonProx}
                            label="Próximo"
                            icon="pi pi-angle-right"
                            iconPos="right"
                            className="p-button-info"
                            onClick={avancarAba}
                        />
                    </div>
                )}
                {!!!ocultarButtonFinalizar && (
                    <>
                        {!!!confirmDialogAoFinalizar ? (
                            <div className="p-col-6 p-md-3">
                                <Button label="Finalizar" {...propsButtonFinalizar} className="p-button-success" />
                            </div>
                        ) : (
                            <div className="p-col-6 p-md-3">
                                <Button
                                    label="Finalizar"
                                    {...propsButtonFinalizar}
                                    onClick={showDialog}
                                    className="p-button-success"
                                />
                            </div>
                        )}
                    </>
                )}
                {!!!ocultarButtonCancelar && (
                    <div className="p-col-6 p-md-3">
                        <Button label="Sair" {...propsButtonCancelar} className="p-button-danger" />
                    </div>
                )}
            </div>
            {!!confirmDialogAoFinalizar && (
                <ConfirmDialog
                    visible={confirmDialog}
                    onHide={() => onHideDialog(propsConfirmDialog.onHide ? propsConfirmDialog.onHide : null)}
                    header={propsConfirmDialog.header ? propsConfirmDialog.header : "Confirmação"}
                    message={
                        propsConfirmDialog.message ? (
                            propsConfirmDialog.message
                        ) : (
                            <span>
                                Deseja persistir os dados gravados?
                                <br />
                                <span className="p-error">
                                    Lembre-se de gravar os dados nos formulários antes de finalizar.{" "}
                                </span>
                            </span>
                        )
                    }
                    icon="pi pi-info-circle p-mr-3"
                    accept={propsConfirmDialog.accept}
                    acceptLabel="Sim"
                    acceptClassName="p-button-danger"
                    reject={() => onRejectDialog(propsConfirmDialog.onReject ? propsConfirmDialog.onReject : null)}
                    rejectLabel="Não"
                />
            )}
        </>
    );
};
