import React, { useEffect, useImperativeHandle, useRef, useState, forwardRef } from "react";

import { ModalMotivoAprovacaoPreco } from "./ModalMotivoAprovacaoPreco";
import { MakoDialogConfirmarSenha } from "@/components/MakoDialogConfirmarSenha";
import { axiosPut } from "@/services/http";
import useToast from "@/hooks/useToast";

const Factory = ({ notificacao, comentario, setLoading }, ref) => {
    const [bodyAprovacao, setBodyNotificacao] = useState({
        notificacao: notificacao.id,
        comentario: comentario || "",
    });
    const modalMotivoAprovacaoPrecoRef = useRef(null);
    const modalSenhaRef = useRef(null);
    const { showSuccess, showWarning, showError } = useToast();

    useEffect(() => {
        return () => {
            modalMotivoAprovacaoPrecoRef.current = null;
            modalSenhaRef.current = null;
        };
    }, []);

    async function aprovarNotificacao() {
        setLoading(true);
        const { status, data } = await axiosPut("/pessoas/aprovar-notificacao/", bodyAprovacao);
        setLoading(false);
        if (status === 200) {
            showSuccess({
                summary: "Sucesso!",
                detail: data.msg,
                life: 2000,
            });
        } else if (status === 400 || status === 404) {
            showWarning({
                summary: "Falhou...",
                detail: data.msg,
                life: 3000,
            });
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível processar a sua requisição.",
                life: 3000,
            });
        }
    }

    const aprovar = () => {
        if (notificacao.tipo === "AS") {
            modalSenhaRef.current?.abrirModal();
        } else {
            aprovarNotificacao();
        }
    };

    const executar = () => {
        const { evento_content_type } = notificacao;
        if (!evento_content_type) aprovar();
        if (evento_content_type.app_label === "vendas" && evento_content_type.model === "itemvenda") {
            modalMotivoAprovacaoPrecoRef.current?.abrirModal();
        }
    };

    useImperativeHandle(ref, () => ({ executar }));

    return (
        <>
            <ModalMotivoAprovacaoPreco
                ref={modalMotivoAprovacaoPrecoRef}
                setBodyNotificacao={setBodyNotificacao}
                onClick={() => aprovar()}
            />
            <MakoDialogConfirmarSenha ref={modalSenhaRef} onConfirm={() => aprovarNotificacao()} />
        </>
    );
};

export const FactoryAprovacaoNotificacao = forwardRef(Factory);
