import React, { forwardRef, useImperativeHandle, useState } from "react";

import { useFormik } from "formik";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Ripple } from "primereact/ripple";

import { Dropdown } from "@/components/Dropdown";

import { STATUS_ITEM } from "@/assets/constants/constants";

import useToast from "@/hooks/useToast";
import { Dialog } from "primereact/dialog";

const INITIAL_VALUES = {
    nome__icontains: "",
    ncm__codigo__iconstains: "",
    marca: null,
    marca__nome__icontains: "",
    ativo: null,
    status: null,
    descricao__iconstains: "",
    marca__nome__iconstains: "",
};

const urlItemLimpa = `/produtos/itens?query={id,nome,ncme,marca,status}&ativo=true&`;

export const Modal = ({ filtros, setFiltro, removerFiltro, setUrl }, ref) => {
    const [visible, setVisible] = useState(false);
    const { showError } = useToast();

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: filtros ? { ...INITIAL_VALUES, ...filtros } : INITIAL_VALUES,
        onSubmit: handleSearch,
        enableReinitialize: true,
    });

    async function handleSearch(values) {
        try {
            const { crescente, decrescente, ...filtros } = values;
            const params = [];
            let _filtros = { ordering: crescente ? crescente : decrescente ? `-${decrescente}` : "" };
            Object.keys(filtros).map((key) => {
                if (filtros[key]) {
                    _filtros[key] = filtros[key];
                    params.push(`${key}=${filtros[key]}`);
                }
            });
            const ordering = crescente ? `&ordering=${crescente}` : decrescente ? `&ordering=-${decrescente}` : "";
            const url = `${urlItemLimpa}${params.join("&")}${ordering}`;
            setFiltro(_filtros);
            setUrl(url);
            setVisible(false);
        } catch (error) {
            showError({
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
        }
    }

    const limparFiltrosForm = () => {
        formik.resetForm();
    };

    const limparFiltro = () => {
        removerFiltro();
        limparFiltrosForm();
    };

    const template = (options) => {
        const toggleIcon = options.collapsed ? "pi pi-chevron-down" : "pi pi-chevron-up";
        const className = `${options.className} justify-content-start`;
        const titleClassName = `${options.titleClassName} pl-1`;

        return (
            <div className={className}>
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
                <span className={titleClassName}>Ordem</span>
            </div>
        );
    };

    const selecionaOrdem = (e, field = "crescente") => {
        const keyToClean = field === "crescente" ? "decrescente" : "crescente";
        setFieldValue(keyToClean, null);
        formik.handleChange(e);
    };

    const abrirModal = () => {
        setVisible(true);
    };

    useImperativeHandle(ref, () => ({ abrirModal, limparFiltro }));

    return (
        <Dialog
            header="Filtrar Produtos Agrupados"
            visible={visible}
            style={{ width: "50vw" }}
            breakpoints={{ "960px": "75vw" }}
            onHide={() => setVisible(false)}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-8">
                        <label htmlFor="nome__icontains">Nome</label>
                        <InputText
                            id="nome__icontains"
                            name="nome__icontains"
                            placeholder="Digite uma descrição"
                            value={formik.values.nome__icontains}
                            onInput={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="ncm__codigo__iconstains">Código NCM</label>
                        <InputText
                            id="ncm__codigo__iconstains"
                            name="ncm__codigo__iconstains"
                            placeholder="Digite um código de ncm"
                            value={formik.values.ncm__codigo__iconstains}
                            onInput={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="marca">Marca</label>
                        <Dropdown
                            id="marca"
                            name="marca"
                            url={"/produtos/marcas/"}
                            optionValue="id"
                            optionLabel="nome"
                            value={formik.values.marca}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="marca__nome__iconstains">Marca por nome</label>
                        <InputText
                            id="marca__nome__iconstains"
                            name="marca__nome__iconstains"
                            placeholder="Digite um nome"
                            value={formik.values.marca__nome__iconstains}
                            onInput={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="descricao__icontains">Descrição</label>
                        <InputText
                            id="descricao__icontains"
                            name="descricao__icontains"
                            placeholder="Digite uma descrição"
                            value={formik.values.descricao__icontains}
                            onInput={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="status">Status do item</label>
                        <Dropdown
                            id="status"
                            name="status"
                            options={STATUS_ITEM}
                            optionLabel="label"
                            optionValue="id"
                            showClear
                            placeholder="Selecione um status"
                            value={formik.values.status}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="ativo">Ativo</label>
                        <Dropdown
                            id="ativo"
                            name="ativo"
                            placeholder="Todos"
                            options={[
                                { value: null, label: "Todos" },
                                { value: "true", label: "Ativos" },
                                { value: "false", label: "Desativados" },
                            ]}
                            optionLabel="label"
                            optionValue="value"
                            value={formik.values.ativo}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <Panel headerTemplate={template} toggleable collapsed={true}>
                    <b>Ordenar</b>
                    <br />
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="crescente">Crescente</label>
                            <Dropdown
                                id="crescente"
                                name="crescente"
                                placeholder="Selecione um campo"
                                options={[
                                    { field: "id", header: "ID" },
                                    { field: "nome", header: "Nome" },
                                ]}
                                optionLabel="header"
                                optionValue="field"
                                value={formik.values.crescente}
                                onChange={selecionaOrdem}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="decrescente">Decrescente</label>
                            <Dropdown
                                id="decrescente"
                                name="decrescente"
                                placeholder="Selecione um campo"
                                options={[
                                    { field: "id", header: "ID" },
                                    { field: "nome", header: "Nome" },
                                ]}
                                optionLabel="header"
                                optionValue="field"
                                value={formik.values.decrescente}
                                onChange={(e) => selecionaOrdem(e, "descrescente")}
                            />
                        </div>
                    </div>
                </Panel>
                <div className="p-grid p-justify-end p-mt-4">
                    <Button
                        type="submit"
                        icon="pi pi-filter"
                        label="Filtrar"
                        className="p-mr-2"
                        onClick={formik.handleSubmit}
                    />
                    <Button
                        type="reset"
                        icon="pi pi-trash"
                        label="Limpar"
                        onClick={limparFiltro}
                        className="p-button-warning p-mr-2"
                    />
                    <Button
                        type="button"
                        label="Cancelar"
                        onClick={() => setVisible(false)}
                        className="p-button-danger p-mr-3"
                    />
                </div>
            </form>
        </Dialog>
    );
};

export const ModalFiltroItem = forwardRef(Modal);
