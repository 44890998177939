import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { TabView, TabPanel } from "primereact/tabview";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { CategoriasVinculadasPage } from "./listCategoriasVinculadas";
import { BotaoDelete } from "@/components/BotaoDelete";
import useClearRefs from "@/hooks/useClearRefs";
import useToast from "@/hooks/useToast";
import { CodigoFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";

const url = "/produtos/categorias-grupos/";

export const GrupoCategoriasPage = () => {
    const [redirecionar, setRedirecionar] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const { showSuccess } = useToast();
    const listagemRef = useRef(null);

    useClearRefs(listagemRef);

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {rowData.padrao_sistema ? (
                    <b>Padrão do sistema</b>
                ) : (
                    <div>
                        <MakoControleAcesso
                            permissao={[permissoes.PRODUTO_PRODUTO_GRUPOSCATEGORIAS_EDITAR]}
                            componente={Button}
                            icon="pi pi-pencil"
                            className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                            tooltipOptions={{ position: "left" }}
                            onClick={() =>
                                setRedirecionar({
                                    pathname: "/produto/produto/grupos-categorias/form",
                                    state: { grupo_categoria: rowData },
                                })
                            }
                        />
                        <MakoControleAcesso
                            permissao={[permissoes.PRODUTO_CADASTRO_VIGENCIAPRECO_EXCLUIR]}
                            componente={BotaoDelete}
                            url={url}
                            objetoId={rowData.id}
                            classNames="p-mb-1"
                            exigeConfirmacao
                            msgConfirmacao={<span>Deseja realmente excluir o grupo de categorias?</span>}
                            msgToastErroExclusao="O grupo não pode ser excluído."
                            onDelete={() => {
                                listagemRef.current?.buscarDados();
                                showSuccess({
                                    summary: "Sucesso",
                                    detail: "Grupo excluído com sucesso!",
                                    life: 1500,
                                });
                            }}
                        />
                    </div>
                )}
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.PRODUTO_PRODUTO_GRUPOSCATEGORIAS_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setRedirecionar("/produto/produto/grupos-categorias/form")}
            />
        </>
    );

    const colunas = [
        { field: "id", header: "Código", style: { width: "12%" }, filter: true, filterElement: CodigoFiltroTemplate },
        { field: "descricao", header: "Descrição", filter: true, filterElement: TextoFiltroTemplate },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "30%" },
        },
    ];

    return redirecionar ? (
        <Redirect to={redirecionar} />
    ) : (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <h5>Grupos de categorias</h5>
                    <TabView
                        className="tabview-custom"
                        activeIndex={activeTab}
                        onTabChange={(e) => setActiveTab(e.index)}
                    >
                        <TabPanel header="- Listagem Grupos" leftIcon="pi pi-th-large">
                            <MakoListagem
                                ref={listagemRef}
                                colunas={colunas}
                                painelEsquerdo={cabecalhoTabela}
                                urlPesquisa={url}
                                configTabela={{
                                    paginator: true,
                                    lazy: true,
                                }}
                                filtros={{
                                    id: { value: null, matchMode: "equals" },
                                    descricao: {
                                        operator: "and",
                                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                                    },
                                }}
                            />
                        </TabPanel>
                        <TabPanel header="- Categorias Vinculadas" leftIcon="pi pi-clone">
                            <CategoriasVinculadasPage />
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        </div>
    );
};
