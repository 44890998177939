import React, { useRef } from "react";

import { setMonth } from "date-fns";

import { FormFeriadosModal } from "./form";

import { Button } from "primereact/button";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { BotaoDelete } from "@/components/BotaoDelete";
import { PageBase } from "@/components/PageBase";
import MakoListagem from "@/components/MakoListagem";

import { toCapitalize } from "@/assets/util/util";
import { dataToStr } from "@/assets/util/datas";
import permissoes from "@/assets/constants/permissoes";
import useClearRefs from "@/hooks/useClearRefs";
import {
    CidadeFiltroTemplate as MCidadeFiltroTemplate,
    CodigoFiltroTemplate,
    DropdownFiltroTemplate,
    NumeroFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";

const BASE_URL = "/configuracoes/feriados/";

export const FeriadosPage = () => {
    const listagemRef = useRef(null);
    const modalRef = useRef(null);

    useClearRefs(listagemRef, modalRef);

    const painelEsquerdo = (
        <>
            <Button
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2 p-mb-2"
                onClick={() => modalRef.current?.abrirModal()}
            />
        </>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.CONFIGURACOES_FERIADOS_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    onClick={() => modalRef.current?.abrirModal(rowData)}
                />
                <MakoControleAcesso
                    permissao={[permissoes.CONFIGURACOES_FERIADOS_EXCLUIR]}
                    componente={BotaoDelete}
                    url={BASE_URL}
                    objetoId={rowData.id}
                    classNames="p-mb-1"
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir o feriado de <b>{rowData.descricao}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O não pode não pode ser excluído."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const DiaFiltroTemplate = (options) => {
        return <NumeroFiltroTemplate inputProps={{ min: 1, max: 31, useGrouping: false }} options={options} />;
    };

    const MesFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: [
                        { value: 0, label: "Janeiro" },
                        { value: 1, label: "Fevereiro" },
                        { value: 2, label: "Março" },
                        { value: 3, label: "Abril" },
                        { value: 4, label: "Maio" },
                        { value: 5, label: "Junho" },
                        { value: 6, label: "Julho" },
                        { value: 7, label: "Agosto" },
                        { value: 8, label: "Setembro" },
                        { value: 9, label: "Outubro" },
                        { value: 10, label: "Novembro" },
                        { value: 11, label: "Dezembro" },
                    ],
                }}
                options={options}
            />
        );
    };

    const AnoFiltroTemplate = (options) => {
        return <TextoFiltroTemplate keyFilter="int" maxLength={4} {...options} />;
    };

    const EstadoFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "codigo_uf",
                    optionLabel: "nome",
                    url: "/pessoas/estados?query={id,nome,uf,codigo_uf}&limit=100",
                    filter: true,
                    showClear: true,
                    filterBy: "nome",
                }}
                options={options}
            />
        );
    };

    const CidadeFiltroTemplate = (options) => {
        return <MCidadeFiltroTemplate {...options} />;
    };

    const colunas = [
        { field: "id", header: "ID", style: { width: "5%" }, filter: true, filterElement: CodigoFiltroTemplate },
        { field: "descricao", header: "Descrição" },
        {
            field: "dia",
            header: "Dia",
            align: "center",
            style: { width: "3%" },
            filter: true,
            filterElement: DiaFiltroTemplate,
        },
        {
            field: "mes",
            header: "Mês",
            action: ({ mes }) => toCapitalize(dataToStr(setMonth(new Date(), mes), "MMMM")),
            filter: true,
            filterElement: MesFiltroTemplate,
        },
        {
            field: "ano",
            header: "Ano",
            style: { width: "5%" },
            filter: true,
            filterElement: AnoFiltroTemplate,
        },
        {
            field: "codigo_uf.nome",
            header: "Estado",
            filter: true,
            filterElement: EstadoFiltroTemplate,
            filterField: "codigo_uf",
        },
        {
            field: "codigo_ibge.nome",
            header: "Cidade",
            filter: true,
            filterElement: CidadeFiltroTemplate,
            filterField: "codigo_ibge",
        },
        { field: "action", header: "Ações", action: (e) => actionBodyTemplate(e), style: { width: "10%" } },
    ];

    const onSuccess = () => {
        listagemRef.current?.buscarDados();
    };

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Listagem de feriados"
                colunas={colunas}
                painelEsquerdo={painelEsquerdo}
                urlPesquisa={BASE_URL}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    documento: { value: null, matchMode: "equals" },
                    dia: { value: null, matchMode: "equals" },
                    mes: { value: null, matchMode: "equals" },
                    ano: { value: null, matchMode: "equals" },
                    codigo_uf: { value: null, matchMode: "equals" },
                    codigo_ibge: { value: null, matchMode: "equals" },
                }}
            />

            <FormFeriadosModal ref={modalRef} onSuccess={onSuccess} />
        </PageBase>
    );
};
