import React, { useRef } from "react";

import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { axiosPost, axiosPut } from "@/services/http";

import * as Yup from "yup";
import classNames from "classnames";
import useLoading from "@/hooks/useLoading";
import { Dropdown } from "@/components/Dropdown";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const ProdutoFatorConversaoForm = (props) => {
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const history = useHistory();

    const TIPOOPERACAO = [
        { id: "*", descricao: "MULTIPLICAÇÃO" },
        { id: "/", descricao: "DIVISÃO" },
    ];

    const formik = useFormik({
        initialValues: props.location.state
            ? props.location.state.unidadeMedidaFc
            : {
                  id: "",
                  unidade: "",
                  unidade_correspondente: "",
                  fator_conversao: null,
                  operacao: null,
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(`/produtos/unidades-medida-fc/`, {
                    ...values,
                    unidade: values.unidade.id,
                    unidade_correspondente: values.unidade_correspondente.id,
                });
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Fator de conversão cadastrado com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push({
                            pathname: "/produto/produto/unidade-medida",
                            state: 1,
                        });
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(`/produtos/unidades-medida-fc/${values.id}/`, values);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Fator de conversão alterado com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push({
                            pathname: "/produto/produto/unidade-medida",
                            state: 1,
                        });
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    function validateForm() {
        return (
            formik.values.unidade !== "" &&
            formik.values.unidade_correspondente !== "" &&
            formik.values.fator_conversao !== null &&
            formik.values.operacao !== null
        );
    }

    const cancelar = () => {
        formik.resetForm();
        history.push({
            pathname: "/produto/produto/unidade-medida",
            state: 1,
        });
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toastRef} />
                    <h3>{!formik.values.id ? "Novo fator de conversão" : "Manutenção de fator de conversão"}</h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="unidade">Unidade *</label>
                                <Dropdown
                                    id="unidade"
                                    name="unidade"
                                    placeholder="Selecione uma unidade"
                                    url="/produtos/unidades-medida?limit=1000"
                                    optionLabel="nome"
                                    filter
                                    filterBy="nome,sigla"
                                    showClear={false}
                                    value={formik.values.unidade}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.unidade })}
                                    autoFocus
                                    autoComplete="off"
                                />
                                {formik.errors.unidade && <small className="p-error">{formik.errors.unidade}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="operacao">Operação *</label>
                                <Dropdown
                                    id="operacao"
                                    name="operacao"
                                    placeholder="Selecione o tipo de operação"
                                    options={TIPOOPERACAO}
                                    optionValue="id"
                                    optionLabel="descricao"
                                    value={formik.values.operacao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.operacao })}
                                />
                                {formik.errors.operacao && <small className="p-error">{formik.errors.operacao}</small>}
                            </div>

                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="fator_conversao">Fator de conversão *</label>
                                <InputNumber
                                    id="fator_conversao"
                                    name="fator_conversao"
                                    minFractionDigits={2}
                                    maxFractionDigits={12}
                                    min={0}
                                    value={formik.values.fator_conversao}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.fator_conversao })}
                                    tooltip="Valor que será utilizado na conversão."
                                    autoComplete="off"
                                />
                                {formik.errors.fator_conversao && (
                                    <small className="p-error">{formik.errors.fator_conversao}</small>
                                )}
                            </div>
                            <div className="p-field p-mt-5" style={{ paddingTop: "2px" }}>
                                <h5>=</h5>
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="unidade_correspondente">Unidade correspondente *</label>
                                <Dropdown
                                    id="unidade_correspondente"
                                    name="unidade_correspondente"
                                    placeholder="Selecione uma unidade correspondente"
                                    url="/produtos/unidades-medida?limit=1000"
                                    optionLabel="nome"
                                    filter
                                    filterBy="nome,sigla"
                                    showClear={false}
                                    value={formik.values.unidade_correspondente}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.unidade_correspondente })}
                                />
                                {formik.errors.unidade_correspondente && (
                                    <small className="p-error">{formik.errors.unidade_correspondente}</small>
                                )}
                            </div>
                        </div>
                        <h6 style={{ color: "#ff5252" }}>
                            <b>CÁLCULO: </b> {formik.values.unidade.sigla ? `1 ${formik.values.unidade.sigla}` : "???"}
                            {formik.values.operacao ? ` ${formik.values.operacao} ` : " ??? "}
                            {formik.values.fator_conversao
                                ? ` ${formik.values.fator_conversao} `
                                : formik.values.fator_conversao === 0
                                ? formik.values.fator_conversao
                                : " ??? "}
                            {" = "}
                            {formik.values.unidade_correspondente.sigla
                                ? `1 ${formik.values.unidade_correspondente.sigla} `
                                : " ??? "}
                        </h6>

                        <p>
                            <b>* Campos obrigatórios.</b>
                        </p>

                        <div className="p-grid p-col-12 p-md-6">
                            <Button
                                label="Gravar"
                                type="submit"
                                className="p-button-info p-mr-2 p-mb-6"
                                disabled={!validateForm()}
                            />
                            <Button
                                label="Cancelar"
                                type="reset"
                                className="p-button-danger p-mr-2 p-mb-6"
                                onClick={cancelar}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
