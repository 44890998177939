import React from "react";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";

import { ResumoDevolucao } from "./ResumoDevolucao";
import { MakoListagem } from "@/components/MakoListagem";
import useDevolucao from "@/hooks/useDevolucao";

export const DocFiscalDevolucaoForm = () => {
    const { dadosBasicos } = useDevolucao();

    const statusBodyTemplate = (rowData) => {
        return <Tag severity="success" value="EMITIDA COM SUCESSO" />;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-cog" className="p-button-rounded p-button-info" />
            </div>
        );
    };

    const colunas = [
        { field: "numero", header: "Número", style: { width: "8%" } },
        {
            field: "serie",
            header: "Série",
            style: { width: "8%" },
        },
        {
            field: "chave",
            header: "Chave",
        },
        { field: "itens", header: "Itens", style: { width: "8%" }, decimal: true },
        { field: "total_nf", header: "Total da NF", style: { width: "10%" }, money: true },
        { field: "situacao", header: "Situação da NF", style: { width: "10%" }, action: statusBodyTemplate },
        { field: "actions", header: "Ações", style: { width: "10%" }, action: actionBodyTemplate },
    ];

    const painelEsquerdo = <Button icon="pi pi-plus" label="Emitir NF" className="p-button-success" />;

    return (
        <>
            <ResumoDevolucao devolucao={dadosBasicos} />
            <MakoListagem painelEsquerdo={painelEsquerdo} colunas={colunas} />
        </>
    );
};
