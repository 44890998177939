import React, { useEffect, useRef, useState, useCallback } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { axiosPost, axiosGet, axiosPatch, axiosDelete } from "@/services/http";
import { useHistory } from "react-router-dom";
import { InputNumber } from "primereact/inputnumber";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MultiSelect } from "primereact/multiselect";
import { TIPO_CENTRO_ESTOCAGEM_CHOICE } from "@/assets/constants/constants";
import { TabPanel, TabView } from "primereact/tabview";
import { VincularEmpresaForm } from "./formVincularEmpresa";
import { parseNumberToMoneyHTML } from "@/assets/util/util";
import { PageBase } from "@/components/PageBase";
import { Dropdown } from "@/components/Dropdown";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import useAuth from "@/hooks/useAuth";
import classNames from "classnames";
import * as Yup from "yup";
import { ItemTabelaModalForm } from "./modals/itemTabelaModalForm";
import { BotaoDelete } from "@/components/BotaoDelete";
import { EditarItemModalForm } from "./modals/editarItemModalForm";
import { OverlayPanel } from "primereact/overlaypanel";
import { Panel } from "primereact/panel";
import { dataToStr } from "@/assets/util/datas";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { ConfirmDialog } from "primereact";

const urlvoltar = "/vendas/cadastros/tabela-precos";

export const ProdutoTabelaPrecosForm = (props) => {
    const [exibeProdutos, setExibeProdutos] = useState(false);
    const [editarProduto, setEditarProduto] = useState(false);
    const [excluirItens, setExcluirItens] = useState(false);
    const [produto, setProduto] = useState(false);
    const [listaPlanos, setListaPlanos] = useState([]);
    const [preco, setPreco] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const { verifyPermission } = useAuth();
    const listagemRef = useRef(null);
    const history = useHistory();
    const toastRef = useRef(null);
    const inspecionar = useRef(null);

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            id: null,
            nome: "",
            qtd_minima_tag: 1,
            tag_cliente: "",
            tipo_centro_estocagem: null,
            vigencia: "",
            plano_recebimento: [],
            percentual_acrescimo: 0,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo 'nome' é obrigatório."),
                qtd_minima_tag: Yup.number().required("O campo 'quantidade mínima' é obrigatório."),
                plano_recebimento: Yup.array().required("O campo 'plano de recebimento' é obrigatório."),
                vigencia: Yup.number().required("O campo 'vigẽncia' é obrigatório."),
                percentual_acrescimo: Yup.number().required("O campo 'percentual de acréscimo' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost("/produtos/tabelas-preco/", values);
                hideLoading();

                if (resposta.status === 201 || resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Tabela de preços cadastrada com sucesso!",
                        life: 1500,
                    });

                    if (formik.values.id) history.push("/vendas/cadastros/tabela-precos");
                    else
                        setTimeout(() => {
                            formik.resetForm();
                            history.push("/vendas/cadastros/tabela-precos/form", {
                                tabelaPreco: resposta.data,
                            });
                        }, 1000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                delete values.empresastabelapreco_set;
                delete values.precostabelapreco_set;

                showLoading();
                const resposta = await axiosPatch(`/produtos/tabelas-preco/${values.id}/`, values);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Cadastro da tabela alterado com sucesso!",
                        life: 1500,
                    });
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    async function handleRemove() {
        try {
            showLoading();
            const resposta = await axiosDelete(`/produtos/limpar-tabela-preco/${formik.values.id}/`);
            hideLoading();

            if (resposta.status === 200) {
                toastRef.current.show({
                    severity: "success",
                    summary: "Sucesso",
                    detail: "Itens da tabela removidos com sucesso!",
                    life: 1500,
                });
                listagemRef.current?.buscarDados();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
            }
        } catch (error) {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 1500,
            });
            hideLoading();
        }
    }

    const listarPlanoRecebimentos = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet(
            "/financeiro/planos-recebimentos?query={id,descricao,minimo_parcelas,maximo_parcelas,taxa_alvo,vigencia_inicial,vigencia_final}&limit=300"
        );
        hideLoading();
        if (resposta.status === 200) setListaPlanos(resposta.data.results);
    }, [showLoading, hideLoading]);

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_CADASTRO_TABELAPRECO_EDITAR]}
                componente={Button}
                label="Adicionar produto(s)"
                icon="pi pi-plus"
                type="button"
                className="p-button-info p-mr-2"
                onClick={() => setExibeProdutos(true)}
            />
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_CADASTRO_TABELAPRECO_EDITAR]}
                componente={Button}
                label="Excluir produtos"
                icon="pi pi-trash"
                type="button"
                className="p-button-danger p-mr-2"
                onClick={() => setExcluirItens(true)}
            />
        </>
    );

    const inspecionaProduto = async (produto, evento) => {
        const resposta = await axiosGet(`/produtos/precos-sku?sku__id=${produto.sku.id}`);
        if (resposta.status === 200 && resposta.data.results?.length > 0) {
            const { custo_base, formacao_origem, ultimo_calculo } = resposta.data.results[0];
            setPreco({
                custo: custo_base,
                preco: produto.preco_calculado,
                markup: ((parseFloat(produto.preco_calculado) - parseFloat(custo_base)) / 100) * 100,
                formacao: formacao_origem?.descricao || null,
                data_calculo: dataToStr(ultimo_calculo, "dd-MM-yyyy HH:mm:ss"),
            });
        }
        inspecionar.current.toggle(evento);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_TABELAPRECO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-outlined p-button-warning p-mr-2"
                    tooltip="Alterar cadastro de tabela de preços"
                    tooltipOptions={{ position: "left" }}
                    onClick={() => {
                        setEditarProduto(true);
                        setProduto(rowData);
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_TABELAPRECO_EDITAR]}
                    componente={BotaoDelete}
                    url="/produtos/tabela-preco-itens/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente remover o item <b>{rowData.sku.descricao_reduzida}</b>?
                        </span>
                    }
                    classNames="p-button-outlined p-mr-2"
                    tooltip="Remover item"
                    tooltipOptions={{ position: "left" }}
                    msgToastErroExclusao="O item não pode ser removido."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_TABELAPRECO_CONSULTAR]}
                    componente={Button}
                    icon="pi pi-search"
                    className="p-button-rounded p-button-outlined p-button-help p-mr-2"
                    tooltip="Inspecionar"
                    tooltipOptions={{ position: "left" }}
                    onClick={(e) => inspecionaProduto(rowData, e)}
                />
            </div>
        );
    };

    const moedaFiltroTemplate = (options) => {
        return (
            <MakoInputMoeda
                showClear
                valueMoeda={options.value}
                onChangeMoeda={(e) => options.filterCallback(e.value)}
            />
        );
    };

    const textoFiltroTemplate = (options) => {
        return (
            <InputNumber
                min={0}
                minFractionDigits={0}
                value={options.value}
                onValueChange={(e) => options.filterCallback(e.value)}
            />
        );
    };

    const planoRecebimentoFiltroTemplate = (options) => {
        return (
            <Dropdown
                placeholder="Selecione"
                showClear
                optionLabel="descricao"
                optionValue="id"
                url={"/financeiro/planos-recebimentos?query={id,descricao}&limit=300"}
                value={options.value}
                onChange={(e) => options.filterCallback(e.value)}
            />
        );
    };

    const aposPesquisarProdutos = (data) => {
        return data.map((item) => {
            return {
                ...item,
                sku__codigo: item.sku.codigo,
            };
        });
    };

    const colunas = [
        {
            field: "sku__codigo",
            header: "Código",
            filter: true,
            filterElement: textoFiltroTemplate,
            style: { minWidth: "5%" },
        },
        { field: "sku.descricao_reduzida", header: "Produto", style: { minWidth: "30%" } },
        {
            field: "plano_recebimento",
            header: "Plano recebimento",
            filter: true,
            filterElement: planoRecebimentoFiltroTemplate,
            action: ({ plano_recebimento }) => plano_recebimento?.descricao || "-",
        },
        {
            field: "preco_calculado",
            filter: true,
            filterElement: moedaFiltroTemplate,
            header: "Preço",
            action: (e) => parseNumberToMoneyHTML(e.preco_calculado),
        },
        {
            field: "preco_arredondado",
            header: "Preço Arredondado",
            filter: true,
            filterElement: moedaFiltroTemplate,
            action: (e) => parseNumberToMoneyHTML(e.preco_arredondado),
        },
        {
            field: "action",
            header: "Ações",
            rowEditor: true,
            action: (e) => actionBodyTemplate(e),
        },
    ];

    useEffect(() => {
        if (props.location?.state?.tabelaPreco) setValues(props.location.state.tabelaPreco);
    }, [props.location?.state?.tabelaPreco, setValues]);

    useEffect(() => {
        listarPlanoRecebimentos();
    }, [listarPlanoRecebimentos]);

    return (
        <PageBase>
            <div className="p-col-12">
                <Toast ref={toastRef} />
                <h5>{!formik.values.id ? "Nova Tabela de Preços" : "Manutenção de Tabela de Preços"}</h5>
                <TabView>
                    <TabPanel header="Dados" leftIcon="pi pi-dollar">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="card">
                                <div className="p-fluid p-formgrid p-grid">
                                    <div className="p-field p-col-12 p-md-7">
                                        <label htmlFor="nome">Nome tabela *</label>
                                        <InputText
                                            id="nome"
                                            name="nome"
                                            value={formik.values.nome}
                                            onChange={formik.handleChange}
                                            className={classNames({
                                                "p-invalid": formik.errors.nome,
                                            })}
                                            autoComplete="off"
                                            autoFocus
                                        />
                                        {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                                    </div>
                                    <div className="p-field p-col-12 p-md-2">
                                        <label htmlFor="qtd_minima_tag">Quantidade mínima *</label>
                                        <InputNumber
                                            id="qtd_minima_tag"
                                            name="qtd_minima_tag"
                                            min={0}
                                            showButtons
                                            onValueChange={formik.handleChange}
                                            value={formik.values.qtd_minima_tag}
                                            className={classNames({
                                                "p-invalid": formik.errors.qtd_minima_tag,
                                            })}
                                            autoComplete="off"
                                        />
                                        {formik.errors.qtd_minima_tag && (
                                            <small className="p-error">{formik.errors.qtd_minima_tag}</small>
                                        )}
                                    </div>
                                    <div className="p-field p-col-12 p-md-3">
                                        <label htmlFor="tag_cliente">Tag cliente</label>
                                        <Dropdown
                                            id="tag_cliente"
                                            name="tag_cliente"
                                            placeholder="Selecione uma tag"
                                            url={"/pessoas/tags-perfis?query={id, descricao}&limit=300"}
                                            optionValue="id"
                                            optionLabel="descricao"
                                            emptyMessage="Nenhuma tag cadastrada."
                                            showClear
                                            value={formik.values.tag_cliente}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.tag_cliente && (
                                            <small className="p-error">{formik.errors.tag_cliente}</small>
                                        )}
                                    </div>
                                </div>
                                <div className="p-fluid p-formgrid p-grid">
                                    <div className="p-field p-col-12 p-md-3">
                                        <label htmlFor="vigencia">Vigência *</label>
                                        <Dropdown
                                            id="vigencia"
                                            name="vigencia"
                                            placeholder="Selecione uma vigência"
                                            url={"/produtos/precos-vigencia?query={id,descricao}&limit=300"}
                                            optionValue="id"
                                            optionLabel="descricao"
                                            filter
                                            filterBy="descricao"
                                            emptyMessage="Nenhuma vigência cadastrada."
                                            value={formik.values.vigencia}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.vigencia && (
                                            <small className="p-error">{formik.errors.vigencia}</small>
                                        )}
                                    </div>
                                    <div className="p-field p-col-12 p-md-3">
                                        <label htmlFor="tipo_centro_estocagem">Tipo estocagem</label>
                                        <Dropdown
                                            id="tipo_centro_estocagem"
                                            name="tipo_centro_estocagem"
                                            options={TIPO_CENTRO_ESTOCAGEM_CHOICE}
                                            optionValue="value"
                                            optionLabel="label"
                                            filter
                                            filterBy="label"
                                            showClear
                                            placeholder="Selecione um tipo"
                                            value={formik.values.tipo_centro_estocagem}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.tipo_centro_estocagem && (
                                            <small className="p-error">{formik.errors.tipo_centro_estocagem}</small>
                                        )}
                                    </div>
                                    <div className="p-field p-col-12 p-md-6">
                                        <label htmlFor="plano_recebimento">Plano de Recebimento *</label>
                                        <MultiSelect
                                            id="plano_recebimento"
                                            name="plano_recebimento"
                                            value={formik.values.plano_recebimento}
                                            options={listaPlanos}
                                            onChange={(e) => setFieldValue("plano_recebimento", e.value)}
                                            optionLabel="descricao"
                                            optionValue="id"
                                            placeholder="Selecione um plano de recebimento"
                                            filterBy="descricao"
                                            filter
                                            className="multiselect-custom"
                                        />
                                    </div>
                                </div>
                                <b>* Campos obrigatórios.</b>
                                <div className="p-fluid p-formgrid p-grid">
                                    {formik.values.id ? (
                                        <div className="p-grid p-col-12 p-md-2 p-ml-1 p-mt-2">
                                            <Button
                                                label="Gravar"
                                                type="submit"
                                                icon="pi pi-save"
                                                className="p-button-success p-mr-2 p-mb-1"
                                            />
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="p-grid p-fluid p-justify-start p-mt-2">
                                {!formik.values.id ? (
                                    <div className="p-col-12 p-md-2">
                                        <Button
                                            label="Criar tabela"
                                            type="submit"
                                            icon="pi pi-plus"
                                            className="p-button-success p-mr-2 p-mb-1"
                                        />
                                    </div>
                                ) : null}
                                <div className="p-col-12 p-md-3">
                                    <Button
                                        label="Voltar"
                                        type="reset"
                                        icon="pi pi-angle-double-left"
                                        className="p-button-danger p-mr-2 p-mb-6"
                                        onClick={cancelar}
                                    />
                                </div>
                            </div>
                        </form>
                    </TabPanel>
                    {formik.values.id ? (
                        <TabPanel header="Itens" leftIcon="pi pi-shopping-cart">
                            <MakoListagem
                                ref={listagemRef}
                                colunas={colunas}
                                urlPesquisa={`/produtos/tabela-preco-itens?tabela=${formik.values.id}`}
                                painelEsquerdo={cabecalhoTabela}
                                responsiva
                                aposPesquisar={aposPesquisarProdutos}
                                filtros={{
                                    sku__codigo: {
                                        operator: "and",
                                        constraints: [{ value: "", matchMode: "contains" }],
                                    },
                                    plano_recebimento: { value: null, matchMode: "equals" },
                                    preco_calculado: { value: null, matchMode: "equals" },
                                    preco_arredondado: { value: null, matchMode: "equals" },
                                }}
                                configTabela={{
                                    paginator: true,
                                    lazy: true,
                                    scrollable: true,
                                    editMode: "row",
                                    dataKey: "id",
                                }}
                            />
                        </TabPanel>
                    ) : null}
                    {verifyPermission(permissoes.VENDAS_CADASTRO_TABELAPRECO_INCLUIR) && formik.values.id ? (
                        <TabPanel header="Vincular Empresas" leftIcon="pi pi-users">
                            <VincularEmpresaForm tabela={formik.values.id} />
                        </TabPanel>
                    ) : null}
                </TabView>
                <OverlayPanel className="p-col-8 p-md-3" ref={inspecionar}>
                    <Panel header="Dados do produto" collapsed>
                        <label>
                            <i className="pi pi-database p-mr-1 p-mb-2" style={{ fontSize: "1rem" }} />
                            <b>Formação: </b>
                            {`${preco?.formacao || "-"}`}
                        </label>
                        <br />
                        <label>
                            <i className="pi pi-money-bill p-mr-1 p-mb-2" style={{ fontSize: "1rem" }} />
                            <b>Custo base: </b>
                            {`R$ ${preco?.custo || "-"}`}
                        </label>
                        <br />
                        <label>
                            <i className="pi pi-dollar p-mr-1 p-mb-2" style={{ fontSize: "1rem" }} />
                            <b>Preço calculado: </b>
                            {`R$ ${preco?.preco || "-"}`}
                        </label>
                        <br />
                        <label>
                            <i className="pi pi-calendar p-mr-1 p-mb-2" style={{ fontSize: "1rem" }} />
                            <b>Data cálculo: </b>
                            {`${preco?.data_calculo || "-"}`}
                        </label>
                        <br />
                        <label>
                            <i className="pi pi-percentage p-mr-1 p-mb-2" style={{ fontSize: "1rem" }} />
                            <b>Markup: </b>
                            {`% ${preco?.markup || "-"}`}
                        </label>
                    </Panel>
                </OverlayPanel>
                <ItemTabelaModalForm
                    tabela={formik.values.id}
                    vigencia={formik.values.vigencia}
                    setExibirDialog={setExibeProdutos}
                    exibirDialog={exibeProdutos}
                    toastRef={toastRef}
                    listagemRef={listagemRef}
                    acrescimo={formik.values.percentual_acrescimo}
                />
                <EditarItemModalForm
                    toastRef={toastRef}
                    setEditarProduto={setEditarProduto}
                    editarProduto={editarProduto}
                    produto={produto}
                    listagemRef={listagemRef}
                />
                <ConfirmDialog
                    visible={excluirItens}
                    onHide={() => setExcluirItens(false)}
                    header="Confirmação"
                    message={
                        <span>
                            Deseja realmente excluir <b>TODOS</b> os itens da tabela de preços?
                        </span>
                    }
                    icon="pi pi-info-circle p-mr-3"
                    accept={() => handleRemove()}
                    acceptLabel="Sim"
                    acceptClassName="p-button-danger"
                    reject={() => setExcluirItens(false)}
                    rejectLabel="Não"
                />
            </div>
        </PageBase>
    );
};
