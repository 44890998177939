import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { PageBase } from "@/components/PageBase";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoCalendar } from "@/components/MakoCalendar";
import { dataToStr } from "@/assets/util/datas";
import { axiosPost, axiosPut } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

const url = "/financeiro/versoes-planos-contas-contabeis/";
const urlvoltar = "/contabil/versao-plano-contas";

export const ContabilVersaoPlanoContasForm = (props) => {
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { showError } = useToast();

    const formik = useFormik({
        initialValues: props.location.state?.versaoContas || {
            descricao: "",
            inicio_vigencia: new Date(),
            final_vigencia: null,
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                inicio_vigencia: Yup.date().required("O campo 'vigência inicial' é obrigatório."),
                final_vigencia: Yup.date().when("inicio_vigencia", {
                    is: (val) => !!val,
                    then: Yup.date()
                        .min(
                            Yup.ref("inicio_vigencia") || new Date(),
                            "O campo 'vigência final' não pode ser anterior a inicial"
                        )
                        .nullable()
                        .typeError("Informe uma 'vigência final' válida"),
                }),
            });
            await formSchema.validate(values, { abortEarly: false });
            const { descricao, inicio_vigencia, final_vigencia, ativo } = values;
            const versaoPlanoContas = {
                descricao,
                ativo,
                inicio_vigencia: dataToStr(inicio_vigencia, "yyyy-MM-dd"),
                final_vigencia: dataToStr(final_vigencia, "yyyy-MM-dd"),
            };
            if (!values.id) {
                showLoading();
                const { status } = await axiosPost(`${url}`, versaoPlanoContas);
                hideLoading();
                if (status === 201) {
                    voltarParaListagem();
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const { status } = await axiosPut(`${url}${values.id}/`, versaoPlanoContas);
                hideLoading();
                if (status === 200) {
                    voltarParaListagem();
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const voltarParaListagem = () => {
        history.push(urlvoltar);
    };

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Nova versão do plano de contas" : "Manutenção de versão do plano de contas"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="descricao">Descrição *</label>
                        <InputText
                            id="descricao"
                            name="descricao"
                            autoComplete="off"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="inicio_vigencia">Vigência inicial *</label>
                        <MakoCalendar
                            id="inicio_vigencia"
                            name="inicio_vigencia"
                            valueCalendar={formik.values.inicio_vigencia}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.inicio_vigencia,
                            })}
                        />
                        {formik.errors.inicio_vigencia && (
                            <small className="p-error">{formik.errors.inicio_vigencia}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="final_vigencia">Vigência final</label>
                        <MakoCalendar
                            id="final_vigencia"
                            name="final_vigencia"
                            valueCalendar={formik.values.final_vigencia}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.final_vigencia,
                            })}
                        />
                        {formik.errors.final_vigencia && (
                            <small className="p-error">{formik.errors.final_vigencia}</small>
                        )}
                    </div>
                    <div className="p-field-checkbox p-col-2 p-mt-auto">
                        <Checkbox
                            inputId="ativo"
                            name="ativo"
                            disabled={!formik.values.id}
                            checked={formik.values.ativo}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="ativo">Ativo</label>
                    </div>
                </div>
                <CamposObrigatorios />
                <div className="p-grid p-col-12 p-md-6">
                    <Button type="submit" icon="pi pi-save" label="Gravar" className="p-button-info p-mr-2" />
                    <Button
                        type="reset"
                        icon="pi pi-angle-double-left"
                        label="Voltar"
                        className="p-button-danger"
                        onClick={voltarParaListagem}
                    />
                </div>
            </form>
        </PageBase>
    );
};
