import React, { useRef, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { MakoSelecionarEmails } from "@/components/MakoSelecionarEmails";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import useRelatorio from "@/hooks/useRelatorio";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIO_FINANCEIRO_EXTRATOCOMISSAOANALITiCO } from "@/assets/constants/relatorios";
import { dataToStr } from "@/assets/util/datas";

export const RelatorioExtratoComissao = () => {
    const [visible, setVisible] = useState(false);
    const [emails, setEmails] = useState([]);

    const { solicitarRelatorio } = useRelatorio();

    const empresaSelecionada = useRef();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            date_start: null,
            date_end: null,
            empresa: null,
            lote: null,
        },
        onSubmit: handleSubmit,
    });

    const filtrosTemplate = [
        {
            key: "empresa",
            label: "Empresa",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: ["date_start", "date_end"],
            label: "Período emissão",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: "lote",
            label: "Lote",
            path: "motivo_inventario.descricao",
        },
    ];

    async function handleSubmit(values = null) {
        try {
            const formSchema = Yup.object().shape({
                empresa: Yup.number().required("O campo 'empresa' é obrigatório.").typeError("Selecione uma empresa"),
                lote: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'lote' é obrigatório."),
                    })
                    .typeError("Seleciona um 'lote' válido."),
                date_start: Yup.date()
                    .required("O campo 'data inicla' é obrigatório")
                    .typeError("Selecione uma 'data inicial' válida."),
                date_end: Yup.date()
                    .required("O campo 'data final' é obrigatório")
                    .typeError("Seleciona uma 'data final' válida."),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                empresa: dadosValidados?.empresa ? empresaSelecionada.current : null,
            });

            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.date_start !== null)
                dadosValidados.date_start = dataToStr(dadosValidados.date_start, "yyyy-MM-dd");
            if (dadosValidados.date_end !== null)
                dadosValidados.date_end = dataToStr(dadosValidados.date_end, "yyyy-MM-dd");

            let filtros = {};
            if (dadosValidados) {
                Object.keys(dadosValidados).forEach((key) => {
                    if (dadosValidados[key]) filtros[key] = dadosValidados[key];
                });
            }

            solicitarRelatorio({
                chave: RELATORIO_FINANCEIRO_EXTRATOCOMISSAOANALITiCO,
                emails,
                filtros,
                filtros_template: filtrosTemplateAplicados,
            });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const handleConfirm = (emails) => {
        fecharModal();
        if (emails && emails.length > 0) {
            handlePreSubmit(emails);
        }
    };

    const handlePreSubmit = (emails = []) => {
        setEmails(emails);
        formik.handleSubmit();
    };

    const fecharModal = () => {
        setVisible(() => false);
    };

    const limparFiltro = () => {
        formik.resetForm();
    };

    const onChangeEmpresa = (e) => {
        empresaSelecionada.current = e?.empresa;
        setValues({ empresa: e.id });
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <h5>Filtro para emissão de relatório de extrato de comissão</h5>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-4">
                            <label htmlFor="empresa">Empresa *</label>
                            <MakoDropdownEmpresas
                                id="empresa"
                                name="empresa"
                                placeholder="Selecione uma empresa"
                                value={formik.values.empresa}
                                onChange={(e) => onChangeEmpresa(e)}
                                className={classNames({ "p-invalid": formik.errors.empresa })}
                            />
                            {formik.errors.empresa && <small className="p-error">{formik.errors.empresa}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="date_start">Periodo</label>
                            <MakoCalendar
                                id="date_start"
                                name="date_start"
                                valueCalendar={formik.values.data_start}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.data_start })}
                            />
                            {formik.errors.date_start && <small className="p-error">{formik.errors.date_start}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2 p-mt-4" style={{ paddingTop: "5px" }}>
                            <MakoCalendar
                                id="date_end"
                                name="date_end"
                                valueCalendar={formik.values.date_end}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.date_end })}
                            />
                            {formik.errors.date_end && <small className="p-error">{formik.errors.date_end}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="lote">Lote *</label>
                            <Dropdown
                                id="lote"
                                name="lote"
                                url={"/produtos/lotes-inventario/"}
                                value={formik.values.lote}
                                onChange={formik.handleChange}
                                optionLabel="motivo_inventario.descricao"
                                placeholder="Selecione um tipo..."
                                className={classNames({ "p-invalid": formik.errors.lote })}
                            />
                            {formik.errors.lote && <small className="p-error">{formik.errors.lote}</small>}
                        </div>
                    </div>
                    <div className="p-grid p-col-12 p-md-6 p-mt-2">
                        <Button
                            label="Gerar PDF"
                            icon="pi pi-file-pdf"
                            type="button"
                            onClick={() => handlePreSubmit()}
                            className="p-button-info p-mr-2"
                        />
                        <Button
                            type="button"
                            onClick={() => setVisible(true)}
                            label="Enviar por email"
                            icon="pi pi-envelope"
                            className="p-button-info p-mr-2"
                        />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            onClick={() => limparFiltro()}
                            className="p-button-warning p-mr-2"
                        />
                    </div>
                </div>
            </div>
            <Dialog
                visible={visible}
                blockScroll
                onHide={fecharModal}
                style={{ width: "60vw" }}
                header="Informar emails"
            >
                <MakoSelecionarEmails onCancel={fecharModal} onConfirm={handleConfirm} />
            </Dialog>
        </div>
    );
};
