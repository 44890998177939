import React, { useCallback, useEffect, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { PageBase } from "@/components/PageBase";
import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";
import MakoListagem from "@/components/MakoListagem";

import { InputText } from "primereact/inputtext";
import { Dropdown } from "@/components/Dropdown";
import { Button } from "primereact/button";

import useToast from "@/hooks/useToast";
import useLoading from "@/hooks/useLoading";

import { axiosGet } from "@/services/http";
import { parseData } from "@/assets/util/datas";
import { isAfter } from "date-fns";
import { calcularJurosComposto } from "@/assets/util/calcFinanceiros";
import { formatarCasasDecimais } from "@/assets/util/util";

export const ConsultaPrecosPage = () => {
    const [sku, setSku] = useState(null);
    const [dados, setDados] = useState([]);
    const [planos, setPlanos] = useState([]);
    const [plano, setPlano] = useState(null);
    const [parcelas, setParcelas] = useState([]);
    const [parcela, setParcela] = useState(null);
    const [message, setMessage] = useState(null);

    const { showError, showWarning } = useToast();
    const { showLoading, hideLoading } = useLoading();

    const colunas = [
        {
            field: "codigo",
            header: "COD",
            style: { width: "8%" },
        },
        {
            field: "descricao_reduzida",
            header: "Descrição",
            style: { width: "25%" },
        },
        {
            field: "unidade_padrao.sigla",
            header: "UN",
            style: { width: "5%" },
        },
        {
            field: "preco.preco_base",
            header: "Preço a Vista",
            money: true,
            align: "right",
        },
        {
            field: "valor_calculado",
            header: "Preço Plano",
            money: true,
            align: "right",
        },
        {
            field: "parcelamento",
            header: "Parcelamento",
            align: "right",
        },
    ];

    const { setValues, ...formik } = useFormik({
        initialValues: {
            codigo: null,
            categoriasku__id: null,
            descricao_reduzida: null,
            item__marca: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                codigo: Yup.string().nullable().typeError("Informe um 'código' válido"),
                categoriasku__id: Yup.number()
                    .required("O campo 'categoria' é obrigatório")
                    .typeError("Informe uma 'categoria' válida."),
                descricao_reduzida: Yup.string().nullable().typeError("Informe uma 'descrição' válida"),
                item__marca: Yup.object().nullable().typeError("Informe uma 'marca' válida."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (values.item__marca !== null) values.item__marca = values.item__marca.id;

            const filtersType = {
                codigo: "codigo__contains",
                categoriasku__id: "categoriasku__id",
                descricao_reduzida: "descricao_reduzida__icontains",
                item__marca: "item__marca",
            };

            const filters = [];

            Object.keys(values).map((key) => {
                if (values[key] != null) filters.push(`${filtersType[key]}=${values[key]}`);
            });

            const query = "query={id,codigo,unidade_padrao,precosku_set,modelo,categoriasku_set,descricao_reduzida}";

            if (plano === null || parcela === null)
                setMessage("Selecione um plano de recebimento é a quantidade de parcelas");
            else {
                showLoading();
                const response = await axiosGet(`/produtos/sku/?ativo=true&${query}&${filters.join("&")}`);
                hideLoading();

                if (response.status === 200) {
                    setMessage(null);

                    const { results } = response.data;

                    if (results.length > 0) {
                        let skusValidos = results.filter((item) => item.precosku_set.length > 0);

                        if (skusValidos.length > 0) {
                            const _plano = planos.find((item) => item.id === plano);
                            const dataFilter = parseData(_plano.vigencia_inicial);

                            skusValidos = skusValidos.map((sku) => {
                                sku.preco = sku.precosku_set.find((p) =>
                                    isAfter(dataFilter, parseData(p.vigencia.data_inicio))
                                );

                                if (sku.preco instanceof Object) {
                                    sku.valor_calculado = formatarCasasDecimais(
                                        calcularJurosComposto(sku.preco.preco_base, _plano.taxa_alvo, parcela),
                                        4
                                    );
                                    sku.parcelamento = `${parcela} x ${formatarCasasDecimais(
                                        sku.valor_calculado / parcela,
                                        2
                                    )}`;
                                }

                                return sku;
                            });
                            setDados([...skusValidos]);
                        } else {
                            setDados([]);
                        }
                    }
                } else {
                    throw new Error();
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            } else {
                const { message } = error;
                showError({
                    summary: "Erro :(",
                    detail: message ? message : "Desculpe, não conseguimos processar sua requisição.",
                    life: 3000,
                });
            }
        }
    }

    const listarPrecos = useCallback(async () => {
        if (typeof sku !== null && sku instanceof Object) {
            if (sku.precosku_set instanceof Array && sku.precosku_set.length > 0) {
                showLoading();
                const response = await axiosGet(`/produtos/tabela-preco-itens/?sku=${sku.id}`);
                hideLoading();

                if (response.status === 200) {
                    setDados(response.data.results);
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showWarning({
                    summary: "Erro :(",
                    detail: "Este produto não possui preço cadastrado.",
                    life: 3000,
                });
            }
        }
    }, [sku, showError, showWarning, showLoading, hideLoading]);

    const listarPlanoRecebimentos = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet(
            "/financeiro/planos-recebimentos?query={id,descricao,minimo_parcelas,maximo_parcelas,taxa_alvo,vigencia_inicial,vigencia_final}&limit=300"
        );
        hideLoading();

        if (resposta.status === 200 || resposta.status === 201) setPlanos(resposta.data.results);
    }, [showLoading, hideLoading, setPlanos]);

    const gerarParcelas = useCallback(() => {
        if (typeof plano === "number") {
            const _plano = planos.find((item) => item.id === plano);
            const _parcelas = [];

            for (let i = _plano.minimo_parcelas; i <= _plano.maximo_parcelas; i++) {
                _parcelas.push({ value: i, label: `${i} parcela${i > 1 ? "s" : ""}` });
            }

            setParcelas(_parcelas);
        }
    }, [plano]);

    useEffect(() => {
        listarPrecos();
    }, [listarPrecos]);

    useEffect(() => {
        listarPlanoRecebimentos();
    }, [listarPlanoRecebimentos]);

    useEffect(() => {
        gerarParcelas();
    }, [gerarParcelas]);

    return (
        <PageBase>
            <h3>Consultar preços</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="codigo">Código: </label>
                        <InputText
                            id="codigo"
                            name="codigo"
                            value={formik.values.codigo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.codigo })}
                        />
                        {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}{" "}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="categoriasku__id">Categoria: *</label>
                        <MakoDropdownCategoriasHierarquicas
                            id="categoriasku__id"
                            name="categoriasku__id"
                            categoriaTituloSelecionavel
                            apenasTitulo
                            onChange={formik.handleChange}
                            value={formik.values.categoriasku__id}
                            className={classNames({ "p-invalid": formik.errors.categoriasku__id })}
                        />
                        {formik.errors.categoriasku__id && (
                            <small className="p-error">{formik.errors.categoriasku__id}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="descricao_reduzida">Descrição: </label>
                        <InputText
                            id="descricao_reduzida"
                            name="descricao_reduzida"
                            value={formik.values.descricao_reduzida}
                            onChange={formik.handleChange}
                        />
                        {formik.errors.descricao_reduzida && (
                            <small className="p-error">{formik.errors.descricao_reduzida}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="item__marca">Marca:</label>
                        <MakoAutoComplete
                            id="item__marca"
                            name="item__marca"
                            placeholder="Digite para pesquisar... (min 3 caracteres)"
                            minCaracteresBusca={3}
                            key="id"
                            field="nome"
                            urlSearch="/produtos/marcas/?search="
                            value={formik.values.item__marca}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.item__marca })}
                        />
                        {formik.errors.item__marca && <small className="p-error">{formik.errors.item__marca}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="plano_recebimento">Plano de recebimento: </label>
                        <Dropdown
                            id="plano_recebimento"
                            name="plano_recebimento"
                            value={plano}
                            options={planos}
                            onChange={(e) => setPlano(e.target.value)}
                            optionLabel="descricao"
                            optionValue="id"
                            placeholder="Selecione um plano de recebimento"
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="categoria">Parcelas: </label>
                        <Dropdown
                            id="parcelas"
                            name="parcelas"
                            optionValue="value"
                            optionLabel="label"
                            value={parcela}
                            options={parcelas}
                            onChange={(e) => setParcela(e.target.value)}
                            placeholder="Selecione parcelas"
                        />
                    </div>
                </div>
                {message && <small className="p-error">{message}</small>}
                <div className="p-grid p-col-12 p-md-6 p-mt-2">
                    <Button
                        label="Pesquisar"
                        icon="pi pi-search"
                        type="button"
                        onClick={formik.handleSubmit}
                        className="p-button-info p-mr-2"
                    />
                    <Button
                        type="reset"
                        icon="pi pi-trash"
                        label="Limpar"
                        onClick={() => formik.resetForm()}
                        className="p-button-warning p-mr-2"
                    />
                </div>
            </form>
            <MakoListagem titulo="Preços calculados" colunas={colunas} dadosLocal={dados} />
        </PageBase>
    );
};
