import { useContext } from "react";

import LoadingContext from "@/contexts/loadingContext";

const useLoading = () => {
    const { loading, showLoading, hideLoading } = useContext(LoadingContext);

    return { loading, showLoading, hideLoading };
};

export default useLoading;
