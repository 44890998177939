import React, { useCallback, useEffect, useImperativeHandle, useState, forwardRef } from "react";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { axiosGet } from "@/services/http";
import useToast from "@/hooks/useToast";

const Listagem = ({ titulo, urlPesquisa, dados, colunas, cabecalho, mensagemTabelaVazia, tabelaConfig, fazerBusca = true }, ref) => {
    const [loading, setLoading] = useState(false);
    const [nodes, setNodes] = useState([]);
    const { showError } = useToast();

    const buscarDados = useCallback(async () => {
        if (urlPesquisa && fazerBusca) {
            setLoading(true);
            const { status, data } = await axiosGet(urlPesquisa);
            setLoading(false);
            if (status === 200) {
                setNodes(data);
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos listar as suas informações",
                    life: 3000,
                });
            }
        }
    }, [urlPesquisa, fazerBusca, showError]);

    useImperativeHandle(ref, () => ({ buscarDados }));

    useEffect(() => {
        if (dados) {
            setNodes(dados);
        } else {
            buscarDados();
        }
    }, [dados, buscarDados]);

    const templatePaginacao = {
        layout: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 100, value: 100 },
                { label: 200, value: 200 },
            ];

            return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />;
        },
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: "var(--text-color)", userSelect: "none" }}>
                    Exibindo {options.first} a {options.last} de {options.totalRecords}
                </span>
            );
        },
    };

    const colunasDinamicas = colunas.map((col, i) => {
        const { field, header, action = null, ...rest } = col;
        return <Column key={i} field={field} header={header} body={action} {...rest} />;
    });

    const header = cabecalho ? <div className="table-header">{cabecalho}</div> : null;

    return (
        <>
            {titulo && <h5>{titulo}</h5>}
            <div className="treetable-responsive-demo">
                <TreeTable
                    header={header}
                    value={nodes}
                    loading={loading}
                    paginatorTemplate={templatePaginacao}
                    paginatorPosition="top"
                    emptyMessage={mensagemTabelaVazia || "Nenhum registro encontrado."}
                    className={`p-datatable-sm mako-table`}
                    {...tabelaConfig}
                >
                    {colunasDinamicas}
                </TreeTable>
            </div>
        </>
    );
};

export const MakoListagemHierarquica = forwardRef(Listagem);
