import React, { useRef } from "react";
import { Button } from "primereact/button";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { BotaoDelete } from "@/components/BotaoDelete";
import MakoListagem from "@/components/MakoListagem";

import permissoes from "@/assets/constants/permissoes";

import useClearRefs from "@/hooks/useClearRefs";

import { FormVinculoCompetenciaMovEstoqueModal } from "./form";

export const VinculoEmpresaCompetenciaMovEstoquePage = () => {
    const listagemRef = useRef();
    const modalRef = useRef(null);

    useClearRefs(listagemRef, modalRef);

    const painelEsquerdoTabela = (
        <Button
            label="Nova"
            icon="pi pi-plus"
            className="p-button-success p-mr-2"
            onClick={() => modalRef.current?.abrirModal()}
        />
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.ESTOQUE_CADASTRO_VINCULOEMPRESACOMPMOVESTOQUE_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    onClick={() => modalRef.current?.abrirModal(rowData)}
                />
                <MakoControleAcesso
                    permissao={[permissoes.ESTOQUE_CADASTRO_VINCULOEMPRESACOMPMOVESTOQUE_EXCLUIR]}
                    componente={BotaoDelete}
                    url={"/produtos/competencias-movimentacoes-estoques-empresas/"}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Confirma a exclusão do vinculo de competência: <b>{rowData.nome}</b>?
                        </span>
                    }
                    msgToastErroExclusao="A competência não pode ser excluído."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const colunas = [
        { field: "competencia.nome", header: "Nome" },
        { field: "empresa.nome", header: "Empresa" },
        { field: "actions", header: "Ações", style: { width: "10%" }, action: (e) => actionBodyTemplate(e) },
    ];

    const onSuccess = () => {
        listagemRef.current?.buscarDados();
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Vinculo empresa a competência de movimentação de estoque"
                        colunas={colunas}
                        urlPesquisa="/produtos/competencias-movimentacoes-estoques-empresas/"
                        filtarPorEmpresa
                        painelEsquerdo={painelEsquerdoTabela}
                        configTabela={{ lazy: true, paginator: true }}
                    />
                </div>
            </div>
            <FormVinculoCompetenciaMovEstoqueModal ref={modalRef} onSuccess={onSuccess} />
        </div>
    );
};
