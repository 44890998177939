import React, { useImperativeHandle, useState, useEffect, useCallback, useRef, forwardRef } from "react";
import classNames from "classnames";
import { OverlayPanel } from "primereact/overlaypanel";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoInputQuantidadeSku } from "@/components/MakoInputs/MakoInputQuantidadeSku";
import MakoListagem from "@/components/MakoListagem";
import { axiosGet } from "@/services/http";
import { OP_CRUD_DJANGO } from "@/assets/util/persistenciaDjango";
import useLoading from "@/hooks/useLoading";
import useOrdemCompra from "@/hooks/useOrdemCompra";
import useEmpresa from "@/hooks/useEmpresa";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useToast from "@/hooks/useToast";
import { MakoItemNcm } from "@/components/MakoItemNcm";

const ItensOrdemCompra = (props, ref) => {
    const [baseCalculoIPI, setBaseCalculoIPI] = useState(100);
    const [baseCalculoICMS, setBaseCalculoICMS] = useState(100);
    const [ufFornecedorValido, setUfFornecedorValido] = useState(false);
    const [ufEmpresaValido, setUfEmpresaValido] = useState(false);
    const [verificaUF, setVerificaUf] = useState(false);
    const [infoTributariasOF, setInfoTributariaOF] = useState([]);
    const [precoSelecionado, setPrecoSelecionado] = useState(null);
    const [visible, setVisible] = useState(false);
    const [operacoesFiscais, setOperacoesFiscais] = useState([]);
    const [unidadesMedidaCompra, setUnidadesMedidaCompra] = useState([]);
    const [loadindInfoTributarias, setLoadingInfoTributarias] = useState(false);
    const overlayTributosRef = useRef(null);
    const overlayUltimosPrecosRef = useRef(null);
    const { showLoading, hideLoading } = useLoading();
    const { empresaSelecionada } = useEmpresa();
    const { dadosBasicos, fornecedorOC, handleItem } = useOrdemCompra();
    const [formatarDocumento] = useFormatCNPJCPF();
    const { showWarning, showError } = useToast();

    const { setFieldValue, ...formik } = useFormik({
        initialValues: {
            sku: null,
            operacao_fiscal: null,
            unidade_compra: null,
            altura: 0,
            largura: 0,
            comprimento: 0,
            cubagem: 1,
            quantidade: 0,
            quantidade_recebida: 0,
            valor_unitario: 0,
            total_nf_item: 0,
            aliquota_ipi: 0,
            aliquota_icms: 0,
            valor_total_icms_st: 0,
            unitario_outros_valores: 0,
            total_item: 0,
            anotacoes: "",
            _status: OP_CRUD_DJANGO.novo,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                sku: Yup.object()
                    .typeError("Você precisa buscar e selecionar um produto.")
                    .required("Você precisa buscar e selecionar um produto."),
                unidade_compra: Yup.number()
                    .required("Selecione uma unidade de medida.")
                    .typeError("Selecione uma unidade de medida."),
                quantidade: Yup.number()
                    .when("sku", {
                        is: (val) => val && val.movimenta_cubagem === true,
                        then: Yup.number().min(0.001, "O campo 'quantidade' não pode ser ZERO."),
                        otherwise: Yup.number().min(1, "O campo 'quantidade' não pode ser ZERO."),
                    })
                    .required("O campo 'quantidade' não pode ser ZERO."),
            });

            await formSchema.validate(values, { abortEarly: false });

            handleItem(values, values._status);

            resetarForm();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const resetarForm = () => {
        formik.resetForm();
        setUfEmpresaValido(false);
        setUfFornecedorValido(false);
        setVerificaUf(false);
        setInfoTributariaOF([]);
    };

    const abrirModal = (item) => {
        if (item) {
            formik.setValues({
                ...item,
                _status: OP_CRUD_DJANGO.editar,
            });
        }

        setVisible(true);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const listarOperacoesFiscais = useCallback(async () => {
        if (empresaSelecionada) {
            const params = {
                empresa: empresaSelecionada.id,
                tipo_movimento: "E",
            };

            showLoading();
            const resposta = await axiosGet("/fiscal/operacoes-fiscais/", { params });
            hideLoading();

            if (resposta.status === 200) {
                setOperacoesFiscais(resposta.data.results);
            }
        }
    }, [showLoading, hideLoading, empresaSelecionada]);

    useEffect(() => {
        listarOperacoesFiscais();
    }, [listarOperacoesFiscais]);

    const setInformacoesItem = useCallback(() => {
        if (formik.values.sku instanceof Object) {
            const { unidademedidasku_set } = formik.values.sku;

            const unidades = unidademedidasku_set
                .map((und) => {
                    if (und.ativo && und.tipo_mov_und_medida.id === "C") {
                        return {
                            value: und.unidade.id,
                            label: `${und.unidade.nome} (${und.unidade.sigla})`,
                        };
                    }

                    return null;
                })
                .filter(Boolean);

            setUnidadesMedidaCompra(unidades);

            if (unidades.length === 1) {
                setFieldValue("unidade_compra", unidades[0].value);
            }
        }
    }, [formik.values.sku, setFieldValue]);

    useEffect(() => {
        setInformacoesItem();
    }, [setInformacoesItem]);

    const validarUfEmpresa = useCallback(
        (ufDestinoOF) => {
            const enderecoPrincipalEmpresa = empresaSelecionada.enderecoperfil_set.find((e) => e.principal);

            if (enderecoPrincipalEmpresa) {
                setUfEmpresaValido(enderecoPrincipalEmpresa.cidade.estado.id === ufDestinoOF?.id || !ufDestinoOF);
            }
        },
        [empresaSelecionada]
    );

    const validarUfFornecedor = useCallback(
        (ufOrigemOF) => {
            const enderecoPrincipal = fornecedorOC.enderecoperfil_set.find((e) => e.principal);

            if (enderecoPrincipal) {
                setUfFornecedorValido(enderecoPrincipal.cidade.estado.id === ufOrigemOF?.id || !ufOrigemOF);
            }
        },
        [fornecedorOC]
    );

    const formatarInformacoesTributariasOF = (infoTributaria) => {
        let _infosTributaria = [];

        _infosTributaria.push({ descricao: "IPI", valor: infoTributaria.cst_ipi?.codigo });
        _infosTributaria.push({ descricao: "Alíquota IPI", valor: infoTributaria.aliquota_ipi || 0 });
        _infosTributaria.push({ descricao: "Redução BC IPI", valor: infoTributaria.reducao_base_ipi || 0 });
        _infosTributaria.push({ descricao: "UF origem", valor: infoTributaria.uf_origem?.uf || "--" });
        _infosTributaria.push({
            descricao: "Alíquota ICMS UF origem",
            valor: infoTributaria.aliquota_icms_origem || 0,
        });
        _infosTributaria.push({ descricao: "UF destino", valor: infoTributaria.uf_destino?.uf || "--" });
        _infosTributaria.push({
            descricao: "Alíquota ICMS UF destino",
            valor: infoTributaria.aliquota_icms_destino || 0,
        });
        _infosTributaria.push({ descricao: "ICMS", valor: infoTributaria.cst_icms?.codigo });
        _infosTributaria.push({ descricao: "Redução BC ICMS", valor: infoTributaria.reducao_base_icms || 0 });
        _infosTributaria.push({ descricao: "Alíquota BC ST", valor: infoTributaria.reducao_base_st || 0 });
        _infosTributaria.push({
            descricao: "Tributado por ST",
            valor: infoTributaria.tributado_por_st ? "Sim" : "Não",
        });

        setInfoTributariaOF(_infosTributaria);
    };

    const buscarInformacoesTributarias = useCallback(async () => {
        if (formik.values.sku instanceof Object && formik.values.operacao_fiscal) {
            const skuId = formik.values.sku.id;
            const ofId = formik.values.operacao_fiscal;

            setLoadingInfoTributarias(true);
            const { status, data } = await axiosGet(`/fiscal/buscar-informacoes-tributarias-sku/${skuId}/${ofId}/`);
            setLoadingInfoTributarias(false);

            if (status === 200) {
                const infoTributaria = data.results;

                if (infoTributaria) {
                    setFieldValue("aliquota_ipi", infoTributaria.aliquota_ipi || 0);
                    setFieldValue("aliquota_icms", infoTributaria.aliquota_icms_origem || 0);
                    setBaseCalculoIPI(100 - (Number(infoTributaria.reducao_base_ipi) || 0));
                    setBaseCalculoICMS(100 - (Number(infoTributaria.reducao_base_icms) || 0));

                    formatarInformacoesTributariasOF(infoTributaria);

                    setVerificaUf(true);
                    validarUfFornecedor(infoTributaria.uf_origem);
                    validarUfEmpresa(infoTributaria.uf_destino);
                }
            } else if (status === 400 || status === 404) {
                showWarning({
                    summary: "Falhou...",
                    detail: data.msg,
                    life: 4000,
                });
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos listar as informações tributárias do produto.",
                    life: 3000,
                });
            }
        }
    }, [
        formik.values.sku,
        formik.values.operacao_fiscal,
        validarUfEmpresa,
        validarUfFornecedor,
        setFieldValue,
        showWarning,
        showError,
    ]);

    useEffect(() => {
        buscarInformacoesTributarias();
    }, [buscarInformacoesTributarias]);

    const buscarPrecoCompraSKU = useCallback(async () => {
        if (formik.values.sku instanceof Object) {
            const params = {
                fornecedor: fornecedorOC.id,
                sku: formik.values.sku.id,
            };
            const { status, data } = await axiosGet("/produtos/vinculo-produtos-fornecedor/", { params });
            if (status !== 200) {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos buscar o preço de compra do produto.",
                    life: 3000,
                });
            }
            if (data.results.length > 0) {
                const [precoCompra] = data.results;

                setFieldValue("valor_unitario", precoCompra.preco_compra || 0);
            }
        }
    }, [formik.values.sku, fornecedorOC, setFieldValue, showError]);

    useEffect(() => {
        buscarPrecoCompraSKU();
    }, [buscarPrecoCompraSKU]);

    const totalizarItemOrdemCompra = useCallback(() => {
        const total =
            formik.values.quantidade *
            formik.values.cubagem *
            (formik.values.valor_unitario + formik.values.unitario_outros_valores);

        setFieldValue("total_item", total);
    }, [
        formik.values.quantidade,
        formik.values.cubagem,
        formik.values.valor_unitario,
        formik.values.unitario_outros_valores,
        setFieldValue,
    ]);

    useEffect(() => {
        totalizarItemOrdemCompra();
    }, [totalizarItemOrdemCompra]);

    const colunasInfoTributarias = [
        { field: "descricao", header: "Descrição", style: { minWidth: "15vw" } },
        { field: "valor", header: "Valor", style: { minWidth: "5vw" } },
    ];

    const colunasUltimosPrecosCompra = [
        { field: "fornecedor", header: "Fornecedor" },
        { field: "identificacao", header: "CPF / CNPJ", action: (e) => formatarDocumento(e.identificacao) },
        { field: "ordem_compra", header: "Num. OC" },
        { field: "data_ordem_compra", header: "Data pedido OC", dateFormat: "dd/MM/yyyy" },
        { field: "valor_unitario", header: "Valor", money: true },
    ];

    const onChangeDimensao = useCallback(
        (e) => {
            setFieldValue("cubagem", e);
        },
        [setFieldValue]
    );

    return (
        <Dialog
            header="Adicionar item"
            visible={visible}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "70vw", display: "block" }}
            onHide={() => setVisible(false)}
        >
            <form onSubmit={formik.handleSubmit}>
                <MakoBuscaSkuPersonalizada
                    exibeDimensoes
                    categoriaId={dadosBasicos?.categoria_item}
                    statusItem="F"
                    skuValue={formik.values.sku}
                    skuChange={(e) => setFieldValue("sku", e)}
                    skuError={formik.errors.sku}
                    alturaValue={formik.values.altura}
                    onChangeAltura={(e) => setFieldValue("altura", e)}
                    larguraValue={formik.values.largura}
                    onChangeLargura={(e) => setFieldValue("largura", e)}
                    comprimentoValue={formik.values.comprimento}
                    onChangeComprimento={(e) => setFieldValue("comprimento", e)}
                    dimensaoValue={formik.values.cubagem}
                    onChangeDimensao={onChangeDimensao}
                />
                {formik.values.sku && typeof formik.values.sku === "object" ? (
                    <MakoItemNcm ncmItem={formik.values.sku.item.ncm.codigo} />
                ) : null}
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="operacao-fiscal">Operação fiscal</label>
                        <div className="p-inputgroup">
                            <Dropdown
                                id="operacao-fiscal"
                                name="operacao_fiscal"
                                options={operacoesFiscais}
                                optionValue="id"
                                optionLabel="descricao"
                                placeholder="Selecione"
                                emptyMessage="Nenhum registro disponível"
                                emptyFilterMessage="Nenhum registro encontrado"
                                filter
                                filterBy="descricao"
                                showClear
                                showFilterClear
                                optionDisabled={(e) => !e.ativo}
                                value={formik.values.operacao_fiscal}
                                onChange={formik.handleChange}
                            />
                            <OverlayPanel ref={overlayTributosRef}>
                                <MakoListagem
                                    titulo="Informações tributárias"
                                    colunas={colunasInfoTributarias}
                                    dadosLocal={infoTributariasOF}
                                    configTabela={{
                                        scrollable: true,
                                        scrollHeight: "300px",
                                    }}
                                />
                            </OverlayPanel>
                            <Button
                                type="button"
                                icon="pi pi-eye"
                                disabled={!!!formik.values.operacao_fiscal}
                                tooltip="Ver informações tributárias"
                                tooltipOptions={{ position: "top" }}
                                onClick={(e) => overlayTributosRef.current?.toggle(e)}
                            />
                        </div>
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="unidade-compra">Und. de compra *</label>
                        <Dropdown
                            id="unidade-compra"
                            name="unidade_compra"
                            options={unidadesMedidaCompra}
                            placeholder="Selecione"
                            emptyMessage="Nenhum registro disponível"
                            emptyFilterMessage="Nenhum registro encontrado"
                            value={formik.values.unidade_compra}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.unidade_compra })}
                        />
                        {formik.errors.unidade_compra && (
                            <small className="p-error">{formik.errors.unidade_compra}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="quantidade">Quantidade *</label>
                        <MakoInputQuantidadeSku
                            id="quantidade"
                            name="quantidade"
                            permiteFracionario={formik.values.sku?.movimenta_cubagem}
                            value={formik.values.quantidade}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.quantidade })}
                        />
                        {formik.errors.quantidade && <small className="p-error">{formik.errors.quantidade}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="quantidade_recebida">Qtd. recebida</label>
                        <MakoInputQuantidadeSku
                            id="quantidade_recebida"
                            name="quantidade_recebida"
                            disabled
                            permiteFracionario={formik.values.sku?.movimenta_cubagem}
                            value={formik.values.quantidade_recebida}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="vlr-unitario">Valor unitário *</label>
                        <div className="p-inputgroup">
                            <MakoInputMoeda
                                id="vlr-unitario"
                                name="valor_unitario"
                                somentePositivo
                                quatroCasasDecimais
                                valueMoeda={formik.values.valor_unitario}
                                onChangeMoeda={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.valor_unitario })}
                            />
                            <OverlayPanel ref={overlayUltimosPrecosRef}>
                                <MakoListagem
                                    titulo="Últimos preços de compra"
                                    colunas={colunasUltimosPrecosCompra}
                                    limit={5}
                                    urlPesquisa={
                                        formik.values.sku?.id &&
                                        `/compras/ultimos-precos-compra/${formik.values.sku?.id}/`
                                    }
                                    configTabela={{
                                        lazy: true,
                                        selection: precoSelecionado,
                                        onSelectionChange: (e) => setPrecoSelecionado(e.value),
                                    }}
                                />
                            </OverlayPanel>
                            <Button
                                type="button"
                                icon="pi pi-eye"
                                disabled={!(formik.values.sku instanceof Object)}
                                tooltip="Ver últimos preços de compra"
                                tooltipOptions={{ position: "top" }}
                                onClick={(e) => overlayUltimosPrecosRef.current?.toggle(e)}
                            />
                        </div>
                        {formik.errors.valor_unitario && (
                            <small className="p-error">{formik.errors.valor_unitario}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="outros-valores">Outros valores (un)</label>
                        <MakoInputMoeda
                            id="outros-valores"
                            name="unitario_outros_valores"
                            somentePositivo
                            quatroCasasDecimais
                            valueMoeda={formik.values.unitario_outros_valores}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="aliquota-ipi">{`% IPI ${
                            baseCalculoIPI !== 100 ? `- BC ${baseCalculoIPI} %` : ""
                        }`}</label>
                        <InputNumber
                            id="aliquota-ipi"
                            name="aliquota_ipi"
                            mode="decimal"
                            suffix=" %"
                            disabled={loadindInfoTributarias}
                            placeholder={loadindInfoTributarias ? "Buscando alíquota..." : null}
                            min={0}
                            max={100}
                            value={formik.values.aliquota_ipi}
                            onValueChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="aliquota-icms">{`% ICMS ${
                            baseCalculoICMS !== 100 ? `- BC ${baseCalculoICMS} %` : ""
                        }`}</label>
                        <InputNumber
                            id="aliquota-icms"
                            name="aliquota_icms"
                            mode="decimal"
                            suffix=" %"
                            disabled={loadindInfoTributarias}
                            placeholder={loadindInfoTributarias ? "Buscando alíquota..." : null}
                            min={0}
                            max={100}
                            value={formik.values.aliquota_icms}
                            onValueChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-icms-st">Valor ICMS/ST</label>
                        <MakoInputMoeda
                            id="valor-icms-st"
                            name="valor_total_icms_st"
                            somentePositivo
                            valueMoeda={formik.values.valor_total_icms_st}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="total_item">Total do item</label>
                        <MakoInputMoeda
                            id="total_item"
                            name="total_item"
                            disabled
                            valueMoeda={formik.values.total_item}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="anotacoes">Anotações</label>
                        <InputTextarea
                            id="anotacoes"
                            name="anotacoes"
                            rows={5}
                            autoComplete="off"
                            autoResize
                            value={formik.values.anotacoes}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <p>
                    <b>* Campos obrigatórios.</b>
                </p>
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button
                            type="submit"
                            disabled={!(!formik.values.operacao_fiscal || (ufFornecedorValido && ufEmpresaValido))}
                            icon="pi pi-save"
                            label="Gravar"
                            className="p-mr-2"
                        />
                        <Button
                            label="Limpar"
                            type="reset"
                            icon="pi pi-trash"
                            className="p-button-warning p-mr-2"
                            onClick={() => resetarForm()}
                        />
                    </div>
                </div>
            </form>
            <div>
                {!ufEmpresaValido && verificaUF && (
                    <small className="p-error">
                        O estado de <strong>DESTINO</strong> da operação fiscal está incorreto.
                    </small>
                )}
            </div>
            <div>
                {!ufFornecedorValido && verificaUF && (
                    <small className="p-error">
                        O estado de <strong>ORIGEM</strong> da operação fiscal está incorreto.
                    </small>
                )}
            </div>
        </Dialog>
    );
};

export const ModalItensOrdemCompra = forwardRef(ItensOrdemCompra);
