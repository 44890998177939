import React, { useEffect, useState, useCallback } from "react";

import { useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { Dropdown } from "primereact/dropdown";
import { Button } from 'primereact/button';

import { axiosGet, axiosPost, axiosPut } from "@/services/http";

import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

export const VinculoCentroEstocagemResultadoForm = () => {
    const [estocagens, setEstocagens] = useState([]);
    const [resultados, setResultados] = useState([]);
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();

    const history = useHistory();

    const { state } = useLocation();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            id: null,
            centro_estocagem: null,
            centro_resultado: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                centro_estocagem: Yup.number()
                    .required("O campo 'centro de estocagem' é obrigatório.")
                    .typeError("Informe um 'centro de estocagem' válido"),
                centro_resultado: Yup.number()
                    .required("O campo 'centro de estocagem' é obrigatório.")
                    .typeError("Informe um 'centro de estocagem' válido")
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (values.id === null) {
                showLoading();

                const response = await axiosPost("/produtos/vinculo-centros-estocagem-resultados/", values);

                if (response.status === 201) {
                    showSuccess({
                        summary: "Sucesso, :)",
                        detail: "Vinculo criado com sucesso",
                        life: 3000,
                    })

                    history.push("/produto/cadastros/vinculo-estocagem-resultado");
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar sua requisição.",
                        life: 3000,
                    })
                }

                hideLoading();

            } else {
                const response = await axiosPut(`/produtos/vinculo-centros-estocagem-resultados/${values.id}/`, values);

                if (response.status === 200) {
                    showSuccess({
                        summary: "Sucesso, :)",
                        detail: "Vinculo atualizado com sucesso",
                        life: 3000,
                    })
                    history.push("/produto/cadastros/vinculo-estocagem-resultado");

                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar sua requisição.",
                        life: 3000,
                    })
                }

                hideLoading();
            }

        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const fetchCentroEstocagem = useCallback(async () => {
        showLoading();

        const response = await axiosGet("/produtos/centros-estocagem/");

        hideLoading();

        if (response.status === 200) {
            setEstocagens(response.data.results);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos listar os centros de estocagem.",
                life: 3000,
            })
        }


    }, [showError, hideLoading, showLoading, setEstocagens])

    const fetchCentroResultado = useCallback(async () => {
        showLoading();

        const response = await axiosGet("/plano-operacional/centros-resultados/");

        hideLoading();

        if (response.status === 200) {
            setResultados(response.data.results);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos listar os centros de resultado.",
                life: 3000,
            })
        }


    }, [showError, hideLoading, showLoading, setResultados])

    const handleReset = () => {
        formik.resetForm()
    }

    useEffect(() => {
        if (state != null && state.id != null) {
            setValues({
                id: state.id,
                centro_estocagem: state.centro_estocagem.id,
                centro_resultado: state.centro_resultado.id,
            });
        }
    }, [state, setValues])

    useEffect(() => {
        fetchCentroEstocagem();
        fetchCentroResultado();
    }, [fetchCentroResultado, fetchCentroEstocagem])

    return (
        <div className="card">
            <h5>Vincular produto centro de resultado a um centro de estocagem:</h5>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-6">
                        <label htmlFor="centro_estocagem">Centro de estocagem: *</label>
                        <Dropdown
                            id="centro_estocagem"
                            name="centro_estocagem"
                            options={estocagens}
                            optionValue="id"
                            optionLabel="nome"
                            filter
                            filterBy="nome"
                            placeholder="Selecione um centro de estocagem..."
                            value={formik.values.centro_estocagem}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.centro_estocagem })}
                        />
                        {formik.errors.centro_estocagem && (
                            <small className="p-error">{formik.errors.centro_estocagem}</small>
                        )}
                    </div>

                    <div className="p-field p-col-6">
                        <label htmlFor="centro_resultado">Centro de resultado: *</label>
                        <Dropdown
                            id="centro_resultado"
                            name="centro_resultado"
                            options={resultados}
                            optionValue="id"
                            optionLabel="nome"
                            filter
                            filterBy="nome"
                            placeholder="Selecione um centro de resultado..."
                            value={formik.values.centro_resultado}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.centro_resultado })}
                        />
                        {formik.errors.centro_resultado && <small className="p-error">{formik.errors.centro_resultado}</small>}
                    </div>
                </div>
                <p>
                    <b>* Campos obrigatórios</b>
                </p>
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button
                            type="submit"
                            icon="pi pi-check"
                            label="Gravar"
                            className="p-mr-2 p-mb-2"
                        />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            className="p-button-warning p-mr-2 p-mb-2"
                            onClick={handleReset}
                        />
                        <Button
                            type="reset"
                            label="Cancelar"
                            className="p-button-danger p-mb-2"
                            onClick={() => history.goBack()}
                        />
                    </div>
                </div>
            </form>
        </div>
    )
}