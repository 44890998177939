import React, { useCallback } from "react";

import { Dropdown } from "../../Dropdown";
import { TIPO_CENTRO_ESTOCAGEM } from "@/assets/constants/estoque";

const url = "/produtos/estoques-empresas/";

export const MakoDropdownEstoques = ({ empresaId, apenasEstoqueConsolidado = false, ...props }) => {
    const estoqueTemplate = (option) => {
        const { nome, endereco } = option;
        const { cidade } = endereco;
        return (
            <span>{`${nome} (${endereco.logradouro}, ${endereco.numero} - ${cidade.nome}/${cidade.estado.uf})`}</span>
        );
    };

    const centroEstocagemTemplate = (option) => {
        const tipoCE = TIPO_CENTRO_ESTOCAGEM.find((el) => el.value === option.tipo);
        return <span>{`${option.nome} [${tipoCE ? tipoCE.label : "Desconhecido"}]`}</span>;
    };

    const centroEstocagemSelecionadoTemplate = (option, props) => {
        if (option) return centroEstocagemTemplate(option);
        return <span>{props.placeholder}</span>;
    };

    const aposBuscar = useCallback(
        (data) => {
            if (!apenasEstoqueConsolidado) return data;
            return data.map((item) => {
                item.centroestocagem_set = item.centroestocagem_set.filter((c) => c.tipo === "C");
                return item;
            });
        },
        [apenasEstoqueConsolidado]
    );

    return (
        <Dropdown
            url={empresaId ? `${url}?empresa__id=${empresaId}` : url}
            {...props}
            optionLabel="nome"
            optionGroupLabel="nome"
            optionGroupChildren="centroestocagem_set"
            optionGroupTemplate={estoqueTemplate}
            itemTemplate={centroEstocagemTemplate}
            valueTemplate={centroEstocagemSelecionadoTemplate}
            aposBuscar={aposBuscar}
        />
    );
};
