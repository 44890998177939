import React from "react";

import { ProdutoUnidadeMedidaFormBase } from "./formBase";

export const ProdutoUnidadeMedidaForm = (props) => {
    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <ProdutoUnidadeMedidaFormBase
                        closeDialog={props?.closeDialog || null}
                        unidade={props?.unidade || false}
                        unidadeMedida={props.location?.state?.unidadeMedida}
                    />
                </div>
            </div>
        </div>
    );
};
