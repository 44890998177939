import React, { useCallback, useEffect, useMemo, useState } from 'react';

import MakoListagem from '@/components/MakoListagem';

import { formatarCasasDecimais } from '@/assets/util/util';

export const VisualizarEntrega = ({ venda_id, handleSelection, getSelecionados }) => {
    const [selecionados, setSelecionados] = useState(null);
    const [items, setItems] = useState([]);

    const colunas = [
        { 
            selectionMode: "multiple", 
            style: { width: "5%" } 
        },
        {
            field: "sku",
            style: { width: "8%" },
            header: "Código",
            action: ({ sku }) => sku.id
        },
        {
            field: "sku",
            header: "Descrição",
            action: ({ sku }) => sku.descricao_reduzida
        },
        {
            field: "sku",
            style: { width: "8%" },
            header: "Un",
            align: "center",
            action: ({ sku }) => sku.unidade_padrao.sigla
        },
        {
            field: "quantidade",
            style: { width: "5%" },
            header: "Qtd.",
        },
        {
            field: "data_previsao",
            style: { width: "10%" },
            header: "Data previsão",
            align: "center",
            dateFormat: "dd/MM/yyyy"
        },
    ];

    const verifiySelection = (values, type = "new") => {
        if(type === "new") {
            if(handleSelection(values)) {
                setSelecionados(prev => prev === null ? [values] : [...prev, values]);
            }
        } else {
            handleSelection(values, type);

            setSelecionados(prev => prev.filter(items => items.id !== values.id))
        }
    }

    const aposPesquisar = useMemo(() => {
        return (data) => {
            setItems(data);
            return data
        }
    }, [setItems])

    const preencherSelecionados = useCallback(() => {
        const response = getSelecionados();
        if(response) {
            let newData = [];
            response.map(item_sele => {
                let aux = items.find(item => item.id === item_sele.id);
                if(aux && venda_id == aux?.venda_id) newData.push(aux);
            })
            if(newData.length > 0) setSelecionados(newData);
        }
    }, [getSelecionados, venda_id, items, setSelecionados])

    const painelEsquerdo = useMemo(() => {
        const total = items?.length ? items.reduce((total, item) => total + formatarCasasDecimais(item.quantidade), 0) : 0;
        return <b>Qtd. para ser entregue: {total}</b>
    }, [items])

    useEffect(() => {
        preencherSelecionados()
    }, [preencherSelecionados])

    return (
        <MakoListagem 
            titulo="Itens da entrega"
            colunas={colunas}
            painelEsquerdo={painelEsquerdo}
            aposPesquisar={aposPesquisar}
            fazerBusca={!!venda_id}
            urlPesquisa={`/vendas/entregas-previsao/?item_venda__venda=${venda_id}&datahora_entrega__isnull=true&retirar=false`}
            configTabela={{
                selectionMode: "multiple",
                selection: selecionados,
                onRowSelect: (e) => verifiySelection({ ...e.data }),
                onRowUnselect: (e) => verifiySelection(e.data, "rmv")
            }}
        />
    )
}