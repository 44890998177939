import React, { useEffect, useRef, useState, useCallback } from "react";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { axiosGet, axiosPatch } from "@/services/http";
import { MultiSelect } from "primereact/multiselect";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import useClearRefs from "@/hooks/useClearRefs";

const url = "/pessoas/papeis-perfis/";

export const PermissoesForm = ({ state: permissaoPerfil }) => {
    const [permissao, setPermissao] = useState([]);
    const [botaoAtivo, setBotaoAtivo] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [listaPermissoes, setListaPermissoes] = useState([]);
    const [permissoesAdd, setPermissoesAdd] = useState([]);
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const listagemRef = useRef(null);

    useClearRefs(listagemRef);

    const listarPermissoesAdd = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/pessoas/permissoes-perfis/?limit=10000&query={id, descricao}");
        hideLoading();

        if (resposta.status === 200) {
            setPermissoesAdd(resposta.data.results);
        }
    }, [showLoading, hideLoading]);

    const listarPermissoesUsuario = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet(`/pessoas/papeis-perfis/?limit=10000&id=${permissaoPerfil.id}`);
        hideLoading();

        if (resposta.status === 200) {
            setListaPermissoes(resposta.data.results[0].permissoes);
        }
    }, [showLoading, hideLoading, permissaoPerfil]);

    async function handleAdicionar() {
        try {
            const body = {
                ...permissaoPerfil,
                permissoes: { add: permissao },
            };

            showLoading();
            const resposta = await axiosPatch(`${url}${permissaoPerfil.id}/`, body);
            hideLoading();
            if (resposta.status === 201 || resposta.status === 200) {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Permissão adicionada com sucesso!",
                    life: 1500,
                });

                setPermissao(null);
                listarPermissoesUsuario();
                listagemRef.current?.buscarDados();
            } else {
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
            }
        } catch (error) {
           showError({
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 1500,
            });
            hideLoading();
        }
    }

    const removePermissao = async () => {
        const body = {
            ...permissaoPerfil,
            permissoes: { remove: permissao },
        };

        showLoading();
        const resposta = await axiosPatch(`${url}${permissaoPerfil.id}/`, body);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204 || resposta.status === 201) {
            showSuccess({
                summary: "Sucesso",
                detail: "Permissão removida com sucesso!",
                life: 3000,
            });

            setPermissao(null);
            listarPermissoesUsuario();
            listagemRef.current?.buscarDados();
        } else {
            showError({
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
        setDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_USUARIOS_PAPEISPERFIS_EDITAR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Remover permissão"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const confirmarDelete = (permissao) => {
        setPermissao([permissao.id]);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const selecionarPermissao = (value) => {
        setPermissao(value);
        setBotaoAtivo(true);
    };

    useEffect(() => {
        listarPermissoesUsuario();
        listarPermissoesAdd();
    }, [listarPermissoesAdd, listarPermissoesUsuario]);

    const colunas = [
        { field: "chave", header: "Chave" },
        {
            field: "action",
            header: "Ações",
            className: "p-text-center",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="p-fluid p-formgrid p-grid p-formgroup-inline">
                    <div className="p-field p-col-12 p-md-5">
                        <label htmlFor="permissao">Permissão</label>
                        <MultiSelect
                            id="permissao"
                            name="permissao"
                            placeholder="Selecione uma permissão"
                            onChange={(e) => selecionarPermissao(e.value)}
                            value={permissao}
                            filter
                            filterBy="descricao"
                            showClear
                            optionLabel="descricao"
                            optionValue="id"
                            options={permissoesAdd}
                            autoComplete="off"
                            autoFocus
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2 p-mt-auto">
                        <Button
                            label="Adicionar"
                            icon="pi pi-plus"
                            className="p-button-success"
                            onClick={handleAdicionar}
                            disabled={botaoAtivo ? false : true}
                        />
                    </div>
                </div>
                <MakoListagem
                    ref={listagemRef}
                    colunas={colunas}
                    dadosLocal={listaPermissoes}
                    responsiva
                    configTabela={{
                        paginator: true,
                    }}
                />
                <ConfirmDialog
                    visible={deleteDialog}
                    onHide={esconderDeleteDialog}
                    header="Confirmação"
                    message={<span>{"Deseja realmente remover a permissão? "}</span>}
                    icon="pi pi-info-circle p-mr-3"
                    accept={removePermissao}
                    acceptLabel="Sim"
                    acceptClassName="p-button-danger"
                    reject={esconderDeleteDialog}
                    rejectLabel="Não"
                />
            </div>
        </div>
    );
};
