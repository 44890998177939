import React, { useCallback, useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { OPERACAO_NOTA_FISCAL_CHOICE } from "@/assets/constants/constants";
import { axiosGet, axiosPost } from "@/services/http";
import permissoes from "@/assets/constants/permissoes";
import useVenda from "@/hooks/useVenda";
import { Dropdown } from "@/components/Dropdown";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const DocFiscalForm = () => {
    const [exibirItens, setExibirItens] = useState(false);
    const [exibirOperacoes, setExibirOperacoes] = useState(false);
    const [itensSelecionados, setItensSelecionados] = useState([]);
    const [operacao, setOperacao] = useState(null);
    const [operacaoFiscal, setOperacaoFiscal] = useState(null);
    const [listaItens, setListaItens] = useState([]);
    const [listaOperacoes, setListaOperacoes] = useState([]);
    const modeloNfRef = useRef(null);
    const { dadosBasicos, parcelasVenda } = useVenda();
    const { empresaSelecionada, empresaSelecionadaId } = useEmpresa();
    const { showSuccess, showWarning, showError } = useToast();
    const history = useHistory();
    const listagemRef = useRef();

    async function enviarNota() {
        try {
            if ((await empresaSelecionada?.enderecoperfil_set?.length) > 0) {
                const { cidade } = await empresaSelecionada.enderecoperfil_set[0];
                const body = {
                    modelo: modeloNfRef.current,
                    operacao_fiscal: operacaoFiscal,
                    natureza_operacao: operacao,
                    venda: dadosBasicos.id,
                    transferencia: null,
                    dados_basicos: {
                        codigo_uf: cidade.estado?.codigo_uf,
                        emitente: dadosBasicos.empresa.id,
                        destinatario: dadosBasicos.cliente.id || null,
                        requer_identificacao: dadosBasicos.cliente?.id ? true : false,
                        indicador_final:
                            parcelasVenda?.lenght > 0 ? (parcelasVenda[0].quantidade_parcelas === 1 ? 1 : 2) : 1,
                        municipio_ocorrencia: cidade.id,
                        transportador: null,
                        indicador_pagamento: 1,
                        formato_impressao: 0,
                    },
                    itens: itensSelecionados.map((item) => {
                        return {
                            ...item,
                            unidade: item.unidade_venda.id,
                            sku: item.sku.id,
                            valor_total: item.valor_total_av,
                            valor_desconto: item.valor_desconto_unitario,
                            valor_frete: 0,
                            valor_seguro: 0,
                            quantidade: item.quantidade_nf,
                            outras_despesas: 0,
                            municipio_issqn: cidade.id,
                            municipio_incidencia: cidade.id,
                        };
                    }),
                    parcelas: parcelasVenda,
                };

                const resposta = await axiosPost("/fiscal/inserir-nota-fiscal/", body);

                if (resposta.status === 200) {
                    listagemRef.current?.buscarDados();
                    showSuccess({
                        summary: "Sucesso!",
                        detail: "Nota fiscal incluida com sucesso!",
                        life: 3000,
                    });
                    setExibirOperacoes(false);
                    setExibirItens(false);
                } else if (resposta.status === 400) {
                    showWarning({
                        summary: "Falha ao inserir",
                        detail: `Não foi possível inserir a nota fiscal, verifique as informações da operação fiscal e tente novamente (${resposta.data.erro}.)`,
                        life: 5000,
                    });
                } else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não foi possível inserir a nota fiscal.",
                        life: 3000,
                    });
                }
            }
        } catch {
            showWarning({
                summary: "Cadastro Incompleto!",
                detail: "A empresa atual não possui todas as informações registradas, verifique o cadastro e tente novamente.",
                life: 5000,
            });
        }
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_VENDA_DOCFISCALVENDA_CONSULTAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Editar"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/fiscal/nota-saida/emissao-nota",
                            state: { notaFiscal: rowData },
                        })
                    }
                />
            </div>
        );
    };

    const verificaEmissao = (chave) => {
        if (chave) return chave;
        else return "NÃO TRANSMITIDO";
    };

    const verificaProtocolo = (protocolo) => {
        if (protocolo) return protocolo;
        else return "-";
    };

    const colunasNota = [
        {
            field: "numero",
            header: "Número",
            filter: true,
            style: { minWidth: "50px" },
        },
        {
            field: "serie",
            header: "Série",
            filter: true,
            style: { minWidth: "50px" },
        },
        {
            field: "modelo",
            header: "Modelo",
            filter: true,
            style: { minWidth: "50px" },
        },
        {
            field: "chave_nf",
            header: "Chave",
            action: (e) => verificaEmissao(e.chave_nf),
            filter: true,
            align: "left",
            style: { minWidth: "400px" },
        },
        {
            field: "protocolo",
            header: "Protocolo",
            action: (e) => verificaProtocolo(e.protocolo),
            filter: true,
            style: { minWidth: "200px" },
        },
        {
            field: "valor_total_nf",
            header: "Total Nf",
            filter: true,
            align: "right",
            style: { minWidth: "120px" },
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "15%" },
        },
    ];

    const editarQuantidade = (options, quantidade, aux) => {
        options.editorCallback(quantidade);
        setListaItens(
            listaItens.map((item) => {
                if (item.aux_quantidade === aux) item.quantidade_nf = quantidade;
                return item;
            })
        );
    };

    const textEditor = (options) => {
        return (
            <InputText
                type="text"
                value={options.value}
                onChange={(e) => editarQuantidade(options, e.target.value, options.rowData.aux_quantidade)}
            />
        );
    };

    const colunasItens = [
        { selectionMode: "multiple", headerStyle: { width: "3em" } },
        { field: "sku.codigo", header: "Código" },
        { field: "sku.descricao_reduzida", header: "Item" },
        {
            field: "quantidade",
            header: "Quant",
            decimal: true,
        },
        {
            field: "quantidade_nf",
            header: "Qtd. NF",
            decimal: true,
            style: { width: "20%" },
            editor: (options) => textEditor(options),
        },
    ];

    const listarItensVenda = useCallback(async () => {
        const resposta = await axiosGet(`/vendas/itens-vendas/?venda=${dadosBasicos?.id}`);
        if (resposta.status === 200)
            setListaItens(
                resposta.data.results.map((item, index) => ({
                    ...item,
                    aux_quantidade: index + 1,
                    quantidade_nf: item.quantidade,
                }))
            );
    }, [dadosBasicos]);

    const listarOperacoes = useCallback(async () => {
        if (!empresaSelecionadaId) return;

        const params = {
            empresa: empresaSelecionadaId,
            tipo_movimento: "S",
            limit: 1000,
            ativo: true,
        };

        const { status, data } = await axiosGet("/fiscal/operacoes-fiscais/", { params });

        if (status === 200) {
            const { enderecoperfil_set } = empresaSelecionada;
            const enderecoEmpresa = enderecoperfil_set.map(({ principal, ...endereco }) => {
                if (principal === true) return endereco;
            });
            const enderecoCliente = dadosBasicos.cliente.enderecoperfil_set.map(({ principal, ...endereco }) => {
                if (principal === true) return endereco;
            });

            if (enderecoEmpresa[0] && enderecoCliente[0]) {
                setListaOperacoes(
                    data.results.map(({ esfera, ...operacao }) => {
                        if (
                            esfera === "D"
                                ? enderecoEmpresa[0].cidade.estado.id === enderecoCliente[0].cidade.estado.id
                                : enderecoEmpresa[0].cidade.estado.id !== enderecoCliente[0].cidade.estado.id
                        )
                            return operacao;
                    })
                );
            } else {
                setListaOperacoes(data.results);
            }
        }
    }, [empresaSelecionadaId, empresaSelecionada, dadosBasicos.cliente.enderecoperfil_set]);

    useEffect(() => {
        listarOperacoes();
        listarItensVenda();
    }, [listarItensVenda, listarOperacoes]);

    return (
        <>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-4">
                    <Dropdown
                        id="operacao"
                        name="operacao"
                        options={OPERACAO_NOTA_FISCAL_CHOICE}
                        value={operacao}
                        onChange={(e) => setOperacao(e.value)}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <MakoControleAcesso
                        permissao={[permissoes.VENDAS_VENDA_DOCFISCALVENDA_INCLUIR]}
                        componente={Button}
                        type="button"
                        label="Gerar NFe"
                        disabled={!operacao}
                        onClick={() => {
                            setExibirItens(true);
                            modeloNfRef.current = "55";
                        }}
                        className="p-mr-2"
                    />
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <MakoControleAcesso
                        permissao={[permissoes.VENDAS_VENDA_DOCFISCALVENDA_INCLUIR]}
                        componente={Button}
                        type="button"
                        label="Gerar NFCe"
                        className="p-mr-2"
                        onClick={() => {
                            setExibirItens(true);
                            modeloNfRef.current = "65";
                        }}
                        disabled={!operacao}
                    />
                </div>
            </div>
            <MakoListagem
                ref={listagemRef}
                titulo="Documentos fiscais da venda"
                urlPesquisa={`/fiscal/notas-fiscais?venda=${dadosBasicos ? dadosBasicos.id : null}`}
                colunas={colunasNota}
                configTabela={{
                    lazy: true,
                    paginator: true,
                }}
            />
            <Dialog
                header={"Selecionando itens para emissão do documento fiscal"}
                visible={exibirItens}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "70vw", display: "block" }}
                onHide={() => setExibirItens(false)}
            >
                <MakoListagem
                    titulo="Itens do Orçamento"
                    colunas={colunasItens}
                    dadosLocal={listaItens}
                    configTabela={{
                        editMode: "cell",
                        selectionMode: "multiple",
                        selection: itensSelecionados,
                        onSelectionChange: (e) => setItensSelecionados(e.value),
                    }}
                />
                <Button
                    type="button"
                    label="Gerar nota"
                    className="p-mt-4"
                    disabled={!itensSelecionados}
                    onClick={() => setExibirOperacoes(true)}
                />
            </Dialog>
            <Dialog
                header={"Selecione uma operação fiscal"}
                visible={exibirOperacoes}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "35vw", display: "block" }}
                onHide={() => setExibirOperacoes(false)}
            >
                <form>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <Dropdown
                                id="operacao_fiscal"
                                name="operacao_fiscal"
                                options={listaOperacoes}
                                optionValue="id"
                                optionLabel="descricao"
                                value={operacaoFiscal}
                                onChange={(e) => setOperacaoFiscal(e.value)}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid p-justify-center">
                        <div className="p-field p-col-12 p-md-4">
                            <Button
                                type="button"
                                label="Concluir"
                                className="p-mr-2 p-mb-2"
                                disabled={!operacaoFiscal}
                                onClick={() => enviarNota()}
                            />
                        </div>
                    </div>
                </form>
            </Dialog>
        </>
    );
};
