import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { axiosDelete, axiosGet } from "@/services/http";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";

const url = "/produtos/unidades-medida-fc/";

export const ProdutoFatorConversaoPage = () => {
    const [redirecionar, setRedirecionar] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [unidadeMedidaFc, setUnidadeMedida] = useState(null);
    const [dados, setDados] = useState([]);

    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const listagemRef = useRef(null);

    const confirmarDelete = (unidadeMedidaFc) => {
        setUnidadeMedida(unidadeMedidaFc);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const deleteFator = async () => {
        showLoading();
        const resposta = await axiosDelete(`${url}${unidadeMedidaFc.id}/`);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Unidade de medida deletada com sucesso!",
                life: 3000,
            });

            listagemRef.current?.buscarDados();
            listarConversoes();
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
        setDeleteDialog(false);
    };

    async function listarConversoes() {
        showLoading();
        const resposta = await axiosGet(url);
        hideLoading();

        if (resposta.status === 200) {
            let listaAux = resposta.data.results;
            resposta.data.results.forEach((value) => {
                value.calculo = `1 ${value.unidade.sigla} ${value.operacao} ${value.fator_conversao} = 1 ${value.unidade_correspondente.sigla}`;
            });
            setDados(listaAux);
        }
    }
    useEffect(() => {
        listarConversoes();
    }, []);

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_UNIDADEMEDIDAFC_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de unidade de medida"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        setRedirecionar({
                            pathname: "/produto/produto/unidade-medida-fc/form",
                            state: { unidadeMedidaFc: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_UNIDADEMEDIDAFC_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Deletar unidade de medida"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.PRODUTO_PRODUTO_UNIDADEMEDIDAFC_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setRedirecionar("/produto/produto/unidade-medida-fc/form")}
            />
        </>
    );

    const colunasUnidade = [
        { field: "id", header: "Código", style: { width: "12%" } },
        { field: "unidade.nome", header: "Unidade" },
        { field: "operacao", header: "Operação", style: { width: "10%" } },
        { field: "fator_conversao", header: "Fator de conversão" },
        { field: "unidade_correspondente.nome", header: "Unidade correspondente" },
        { field: "calculo", header: "Cálculo" },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    return redirecionar ? (
        <Redirect to={redirecionar} />
    ) : (
        <>
            <div className="p-grid">
                <Toast ref={toastRef} />
                <div className="p-col-12">
                    {dados ? (
                        <MakoListagem
                            ref={listagemRef}
                            colunas={colunasUnidade}
                            painelEsquerdo={cabecalhoTabela}
                            dadosLocal={dados}
                            responsiva
                            configTabela={{
                                paginator: true,
                            }}
                        />
                    ) : null}
                    <ConfirmDialog
                        visible={deleteDialog}
                        onHide={esconderDeleteDialog}
                        header="Confirmação"
                        message={
                            unidadeMedidaFc && (
                                <span>
                                    {"Deseja realmente excluir o fator de conversão "}
                                    <b>{unidadeMedidaFc.nome}</b>?
                                </span>
                            )
                        }
                        icon="pi pi-info-circle p-mr-3"
                        accept={deleteFator}
                        acceptLabel="Sim"
                        acceptClassName="p-button-danger"
                        reject={esconderDeleteDialog}
                        rejectLabel="Não"
                    />
                </div>
            </div>
        </>
    );
};
