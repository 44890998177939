import React, { useCallback, useEffect, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import axios from "axios";
import * as Yup from "yup";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { MakoDropdownCompetenciaFinanceira } from "@/components/MakoInputs/MakoDropdownCompetenciaFinanceira";
import { MakoDropdownCompetenciaEstoque } from "@/components/MakoInputs/MakoDropdownCompetenciaEstoque";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { MakoInputPerfil } from "@/components/MakoInputs/MakoInputPerfil";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";

import { STATUS_ENTRADA } from "@/assets/constants/compras";
import { dataToStr } from "@/assets/util/datas";
import { axiosGet } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import { totalizadorFiltrosAplicados } from "@/assets/util/util";

const INITIAL_VALUES = {
    datahora_entrada__lte: null,
    datahora_entrada__gte: null,
    tipo_entrada: null,
    categoria_titulo: null,
    competencia_estoque: null,
    competencia_financeira: null,
    entradaxml__cpf_cnpj_emitente: null,
    entradaxml__numero_nf: null,
    itementrada__sku: null,
    status: null,
    usuario_cadastro: null,
    entradaxml__xml: null,
    usuario_finalizacao: null,
    usuario_validacao: null,
    ordem_compra_principal: null,
    conhecimento_transporte: null,
    itementrada__sku__codigo: null,
};

export const FiltroAvancadoEntrada = ({ onConfirm, onCancel, baseUrl }) => {
    const [tiposEntrada, setTiposEntrada] = useState([]);
    const { showLoading, hideLoading } = useLoading();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            ...INITIAL_VALUES,
            status: "P",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                datahora_entrada__lte: Yup.date().nullable().typeError("Informe um 'data inicial' válida"),
                datahora_entrada__gte: Yup.date().when("datahora_entrada__lte", {
                    is: (val) => !!val,
                    then: Yup.date()
                        .min(
                            values.datahora_entrada__lte || new Date(),
                            "O campo 'data final' não pode ser anterior a inicial"
                        )
                        .typeError("Informe uma 'data final' válida"),
                    otherwise: Yup.date().nullable(),
                }),
                tipo_entrada: Yup.number().nullable().typeError("Informe um 'tipo entrada' válido"),
                categoria_titulo: Yup.number().nullable().typeError("Informe uma 'categoria titulo' válida"),
                competencia_estoque: Yup.number().nullable().typeError("Informe uma 'competência de estoque' válida"),
                competencia_financeira: Yup.number()
                    .nullable()
                    .typeError("Informe uma 'competência de financeira' válida"),
                entradaxml__numero_nf: Yup.string().nullable().typeError("Informe um 'numero nf' válido"),
                entradaxml__xml: Yup.string().nullable().typeError("Informe um 'xml' válido"),
                entradaxml__cpf_cnpj_emitente: Yup.string().nullable().typeError("Informe um 'CPF/CNPJ' válido"),
                usuario_cadastro: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number().nullable(),
                    })
                    .typeError("Informe um 'usuario cadastro' válido"),
                usuario_finalizacao: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number().nullable(),
                    })
                    .typeError("Informe um 'usuario finalizacao' válido"),
                usuario_validacao: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number().nullable(),
                    })
                    .typeError("Informe um 'usuario validacao' válido"),
                ordem_compra_principal: Yup.string().nullable().typeError("Informe um 'codigo' válido"),
                conhecimento_transporte: Yup.number()
                    .nullable()
                    .typeError("Informe um 'conhecimento de transporte' válido"),
                itementrada__sku__codigo: Yup.string().nullable().typeError("Informe um 'EAN' válido"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            let params = [];

            Object.keys(values).forEach((key) => {
                if (values[key] !== null) {
                    if (key === "datahora_entrada__lte")
                        params.push(`${key}=${dataToStr(values[key], "yyyy-MM-dd hh:mm:ss")}`);
                    if (key === "datahora_entrada__gte")
                        params.push(`${key}=${dataToStr(values[key], "yyyy-MM-dd hh:mm:ss")}`);
                    if (key === "usuario_cadastro") params.push(`${key}=${values[key].id}`);
                    if (key === "usuario_finalizacao") params.push(`${key}=${values[key].id}`);
                    if (key === "usuario_validacao") params.push(`${key}=${values[key].id}`);
                    params.push(`${key}=${values[key]}`);
                }
            });
            if (params.length > 0) {
                const url = baseUrl + "?" + params.join("&");

                if (typeof onConfirm === "function")
                    onConfirm(url, totalizadorFiltrosAplicados(INITIAL_VALUES, values));
            } else {
                if (typeof onConfirm === "function")
                    onConfirm(baseUrl, totalizadorFiltrosAplicados(INITIAL_VALUES, values));
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const listarTiposEntrada = useCallback(async (cancelToken) => {
        const { status, data } = await axiosGet("/compras/tipos-entradas/", { cancelToken });
        return { status, data: data?.results };
    }, []);

    const fetchDados = useCallback(
        async (cancelToken) => {
            showLoading();
            const [responseEntrada] = await Promise.all([listarTiposEntrada(cancelToken)]);
            hideLoading();
            if (responseEntrada.status) setTiposEntrada(responseEntrada.data);
        },
        [showLoading, hideLoading, listarTiposEntrada]
    );

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        fetchDados(cancelToken.token);
        return function clear() {
            cancelToken.cancel();
        };
    }, [fetchDados]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="datahora_entrada__lte">Período inicial</label>
                    <MakoCalendar
                        id="datahora_entrada__lte"
                        name="datahora_entrada__lte"
                        valueCalendar={formik.values.datahora_entrada__lte}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.datahora_entrada__lte })}
                    />
                    {formik.errors.datahora_entrada__lte && (
                        <small className="p-error">{formik.errors.datahora_entrada__lte}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="datahora_entrada__lte">Período final</label>
                    <MakoCalendar
                        id="datahora_entrada__gte"
                        name="datahora_entrada__gte"
                        valueCalendar={formik.values.datahora_entrada__gte}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.datahora_entrada__gte })}
                    />
                    {formik.errors.datahora_entrada__gte && (
                        <small className="p-error">{formik.errors.datahora_entrada__gte}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="tipo_entrada">Tipo de entrada</label>
                    <Dropdown
                        id="tipo_entrada"
                        name="tipo_entrada"
                        options={tiposEntrada}
                        optionValue="id"
                        optionLabel="descricao"
                        value={formik.values.tipo_entrada}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.tipo_entrada })}
                    />
                    {formik.errors.tipo_entrada && <small className="p-error">{formik.errors.tipo_entrada}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="categoria_titulo">Categoria título</label>
                    <MakoDropdownCategoriasHierarquicas
                        id="categoria_titulo"
                        name="categoria_titulo"
                        apenasTitulo
                        categoriaTituloSelecionavel
                        value={formik.values.categoria_titulo}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.categoria_titulo })}
                    />
                    {formik.errors.categoria_titulo && (
                        <small className="p-error">{formik.errors.categoria_titulo}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="competencia-estoque">Competência de estoque</label>
                    <MakoDropdownCompetenciaEstoque
                        id="competencia-estoque"
                        name="competencia_estoque"
                        habilitarInativas
                        value={formik.values.competencia_estoque}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.competencia_estoque })}
                    />
                    {formik.errors.competencia_estoque && (
                        <small className="p-error">{formik.errors.competencia_estoque}</small>
                    )}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="competencia_financeira">Competência financeira</label>
                    <MakoDropdownCompetenciaFinanceira
                        id="competencia_financeira"
                        name="competencia_financeira"
                        habilitarInativas
                        value={formik.values.competencia_financeira}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.competencia_financeira })}
                    />
                    {formik.errors.competencia_financeira && (
                        <small className="p-error">{formik.errors.competencia_financeira}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="entradaxml__numero_nf">Número NF</label>
                    <InputText
                        id="entradaxml__numero_nf"
                        name="entradaxml__numero_nf"
                        value={formik.values.entradaxml__numero_nf__contains}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.entradaxml__numero_nf__contains })}
                    />
                    {formik.errors.entradaxml__numero_nf__contains && (
                        <small className="p-error">{formik.errors.entradaxml__numero_nf__contains}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="entradaxml__xml">Chave XML</label>
                    <InputText
                        id="entradaxml__xml"
                        name="entradaxml__xml"
                        value={formik.values.entradaxml__xml}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.entradaxml__xml })}
                    />
                    {formik.errors.entradaxml__xml && (
                        <small className="p-error">{formik.errors.entradaxml__xml}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="entradaxml__cpf_cnpj_emitente">CPF / CNPJ do emitente NF</label>
                    <InputText
                        id="entradaxml__cpf_cnpj_emitente"
                        name="entradaxml__cpf_cnpj_emitente"
                        value={formik.values.entradaxml__cpf_cnpj_emitente}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.entradaxml__cpf_cnpj_emitente })}
                    />
                    {formik.errors.entradaxml__cpf_cnpj_emitente && (
                        <small className="p-error">{formik.errors.entradaxml__cpf_cnpj_emitente}</small>
                    )}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="ordem_compra_principal">Nº de ordem de compra</label>
                    <InputText
                        id="ordem_compra_principal"
                        name="ordem_compra_principal"
                        value={formik.values.ordem_compra_principal}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.ordem_compra_principal })}
                    />
                    {formik.errors.ordem_compra_principal && (
                        <small className="p-error">{formik.errors.ordem_compra_principal}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="conhecimento_transporte">Conhecimento de transporte</label>
                    <InputText
                        id="conhecimento_transporte"
                        name="conhecimento_transporte"
                        value={formik.values.conhecimento_transporte}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.conhecimento_transporte })}
                    />
                    {formik.errors.conhecimento_transporte && (
                        <small className="p-error">{formik.errors.conhecimento_transporte}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="itementrada__sku__codigo">EAN produto</label>
                    <InputText
                        id="itementrada__sku__codigo"
                        name="itementrada__sku__codigo"
                        value={formik.values.itementrada__sku__codigo}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.itementrada__sku__codigo })}
                    />
                    {formik.errors.itementrada__sku__codigo && (
                        <small className="p-error">{formik.errors.itementrada__sku__codigo}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="status">Status</label>
                    <Dropdown
                        id="status"
                        name="status"
                        options={STATUS_ENTRADA}
                        optionValue="value"
                        optionLabel="label"
                        value={formik.values.status}
                        onChange={formik.handleChange}
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-4">
                    <label htmlFor="usuario_cadastro">Usuário cadastro</label>
                    <MakoInputPerfil
                        id="usuario_cadastro"
                        name="usuario_cadastro"
                        value={formik.values.usuario_cadastro}
                        somenteAtivo
                        query="id,nome,identificacao"
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.usuario_cadastro })}
                    />
                    {formik.errors.usuario_cadastro && (
                        <small className="p-error">{formik.errors.usuario_cadastro}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <label htmlFor="usuario_finalizacao">Usuário finalização</label>
                    <MakoInputPerfil
                        id="usuario_finalizacao"
                        name="usuario_finalizacao"
                        value={formik.values.usuario_finalizacao}
                        somenteAtivo
                        query="id,nome,identificacao"
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.usuario_finalizacao })}
                    />
                    {formik.errors.usuario_finalizacao && (
                        <small className="p-error">{formik.errors.usuario_finalizacao}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <label htmlFor="usuario_validacao">Usuario validação</label>
                    <MakoInputPerfil
                        id="usuario_validacao"
                        name="usuario_validacao"
                        value={formik.values.usuario_validacao}
                        somenteAtivo
                        query="id,nome,identificacao"
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.usuario_validacao })}
                    />
                    {formik.errors.usuario_validacao && (
                        <small className="p-error">{formik.errors.usuario_validacao}</small>
                    )}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <label htmlFor="itementrada__sku">Contém o produto</label>
                    <MakoBuscaSkuPersonalizada
                        skuValue={formik.values.itementrada__sku}
                        skuChange={(e) => setFieldValue("itementrada__sku", e)}
                        skuError={formik.errors.itementrada__sku}
                    />
                    {formik.errors.itementrada__sku && (
                        <small className="p-error">{formik.errors.itementrada__sku}</small>
                    )}
                </div>
            </div>
            <div className="p-grid p-justify-end p-mt-2">
                <Button type="submit" icon="pi pi-filter" label="Filtrar" className="p-mr-2" />
                <Button
                    type="reset"
                    icon="pi pi-trash"
                    label="Limpar"
                    onClick={formik.resetForm}
                    className="p-button-warning p-mr-2"
                />
                <Button type="reset" label="Cancelar" onClick={onCancel} className="p-button-danger p-mr-3" />
            </div>
        </form>
    );
};
