import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";

import { PageBase } from "@/components/PageBase";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { BotaoDelete } from "@/components/BotaoDelete";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { Dropdown } from "@/components/Dropdown";
import { Tag } from "primereact/tag";

export const FinanceiroTemplateRateioPage = () => {
    const [linhasExpandidas, setLinhasExpandidas] = useState(null);
    const listagemRef = useRef(null);
    const history = useHistory();

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_TEMPLATERATEIO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    onClick={() =>
                        history.push({
                            pathname: "/financeiro/financeiro/templates-rateios/form",
                            state: rowData,
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_TEMPLATERATEIO_EXCLUIR]}
                    componente={BotaoDelete}
                    url="/financeiro/templates-rateios"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir a regra de rateio <b>{rowData.descricao}</b>?
                        </span>
                    }
                    classNames="p-mb-1"
                    msgToastErroExclusao="A regra de rateio não pode ser excluída."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const situacaoBodyTemplate = (rowData) => {
        if (rowData.valida) return <Tag value="VÁLIDA" />;
        return <Tag severity="danger" value="INVÁLIDA" />;
    };

    const statusBodyTemplate = (rowData) => {
        if (rowData.ativo) return <Tag severity="success" value="ATIVA" />;
        return <Tag severity="danger" value="DESATIVADA" />;
    };

    const statusFiltroTemplate = (options) => {
        return (
            <Dropdown
                placeholder="Selecione"
                showClear
                value={options.value}
                options={[
                    { value: true, label: "Ativo" },
                    { value: false, label: "Desativado" },
                ]}
                onChange={(e) => options.filterCallback(e.value)}
            />
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_FINANCEIRO_TEMPLATEDETALHERATEIO_INCLUIR]}
                componente={Button}
                label="Nova"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/financeiro/financeiro/templates-rateios/form")}
            />
        </>
    );

    const colunas = [
        { expander: true, style: { width: "5rem" } },
        { field: "descricao", header: "Descrição", filter: true },
        {
            field: "ativo",
            header: "Status",
            filter: true,
            filterElement: statusFiltroTemplate,
            action: (e) => statusBodyTemplate(e),
        },
        { field: "valida", header: "Situação", action: (e) => situacaoBodyTemplate(e) },
        { field: "action", header: "Ações", action: (e) => actionBodyTemplate(e), style: { width: "10%" } },
    ];

    const rowExpansionTemplate = (data) => {
        const detalheBodyTemplate = (rowData) => {
            if (rowData.plano_conta_contabil) return <span>{rowData.plano_conta_contabil.descricao}</span>;
            if (rowData.plano_conta_financeira) return <span>{rowData.plano_conta_financeira.descricao}</span>;
            if (rowData.centro_resultado) return <span>{rowData.centro_resultado.descricao}</span>;
            if (rowData.fase_projeto) return <span>{rowData.fase_projeto.descricao}</span>;
            return <span>N/A</span>;
        };

        const colunas = [
            { field: "tipo_detalhe.descricao", header: "Tipo de detalhe" },
            { field: "detalhe", header: "Detalhe", action: (e) => detalheBodyTemplate(e) },
            {
                field: "percentual",
                header: "Percentual",
                decimal: true,
                action: (e) => <span>{`${e.percentual} %`}</span>,
            },
        ];

        return (
            <div className="p-p-3">
                <MakoListagem colunas={colunas} dadosLocal={data.templatedetalherateio_set} />
            </div>
        );
    };

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Regras de Rateios"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa="/financeiro/templates-rateios/"
                filtarPorEmpresa
                naoBuscarSemEmpresa
                filtros={{
                    id: { value: "", matchMode: "equals" },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    ativo: { value: true, matchMode: "equals" },
                }}
                configTabela={{
                    paginator: true,
                    lazy: true,
                    expandedRows: linhasExpandidas,
                    onRowToggle: (e) => setLinhasExpandidas(e.data),
                    rowExpansionTemplate,
                }}
            />
        </PageBase>
    );
};
