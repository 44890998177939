import React, { useCallback, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Menu } from "primereact/menu";
import { Tag } from "primereact/tag";

import { ModalFiltroAvancadoPessoas } from "./ModalFiltroAvancado";
import { ModalMotivoBaixa } from "./ModalMotivoBaixa";
import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { axiosPatch } from "@/services/http";
import MakoListagem from "@/components/MakoListagem";
import useFormatCnpjCpf from "@/hooks/useFomatCNPJCPF";
import useClearRefs from "@/hooks/useClearRefs";
import useLoading from "@/hooks/useLoading";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";
import { key_filtros } from "@/assets/constants/filtros";
import { useLocalFiltro } from "@/hooks/useLocalFiltro";
import { CodigoFiltroTemplate, DropdownFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";

const BASE_URL = "/pessoas/perfis?query={id,nome,identificacao,tipo_pessoa,ativo}";

export const PessoasPage = () => {
    const [filtros, setFiltro, removerFiltro, filtroString] = useLocalFiltro(key_filtros.GESTAO_PESSOAS_AVANCADO);
    const [totalizadorFiltros, setTotalizadorFiltros] = useState(0);
    const [perfil, setPerfil] = useState(null);
    const [ativarModal, setAtivarModal] = useState(false);
    const [url, setUrl] = useState(() => {
        if (filtros) return `${BASE_URL}&${filtroString}`;
        return BASE_URL;
    });

    const history = useHistory();
    const listagemRef = useRef(null);
    const modalFiltroAvancadoRef = useRef(null);
    const motivobaixaRef = useRef(null);
    const menuRef = useRef(null);

    useClearRefs(listagemRef, modalFiltroAvancadoRef, motivobaixaRef, menuRef);

    const [formatDocumento, limparDocumento] = useFormatCnpjCpf();
    const { showLoading, hideLoading } = useLoading();
    const { showError, showSuccess } = useToast();
    const { empresaSelecionadaId } = useEmpresa();

    const TIPO_PESSOA = [
        { value: "PF", label: "Pessoa Física" },
        { value: "PJ", label: "Pessoa Jurídica" },
        { value: "PE", label: "Pessoa Estrangeira" },
    ];

    const STATUS_PERFIL = [
        { value: true, label: "Ativo" },
        { value: false, label: "Desativado" },
    ];

    const itens = [
        {
            label: "Escolha o tipo de pessoa para cadastrar",
            items: [
                {
                    label: "Pessoa Física",
                    command: () =>
                        history.push({
                            pathname: "/gestao/pessoas/pessoas/form",
                            state: { tipo_form: "Pessoa Física" },
                        }),
                },
                {
                    label: "Pessoa Jurídica",
                    command: () =>
                        history.push({
                            pathname: "/gestao/pessoas/pessoas/form",
                            state: { tipo_form: "Pessoa Jurídica" },
                        }),
                },
                {
                    label: "Pessoa Estrangeira",
                    command: () =>
                        history.push({
                            pathname: "/gestao/pessoas/pessoas/form",
                            state: { tipo_form: "Pessoa Estrangeira" },
                        }),
                },
            ],
        },
    ];

    const formatarDadosPessoa = useCallback(
        (data) => {
            let pessoas = [];
            data.forEach((pessoa) => {
                if (pessoa.tipo_pessoa === "PF") {
                    pessoas.push({
                        id: pessoa.id,
                        tipo_pessoa: "Pessoa Física",
                        nome: pessoa.nome,
                        identificacao: formatDocumento(pessoa.identificacao),
                        ativo: pessoa.ativo,
                    });
                } else if (pessoa.tipo_pessoa === "PJ") {
                    pessoas.push({
                        id: pessoa.id,
                        tipo_pessoa: "Pessoa Jurídica",
                        nome: pessoa.nome,
                        identificacao: formatDocumento(pessoa.identificacao),
                        ativo: pessoa.ativo,
                    });
                } else if (pessoa.tipo_pessoa === "PE") {
                    pessoas.push({
                        id: pessoa.id,
                        tipo_pessoa: "Pessoa Estrangeira",
                        nome: pessoa.nome,
                        identificacao: pessoa.identificacao,
                        ativo: pessoa.ativo,
                    });
                }
            });

            return pessoas;
        },
        [formatDocumento]
    );

    const clearFiltros = () => {
        setUrl(BASE_URL);
        removerFiltro();
        setTotalizadorFiltros(0);
        modalFiltroAvancadoRef.current?.limparFiltros();
    };

    const painelEsquerdoTabela = (
        <>
            <Menu model={itens} popup ref={menuRef} id="popup_menu" />
            <Button
                label="Nova"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                aria-controls="popup_menu"
                aria-haspopup
                onClick={(e) => menuRef.current.toggle(e)}
            />
            <Button
                label="Filtro Avançado"
                icon="pi pi-filter"
                className="p-button-help p-mr-2"
                badge={totalizadorFiltros > 0 ? totalizadorFiltros : null}
                onClick={() => modalFiltroAvancadoRef.current?.abrirModal()}
            />
            <Button
                label="Limpar filtros"
                icon="pi pi-trash"
                className="p-button-warning p-mr-2"
                disabled={!filtros}
                onClick={clearFiltros}
            />
        </>
    );

    const desativarPerfil = async (perfil) => {
        setPerfil(perfil);
        motivobaixaRef.current?.abrirModal();
    };

    const ativarPerfil = async () => {
        showLoading();
        const json = await axiosPatch(`/pessoas/perfis/${perfil.id}/`, { ativo: true });
        hideLoading();

        if (json.status === 200) {
            showSuccess({
                summary: "Sucesso!",
                detail: "Perfil ativado com sucesso!",
                life: 3000,
            });

            listagemRef.current.buscarDados();
            setPerfil(null);
        } else {
            showError({
                summary: "Erro :(",
                detail: "A sua requisição não pode ser concluída.",
                life: 3000,
            });
        }
    };

    const onToggleAtivarModal = () => {
        setAtivarModal(!ativarModal);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_PESSOAS_PESSOAS_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    onClick={() => editarCadastroPessoa(rowData)}
                />
                {rowData.ativo ? (
                    <MakoControleAcesso
                        permissao={[permissoes.GESTAO_PESSOAS_PESSOAS_DESATIVAR]}
                        componente={Button}
                        icon="pi pi-ban"
                        className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                        onClick={() => desativarPerfil(rowData)}
                        disabled={empresaSelecionadaId === rowData.id}
                    />
                ) : (
                    <MakoControleAcesso
                        permissao={[permissoes.GESTAO_PESSOAS_PESSOAS_ATIVAR]}
                        componente={Button}
                        icon="pi pi-ban"
                        className="p-button-rounded p-button-success p-mb-1"
                        onClick={() => {
                            setPerfil(rowData);
                            onToggleAtivarModal();
                        }}
                        disabled={empresaSelecionadaId === rowData.id}
                    />
                )}
            </div>
        );
    };

    const statusBodyTemplate = (rowData) => {
        if (rowData.ativo) return <Tag severity="success" value="ATIVO" />;
        return <Tag severity="danger" value="DESATIVADO" />;
    };

    const IdentificacaoFiltroTemplate = (options) => {
        return (
            <InputText
                value={options.value}
                keyfilter="num"
                onChange={(e) => options.filterCallback(limparDocumento(e.target.value))}
            />
        );
    };

    const TipoPessoaFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: TIPO_PESSOA,
                }}
                options={options}
            />
        );
    };

    const StatusFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: STATUS_PERFIL,
                }}
                options={options}
            />
        );
    };

    const editarCadastroPessoa = (pessoa) => {
        if (pessoa.tipo_pessoa === "Pessoa Física") {
            history.push({
                pathname: "/gestao/pessoas/pessoas/form",
                state: {
                    tipo_form: "Pessoa Física",
                    idPerfil: pessoa.id,
                },
            });
        } else if (pessoa.tipo_pessoa === "Pessoa Jurídica") {
            history.push({
                pathname: "/gestao/pessoas/pessoas/form",
                state: {
                    tipo_form: "Pessoa Jurídica",
                    idPerfil: pessoa.id,
                },
            });
        } else if (pessoa.tipo_pessoa === "Pessoa Estrangeira") {
            history.push({
                pathname: "/gestao/pessoas/pessoas/form",
                state: {
                    tipo_form: "Pessoa Estrangeira",
                    idPerfil: pessoa.id,
                },
            });
        }
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            filter: true,
            filterElement: CodigoFiltroTemplate,
            style: { width: "10%" },
        },
        {
            field: "nome",
            header: "Nome / Razão Social",
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "tipo_pessoa",
            header: "Tipo",
            style: { width: "12%" },
            filter: true,
            filterElement: TipoPessoaFiltroTemplate,
        },
        {
            field: "identificacao",
            header: "CPF / CNPJ / Passaporte",
            filter: true,
            filterElement: IdentificacaoFiltroTemplate,
            style: { width: "18%" },
        },
        {
            field: "ativo",
            header: "Status",
            style: { width: "10%" },
            filter: true,
            filterElement: StatusFiltroTemplate,
            action: (e) => statusBodyTemplate(e),
        },
        { field: "action", header: "Ações", style: { width: "10%" }, action: (e) => actionBodyTemplate(e) },
    ];

    const onFilter = (e, contador) => {
        setUrl(e);
        setTotalizadorFiltros(contador);
    };

    const onFinish = () => {
        listagemRef?.current.buscarDados();
    };

    return (
        <div className="p-grid">
            <ModalFiltroAvancadoPessoas
                ref={modalFiltroAvancadoRef}
                onFilter={onFilter}
                baseUrl={BASE_URL}
                filtros={filtros}
                setFiltro={setFiltro}
                removerFiltro={removerFiltro}
            />
            <ModalMotivoBaixa ref={motivobaixaRef} perfil={perfil} onFinish={onFinish} />
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Cadastro de Pessoas"
                        colunas={colunas}
                        painelEsquerdo={painelEsquerdoTabela}
                        urlPesquisa={url}
                        aposPesquisar={formatarDadosPessoa}
                        keyFiltrosStorage={key_filtros.GESTAO_PESSOAS_TABELA}
                        filtros={{
                            id: { value: null, matchMode: "equals" },
                            nome: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                            },
                            tipo_pessoa: { value: null, matchMode: "equals" },
                            identificacao: { value: null, matchMode: "equals" },
                            ativo: { value: true, matchMode: "equals" },
                        }}
                        configTabela={{
                            paginator: true,
                            lazy: true,
                        }}
                    />
                    <ConfirmDialog
                        visible={ativarModal}
                        onHide={() => setAtivarModal(false)}
                        message={
                            perfil && (
                                <span>
                                    Deseja mesmo ativar o perfil de <b>{perfil.nome}</b>?
                                </span>
                            )
                        }
                        header="Confirmação"
                        icon="pi pi-exclamation-triangle"
                        accept={ativarPerfil}
                        acceptLabel="Confirmar"
                        rejectLabel="Cancelar"
                    />
                </div>
            </div>
        </div>
    );
};
