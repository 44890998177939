import React, { useRef, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { MakoSelecionarEmails } from "@/components/MakoSelecionarEmails";
import { MakoInputFornecedor } from "@/components/MakoInputs/MakoInputFornecedor";
import { MakoInputCheckBox } from "@/components/MakoInputs/MakoInputCheckbox";
import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";
import { MakoInputNcm } from "@/components/MakoInputs/MakoInputNcm";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";

import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import useRelatorio from "@/hooks/useRelatorio";

import { RELATORIO_ESTOQUE_SALDOPRODUTOS } from "@/assets/constants/relatorios";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { dataToStr } from "@/assets/util/datas";

export const RelatorioSaldoProdutos = () => {
    const [visible, setVisible] = useState(false);
    const [emails, setEmails] = useState([]);

    const categoriaRef = useRef();

    const { solicitarRelatorio } = useRelatorio();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            marca: null,
            data_saldo: null,
            custo_entre: null,
            preço_start: null,
            preço_end: null,
            grupo: null,
            fornecedor: null,
            grupo_comissao: null,
            descricao: "",
            destinacao: null,
            ncm: null,
            preco_custo: false,
        },
        onSubmit: handleSubmit,
    });

    const filtrosTemplate = [
        {
            key: "marca",
            label: "Marca",
            path: "nome",
        },
        {
            key: "data_saldo",
            label: "Data saldo",
            type: TIPOS_FILTROS_TEMPLATE.DATE,
        },
        {
            key: "custo_entre",
            label: "Custo entre",
            type: TIPOS_FILTROS_TEMPLATE.DATE,
        },
        {
            key: ["preço_start", "preço_end"],
            label: "Preço base entre",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: "grupo",
            label: "Grupo",
            path: "nome",
        },
        {
            key: "fornecedor",
            label: "Fornecedor",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: "grupo_comissao",
            label: "Grupo comissão",
            path: "descricao",
        },
        {
            key: "descricao",
            label: "Descrição",
        },
        {
            key: "destinacao",
            label: "Destinação",
            path: "nome",
        },
        {
            key: "ncm",
            label: "Ncm",
            path: "codigo",
        },
        {
            key: "preco_custo",
            label: "Preço custo?",
            type: TIPOS_FILTROS_TEMPLATE.BOOLEAN,
        },
    ];

    async function handleSubmit(values = null) {
        try {
            const formSchema = Yup.object().shape({
                marca: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number().required("Informe uma 'marca' válida."),
                    })
                    .typeError("Informe uma 'marca' válida."),
                grupo: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number().required("Informe um 'grupo' válido."),
                    })
                    .typeError("Informe uma 'grupo' válido."),
                fornecedor: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number().required("Informe um 'fornecedor' válida."),
                    })
                    .typeError("Informe um 'fornecedor' válida."),
                grupo_comissao: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number().required("Informe um 'grupo comissão' válido."),
                    })
                    .typeError("Informe um 'grupo comissão' válido"),
                destinacao: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number().required("Informe uma 'destinação' válida."),
                    })
                    .typeError("Informe uma 'destinação' válido."),
                data_saldo: Yup.date()
                    .required("O campo 'data abertura' é obrigatório")
                    .typeError("Seleciona um 'saldo entre' válido."),
                custo_entre: Yup.date().nullable().typeError("Seleciona um 'custo entre' válido."),
                preço_start: Yup.date().nullable().typeError("Seleciona um 'preço inicio' válido."),
                preço_end: Yup.date().nullable().typeError("Seleciona um 'preço end' válido."),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                grupo: dadosValidados.grupo ? categoriaRef.current : null,
            });
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.ncm) dadosValidados.ncm = dadosValidados.ncm.id;
            if (dadosValidados.fornecedor) dadosValidados.fornecedor = dadosValidados.fornecedor.id;
            if (dadosValidados.marca) dadosValidados.marca = dadosValidados.marca.id;
            if (dadosValidados.destinacao) dadosValidados.destinacao = dadosValidados.destinacao.id;
            if (dadosValidados.data_saldo)
                dadosValidados.data_saldo = dataToStr(dadosValidados.data_saldo, "yyyy-MM-dd");
            if (dadosValidados.custo_entre)
                dadosValidados.custo_entre = dataToStr(dadosValidados.custo_entre, "yyyy-MM-dd");
            if (dadosValidados.preço_start)
                dadosValidados.preço_start = dataToStr(dadosValidados.preço_start, "yyyy-MM-dd");
            if (dadosValidados.preço_end) dadosValidados.preço_end = dataToStr(dadosValidados.preço_end, "yyyy-MM-dd");

            let filtros = {};
            if (dadosValidados) {
                Object.keys(dadosValidados).forEach((key) => {
                    if (dadosValidados[key]) filtros[key] = dadosValidados[key];
                });
            }

            solicitarRelatorio({
                chave: RELATORIO_ESTOQUE_SALDOPRODUTOS,
                emails,
                filtros,
                filtros_template: filtrosTemplateAplicados,
            });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const handleConfirm = (emails) => {
        fecharModal();
        if (emails && emails.length > 0) {
            handlePreSubmit(emails);
        }
    };

    const handlePreSubmit = (emails = []) => {
        setEmails(emails);
        formik.handleSubmit();
    };

    const fecharModal = () => {
        setVisible(() => false);
    };

    const limparFiltro = () => {
        formik.resetForm();
    };

    const selecionarCategoria = (e) => {
        categoriaRef.current = e;
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <h5>Filtro para emissão de relatório de saldo de produtos</h5>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-4">
                            <label htmlFor="marca">Marca</label>
                            <MakoAutoComplete
                                id="marca"
                                name="marca"
                                placeholder="Digite para pesquisar... (min 4 caracteres)"
                                minCaracteresBusca={4}
                                key="id"
                                field="nome"
                                urlSearch="/produtos/marcas/?search="
                                value={formik.values.marca}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.marca })}
                            />
                            {formik.errors.marca && <small className="p-error">{formik.errors.marca}</small>}
                        </div>
                        <div className="p-field p-col-2 p-md-2">
                            <label htmlFor="custo_entre">Custo entre</label>
                            <MakoCalendar
                                id="custo_entre"
                                name="custo_entre"
                                valueCalendar={formik.values.custo_entre}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.custo_entre })}
                            />
                            {formik.errors.custo_entre && (
                                <small className="p-error">{formik.errors.custo_entre}</small>
                            )}
                        </div>
                        <div className="p-field p-col-2 p-md-2">
                            <label htmlFor="data_saldo">Saldo entre *</label>
                            <MakoCalendar
                                id="data_saldo"
                                name="data_saldo"
                                valueCalendar={formik.values.data_saldo}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.data_saldo })}
                            />
                            {formik.errors.data_saldo && <small className="p-error">{formik.errors.data_saldo}</small>}
                        </div>
                        <div className="p-field p-col-2 p-md-2">
                            <label htmlFor="preço_start">Preço base entre</label>
                            <MakoCalendar
                                id="preço_start"
                                name="preço_start"
                                valueCalendar={formik.values.preço_start}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.preço_start })}
                            />
                        </div>
                        <div className="p-field p-col-2 p-md-2 p-mt-4" style={{ paddingTop: "5px" }}>
                            <MakoCalendar
                                id="preço_end"
                                name="preço_end"
                                valueCalendar={formik.values.preço_end}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.preço_end })}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-2 p-md-2">
                            <label htmlFor="destinacao">Destinação</label>
                            <Dropdown
                                id="destinacao"
                                name="destinacao"
                                url="/compras/destinacoes-entradas/"
                                value={formik.values.destinacao}
                                onChange={formik.handleChange}
                                filter
                                filterBy="nome"
                                optionLabel="nome"
                                placeholder="Selecione um tipo..."
                                className={classNames({ "p-invalid": formik.errors.destinacao })}
                            />
                        </div>
                        <div className="p-field p-col-2 p-md-2">
                            <label htmlFor="grupo">Grupo</label>
                            <MakoDropdownCategoriasHierarquicas
                                id="grupo"
                                name="grupo"
                                categoriaTituloSelecionavel
                                getCategoriaCompleta={selecionarCategoria}
                                value={formik.values.grupo}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.grupo })}
                            />
                        </div>
                        <div className="p-field p-col-2 p-md-2">
                            <label htmlFor="grupo_comissao">Grupo comissão</label>
                            <Dropdown
                                id="grupo_comissao"
                                name="grupo_comissao"
                                url="/vendas/comissoes-vendas/"
                                value={formik.values.grupo_comissao}
                                onChange={formik.handleChange}
                                filter
                                filterBy="descricao"
                                optionLabel="descricao"
                                optionValue="id"
                                placeholder="Selecione um tipo..."
                                className={classNames({ "p-invalid": formik.errors.grupo_comissao })}
                            />
                        </div>
                        <div className="p-field p-col-6">
                            <label htmlFor="fornecedor">Fornecedor</label>
                            <MakoInputFornecedor
                                id="fornecedor"
                                name="fornecedor"
                                value={formik.values.fornecedor}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.fornecedor })}
                            />
                            {formik.errors.fornecedor && <small className="p-error">{formik.errors.fornecedor}</small>}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="ncm">NCM</label>
                            <MakoInputNcm
                                id="ncm"
                                name="ncm"
                                fieldValue={setFieldValue}
                                valorNcm={formik.values.ncm}
                                className={classNames({ "p-invalid": formik.errors.ncm })}
                            />
                            {formik.errors.ncm && <small className="p-error">{formik.errors.ncm}</small>}
                        </div>
                    </div>
                    <MakoInputCheckBox
                        id="preco_custo"
                        name="preco_custo"
                        checked={formik.values.preco_custo}
                        onChange={formik.handleChange}
                        label="Preço de custo"
                    />
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="descricao">Descrição contém </label>
                            <InputTextarea
                                id="descricao"
                                name="descricao"
                                value={formik.values.descricao}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.descricao })}
                                autoResize
                                rows={3}
                                maxLength={100}
                            />
                            {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                        </div>
                    </div>
                    <div className="p-grid p-col-12 p-md-6 p-mt-2">
                        <Button
                            label="Gerar PDF"
                            icon="pi pi-file-pdf"
                            type="button"
                            onClick={() => handlePreSubmit()}
                            className="p-button-info p-mr-2"
                        />
                        <Button
                            type="button"
                            onClick={() => setVisible(true)}
                            label="Enviar por email"
                            icon="pi pi-envelope"
                            className="p-button-info p-mr-2"
                        />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            onClick={() => limparFiltro()}
                            className="p-button-warning p-mr-2"
                        />
                    </div>
                </div>
            </div>
            <Dialog
                visible={visible}
                blockScroll
                onHide={fecharModal}
                style={{ width: "60vw" }}
                header="Informar emails"
            >
                <MakoSelecionarEmails onCancel={fecharModal} onConfirm={handleConfirm} />
            </Dialog>
        </div>
    );
};
