import React, { useRef, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";

import { PageBase } from "@/components/PageBase";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { BotaoDelete } from "@/components/BotaoDelete";
import permissoes from "@/assets/constants/permissoes";
import useAuth from "@/hooks/useAuth";
import { AtivoFiltroTemplate, DateTimeFiltroTemplate, PerfilFiltroTemplate } from "@/components/MakoFiltrosCabecalho";

export const TokensIntegracaoPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();
    const { verifyPermission, user } = useAuth();

    const urlPesquisa = useMemo(() => {
        const temPermissao = verifyPermission([permissoes.GESTAO_USUARIOS_TOKENINTEGRACAO_CONSULTAR_TERCEIRO]);
        if (temPermissao) {
            return "/integracoes/tokens-usuario/";
        } else {
            return `/integracoes/tokens-usuario/?usuario=${user.id}`;
        }
    }, [verifyPermission, user.id]);

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[
                    permissoes.GESTAO_USUARIOS_TOKENINTEGRACAO_INCLUIR,
                    permissoes.GESTAO_USUARIOS_TOKENINTEGRACAO_INCLUIR_TERCEIRO,
                ]}
                componente={Button}
                exibirDesabilitadoSemPermissao
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2 p-mb-2"
                onClick={() => history.push("/gestao/usuarios/tokens/form")}
            />
        </>
    );

    const usuarioBodyTemplate = (rowData) => {
        return <span>{`${rowData.usuario.nome} (${rowData.usuario.usuario.username})`}</span>;
    };

    const statusBodyTemplate = (rowData) => {
        if (rowData.ativo) return <Tag severity="success" value="ATIVO" />;
        return <Tag severity="danger" value="INATIVO" />;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_USUARIOS_TOKENINTEGRACAO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    onClick={() =>
                        history.push({
                            pathname: "/gestao/usuarios/tokens/form",
                            state: rowData,
                        })
                    }
                />
                <Button
                    icon="pi pi-copy"
                    className="p-button-rounded p-button-info p-mr-2"
                    onClick={() => navigator.clipboard.writeText(rowData.token)}
                />
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_USUARIOS_TOKENINTEGRACAO_EXCLUIR]}
                    componente={BotaoDelete}
                    url="/integracoes/tokens-usuario/"
                    objetoId={rowData.id}
                    classNames="p-mb-1"
                    exigeConfirmacao
                    msgConfirmacao="Deseja mesmo realizar a exclusão do token?"
                    msgToastErroExclusao="O token não pode ser excluído."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const colunas = [
        {
            field: "usuario",
            header: "Usuário",
            action: (e) => usuarioBodyTemplate(e),
            filter: true,
            filterElement: PerfilFiltroTemplate,
        },
        {
            field: "datahora_criacao",
            header: "Data / hora criação",
            dateFormat: "dd/MM/yyyy HH:mm:ss",
            filter: true,
            filterElement: DateTimeFiltroTemplate,
        },
        {
            field: "ativo",
            header: "Status",
            action: (e) => statusBodyTemplate(e),
            filter: true,
            filterElement: AtivoFiltroTemplate,
        },
        { field: "actions", header: "Ações", style: { width: "15%" }, action: (e) => actionBodyTemplate(e) },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Tokens de integração"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={urlPesquisa}
                configTabela={{
                    lazy: true,
                    paginator: true,
                }}
                filtros={{
                    usuario: { value: "", matchMode: "equals" },
                    datahora_criacao: { value: "", matchMode: "equals" },
                    ativo: { value: "", matchMode: "equals" },
                }}
            />
        </PageBase>
    );
};
