import React, { useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { MakoSelecionarEmails } from "@/components/MakoSelecionarEmails";
import { Dropdown } from "@/components/Dropdown";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import useRelatorio from "@/hooks/useRelatorio";

import { gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIO_PRODUTOS_RELACAOPRODUTOS } from "@/assets/constants/relatorios";

export const RelatorioRelacaoProdutos = () => {
    const [visible, setVisible] = useState(false);
    const [emails, setEmails] = useState([]);

    const { solicitarRelatorio } = useRelatorio();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            marca: null,
            estoque: null,
            centro_estocagem: null,
        },
        onSubmit: handleSubmit,
    });

    const filtrosTemplate = [
        {
            key: "centro_estocagem",
            label: "Centro estocagem",
            path: "nome",
        },
        {
            key: "estoque",
            label: "Estoque",
            path: "nome",
        },
        {
            key: "marca",
            label: "Marca",
            path: "nome",
        },
    ];

    async function handleSubmit(values = null) {
        const formSchema = Yup.object().shape({
            centro_estocagem: Yup.object()
                .nullable()
                .shape({
                    id: Yup.number().required("Informe um 'centro estocagem' válido."),
                })
                .typeError("Informe um 'centro estocagem' válido"),
            estoque: Yup.object()
                .nullable()
                .shape({
                    id: Yup.number().required("Informe um 'estoque' válido."),
                })
                .typeError("Informe um 'estoque' válido"),
            marca: Yup.object()
                .nullable()
                .shape({
                    id: Yup.number().required("Informe uma 'marca' válida."),
                })
                .typeError("Informe uma 'marca' válida"),
        });

        let dadosValidados = await formSchema.validate(values, {
            abortEarly: false,
        });

        let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, dadosValidados);
        filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

        if (dadosValidados.centro_estocagem) dadosValidados.centro_estocagem = dadosValidados.centro_estocagem.id;
        if (dadosValidados.estoque) dadosValidados.estoque = dadosValidados.estoque.id;
        if (dadosValidados.marca) dadosValidados.marca = dadosValidados.marca.id;

        let filtros = {};
        if (values) {
            Object.keys(values).forEach((key) => {
                if (values[key]) filtros[key] = values[key];
            });
        }
        solicitarRelatorio({
            chave: RELATORIO_PRODUTOS_RELACAOPRODUTOS,
            emails,
            filtros,
            filtros_template: filtrosTemplateAplicados,
        });
        formik.resetForm();
    }

    const handleConfirm = (emails) => {
        fecharModal();
        if (emails && emails.length > 0) {
            handlePreSubmit(emails);
        }
    };

    const handlePreSubmit = (emails = []) => {
        setEmails(emails);
        formik.handleSubmit();
    };

    const limparFiltro = () => {
        formik.resetForm();
    };

    const fecharModal = () => {
        setVisible(() => false);
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <form onSubmit={formik.handleSubmit}>
                    <div className="card">
                        <h5>Filtro para emissão de relatório de relacao de produtos</h5>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="marca">Marca</label>
                                <Dropdown
                                    id="marca"
                                    name="marca"
                                    placeholder="Selecione uma marca"
                                    filter
                                    filterBy="nome"
                                    url={"/produtos/marcas/"}
                                    optionLabel="nome"
                                    value={formik.values.marca}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.marca })}
                                />
                                {formik.errors.marca && <small className="p-error">{formik.errors.marca}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="estoque">Estoque</label>
                                <Dropdown
                                    id="estoque"
                                    name="estoque"
                                    placeholder="Selecione um estoque"
                                    filter
                                    filterBy="nome"
                                    url={"/produtos/estoques-empresas/"}
                                    optionLabel="nome"
                                    value={formik.values.estoque}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.estoque })}
                                />
                                {formik.errors.estoque && <small className="p-error">{formik.errors.estoque}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="centro_estocagem">Centro estocagem</label>
                                <Dropdown
                                    id="centro_estocagem"
                                    name="centro_estocagem"
                                    placeholder="Selecione um centro estocagem"
                                    filter
                                    filterBy="nome"
                                    url={"/produtos/centros-estocagem/"}
                                    optionLabel="nome"
                                    value={formik.values.centro_estocagem}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.centro_estocagem })}
                                />
                                {formik.errors.centro_estocagem && (
                                    <small className="p-error">{formik.errors.centro_estocagem}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-grid p-col-12 p-md-6 p-mt-2">
                            <Button
                                label="Gerar PDF"
                                icon="pi pi-file-pdf"
                                type="button"
                                onClick={() => handlePreSubmit()}
                                className="p-button-info p-mr-2"
                            />
                            <Button
                                type="button"
                                onClick={() => setVisible(true)}
                                label="Enviar por email"
                                icon="pi pi-envelope"
                                className="p-button-info p-mr-2"
                            />
                            <Button
                                type="reset"
                                icon="pi pi-trash"
                                label="Limpar"
                                onClick={limparFiltro}
                                className="p-button-warning p-mr-2"
                            />
                        </div>
                    </div>
                </form>
            </div>
            <Dialog
                visible={visible}
                blockScroll
                onHide={fecharModal}
                style={{ width: "60vw" }}
                header="Informar emails"
            >
                <MakoSelecionarEmails onCancel={fecharModal} onConfirm={handleConfirm} />
            </Dialog>
        </div>
    );
};
