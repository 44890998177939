import React, { memo, useEffect, useRef, useState, useCallback } from "react";
import classNames from "classnames";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { axiosGet } from "@/services/http";
import { OP_CRUD_DJANGO } from "@/assets/util/persistenciaDjango";

import usePessoa from "@/hooks/usePessoa";
import * as Yup from "yup";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import { Dialog } from "primereact/dialog";
import { PessoasTagsPerfisForm } from "../../Cadastros/TagsPerfis/form";
import useLoadingLocal from "@/hooks/useLoadingLocal";

const TagForm = () => {
    const [listaTags, setListaTags] = useState([]);
    const [exibirCadastro, setExibirCadastro] = useState(false);
    const { tags, handleTag } = usePessoa();
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const toastRef = useRef(null);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            tag: null,
            status: "novo",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                tag: Yup.object().typeError("O campo 'tag' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });
            if (values.status === OP_CRUD_DJANGO.novo && tags.findIndex((t) => t.tag.id === values.tag.id) === -1)
                handleTag(values, OP_CRUD_DJANGO.novo);

            formik.resetForm();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const listarTags = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/pessoas/tags-perfis?tag_crediario=false&limit=1000");
        hideLoading();

        if (resposta.status === 200) {
            setListaTags(resposta.data.results);
        }
    }, [showLoading, hideLoading]);

    useEffect(() => {
        listarTags();
    }, [listarTags]);

    const escondeCadastro = (reloadTags = false) => {
        setExibirCadastro(false);
        if(reloadTags) listarTags();
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Toast ref={toastRef} />
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="tag">Tag *</label>
                        <div className="p-inputgroup">
                            <Dropdown
                                id="tag"
                                name="tag"
                                options={listaTags}
                                loading={loading}
                                optionLabel="descricao"
                                filter
                                filterBy="descricao"
                                value={formik.values.tag}
                                onChange={formik.handleChange}
                                placeholder="Selecione uma tag"
                                className={classNames({ "p-invalid": formik.errors.tag })}
                            />
                            <MakoControleAcesso
                                permissao={[permissoes.GESTAO_CADASTRO_TAGSPERFIL_INCLUIR]}
                                componente={Button}
                                type="button"
                                icon="pi pi-plus"
                                className="p-button-success"
                                tooltip="Cadastrar nova tag"
                                onClick={() => setExibirCadastro(true)}
                            />
                        </div>

                        {formik.errors.tag && <small className="p-error">{formik.errors.tag}</small>}
                    </div>
                </div>
                <p>
                    <b>* Campos obrigatórios</b>
                </p>
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button type="submit" icon="pi pi-check" label="Gravar" className="p-mr-2" />
                    </div>
                </div>
            </form>
            <div className="p-fluid p-formgrid p-grid p-justify-center">
                {tags.map((tag, index) =>
                    tag._status !== "deletar" ? (
                        <SplitButton
                            key={index}
                            label={tag.tag.descricao}
                            model={[
                                {
                                    label: "Deletar",
                                    icon: "pi pi-times",
                                    command: () => handleTag(tag, OP_CRUD_DJANGO.deletar),
                                },
                            ]}
                            className="p-button-warning p-col-12 p-md-2 p-mr-2 p-mb-2"
                        />
                    ) : null
                )}
                <Dialog
                    header={"Cadastrar Tag"}
                    visible={exibirCadastro}
                    breakpoints={{ "960px": "75vw" }}
                    style={{ width: "30vw", display: "block" }}
                    onHide={() => escondeCadastro()}
                >
                    <PessoasTagsPerfisForm modal esconderDialog={escondeCadastro} />
                </Dialog>
            </div>
        </>
    );
};

export default memo(TagForm);
