import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import useLoading from "@/hooks/useLoading";
import { axiosDelete } from "@/services/http";
import { BooleanFiltroTemplate, CodigoFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";

export const TipoEstagioPage = () => {
    const [deleteModal, setDeleteModal] = useState(false);
    const [tipoEstagio, setTipoEstagio] = useState(null);
    const listagemRef = useRef(null);
    const toastRef = useRef(null);
    const history = useHistory(null);
    const { showLoading, hideLoading } = useLoading();

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_CADASTRO_TIPOESTAGIO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/vendas/cadastros/tipos-estagios/form")}
            />
        </>
    );

    const editarTipoEstagio = (tipoEstagio) => {
        history.push({
            pathname: "/vendas/cadastros/tipos-estagios/form",
            state: {
                tipoEstagio,
            },
        });
    };

    const onToggleDeleteModal = () => {
        setDeleteModal(!deleteModal);
    };

    const deleteEstagioVenda = async () => {
        showLoading();
        const json = await axiosDelete(`/vendas/tipos-estagios-vendas/${tipoEstagio.id}/`);
        hideLoading();

        if (json.status === 204) {
            toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Tipo de estágio de venda deletado com sucesso!",
                life: 3000,
            });

            listagemRef.current.buscarDados();
            setTipoEstagio(null);
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_TIPOESTAGIO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    tooltip="Alterar cadastro."
                    tooltipOptions={{ position: "left" }}
                    onClick={() => editarTipoEstagio(rowData)}
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_TIPOESTAGIO_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2"
                    tooltip="Excluir cadastro."
                    tooltipOptions={{ position: "left" }}
                    onClick={() => {
                        onToggleDeleteModal();
                        setTipoEstagio(rowData);
                    }}
                />
            </div>
        );
    };

    const operacaoTipoEstagioBodyTemplate = (value) => {
        if (value) {
            return <span>Sim</span>;
        } else if (value === false) {
            return <span>Não</span>;
        } else {
            return <span>Mantém</span>;
        }
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "8%" }, filter: true, filterElement: CodigoFiltroTemplate },
        {
            field: "descricao",
            header: "Descrição",
            style: { width: "30%" },
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "ativo",
            header: "Ativo",
            action: (e) => operacaoTipoEstagioBodyTemplate(e.ativo),
            filter: true,
            filterElement: BooleanFiltroTemplate,
        },
        {
            field: "baixa_estoque",
            header: "Baixa Estoque",
            action: (e) => operacaoTipoEstagioBodyTemplate(e.baixa_estoque),
            filter: true,
            filterElement: BooleanFiltroTemplate,
        },
        {
            field: "gera_financeiro",
            header: "Gera Financeiro",
            action: (e) => operacaoTipoEstagioBodyTemplate(e.gera_financeiro),
            filter: true,
            filterElement: BooleanFiltroTemplate,
        },
        {
            field: "gera_documento_fiscal",
            header: "Gera Doc. Fiscal",
            action: (e) => operacaoTipoEstagioBodyTemplate(e.gera_documento_fiscal),
            filter: true,
            filterElement: BooleanFiltroTemplate,
        },
        {
            field: "reserva_estoque",
            header: "Reserva Estoque",
            action: (e) => operacaoTipoEstagioBodyTemplate(e.reserva_estoque),
            filter: true,
            filterElement: BooleanFiltroTemplate,
        },
        {
            field: "gera_comissao",
            header: "Gera Comissão",
            action: (e) => operacaoTipoEstagioBodyTemplate(e.gera_comissao),
            filter: true,
            filterElement: BooleanFiltroTemplate,
        },
        { field: "action", header: "Ações", action: (e) => actionBodyTemplate(e) },
    ];

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Tipos de estágios de vendas"
                        colunas={colunas}
                        painelEsquerdo={cabecalhoTabela}
                        urlPesquisa="/vendas/tipos-estagios-vendas/"
                        configTabela={{ lazy: true, paginator: true }}
                        filtros={{
                            id: { value: null, matchMode: "equals" },
                            descricao: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                            },
                            ativo: { value: true, matchMode: "equals" },
                            baixa_estoque: { value: null, matchMode: "equals" },
                            gera_financeiro: { value: null, matchMode: "equals" },
                            gera_documento_fiscal: { value: null, matchMode: "equals" },
                            reserva_estoque: { value: null, matchMode: "equals" },
                            gera_comissao: { value: null, matchMode: "equals" },
                        }}
                    />
                    <ConfirmDialog
                        visible={deleteModal}
                        onHide={() => onToggleDeleteModal}
                        message={
                            tipoEstagio && (
                                <span>
                                    Deseja mesmo apagar o tipo de estágio <b>{tipoEstagio.descricao}</b>?
                                </span>
                            )
                        }
                        header="Confirmação"
                        icon="pi pi-exclamation-triangle"
                        accept={deleteEstagioVenda}
                        acceptLabel="Confirmar"
                        reject={onToggleDeleteModal}
                        rejectLabel="Cancelar"
                    />
                </div>
            </div>
        </div>
    );
};
