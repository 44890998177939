import React, { useCallback, useEffect, useImperativeHandle, useState, forwardRef } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoInputQuantidadeSku } from "@/components/MakoInputs/MakoInputQuantidadeSku";
import { Dropdown } from "@/components/Dropdown";
import { MakoBuscaSkuPersonalizadaDevolucao } from "@/components/MakoBuscaSkuPersonalizadaDevolucao";
import { MakoDropdownEstoques } from "@/components/MakoInputs/MakoDropdownEstoques";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";
import { formatarCasasDecimais } from "@/assets/util/util";
import { axiosPatch, axiosPost } from "@/services/http";
import { parseNumber } from "@/assets/helpers/number";
import useToast from "@/hooks/useToast";

const Modal = ({ venda, devolucaoId, onSalvar }, ref) => {
    const [visible, setVisible] = useState(false);
    const { showSuccess, showError } = useToast();

    const { setFieldValue, ...formik } = useFormik({
        initialValues: {
            sku: null,
            unidade_medida: null,
            item_venda: null,
            quantidade: 0,
            cubagem: 0,
            valor_unitario: 0,
            subtotal: 0,
            encargos: 0,
            motivo_devolucao: null,
            justificativa: "",
            retorno_estoque: true,
            defeito: false,
            estoque_retorno: null,
            sku_movimenta_estoque: null,
            _encargos_venda: 0,
            _unidade: "",
            _cubagem: "",
            _qtd_vendida: "",
            _qtd_devolvida: "",
        },
        onSubmit: handleSubmit,
    });

    const abrirModal = (itemDevolucao) => {
        if (itemDevolucao) {
            const { item_venda, ...rest } = itemDevolucao;
            formik.setValues({
                ...rest,
                sku: item_venda,
                unidade_medida: rest.unidade_medida.id,
                motivo_devolucao: rest.motivo_devolucao.id,
                sku_movimenta_estoque: rest.sku_movimenta_estoque.id,
                _encargos_venda: parseNumber(item_venda.valor_encargos),
                _unidade: item_venda.unidade_venda?.sigla,
                _cubagem: parseNumber(item_venda.cubagem),
                _qtd_vendida: parseNumber(item_venda.quantidade),
                _qtd_devolvida: parseNumber(item_venda.quantidade_devolvida),
            });
        }
        setVisible(true);
    };

    const fecharModal = () => {
        formik.resetForm();
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                sku: Yup.object()
                    .typeError("Você precisa buscar e selecionar um produto.")
                    .required("Você precisa buscar e selecionar um produto."),
                quantidade: Yup.number()
                    .required("O campo 'qtd. desta devolução' é obrigatório.")
                    .test({
                        message: "A quantidade informada é maior do que o saldo disponível.",
                        test: (value) => value <= values._qtd_vendida - values._qtd_devolvida,
                    }),
                motivo_devolucao: Yup.number()
                    .required("O campo 'motivo da devolução' é obrigatório.")
                    .typeError("Você precisa selecionar um motivo para a devolução."),
                estoque_retorno: Yup.number()
                    .required("O campo 'centro de estocagem de retorno' é obrigatório.")
                    .typeError("Você precisa selecionar um centro de estocagem de retorno."),
            });
            formSchema.validateSync(values, { abortEarly: false });
            const { sku, estoque_retorno, ...rest } = values;
            const itemDevolucao = {
                ...rest,
                item_venda: venda ? sku.id : null,
                sku: sku.sku.id,
                sku_movimenta_estoque: sku.sku_movimenta_estoque.id,
                unidade_medida: sku.unidade_venda.id,
                estoque_retorno: estoque_retorno.id,
                devolucao: devolucaoId,
                encargos: formatarCasasDecimais(rest.encargos),
                subtotal: formatarCasasDecimais(rest.subtotal),
            };
            if (!values.id) {
                const { status } = await axiosPost("/vendas/itens-troca-devolucao/", itemDevolucao);
                if (status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Item para devolução cadastrado com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        if (typeof onSalvar === "function") onSalvar();
                        fecharModal();
                    }, 1500);
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não foi possível salvar o item de devolução.",
                        life: 3000,
                    });
                }
            } else {
                const { quantidade, motivo_devolucao, retorno_estoque, defeito, estoque_retorno, subtotal } =
                    itemDevolucao;
                const itemDevolucaoEditado = {
                    quantidade,
                    motivo_devolucao,
                    retorno_estoque,
                    defeito,
                    estoque_retorno,
                    subtotal,
                };
                const { status } = await axiosPatch(
                    `/vendas/itens-troca-devolucao/${values.id}/`,
                    itemDevolucaoEditado
                );
                if (status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Item para devolução alterado com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        if (typeof onSalvar === "function") onSalvar();
                        fecharModal();
                    }, 1500);
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não foi possível alterar o item de devolução.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const footer = (
        <div>
            <Button type="submit" label="Gravar" icon="pi pi-check" onClick={() => formik.handleSubmit()} autoFocus />
            <Button label="Cancelar" icon="pi pi-times" onClick={fecharModal} className="p-button-danger" />
        </div>
    );

    const onChangeItemDevolucao = (value) => {
        setFieldValue("sku", value);
        if (value instanceof Object) {
            const { unidade_venda, cubagem, quantidade, quantidade_devolvida, valor_unitario, valor_encargos } = value;
            setFieldValue("valor_unitario", parseNumber(valor_unitario));
            setFieldValue("_encargos_venda", parseNumber(valor_encargos));
            setFieldValue("_unidade", unidade_venda?.sigla);
            setFieldValue("_cubagem", parseNumber(cubagem));
            setFieldValue("_qtd_vendida", parseNumber(quantidade));
            setFieldValue("_qtd_devolvida", parseNumber(quantidade_devolvida));
        }
    };

    const calcularEncargosItemDevolucao = useCallback(() => {
        if (!formik.values.quantidade) setFieldValue("encargos", 0);
        const encargos =
            (formik.values._encargos_venda * formik.values.quantidade) / parseNumber(formik.values._qtd_vendida);
        setFieldValue("encargos", encargos);
    }, [formik.values.quantidade, formik.values._encargos_venda, formik.values._qtd_vendida, setFieldValue]);

    useEffect(() => {
        calcularEncargosItemDevolucao();
    }, [calcularEncargosItemDevolucao]);

    const calcularSubtotalItemDevolucao = useCallback(() => {
        if (!formik.values.quantidade) setFieldValue("subtotal", 0);
        const subtotal = formik.values.quantidade * formik.values.valor_unitario + formik.values.encargos;
        setFieldValue("subtotal", subtotal);
    }, [formik.values.quantidade, formik.values.valor_unitario, formik.values.encargos, setFieldValue]);

    useEffect(() => {
        calcularSubtotalItemDevolucao();
    }, [calcularSubtotalItemDevolucao]);

    return (
        <Dialog
            visible={visible}
            header="Item de devolução"
            footer={footer}
            style={{ width: "65vw" }}
            onHide={fecharModal}
        >
            <form>
                <MakoBuscaSkuPersonalizadaDevolucao
                    vendaId={venda?.id}
                    disabledBusca={!!formik.values.id}
                    skuValue={formik.values.sku}
                    skuChange={onChangeItemDevolucao}
                    skuError={formik.errors.sku}
                />
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="n-venda">Nº da venda</label>
                        <InputText id="n-venda" disabled value={venda?.id} />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="data-venda">Data da venda</label>
                        <MakoCalendar id="data-venda" disabled valueCalendar={venda?.data} />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="und">Unidade</label>
                        <InputText id="und" disabled value={formik.values._unidade} />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="cubagem">Cubagem</label>
                        <InputText id="cubagem" disabled value={formik.values._cubagem} />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="qtd-vendida">Qtd. vendida</label>
                        <InputText id="qtd-vendida" disabled value={formik.values._qtd_vendida} />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="qtd-devolvida">Qtd. devolvida</label>
                        <InputText id="qtd-devolvida" disabled value={formik.values._qtd_devolvida} />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-unitario">Valor unitário</label>
                        <MakoInputMoeda id="valor-unitario" disabled value={formik.values.valor_unitario} />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-encargos">Valor dos encargos</label>
                        <MakoInputMoeda id="valor-encargos" disabled value={formik.values.encargos} />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="qtd-desta-devolucao">Qtd. desta devolução *</label>
                        <MakoInputQuantidadeSku
                            id="qtd-desta-devolucao"
                            name="quantidade"
                            value={formik.values.quantidade}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.quantidade })}
                        />
                        {formik.errors.quantidade && <small className="p-error">{formik.errors.quantidade}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="motivo-devolucao">Motivo da devolução *</label>
                        <Dropdown
                            id="motivo-devolucao"
                            name="motivo_devolucao"
                            url="/vendas/motivo-devolucao/"
                            optionValue="id"
                            optionLabel="descricao"
                            filter
                            filterBy="descricao"
                            value={formik.values.motivo_devolucao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.motivo_devolucao })}
                        />
                        {formik.errors.motivo_devolucao && (
                            <small className="p-error">{formik.errors.motivo_devolucao}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="retorna-estoque">Retorna ao estoque</label>
                        <SelectButton
                            id="retorna-estoque"
                            name="retorno_estoque"
                            options={SIM_NAO_BOOLEAN}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.retorno_estoque}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="produto-com-defeito">Produto com defeito</label>
                        <SelectButton
                            id="produto-com-defeito"
                            name="defeito"
                            options={SIM_NAO_BOOLEAN}
                            optionValue="id"
                            optionLabel="label"
                            value={formik.values.defeito}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="estoque-retorno">Estoque / centro de estocagem de retorno *</label>
                        <MakoDropdownEstoques
                            id="estoque-retorno"
                            name="estoque_retorno"
                            optionValue="id"
                            value={formik.values.estoque_retorno}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.estoque_retorno })}
                        />
                        {formik.errors.estoque_retorno && (
                            <small className="p-error">{formik.errors.estoque_retorno}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="subtotal-devolucao">Subtotal da devolução</label>
                        <MakoInputMoeda id="subtotal-devolucao" disabled value={formik.values.subtotal} />
                    </div>
                </div>
                <CamposObrigatorios />
            </form>
        </Dialog>
    );
};

export const ModalItem = forwardRef(Modal);
