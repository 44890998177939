import React, { useCallback, useEffect, useRef, useState } from "react";

import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { NATUREZA_PLANO_CONTAS } from "@/assets/constants/constants";
import { axiosPost, axiosPatch, axiosGet } from "@/services/http";

import useLocalStorage from "@/hooks/useLocalStorage";
import storageKeys from "@/assets/constants/storage";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";

const urlvoltar = "/contabil/plano-contas";
const urlPlanoContas = "/financeiro/planos-contas-contabeis/?conta_titulo=true";

export const ContabilPlanoContasForm = (props) => {
    const { showLoading, hideLoading } = useLoading();
    const [redirecionar, setRedirecionar] = useState(null);
    const [empresaSelecionada] = useLocalStorage(storageKeys.EMPRESA_KEY);
    const [planos, setPlanos] = useState([]);

    const toastRef = useRef(null);
    const history = useHistory();

    const planoVazio = {
        codigo: "",
        descricao: "",
        natureza: "",
        nivel: "",
        estrutura: "",
        conta_titulo: false,
        versao: null,
        ativo: true,
    };

    const { setFieldValue, ...formik } = useFormik({
        initialValues: props.location.state?.planContas
            ? { ...props.location.state.planContas.data, versao: props.location.state.planContas.data.versao.id }
            : planoVazio,
        onSubmit: handleSubmit,
    });

    const [ativo, setAtivo] = useState(formik.values.ativo);

    async function handleSubmit(values) {
        let nivel = values.estrutura.split(".");

        try {
            const formSchema = Yup.object().shape({
                codigo: Yup.string().required("O campo 'código' é obrigatório."),
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                natureza: Yup.string().required("O campo 'natureza' é obrigatório."),
                estrutura: Yup.string().required("O campo 'estrutura' é obrigatório."),
                plano_conta_contabil: Yup.number()
                    .when("conta_titulo", {
                        is: (val) => !val,
                        then: Yup.number().required("Necessário selecionar uma conta pai."),
                    })
                    .nullable(),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost("/financeiro/planos-contas-contabeis/", {
                    ...values,
                    empresas: empresaSelecionada.id,
                    nivel: nivel.length,
                    versao: props.location.versaoId,
                });
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Plano de contas cadastrado com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        setRedirecionar({
                            pathname: urlvoltar,
                            state: { versaoId: props.location.versaoId },
                        });
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPatch(`/financeiro/planos-contas-contabeis/${values.id}/`, {
                    ...values,
                    ativo: ativo,
                });
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Plano de contas alterado com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        setRedirecionar({
                            pathname: urlvoltar,
                            state: { versaoId: values.versao },
                        });
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    const verificaContaPài = useCallback(
        async (conta) => {
            showLoading();
            const resposta = await axiosGet(`/financeiro/planos-contas-contabeis/${conta}/`);
            hideLoading();

            if (resposta.status === 200 || resposta.status === 201) {
                setFieldValue("natureza", resposta.data.natureza);
                setFieldValue("plano_conta_contabil", resposta.data.id);
            }
        },
        [showLoading, hideLoading, setFieldValue]
    );

    const getPlanoContas = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet(urlPlanoContas);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            setPlanos(resposta.data.results);
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Mako",
                detail: "Desculpe \nNão conseguimos listar as versões do plano de contas.",
                life: 3000,
            });
        }
    }, [showLoading, hideLoading]);

    useEffect(() => {
        getPlanoContas();
        if (props.location?.contaId) {
            verificaContaPài(props.location.contaId);
        } else if (props.location?.state?.planContas?.data) {
            verificaContaPài(props.location?.state?.planContas?.data.plano_conta_contabil);
        }
    }, [setFieldValue, getPlanoContas, verificaContaPài, props.location?.contaId, props.location?.state?.planContas]);

    function gerarCodigo() {
        let id = "";
        let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789012345678901234567890123456789";

        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        setFieldValue("codigo", id);

        toastRef.current.show({
            severity: "success",
            summary: "Mako",
            detail: "Código gerado com sucesso!",
            life: 3000,
        });
    }

    function optionValidate(value) {
        return value.natureza !== formik.values.natureza;
    }

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    return redirecionar ? (
        <Redirect to={redirecionar} />
    ) : (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toastRef} />
                    <h3>{!formik.values.id ? "Inserir plano de contas" : "Manutenção de plano de contas"}</h3>

                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="natureza">Natureza *</label>
                                <Dropdown
                                    id="natureza"
                                    name="natureza"
                                    placeholder="D/C"
                                    options={NATUREZA_PLANO_CONTAS}
                                    optionValue="id"
                                    optionLabel="label"
                                    value={formik.values.natureza}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.natureza })}
                                />
                                {formik.errors.natureza && <small className="p-error">{formik.errors.natureza}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="plano_conta_contabil">Conta pai *</label>
                                <Dropdown
                                    id="plano_conta_contabil"
                                    name="plano_conta_contabil"
                                    value={formik.values.plano_conta_contabil}
                                    onChange={formik.handleChange}
                                    filter
                                    filterBy="descricao"
                                    placeholder="Selecione uma conta pai"
                                    optionLabel="descricao"
                                    optionValue="id"
                                    optionDisabled={optionValidate}
                                    disabled={formik.values.natureza ? false : true}
                                    options={planos}
                                    className={classNames({ "p-invalid": formik.errors.plano_conta_contabil })}
                                />
                                {formik.errors.plano_conta_contabil && (
                                    <small className="p-error">{formik.errors.plano_conta_contabil}</small>
                                )}
                            </div>
                            <div className="p-field-checkbox p-col-12 p-md-3">
                                <br></br>
                                <Checkbox
                                    inputId="conta_titulo"
                                    id="conta_titulo"
                                    name="conta_titulo"
                                    onChange={formik.handleChange}
                                    checked={formik.values.conta_titulo}
                                    value={formik.values.conta_titulo}
                                    className="p-mt-3"
                                />
                                <label htmlFor="conta_titulo" className="p-mt-3">
                                    <b>Conta título</b>
                                </label>
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="codigo">Código *</label>
                                <div className="p-inputgroup">
                                    <InputText
                                        id="codigo"
                                        name="codigo"
                                        keyfilter={/^[A-Z0-9]/}
                                        value={formik.values.codigo}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": formik.errors.codigo })}
                                        tooltip="Código do Plano de Contas Exemplo: Código '999' da descrição 'Outros'."
                                        autoComplete="off"
                                        autoFocus
                                    />
                                    <Button
                                        type="button"
                                        icon="pi pi-refresh"
                                        className="p-button-info"
                                        tooltip="Gerar código aleatório"
                                        onClick={() => gerarCodigo()}
                                    />
                                </div>

                                {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                            </div>

                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="descricao">Descrição *</label>
                                <InputText
                                    id="descricao"
                                    name="descricao"
                                    value={formik.values.descricao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.descricao })}
                                    tooltip="Descricao que será dado ao Plano de Contas."
                                    autoComplete="off"
                                />
                                {formik.errors.descricao && (
                                    <small className="p-error">{formik.errors.descricao}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="estrutura">Estrutura *</label>
                                <InputText
                                    id="estrutura"
                                    name="estrutura"
                                    value={formik.values.estrutura}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.estrutura })}
                                    tooltip="Estrutura que será dada ao plano de contas. (Ex: 1.1.1)"
                                    autoComplete="off"
                                />
                                {formik.errors.estrutura && (
                                    <small className="p-error">{formik.errors.estrutura}</small>
                                )}
                            </div>
                        </div>

                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 ">
                                <h6>
                                    <label htmlFor="status">Status</label>
                                </h6>
                                <InputSwitch
                                    id="ativo"
                                    name="ativo"
                                    checked={ativo}
                                    disabled={formik.values.id ? false : true}
                                    value={formik.values.ativo}
                                    onChange={(e) => setAtivo(e.value)}
                                />
                            </div>
                        </div>

                        <p>
                            <b>* Campos obrigatórios.</b>
                        </p>

                        <div className="p-grid p-col-12 p-md-6">
                            <Button label="Gravar" type="submit" className="p-button-info p-mr-2 p-mb-6" />
                            <Button
                                label="Cancelar"
                                type="reset"
                                className="p-button-danger p-mr-2 p-mb-6"
                                onClick={cancelar}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
