import React, { useState, useRef, useCallback, useEffect, useMemo } from "react";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { axiosGet, axiosPatch } from "@/services/http";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { Tag } from "primereact/tag";
import { dataToStr } from "@/assets/util/datas";
import { Menu } from "primereact/menu";
import { EditarHistoricoModalForm } from "./modals/editarHistoricoModalForm";
import { LancarMovimentacaoModalForm } from "./modals/lancarMovimentacaoModalForm";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import useAuth from "@/hooks/useAuth";
import { FINANCEIRO_MOVIMENTACAO_CAIXA } from "@/assets/constants/parametros";
import useParam from "@/hooks/useParam";
import { parseNumberToMoneyHTML, totalizadorFiltrosAplicados } from "@/assets/util/util";
import { InputNumber } from "primereact/inputnumber";
import { MakoCalendar } from "@/components/MakoCalendar";
import { DJANGO_FILTERS_MATCHMODE, NATUREZA_PLANO_CONTAS } from "@/assets/constants/constants";
import { Panel } from "primereact/panel";
import { useFormik } from "formik";
import { Dropdown } from "@/components/Dropdown";
import classNames from "classnames";
import * as Yup from "yup";
import { InspecionarModalForm } from "./modals/inspecionarModalForm";
import { MakoCardFinanceiro } from "@/components/MakoCards";
import { FILTRO_INDICADORES } from "@/assets/constants/financeiro";
import useToast from "@/hooks/useToast";
import useEmpresa from "@/hooks/useEmpresa";
import { Badge } from "primereact";

const BASE_FILTROS = {
    carteira_financeira: null,
    conta_financeira: null,
    status: null,
    data_inicio: null,
    data_fim: null,
};

export const FinanceiroLancamentosPage = () => {
    const [filtroIndicadores, setFiltroIndicadores] = useState("mes");
    const [indicadores, setIndicadores] = useState(null);
    const [loadingIndicadores, setLoadingIndicadores] = useState(false);
    const [compensarDialog, setCompensarDialog] = useState(false);
    const [cancelarDialog, setCancelarDialog] = useState(false);
    const [editarHistorico, setEditarHistorico] = useState(false);
    const [inspecionar, setInspecionar] = useState(false);
    const [lancarMovimentacao, setLancarMovimentacao] = useState(false);
    const [usuario, setUsuario] = useState(null);
    const [movimentacao, setMovimentacao] = useState(null);
    const { empresaSelecionadaId } = useEmpresa();
    const { showLoading, hideLoading } = useLoading();
    const { verifyPermission } = useAuth();
    const { getParam } = useParam();
    const BASE_URL = "/financeiro/movimentacao-contas-corrente/";
    const [url, setUrl] = useState(`${BASE_URL}?cancelado=false`);
    const menu = useRef(null);
    const toastRef = useRef(null);
    const listagemRef = useRef(null);
    const { showWarning, showError } = useToast();

    const { resetForm, ...formik } = useFormik({
        initialValues: { ...BASE_FILTROS, status: 1 },
        onSubmit: handleSubmit,
    });

    const onFilter = (e) => {
        setUrl(e);
    };

    async function handleSubmit(values) {
        try {
            if (typeof onFilter === "function") {
                const matchModes = {
                    status: DJANGO_FILTERS_MATCHMODE.equals,
                    carteira_financeira: DJANGO_FILTERS_MATCHMODE.equals,
                    conta_financeira: DJANGO_FILTERS_MATCHMODE.equals,
                    data_inicio: DJANGO_FILTERS_MATCHMODE.gte,
                    data_fim: DJANGO_FILTERS_MATCHMODE.lte,
                };

                let params = [];
                Object.keys(values).forEach((key) => {
                    if (values[key] !== null && values[key] !== "") {
                        if (key === "status") {
                            const _key =
                                values[key] === 0
                                    ? "todos"
                                    : values[key] === 1
                                    ? "compensado"
                                    : values[key] === 2
                                    ? "cancelado"
                                    : "cancelado";
                            if (_key !== "todos") return params.push(`${_key}${matchModes[key]}=true`);
                        }

                        if (key === "data_inicio" || key === "data_fim") {
                            const _key = "data_movimentacao";
                            return params.push(
                                `${_key}${matchModes[key]}=${dataToStr(values[key], "yyyy-MM-dd hh:mm:ss")}`
                            );
                        }

                        return typeof matchModes[key]
                            ? params.push(`${key}${matchModes[key]}=${values[key]}`)
                            : params.push(`${key}=${values[key]}`);
                    }
                });
                if (params.length > 0) onFilter(BASE_URL + `?${params.join("&")}`);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    async function compensacaoMovimentacao() {
        try {
            const body = {
                usuario_compensacao: movimentacao.compensado ? usuario.id : null,
                data_compensacao: movimentacao.compensado ? dataToStr(new Date(), "yyyy-MM-dd") : null,
                compensado: movimentacao.compensado,
            };

            showLoading();
            const resposta = await axiosPatch(`/financeiro/movimentacao-contas-corrente/${movimentacao.id}/`, body);
            hideLoading();

            if (resposta.status === 200) {
                toastRef.current.show({
                    severity: "success",
                    summary: "Sucesso",
                    detail: `Movimentação ${movimentacao?.compensado ? "compensada" : "descompensada"} com sucesso!`,
                    life: 3000,
                });
                carregaIndicadores();
                listagemRef.current?.buscarDados();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Falha ao compensar movimentação, tente novamente.",
                    life: 3000,
                });
            }
        } catch (error) {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
        }
    }

    async function cancelarMovimentacao() {
        try {
            const body = {
                usuario_cancelamento: usuario.id,
                cancelado: true,
            };

            showLoading();
            const resposta = await axiosPatch(`/financeiro/movimentacao-contas-corrente/${movimentacao.id}/`, body);
            hideLoading();

            if (resposta.status === 200) {
                toastRef.current.show({
                    severity: "success",
                    summary: "Sucesso",
                    detail: "Movimentação cancelada com sucesso!",
                    life: 3000,
                });
                listagemRef.current?.buscarDados();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Falha ao cancelar movimentação, tente novamente.",
                    life: 3000,
                });
            }
        } catch (error) {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
        }
    }

    const tagMovimentacaoCaixa = useMemo(() => {
        const tagCaixa = getParam(FINANCEIRO_MOVIMENTACAO_CAIXA);
        if (tagCaixa) return tagCaixa.valor;
        return null;
    }, [getParam]);

    const contadorFiltros = useMemo(() => {
        const values = formik.values;
        return totalizadorFiltrosAplicados(BASE_FILTROS, values);
    }, [formik.values]);

    const carregaUsuario = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/auth/users/me/");
        hideLoading();

        if (resposta.status === 200) setUsuario(resposta.data);
    }, [showLoading, hideLoading]);

    const confirmarCompensacao = async (compensa) => {
        setMovimentacao({
            ...(await movimentacao),
            compensado: compensa,
            data_evento: dataToStr(new Date(), "dd-MM-yyyy"),
        });
        setCompensarDialog(true);
    };

    const esconderCompensacaoDialog = () => {
        setMovimentacao(null);
        setCompensarDialog(false);
    };

    const confirmarCancelar = async () => {
        setMovimentacao({ ...(await movimentacao), data_evento: dataToStr(new Date(), "dd-MM-yyyy") });
        setCancelarDialog(true);
    };

    const esconderCancelarDialog = () => {
        setMovimentacao(null);
        setCancelarDialog(false);
    };

    const esconderEditarHistorico = () => {
        setMovimentacao(null);
        setEditarHistorico(false);
    };

    const esconderInspecionar = () => {
        setMovimentacao(null);
        setInspecionar(false);
    };

    const confirmarEditarHistorico = (lancamento) => {
        setMovimentacao(lancamento);
        setEditarHistorico(true);
    };

    const botoesOp = [
        {
            label: "Operações",
            items: [
                verifyPermission([permissoes.FINANCEIRO_FINANCEIRO_LANCAMENTOS_COMPENSARMOVIMENTACAO])
                    ? {
                          label: "Compensar",
                          icon: "pi pi-file",
                          command: () => confirmarCompensacao(true),
                          disabled: movimentacao?.cancelado || movimentacao?.compensado,
                      }
                    : null,
                verifyPermission([permissoes.FINANCEIRO_FINANCEIRO_LANCAMENTOS_DESCOMPENSARMOVIMENTACAO])
                    ? {
                          label: "Descompensar",
                          icon: "pi pi-file",
                          command: () => confirmarCompensacao(false),
                          disabled: movimentacao?.cancelado || !movimentacao?.compensado,
                      }
                    : null,
                verifyPermission([permissoes.FINANCEIRO_FINANCEIRO_LANCAMENTOS_CANCELARMOVIMENTACAO])
                    ? {
                          label: "Cancelar",
                          icon: "pi pi-envelope",
                          command: () => confirmarCancelar(),
                          disabled: movimentacao?.cancelado || movimentacao?.compensado,
                      }
                    : null,
            ],
        },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_LANCAMENTOS_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar histórico"
                    tooltipOptions={{ position: "left" }}
                    onClick={() => confirmarEditarHistorico(rowData)}
                />
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_LANCAMENTOS_EDITAR]}
                    model={botoesOp}
                    componente={Button}
                    className="p-button-rounded p-button-info p-mr-2 p-mb-1"
                    tooltip="Operações"
                    tooltipOptions={{ position: "left" }}
                    icon="pi pi-cog"
                    onClick={(e) => {
                        menu.current.toggle(e);
                        setMovimentacao(rowData);
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_LANCAMENTOS_CONSULTAR]}
                    componente={Button}
                    icon="pi pi-search"
                    className="p-button-rounded p-button-help p-mr-2 p-mb-1"
                    tooltip="Inspecionar"
                    tooltipOptions={{ position: "left" }}
                    onClick={() => {
                        setMovimentacao(rowData);
                        setInspecionar(true);
                    }}
                />
            </div>
        );
    };

    const templateTipoMovimentacao = (tipo) => {
        if (tipo === "C") {
            return <Tag severity="success" value="CRÉDITO" />;
        }
        return <Tag severity="danger" value="DÉBITO" />;
    };

    useEffect(() => {
        carregaUsuario();
    }, [carregaUsuario]);

    const carregaIndicadores = useCallback(async () => {
        if (!empresaSelecionadaId || !filtroIndicadores) return;
        const params = {
            empresa: empresaSelecionadaId,
            filtro: filtroIndicadores,
        };
        setLoadingIndicadores(true);
        const { status, data } = await axiosGet("/financeiro/indicadores/lancamentos/", { params });
        setLoadingIndicadores(false);

        if (status === 200) {
            setIndicadores(data.result);
        } else if (status !== 500 && data?.msg) {
            showWarning({
                summary: "Ops",
                detail: data.msg,
                life: 3000,
            });
        } else {
            showError({
                summary: "Falha nos indicadores",
                detail: "Desculpe, não foi possível exibir os indicadores dos pagamentos.",
                life: 3000,
            });
        }
    }, [empresaSelecionadaId, filtroIndicadores, showWarning, showError]);

    useEffect(() => {
        carregaIndicadores();
    }, [carregaIndicadores]);

    const codigoFiltroTemplate = (options) => {
        return <InputNumber value={options.value} onValueChange={(e) => options.filterCallback(e.value)} />;
    };

    const tipoMovFiltroTemplate = (options) => {
        return (
            <Dropdown
                placeholder="Selecione"
                showClear
                value={options.value}
                options={NATUREZA_PLANO_CONTAS}
                onChange={(e) => options.filterCallback(e.value)}
            />
        );
    };

    const colunas = [
        {
            field: "id",
            header: "Lançamento",
            minWidth: { width: "10%" },
            filter: true,
            filterElement: codigoFiltroTemplate,
        },
        {
            field: "tipo_movimento",
            header: "Tipo movimentação",
            filter: true,
            filterElement: tipoMovFiltroTemplate,
            action: (e) => templateTipoMovimentacao(e.tipo_movimento),
        },
        { field: "historico", header: "Histórico", minWidth: { width: "50%" }, filter: true },
        {
            field: "data_movimentacao",
            header: "Data",
            dateFormat: "dd/MM/yyyy HH:mm",
            style: { minWidth: "10%" },
        },
        { field: "valor", header: "Valor", action: (e) => parseNumberToMoneyHTML(e.valor) },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "10%" },
        },
    ];

    const rowClass = (movimentacao) => {
        return {
            "table-recebimentos-effective": movimentacao.compensado,
            "table-recebimentos-overdue": movimentacao.cancelado,
        };
    };

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <Menu model={botoesOp} popup ref={menu} id="popup_menu" />
            <div className="p-col-12">
                <h5>Lançamentos</h5>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <MakoControleAcesso
                            permissao={[permissoes.FINANCEIRO_FINANCEIRO_LANCAMENTOS_EDITAR]}
                            componente={Button}
                            icon="pi pi-plus"
                            className="p-button-success"
                            label="Lançar movimentação"
                            onClick={() => setLancarMovimentacao(true)}
                        />
                    </div>
                    <div className="p-field p-col-6 p-md-2 p-offset-7 p-justify-end">
                        <Dropdown
                            options={FILTRO_INDICADORES}
                            placeholder="Filtro de indicadores"
                            value={filtroIndicadores}
                            showClear={false}
                            onChange={(e) => setFiltroIndicadores(e.value)}
                        />
                    </div>
                </div>
                <div className="p-fluid p-grid">
                    <div className="p-col-6 p-md-3">
                        <MakoCardFinanceiro
                            title="Movimentado hoje"
                            value={indicadores?.movimentado_hoje}
                            blocked={loadingIndicadores}
                        />
                    </div>
                    <div className="p-col-6 p-md-3">
                        <MakoCardFinanceiro
                            title="Total creditado"
                            value={indicadores?.total_creditado}
                            blocked={loadingIndicadores}
                        />
                    </div>
                    <div className="p-col-6 p-md-3">
                        <MakoCardFinanceiro
                            title="Total debitado"
                            value={indicadores?.total_debitado}
                            blocked={loadingIndicadores}
                        />
                    </div>
                    <div className="p-col-6 p-md-3">
                        <MakoCardFinanceiro
                            title="Saldo no período"
                            value={indicadores?.saldo}
                            blocked={loadingIndicadores}
                        />
                    </div>
                </div>
                <Panel
                    header={<span>Filtros avançados {contadorFiltros > 0 && <Badge value={contadorFiltros} />}</span>}
                    toggleable
                    collapsed={true}
                >
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="conta_financeira">Conta Financeira</label>
                                <Dropdown
                                    id="conta_financeira"
                                    name="conta_financeira"
                                    value={formik.values.conta_financeira}
                                    url={
                                        !tagMovimentacaoCaixa
                                            ? "/financeiro/contas-financeiras/?query={id,descricao}"
                                            : "/financeiro/contas-financeiras/?query={id,descricao}&tipo_conta=1"
                                    }
                                    optionValue="id"
                                    optionLabel="descricao"
                                    filter
                                    filterBy="descricao"
                                    showClear
                                    placeholder="Selecione uma conta financeira"
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="carteira_financeira">Carteira</label>
                                <Dropdown
                                    id="carteira_financeira"
                                    name="carteira_financeira"
                                    value={formik.values.carteira_financeira}
                                    url="/financeiro/carteiras-contas-financeiras/"
                                    optionValue="id"
                                    optionLabel="descricao"
                                    filter
                                    filterBy="descricao"
                                    showClear
                                    placeholder="Selecione uma carteira"
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="data_inicio">Data inicial</label>
                                <MakoCalendar
                                    id="data_inicio"
                                    name="data_inicio"
                                    valueCalendar={formik.values.data_inicio}
                                    onChange={formik.handleChange}
                                    className={classNames({
                                        "p-invalid": formik.errors.data_inicio,
                                    })}
                                />
                                {formik.errors.data_inicio && (
                                    <small className="p-error">{formik.errors.data_inicio}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="data_fim">Data final</label>
                                <MakoCalendar
                                    id="data_fim"
                                    name="data_fim"
                                    valueCalendar={formik.values.data_fim}
                                    onChange={formik.handleChange}
                                    className={classNames({
                                        "p-invalid": formik.errors.data_fim,
                                    })}
                                />
                                {formik.errors.data_fim && <small className="p-error">{formik.errors.data_fim}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="status">Status</label>
                                <Dropdown
                                    id="status"
                                    name="status"
                                    placeholder="Selecione..."
                                    options={[
                                        { id: 0, label: "Todos" },
                                        { id: 1, label: "Compensado" },
                                        { id: 2, label: "Cancelado" },
                                        { id: 3, label: "Pendente" },
                                    ]}
                                    filter
                                    filterBy="label"
                                    optionValue="id"
                                    optionLabel="label"
                                    value={formik.values.status}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </div>

                        <div className="p-mt-4">
                            <Button
                                type="submit"
                                label="Filtrar"
                                icon="pi pi-filter"
                                className="p-mr-2"
                                //onClick={() => formik.resetForm()}
                            />
                            <Button
                                type="reset"
                                icon="pi pi-trash"
                                label="Limpar"
                                onClick={() => {
                                    setUrl(`${BASE_URL}?cancelado=false`);
                                    resetForm();
                                }}
                                className="p-button-warning p-mr-2"
                            />
                        </div>
                    </form>
                </Panel>
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        colunas={colunas}
                        urlPesquisa={url}
                        filtros={{
                            id: { value: "", matchMode: "equals" },
                            tipo_movimento: { value: "", matchMode: "equals" },
                            historico: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "icontains" }],
                            },
                        }}
                        configTabela={{
                            rowClassName: rowClass,
                            paginator: true,
                            lazy: true,
                        }}
                    />
                    <LancarMovimentacaoModalForm
                        esconderDialog={setLancarMovimentacao}
                        listagem={listagemRef}
                        toast={toastRef}
                        lancarMovimentacao={lancarMovimentacao}
                        setLancarMovimentacao={setLancarMovimentacao}
                        carregaIndicadores={carregaIndicadores}
                    />
                    <EditarHistoricoModalForm
                        movimentacao={movimentacao}
                        esconderDialog={esconderEditarHistorico}
                        toast={toastRef}
                        listagem={listagemRef}
                        editarHistorico={editarHistorico}
                    />
                    <InspecionarModalForm
                        movimentacao={movimentacao}
                        inspecionar={inspecionar}
                        esconderDialog={esconderInspecionar}
                    />
                    <ConfirmDialog
                        visible={compensarDialog}
                        onHide={esconderCompensacaoDialog}
                        header="Confirmação"
                        message={
                            movimentacao && (
                                <span>
                                    {"Deseja realmente"}{" "}
                                    <b>{movimentacao?.compensado ? "compensar" : "descompensar"}</b>{" "}
                                    {" a movimentação n.º "}
                                    <b>{movimentacao.id}</b>?
                                    <br />
                                    <br />
                                    <text>
                                        {`Usuário responsável: `} <b>{usuario.username}</b>
                                    </text>
                                    <br />
                                    <text>
                                        {`Data da compensação: `} <b>{movimentacao.data_evento}</b>
                                    </text>
                                    <br />
                                </span>
                            )
                        }
                        icon="pi pi-info-circle p-mr-3"
                        accept={compensacaoMovimentacao}
                        acceptLabel="Sim"
                        acceptClassName="p-button-success"
                        reject={esconderCompensacaoDialog}
                        rejectLabel="Não"
                    />
                    <ConfirmDialog
                        visible={cancelarDialog}
                        onHide={esconderCancelarDialog}
                        header="Confirmação"
                        message={
                            movimentacao && (
                                <span>
                                    {"Deseja realmente"} <b>cancelar</b> {" a movimentação n.º "}
                                    <b>{movimentacao.id}</b>?
                                    <br />
                                    <br />
                                    <text>
                                        {`Usuário responsável: `} <b>{usuario.username}</b>
                                    </text>
                                    <br />
                                    <text>
                                        {`Data da compensação: `} <b>{movimentacao.data_evento}</b>
                                    </text>
                                </span>
                            )
                        }
                        icon="pi pi-info-circle p-mr-3"
                        accept={cancelarMovimentacao}
                        acceptLabel="Sim"
                        acceptClassName="p-button-danger"
                        reject={esconderCancelarDialog}
                        rejectLabel="Não"
                    />
                </div>
            </div>
        </div>
    );
};
