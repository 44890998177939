import React, { useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { MakoSelecionarEmails } from "@/components/MakoSelecionarEmails";
import { MakoInputFornecedor } from "@/components/MakoInputs/MakoInputFornecedor";
import { MakoInputCheckBox } from "@/components/MakoInputs/MakoInputCheckbox";
import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";
import { MakoInputNcm } from "@/components/MakoInputs/MakoInputNcm";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";

import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import useRelatorio from "@/hooks/useRelatorio";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIO_ESTOQUE_SALDOOC } from "@/assets/constants/relatorios";
import { dataToStr } from "@/assets/util/datas";

export const RelatorioSaldoOC = () => {
    const [visible, setVisible] = useState(false);
    const [emails, setEmails] = useState([]);

    const { solicitarRelatorio } = useRelatorio();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            marca: null,
            data_saldo: null,
            grupo: null,
            numero_oc: "",
            destinacao: null,
            tipo_produto: null,
            fornec: null,
            descricao: "",
            ncm: null,
            custo_liquido: false,
            orderBy: "",
        },
        onSubmit: handleSubmit,
    });

    const ORDENACAO_FILTROS = [
        { value: "descricao", label: "Descrição (crescente)" },
        { value: "-descricao", label: "Descrição (decrescente)" },
        { value: "saldo_fisico", label: "Saldo fisico (crescente)" },
        { value: "-saldo_fisico", label: "Saldo fisico (decrescente)" },
        { value: "saldo_oc", label: "Saldo OC (crescente)" },
        { value: "-saldo_oc", label: "Saldo OC (decrescente)" },
        { value: "saldo_total", label: "Saldo total (crescente)" },
        { value: "-saldo_total", label: "Saldo total (decrescente)" },
        { value: "tipo_produto", label: "Tipo produto (crescente)" },
        { value: "-tipo_produto", label: "Tipo produto (decrescente)" },
        { value: "centro_estocagem", label: "Centro de estocagem (crescente)" },
        { value: "-centro_estocagem", label: "Centro de estocagem (decrescente)" },
    ];

    const filtrosTemplate = [
        {
            key: "marca",
            label: "Grupo",
            path: "descricao",
        },
        {
            key: "data_saldo",
            label: "Saldo entre",
            type: TIPOS_FILTROS_TEMPLATE.DATE,
        },
        {
            key: "grupo",
            label: "Grupo",
            path: "descricao",
        },
        {
            key: "numero_oc",
            label: "N° OC",
        },
        {
            key: "destinacao",
            label: "Destinação",
            path: "descricao",
        },
        {
            key: "tipo_produto",
            label: "Tipo de produto",
            path: "descricao",
        },
        {
            key: "ncm",
            label: "NCM",
            path: "codigo",
        },
        {
            key: "custo_liquido",
            label: "Custo líquido",
            type: TIPOS_FILTROS_TEMPLATE.BOOLEAN,
        },
        {
            key: "orderBy",
            label: "Ordenado por",
            optionLabel: "label",
            optionKey: "value",
            options: ORDENACAO_FILTROS,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
    ];

    async function handleSubmit(values = null) {
        try {
            const formSchema = Yup.object().shape({
                marca: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number().required("Informe uma 'marca' válida."),
                    })
                    .typeError("Informe uma 'marca' válida."),
                data_saldo: Yup.date()
                    .required("O campo 'data abertura' é obrigatório")
                    .typeError("Seleciona um 'saldo entre' válido."),
                grupo: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number().required("Informe um 'grupo' válida."),
                    })
                    .typeError("Informe uma 'grupo' válido."),
                numero_oc: Yup.string()
                    .required("O campo 'numero oc' é obrigatório.")
                    .typeError("Informe uma 'numero de oc' válido."),
                destinacao: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number().required("Informe uma 'destinação' válida."),
                    })
                    .typeError("Informe uma 'destinação' válido."),
                tipo_produto: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number().required("Informe um 'tipo_produto' válida."),
                    })
                    .typeError("Informe uma 'tipo de produto' válido."),
                fornec: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number().required("Informe um 'fornecedor' válida."),
                    })
                    .typeError("Informe um 'fornecedor' válida."),
                descricao: Yup.string().nullable().typeError("Informe uma 'descrição' válida."),
                ncm: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number().required("Informe uma 'ncm' válida."),
                    })
                    .typeError("Informe uma 'ncm' válida."),
                custo_liquido: Yup.boolean().nullable(),
                orderBy: Yup.string().nullable().typeError("Seleciona uma 'ordenação' válida."),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, dadosValidados);
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.ncm) dadosValidados.ncm = dadosValidados.ncm.id;
            if (dadosValidados.fornec) dadosValidados.fornec = dadosValidados.fornec.id;
            if (dadosValidados.marca) dadosValidados.marca = dadosValidados.marca.id;
            if (dadosValidados.destinacao) dadosValidados.destinacao = dadosValidados.destinacao.id;
            if (dadosValidados.data_saldo)
                dadosValidados.data_saldo = dataToStr(dadosValidados.data_saldo, "yyyy-MM-dd");

            let filtros = {};
            if (dadosValidados) {
                Object.keys(dadosValidados).forEach((key) => {
                    if (dadosValidados[key]) filtros[key] = dadosValidados[key];
                });
            }

            solicitarRelatorio({
                chave: RELATORIO_ESTOQUE_SALDOOC,
                emails,
                filtros,
                filtros_template: filtrosTemplateAplicados,
            });
            formik.resetForm();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const handleConfirm = (emails) => {
        fecharModal();
        if (emails && emails.length > 0) {
            handlePreSubmit(emails);
        }
    };

    const handlePreSubmit = (emails = []) => {
        setEmails(emails);
        formik.handleSubmit();
    };

    const fecharModal = () => {
        setVisible(() => false);
    };

    const limparFiltro = () => {
        formik.resetForm();
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <h5>Filtro para emissão de relatório de saldo de produtos - OC</h5>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="numero_oc">Nº de OC</label>
                            <InputText
                                id="numero_oc"
                                name="numero_oc"
                                value={formik.values.numero_oc}
                                onInput={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.numero_oc })}
                            />
                            {formik.errors.numero_oc && <small className="p-error">{formik.errors.numero_oc}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="data_saldo">Saldo entre</label>
                            <MakoCalendar
                                id="data_saldo"
                                name="data_saldo"
                                valueCalendar={formik.values.data_saldo}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.data_saldo })}
                            />
                            {formik.errors.data_saldo && <small className="p-error">{formik.errors.data_saldo}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="fornec">Fornecedor</label>
                            <MakoInputFornecedor
                                id="fornec"
                                name="fornec"
                                value={formik.values.fornec}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.fornec })}
                            />
                            {formik.errors.fornec && <small className="p-error">{formik.errors.fornec}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="marca">Marca</label>
                            <MakoAutoComplete
                                id="marca"
                                name="marca"
                                placeholder="Digite para pesquisar... (min 4 caracteres)"
                                minCaracteresBusca={4}
                                key="id"
                                field="nome"
                                urlSearch="/produtos/marcas/?search="
                                value={formik.values.marca}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.marca })}
                            />
                            {formik.errors.marca && <small className="p-error">{formik.errors.marca}</small>}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="ncm">NCM</label>
                            <MakoInputNcm
                                id="ncm"
                                name="ncm"
                                fieldValue={setFieldValue}
                                valorNcm={formik.values.ncm}
                                className={classNames({ "p-invalid": formik.errors.ncm })}
                            />
                            {formik.errors.ncm && <small className="p-error">{formik.errors.ncm}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="tipo_produto">Tipo de produto</label>
                            <Dropdown
                                id="tipo_produto"
                                name="tipo_produto"
                                url="/produtos/tipos-codigos-sku/"
                                value={formik.values.tipo_produto}
                                onChange={formik.handleChange}
                                filter
                                filterBy="descricao"
                                optionLabel="descricao"
                                placeholder="Selecione um tipo..."
                                className={classNames({ "p-invalid": formik.errors.tipo_produto })}
                            />
                            {formik.errors.tipo_produto && (
                                <small className="p-error">{formik.errors.tipo_produto}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="grupo">Grupo</label>
                            <Dropdown
                                id="grupo"
                                name="grupo"
                                url={"/produtos/categorias-grupos/"}
                                value={formik.values.grupo}
                                onChange={formik.handleChange}
                                filter
                                filterBy="descricao"
                                optionLabel="descricao"
                                placeholder="Selecione um tipo..."
                                className={classNames({ "p-invalid": formik.errors.grupo })}
                            />
                            {formik.errors.grupo && <small className="p-error">{formik.errors.grupo}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="destinacao">Destinação</label>
                            <Dropdown
                                id="destinacao"
                                name="destinacao"
                                url={"/compras/destinacoes-entradas/"}
                                value={formik.values.destinacao}
                                onChange={formik.handleChange}
                                filter
                                filterBy="nome"
                                optionLabel="nome"
                                placeholder="Selecione um tipo..."
                                className={classNames({ "p-invalid": formik.errors.destinacao })}
                            />
                            {formik.errors.destinacao && <small className="p-error">{formik.errors.destinacao}</small>}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="orderBy">Ordenado por</label>
                            <Dropdown
                                id="orderBy"
                                name="orderBy"
                                placeholder="Selecione uma forma de pagamento"
                                filter
                                filterBy="label"
                                optionLabel="label"
                                optionValue="value"
                                options={ORDENACAO_FILTROS}
                                value={formik.values.orderBy}
                                onChange={formik.handleChange}
                            />
                            {formik.errors.orderBy && <small className="p-error">{formik.errors.orderBy}</small>}
                        </div>
                        <MakoInputCheckBox
                            id="custo_liquido"
                            name="custo_liquido"
                            checked={formik.values.custo_liquido}
                            onChange={formik.handleChange}
                            label="Custo líquido"
                        />
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="descricao">Descrição</label>
                            <InputTextarea
                                id="descricao"
                                name="descricao"
                                value={formik.values.descricao}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.descricao })}
                                autoResize
                                rows={3}
                                maxLength={100}
                            />
                            {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                        </div>
                    </div>
                    <div className="p-grid p-col-12 p-md-6 p-mt-2">
                        <Button
                            label="Gerar PDF"
                            icon="pi pi-file-pdf"
                            type="button"
                            onClick={() => handlePreSubmit()}
                            className="p-button-info p-mr-2"
                        />
                        <Button
                            type="button"
                            onClick={() => setVisible(true)}
                            label="Enviar por email"
                            icon="pi pi-envelope"
                            className="p-button-info p-mr-2"
                        />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            onClick={() => limparFiltro()}
                            className="p-button-warning p-mr-2"
                        />
                    </div>
                </div>
            </div>
            <Dialog
                visible={visible}
                blockScroll
                onHide={fecharModal}
                style={{ width: "60vw" }}
                header="Informar emails"
            >
                <MakoSelecionarEmails onCancel={fecharModal} onConfirm={handleConfirm} />
            </Dialog>
        </div>
    );
};
