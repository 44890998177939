import React, { useCallback, useEffect, useRef, useState } from "react";
import { Timeline } from "primereact/timeline";
import { Card } from "primereact/card";
import { Tag } from "primereact/tag";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";

import { ModalNotificacoes } from "./ModalNotificacoes";
import { BotaoAprovarNotificacao } from "./BotaoAprovarNotificacao";
import { BotaoEscalarNotificacao } from "./BotaoEscalarNotificacao";
import { BotaoReprovarNotificacao } from "./BotaoReprovarNotificacao";
import { PageBase } from "@/components/PageBase";
import { STATUS_NOTIFICACAO_CHOICE, TIPO_NOTIFICACAO_CHOICE } from "@/assets/constants/notificacao";
import { dataToStr } from "@/assets/util/datas";
import { axiosGet } from "@/services/http";
import useToast from "@/hooks/useToast";

export const NotificacoesPage = () => {
    const [loading, setLoading] = useState(false);
    const [notificacoes, setNotificacoes] = useState([]);
    const modalNotificacoesRef = useRef(null);
    const { showError } = useToast();

    const listarNotificacoes = useCallback(async () => {
        setLoading(true);
        const { status, data } = await axiosGet("/pessoas/notificacoes-perfil/");
        setLoading(false);
        if (status === 200) {
            setNotificacoes(data.results);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos processar sua requisição.",
                life: 3000,
            });
        }
    }, [showError]);

    useEffect(() => {
        listarNotificacoes();
    }, [listarNotificacoes]);

    const nomeUsuarioEmitenteTemplate = (item) => {
        return <span>{dataToStr(item.datahora_criacao, "MMM/yy")}</span>;
    };

    const marcadorEmitente = (item) => {
        const nome = item.emitente.nome.split(" ");
        let sigla = "";
        if (nome.length >= 2) sigla = `${nome[0][0]}${nome[1][0]}`;
        else sigla = nome[0][0];
        return <Avatar label={sigla} shape="circle" />;
    };

    const tagTemplate = (item) => {
        const style = item._severity === "secondary" ? { background: "rgb(96, 125, 139)" } : null;
        if (item)
            return (
                <Tag
                    rounded
                    style={style}
                    severity={item._severity}
                    value={item.label.toUpperCase()}
                    className="p-mb-1"
                />
            );
        return <Tag rounded style={style} value="N/A" className="p-mb-1" />;
    };

    const contentTemplate = (item) => {
        const tipoChoice = TIPO_NOTIFICACAO_CHOICE.find((e) => e.value === item.tipo);
        const tipoHtml = tagTemplate(tipoChoice);
        const status = STATUS_NOTIFICACAO_CHOICE.find((e) => e.value === item.status);
        const statusHtml = tagTemplate(status);
        return (
            <Card
                title={item.nome}
                subTitle={`${dataToStr(item.datahora_criacao, "dd/MM/yyyy hh:mm")} - Gerado por ${
                    item.emitente.usuario.username
                }`}
                className="p-mt-2"
            >
                <span>
                    {statusHtml} {tipoHtml}
                </span>
                <p>{item.descricao}</p>
                <div className="p-grid p-fluid">
                    <div className="p-col-12 p-md-3 p-pb-0">
                        <Button
                            icon="pi pi-eye"
                            label="Detalhes"
                            className="p-button-help p-mb-1"
                            onClick={() => modalNotificacoesRef.current?.abrirModal(item)}
                        />
                    </div>
                    <div className="p-col-12 p-md-3 p-pb-0">
                        <BotaoEscalarNotificacao notificacao={item} />
                    </div>
                    <div className="p-col-12 p-md-3 p-pb-0">
                        <BotaoReprovarNotificacao notificacao={item} />
                    </div>
                    <div className="p-col-12 p-md-3 p-pb-0">
                        <BotaoAprovarNotificacao notificacao={item} />
                    </div>
                </div>
            </Card>
        );
    };

    return (
        <PageBase blocked={loading}>
            <h5>Notificações</h5>
            <Timeline
                align="right"
                value={notificacoes}
                opposite={nomeUsuarioEmitenteTemplate}
                content={contentTemplate}
                marker={marcadorEmitente}
            />
            <ModalNotificacoes ref={modalNotificacoesRef} />
        </PageBase>
    );
};
