import React, { useEffect } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";

import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";

import useCaixaMovimento from "@/hooks/useCaixaMovimento";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import useAuth from "@/hooks/useAuth";

import { NATUREZA_PLANO_CONTAS } from "@/assets/constants/constants";
import { TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE } from "@/assets/constants/financeiro";
import { axiosPost } from "@/services/http";

export const FormSangria = ({ fecharModal }) => {
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const { caixaMov, padraoPlanos } = useCaixaMovimento();
    const { user } = useAuth();

    const { setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            valor: 0,
            tipo_movimento: "D",
            data_movimentacao: new Date(),
            caixa_movimento: caixaMov?.id,
            historico: "Efetuação de sangria",
            conta_financeira: caixaMov?.caixa.conta_financeira.id,
            plano_contas: null,
            compensado: false,
            cancelado: false,
            usuario_lancamento: user.id,
            forma_pagamento: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                valor: Yup.number().required("O campo 'valor' é obrigatório").typeError("Informe um 'valor' válido"),
                data_movimentacao: Yup.date()
                    .required("O campo 'data da movimentação' é obrigatório")
                    .typeError("Informe uma 'data' válida"),
                historico: Yup.string()
                    .max(60, "Quantidade máxima de caracteres atingida: 60")
                    .required("O campo 'histórico' é obrigatório.")
                    .typeError("Informe um 'historico' válido"),
                tipo_movimento: Yup.string()
                    .required("O campo 'tipo' é obrigatório.")
                    .typeError("Informe um 'tipo' válido"),
                forma_pagamento: Yup.number()
                    .required("O campo 'forma' é obrigatório")
                    .typeError("Informe uma 'forma' válido"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const saldo_caixa =
                parseFloat(caixaMov.dinheiro_inicial) +
                caixaMov.resumos.reduce((total, item) => total + parseFloat(item.valor), 0);

            if (values.valor <= saldo_caixa) {
                values.plano_contas = padraoPlanos["sangria"];

                showLoading();
                const resposta = await axiosPost(`/financeiro/movimentacao-contas-corrente/`, {
                    ...values,
                    valor: values.valor * -1,
                });
                hideLoading();

                if (resposta.status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Sangria efetuada com sucesso!",
                        life: 1500,
                    });

                    fecharModal(true);
                } else {
                    throw new Error("Desculpe, não conseguimos processar sua requisição.");
                }
            } else {
                throw new Error("Desculpe, o caixa não possui saldo suficiente para essa transação.");
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            } else {
                showError({
                    severity: "error",
                    summary: "Erro :(",
                    detail: error.message,
                    life: 3000,
                });
            }
        }
    }

    useEffect(() => {
        resetForm();
    }, [resetForm]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <label htmlFor="data_movimentacao">Data *</label>
                    <MakoCalendar
                        id="data_movimentacao"
                        name="data_movimentacao"
                        valueCalendar={formik.values.data_movimentacao}
                        minDate={caixaMov.data_abertura}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.data_movimentacao })}
                    />
                    {formik.errors.data_movimentacao && (
                        <small className="p-error">{formik.errors.data_movimentacao}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-12">
                    <label htmlFor="tipo_movimento">Tipo *</label>
                    <Dropdown
                        id="tipo_movimento"
                        name="tipo_movimento"
                        placeholder="Selecione uma conta financeira"
                        options={NATUREZA_PLANO_CONTAS}
                        disabled
                        filter
                        filterBy="label"
                        optionValue="id"
                        optionLabel="label"
                        value={formik.values.tipo_movimento}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.tipo_movimento })}
                    />
                    {formik.errors.tipo_movimento && <small className="p-error">{formik.errors.tipo_movimento}</small>}
                </div>
                <div className="p-field p-col-12 p-md-12">
                    <label htmlFor="forma_pagamento">Forma *</label>
                    <Dropdown
                        id="forma_pagamento"
                        name="forma_pagamento"
                        placeholder="Selecione uma forma"
                        options={TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE}
                        optionValue="id"
                        optionLabel="label"
                        value={formik.values.forma_pagamento}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.forma_pagamento })}
                    />
                    {formik.errors.forma_pagamento && (
                        <small className="p-error">{formik.errors.forma_pagamento}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-12">
                    <label htmlFor="valor">Valor *</label>
                    <MakoInputMoeda
                        id="valor"
                        name="valor"
                        somentePositivo
                        valueMoeda={formik.values.valor}
                        onChangeMoeda={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.valor })}
                    />
                    {formik.errors.valor && <small className="p-error">{formik.errors.valor}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <label htmlFor="historico">Histórico *</label>
                    <InputTextarea
                        id="historico"
                        name="historico"
                        rows={4}
                        maxLength={60}
                        value={formik.values.historico}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.historico })}
                    />
                    {formik.errors.historico && <small className="p-error">{formik.errors.historico}</small>}
                </div>
            </div>
            <div>
                <Button label="Gravar" className="p-button-success p-mr-2" type="submit" />
                <Button label="Cancelar" className="p-button-danger" onClick={fecharModal} />
            </div>
        </form>
    );
};
