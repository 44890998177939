import React from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { Button } from "primereact/button";

import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";

import { OPCOES_PESQUISA_BOOLEAN } from "@/assets/constants/constants";
import { dataToStr } from "@/assets/util/datas";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";

export const FiltroAvancadoNecessidadeCompra = ({ onConfirm, onCancel, url }) => {
    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            solicitante: "",
            cliente: null,
            descritivo__contains: "",
            data: null,
            custo: false,
            ativo: null,
            sku__isnull: false,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                solicitante: Yup.string().nullable().typeError("Informe um 'solicitante' válido"),
                cliente: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'cliente' é obrigatório"),
                    })
                    .notRequired()
                    .nullable()
                    .typeError("Informe um 'cliente' válida"),
                descritivo__contains: Yup.string().nullable().typeError("Informe uma 'descrição' válida"),
                data: Yup.date().nullable().typeError("Informe uma 'data' válida"),
                ativo: Yup.number().nullable().typeError("Informe um 'status' válido"),
                custo: Yup.boolean().typeError("Informe um 'custo' válido"),
                sku__isnull: Yup.boolean().typeError("Informe um 'vinculo' válido"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const baseUrl = url;
            let params = [];

            Object.keys(values).forEach((key) => {
                if (values[key] !== null) {
                    if (key === "cliente") params.push(`${key}=${values[key].id}`);
                    if (key === "data") params.push(`${key}=${dataToStr(values[key], "yyyy-MM-dd")}`);
                    if (key === "ativo" && values[key] !== 0) {
                        const _status = values[key] === 2 ? false : true;
                        params.push(`${key}=${_status}`);
                    }
                    if (key === "custo" && !values[key]) params.push(`${key}=0`);
                    if (key === "sku__isnull" && !values[key]) params.push(`${key}=True`);
                    params.push(`${key}=${values[key]}`);
                }
            });
            if (params.length > 0) {
                const _url = baseUrl + "?" + params.join("&");
                if (typeof onConfirm === "function") onConfirm(_url);
            } else {
                if (typeof onConfirm === "function") onConfirm(baseUrl);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const autoCompleteClienteTemplate = (item) => {
        return <div>{`${item.nome} - ${item.identificacao}${item.telefone ? ` - ${item.telefone}` : ""}`}</div>;
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="solicitante">Solicitante:</label>
                    <InputText
                        id="solicitante"
                        name="solicitante"
                        value={formik.values.solicitante}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.solicitante })}
                    />
                    {formik.errors.solicitante && <small className="p-error">{formik.errors.solicitante}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="cliente">Cliente:</label>
                    <MakoAutoComplete
                        id="cliente"
                        name="cliente"
                        value={formik.values.cliente}
                        onChange={formik.handleChange}
                        key="id"
                        field="nome"
                        minCaracteresBusca={4}
                        itemTemplate={autoCompleteClienteTemplate}
                        urlSearch="/pessoas/perfis?query={id,nome,identificacao,tipo_pessoa,telefoneperfil_set}&ativo=true&nome__unaccent__icontains="
                        placeholder="Digite o nome do cliente para buscar... (min 4 caracteres)"
                        className={classNames({ "p-invalid": formik.errors.cliente })}
                    />
                    {formik.errors.cliente && <small className="p-error">{formik.errors.cliente}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="data">Data:</label>
                    <MakoCalendar
                        id="data"
                        name="data"
                        valueCalendar={formik.values.data}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.data })}
                    />
                    {formik.errors.data && <small className="p-error">{formik.errors.data}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="ativo">Status:</label>
                    <Dropdown
                        id="ativo"
                        name="ativo"
                        options={OPCOES_PESQUISA_BOOLEAN}
                        optionLabel="label"
                        optionValue="value"
                        value={formik.values.ativo}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.ativo })}
                    />
                    {formik.errors.ativo && <small className="p-error">{formik.errors.ativo}</small>}
                </div>
                <div className="p-field-checkbox p-col-12 p-md-1 p-mt-5">
                    <Checkbox id="custo" name="custo" checked={formik.values.custo} onChange={formik.handleChange} />
                    <label htmlFor="custo">Possui custo?</label>
                </div>
                <div className="p-field-checkbox p-col-12 p-md-1 p-mt-5">
                    <Checkbox
                        id="sku__isnull"
                        name="sku__isnull"
                        checked={formik.values.sku__isnull}
                        onChange={formik.handleChange}
                    />
                    <label htmlFor="sku__isnull">Possui vinculo sku?</label>
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <label htmlFor="descritivo__contains">Descrição</label>
                    <InputTextarea
                        id="descritivo__contains"
                        name="descritivo__contains"
                        value={formik.values.descritivo__contains}
                        onChange={formik.handleChange}
                        autoResize
                        rows={3}
                        className={classNames({ "p-invalid": formik.errors.descritivo__contains })}
                    />
                    {formik.errors.descritivo__contains && (
                        <small className="p-error">{formik.errors.descritivo__contains}</small>
                    )}
                </div>
            </div>
            <div className="p-grid p-justify-end p-mt-2">
                <Button type="submit" icon="pi pi-filter" label="Filtrar" className="p-mr-2" />
                <Button
                    type="reset"
                    icon="pi pi-trash"
                    label="Limpar"
                    onClick={() => formik.resetForm()}
                    className="p-button-warning p-mr-2"
                />
                <Button type="reset" label="Cancelar" onClick={() => onCancel()} className="p-button-danger p-mr-3" />
            </div>
        </form>
    );
};
