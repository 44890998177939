import React, { useCallback, useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";

import { MakoInputQuantidadeSku } from "@/components/MakoInputs/MakoInputQuantidadeSku";
import MakoListagem from "@/components/MakoListagem";

import { axiosGet } from "@/services/http";

import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

const ModalItem = ({ fecharModal, onConfirm }) => {
    const [itensVenda, setItensVenda] = useState([]);
    const [itensSelecionados, setItensSelecionados] = useState([]);

    const { showError, showSuccess } = useToast();
    const { showLoading, hideLoading } = useLoading();

    const listagemRef = useRef(null);

    async function handleSubmit() {
        try {
            itensSelecionados.forEach((item) => {
                if (typeof onConfirm === "function") {
                    let _dataItem = {
                        ...item,
                        item_venda: item.id,
                        valor_total: item.quantidade * item.valor_unitario,
                    };

                    delete _dataItem.id;

                    onConfirm(_dataItem);
                }
            });
            showSuccess({
                summary: "Sucesso",
                detail: `O(s) item(ns) adicionado(s) com sucesso.`,
                life: 3000,
            });
            fecharModal();
        } catch (error) {
            showError({
                summary: "Erro",
                detail: "Desculpe, não foi possível processar a sua requisição.",
                life: 3000,
            });
        }
    }

    const listaItensVenda = useCallback(async () => {
        showLoading();
        const { status, data } = await axiosGet(`/vendas/itens-vendas/?limit=10000&venda__situacao=F`);
        hideLoading();
        if (status === 200) {
            let itens_venda = [];
            await data.results.forEach((value) => {
                itens_venda.push({
                    id: value.id,
                    sku: {
                        ...value.sku,
                        label: `${value.sku.codigo} - ${value.sku.descricao_reduzida}`,
                    },
                    unidade_medida: value.unidade_venda,
                    quantidade: parseFloat(value.quantidade) - parseFloat(value.quantidade_devolvida),
                    valor_unitario: parseFloat(value.valor_unitario),
                    valor_total: 0,
                    largura: value.largura,
                    comprimento: value.comprimento,
                    altura: value.altura,
                    cubagem: value.cubagem,
                    venda_info: {
                        id_venda: value.venda.id,
                        label: `Código: ${value.venda.id} | Cliente: ${value.venda.cliente.nome} - ${value.venda.cliente.identificacao}`,
                    },
                });
            });

            setItensVenda(itens_venda);
        }
    }, [showLoading, hideLoading]);

    const editarQuantidade = (options, quantidade, aux) => {
        let _quantidade = quantidade;
        let novaLista = [];
        itensVenda.forEach(async (item) => {
            if (item.id === aux) {
                if (quantidade <= item.quantidade) {
                    item.quantidade = quantidade;
                } else {
                    _quantidade = item.quantidade;
                }
            }
            novaLista.push(item);
        });
        options.editorCallback(_quantidade);
        setItensVenda(novaLista);
    };

    const editorQuantidade = (options) => {
        return (
            <MakoInputQuantidadeSku
                id="quantidade"
                name="quantidade"
                min={0}
                permiteFracionario
                showButtons
                value={options.value}
                onValueChange={(e) => editarQuantidade(options, e.target.value, options.rowData.id)}
            />
        );
    };

    const colunas = [
        { selectionMode: "multiple", style: { width: "5rem" } },
        { field: "venda_info.label", header: "Venda/Orçamento" },
        {
            field: "sku.label",
            header: "Item",
        },
        {
            field: "quantidade",
            header: "Quantidade",
            editor: (options) => editorQuantidade(options),
        },
        {
            field: "unidade_medida.nome",
            header: "Unidade Medida",
        },
        {
            field: "valor_unitario",
            header: "Valor unitário",
            align: "end",
            money: true,
        },
        {
            field: "cubagem",
            header: "Cubagem",
        },
    ];

    useEffect(() => {
        listaItensVenda();
    }, [listaItensVenda]);

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <form>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label>Selecione um ou mais itens para inserção:</label>
                            <MakoListagem
                                ref={listagemRef}
                                colunas={colunas}
                                dadosLocal={itensVenda}
                                configTabela={{
                                    scrollable: true,
                                    scrollHeight: "400px",
                                    selection: itensSelecionados,
                                    onSelectionChange: (e) => setItensSelecionados(e.value),
                                    selectionMode: "multiple",
                                }}
                            />
                        </div>
                    </div>
                    <div className="p-grid p-col-12 p-md-12 p-justify-end p-mt-1">
                        <Button
                            icon="pi pi-plus"
                            label="Inserir Itens"
                            type="button"
                            className="p-button-success p-mr-2"
                            disabled={!itensSelecionados?.length > 0}
                            onClick={() => handleSubmit()}
                        />
                        <Button
                            icon="pi pi-times"
                            label="Fechar"
                            type="reset"
                            className="p-button-danger"
                            onClick={() => fecharModal()}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default React.memo(ModalItem);
