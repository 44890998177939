import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";

import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import { axiosDelete } from "@/services/http";
import useToast from "@/hooks/useToast";
import { CodigoFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";

const url = "/produtos/tags-imagens-sku/";

export const ProdutoTagsImgPage = () => {
    const [redirecionar, setRedirecionar] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);

    const [tagImg, setTagImg] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const listagemRef = useRef(null);

    const confirmarDelete = (tagImg) => {
        setTagImg(tagImg);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const deleteParametro = async () => {
        showLoading();
        const resposta = await axiosDelete(`${url}${tagImg.id}`);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            showSuccess({
                summary: "Sucesso",
                detail: "Tag de imagem deletada com sucesso!",
                life: 3000,
            });

            listagemRef.current?.buscarDados();
        } else {
            showError({
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
        setDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_CADASTRO_TAGSIMAGEMSKU_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de tag de imagem"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        setRedirecionar({
                            pathname: "/produto/cadastros/tags-imagens/form",
                            state: { tagImg: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_CADASTRO_TAGSIMAGEMSKU_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Deletar tag de imagem"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.PRODUTO_CADASTRO_TAGSIMAGEMSKU_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setRedirecionar("/produto/cadastros/tags-imagens/form")}
            />
        </>
    );

    const colunas = [
        { field: "id", header: "Código", style: { width: "15%" }, filter: true, filterElement: CodigoFiltroTemplate },
        { field: "tag", header: "Tag", filter: true, filterElement: TextoFiltroTemplate },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "15%" },
        },
    ];

    return redirecionar ? (
        <Redirect to={redirecionar} />
    ) : (
        <>
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <MakoListagem
                            ref={listagemRef}
                            titulo="Tags de Imagem do Produto"
                            colunas={colunas}
                            painelEsquerdo={cabecalhoTabela}
                            urlPesquisa={url}
                            configTabela={{
                                paginator: true,
                                lazy: true,
                            }}
                            filtros={{
                                id: { value: null, matchMode: "equals" },
                                tag: {
                                    operator: "and",
                                    constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                                },
                            }}
                        />
                        <ConfirmDialog
                            visible={deleteDialog}
                            onHide={esconderDeleteDialog}
                            header="Confirmação"
                            message={
                                tagImg && (
                                    <span>
                                        {"Deseja realmente excluir a tag de imagem "}
                                        <b>{tagImg.nome}</b>?
                                    </span>
                                )
                            }
                            icon="pi pi-info-circle p-mr-3"
                            accept={deleteParametro}
                            acceptLabel="Sim"
                            acceptClassName="p-button-danger"
                            reject={esconderDeleteDialog}
                            rejectLabel="Não"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
