import { VendasServicosPage } from "@/pages/Vendas/Cadastros/Servicos";
import { VendasServicosForm } from "@/pages/Vendas/Cadastros/Servicos/form";
import { TipoEstagioPage } from "@/pages/Vendas/Cadastros/TipoEstagio";
import { TipoEstagioForm } from "@/pages/Vendas/Cadastros/TipoEstagio/form";
import { EstagioVendaPage } from "@/pages/Vendas/Cadastros/EstagioVenda";
import { EstagioVendaForm } from "@/pages/Vendas/Cadastros/EstagioVenda/form";
import { VendasPage } from "@/pages/Vendas/Venda";
import { TabVendasContext } from "@/pages/Vendas/Venda/form";
import { SequenciaEstagioPage } from "@/pages/Vendas/Cadastros/EstagioVenda/SequenciaEstagio";
import { ComissaoVendasPage } from "@/pages/Vendas/Cadastros/ComissaoVenda";
import { ComissaoVendasForm } from "@/pages/Vendas/Cadastros/ComissaoVenda/form";
import { FormacaoPrecoPage } from "@/pages/Vendas/Cadastros/FormacaoPrecos";
import { FormacaoPrecoForm } from "@/pages/Vendas/Cadastros/FormacaoPrecos/form";
import { VendasConsultaSpcForm } from "@/pages/Vendas/ConsultaSpc/form";
import { VendasConsultaSpcPage } from "@/pages/Vendas/ConsultaSpc";
import { RegraArredondamentoValorPage } from "@/pages/Vendas/Cadastros/ArredondamentoValor";
import { TabRegraArredondamentoValor } from "@/pages/Vendas/Cadastros/ArredondamentoValor/TabRegraArredondamentoValor";
import { CarteiraClientePage } from "@/pages/Vendas/Cadastros/CarteiraCliente";
import { CarteiraClienteForm } from "@/pages/Vendas/Cadastros/CarteiraCliente/form";
import { RotaEntregaPage } from "@/pages/Vendas/Cadastros/RotaEntrega";
import { RotaEntregaForm } from "@/pages/Vendas/Cadastros/RotaEntrega/form";
import { RegraApuracaoComissaoPage } from "@/pages/Vendas/Cadastros/RegraApuracaoComissao";
import { RegraApuracaoComissaoForm } from "@/pages/Vendas/Cadastros/RegraApuracaoComissao/form";
import { FaixaComissaoForm } from "@/pages/Vendas/Cadastros/FaixaComissao/form";
import { FaixaComissaoPage } from "@/pages/Vendas/Cadastros/FaixaComissao";
import { ApuracaoComissaoPage } from "@/pages/Vendas/Comissao/ApuracaoComissao";
import { ApuracaoComissaoForm } from "@/pages/Vendas/Comissao/ApuracaoComissao/form";
import { EntregasPage } from "@/pages/Vendas/Entregas";
import { VoucherPage } from "@/pages/Vendas/Cadastros/Vouchers";
import { VoucherForm } from "@/pages/Vendas/Cadastros/Vouchers/form";
import { VendaTrocaPage } from "@/pages/Vendas/Trocas";
import { TabTrocaContext } from "@/pages/Vendas/Trocas/tabTrocas";
import { MotivoDevolucaoPage } from "@/pages/Vendas/Cadastros/MotivoDevolucao";
import { MotivoDevolucaoForm } from "@/pages/Vendas/Cadastros/MotivoDevolucao/form";
import { VendaDevolucaoPage } from "@/pages/Vendas/Devolucao";
import { DevolucaoVendaForm } from "@/pages/Vendas/Devolucao/DevolucaoForm";
import { ConsultaPrecosPage } from "@/pages/Vendas/ConsultaPrecos";
import { OrcamentoRapidoForm } from "@/pages/Vendas/OrcamentoRapido/form";
import { OrcamentoRapidoPage } from "@/pages/Vendas/OrcamentoRapido";
import { RelatorioTabelaProdutosVendas } from "@/pages/Relatorios/Vendas/TabelaProdutosVendas";
import { RelatorioCurvaAbcPage } from "@/pages/Relatorios/Vendas/CurvaABC";
import { RelatorioExtratoComissaoSintetico } from "@/pages/Relatorios/Vendas/ExtratoComissaoSintetico";
import { RelatorioVendasListagemSimples } from "@/pages/Relatorios/Vendas/ListagemSimples";
import { RelatorioExtratoComissao } from "@/pages/Relatorios/Vendas/ExtratoComissaoAnalitico";
import { RelatorioHistoricoSimplesCliente } from "@/pages/Relatorios/Vendas/HistoricoSimplesCliente";
import { ProdutoTabelaPrecosPage } from "@/pages/Vendas/Cadastros/TabelaPrecos";
import { ProdutoTabelaPrecosForm } from "@/pages/Vendas/Cadastros/TabelaPrecos/form";
import { VigenciaPrecoPage } from "@/pages/Vendas/Cadastros/VigenciaPreco";
import { VigenciaPrecoForm } from "@/pages/Vendas/Cadastros/VigenciaPreco/form";

export const vendasRoutes = [
    { path: "/vendas/venda", component: VendasPage },
    { path: "/vendas/venda/form", component: TabVendasContext },
    { path: "/vendas/venda/trocas", component: VendaTrocaPage },
    { path: "/vendas/venda/trocas/form", component: TabTrocaContext },
    { path: "/vendas/venda/devolucao", component: VendaDevolucaoPage },
    { path: "/vendas/venda/devolucao/form", component: DevolucaoVendaForm },
    { path: "/vendas/venda/orcamento-rapido", component: OrcamentoRapidoPage },
    { path: "/vendas/venda/orcamento-rapido/form", component: OrcamentoRapidoForm },
    { path: "/vendas/venda/consultas-spc", component: VendasConsultaSpcPage },
    { path: "/vendas/venda/consultas-spc/form", component: VendasConsultaSpcForm },
    { path: "/vendas/venda/entregas", component: EntregasPage },
    { path: "/vendas/cadastros/consulta-precos", component: ConsultaPrecosPage },
    { path: "/vendas/cadastros/regra-apuracao-comissao", component: RegraApuracaoComissaoPage },
    { path: "/vendas/cadastros/regra-apuracao-comissao/form", component: RegraApuracaoComissaoForm },
    { path: "/vendas/cadastros/apuracao-comissao", component: ApuracaoComissaoPage },
    { path: "/vendas/cadastros/apuracao-comissao/form", component: ApuracaoComissaoForm },
    { path: "/vendas/cadastros/faixa-comissao", component: FaixaComissaoPage },
    { path: "/vendas/cadastros/faixa-comissao/form", component: FaixaComissaoForm },
    { path: "/vendas/cadastros/motivo-devolucao", component: MotivoDevolucaoPage },
    { path: "/vendas/cadastros/motivo-devolucao/form", component: MotivoDevolucaoForm },
    { path: "/vendas/cadastros/servicos", component: VendasServicosPage },
    { path: "/vendas/cadastros/servicos/form", component: VendasServicosForm },
    { path: "/vendas/cadastros/tipos-estagios", component: TipoEstagioPage },
    { path: "/vendas/cadastros/tipos-estagios/form", component: TipoEstagioForm },
    { path: "/vendas/cadastros/estagios-vendas", component: EstagioVendaPage },
    { path: "/vendas/cadastros/estagios-vendas/form", component: EstagioVendaForm },
    { path: "/vendas/cadastros/estagios-vendas/sequencia", component: SequenciaEstagioPage },
    { path: "/vendas/cadastros/arredondamento-valor", component: RegraArredondamentoValorPage },
    { path: "/vendas/cadastros/arredondamento-valor/form", component: TabRegraArredondamentoValor },
    { path: "/vendas/cadastros/comissao-vendas", component: ComissaoVendasPage },
    { path: "/vendas/cadastros/comissao-vendas/form", component: ComissaoVendasForm },
    { path: "/vendas/cadastros/formacao-precos", component: FormacaoPrecoPage },
    { path: "/vendas/cadastros/formacao-precos/form", component: FormacaoPrecoForm },
    { path: "/vendas/cadastros/carteira-clientes", component: CarteiraClientePage },
    { path: "/vendas/cadastros/carteira-clientes/form", component: CarteiraClienteForm },
    { path: "/vendas/cadastros/rota-entrega", component: RotaEntregaPage },
    { path: "/vendas/cadastros/rota-entrega/form", component: RotaEntregaForm },
    { path: "/vendas/cadastros/voucher", component: VoucherPage },
    { path: "/vendas/cadastros/voucher/form", component: VoucherForm },
    { path: "/vendas/cadastros/tabela-precos", component: ProdutoTabelaPrecosPage },
    { path: "/vendas/cadastros/tabela-precos/form", component: ProdutoTabelaPrecosForm },
    { path: "/vendas/relatorios/tabela-produtos", component: RelatorioTabelaProdutosVendas },
    { path: "/vendas/relatorios/curva-abc", component: RelatorioCurvaAbcPage },
    { path: "/vendas/relatorios/extrato-comissao-sintetico", component: RelatorioExtratoComissaoSintetico },
    { path: "/vendas/relatorios/listagem-simples", component: RelatorioVendasListagemSimples },
    { path: "/vendas/relatorios/extrato-comissao-analitco", component: RelatorioExtratoComissao },
    { path: "/vendas/relatorios/historico-simples-cliente", component: RelatorioHistoricoSimplesCliente },
    { path: "/vendas/cadastros/vigencia-preco", component: VigenciaPrecoPage },
    { path: "/vendas/cadastros/vigencia-preco/form", component: VigenciaPrecoForm },
];
