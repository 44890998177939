import React, { useState, useCallback, useEffect, useReducer } from "react";
import { endOfWeek, setDay, startOfWeek, subDays, subMonths } from "date-fns";

import { Button } from "primereact/button";
import { Tag } from "primereact/tag";

import {
    MakoCardGraficoPizza,
    MakoCardItemProgresso,
    MakoCardLineChart,
    MakoCardProdutoInfo,
    MakoCardUsuarios,
    MakoCardListagemTabela,
} from "@/components/MakoCards";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";

import { ACTIONS_DASHBOARD, baseChart, dashboardReducer, initialIndicadoresReducer } from "./dashboardReducer";
import { DashboardCardsContainer } from "./cardsContainer";

import { SITUACAO_VENDA_CHOICE, TIPOS_MESSES_ANO } from "@/assets/constants/constants";
import { dataToStr } from "@/assets/util/datas";
import permissoes from "@/assets/constants/permissoes";

import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";
import useAuth from "@/hooks/useAuth";
import useAxiosPrivate from "@/hooks/useAxiosPrivate";

const ordersChartOptions = {
    legend: {
        display: true,
    },
    responsive: true,
    hover: {
        mode: "index",
    },
    scales: {
        yaxis: {
            min: 0,
            ticks: {
                precision: 0,
            },
        },
    },
};

const baseUrlVendas = "/dashboard/vendas-recentes/";
const startSemana = startOfWeek(new Date(), { weekStartsOn: 0 });
const endSemana = endOfWeek(new Date(), { weekStartsOn: 0 });
const hoje = new Date();

export const Dashboard = () => {
    const [indicadores, dispatchIndicadores] = useReducer(dashboardReducer, initialIndicadoresReducer);
    const { empresaSelecionadaId } = useEmpresa();
    const { axiosGet } = useAxiosPrivate();
    const { showError } = useToast();
    const { user } = useAuth();

    // useEffect(() => console.log(indicadores), [indicadores]);

    const orderWeek = [
        { name: "Essa semana", code: "0" },
        { name: "Semana passada", code: "1" },
    ];

    const [selectedOrderWeek, setSelectedOrderWeek] = useState(orderWeek[0]);
    const [urlVendas, setUrlVendas] = useState(() => {
        return (
            baseUrlVendas +
            `?data_start=${dataToStr(startSemana, "yyyy-MM-dd")}&data_end=${dataToStr(endSemana, "yyyy-MM-dd")}`
        );
    });

    function randomRGBAColor(opacidade = 0.6) {
        var color = [];
        for (var i = 0; i < 3; i++) {
            var number = Math.floor(Math.random() * 256);
            color = [...color, number];
        }

        // color = `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${opacidade})`;
        return [...color, opacidade];
    }

    const vendidosItems = [
        {
            column_1: "Bamboo Watch",
            column_2: "82",
            src: "assets/demo/images/product/bamboo-watch.jpg",
            alt: "diamond-layout",
        },
        {
            column_1: "Blue Band",
            column_2: "75",
            src: "assets/demo/images/product/blue-band.jpg",
            alt: "diamond-layout",
        },
        {
            column_1: "Game Controller",
            column_2: "64",
            src: "assets/demo/images/product/game-controller.jpg",
            alt: "diamond-layout",
        },
        {
            column_1: "Lime Band",
            column_2: "62",
            src: "assets/demo/images/product/lime-band.jpg",
            alt: "diamond-layout",
        },
        {
            column_1: "Phone Case",
            column_2: "55",
            src: "assets/demo/images/product/gold-phone-case.jpg",
            alt: "diamond-layout",
        },
        {
            column_1: "Green T-Shirt",
            column_2: "48",
            src: "assets/demo/images/product/green-t-shirt.jpg",
            alt: "diamond-layout",
        },
        {
            column_1: "Purple T-Shirt",
            column_2: "32",
            src: "assets/demo/images/product/purple-t-shirt.jpg",
            alt: "diamond-layout",
        },
    ];

    const chartProps = {
        id: "revenue-chart",
        data: {
            labels: ["Direta", "Promovida", "Afiliada"],
            datasets: [
                {
                    data: [40, 35, 25],
                    backgroundColor: ["#64B5F6", "#7986CB", "#4DB6AC"],
                },
            ],
        },
    };

    const actionBodyTemplateUsuarios = (rowData) => {
        return (
            <>
                <Button type="button" className="p-button-rounded p-button-success" icon="pi pi-envelope" disabled />,
                <Button type="button" className="p-button-rounded p-button-warning" icon="pi pi-cog" disabled />
            </>
        );
    };

    const dataFiltroTemplate = (options) => {
        return (
            <MakoCalendar
                valueCalendar={options.value}
                onChange={(e) => options.filterCallback(dataToStr(e.value, "yyyy-MM-dd"))}
            />
        );
    };

    const situacaoFiltroTemplate = (options) => {
        return (
            <Dropdown
                placeholder="Selecione"
                value={options.value}
                options={SITUACAO_VENDA_CHOICE}
                onChange={(e) => options.filterCallback(e.value)}
            />
        );
    };

    const valorFiltroTemplate = (options) => {
        return (
            <MakoInputMoeda
                valueMoeda={options.value}
                onChangeMoeda={(e) => options.filterCallback(e.value)}
                somentePositivo
            />
        );
    };

    const tableVendas = useCallback(() => {
        const aposPesquisar = (values) => {
            if (Array.isArray(values) && values.length > 0) {
                const _data = values.filter((item) => item.vendedor === user.id);

                return _data;
            }
            return values;
        };

        const semEmpresa = empresaSelecionadaId === null;

        return {
            limit: 10,
            titulo: semEmpresa ? <span className="p-error">Selecione uma empresa para exibirmos a venda</span> : null,
            colunas: [
                {
                    field: "id",
                    header: "Orçamento",
                    sortable: true,
                    style: { width: "8%" },
                },
                {
                    field: "data",
                    header: "Data",
                    filter: true,
                    filterElement: dataFiltroTemplate,
                    dateFormat: "dd/MM/yyyy",
                    style: { width: "10%" },
                },
                {
                    field: "cliente.nome",
                    header: "Cliente",
                },
                {
                    field: "valor_total_ap",
                    header: "Valor",
                    filter: true,
                    filterElement: valorFiltroTemplate,
                    money: true,
                    style: { width: "8%" },
                },
                {
                    field: "situacao",
                    header: "Situação",
                    align: "center",
                    filter: true,
                    filterElement: situacaoFiltroTemplate,
                    action: ({ situacao }) => statusBodyTemplate(situacao),
                    style: { width: "15%" },
                },
            ],
            urlPesquisa: semEmpresa ? "" : `${urlVendas}&empresa=${empresaSelecionadaId}&vendedor__id=${user.id}`,
            aposPesquisar: aposPesquisar,
            configTabela: {
                paginator: true,
                lazy: true,
                filterDisplay: "menu",
            },
            filtros: {
                data: { value: "", matchMode: "equals" },
                situacao: { value: "", matchMode: "equals" },
                valor_total_ap: { value: null, matchMode: "equals" },
            },
        };
    }, [urlVendas, empresaSelecionadaId, user]);

    const recentSales = (event) => {
        const key = event.value.code;

        const semanaLimiters = {
            0: {
                start: dataToStr(startSemana, "yyyy-MM-dd"),
                end: dataToStr(endSemana, "yyyy-MM-dd"),
            },
            1: {
                start: dataToStr(subDays(startSemana, 7), "yyyy-MM-dd"),
                end: dataToStr(subDays(startSemana, 1), "yyyy-MM-dd"),
            },
        };

        setUrlVendas(baseUrlVendas + `?data_start=${semanaLimiters[key].start}&data_end=${semanaLimiters[key].end}`);

        setSelectedOrderWeek(event.value);
    };

    const dropdownVendasProps = {
        options: orderWeek,
        value: selectedOrderWeek,
        optionLabel: "name",
        onChange: recentSales,
        className: "dashbard-demo-dropdown",
    };

    const statusBodyTemplate = (data) => {
        const status = SITUACAO_VENDA_CHOICE.find((item) => item.value === data);
        return <Tag severity={status.tag} value={status.label.toUpperCase()} />;
    };

    const fetchPedidosGraficoInfo = useCallback(
        async (signal) => {
            if (empresaSelecionadaId) {
                const inicio = dataToStr(setDay(subMonths(hoje, 6), 1), "yyyy-MM-dd");
                const fim = dataToStr(hoje, "yyyy-MM-dd");
                const response = await axiosGet(
                    `/dashboard/pedidos-totalizador/?date_start=${inicio}&date_end=${fim}&empresa_id=${empresaSelecionadaId}`,
                    { signal }
                );
                if (response.status === 200) {
                    const data = response.data.dados;
                    let copy = { ...baseChart };

                    const labels = data.labels.map((item) => {
                        return TIPOS_MESSES_ANO.find((m) => m.value === item).label;
                    });
                    const icons = [
                        { value: "P", icon: "pi pi-plus-circle" },
                        { value: "F", icon: "pi pi-check" },
                        { value: "C", icon: "pi pi-times-circle" },
                        { value: "TR", icon: "pi pi-check-circle" },
                    ];
                    const situacoes = [];
                    Object.keys(data.results).forEach((key) => {
                        const color = randomRGBAColor();
                        const situacao = SITUACAO_VENDA_CHOICE.find((t) => t.value === key);
                        if (situacao) {
                            situacoes.push({
                                value: situacao.value,
                                label: situacao.label + "s",
                                data_fill: `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`,
                                data_stroke: `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3] + 0.2})`,
                            });
                        }
                    });
                    const color = randomRGBAColor();
                    situacoes.push({
                        value: "TR",
                        label: "Trocas",
                        data_stroke: `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`,
                        data_fill: `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3] + 0.2})`,
                    });

                    copy.tabs = situacoes.map((item) => {
                        return {
                            data_label: item.label,
                            data_stroke: item.data_stroke,
                            data_fill: item.data_fill,
                            icon: icons.find((i) => i.value === item.value).icon,
                            text: item.label,
                            src_graph: null,
                        };
                    });
                    copy.datasets = Object.keys(data.results).map((key) => {
                        const situacao = situacoes.find((s) => s.value === key);
                        return {
                            fill: true,
                            label: situacao.label,
                            borderWidth: 3,
                            data: data.results[key],
                            backgroundColor: situacao.data_fill,
                            borderColor: situacao.data_stroke,
                        };
                    });
                    copy.labels = labels;
                    return copy;
                }
            }
            return baseChart;
        },
        [empresaSelecionadaId, axiosGet]
    );

    const fetchTarefas = useCallback(
        async (signal) => {
            if (empresaSelecionadaId) {
                const response = await axiosGet(
                    `/dashboard/tarefas-totalizador/?date_start=${dataToStr(
                        startSemana,
                        "yyyy-MM-dd"
                    )}&date_end=${dataToStr(endSemana, "yyyy-MM-dd")}&empresa_id=${empresaSelecionadaId}`,
                    { signal }
                );

                if (response.status === 200) {
                    return response.data.itens;
                }
            }
            return [];
        },
        [axiosGet, empresaSelecionadaId]
    );

    const fetchIndicadores = useCallback(
        async (signal) => {
            try {
                dispatchIndicadores({ type: ACTIONS_DASHBOARD.SHOW_LOADING });
                const response = await Promise.all([fetchTarefas(signal), fetchPedidosGraficoInfo(signal)]);
                dispatchIndicadores({ type: ACTIONS_DASHBOARD.UPDATE_PEDIDOS, payload: { value: response[1] } });
                dispatchIndicadores({ type: ACTIONS_DASHBOARD.UPDATE_TAREFAS, payload: { value: response[0] } });
                dispatchIndicadores({ type: ACTIONS_DASHBOARD.HIDE_LOADING });
            } catch (error) {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos processar a sua requisição",
                    life: 3000,
                });
            }
        },
        [fetchTarefas, fetchPedidosGraficoInfo, showError]
    );

    useEffect(() => {
        const controller = new AbortController();
        fetchIndicadores(controller.signal);

        return () => {
            controller.abort();
        };
    }, [fetchIndicadores]);

    return (
        <div className="layout-dashboard">
            <div className="p-grid">
                <DashboardCardsContainer />
                {empresaSelecionadaId && (
                    <div className="p-col-12 p-xl-6">
                        <MakoControleAcesso
                            permissao={[permissoes.DASHBOARD_INDICATIVO_VENDAS_PEDIDOSTOTALIZADOR]}
                            componente={MakoCardLineChart}
                            title="Pedidos"
                            tabs={indicadores.pedidos.tabs}
                            ordersChart={indicadores.pedidos}
                            ordersChartOptions={ordersChartOptions}
                            idChart="order-chart"
                            menuItems={indicadores.pedidos.itemsMenu}
                            loading={indicadores.loading}
                        />
                    </div>
                )}

                <div className="p-col-12 p-xl-6">
                    <MakoControleAcesso
                        permissao={[permissoes.DASHBOARD_INDICATIVO_VENDAS_VENDASRECENTES]}
                        componente={MakoCardListagemTabela}
                        title="Vendas Recentes"
                        subtitle="Sua atividade de vendas ao longo do tempo."
                        tableProps={tableVendas()}
                        dropdownProps={dropdownVendasProps}
                    />
                </div>
                <div className="p-col-12 p-lg-4">
                    {empresaSelecionadaId ? (
                        <MakoControleAcesso
                            permissao={[permissoes.DASHBOARD]}
                            componente={MakoCardItemProgresso}
                            title="Tarefas"
                            subtitle="Visão geral de suas tarefas pendentes."
                            items={indicadores.tarefas}
                            loading={indicadores.loading}
                        />
                    ) : null}

                    <MakoControleAcesso
                        permissao={[permissoes.DASHBOARD]}
                        componente={MakoCardProdutoInfo}
                        title="Mais vendidos"
                        subtitle="Produtos mais populares de sua coleção."
                        column_1="Produtos"
                        column_2="Vendas"
                        items={vendidosItems}
                    />
                </div>

                <div className="p-col-12 p-lg-8">
                    <MakoControleAcesso
                        permissao={[permissoes.DASHBOARD]}
                        componente={MakoCardGraficoPizza}
                        title="Fluxo de receitas"
                        subtitle="Comparação de suas fontes de receita."
                        chartProps={chartProps}
                    />
                    <MakoControleAcesso
                        permissao={[permissoes.DASHBOARD_INDICATIVO_PESSOAS_LISTARATIVOS]}
                        componente={MakoCardUsuarios}
                        title="Membros do time"
                        actionTemplate={actionBodyTemplateUsuarios}
                    />
                </div>
            </div>
        </div>
    );
};
