import React, { useCallback, useEffect, useRef, useState, useImperativeHandle, forwardRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";

import { dataToStr } from "@/assets/util/datas";
import { parseMoeda } from "@/assets/util/util";
import { DJANGO_FILTERS_MATCHMODE } from "@/assets/constants/constants";
import { parseNumber } from "@/assets/helpers/number";
import { axiosGet } from "@/services/http";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";
import { useLocalFiltro } from "@/hooks/useLocalFiltro";

// DOC...
// ref: (ref) Referência do componente da árvore DOM.
// colunas: (array) Array de objetos de colunas, pode receber qualquer propriedade do componente Column do PrimeReact
//      -> EXEMPLO: [{ field: "id", header: "Código", action: (e) => console.log(e)}].
// painelEsquerdo: (any) Conjunto de componentes que devem aparecer no cabecalho da tabela.
// botaoExportar: (bool) Se o botão de exportar deve aparecer ou não.
// fieldFiltroEmpresa: (string) Define o campo onde deve ser aplicado o filtro de empresa. Ex: (empresa, perfil, pessoa)
// configTabela: (obj) Todas as configurações do componente DataTable do PrimeReact podem ser passadas nesse objeto.
// filtros: (obj) Objeto contendo as opções de filtro

const Listagem = (
    {
        titulo,
        colunas = [],
        limit = 20,
        dadosLocal,
        painelEsquerdo,
        painelDireito,
        botaoExportar,
        urlPesquisa,
        fazerBusca = true,
        filtarPorEmpresa,
        naoBuscarSemEmpresa,
        fieldFiltroEmpresa = "empresa",
        aposPesquisar,
        configTabela = {},
        msgTabelaVazia,
        filtros,
        keyFiltrosStorage = null,
    },
    ref
) => {
    const [lazyParams, setLazyParams] = useLocalFiltro(keyFiltrosStorage, {
        first: 0,
        rows: limit,
        page: 0,
        filters: filtros,
    });
    const [dados, setDados] = useState([]);
    const [quantRegistros, setQuantRegistros] = useState(0);
    const [possuiFiltro, setPossuiFiltro] = useState(false);
    const [loading, setLoading] = useState(false);
    const listagemRef = useRef(null);
    const { empresaSelecionadaId } = useEmpresa();
    const { showWarning, showError } = useToast();

    useImperativeHandle(ref, () => ({ buscarDados: popularTabela, filter, trocarFilters }));

    function adicionarClausulaFiltro(filtros, field, matchMode, value) {
        if (value !== null && value !== undefined && value.toString().length > 0) {
            filtros[`${field}${DJANGO_FILTERS_MATCHMODE[matchMode] || ""}`] =
                value instanceof Object ? value?.id : value;
        }

        return filtros;
    }

    const limparFiltros = useCallback(() => {
        let _filtros = {};
        for (const [k, v] of Object.entries(filtros)) {
            const { advanced } = v;

            if (!advanced) {
                _filtros[k] = v;
            }
        }

        setPossuiFiltro(false);
        setLazyParams({ ...lazyParams, filters: _filtros });
    }, [lazyParams, filtros, setLazyParams]);

    const trocarFilters = useCallback(() => {
        setPossuiFiltro(false);
        setLazyParams((prev) => ({ ...prev, filters: filtros }));
    }, [filtros, setLazyParams, setPossuiFiltro]);

    const criarFiltro = useCallback((filtros) => {
        let filtroPronto = {};
        for (let [k, v] of Object.entries(filtros)) {
            let { value, matchMode, constraints } = v;

            if (constraints instanceof Array) {
                let aux = {};
                constraints.forEach((constraint) => {
                    const { matchMode: constraintMatchMode, value: constraintValue } = constraint;
                    aux = adicionarClausulaFiltro(aux, k, constraintMatchMode, constraintValue);
                });

                filtroPronto = { ...filtroPronto, ...aux };
            }

            filtroPronto = adicionarClausulaFiltro(filtroPronto, k, matchMode, value);
        }

        return filtroPronto;
    }, []);

    const buscarDadosBackend = useCallback(async () => {
        if (naoBuscarSemEmpresa && !empresaSelecionadaId) {
            setDados([]);
            return null;
        }

        if (typeof urlPesquisa !== "string" && !fazerBusca) return null;
        const [urlBase, query] = urlPesquisa.split("?");
        const limit = parseInt(lazyParams.rows);
        const offset = limit * parseInt(lazyParams.page);
        let params = { limit, offset };

        if (query) {
            const queryParams = query.split("&");

            queryParams.forEach((param) => {
                const [campo, valor] = param.split("=");
                params[campo] = valor;
            });
        }

        if (lazyParams.filters) {
            const _filtro = criarFiltro(lazyParams.filters);

            params = {
                ...params,
                ..._filtro,
            };
        }

        if (filtarPorEmpresa && empresaSelecionadaId) {
            params = {
                ...params,
                [fieldFiltroEmpresa]: empresaSelecionadaId,
            };
        }

        setLoading(true);
        const { status, data } = await axiosGet(urlBase, { params });
        setLoading(false);

        if (status === 200) {
            const { results, ...info } = data;
            setQuantRegistros(info.count);

            if (typeof aposPesquisar === "function") {
                setDados(aposPesquisar(results));
            } else {
                setDados(results);
            }
        } else if (status === 401) {
            showWarning({
                summary: "Acesso expirado!",
                detail: "Você não está autenticado, faça login novamente.",
                life: 3000,
            });
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos listar suas informações.",
                life: 3000,
            });
        }
    }, [
        filtarPorEmpresa,
        fieldFiltroEmpresa,
        naoBuscarSemEmpresa,
        empresaSelecionadaId,
        lazyParams,
        urlPesquisa,
        fazerBusca,
        aposPesquisar,
        criarFiltro,
        showWarning,
        showError,
    ]);

    const popularTabela = useCallback(async () => {
        if (dadosLocal) {
            if (typeof aposPesquisar === "function") {
                setDados(aposPesquisar(dadosLocal));
            } else {
                setDados(dadosLocal);
            }
        } else if (urlPesquisa) {
            buscarDadosBackend();
        }
    }, [dadosLocal, urlPesquisa, aposPesquisar, buscarDadosBackend]);

    useEffect(() => {
        popularTabela();
    }, [popularTabela]);

    const limparFiltroTemplate = (options) => {
        return (
            <Button
                type="button"
                icon="pi pi-times"
                label="Limpar"
                onClick={options.filterClearCallback}
                className="p-button-secondary p-mr-1"
            />
        );
    };

    const aplicarFiltroTemplate = (options) => {
        return (
            <Button
                type="button"
                icon="pi pi-check"
                label="Aplicar"
                onClick={options.filterApplyCallback}
                className="p-button-success p-ml-1"
            />
        );
    };

    const getPropObjetoAninhado = (rowData, field) => {
        const path = field.split(".");

        for (const p of path) {
            if (p && rowData[p]) {
                rowData = rowData[p];
            } else {
                rowData = null;
                break;
            }
        }

        return rowData;
    };

    const colunasDinamicas = colunas.map((col, i) => {
        const { field, header, action, money, dateFormat, decimal, ...rest } = col;
        let template = null;
        let align = null;
        if (action) {
            template = action;
        } else if (money) {
            template = (e) => parseMoeda(getPropObjetoAninhado(e, field), false);
            align = "right";
        } else if (dateFormat) {
            template = (e) => dataToStr(getPropObjetoAninhado(e, field), dateFormat);
        } else if (decimal) {
            template = (e) => new Intl.NumberFormat("pt-BR").format(parseNumber(e[field]));
        }
        if (typeof field === "string" && field.startsWith("action")) align = "center";
        return (
            <Column
                key={field || `col-${i}`}
                field={field}
                header={header}
                body={template}
                align={align}
                filterClear={limparFiltroTemplate}
                filterApply={aplicarFiltroTemplate}
                showFilterMatchModes={false}
                showFilterMenuOptions={false}
                showFilterOperator={false}
                {...rest}
            />
        );
    });

    const templatePaginacao = {
        layout: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 100, value: 100 },
                { label: 200, value: 200 },
            ];

            return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />;
        },
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: "var(--text-color)", userSelect: "none" }}>
                    Exibindo {options.first} a {options.last} de {options.totalRecords}
                </span>
            );
        },
    };

    const trocarPagina = (event) => {
        let _lazyParams = { ...lazyParams, ...event, filters: { ...lazyParams.filters } };
        setLazyParams(_lazyParams);
    };

    const ordenarRegistros = (event) => {
        let _lazyParams = { ...lazyParams, ...event, filters: { ...lazyParams.filters }  };
        setLazyParams(_lazyParams);
    };

    const filtrarRegistros = (event) => {
        let _lazyParams = { ...lazyParams, ...event, first: 0, };
        setLazyParams(_lazyParams);
    };

    const exportCSV = () => {
        listagemRef.current.exportCSV();
    };

    function filter(data, field, compare) {
        listagemRef.current.filter(data, field, compare);
    }

    const headerTabela =
        painelEsquerdo || botaoExportar ? (
            <div className="table-header">
                {filtarPorEmpresa && naoBuscarSemEmpresa && !empresaSelecionadaId ? (
                    <span className="p-error">Selecione uma empresa primeiro.</span>
                ) : painelEsquerdo || painelDireito || botaoExportar ? (
                    <>
                        <div className="p-text-left" style={{ width: "100%" }}>
                            {painelEsquerdo}
                            {botaoExportar ? (
                                <Button
                                    label="Exportar"
                                    icon="pi pi-download"
                                    className="p-button-help p-mr-2 p-mb-2"
                                    onClick={exportCSV}
                                />
                            ) : null}
                            {possuiFiltro && (
                                <Button
                                    label="Limpar filtro"
                                    icon="pi pi-filter-slash"
                                    className="p-button-outlined p-mr-2 p-mb-2"
                                    onClick={limparFiltros}
                                />
                            )}
                        </div>
                        {painelDireito}
                    </>
                ) : null}
            </div>
        ) : null;

    return (
        <>
            {titulo && <h5>{titulo}</h5>}
            <div className="crud-demo">
                <DataTable
                    ref={listagemRef}
                    value={dados}
                    header={headerTabela}
                    loading={loading}
                    paginatorTemplate={templatePaginacao}
                    totalRecords={quantRegistros}
                    sortField={lazyParams?.sortField}
                    sortOrder={lazyParams?.sortOrder}
                    filters={lazyParams?.filters}
                    first={lazyParams?.first}
                    rows={lazyParams?.rows}
                    onFilter={filtrarRegistros}
                    onPage={trocarPagina}
                    onSort={ordenarRegistros}
                    emptyMessage={msgTabelaVazia || "Nenhum registro encontrado."}
                    className="p-datatable-sm mako-table"
                    filterDisplay="menu"
                    responsiveLayout="scroll"
                    {...configTabela}
                >
                    {colunasDinamicas}
                </DataTable>
            </div>
        </>
    );
};

export const MakoListagem = forwardRef(Listagem);
export default MakoListagem;
