import { DropdownFiltroTemplate } from "../DropdownFiltroTemplate";

export const UnidadeMedidaFiltroTemplate = (options) => {
    const aposBuscar = (data) => {
        return data.map((item) => {
            return { ...item, label: `${item.nome} (${item.sigla})` };
        });
    };

    return (
        <DropdownFiltroTemplate
            dropdownProps={{
                url: "/produtos/unidades-medida/?query={id,nome,sigla}",
                optionValue: "id",
                optionLabel: "nome",
                aposBuscar: aposBuscar,
            }}
            options={options}
        />
    );
};
