import axios from "axios";

export const url = () => {
    const [urlHref] = window.location.href.replace("www.", "").split(".");
    const [, urlLocal] = window.location.href.split(":");

    let base = `${urlHref}.api.erpmako.com.br`;

    if (urlLocal === "//localhost") {
        base = "http://localhost:8000";
    }

    return base;
};

const api = axios.create({
    baseURL: url(),
});

export default api;
