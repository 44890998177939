import React, { useCallback, useEffect } from 'react';

import { useFormik } from 'formik';
import classNames from "classnames";
import * as Yup from "yup";

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { MakoInputTransportador } from '@/components/MakoInputs/MakoInputTransportador';
import { MakoInputMoeda } from '@/components/MakoInputMoeda';
import { Dropdown } from '@/components/Dropdown';

import { TIPOS_FORMA_PAGAMENTO_DEVOLUCAO_FORNECEDOR } from '@/assets/constants/constants';

import useDevolucaoFornecedor from '@/hooks/useDevolucaoFornecedor';
import useToast from '@/hooks/useToast';

const FormDadosEntrega = ({ handleAbleButton }) => {
    const { devolucao, updateDevolucao } = useDevolucaoFornecedor();
    const { showError } = useToast();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            transportador: null,
            pagante_frete: null,
            valor_frete: 0,
            rntc: null,
            sigla_uf_veiculo: null,
            placa_veiculo: null
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                transportador: Yup.object()
                    .shape({
                        id: Yup.number()
                            .required("O campo 'transportador' é obrigatório")
                            .typeError("Informe um 'transportador' válido")
                    })
                    .nullable()
                    .typeError("Informe um 'transportador' válido"),
                pagante_frete: Yup.object()
                    .notRequired()
                    .shape({
                        id: Yup.number()
                            .typeError("Informe um 'pagante frete' válido")
                    })
                    .nullable()
                    .typeError("Informe um 'transportador' válido"),
                valor_frete: Yup.number()
                    .notRequired()
                    .nullable()
                    .typeError("Informe um 'valor de frete' válido"),
                rntc: Yup.string()
                    .notRequired()
                    .test('len', 'Máximo de caracteres atingido: 20', val => val.length <= 20)
                    .typeError("Informe um 'rntc' válida"),
                sigla_uf_veiculo: Yup.string()
                    .notRequired()
                    .test('len', 'Máximo de caracteres atingido: 2', val => val.length <= 2)
                    .typeError("Informe um 'uf' válido"),
                placa_veiculo: Yup.string()
                    .notRequired()
                    .test('len', 'Máximo de caracteres atingido: 7', val => val.length <= 7)
                    .typeError("Informe uma 'placa de veículo válida' válida"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            updateDevolucao(values);

            handleAbleButton(true);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, a requisição não pode ser concluída.",
                    life: 3000,
                });
            }
            handleAbleButton(false);
        }
    }

    const handleReset = useCallback(() => {
        formik.resetForm();
        handleAbleButton(false);
    }, [])

    const autoCompleteTemplate = (cliente) => {
        cliente.label = `${cliente.nome} - ${cliente.identificacao}`;
        return <div>{`${cliente.nome} - ${cliente.identificacao}`}</div>;
    };

    useEffect(() => {
        if (devolucao !== null) {
            setValues({
                transportador: devolucao?.transportador,
                pagante_frete: devolucao?.pagante_frete,
                valor_frete: devolucao?.valor_frete,
                rntc: devolucao?.rntc,
                sigla_uf_veiculo: devolucao?.sigla_uf_veiculo,
                placa_veiculo: devolucao?.placa_veiculo,
            })
        }
    }, [devolucao])

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="transportador">Transportador: *</label>
                        <MakoInputTransportador
                            id="transportador"
                            name="transportador"
                            value={formik.values.transportador}
                            onChange={formik.handleChange}
                        />
                        {formik.errors.transportador && <small className="p-error">{formik.errors.transportador}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="pagante_frete">Forma de pagante frete:</label>
                        <Dropdown
                            id="pagante_frete"
                            name="pagante_frete"
                            options={TIPOS_FORMA_PAGAMENTO_DEVOLUCAO_FORNECEDOR}
                            optionLabel="label"
                            optionValue="value"
                            value={formik.values.pagante_frete}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.pagante_frete })}
                        />
                        {formik.errors.pagante_frete && (
                            <small className="p-error">{formik.errors.pagante_frete}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-1">
                        <label htmlFor="valor_frete">Valor frete:</label>
                        <MakoInputMoeda
                            id="valor_frete"
                            name="valor_frete"
                            value={formik.values.valor_frete}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor_frete })}
                        />
                        {formik.errors.valor_frete && (
                            <small className="p-error">{formik.errors.valor_frete}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="rntc">RNTC:</label>
                        <InputText
                            id="rntc"
                            name="rntc"
                            value={formik.values.rntc}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.rntc })}
                        />
                        {formik.errors.rntc && <small className="p-error">{formik.errors.rntc}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-1">
                        <label htmlFor="sigla_uf_veiculo">UF veículo:</label>
                        <InputText
                            id="sigla_uf_veiculo"
                            name="sigla_uf_veiculo"
                            value={formik.values.sigla_uf_veiculo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.sigla_uf_veiculo })}
                        />
                        {formik.errors.sigla_uf_veiculo && <small className="p-error">{formik.errors.sigla_uf_veiculo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="placa_veiculo">Placa do veículo:</label>
                        <InputText
                            id="placa_veiculo"
                            name="placa_veiculo"
                            value={formik.values.placa_veiculo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.placa_veiculo })}
                        />
                        {formik.errors.placa_veiculo && <small className="p-error">{formik.errors.placa_veiculo}</small>}
                    </div>
                </div>
                <p>
                    <b>* Campos obrigatórios</b>
                </p>
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button
                            type="submit"
                            icon="pi pi-check"
                            label="Gravar"
                            className="p-mr-2 p-mb-2"
                        />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            className="p-button-warning p-mr-2 p-mb-2"
                            onClick={handleReset}
                        />
                    </div>
                </div>
            </form>
        </div>
    )
}

export default React.memo(FormDadosEntrega);