import React from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { axiosPost, axiosPut } from "@/services/http";
import { MakoInputPerfilHierarquia } from "@/components/MakoInputs/MakoInputPerfilHierarquia";


import useLocalStorage from "@/hooks/useLocalStorage";
import storageKeys from "@/assets/constants/storage";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import useToast from "@/hooks/useToast";

const url = "/plano-operacional/centros-resultados/";
const urlvoltar = "/operacional/plano-operacional/centros-resultados";

export const OperacionalCentroResultadosForm = (props) => {
    const { showLoading, hideLoading } = useLoading();
    const [empresaSelecionada] = useLocalStorage(storageKeys.EMPRESA_KEY);
    const { showSuccess, showError } = useToast();
    const history = useHistory();

    const centroVazio = {
        empresa: empresaSelecionada.id,
        nome: "",
        descricao: "",
        responsavel: "",
    };

    const formik = useFormik({
        initialValues: props.location.state ? props.location.state.centroResultados : centroVazio,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo é obrigatório.").max(30),
                descricao: Yup.string().required("O campo é obrigatório.").max(255),
                responsavel: Yup.object().required("O campo é obrigatório.").typeError("Informe um 'responsável' válido."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(`${url}`, { ...values, responsavel: values.responsavel?.id });
                hideLoading();

                if (resposta.status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Centro de resultados cadastrado com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(`${url}${values.id}/`, {
                    ...values,
                    responsavel: values.responsavel?.id,
                    empresa: values.empresa?.id,
                });
                hideLoading();

                if (resposta.status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Centro de resultados alterado com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <h3>{!formik.values.id ? "Novo centro de resultados" : "Manutenção de centro de resultados"}</h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="nome">Nome *</label>
                                <InputText
                                    id="nome"
                                    name="nome"
                                    value={formik.values.nome}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.nome })}
                                    autoComplete="off"
                                />
                                {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-8">
                                <label htmlFor="descricao">Descrição *</label>
                                <InputText
                                    id="descricao"
                                    name="descricao"
                                    value={formik.values.descricao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.descricao })}
                                    autoComplete="off"
                                />
                                {formik.errors.descricao && (
                                    <small className="p-error">{formik.errors.descricao}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-6 p-md-6">
                                <label htmlFor="responsavel">Responsável * </label>
                                <MakoInputPerfilHierarquia
                                    id="responsavel"
                                    name="responsavel"
                                    value={formik.values.responsavel}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.responsavel })}
                                />
                                {formik.errors.responsavel && (
                                    <small className="p-error">{formik.errors.responsavel}</small>
                                )}
                            </div>
                        </div>

                        <p>
                            <b>* Campos obrigatórios.</b>
                        </p>

                        <div className="p-grid p-col-12 p-md-6">
                            <Button label="Gravar" type="submit" className="p-button-info p-mr-2 p-mb-6" />
                            <Button
                                label="Cancelar"
                                type="reset"
                                className="p-button-danger p-mr-2 p-mb-6"
                                onClick={cancelar}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
