import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";

import { FactoryAprovacaoNotificacao } from "./FactoryAprovacao";

export const BotaoAprovarNotificacao = ({ notificacao, comentario }) => {
    const [loading, setLoading] = useState(false);
    const factoryAprovacaoRef = useRef(null);

    useEffect(() => {
        return () => {
            factoryAprovacaoRef.current = null;
        };
    }, []);

    return (
        <>
            <Button
                icon="pi pi-check"
                label="Aprovar"
                loading={loading}
                disabled={notificacao.status !== "P"}
                className="p-mb-1"
                onClick={() => factoryAprovacaoRef.current?.executar()}
            />
            <FactoryAprovacaoNotificacao
                ref={factoryAprovacaoRef}
                notificacao={notificacao}
                comentario={comentario}
                setLoading={setLoading}
            />
        </>
    );
};
