import React, { useEffect, useCallback, useRef, useState } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import * as Yup from "yup";

import { dataToStr } from "@/assets/util/datas";
import { MakoCalendar } from "@/components/MakoCalendar";
import { axiosPost, axiosPut, axiosGet } from "@/services/http";
import useLoading from "@/hooks/useLoading";

const url = "/produtos/categorias/";
const urlvoltar = "/produto/produto/categorias";

const TIPOCATEGORIA = [
    { id: "T", label: "Título" },
    { id: "C", label: "Categoria" },
];

export const CategoriasForm = (props) => {
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const [comissoes, setComissoes] = useState([]);
    const [grupos, setGrupos] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const toastRef = useRef(null);

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: {
            nome: "",
            descricao: "",
            vigencia_inicial: null,
            vigencia_final: null,
            comissao_venda: null,
            categoria: null,
            grupo: null,
            tipo: null,
            exclusiva: false,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo 'nome' é obrigatório."),
                tipo: Yup.string().required("O campo 'tipo' é obrigatório."),
                vigencia_inicial: Yup.date().required("O campo 'vigência inicial' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const novaCategoria = {
                ...values,
                grupo: values.tipo !== "C" ? values.grupo : null,
                vigencia_inicial: dataToStr(values.vigencia_inicial, "yyyy-MM-dd"),
                vigencia_final: dataToStr(values.vigencia_final, "yyyy-MM-dd"),
            };

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(url, novaCategoria);
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Categoria cadastrada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(`${url}${values.id}/`, novaCategoria);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Categoria alterada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
            }
        }
    }

    const listarComissaoVendas = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/vendas/comissoes-vendas/");
        hideLoading();

        if (resposta.status === 200) {
            setComissoes(resposta.data.results);
        }
    }, [showLoading, hideLoading]);

    const listarGrupos = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/produtos/categorias-grupos/");
        hideLoading();

        if (resposta.status === 200) {
            setGrupos(resposta.data.results);
        }
    }, [showLoading, hideLoading]);

    const listarCategorias = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/produtos/categorias?tipo=T&limit=10000");
        hideLoading();

        if (resposta.status === 200) {
            setCategorias(resposta.data.results);
        }
    }, [showLoading, hideLoading]);

    useEffect(() => {
        if (formik.values.categoria && formik.values.categoria.id)
            if (formik.values.categoria.id !== formik.values.id) setFieldValue("categoria", formik.values.categoria.id);
    }, [formik.values.categoria, formik.values.id, setFieldValue]);

    useEffect(() => {
        listarCategorias();
        listarComissaoVendas();
        listarGrupos();
    }, [listarCategorias, listarComissaoVendas, listarGrupos]);

    useEffect(() => {
        if (props.location?.categoria) {
            setFieldValue("categoria", props.location.categoria);
        }

        if (props.location.state?.categoria) {
            const { grupo, ...rest } = props.location.state.categoria;

            setValues({ ...rest, grupo: grupo?.id });
        }
    }, [props.location, setFieldValue, setValues]);

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <h3>{!formik.values.id ? "Nova Categoria" : "Manutenção de categoria"}</h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="nome">Nome *</label>
                                <InputText
                                    id="nome"
                                    name="nome"
                                    value={formik.values.nome}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.nome })}
                                    autoComplete="off"
                                    autoFocus
                                />
                                {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-5">
                                <label htmlFor="descricao">Descrição</label>
                                <InputText
                                    id="descricao"
                                    name="descricao"
                                    value={formik.values.descricao}
                                    onChange={formik.handleChange}
                                    autoComplete="off"
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="comissao_venda">Comissão de vendas </label>
                                <Dropdown
                                    id="comissao_venda"
                                    name="comissao_venda"
                                    placeholder="Selecione"
                                    options={comissoes}
                                    filter
                                    filterBy="descricao"
                                    showClear
                                    optionValue="id"
                                    optionLabel="descricao"
                                    value={formik.values.comissao_venda}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.comissao_venda })}
                                />
                                {formik.errors.comissao_venda && (
                                    <small className="p-error">{formik.errors.comissao_venda}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="tipo">Tipo *</label>
                                <Dropdown
                                    id="tipo"
                                    name="tipo"
                                    placeholder="Selecione um tipo"
                                    options={TIPOCATEGORIA}
                                    optionValue="id"
                                    optionLabel="label"
                                    value={formik.values.tipo}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.tipo })}
                                />
                                {formik.errors.tipo && <small className="p-error">{formik.errors.tipo}</small>}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="categoria">Categoria mãe</label>
                                <Dropdown
                                    id="categoria"
                                    name="categoria"
                                    placeholder="Selecione"
                                    options={categorias}
                                    filter
                                    filterBy="nome"
                                    showClear
                                    optionValue="id"
                                    optionLabel="nome"
                                    value={formik.values.categoria}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="grupo">Grupo</label>
                                <Dropdown
                                    id="grupo"
                                    name="grupo"
                                    placeholder="Selecione"
                                    options={grupos}
                                    filter
                                    filterBy="descricao"
                                    showClear
                                    optionValue="id"
                                    optionLabel="descricao"
                                    disabled={formik.values.tipo === "C"}
                                    value={formik.values.grupo}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="p-field p-col-6 p-md-2">
                                <label htmlFor="vigencia_inicial">Vigência inicial *</label>
                                <MakoCalendar
                                    id="vigencia_inicial"
                                    name="vigencia_inicial"
                                    valueCalendar={formik.values.vigencia_inicial}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.vigencia_inicial })}
                                />
                                {formik.errors.vigencia_inicial && (
                                    <small className="p-error">{formik.errors.vigencia_inicial}</small>
                                )}
                            </div>
                            <div className="p-field p-col-6 p-md-2">
                                <label htmlFor="vigencia_final">Vigência final</label>
                                <MakoCalendar
                                    id="vigencia_final"
                                    name="vigencia_final"
                                    valueCalendar={formik.values.vigencia_final}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="p-field-checkbox p-col-2 p-md-2">
                                <br></br>
                                <Checkbox
                                    inputId="exclusiva"
                                    id="exclusiva"
                                    name="exclusiva"
                                    onChange={formik.handleChange}
                                    checked={formik.values.exclusiva}
                                    value={formik.values.exclusiva}
                                    className="p-mt-5"
                                />
                                <label htmlFor="exclusiva" className="p-mt-5">
                                    Categoria exclusiva
                                </label>
                            </div>
                        </div>
                        <p>* Campos obrigatórios.</p>
                        <div className="p-grid p-col-12 p-md-6">
                            <Button label="Gravar" type="submit" className="p-button-info p-mr-2" />
                            <Button
                                label="Cancelar"
                                type="reset"
                                className="p-button-danger p-mr-2"
                                onClick={cancelar}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
