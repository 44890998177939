import React, { useEffect, useRef, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { MakoSelecionarEmails } from "@/components/MakoSelecionarEmails";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { Dropdown } from "@/components/Dropdown";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import useRelatorio from "@/hooks/useRelatorio";
import useClearRefs from "@/hooks/useClearRefs";
import useAuth from "@/hooks/useAuth";

import { RELATORIO_FINANCEIRO_MOVIMENTACAOCAIXA } from "@/assets/constants/relatorios";
import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";

export const RelatorioCaixaMovResumo = () => {
    const [visible, setVisible] = useState(false);
    const [emails, setEmails] = useState(null);

    const { solicitarRelatorio } = useRelatorio();
    const { user } = useAuth();

    const empresaSelecionada = useRef();

    useClearRefs(empresaSelecionada);

    const { setValues, ...formik } = useFormik({
        initialValues: {
            empresa: null,
            caixa: null,
            // data: null,
            controle: "",
        },
        onSubmit: handleSubmit,
    });

    const filtrosTemplate = [
        {
            key: "empresa",
            label: "Empresa",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: "controle",
            label: "Controle",
        },
        {
            key: "caixa",
            label: "Caixa",
            path: "nome",
        },
    ];

    async function handleSubmit(values = null) {
        try {
            const formSchema = Yup.object().shape({
                empresa: Yup.number()
                    .integer()
                    .required("O campo 'empresa' é obrigatório.")
                    .typeError("Selecione uma empresa"),
                caixa: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'caixa' é obrigatório."),
                    })
                    .typeError("Selecione uma caixa"),
                // data: Yup.date().typeError("Informe uma data válida"),
                controle: Yup.string().required("O campo 'controle' é obrigatório.").typeError("Informe um controle"),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                empresa: dadosValidados?.empresa ? empresaSelecionada.current : null,
            });

            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.caixa) dadosValidados.caixa = dadosValidados.caixa.id;

            let filtros = {};
            if (dadosValidados) {
                Object.keys(dadosValidados).forEach((key) => {
                    if (dadosValidados[key]) filtros[key] = dadosValidados[key];
                });
            }

            solicitarRelatorio({
                chave: RELATORIO_FINANCEIRO_MOVIMENTACAOCAIXA,
                emails,
                filtros,
                filtros_template: filtrosTemplateAplicados,
            });
            formik.resetForm();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const handleConfirm = (emails) => {
        fecharModal();
        if (emails && emails.length > 0) {
            handlePreSubmit(emails);
        }
    };

    const handlePreSubmit = (emails = []) => {
        setEmails(emails);
        formik.handleSubmit();
    };

    const limparFiltro = () => {
        formik.resetForm();
    };

    const fecharModal = () => {
        setVisible(() => false);
    };

    const onChangeEmpresa = (e) => {
        empresaSelecionada.current = e?.empresa;
        setValues({ empresa: e.id });
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <form onSubmit={formik.handleSubmit}>
                    <div className="card">
                        <h5>Filtro para emissão de relatório de resumo de movimentação de caixa</h5>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="empresa">Empresa *</label>
                                <MakoDropdownEmpresas
                                    id="empresa"
                                    name="empresa"
                                    placeholder="Selecione uma empresa"
                                    value={formik.values.empresa}
                                    onChange={(e) => onChangeEmpresa(e)}
                                    className={classNames({ "p-invalid": formik.errors.empresa })}
                                />
                                {formik.errors.empresa && <small className="p-error">{formik.errors.empresa}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="caixa">Selecione o caixa *</label>
                                <Dropdown
                                    id="caixa"
                                    name="caixa"
                                    url={`/financeiro/caixas/?query={id,nome}&operador=${user.id}&datahora_fechamento__isnull=True`}
                                    optionLabel="nome"
                                    filter
                                    filterBy="nome"
                                    placeholder="Selecione um caixa..."
                                    value={formik.values.caixa}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.caixa })}
                                />
                                {formik.errors.caixa && <small className="p-error">{formik.errors.caixa}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="controle">Controle *</label>
                                <InputText
                                    id="controle"
                                    name="controle"
                                    value={formik.values.controle}
                                    onInput={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.controle })}
                                />
                                {formik.errors.controle && <small className="p-error">{formik.errors.controle}</small>}
                            </div>
                            {/* <div className="p-field p-col-2 p-md-2">
                                <label htmlFor="data">Data:</label>
                                <MakoCalendar
                                    id="data"
                                    name="data"
                                    valueCalendar={formik.values.data}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.data })}
                                />
                                {formik.errors.data && <small className="p-error">{formik.errors.data}</small>}
                            </div> */}
                        </div>
                        <div className="p-grid p-col-12 p-md-6 p-mt-2">
                            <Button
                                label="Gerar PDF"
                                icon="pi pi-file-pdf"
                                type="button"
                                onClick={() => handlePreSubmit()}
                                className="p-button-info p-mr-2"
                            />
                            <Button
                                type="button"
                                onClick={() => setVisible(true)}
                                label="Enviar por email"
                                icon="pi pi-envelope"
                                className="p-button-info p-mr-2"
                            />
                            <Button
                                type="reset"
                                icon="pi pi-trash"
                                label="Limpar"
                                onClick={() => limparFiltro()}
                                className="p-button-warning p-mr-2"
                            />
                        </div>
                    </div>
                </form>
            </div>
            <Dialog
                visible={visible}
                blockScroll
                onHide={fecharModal}
                style={{ width: "60vw" }}
                header="Informar emails"
            >
                <MakoSelecionarEmails onCancel={fecharModal} onConfirm={handleConfirm} />
            </Dialog>
        </div>
    );
};
