import React, { useImperativeHandle, useRef, useState, forwardRef } from "react";
import { Button } from "primereact/button";

import { MakoErrosPersonalizados } from "@/components/MakoErrosPersonalizados";
import { ModalRegraOperacaoFiscalNCM } from "@/pages/Fiscal/Cadastros/OperacoesFiscaisNFe/RegrasOperacaoFiscal/ModalRegraOperacaoFiscalNCM";
import { ModalRegraOperacaoFiscalSKU } from "@/pages/Fiscal/Cadastros/OperacoesFiscaisNFe/RegrasOperacaoFiscal/ModalRegraOperacaoFiscalSKU";
import { axiosPost } from "@/services/http";
import useClearRefs from "@/hooks/useClearRefs";

const Modal = (props, ref) => {
    const [operacaoFiscal, setOperacaoFiscal] = useState(null);
    const modalErrosRef = useRef(null);
    const modalRegraNcmRef = useRef(null);
    const modalRegraSkuRef = useRef(null);

    useClearRefs(modalErrosRef, modalRegraNcmRef, modalRegraSkuRef);

    const abrirModal = (pendencias) => {
        modalErrosRef.current.abrirModal(pendencias);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    async function gerarRegraOperacaoFiscal(escopo, itemEntradaId) {
        const body = {
            escopo,
            item_entrada: itemEntradaId,
        };
        const { status, data } = await axiosPost(`/compras/gerar-regra-operacao-fiscal/`, body);
        if (status === 200 && escopo === "sku") {
            setOperacaoFiscal(data.regra.operacao_fiscal);
            modalRegraSkuRef.current.abrirModal(data.regra.sku.sku, data.regra.sku.br, data.regra.sku.uf);
        } else if (status === 200 && escopo === "ncm") {
            setOperacaoFiscal(data.regra.operacao_fiscal);
            modalRegraNcmRef.current.abrirModal(data.regra.ncm);
        }
    }

    const actionErroBodyTemplate = (rowData) => {
        const regraSKU = rowData.erro.includes("estaduais/federais por produto");
        const regraNCM = rowData.erro.includes("estaduais/federais por NCM");
        return (
            <div className="actions">
                {regraSKU && (
                    <Button
                        icon="pi pi-plus"
                        label="Add regra por produto"
                        onClick={() => gerarRegraOperacaoFiscal("sku", rowData.item_entrada)}
                        className="p-button-rounded p-button-help p-mr-2 p-mt-1"
                    />
                )}
                {regraNCM && (
                    <Button
                        icon="pi pi-plus"
                        label="Add regra por NCM"
                        onClick={() => gerarRegraOperacaoFiscal("ncm", rowData.item_entrada)}
                        className="p-button-rounded p-button-help p-mt-1"
                    />
                )}
            </div>
        );
    };

    const colunas = [
        { field: "erro", header: "Descrição da pendência" },
        {
            field: "action",
            header: "Ações",
            align: "center",
            style: { width: "25%" },
            action: (e) => actionErroBodyTemplate(e),
        },
    ];

    return (
        <>
            <MakoErrosPersonalizados ref={modalErrosRef} titulo="Inconsistências encontradas" colunas={colunas} />
            <ModalRegraOperacaoFiscalNCM ref={modalRegraNcmRef} operacaoFiscal={operacaoFiscal} />
            <ModalRegraOperacaoFiscalSKU ref={modalRegraSkuRef} operacaoFiscal={operacaoFiscal} />
        </>
    );
};

export const ModalErrosValidacaoItem = forwardRef(Modal);
