import { useCallback, useMemo } from "react";

import useLocalStorage from "./useLocalStorage";

export const useLocalFiltro = (key, initialValue) => {
    const [filtros, setValue, removeFiltro] = useLocalStorage(key, initialValue);

    const updateFiltro = useCallback(
        (values) => {
            setValue((prev) => {
                if (typeof values === "function") {
                    return values(prev);
                }
                let data = {};
                Object.keys(values).forEach((key) => {
                    if (values[key] instanceof Array) {
                        if (values[key]?.length) data[key] = values[key];
                    } else if (values[key] || typeof values[key] === "number") data[key] = values[key];
                });
                return data;
            });
        },
        [setValue]
    );

    const removerFiltro = () => {
        removeFiltro();
        setValue(null);
    };
    const filtroString = useMemo(() => {
        if (!filtros) return "";
        let _filtros = [];
        Object.keys(filtros).map((key) => _filtros.push(`${key}=${filtros[key]}`));
        return _filtros.join("&");
    }, [filtros]);

    return [filtros, updateFiltro, removerFiltro, filtroString];
};
