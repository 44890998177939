import React, { useRef, useState, useCallback, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { dataToStr } from "@/assets/util/datas";
import { TabPanel, TabView } from "primereact/tabview";
import { Calendar } from "primereact/calendar";
import { axiosGet, axiosPost } from "@/services/http";

import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import useLocalStorage from "@/hooks/useLocalStorage";
import storageKeys from "@/assets/constants/storage";
import classNames from "classnames";
import * as Yup from "yup";
import { TIPOS_ITENS_FINALIDADE, TIPOS_ITENS_INVENTARIO } from "@/assets/constants/constants";
import MakoListagem from "@/components/MakoListagem";

export const FiscalSped = () => {
    const [activeTab, setActiveTab] = useState(1);
    const [empresaSelecionada] = useLocalStorage(storageKeys.EMPRESA_KEY);
    const { showLoading, hideLoading } = useLoading();
    const listagemRef = useRef(null);
    const { showSuccess, showError } = useToast();

    const history = useHistory();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            empresa: null,
            periodo: new Date(),
            gerar_bloco_h: false,
            gerar_bloco_m_s: false,
            motivo_inventario: 1,
            finalidade: null,
            registro_74: false,
        },
        // onSubmit: handleSubmit,
    });

    async function handleSintegra() {
        try {
            const empresa = empresaSelecionada.perfil_pj;
            const endereco = empresaSelecionada.enderecoperfil_set[0];
            const cidade = empresaSelecionada.enderecoperfil_set[0].cidade;
            const contato = empresaSelecionada.telefoneperfil_set[0];
            const periodo = dataToStr(formik.values.periodo, "yyyy-MM");

            const body = {
                data_inicial: `${periodo}-01`,
                data_final: `${periodo}-30`,
                registro_10: {
                    cnpj_mf: empresa.cnpj,
                    ie: empresa.inscricao_estadual,
                    nome_contribuinte: empresa.razao_social,
                    municipio: cidade.nome,
                    unidade_federacao: cidade.estado.uf,
                    // fax: ,
                    data_inicial: `${periodo}-01 00:00:00`,
                    data_final: `${periodo}-30 00:00:00`,
                    // cod_id_estrutura: ,
                    // cod_id_natureza: ,
                    // cod_id_finalidade: ,
                },
                registro_11: {
                    logradouro: endereco.logradouro,
                    numero: endereco.numero,
                    complemento: endereco.complemento,
                    bairro: endereco.bairro,
                    cep: endereco.cep,
                    nome_contato: endereco.identificacao,
                    telefone: contato.telefone,
                },
                uf: empresaSelecionada?.enderecoperfil_set[0]?.cidade.estado.uf,
                registro_74: formik.values.registro_74,
                empresa_id: empresaSelecionada.id,
            };

            showLoading();
            const resposta = await axiosPost(`/fiscal/gerar-sintegra/`, body);
            hideLoading();

            if (!resposta.data?.erro) {
                showSuccess({
                    summary: "Sucesso",
                    detail: "SINTEGRA gerado com sucesso!",
                    life: 3000,
                });
                listagemRef.current?.buscarDados();
            } else {
                showError({
                    summary: "Erro",
                    detail: resposta.data.erro,
                    life: 3000,
                });
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            } else {
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 3000,
                });
            }
        }
    }

    const handleReset = () => {
        formik.resetForm();
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { width: "15%" },
        },
        {
            field: "periodo_inicial",
            header: "Período Inicial",
            dateFormat: "dd/MM/yyyy",
        },
        {
            field: "periodo_final",
            header: "Período Final",
            dateFormat: "dd/MM/yyyy",
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    const gerarTxt = useCallback(
        async (rowData) => {
            showLoading();
            const response = await axiosGet(`/fiscal/dados-sintegra/?ordering=numero_linha&sintegra=${rowData.id}`);
            hideLoading();

            try {
                if (response.status === 200) {
                    const { results } = response.data;

                    if (results instanceof Array && results.length > 0) {
                        let _linhas = "";

                        for (let i = 0; i < results.length; i++) {
                            _linhas +=
                                i !== results.length - 1 ? `${results[i].linha_dados} \n` : `${results[i].linha_dados}`;
                        }

                        const blobFile = new Blob([_linhas], {
                            type: "text/plain;charset-utf-8",
                        });

                        const a = document.createElement("a");
                        a.href = window.URL.createObjectURL(blobFile);
                        a.download = `${empresaSelecionada.identificacao}_${dataToStr(
                            rowData.periodo_inicial,
                            "ddMMyyyy"
                        )}_${dataToStr(rowData.periodo_final, "ddMMyyyy")}.txt`;
                        a.click();
                    }
                } else {
                    throw new Error("Desculpe, não conseguimos processar sua requisição");
                }
            } catch (error) {
                showError({
                    summary: "Erro :(",
                    detail: error.message,
                    life: 3000,
                });
            }
        },
        [showLoading, hideLoading, showError, empresaSelecionada.identificacao]
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <Button
                type="button"
                icon="pi pi-file"
                tooltip="Gerar arquivo sintegra TXT"
                className="p-button-info p-button-rounded"
                onClick={() => gerarTxt(rowData)}
            />
        );
    };

    return (
        <div className="card">
            <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
                <TabPanel header="Gerar SPED" leftIcon="pi pi-sync" disabled>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="periodo">Periodo:</label>
                                <Calendar
                                    id="monthpicker"
                                    value={formik.values.periodo}
                                    onChange={formik.handleChange}
                                    view="month"
                                    showIcon
                                    dateFormat="mm/yy"
                                    maxDate={new Date()}
                                />
                                {formik.errors.periodo && <small className="p-error">{formik.errors.periodo}</small>}
                            </div>
                            <div className="p-field p-col-4">
                                <label htmlFor="motivo_inventario">Motivo inventário:</label>
                                <Dropdown
                                    id="motivo_inventario"
                                    name="motivo_inventario"
                                    placeholder="Selecione um motivo"
                                    filter
                                    filterBy="descricao"
                                    optionLabel="label"
                                    optionValue="value"
                                    options={TIPOS_ITENS_INVENTARIO}
                                    value={formik.values.motivo_inventario}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.motivo_inventario })}
                                />
                                {formik.errors.motivo_inventario && (
                                    <small className="p-error">{formik.errors.motivo_inventario}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-4">
                                <label htmlFor="finalidade">Finalidade:</label>
                                <Dropdown
                                    id="finalidade"
                                    name="finalidade"
                                    placeholder="Selecione um motivo"
                                    filter
                                    filterBy="descricao"
                                    optionLabel="label"
                                    optionValue="value"
                                    options={TIPOS_ITENS_FINALIDADE}
                                    value={formik.values.finalidade}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.finalidade })}
                                />
                                {formik.errors.finalidade && (
                                    <small className="p-error">{formik.errors.finalidade}</small>
                                )}
                            </div>
                            <div
                                className="p-field p-col-2 p-mt-4"
                                style={{ display: "flex", alignItems: "center", gap: "5px" }}
                            >
                                <Checkbox
                                    id="gerar_bloco_h"
                                    name="gerar_bloco_h"
                                    checked={formik.values.gerar_bloco_h}
                                    onChange={formik.handleChange}
                                />
                                <label htmlFor="gerar_bloco_h" className="p-mt-2">
                                    Gerar bloco H
                                </label>
                            </div>
                            <div
                                className="p-field p-col-2 p-mt-4"
                                style={{ display: "flex", alignItems: "center", gap: "5px" }}
                            >
                                <Checkbox
                                    id="gerar_bloco_m_s"
                                    name="gerar_bloco_m_s"
                                    checked={formik.values.gerar_bloco_m_s}
                                    onChange={formik.handleChange}
                                />
                                <label htmlFor="gerar_bloco_m_s" className="p-mt-2">
                                    Gerar bloco 1600
                                </label>
                            </div>
                        </div>
                        <p>
                            <b>* Campos obrigatórios</b>
                        </p>
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6">
                                <Button type="submit" icon="pi pi-cog" label="Gerar" className="p-mr-2 p-mb-2" />
                                <Button
                                    type="reset"
                                    icon="pi pi-trash"
                                    label="Limpar"
                                    className="p-button-warning p-mr-2 p-mb-2"
                                    onClick={handleReset}
                                />
                                <Button
                                    type="reset"
                                    label="Cancelar"
                                    className="p-button-danger p-mb-2"
                                    onClick={() => history.goBack()}
                                />
                            </div>
                        </div>
                    </form>
                </TabPanel>
                <TabPanel header="Gerar SINTEGRA" leftIcon="pi pi-sync">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="periodo">Periodo:</label>
                                <Calendar
                                    id="monthpicker"
                                    value={formik.values.periodo}
                                    onChange={formik.handleChange}
                                    view="month"
                                    showIcon
                                    dateFormat="mm/yy"
                                    maxDate={new Date()}
                                />
                                {formik.errors.periodo && <small className="p-error">{formik.errors.periodo}</small>}
                            </div>
                            <div
                                className="p-field p-col-12 p-md-3 p-mt-4"
                                style={{ display: "flex", alignItems: "center", gap: "5px" }}
                            >
                                <Checkbox
                                    id="registro_74"
                                    name="registro_74"
                                    checked={formik.values.registro_74}
                                    onChange={formik.handleChange}
                                />
                                <label htmlFor="registro_74" className="p-mt-2">
                                    Gerar registro 74
                                </label>
                            </div>
                        </div>
                        <p>
                            <b>* Campos obrigatórios</b>
                        </p>
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6">
                                <Button
                                    type="button"
                                    icon="pi pi-cog"
                                    label="Gerar"
                                    className="p-mr-2 p-mb-2"
                                    onClick={() => handleSintegra()}
                                />
                                <Button
                                    type="reset"
                                    icon="pi pi-trash"
                                    label="Limpar"
                                    className="p-button-warning p-mr-2 p-mb-2"
                                    onClick={handleReset}
                                />
                                <Button
                                    type="reset"
                                    label="Cancelar"
                                    className="p-button-danger p-mb-2"
                                    onClick={() => history.goBack()}
                                />
                            </div>
                        </div>
                    </form>
                </TabPanel>
            </TabView>
            <div className="card">
                <MakoListagem
                    ref={listagemRef}
                    titulo="Registros de Sintegra"
                    colunas={colunas}
                    urlPesquisa={"/fiscal/sintegra/"}
                    responsiva
                    configTabela={{
                        paginator: true,
                        lazy: true,
                    }}
                />
            </div>
        </div>
    );
};
