import React, { forwardRef, memo, useEffect, useRef, useState, useCallback } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { MakoCalendar } from "@/components/MakoCalendar";
import { axiosGet } from "@/services/http";
import { isAfter, isBefore, isEqual } from "date-fns";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { dataToStr } from "@/assets/util/datas";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";

import useLoading from "@/hooks/useLoading";
import useTroca from "@/hooks/useTroca";
import classNames from "classnames";
import permissoes from "@/assets/constants/permissoes";
import useAuth from "@/hooks/useAuth";
import * as Yup from "yup";
import useLocalStorage from "@/hooks/useLocalStorage";
import storageKeys from "@/assets/constants/storage";

export const TrocaDadosBasicosForm = (props) => {
    const [supervisores, setSupervisores] = useState([]);
    const [planosRecebimento, setPlanosRecebimento] = useState([]);
    const [vendedores, setVendedores] = useState([]);
    const [competenciasEstoque, setCompetenciasEstoque] = useState([]);
    const [estagiosVenda, setEstagiosVenda] = useState([]);
    const [loading, setLoading] = useState(false);
    const [empresaSelecionada] = useLocalStorage(storageKeys.EMPRESA_KEY);
    const { handleDadosTroca, troca } = useTroca();
    const { showLoading, hideLoading } = useLoading();
    const { user } = useAuth();
    const toastRef = useRef(null);

    const { setFieldValue, setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            status: "novo",
            empresa: empresaSelecionada?.id,
            incluido_por: user?.id,
            vendedor: null,
            supervisor: null,
            competencia: null,
            data_venda: new Date(),
            plano_recebimento: null,
            observacoes: "",
            estagio_venda: null,
            sequencia_estagio_venda: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                data_venda: Yup.date()
                    .required("O campo 'data da venda' é obrigatório.")
                    .typeError("Informe uma 'data da venda' válida"),
                competencia: Yup.number()
                    .required("O campo 'competência' é obrigatório.")
                    .typeError("Informe uma 'competência' válida"),
                vendedor: Yup.number()
                    .required("O campo 'vendedor' é obrigatório.")
                    .typeError("Informe um 'vendedor' válido"),
                supervisor: Yup.number()
                    .required("O campo 'supervisor' é obrigatório.")
                    .typeError("Informe um 'supervisor' válido"),
                plano_recebimento: Yup.number()
                    .required("O campo 'plano de recebimento' é obrigatório.")
                    .typeError("Informe um 'plano de recebimento' válido"),
                estagio_venda: Yup.object()
                    .required("O campo 'estágio da venda' é obrigatório.")
                    .typeError("Informe um 'estágio de venda' válido"),
                sequencia_estagio_venda: Yup.number()
                    .required("O campo 'sequência de estágio da venda' é obrigatório.")
                    .typeError("Informe uma 'sequência de venda' válida"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            values.data_venda = dataToStr(values.data_venda, "yyyy-MM-dd");

            handleDadosTroca({ ...values, status: "P" });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não foi possível salvar as informações da troca.",
                    life: 3000,
                });
            }
        }
    }

    const buscarCompetenciasEstoque = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/produtos/competencias-movimentacoes-estoques/");
        hideLoading();

        if (resposta.status === 200) {
            const competenciasEstoque = resposta.data.results.map((comp) => {
                let disabled = true;

                if (
                    (isBefore(new Date(comp.data_inicio), new Date()) ||
                        isEqual(new Date(comp.data_inicio), new Date())) &&
                    (!comp.data_fim ||
                        isAfter(new Date(comp.data_fim), new Date()) ||
                        isEqual(new Date(comp.data_fim), new Date()))
                ) {
                    disabled = false;
                }

                return {
                    ...comp,
                    disabled,
                };
            });

            setCompetenciasEstoque(competenciasEstoque);
        } else
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos listar as competências.",
                life: 3000,
            });
    }, [showLoading, hideLoading]);

    const buscarVendedoresSupervisores = useCallback(async () => {
        setLoading(true);
        const resposta = await axiosGet(
            "/pessoas/perfis/?usuario__isnull=False&query={id,perfil_pf,papeis_vigentes}&limit=500"
        );
        setLoading(false);

        let _supervisores = [];
        let _vendedores = [];

        if (resposta.status === 200) {
            resposta.data.results.forEach((perfil) => {
                perfil.papeis_vigentes.forEach((papel) => {
                    if (papel.chave?.id === "SUP") {
                        _supervisores.push({
                            id: perfil.id,
                            nome: perfil.perfil_pf.nome_completo,
                        });
                    }
                    if (papel.chave?.id === "VND") {
                        _vendedores.push({
                            id: perfil.id,
                            nome: perfil.perfil_pf.nome_completo,
                        });
                        if (user.id === perfil.id) {
                            setFieldValue("vendedor", perfil.id);
                        }
                    }
                });
            });

            setSupervisores(_supervisores);
            setVendedores(_vendedores);
        } else
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos listar os supervisores e vendedores.",
                life: 3000,
            });
    }, [user, setFieldValue]);

    const buscarPlanosPagamento = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/financeiro/planos-recebimentos/");
        hideLoading();

        if (resposta.status === 200) setPlanosRecebimento(resposta.data.results);
        else
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos listar os planos de pagamento.",
                life: 3000,
            });
    }, [showLoading, hideLoading]);

    const buscarEstagiosVenda = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/vendas/estagios-vendas/");
        hideLoading();

        if (resposta.status === 200) setEstagiosVenda(resposta.data.results);
        else
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos listar os planos de pagamento.",
                life: 3000,
            });
    }, [showLoading, hideLoading]);

    const competenciaSelecionadaTemplate = (option, props) => {
        if (option) {
            return (
                <span>{`${option.nome} ${dataToStr(option.data_inicio, "dd/MM/yyyy")} até ${dataToStr(
                    option.data_fim,
                    "dd/MM/yyyy"
                )}`}</span>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const opcaoCompetenciaTemplate = (option) => {
        return (
            <span>{`${option.nome} ${dataToStr(option.data_inicio, "dd/MM/yyyy")} até ${dataToStr(
                option.data_fim,
                "dd/MM/yyyy"
            )}`}</span>
        );
    };

    useEffect(() => {
        buscarPlanosPagamento();
        buscarVendedoresSupervisores();
        buscarCompetenciasEstoque();
        buscarEstagiosVenda();
    }, [buscarVendedoresSupervisores, buscarCompetenciasEstoque, buscarPlanosPagamento, buscarEstagiosVenda]);

    useEffect(() => {
        if (props.cliente) setFieldValue("cliente", props.cliente);
    }, [setFieldValue, props.cliente]);

    useEffect(() => {
        if (troca) setValues(troca);
    }, [troca, setValues]);

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toastRef} />
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="cliente">Cliente *</label>
                                <InputText
                                    id="cliente"
                                    name="cliente"
                                    value={formik.values.cliente?.nome || ""}
                                    disabled
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="identificacao">Cnpj/Cpf *</label>
                                <InputText
                                    id="identificacao"
                                    name="identificacao"
                                    value={formik.values.cliente?.identificacao || ""}
                                    disabled
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="data_venda">Data da venda *</label>
                                <MakoCalendar
                                    id="data_venda"
                                    name="data_venda"
                                    valueCalendar={formik.values.data_venda}
                                    onChange={formik.handleChange}
                                    className={classNames({
                                        "p-invalid": formik.errors.data_venda,
                                    })}
                                />
                                {formik.errors.data_venda && (
                                    <small className="p-error">{formik.errors.data_venda}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="competencia">Competência de estoque *</label>
                                <Dropdown
                                    id="competencia"
                                    name="competencia"
                                    placeholder="Selecione..."
                                    options={competenciasEstoque}
                                    optionValue="id"
                                    optionLabel="nome"
                                    valueTemplate={competenciaSelecionadaTemplate}
                                    itemTemplate={opcaoCompetenciaTemplate}
                                    value={formik.values.competencia}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.competencia })}
                                />
                                {formik.errors.competencia && (
                                    <small className="p-error">{formik.errors.competencia}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="vendedor">Vendedor *</label>
                                <MakoControleAcesso
                                    permissao={[permissoes.VENDAS_VENDA_REALIZARVENDA_ALTERARVENDEDOR]}
                                    componente={Dropdown}
                                    exibirDesabilitadoSemPermissao
                                    id="vendedor"
                                    name="vendedor"
                                    placeholder={loading ? "Carregando vendedores..." : "Selecione..."}
                                    options={vendedores}
                                    optionValue="id"
                                    optionLabel="nome"
                                    filter
                                    filterBy="nome"
                                    value={formik.values.vendedor}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.vendedor })}
                                />
                                {formik.errors.vendedor && <small className="p-error">{formik.errors.vendedor}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="supervisor">Supervisor *</label>
                                <MakoControleAcesso
                                    permissao={[permissoes.VENDAS_VENDA_REALIZARVENDA_ALTERARSUPERVISOR]}
                                    componente={Dropdown}
                                    exibirDesabilitadoSemPermissao
                                    id="supervisor"
                                    name="supervisor"
                                    placeholder={loading ? "Carregando supervisores..." : "Selecione..."}
                                    options={supervisores}
                                    optionValue="id"
                                    optionLabel="nome"
                                    filter
                                    filterBy="nome"
                                    value={formik.values.supervisor}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.supervisor })}
                                />
                                {formik.errors.supervisor && (
                                    <small className="p-error">{formik.errors.supervisor}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="plano_recebimento">Plano de recebimento *</label>
                                <Dropdown
                                    id="plano_recebimento"
                                    name="plano_recebimento"
                                    placeholder="Selecione"
                                    options={planosRecebimento}
                                    optionLabel="descricao"
                                    optionValue="id"
                                    filter
                                    filterBy="descricao"
                                    value={formik.values.plano_recebimento}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.plano_recebimento })}
                                />
                                {formik.errors.plano_recebimento && (
                                    <small className="p-error">{formik.errors.plano_recebimento}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="estagio_venda">Estágio venda *</label>
                                <Dropdown
                                    id="estagio_venda"
                                    name="estagio_venda"
                                    placeholder="Selecione"
                                    options={estagiosVenda}
                                    optionLabel="descricao"
                                    value={formik.values.estagio_venda}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.estagio_venda })}
                                />
                                {formik.errors.estagio_venda && (
                                    <small className="p-error">{formik.errors.estagio_venda}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="sequencia_estagio_venda">Sequência estágio *</label>
                                <Dropdown
                                    id="sequencia_estagio_venda"
                                    name="sequencia_estagio_venda"
                                    placeholder={
                                        formik.values.estagio_venda?.sequenciaestagiovenda_set?.length > 0
                                            ? "Selecione uma sequência"
                                            : "Selecione um estágio**"
                                    }
                                    options={formik.values.estagio_venda?.sequenciaestagiovenda_set || []}
                                    optionLabel="tipo_estagio_venda.descricao"
                                    optionValue="id"
                                    value={formik.values.sequencia_estagio_venda}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.sequencia_estagio_venda })}
                                />
                                {formik.errors.sequencia_estagio_venda && (
                                    <small className="p-error">{formik.errors.sequencia_estagio_venda}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12">
                                <label htmlFor="obs">Observações</label>
                                <InputTextarea
                                    id="obs"
                                    name="observacoes"
                                    rows={3}
                                    value={formik.values.observacoes}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </div>

                        <p>
                            <b>* Campos obrigatórios.</b>
                        </p>
                        <div className="p-grid p-col-12 p-md-12">
                            <Button
                                icon="pi pi-save"
                                label="Gravar dados"
                                type="submit"
                                className="p-button-success p-mr-2"
                            />
                            <Button
                                icon="pi pi-trash"
                                label="Limpar"
                                type="reset"
                                className="p-button-warning p-mr-2"
                                disabled={formik.values.id ? true : false}
                                onClick={() => resetForm()}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default memo(forwardRef(TrocaDadosBasicosForm));
