import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";

import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import { axiosDelete } from "@/services/http";
import { Tag } from "primereact/tag";
import useClearRefs from "@/hooks/useClearRefs";
import useToast from "@/hooks/useToast";
import { CodigoFiltroTemplate, DropdownFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE } from "@/assets/constants/financeiro";
import { ESCOPO_FORMA_RECEB_PGTO_CHOICE } from "@/assets/constants/constants";

const url = "/financeiro/formas-recebimentos/";

const opcoesPesquisa = [
    { id: "todos", label: "Todos", value: "" },
    { id: "ativos", label: "Ativos", value: true },
    { id: "inativos", label: "Inativos", value: false },
];

export const FinanceiroFormaRecebimentoPage = () => {
    const [redirecionar, setRedirecionar] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [filtro, setFiltro] = useState("");

    const [formaRecebimento, setFormaRecebimento] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const listagemRef = useRef(null);

    useClearRefs(listagemRef);

    const confirmarDelete = (formaRecebimento) => {
        setFormaRecebimento(formaRecebimento);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const deleteParametro = async () => {
        showLoading();
        const resposta = await axiosDelete(`${url}${formaRecebimento.id}`);
        hideLoading();

        if (resposta.status === 200) {
            showSuccess({
                summary: "Sucesso",
                detail: "Forma de recebimento deletada com sucesso!",
                life: 3000,
            });

            listagemRef.current?.buscarDados();
        } else {
            showError({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
        setDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTRO_FORMARECEBIMENTOS_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de forma de recebimento"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        setRedirecionar({
                            pathname: "/financeiro/cadastros/formas-recebimentos/form",
                            state: { formaRecebimento: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTRO_FORMARECEBIMENTOS_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Deletar forma de recebimento"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const filtroStatus = (status) => {
        listagemRef.current.filter(status.value, "ativo", "contains");
        setFiltro(status.value);
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_CADASTRO_FORMARECEBIMENTOS_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setRedirecionar("/financeiro/cadastros/formas-recebimentos/form")}
            />
            <Dropdown
                id="opcoesPesquisa"
                name="opcoesPesquisa"
                style={{ width: "120px" }}
                value={filtro}
                options={opcoesPesquisa}
                optionLabel="label"
                placeholder={!filtro ? ("" ? filtro : "Todos") : filtro ? "Ativos" : false ? filtro : "Inativos"}
                onChange={filtroStatus}
            />
        </>
    );

    const statusBodyTemplate = (rowData) => {
        if (rowData.ativo) return <Tag severity="success" value="ATIVO" />;
        return <Tag severity="danger" value="DESATIVADO" />;
    };

    const escopoBodyTemplate = (escopo) => {
        if (escopo === "R") return <Tag severity="success" value="Recebimento" style={{ width: "130px" }} />;
        else if (escopo === "P") return <Tag severity="danger" value="Pagamento" style={{ width: "130px" }} />;
        return <Tag severity="info" value="Pagamento/Recebimento" style={{ width: "130px" }} />;
    };

    const TipoFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "id",
                    optionLabel: "label",
                    options: TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE,
                }}
                options={options}
            />
        );
    };

    const EscopoFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: ESCOPO_FORMA_RECEB_PGTO_CHOICE,
                }}
                options={options}
            />
        );
    };

    const colunas = [
        {
            field: "codigo",
            header: "Código",
            style: { minWidth: "50px" },
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "tipo.descricao",
            header: "Tipo",
            style: { minWidth: "80px" },
            filter: true,
            filterElement: TipoFiltroTemplate,
            filterField: "tipo",
        },
        {
            field: "descricao",
            header: "Descrição",
            style: { minWidth: "200px" },
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "escopo",
            header: "Escopo",
            action: (e) => escopoBodyTemplate(e.escopo),
            filter: true,
            filterElement: EscopoFiltroTemplate,
            style: { minWidth: "150px" },
        },
        { field: "ativo", header: "Ativo", action: (e) => statusBodyTemplate(e), style: { minWidth: "50px" } },
        {
            field: "action",
            header: "Ações",
            style: { minWidth: "80px" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    return redirecionar ? (
        <Redirect to={redirecionar} />
    ) : (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Formas de Recebimentos"
                        colunas={colunas}
                        painelEsquerdo={cabecalhoTabela}
                        urlPesquisa={url}
                        configTabela={{
                            paginator: true,
                            lazy: true,
                            scrollable: true,
                        }}
                        filtros={{
                            codigo: { value: null, matchMode: "equals" },
                            tipo: { value: null, matchMode: "equals" },
                            descricao: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                            },
                            escopo: { value: null, matchMode: "equals" },
                        }}
                    />
                    <ConfirmDialog
                        visible={deleteDialog}
                        onHide={esconderDeleteDialog}
                        header="Confirmação"
                        message={
                            formaRecebimento && (
                                <span>
                                    {"Deseja realmente excluir a forma de recebimento. "}
                                    <b>{formaRecebimento.descricao}</b>?
                                </span>
                            )
                        }
                        icon="pi pi-info-circle p-mr-3"
                        accept={deleteParametro}
                        acceptLabel="Sim"
                        acceptClassName="p-button-danger"
                        reject={esconderDeleteDialog}
                        rejectLabel="Não"
                    />
                </div>
            </div>
        </div>
    );
};
