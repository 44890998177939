import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";

import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import { axiosDelete } from "@/services/http";
import useToast from "@/hooks/useToast";
import useClearRefs from "@/hooks/useClearRefs";
import {
    CodigoFiltroTemplate,
    DateFiltroTemplate,
    DropdownFiltroTemplate,
    MoedaFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import { TIPO_CONTA_FINANCEIRA_CHOICE } from "@/assets/constants/constants";

const url = "/financeiro/contas-financeiras/";

export const FinanceiroContaFinanceiraPage = () => {
    const [redirecionar, setRedirecionar] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [contaFinanceira, setContaFinanceira] = useState(null);
    const listagemRef = useRef(null);
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();

    useClearRefs(listagemRef);

    const confirmarDelete = (contaFinanceira) => {
        setContaFinanceira(contaFinanceira);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const tipoFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "id",
                    optionLabel: "label",
                    options: TIPO_CONTA_FINANCEIRA_CHOICE,
                }}
                options={options}
            />
        );
    };

    const deleteParametro = async () => {
        showLoading();
        const resposta = await axiosDelete(`${url}${contaFinanceira.id}`);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            showSuccess({
                summary: "Sucesso",
                detail: "Conta financeira deletada com sucesso!",
                life: 5000,
            });

            listagemRef.current?.buscarDados();
        } else {
            showError({
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição. \n Caixa com movimentação não pode ser exclúido, apenas desativado!",
                life: 5000,
            });
            hideLoading();
        }
        setDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTRO_CONTAFINANCEIRA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    onClick={() =>
                        setRedirecionar({
                            pathname: "/financeiro/cadastros/contas-financeiras/form",
                            state: { contaFinanceira: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTRO_CONTAFINANCEIRA_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_CADASTRO_CONTAFINANCEIRA_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setRedirecionar("/financeiro/cadastros/contas-financeiras/form")}
            />
        </>
    );

    const colunas = [
        {
            field: "tipo_conta.descricao",
            header: "Tipo conta",
            style: { width: "13%" },
            filter: true,
            filterElement: tipoFiltroTemplate,
            filterField: "tipo_conta",
        },
        {
            field: "codigo",
            header: "Código",
            style: { width: "10%" },
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        { field: "descricao", header: "Descrição", filter: true, filterElement: TextoFiltroTemplate },
        {
            field: "data_abertura",
            header: "Data abertura",
            style: { width: "12%" },
            dateFormat: "dd/MM/yyyy",
            filter: true,
            filterElement: DateFiltroTemplate,
        },
        {
            field: "saldo_inicial",
            header: "Saldo inicial",
            style: { width: "10%" },
            money: true,
            filter: true,
            filterElement: MoedaFiltroTemplate,
        },
        {
            field: "saldo_atual",
            header: "Saldo atual",
            style: { width: "10%" },
            money: true,
            filter: true,
            filterElement: MoedaFiltroTemplate,
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "15%" },
        },
    ];

    return redirecionar ? (
        <Redirect to={redirecionar} />
    ) : (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Contas Financeiras"
                        colunas={colunas}
                        painelEsquerdo={cabecalhoTabela}
                        urlPesquisa={url}
                        responsiva
                        configTabela={{
                            paginator: true,
                            lazy: true,
                        }}
                        filtros={{
                            tipo_conta: { value: null, matchMode: "equals" },
                            codigo: { value: null, matchMode: "equals" },
                            descricao: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                            },
                            data_abertura: { value: null, matchMode: "equals" },
                            saldo_inicial: { value: null, matchMode: "equals" },
                            saldo_atual: { value: null, matchMode: "equals" },
                        }}
                    />
                    <ConfirmDialog
                        visible={deleteDialog}
                        onHide={esconderDeleteDialog}
                        header="Confirmação"
                        message={
                            contaFinanceira && (
                                <span>
                                    {"Deseja realmente excluir a conta financeira "}
                                    <b>{contaFinanceira.descricao}</b>?
                                </span>
                            )
                        }
                        icon="pi pi-info-circle p-mr-3"
                        accept={deleteParametro}
                        acceptLabel="Sim"
                        acceptClassName="p-button-danger"
                        reject={esconderDeleteDialog}
                        rejectLabel="Não"
                    />
                </div>
            </div>
        </div>
    );
};
