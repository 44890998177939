import React, { useCallback, useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dropdown } from "@/components/Dropdown";
import { axiosGet } from "@/services/http";
import { MakoInputQuantidadeSku } from "@/components/MakoInputs/MakoInputQuantidadeSku";
import { parseNumberToMoneyHTML } from "@/assets/util/util";
import { Tag } from "primereact/tag";

import useTroca from "@/hooks/useTroca";
import useLoading from "@/hooks/useLoading";
import MakoListagem from "@/components/MakoListagem";

export const ItemVendaModalForm = (props) => {
    const [listaMotivos, setListaMotivos] = useState([]);
    const [listaEstoques, setListaEstoques] = useState([]);
    const [itensVenda, setItensVenda] = useState([]);
    const [itensSelecionados, setItensSelecionados] = useState([]);
    const { showLoading, hideLoading } = useLoading();
    const { handleItemTrocaDevolucao, troca } = useTroca();
    const toastRef = useRef(null);
    const listagemRef = useRef(null);

    async function handleSubmit() {
        try {
            let valido = true;
            itensSelecionados.forEach((item) => {
                if (item.motivo_devolucao) handleItemTrocaDevolucao("novo", item);
                else {
                    valido = false;
                    toastRef.current.show({
                        severity: "warn",
                        summary: "Item incompleto",
                        detail: `O item ${item.descricao_reduzida} (orçamento: ${item.venda}) não possui motivo de devolução. Por favor, preencha e tente novamente.`,
                        life: 3000,
                    });
                }
            });
            if (valido) {
                props.toast.current.show({
                    severity: "success",
                    summary: "Sucesso",
                    detail: `O(s) item(ns) adicionado(s) com sucesso.`,
                    life: 3000,
                });
                props.dialog(false);
            }
        } catch (error) {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não foi possível processar a sua requisição.",
                life: 3000,
            });
        }
    }

    const listarMotivos = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/vendas/motivo-devolucao?limit=10000");
        hideLoading();

        if (resposta.status === 200) setListaMotivos(resposta.data.results);
    }, [showLoading, hideLoading]);

    const listarEstoques = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/produtos/centros-estocagem?limit=10000");
        hideLoading();

        if (resposta.status === 200) setListaEstoques(resposta.data.results);
    }, [showLoading, hideLoading]);

    const listaItensVenda = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet(`/vendas/itens-vendas?venda__cliente=${troca.cliente?.id}&limit=1000`);
        hideLoading();

        if (resposta.status === 200) {
            let itens_venda = [];

            await resposta.data.results.forEach((item) => {
                itens_venda.push({
                    id: item.id,
                    sku: {
                        ...item.sku,
                        label: `${item.sku.codigo} - ${item.sku.descricao_reduzida}`,
                    },
                    descricao_reduzida: item.descricao_reduzida,
                    encargos: item.valor_encargos,
                    quantidade: parseFloat(item.quantidade) - parseFloat(item.quantidade_devolvida),
                    subtotal: item.valor_servico_agregado,
                    cubagem: item.cubagem,
                    valor_unitario: parseFloat(item.valor_unitario),
                    motivo_devolucao: null,
                    justificativa: "",
                    retorno_estoque: true,
                    defeito: false,
                    venda: item.venda,
                    sku_movimenta_estoque: {
                        ...item.sku_movimenta_estoque,
                        label: `${item.sku_movimenta_estoque.codigo} - ${item.sku_movimenta_estoque.descricao_reduzida}`,
                    },
                    estoque_retorno: null,
                    unidade_medida: item.unidade_venda,
                });
            });

            setItensVenda(itens_venda);
        }
    }, [showLoading, hideLoading, troca.cliente?.id]);

    const retornaSimOuNao = (aux) => {
        if (aux) return <Tag severity="success" value="Sim" style={{ width: "100px" }} />;
        return <Tag severity="danger" value="Não" style={{ width: "100px" }} />;
    };

    const editarQuantidade = (options, quantidade, aux) => {
        options.editorCallback(quantidade);
        let novaLista = [];
        itensVenda.forEach(async (item) => {
            if (item.id === aux) item.quantidade = quantidade;
            novaLista.push(item);
        });
        setItensVenda(novaLista);
    };

    const editorQuantidade = (options) => {
        return (
            <MakoInputQuantidadeSku
                id="quantidade"
                name="quantidade"
                min={0}
                permiteFracionario
                showButtons
                value={options.value}
                onValueChange={(e) => editarQuantidade(options, e.target.value, options.rowData.id)}
            />
        );
    };

    const editarMotivo = (options, motivo, aux) => {
        options.editorCallback(motivo);
        let novaLista = [];
        itensVenda.forEach((item) => {
            if (item.id === aux) item.motivo_devolucao = motivo;
            novaLista.push(item);
        });
        setItensVenda(novaLista);
    };

    const editorMotivo = (options) => {
        return (
            <Dropdown
                optionValue="id"
                optionLabel="descricao"
                options={listaMotivos}
                filter
                filterBy="descricao"
                placeholder="Selecione um motivo"
                value={options.value}
                onChange={(e) => editarMotivo(options, e.target.value, options.rowData.id)}
                style={{ width: "100%" }}
            />
        );
    };

    const motivoBodyTemplate = (plano) => {
        return (
            <Dropdown
                options={listaMotivos}
                optionValue="id"
                optionLabel="descricao"
                disabled
                placeholder="-"
                value={plano}
                style={{ width: "100%" }}
            />
        );
    };

    const editarEstoque = (options, estoque, aux) => {
        options.editorCallback(estoque);
        let novaLista = [];
        itensVenda.forEach((item) => {
            if (item.id === aux) item.estoque_retorno = estoque;
            novaLista.push(item);
        });
        setItensVenda(novaLista);
    };

    const editorEstoque = (options) => {
        return (
            <Dropdown
                optionValue="id"
                optionLabel="descricao"
                options={listaEstoques}
                filter
                filterBy="descricao"
                placeholder="Selecione um estoque"
                value={options.value}
                onChange={(e) => editarEstoque(options, e.target.value, options.rowData.id)}
                style={{ width: "100%" }}
            />
        );
    };

    const estoqueBodyTemplate = (estoque) => {
        return (
            <Dropdown
                options={listaEstoques}
                optionValue="id"
                optionLabel="descricao"
                disabled
                placeholder="-"
                value={estoque}
                style={{ width: "100%" }}
            />
        );
    };

    const colunas = [
        { selectionMode: "multiple", style: { minWidth: "20px" } },
        { field: "venda", header: "Venda/Orçamento", style: { minWidth: "200px" } },
        {
            field: "sku.label",
            header: "Item",
            style: { minWidth: "350px" },
        },
        {
            field: "motivo_devolucao",
            header: "Motivo Devolução",
            style: { minWidth: "350px" },
            editor: (options) => editorMotivo(options),
            action: (e) => motivoBodyTemplate(e.motivo_devolucao),
        },
        {
            field: "quantidade",
            header: "Quantidade",
            style: { minWidth: "250px" },
            editor: (options) => editorQuantidade(options),
        },
        {
            field: "unidade_medida.nome",
            header: "Unidade Medida",
            style: { minWidth: "200px" },
        },
        {
            field: "valor_unitario",
            header: "Valor unitário",
            style: { minWidth: "250px" },
            action: (e) => parseNumberToMoneyHTML(e.valor_unitario),
        },
        {
            field: "cubagem",
            header: "Cubagem",
            style: { minWidth: "150px" },
        },
        {
            field: "encargos",
            header: "Encargos",
            style: { minWidth: "150px" },
            action: (e) => parseNumberToMoneyHTML(e.encargos),
        },
        {
            field: "subtotal",
            header: "Subtotal",
            style: { minWidth: "150px" },
            action: (e) => parseNumberToMoneyHTML(e.subtotal),
        },
        {
            field: "retorno_estoque",
            header: "Retorna Estoque?",
            style: { minWidth: "150px" },
            action: (e) => retornaSimOuNao(e.retorno_estoque),
        },
        {
            field: "estoque_retorno",
            header: "Estoque de Retorno",
            style: { minWidth: "350px" },
            editor: (options) => editorEstoque(options),
            action: (e) => estoqueBodyTemplate(e.estoque_retorno),
        },
        { field: "sku_movimenta_estoque.label", header: "Item que Movimenta Estoque", style: { minWidth: "350px" } },
        {
            field: "defeito",
            header: "Defeito",
            style: { minWidth: "150px" },
            action: (e) => retornaSimOuNao(e.defeito),
        },
    ];

    useEffect(() => {
        listarEstoques();
        listarMotivos();
        listaItensVenda();
    }, [listarMotivos, listarEstoques, listaItensVenda]);

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <Toast ref={toastRef} />
                <form>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label>Selecione um ou mais itens para inserção:</label>
                            <MakoListagem
                                ref={listagemRef}
                                colunas={colunas}
                                dadosLocal={itensVenda}
                                configTabela={{
                                    scrollable: true,
                                    scrollHeight: "400px",
                                    selection: itensSelecionados,
                                    onSelectionChange: (e) => setItensSelecionados(e.value),
                                    selectionMode: "multiple",
                                }}
                            />
                        </div>
                    </div>
                    <div className="p-grid p-col-12 p-md-12 p-justify-end p-mt-1">
                        <Button
                            icon="pi pi-plus"
                            label="Inserir Itens"
                            type="button"
                            className="p-button-success p-mr-2"
                            disabled={!itensSelecionados?.length > 0}
                            onClick={() => handleSubmit()}
                        />
                        <Button
                            icon="pi pi-times"
                            label="Fechar"
                            type="reset"
                            className="p-button-danger"
                            onClick={() => props.dialog(false)}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};
