import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import MakoListagem from "@/components/MakoListagem";
import { axiosPost } from "@/services/http";
import { gerarParcelas } from "@/assets/util/calcFinanceiros";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useLoading from "@/hooks/useLoading";

export const FracionamentoRecebimentoPage = (props) => {
    const [devedor, setDevedor] = useState({ nome: "", doc: "" });
    const [recebimento, setRecebimento] = useState(null);
    const [fracionamentos, setFracionamentos] = useState([]);
    const [qtdFracionamentos, setQtdFracionamentos] = useState(2);
    const [valorPrimeira, setValorPrimeira] = useState(0);
    const toastRef = useRef(null);
    const history = useHistory();
    const [formatarDocumento] = useFormatCNPJCPF();
    const { showLoading, hideLoading } = useLoading();

    async function handleSubmit() {
        const _fracionamentos = fracionamentos.map((frac) => ({
            recebimento_id: recebimento.id,
            documento: frac.documento,
            valor: frac.valor,
        }));

        showLoading();
        const resposta = await axiosPost("/financeiro/incluir-fracionamentos/", { fracionamentos: _fracionamentos });
        hideLoading();

        if (resposta.status === 200) {
            toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Fracionamento realizado com sucesso!",
                life: 1500,
            });

            setTimeout(() => {
                history.push("/financeiro/financeiro/recebimentos");
            }, 2000);
        } else if (resposta.status === 400) {
            toastRef.current.show({
                severity: "warn",
                summary: "Falha no fracionamento",
                detail: resposta.data.msg,
                life: 3000,
            });
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
        }
    }

    async function fracionarRecebimento() {
        if (valorPrimeira > 0) {
            let _fracionamentos = [
                {
                    documento: `${recebimento.documento} FRA-1`,
                    vencimento: recebimento.vencimento,
                    valor: valorPrimeira,
                },
            ];

            const fracionamentos = gerarParcelas(recebimento.valor - valorPrimeira, qtdFracionamentos - 1);

            setFracionamentos([
                ..._fracionamentos,
                ...fracionamentos.map((fracionamento, index) => ({
                    documento: `${recebimento.documento} FRA-${index + 2}`,
                    vencimento: recebimento.vencimento,
                    valor: fracionamento,
                })),
            ]);
        } else {
            const fracionamentos = gerarParcelas(recebimento.valor, qtdFracionamentos);

            setFracionamentos(
                fracionamentos.map((fracionamento, index) => ({
                    documento: `${recebimento.documento} FRA-${index + 1}`,
                    vencimento: recebimento.vencimento,
                    valor: fracionamento,
                }))
            );
        }
    }

    useEffect(() => {
        if (props.location.state) {
            const { devedor, quantidade_parcelas, numero_parcela } = props.location.state;

            setDevedor({
                nome: devedor.nome,
                doc: formatarDocumento(devedor.identificacao),
            });

            setRecebimento({
                ...props.location.state,
                parcela: numero_parcela === 0 ? "ENTRADA" : `${numero_parcela}/${quantidade_parcelas}`,
            });
        }
    }, [props.location.state, formatarDocumento]);

    const colunas = [
        { field: "documento", header: "Documento" },
        { field: "vencimento", header: "Vencimento", dateFormat: "dd/MM/yyyy" },
        { field: "valor", header: "Valor", money: true },
    ];

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <h5>Fracionar parcela</h5>
                    <Panel header="Devedor" className="p-mb-3">
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="doc">CPF / CNPJ</label>
                                <InputText id="doc" disabled value={devedor.doc} />
                            </div>
                            <div className="p-field p-col-12 p-md-8">
                                <label htmlFor="nome-completo">Nome completo</label>
                                <InputText id="nome-completo" disabled value={devedor.nome} />
                            </div>
                        </div>
                    </Panel>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="documento">Documento</label>
                            <InputText id="documento" name="documento" disabled value={recebimento?.documento || ""} />
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="parcela">Parcela</label>
                            <InputText id="parcela" name="parcela" disabled value={recebimento?.parcela || ""} />
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor">Valor</label>
                            <MakoInputMoeda id="valor" name="valor" disabled valueMoeda={recebimento?.valor} />
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="qtd-fracionamentos">Qtd. fracionamentos *</label>
                            <InputNumber
                                id="qtd-fracionamentos"
                                min={2}
                                value={qtdFracionamentos}
                                onValueChange={(e) => setQtdFracionamentos(e.value)}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="valor-primeira">Valor da primeira *</label>
                            <MakoInputMoeda
                                id="valor-primeira"
                                min={0}
                                valueMoeda={valorPrimeira}
                                tooltip="Deixe 0 para fracionar igualmente."
                                tooltipOptions={{ position: "bottom" }}
                                onChangeMoeda={(e) => setValorPrimeira(e.value)}
                            />
                        </div>
                    </div>
                    <Button
                        type="submit"
                        label="Fracionar"
                        icon="fas fa-chart-pie"
                        className="p-button-secondary p-mb-3"
                        onClick={fracionarRecebimento}
                    />
                    <MakoListagem colunas={colunas} dadosLocal={fracionamentos} configTabela={{ paginator: true }} />
                    <Button icon="pi pi-save" label="Gravar" className="p-mt-3" onClick={handleSubmit} />
                    <Button
                        label="Cancelar"
                        icon="pi pi-times"
                        className="p-button-danger p-ml-2"
                        onClick={() => history.push("/financeiro/financeiro/recebimentos")}
                    />
                </div>
            </div>
        </div>
    );
};
