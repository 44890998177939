import React from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import MakoListagem from "@/components/MakoListagem";
import { MakoInputTransportador } from "@/components/MakoInputs/MakoInputTransportador";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { OP_CRUD_DJANGO } from "@/assets/util/persistenciaDjango";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useOrdemCompra from "@/hooks/useOrdemCompra";

export const TransportadoresOrdemCompraForm = () => {
    const [formatarDocumento] = useFormatCNPJCPF();
    const { dadosBasicos, permiteAlterar, transportadores, handleTransportador } = useOrdemCompra();

    const formik = useFormik({
        initialValues: {
            transportador: null,
            trajeto: "",
            cif: false,
            valor_frete: 0,
            _status: OP_CRUD_DJANGO.novo,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                transportador: Yup.object().typeError("Você precisa selecionar um transportador."),
                trajeto: Yup.string().max(60, "O campo 'trajeto' não pode ser maior que 60 caracteres."),
            });
            await formSchema.validate(values, { abortEarly: false });
            handleTransportador(values, values._status);
            formik.resetForm();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const formatarCNPJBodyTemplate = (rowData) => {
        return formatarDocumento(rowData.transportador.identificacao);
    };

    const cifFobBodyTemplate = (rowData) => {
        if (rowData.cif) return <span>CIF</span>;
        return <span>FOB</span>;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-pencil"
                    disabled={!permiteAlterar}
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    onClick={() =>
                        formik.setValues({
                            ...rowData,
                            _status: OP_CRUD_DJANGO.editar,
                        })
                    }
                />
                <Button
                    icon="pi pi-trash"
                    disabled={!permiteAlterar}
                    className="p-button-rounded p-button-danger p-mb-1"
                    onClick={() => handleTransportador(rowData, OP_CRUD_DJANGO.deletar)}
                />
            </div>
        );
    };

    const colunasTransportadores = [
        { field: "transportador.nome", header: "Nome" },
        {
            field: "transportador.identificacao",
            header: "CNPJ",
            style: { width: "15%" },
            action: (e) => formatarCNPJBodyTemplate(e),
        },
        { field: "trajeto", header: "Trajeto" },
        { field: "valor_frete", header: "Valor frete", money: true },
        { field: "cif", header: "CIF / FOB", action: (e) => cifFobBodyTemplate(e) },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="transportador">Transportador *</label>
                        <MakoInputTransportador
                            id="transportador"
                            name="transportador"
                            value={formik.values.transportador}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.transportador })}
                        />
                        {formik.errors.transportador && (
                            <small className="p-error">{formik.errors.transportador}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="trajeto">Trajeto</label>
                        <InputText
                            id="trajeto"
                            name="trajeto"
                            value={formik.values.trajeto}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.trajeto })}
                        />
                        {formik.errors.trajeto && <small className="p-error">{formik.errors.trajeto}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-frete-previsto">Valor do frete previsto</label>
                        <MakoInputMoeda
                            id="valor-frete-previsto"
                            name="valor_frete_previsto"
                            disabled
                            valueMoeda={dadosBasicos?.valor_frete_previsto || 0}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor-frete">Valor do frete</label>
                        <MakoInputMoeda
                            id="valor-frete"
                            name="valor_frete"
                            valueMoeda={formik.values.valor_frete}
                            onChangeMoeda={formik.handleChange}
                        />
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-3 p-mt-5">
                        <Checkbox id="cif" name="cif" checked={formik.values.cif} onChange={formik.handleChange} />
                        <label htmlFor="cif">Frete pago pelo emitente?</label>
                    </div>
                </div>
                <CamposObrigatorios />
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button
                            type="submit"
                            icon="pi pi-plus"
                            disabled={!permiteAlterar}
                            label="Adicionar"
                            className="p-mr-2 p-mb-2"
                        />
                        <Button
                            label="Limpar"
                            type="reset"
                            disabled={!permiteAlterar}
                            icon="pi pi-trash"
                            className="p-button-warning p-mr-2 p-mb-2"
                            onClick={() => formik.resetForm()}
                        />
                    </div>
                </div>
            </form>
            <MakoListagem colunas={colunasTransportadores} dadosLocal={transportadores} />
        </>
    );
};
