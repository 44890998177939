import React, { useState } from "react";
import { Button } from "primereact/button";

import { axiosPut } from "@/services/http";
import useToast from "@/hooks/useToast";

export const BotaoEscalarNotificacao = ({ notificacao }) => {
    const [loading, setLoading] = useState(false);
    const { showSuccess, showWarning, showError } = useToast();

    async function escalarNotificacao() {
        setLoading(true);
        const { status, data } = await axiosPut(`/pessoas/escalar-notificacao/${notificacao.id}/`);
        setLoading(false);
        if (status === 200) {
            showSuccess({
                summary: "Sucesso!",
                detail: data.msg,
                life: 2000,
            });
        } else if (status === 400 || status === 404) {
            showWarning({
                summary: "Falhou...",
                detail: data.msg,
                life: 3000,
            });
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível processar a sua requisição.",
                life: 3000,
            });
        }
    }

    const handleClick = () => {
        escalarNotificacao();
    };

    return (
        <Button
            icon="pi pi-arrow-up"
            label="Escalar"
            loading={loading}
            disabled={notificacao.status !== "P"}
            className="p-button-secondary p-mb-1"
            onClick={handleClick}
        />
    );
};
