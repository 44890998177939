import React, { useEffect, useRef, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";
import { axiosPatch, axiosPost, axiosGet } from "@/services/http";
import { TIPO_CONTA_FINANCEIRA_CHOICE } from "@/assets/constants/constants";
import useLoading from "@/hooks/useLoading";
import { dataToStr } from "@/assets/util/datas";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";

const url = "/financeiro/contas-financeiras/";
const urlvoltar = "/financeiro/cadastros/contas-financeiras";

export const FinanceiroContaFinanceiraForm = (props) => {
    const { showLoading, hideLoading } = useLoading();
    const [carteiras, setCarteiras] = useState([]);
    const toastRef = useRef(null);
    const history = useHistory();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            codigo: "",
            descricao: "",
            data_abertura: null,
            saldo_atual: 0,
            saldo_inicial: 0,
            perfil: null,
            carteira: null,
            tipo_conta: null,
            ativa: true,
        },
        onSubmit: handleSubmit,
    });

    useEffect(() => {
        if (props.location.state?.contaFinanceira) {
            const { carteira, tipo_conta, ...rest } = props.location.state.contaFinanceira;

            setValues({
                ...rest,
                carteira: carteira.id,
                tipo_conta: tipo_conta.id,
            });
        }
    }, [props.location.state?.contaFinanceira, setValues]);

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                tipo_conta: Yup.string()
                    .required("O campo 'tipo' é obrigatório.")
                    .typeError("Selecione um tipo válido."),
                codigo: Yup.string().required("O campo 'código' é obrigatório."),
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                data_abertura: Yup.date()
                    .required("O campo 'data de abertura' é obrigatório.")
                    .typeError("Informe uma data válida."),
                perfil: Yup.string()
                    .required("O campo 'perfil' é obrigatório.")
                    .typeError("Selecione um perfil válido."),
                carteira: Yup.string()
                    .required("O campo 'carteira' é obrigatório.")
                    .typeError("Selecione uma carteira válida."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                const body = {
                    ...values,
                    data_abertura: dataToStr(values.data_abertura, "yyyy-MM-dd"),
                };

                showLoading();
                const resposta = await axiosPost(url, body);
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Conta financeira cadastrada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                const { id, codigo, descricao, ativa } = values;

                const body = {
                    codigo,
                    descricao,
                    ativa,
                };

                showLoading();
                const resposta = await axiosPatch(`${url}${id}/`, body);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Conta financeira alterada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    const listarCarteiras = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/financeiro/carteiras-contas-financeiras/");
        hideLoading();

        if (resposta.status === 200) {
            setCarteiras(resposta.data.results);
        }
    }, [showLoading, hideLoading]);

    useEffect(() => {
        listarCarteiras();
    }, [listarCarteiras]);

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <h3>{!formik.values.id ? "Nova conta financeira" : "Manutenção de conta financeira"}</h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="tipo_conta">Tipo *</label>
                                <Dropdown
                                    id="tipo_conta"
                                    name="tipo_conta"
                                    placeholder="Selecione um tipo"
                                    options={TIPO_CONTA_FINANCEIRA_CHOICE}
                                    optionValue="id"
                                    optionLabel="label"
                                    value={formik.values.tipo_conta}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.tipo_conta })}
                                />
                                {formik.errors.tipo_conta && (
                                    <small className="p-error">{formik.errors.tipo_conta}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="codigo">Código *</label>
                                <InputText
                                    id="codigo"
                                    name="codigo"
                                    value={formik.values.codigo}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.codigo })}
                                />
                                {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="descricao">Descrição *</label>
                                <InputText
                                    id="descricao"
                                    name="descricao"
                                    value={formik.values.descricao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.descricao })}
                                />
                                {formik.errors.descricao && (
                                    <small className="p-error">{formik.errors.descricao}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="data_abertura">Data abertura *</label>
                                <MakoCalendar
                                    id="data_abertura"
                                    name="data_abertura"
                                    valueCalendar={formik.values.data_abertura}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.data_abertura })}
                                />
                                {formik.errors.data_abertura && (
                                    <small className="p-error">{formik.errors.data_abertura}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="saldo_inicial">Saldo inicial</label>
                                <MakoInputMoeda
                                    id="saldo_inicial"
                                    name="saldo_inicial"
                                    valueMoeda={formik.values.saldo_inicial}
                                    onChangeMoeda={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.saldo_inicial })}
                                />
                                {formik.errors.saldo_inicial && (
                                    <small className="p-error">{formik.errors.saldo_inicial}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="saldo_atual">Saldo atual</label>
                                <MakoInputMoeda
                                    id="saldo_atual"
                                    name="saldo_atual"
                                    valueMoeda={formik.values.saldo_atual}
                                    onChangeMoeda={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.saldo_atual })}
                                />
                                {formik.errors.saldo_atual && (
                                    <small className="p-error">{formik.errors.saldo_atual}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="perfil">Perfil *</label>
                                <MakoDropdownEmpresas
                                    id="perfil"
                                    name="perfil"
                                    value={formik.values.perfil}
                                    className={classNames({ "p-invalid": formik.errors.perfil })}
                                    onChange={(e) => setFieldValue("perfil", e.id)}
                                />
                                {formik.errors.perfil && <small className="p-error">{formik.errors.perfil}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="carteira">Carteira *</label>
                                <Dropdown
                                    id="carteira"
                                    name="carteira"
                                    placeholder="Selecione uma carteira"
                                    options={carteiras}
                                    optionValue="id"
                                    optionLabel="descricao"
                                    value={formik.values.carteira}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.carteira })}
                                />
                                {formik.errors.carteira && <small className="p-error">{formik.errors.carteira}</small>}
                            </div>
                            <div className="p-field-checkbox p-col-12 p-md-2 p-mt-4">
                                <Checkbox
                                    id="ativo"
                                    name="ativa"
                                    checked={formik.values.ativa}
                                    onChange={formik.handleChange}
                                />
                                <label htmlFor="ativo">{formik.values.ativa ? "Ativa" : "Inativa"}</label>
                            </div>
                        </div>
                        <p>
                            <b>* Campos obrigatórios.</b>
                        </p>
                        <div className="p-grid p-col-12 p-md-6">
                            <Button icon="pi pi-save" label="Gravar" type="submit" className="p-button-info p-mr-2" />
                            <Button
                                icon="pi pi-angle-double-left"
                                label="Voltar"
                                type="button"
                                className="p-button-danger"
                                onClick={cancelar}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
