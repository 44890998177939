import { useContext } from "react";

import AuthContext from "@/contexts/authContext";

const useCaixa = () => {
    const { caixa, loadCaixa, setCaixaUsuario, clearCaixa } = useContext(AuthContext);

    return { caixa, loadCaixa, setCaixaUsuario, clearCaixa };
};

export default useCaixa;
