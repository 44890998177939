import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { ModalCadastroUsuario } from "./modal/CadastrarUsuario";
import { ModalAlterarSenhaUsuario } from "./modal/AlterarSenhaUsuario";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { PageBase } from "@/components/PageBase";
import permissoes from "@/assets/constants/permissoes";
import { axiosPatch } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useAuth from "@/hooks/useAuth";
import useToast from "@/hooks/useToast";

export const GestaoGerenciarUsuariosPage = () => {
    const modalCadastroUsuarioRef = useRef(null);
    const modalAltSenhaUsuarioRef = useRef(null);
    const listagemRef = useRef(null);
    const history = useHistory();
    const [formatarDocumento] = useFormatCNPJCPF();
    const { showLoading, hideLoading } = useLoading();
    const { verifyPermission } = useAuth();
    const { showError } = useToast();

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.GESTAO_USUARIOS_GERENCIARUSUARIO_INCLUIR]}
                componente={Button}
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                label="Criar usuário"
                onClick={() => modalCadastroUsuarioRef.current?.abrirModal()}
            />
        </>
    );

    const alterarStatusUsuario = async (usuario) => {
        const body = { is_active: !usuario.is_active };
        showLoading();
        const { status } = await axiosPatch(`/pessoas/usuario/${usuario.id}/`, body);
        hideLoading();
        if (status === 200) {
            listagemRef.current?.buscarDados();
        } else {
            showError({
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
        }
    };

    const confirmarAlteracaoStatusUsuario = ({ usuario }) => {
        confirmDialog({
            header: "Confirmação",
            message: (
                <span>
                    Deseja mesmo {usuario.is_active ? "desativar" : "ativar"} o usuário <b>{usuario.username}</b>?
                </span>
            ),
            icon: "pi pi-exclamation-triangle",
            rejectLabel: "Cancelar",
            accept: () => alterarStatusUsuario(usuario),
            acceptClassName: usuario.is_active ? "p-button-danger" : "p-button-info",
            acceptLabel: "Confirmar",
        });
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_USUARIOS_GERENCIARUSUARIO_EDITAR]}
                    componente={Button}
                    icon="pi pi-check-square"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Editar permissões"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/gestao/usuarios/gerenciar-usuarios/form",
                            state: { gerenciarUsuario: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_USUARIOS_GERENCIARUSUARIO_EDITAR]}
                    componente={Button}
                    icon="fa fa-user-lock"
                    className="p-button-rounded p-button-secondary p-mr-2 p-mb-1"
                    tooltip="Editar senha"
                    tooltipOptions={{ position: "left" }}
                    onClick={() => modalAltSenhaUsuarioRef.current?.abrirModal(rowData.usuario)}
                />
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_USUARIOS_GERENCIARUSUARIO_DESATIVAR]}
                    componente={Button}
                    icon={rowData.usuario.is_active ? "pi pi-chevron-circle-down" : "pi pi-circle"}
                    className={`p-button-rounded p-button-${rowData.usuario.is_active ? "success" : "danger"} p-mb-1`}
                    tooltip={rowData.usuario.is_active ? "Desativar" : "Ativar"}
                    tooltipOptions={{ position: "left" }}
                    onClick={() => confirmarAlteracaoStatusUsuario(rowData)}
                />
            </div>
        );
    };

    const rowClass = (rowData) => {
        return {
            "mako-table-status-ativo": rowData.usuario.is_active,
            "mako-table-status-inativo": !rowData.usuario.is_active,
        };
    };

    const exibeEmail = (emails) => {
        if (emails.length > 0) return emails[0].email;
        return "-";
    };

    const colunas = [
        { field: "id", header: "Código", style: { minWidth: "8rem" }, filter: true },
        { field: "usuario.username", header: "Usuário", style: { minWidth: "15rem" }, filter: true },
        {
            field: "nome",
            header: "Nome",
            filter: true,
            style: { minWidth: "25rem" },
        },
        {
            field: "identificacao",
            header: "CPF",
            style: { minWidth: "10rem" },
            action: (e) => formatarDocumento(e.identificacao),
            filter: true,
        },
        { field: "email", header: "Email", style: { minWidth: "25rem" }, action: (e) => exibeEmail(e.emailperfil_set) },
        {
            field: "action",
            header: "Ações",
            style: { minWidth: "10rem" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                titulo="Gerenciar Usuários"
                ref={listagemRef}
                colunas={colunas}
                urlPesquisa={
                    "/pessoas/perfis?query={id,usuario,nome,identificacao,emailperfil_set,papeis_vigentes}&usuario__isnull=false&limit=1000"
                }
                painelEsquerdo={cabecalhoTabela}
                configTabela={{
                    paginator: true,
                    lazy: true,
                    scrollable: true,
                    rowClassName: rowClass,
                }}
            />
            <ConfirmDialog />
            <ModalCadastroUsuario ref={modalCadastroUsuarioRef} onCreate={() => listagemRef.current?.buscarDados()} />
            <ModalAlterarSenhaUsuario ref={modalAltSenhaUsuarioRef} isRoot={verifyPermission(permissoes.ROOT)} />
        </PageBase>
    );
};
