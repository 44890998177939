import React, { useRef, useState, useEffect, useCallback } from "react";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { TabView, TabPanel } from "primereact/tabview";
import { InputNumber } from "primereact/inputnumber";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { axiosPost, axiosPut, axiosGet } from "@/services/http";
import { PermissoesForm } from "@/pages/Gestao/Usuarios/PapeisPerfis/permissoesForm";
import { useHistory } from "react-router-dom";
import { CHAVE_PAPEL_CHOICE, TIPO_PESSOA_APLICAVEL_CHOICE } from "@/assets/constants/constants";

import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";

const url = "/pessoas/papeis-perfis/";
const urlvoltar = "/gestao/usuarios/papeis-perfis";

export const PessoasPapeisPerfisForm = (props) => {
    const [comissoes, setComissoes] = useState([]);
    const { showLoading, hideLoading } = useLoading();
    const history = useHistory();
    const toastRef = useRef(null);

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: {
            nome: "",
            max_desconto_avista: 0,
            max_desconto_encargos: 0,
            chave: null,
            tipo_pessoa_aplicavel: null,
            comissao_venda: null,
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    useEffect(() => {
        if (props.location.state?.papeisPerfis) {
            setValues(props.location.state.papeisPerfis);
        }
    }, [props.location.state?.papeisPerfis, setValues]);

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo 'nome' é obrigatório."),
                tipo_pessoa_aplicavel: Yup.number()
                    .required("O campo 'tipo de pessoa aplicável' é obrigatório.")
                    .typeError("Selecione um tipo de pessoa aplicável."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(url, values);
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Papel de perfil cadastrado com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push({
                            path: "/gestao/usuarios/papeis-perfis/form",
                            state: { papeisPerfis: resposta.data },
                        });
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                values.permissoes = {};
                showLoading();
                const resposta = await axiosPut(`${url}${values.id}/`, values);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Papel de perfil alterado com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    const listarComissaoVendas = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/vendas/comissoes-vendas?aplica_perfil=true");
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            setComissoes(resposta.data.results);
        }
    }, [showLoading, hideLoading]);

    useEffect(() => {
        listarComissaoVendas();
    }, [listarComissaoVendas]);

    useEffect(() => {
        if (formik.values.tipo_pessoa_aplicavel && formik.values.tipo_pessoa_aplicavel.id) {
            setFieldValue("tipo_pessoa_aplicavel", formik.values.tipo_pessoa_aplicavel.id);
        }

        if (formik.values.chave && formik.values.chave.id) {
            setFieldValue("chave", formik.values.chave.id);
        }
    }, [formik.values.tipo_pessoa_aplicavel, formik.values.chave, setFieldValue]);

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <h3>
                        {!formik.values.id
                            ? "Novo papel de perfil"
                            : `Manutenção de papel de perfil: ${formik.values.nome}`}
                    </h3>
                    <TabView className="tabview-custom">
                        <TabPanel header="Papel perfil" leftIcon="pi pi-fw pi-book">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="p-fluid p-formgrid p-grid">
                                    <div className="p-field p-col-12 p-md-6">
                                        <label htmlFor="nome">Nome *</label>
                                        <InputText
                                            id="nome"
                                            name="nome"
                                            value={formik.values.nome}
                                            onChange={formik.handleChange}
                                            className={classNames({ "p-invalid": formik.errors.nome })}
                                        />
                                        {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                                    </div>
                                    <div className="p-field p-col-12 p-md-3">
                                        <label htmlFor="tipo_pessoa_aplicavel">Tipo de pessoa aplicável *</label>
                                        <Dropdown
                                            id="tipo_pessoa_aplicavel"
                                            name="tipo_pessoa_aplicavel"
                                            placeholder="Selecione um tipo"
                                            options={TIPO_PESSOA_APLICAVEL_CHOICE}
                                            optionValue="value"
                                            optionLabel="label"
                                            value={formik.values.tipo_pessoa_aplicavel}
                                            onChange={formik.handleChange}
                                            className={classNames({ "p-invalid": formik.errors.tipo_pessoa_aplicavel })}
                                        />
                                        {formik.errors.tipo_pessoa_aplicavel && (
                                            <small className="p-error">{formik.errors.tipo_pessoa_aplicavel}</small>
                                        )}
                                    </div>
                                    <div className="p-field p-col-12 p-md-3">
                                        <label htmlFor="chave">Chave</label>
                                        <Dropdown
                                            id="chave"
                                            name="chave"
                                            placeholder="Selecione um chave"
                                            options={CHAVE_PAPEL_CHOICE}
                                            optionValue="value"
                                            optionLabel="label"
                                            value={formik.values.chave}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="p-fluid p-formgrid p-grid">
                                    <div className="p-field p-col-12 p-md-3">
                                        <label htmlFor="max_desconto_avista">Max. desconto à vista</label>
                                        <InputNumber
                                            id="max_desconto_avista"
                                            name="max_desconto_avista"
                                            suffix=" %"
                                            minFractionDigits={2}
                                            maxFractionDigits={2}
                                            min={0}
                                            value={formik.values.max_desconto_avista}
                                            onValueChange={formik.handleChange}
                                        />
                                    </div>
                                    <div className="p-field p-col-12 p-md-3">
                                        <label htmlFor="max_desconto_encargos">Max. desconto encargos</label>
                                        <InputNumber
                                            id="max_desconto_encargos"
                                            name="max_desconto_encargos"
                                            suffix=" %"
                                            minFractionDigits={2}
                                            maxFractionDigits={2}
                                            min={0}
                                            value={formik.values.max_desconto_encargos}
                                            onValueChange={formik.handleChange}
                                        />
                                    </div>
                                    <div className="p-field p-col-12 p-md-3">
                                        <label htmlFor="comissao_venda">Comissão de vendas</label>
                                        <Dropdown
                                            id="comissao_venda"
                                            name="comissao_venda"
                                            placeholder="Selecione uma comissão"
                                            options={comissoes}
                                            showClear
                                            filter
                                            filterBy="descricao"
                                            optionValue="id"
                                            optionLabel="descricao"
                                            value={formik.values.comissao_venda}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                    <div className="p-field-checkbox p-mt-5 p-col-12 p-md-3">
                                        <Checkbox
                                            inputId="status"
                                            name="ativo"
                                            disabled={formik.values.id ? false : true}
                                            checked={formik.values.ativo}
                                            onChange={formik.handleChange}
                                        />
                                        <label htmlFor="status">{formik.values.ativo ? "Ativo" : "Inativo"}</label>
                                    </div>
                                </div>

                                <p>
                                    <b>* Campos obrigatórios.</b>
                                </p>

                                <div className="p-grid p-col-12 p-md-6">
                                    <Button label="Gravar" type="submit" className="p-button-info p-mr-2" />
                                    <Button
                                        label="Cancelar"
                                        type="button"
                                        className="p-button-danger p-mr-2"
                                        onClick={cancelar}
                                    />
                                </div>
                            </form>
                        </TabPanel>
                        <TabPanel
                            header="Permissões"
                            disabled={formik.values.id ? false : true}
                            leftIcon="pi pi-fw pi-lock-open"
                        >
                            <PermissoesForm state={formik.values} />
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        </div>
    );
};
