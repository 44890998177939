import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { axiosGet, axiosPatch } from "@/services/http";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import useLoading from "@/hooks/useLoading";

export const ClonarPermissoesForm = (props) => {
    const [usuarioClonado, setUsuarioClonado] = useState(null);
    const [listaUsuarios, setListaUsuarios] = useState([]);
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);

    async function clonarPermissoes() {
        try {
            const usuarioRaiz = await axiosGet(
                `/pessoas/perfis?usuario__id=${usuarioClonado}&query={id, vinculoperfilpermissao_set}`
            );
            const usuarioAlvo = await axiosGet(
                `/pessoas/perfis?usuario__id=${props.usuario.id}&query={id, vinculoperfilpermissao_set}`
            );

            if (usuarioRaiz.data.results?.length === 0 || usuarioAlvo.data.results?.length === 0) {
                toastRef.current.show({
                    severity: "warn",
                    summary: "Não encontrado!",
                    detail: "Perfil do usuário não encontrado.",
                    life: 1500,
                });
            } else {
                const { vinculoperfilpermissao_set } = usuarioRaiz.data.results[0];
                const alvo = usuarioAlvo.data.results[0];
                if (vinculoperfilpermissao_set.length > 0) {
                    showLoading();
                    const resposta = await axiosPatch(`/pessoas/perfis/${alvo.id}/`, {
                        vinculoperfilpermissao_set: {
                            create: await vinculoperfilpermissao_set.map((vinculo) => {
                                return {
                                    empresa: alvo.id,
                                    permissao: vinculo.permissao.id,
                                    vigencia_final: vinculo.vigencia_final,
                                };
                            }),
                        },
                    });
                    hideLoading();

                    if (resposta.status === 200) {
                        toastRef.current.show({
                            severity: "success",
                            summary: "Sucesso",
                            detail: "Permissões clonadas com sucesso!",
                            life: 3000,
                        });
                        props.esconderClonar();
                    } else {
                        toastRef.current.show({
                            severity: "error",
                            summary: "Erro",
                            detail: "Desculpe, não conseguimos processar a sua requisição.",
                            life: 1500,
                        });
                    }
                } else {
                    toastRef.current.show({
                        severity: "warn",
                        summary: "Sem permissões",
                        detail: "O usuário não possui permissões vinculadas.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 1500,
            });
            hideLoading();
        }
    }

    const listarTabelas = useCallback(async () => {
        const resposta = await axiosGet("/pessoas/usuario?query={id, username, is_active}");
        if (resposta.status === 200) {
            let lista = [];
            resposta.data.results.forEach((usuario) => {
                if (usuario.is_active && usuario.id !== props.usuario.id) lista.push(usuario);
            });
            setListaUsuarios(lista);
        }
    }, [props.usuario?.id]);

    useEffect(() => {
        listarTabelas();
    }, [listarTabelas]);

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <form>
                    <label htmlFor="natureza_operacao">Selecione o usuário que será clonado *</label>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <Dropdown
                                id="username"
                                name="username"
                                options={listaUsuarios}
                                optionValue="id"
                                optionLabel="username"
                                filter
                                filterBy="username"
                                placeholder="Selecione um usuário"
                                value={usuarioClonado}
                                onChange={(e) => setUsuarioClonado(e.target.value)}
                            />
                        </div>
                    </div>

                    <br />
                    <div className="p-grid p-justify-center p-col-12 p-md-12">
                        <Button
                            label="Clonar permissões"
                            type="button"
                            icon="pi pi-users"
                            className="p-button-info p-mr-2"
                            disabled={!usuarioClonado}
                            onClick={() => clonarPermissoes()}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};
