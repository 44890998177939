import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { TabView, TabPanel } from "primereact/tabview";

import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import { axiosDelete } from "@/services/http";
import { ProdutoFatorConversaoPage } from "./listFatorConversao";

const url = "/produtos/unidades-medida/";

export const ProdutoUnidadeMedidaPage = () => {
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [unidadeMedida, setUnidadeMedida] = useState(null);
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const listagemRef = useRef(null);

    const confirmarDelete = (unidadeMedida) => {
        setUnidadeMedida(unidadeMedida);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const deleteParametro = async () => {
        showLoading();
        const resposta = await axiosDelete(`${url}${unidadeMedida.id}/`);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Unidade de medida deletada com sucesso!",
                life: 3000,
            });

            listagemRef.current?.buscarDados();
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
        setDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_UNIDADEMEDIDA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de unidade de medida"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/produto/produto/unidade-medida/form",
                            state: { unidadeMedida: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_UNIDADEMEDIDA_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Deletar unidade de medida"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.PRODUTO_PRODUTO_UNIDADEMEDIDA_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2 p-mb-2"
                onClick={() => history.push("/produto/produto/unidade-medida/form")}
            />
        </>
    );

    const colunasUnidade = [
        { field: "id", header: "Código", style: { width: "10%" }, filter: true },
        { field: "sigla", header: "Sigla", filter: true },
        { field: "nome", header: "Nome", filter: true },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <h5>Unidades de Medida</h5>
                    <TabView
                        className="tabview-custom"
                        // activeIndex={activeTab}
                        // onTabChange={(e) => setActiveTab(e.index)}
                    >
                        <TabPanel header="Unidade de medida" leftIcon="pi pi-fw pi-sort-numeric-up">
                            <MakoListagem
                                ref={listagemRef}
                                // titulo="Listagem de unidade de medida"
                                colunas={colunasUnidade}
                                painelEsquerdo={cabecalhoTabela}
                                urlPesquisa={url}
                                botaoExportar
                                responsiva
                                filtros={{
                                    id: { value: "", matchMode: "equals" },
                                    sigla: {
                                        operator: "and",
                                        constraints: [{ value: "", matchMode: "contains" }],
                                    },
                                    nome: {
                                        operator: "and",
                                        constraints: [{ value: "", matchMode: "icontains" }],
                                    }
                                }}
                                configTabela={{
                                    paginator: true,
                                    lazy: true,
                                }}
                            />
                        </TabPanel>
                        <TabPanel header="Fator de Conversão" leftIcon="pi pi-fw pi-refresh">
                            <ProdutoFatorConversaoPage />
                        </TabPanel>
                    </TabView>
                </div>
                <ConfirmDialog
                    visible={deleteDialog}
                    onHide={esconderDeleteDialog}
                    header="Confirmação"
                    message={
                        unidadeMedida && (
                            <span>
                                {"Deseja realmente excluir a marca "}
                                <b>{unidadeMedida.nome}</b>?
                            </span>
                        )
                    }
                    icon="pi pi-info-circle p-mr-3"
                    accept={deleteParametro}
                    acceptLabel="Sim"
                    acceptClassName="p-button-danger"
                    reject={esconderDeleteDialog}
                    rejectLabel="Não"
                />
            </div>
        </div>
    );
};
