import { LoginPage } from "@/pages/Login";
import { RecuperarSenhaPage } from "@/pages/Login/RecuperarSenha";
import { Dashboard } from "@/pages/Dashboard";
import { Error } from "@/pages/Error";
import { NotFound } from "@/pages/NaoEncontrado";
import { ConfirmarSenhaPage } from "@/pages/Login/ConfirmarSenha";

import { gestaoRoutes } from "./gestao";
import { produtosRoutes } from "./produtos";
import { comprasRoutes } from "./compras";
import { vendasRoutes } from "./vendas";
import { estoqueRoutes } from "./estoque";
import { financeiroRoutes } from "./financeiro";
import { fiscalRoutes } from "./fiscal";
import { contabilRoutes } from "./contabil";
import { planoOperacionalRoutes } from "./planoOperacional";
import { configuracoesRoutes } from "./configuacoes";
import { relatoriosRoutes } from "./relatorios";
import { outrosRoutes } from "./outros";

export const routes = [
    { path: "/login", public: true, component: LoginPage },
    { path: "/recuperar-senha", public: true, component: RecuperarSenhaPage },
    { path: "/password/reset/confirm/MTE/:token/:id", public: true, component: ConfirmarSenhaPage },
    { path: "/error", public: true, component: Error },
    { path: "/nao-encontrado", public: true, component: NotFound },
    { path: "/", component: Dashboard },
    ...gestaoRoutes,
    ...produtosRoutes,
    ...comprasRoutes,
    ...vendasRoutes,
    ...estoqueRoutes,
    ...financeiroRoutes,
    ...fiscalRoutes,
    ...contabilRoutes,
    ...planoOperacionalRoutes,
    ...configuracoesRoutes,
    ...relatoriosRoutes,
    ...outrosRoutes,
];
