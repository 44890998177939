import React, { useRef, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { MakoSelecionarEmails } from "@/components/MakoSelecionarEmails";
import { MakoInputCliente } from "@/components/MakoInputs/MakoInputCliente";
import { MakoInputPeriodo } from "@/components/MakoInputs/MakoInputPeriodo";
import { Dropdown } from "@/components/Dropdown";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import useRelatorio from "@/hooks/useRelatorio";
import useClearRefs from "@/hooks/useClearRefs";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIO_VENDAS_TABELAPRODUTOSVENDA } from "@/assets/constants/relatorios";
import { dataToStr } from "@/assets/util/datas";

export const RelatorioTabelaProdutosVendas = () => {
    const [visible, setVisible] = useState(false);
    const [emails, setEmails] = useState([]);

    const empresaSelecionada = useRef();

    useClearRefs(empresaSelecionada);

    const { solicitarRelatorio } = useRelatorio();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            periodo_start: null,
            periodo_end: null,
            empresa: null,
            cliente: null,
            estagio: null,
            orderby: "",
        },
        onSubmit: handleSubmit,
    });

    const ORDENACAO_FILTROS = [
        { value: "periodo", label: "Periodo (crescente)" },
        { value: "-periodo", label: "Periodo (decrescente)" },
    ];

    const filtrosTemplate = [
        {
            key: "empresa",
            label: "Empresa",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: "cliente",
            label: "Cliente",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: ["periodo_start", "periodo_end"],
            label: "Período emissão",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: "estagio",
            label: "Estágio",
            path: "descricao",
        },
        {
            key: "orderBy",
            label: "Ordenado por",
            optionKey: "value",
            optionLabel: "label",
            options: ORDENACAO_FILTROS,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
    ];

    async function handleSubmit(values = null) {
        try {
            const formSchema = Yup.object().shape({
                periodo_start: Yup.date()
                    .required("O campo 'periodo inicial' é obrigatório")
                    .typeError("Informe um 'periodo inicial' válido"),
                periodo_end: Yup.date()
                    .when("periodo_start", {
                        is: (val) => !!val,
                        then: Yup.date()
                            .min(
                                values.periodo_start || new Date(),
                                "O 'periodo final' não pode ser anterior a inicial"
                            )
                            .typeError("Informe um 'periodo final' válido"),
                    })
                    .required("O campo 'periodo final' é obrigatório"),
                empresa: Yup.number()
                    .required("O campo 'empresa' é obrigatório")
                    .typeError("Informe uma 'empresa' válida"),
                cliente: Yup.object()
                    .nullable()
                    .shape({
                        id: Yup.number(),
                    })
                    .typeError("Informe uma 'cliente' válida"),
                estagio: Yup.object()
                    .shape({
                        id: Yup.number(),
                    })
                    .nullable()
                    .typeError("Seleciona um 'estagio' válido."),
                orderBy: Yup.string().nullable().typeError("Seleciona uma 'ordenação' válida."),
            });
            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                empresa: dadosValidados?.empresa ? empresaSelecionada.current : null,
            });
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.cliente) {
                dadosValidados.cliente = dadosValidados.cliente.id;
            }
            let filtros = {};
            if (dadosValidados) {
                Object.keys(dadosValidados).forEach((key) => {
                    if (dadosValidados[key]) {
                        if (key === "periodo_start" || key === "periodo_end")
                            return (filtros[key] = dataToStr(dadosValidados[key], "yyyy-MM-dd"));
                        return (filtros[key] = dadosValidados[key]);
                    }
                });
            }
            solicitarRelatorio({
                chave: RELATORIO_VENDAS_TABELAPRODUTOSVENDA,
                emails,
                filtros,
                filtros_template: filtrosTemplateAplicados,
            });
            formik.resetForm();
        } catch (error) {
            console.log(error);
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const handleConfirm = (emails) => {
        fecharModal();
        if (emails && emails.length > 0) {
            handlePreSubmit(emails);
        }
    };

    const handlePreSubmit = (emails = []) => {
        setEmails(emails);
        formik.handleSubmit();
    };

    const fecharModal = () => {
        setVisible(() => false);
    };

    const limparFiltro = () => {
        formik.resetForm();
    };

    const onChangeEmpresa = (e) => {
        empresaSelecionada.current = e?.empresa;
        setFieldValue("empresa", e.id);
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <form onSubmit={formik.handleSubmit} className="card">
                    <h5>Filtro para emissão de relatório tabela produtos venda</h5>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="empresa">Empresa: *</label>
                            <MakoDropdownEmpresas
                                inputId="empresa"
                                name="empresa"
                                placeholder="Selecione uma empresa"
                                value={formik.values.empresa}
                                onChange={(e) => onChangeEmpresa(e)}
                                className={classNames({ "p-invalid": formik.errors.empresa })}
                            />
                            {formik.errors.empresa && <small className="p-error">{formik.errors.empresa}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="cliente">Cliente: *</label>
                            <MakoInputCliente
                                inputId="cliente"
                                name="cliente"
                                value={formik.values.cliente}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.cliente })}
                            />
                            {formik.errors.cliente && <small className="p-error">{formik.errors.cliente}</small>}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <MakoInputPeriodo
                            label="Periodo *"
                            nameInicio="periodo_start"
                            nameFinal="periodo_end"
                            valueInicio={formik.values.periodo_start}
                            valueFinal={formik.values.periodo_end}
                            errorInicio={formik.errors.periodo_start}
                            errorFinal={formik.errors.periodo_end}
                            onChangeInicio={formik.handleChange}
                            onChangeFinal={formik.handleChange}
                        />
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="estagio">Estagio:</label>
                            <Dropdown
                                inputId="estagio"
                                name="estagio"
                                placeholder="Selecione uma estagio"
                                filter
                                filterBy="descricao"
                                optionLabel="descricao"
                                url="/vendas/estagios-vendas/"
                                value={formik.values.estagio}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.estagio })}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="orderby">Ordenado por:</label>
                            <Dropdown
                                inputId="orderby"
                                name="orderBy"
                                placeholder="Selecione uma forma de ordenação"
                                filter
                                filterBy="label"
                                optionLabel="label"
                                optionValue="value"
                                options={ORDENACAO_FILTROS}
                                value={formik.values.orderBy}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                    <div className="p-grid p-col-12 p-md-12 p-mt-2">
                        <Button
                            label="Gerar PDF"
                            icon="pi pi-file-pdf"
                            type="submit"
                            className="p-button-info p-mr-2"
                        />
                        <Button
                            type="button"
                            onClick={() => setVisible(true)}
                            label="Enviar por email"
                            icon="pi pi-envelope"
                            className="p-button-info p-mr-2"
                        />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            onClick={limparFiltro}
                            className="p-button-warning p-mr-2"
                        />
                    </div>
                </form>
            </div>
            <Dialog
                visible={visible}
                blockScroll
                onHide={fecharModal}
                style={{ width: "60vw" }}
                header="Informar emails"
            >
                <MakoSelecionarEmails onCancel={fecharModal} onConfirm={handleConfirm} />
            </Dialog>
        </div>
    );
};
