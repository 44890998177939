import React, { useState, useRef, useCallback, useEffect } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";
import useLoading from "@/hooks/useLoading";
import useFormatCnpjCpf from "@/hooks/useFomatCNPJCPF";
import { TIPO_CHAVE_PIX_CHOICE } from "@/assets/constants/constants";
import { axiosGet, axiosPost, axiosPut } from "@/services/http";
import { validaEmail, validarCNPJ, validarCPF } from "@/assets/util/validacoes";

export const FinanceiroChavePixFormBase = ({ chavePix, modal, aposSalvar }) => {
    const [contasBancarias, setContasBancarias] = useState([]);
    const toastRef = useRef(null);
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const [formatDocumento] = useFormatCnpjCpf();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            perfil: null,
            descricao: "",
            conta_bancaria: null,
            tipo_chave: null,
            chave: "",
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                perfil: Yup.object()
                    .required("O campo 'perfil' é obrigatório.")
                    .typeError("O campo 'perfil' é obrigatório."),
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                conta_bancaria: Yup.number().nullable(),
                tipo_chave: Yup.string()
                    .required("O campo 'tipo de chave' é obrigatório.")
                    .typeError("Selecione um tipo de chave."),
                chave: Yup.string().required("O campo 'chave' é obrigatório."),
            });

            await formSchema.validate(values, { abortEarly: false });

            let chave_valida = false;
            if (values.tipo_chave === "C") {
                if (validarCNPJ(values.chave) || validarCPF(values.chave)) chave_valida = true;
            } else if (values.tipo_chave === "A" && values.chave.length === 32) chave_valida = true;
            else if (values.tipo_chave === "T" && values.chave.length >= 8 && values.chave.length <= 14)
                chave_valida = true;
            else if (values.tipo_chave === "E" && validaEmail(values.chave)) chave_valida = true;

            if (chave_valida) {
                const chavePix = {
                    ...values,
                    perfil: values.perfil.id,
                };

                if (!values.id) {
                    showLoading();
                    const resposta = await axiosPost("/financeiro/chaves-pix/", chavePix);
                    hideLoading();

                    if (resposta.status === 201) {
                        toastRef.current.show({
                            severity: "success",
                            summary: "Sucesso",
                            detail: "Chave Pix cadastrada com sucesso!",
                            life: 1500,
                        });

                        if (aposSalvar) {
                            aposSalvar();
                        }

                        if (!modal) {
                            setTimeout(() => {
                                history.push("/financeiro/cadastros/chaves-pix");
                            }, 2000);
                        }
                    } else {
                        toastRef.current.show({
                            severity: "error",
                            summary: "Erro",
                            detail: "Desculpe, não conseguimos processar a sua requisição.",
                            life: 3000,
                        });
                    }
                } else {
                    showLoading();
                    const resposta = await axiosPut(`/financeiro/chaves-pix/${values.id}/`, chavePix);
                    hideLoading();

                    if (resposta.status === 200) {
                        toastRef.current.show({
                            severity: "success",
                            summary: "Sucesso",
                            detail: "Chave Pix alterada com sucesso!",
                            life: 1500,
                        });

                        if (aposSalvar) {
                            aposSalvar();
                        }

                        if (!modal) {
                            setTimeout(() => {
                                history.push("/financeiro/cadastros/chaves-pix");
                            }, 2000);
                        }
                    } else {
                        toastRef.current.show({
                            severity: "warn",
                            summary: "Erro",
                            detail: "Desculpe, não conseguimos processar a sua requisição.",
                            life: 3000,
                        });
                    }
                }
            } else
                toastRef.current.show({
                    severity: "warn",
                    summary: "Chave Inválida",
                    detail: "A chave pix digitada é inválida, verifique o formato e tente novamente.",
                    life: 3000,
                });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const listarContasBancarias = useCallback(async () => {
        if (formik.values.perfil instanceof Object) {
            showLoading();
            const resposta = await axiosGet(
                `/financeiro/contas-bancarias/?query={id,numero_conta}&perfil=${formik.values.perfil.id}`
            );
            hideLoading();

            if (resposta.status === 200) {
                setContasBancarias(resposta.data.results);
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos listar as contas bancárias.",
                    life: 3000,
                });
            }
        } else {
            setContasBancarias([]);
        }
    }, [formik.values.perfil, showLoading, hideLoading]);

    useEffect(() => {
        listarContasBancarias();
    }, [listarContasBancarias]);

    useEffect(() => {
        if (chavePix) {
            setValues(chavePix);
        }
    }, [setValues, chavePix]);

    const autoCompletePerfilTemplate = (item) => {
        return <div>{`${item.nome} - ${item.doc}`}</div>;
    };

    const aposPesquisarPerfil = (data) => {
        let pessoas = [];
        if (data.length > 0) {
            data.forEach((pessoa) => {
                const { identificacao, tipo_pessoa, ...rest } = pessoa;

                if (tipo_pessoa === "PF" || tipo_pessoa === "PJ") {
                    pessoas.push({
                        ...rest,
                        doc: formatDocumento(identificacao),
                    });
                } else {
                    pessoas.push({
                        ...rest,
                        doc: identificacao,
                    });
                }
            });
        }
        return pessoas;
    };

    return (
        <>
            {!modal && <h3>{!formik.values.id ? "Nova chave Pix" : "Manutenção de chave Pix"}</h3>}
            <Toast ref={toastRef} />
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="perfil">Perfil *</label>
                        <MakoAutoComplete
                            id="perfil"
                            name="perfil"
                            value={formik.values.perfil}
                            onChange={formik.handleChange}
                            minCaracteresBusca={4}
                            itemTemplate={autoCompletePerfilTemplate}
                            field="nome"
                            urlSearch="/pessoas/perfis?query={id,nome,identificacao,tipo_pessoa}&ativo=true&nome__unaccent__icontains="
                            placeholder="Comece a digitar para buscar... (min 4 caracteres)"
                            aposPesquisar={(e) => aposPesquisarPerfil(e)}
                        />
                        {formik.errors.perfil && <small className="p-error">{formik.errors.perfil}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-8">
                        <label htmlFor="descricao">Descrição *</label>
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="conta-bancaria">Conta bancária</label>
                        <Dropdown
                            id="conta-bancaria"
                            name="conta_bancaria"
                            placeholder="Selecione uma conta bancária"
                            options={contasBancarias}
                            optionValue="id"
                            optionLabel="numero_conta"
                            emptyMessage="Nenhum registro encontrado"
                            value={formik.values.conta_bancaria}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="tipo-chave">Tipo de chave *</label>
                        <Dropdown
                            id="tipo-chave"
                            name="tipo_chave"
                            placeholder="Selecione um tipo"
                            options={TIPO_CHAVE_PIX_CHOICE}
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.tipo_chave}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo_chave })}
                        />
                        {formik.errors.tipo_chave && <small className="p-error">{formik.errors.tipo_chave}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="chave">Chave *</label>
                        <InputText
                            id="chave"
                            name="chave"
                            value={formik.values.chave}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.chave })}
                        />
                        {formik.errors.chave && <small className="p-error">{formik.errors.chave}</small>}
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-1 p-mt-5">
                        <Checkbox
                            id="ativo"
                            name="ativo"
                            checked={formik.values.ativo}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="ativo">Ativa?</label>
                    </div>
                </div>
                <p>* Campos obrigatórios</p>
                <div className="p-grid p-col-12 p-md-6">
                    <Button label="Gravar" icon="pi pi-save" type="submit" className="p-button-info p-mr-2" />
                    {!modal && (
                        <Button
                            label="Cancelar"
                            icon="pi pi-times"
                            type="button"
                            className="p-button-danger p-mr-2"
                            onClick={() => history.push("/financeiro/cadastros/chaves-pix")}
                        />
                    )}
                </div>
            </form>
        </>
    );
};
