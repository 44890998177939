import React, { useState, useRef, useCallback, useEffect } from "react";

import { Redirect, useHistory } from "react-router-dom";
import { FiltroAvancadoDevolucao } from "./filtroAvancado";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "@/components/Dropdown";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { Tag } from "primereact/tag";
import { MakoInputFornecedor } from "@/components/MakoInputs/MakoInputFornecedor";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { SITUACAO_VENDA_DEVOLUCAO } from "@/assets/constants/constants";
import { axiosGet, axiosPatch } from "@/services/http";

import permissoes from "@/assets/constants/permissoes";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

const BASE_URL = "/compras/devolucao-fornecedor/";

export const VendaDevolucaoFornecedorPage = () => {
    const [url, setUrl] = useState(BASE_URL);
    const [redirecionar, setRedirecionar] = useState(null);
    const [filtro, setFiltro] = useState(-1);
    const [validarDialog, setValidarDialog] = useState(false);
    const [cancelarDialog, setCancelarDialog] = useState(false);
    const [devolverDialog, setDevolverDialog] = useState(false);
    const [cadastraNotaDialog, setCadastraNotaDialog] = useState(false);
    const [devolucaoFornecedor, setDevolucaoFornecedor] = useState(null);
    const [status, setStatus] = useState(null);
    const [listaOpFiscal, setListaOpFiscal] = useState([]);
    const [operacaoFiscal, setOperacaoFiscal] = useState(null);
    const [fornecedor, setFornecedor] = useState(null);
    const [search, setSearch] = useState(null);
    const [exibirPesquisa, setExibirPesquisa] = useState(false);
    const [filtroAvancado, setFiltroAvancado] = useState(false);
    const [formatarDocumento] = useFormatCNPJCPF();
    const { showLoading, hideLoading } = useLoading();
    const { showError, showSuccess } = useToast();
    const history = useHistory();
    const listagemRef = useRef(null);
    const menu = useRef(null);

    const opcoesPesquisa = [
        { label: "Todos", value: -1 },
        { label: "Ativos", value: false },
        { label: "Cancelado", value: true },
    ];

    const filtroStatus = useCallback(
        (status) => {
            setFiltro(status.value);

            const urlFornecedor = BASE_URL + `?fornecedor=${fornecedor.id}`;

            if (status.value === -1) {
                setUrl(urlFornecedor);
            } else if (status.value) {
                setUrl(urlFornecedor + "&cancelada=True");
            } else {
                setUrl(urlFornecedor + "&cancelada=False");
            }
        },
        [fornecedor]
    );

    const editarStatus = async () => {
        showLoading();
        const resposta = await axiosPatch(`${BASE_URL}${devolucaoFornecedor.id}/`, {
            status: status,
        });
        hideLoading();

        if (resposta.status === 200) {
            showSuccess({
                summary: "Sucesso",
                detail: "Troca validada com sucesso!",
                life: 3000,
            });

            listagemRef.current?.buscarDados();
        } else {
            showError({
                summary: "Erro",
                detail: "Desculpe, não foi possível processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
        setValidarDialog(false);
    };

    const cadastraDevolucao = async () => {
        setRedirecionar({
            pathname: "/fiscal/nota-saida/emissao-nota",
            state: {
                itensVenda: devolucaoFornecedor?.itemdevolucaofornecedor_set || [],
                financeiro: devolucaoFornecedor?.parcelafornecedordevolucao_set || [],
                operacao: operacaoFiscal,
                cfop: "5202",
                devolucao_troca: devolucaoFornecedor.id,
                modelo: "55",
            },
        });
    };

    const botoesTroca = [
        {
            label: "Ações",
            items: [
                {
                    label: "Editar",
                    icon: "pi pi-pencil",
                    disabled: devolucaoFornecedor?.status !== "P",
                    command: () => {
                        history.push({
                            pathname: "/compras/entrada/devolucao-fornecedor/form",
                            state: {
                                fornecedor: devolucaoFornecedor?.fornecedor,
                                data: devolucaoFornecedor,
                            },
                        });
                    },
                },
                {
                    label: "Validar",
                    icon: "pi pi-check",
                    disabled:
                        devolucaoFornecedor?.status.id === "V" ||
                        devolucaoFornecedor?.status.id === "F" ||
                        devolucaoFornecedor?.status.id === "C",
                    command: () => {
                        setValidarDialog(true);
                        setStatus("V");
                    },
                },
                {
                    label: "Emitir devolução",
                    icon: "pi pi-sync",
                    disabled:
                        devolucaoFornecedor?.status.id === "P" ||
                        devolucaoFornecedor?.status.id === "F" ||
                        devolucaoFornecedor?.status.id === "C",
                    command: () => {
                        setDevolverDialog(true);
                    },
                },
                {
                    label: "Cancelar",
                    icon: "pi pi-times",
                    disabled: devolucaoFornecedor?.status.id === "C" || devolucaoFornecedor?.status.id === "F",
                    command: () => {
                        setCancelarDialog(true);
                        setStatus("C");
                    },
                },
            ],
        },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.COMPRAS_ENTRADA_DEVOLUCAOFORNECEDOR_EDITAR]}
                    componente={Button}
                    icon="pi pi-cog"
                    className="p-button-rounded p-button-info p-mr-2 p-mb-1"
                    model={botoesTroca}
                    disabled={rowData.status?.id === "C"}
                    onClick={(event) => {
                        menu?.current.toggle(event);
                        setDevolucaoFornecedor(rowData);
                    }}
                />
            </div>
        );
    };

    const handleSearchFornecedor = (value) => {
        if (typeof value === "string" && value.length === 0) setFornecedor(null);
        if (typeof value === "object") {
            setUrl(BASE_URL + `?cliente=${value.id}`);
            setFornecedor(value);
        }

        setSearch(value);
    };

    const listarOperacoesFiscais = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/fiscal/operacoes-fiscais/");
        hideLoading();

        if (resposta.status === 200) {
            setListaOpFiscal(resposta.data.results);
        }
    }, [showLoading, hideLoading]);

    const cabecalhoTabela = (
        <>
            <Button
                label="Filtro Avançado"
                icon="pi pi-filter"
                className="p-button-help p-mr-2"
                onClick={() => setFiltroAvancado(true)}
            />
            <Button
                label="Limpar filtros"
                icon="pi pi-trash"
                className="p-button-warning p-mr-2"
                onClick={() => {
                    setUrl(BASE_URL);
                    setFiltro(-1);
                }}
            />
            <Dropdown value={filtro} options={opcoesPesquisa} placeholder="Selecione" onChange={filtroStatus} />
        </>
    );

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { width: "8%" },
        },
        // {
        //     field: "fornecedor",
        //     header: "Fornecedor",
        //     action: ({ fornecedor }) => `${fornecedor.nome} - ${fornecedor.identificacao}`,
        // },
        {
            field: "data_devolucao",
            header: "Data Devolução",
            dateFormat: "dd/MM/yyyy",
        },
        {
            field: "valor_total_itens",
            header: "Valor Total",
            money: true,
        },
        {
            field: "status",
            header: "Status",
            align: "center",
            action: ({ status }) => {
                const _status = SITUACAO_VENDA_DEVOLUCAO.find((item) => item.value === status);
                return <Tag severity={_status.tag} value={_status.label} />;
            },
        },
        {
            field: "action",
            header: "Ações",
            align: "center",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    const fecharDialog = () => {
        setFiltroAvancado(() => false);
    };

    const onConfirmFiltro = (newUrl) => {
        setUrl(newUrl);
        fecharDialog();
    };

    const esconderValidarDialog = () => {
        setValidarDialog(false);
        setDevolucaoFornecedor(null);
    };

    const esconderCancelarDialog = () => {
        setCancelarDialog(false);
        setDevolucaoFornecedor(null);
    };

    const esconderDevolverDialog = () => {
        setDevolverDialog(false);
        setOperacaoFiscal(null);
        setDevolucaoFornecedor(null);
    };

    const esconderCadastraDialog = () => {
        setCadastraNotaDialog(false);
    };

    useEffect(() => {
        listarOperacoesFiscais();
    }, [listarOperacoesFiscais]);

    return redirecionar ? (
        <Redirect to={redirecionar} />
    ) : (
        <div className="p-grid">
            <Menu model={botoesTroca} popup ref={menu} id="popup_menu_acoes" />
            <div className="p-col-12">
                <h5>Devoluções para fornecedor</h5>
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card p-mt-4">
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-12">
                                <label>Selecione um fornecedor:</label>
                                    <div className="p-fluid p-formgrid p-grid" style={{ rowGap: "10px" }}>
                                        <div className="p-col-12 p-md-8">
                                            <MakoInputFornecedor
                                                value={search}
                                                onChange={(e) => handleSearchFornecedor(e.target.value)}
                                                placeholder="Digite o nome do fornecedor para buscar e listar suas devoluções... (min 4 caracteres)"
                                                minCaracteresBusca={1}
                                                disabled={exibirPesquisa}
                                            />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Button
                                                icon="pi pi-plus"
                                                label="Incluir devolução"
                                                type="button"
                                                className="p-button-success"
                                                disabled={!fornecedor || typeof fornecedor !== "object"}
                                                onClick={() =>
                                                    history.push({
                                                        pathname: "/compras/entrada/devolucao-fornecedor/form",
                                                        state: { fornecedor: fornecedor, type: "new" },
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="p-col-12 p-md-2 ">
                                            <Button
                                                icon="pi pi-trash"
                                                label="Limpar"
                                                type="reset"
                                                className="p-button-warning"
                                                onClick={() => {
                                                    setFornecedor(null);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {exibirPesquisa ? (
                                <div className="p-fluid p-formgrid p-grid">
                                    <div className="p-field p-col-12 p-md-5">
                                        <div className="p-inputgroup">
                                            <Button
                                                icon="pi pi-plus"
                                                label="Incluir devolução"
                                                type="button"
                                                className="p-button-success"
                                                onClick={() =>
                                                    history.push({
                                                        pathname: "/compras/entrada/devolucao-fornecedor/form",
                                                        state: {
                                                            fornecedor: fornecedor,
                                                            type: "new",
                                                        },
                                                    })
                                                }
                                            />
                                            <Button
                                                icon="pi pi-trash"
                                                label="Limpar"
                                                type="reset"
                                                className="p-button-warning p-ml-1"
                                                onClick={() => {
                                                    setExibirPesquisa(false);
                                                    setFornecedor(null);
                                                    setSearch(null);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                {fornecedor !== null && (
                    <div className="card">
                        <MakoListagem
                            titulo={`Listagem devoluções para o fornecedor : ${fornecedor.nome} - ${formatarDocumento(
                                fornecedor.identificacao
                            )}`}
                            ref={listagemRef}
                            colunas={colunas}
                            urlPesquisa={url}
                            painelEsquerdo={cabecalhoTabela}
                            responsiva
                            configTabela={{
                                paginator: true,
                                lazy: true,
                            }}
                        />
                    </div>
                )}
                <Dialog
                    header="Filtro Avançado"
                    visible={filtroAvancado}
                    breakpoints={{ "960px": "75vw" }}
                    style={{ width: "75vw", display: "block" }}
                    onHide={() => fecharDialog()}
                >
                    <FiltroAvancadoDevolucao onConfirm={onConfirmFiltro} onCancel={fecharDialog} url={url} />
                </Dialog>
                <Dialog
                    header={"Selecione uma operação fiscal"}
                    visible={devolverDialog}
                    breakpoints={{ "960px": "75vw" }}
                    style={{ width: "60w", display: "block" }}
                    onHide={() => esconderDevolverDialog()}
                >
                    <form>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-12">
                                <Dropdown
                                    id="operacao_fiscal"
                                    name="operacao_fiscal"
                                    placeholder="Selecione..."
                                    optionValue="id"
                                    optionLabel="descricao"
                                    options={listaOpFiscal}
                                    value={operacaoFiscal}
                                    onChange={(e) => setOperacaoFiscal(e.value)}
                                />
                            </div>
                        </div>
                        <div className="p-grid p-justify-center p-mt-2">
                            <Button
                                icon="pi pi-times"
                                className="p-button-danger p-mr-2"
                                type="button"
                                label="Cancelar"
                                onClick={() => esconderDevolverDialog()}
                            />
                            <Button
                                icon="pi pi-save"
                                type="button"
                                label="Cadastrar"
                                className="p-button-success"
                                disabled={operacaoFiscal ? false : true}
                                onClick={() => setCadastraNotaDialog(true)}
                            />
                        </div>
                    </form>
                </Dialog>
                <ConfirmDialog
                    visible={validarDialog}
                    onHide={esconderValidarDialog}
                    header="Confirmação"
                    message={
                        devolucaoFornecedor && (
                            <span>
                                {"Deseja realmente validar a devolução número "}
                                <b>{devolucaoFornecedor.id}</b>?
                            </span>
                        )
                    }
                    icon="pi pi-info-circle p-mr-3"
                    accept={editarStatus}
                    acceptLabel="Sim"
                    acceptClassName="p-button-success"
                    reject={esconderValidarDialog}
                    rejectLabel="Não"
                />
                <ConfirmDialog
                    visible={cancelarDialog}
                    onHide={esconderCancelarDialog}
                    header="Confirmação"
                    message={
                        devolucaoFornecedor && (
                            <span>
                                {"Deseja realmente cancelar a devolução número "}
                                <b>{devolucaoFornecedor.id}</b>?
                            </span>
                        )
                    }
                    icon="pi pi-info-circle p-mr-3"
                    accept={editarStatus}
                    acceptLabel="Sim"
                    acceptClassName="p-button-danger"
                    reject={esconderCancelarDialog}
                    rejectLabel="Não"
                />
                <ConfirmDialog
                    visible={cadastraNotaDialog}
                    onHide={esconderCadastraDialog}
                    header="Confirmação"
                    message={
                        devolucaoFornecedor && (
                            <span>
                                {"Deseja realmente cadastrar uma nota para a devolução número "}
                                <b>{devolucaoFornecedor.id}</b>?
                            </span>
                        )
                    }
                    icon="pi pi-info-circle p-mr-3"
                    accept={cadastraDevolucao}
                    acceptLabel="Sim"
                    acceptClassName="p-button-success"
                    reject={esconderCadastraDialog}
                    rejectLabel="Não"
                />
            </div>
        </div>
    );
};
