import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { Menu } from "primereact/menu";

import { PageBase } from "@/components/PageBase";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { BotaoDelete } from "@/components/BotaoDelete";
import permissoes from "@/assets/constants/permissoes";
import { TIPO_STATUS_ORDEM_COMPRA_CHOICE } from "@/assets/constants/constants";
import { TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE } from "@/assets/constants/financeiro";
import { RELATORIO_COMPRAS_ORDEMCOMPRA } from "@/assets/constants/relatorios";
import { axiosPut } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import useRelatorio from "@/hooks/useRelatorio";
import useAuth from "@/hooks/useAuth";
import useToast from "@/hooks/useToast";
import { MakoInputFornecedor } from "@/components/MakoInputs/MakoInputFornecedor";
import { MakoCalendar } from "@/components/MakoCalendar";
import { dataToStr } from "@/assets/util/datas";
import { Dropdown } from "@/components/Dropdown";
import { InputNumber } from "primereact/inputnumber";

const url = "/compras/ordens-compra/";

export const OrdemCompraPage = () => {
    const [ordemCompraSelecionada, setOrdemCompraSelecionada] = useState(null);
    const listagemRef = useRef(null);
    const menuAcoesRef = useRef(null);
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { solicitarRelatorio } = useRelatorio();
    const { verifyPermission } = useAuth();
    const { showSuccess, showWarning, showError } = useToast();

    const validarOrdemCompra = async (ordemCompra) => {
        showLoading();
        const resposta = await axiosPut(`/compras/validar-ordem-compra/${ordemCompra.id}/`);
        hideLoading();
        if (resposta.status === 200) {
            listagemRef.current?.buscarDados();
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
        }
    };

    const confirmarValidacaoOC = (value) => {
        confirmDialog({
            header: "Confirmação",
            message: (
                <p>
                    Confirma a validação da ordem de compra número <b>{value.id}</b>?
                </p>
            ),
            icon: "pi pi-exclamation-triangle",
            acceptLabel: "Validar",
            accept: () => validarOrdemCompra(value),
            rejectLabel: "Cancelar",
        });
    };

    const cancelarOrdemCompra = async (ordemCompra) => {
        showLoading();
        const { status, data } = await axiosPut(`/compras/cancelar-ordem-compra/${ordemCompra.id}/`);
        hideLoading();
        if (status === 200) {
            listagemRef.current?.buscarDados();
        } else if (status === 404 || status === 409) {
            showWarning({
                summary: "Aviso!",
                detail: data.msg,
                life: 3000,
            });
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
        }
    };

    const confirmarCancelamentoOC = (value) => {
        confirmDialog({
            header: "Confirmação",
            message: (
                <p>
                    Confirma o cancelamento da ordem de compra número <b>{value.id}</b>?
                </p>
            ),
            icon: "pi pi-exclamation-triangle",
            acceptLabel: "Confirmar",
            accept: () => cancelarOrdemCompra(value),
            rejectLabel: "Cancelar",
        });
    };

    const enviarOrdemCompraPorEmail = async (ordemCompra) => {
        showLoading();
        const { status, data } = await axiosPut(`/compras/enviar-ordem-compra-email/${ordemCompra.id}/`);
        hideLoading();
        if (status === 200) {
            showSuccess({
                summary: "Sucesso!",
                detail: data.msg,
                life: 1500,
            });
            setOrdemCompraSelecionada(null);
        } else if (status !== 500) {
            showWarning({
                summary: "Aviso!",
                detail: data.msg,
                life: 3000,
            });
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
        }
    };

    const itensAcoes = [
        {
            label: "Ações ordem de compra",
            items: [
                {
                    label: "Gerar PDF",
                    icon: "pi pi-file-pdf",
                    disabled: ordemCompraSelecionada?.status === "PE" || ordemCompraSelecionada?.status === "BA",
                    command: () =>
                        solicitarRelatorio({
                            chave: RELATORIO_COMPRAS_ORDEMCOMPRA,
                            filtros: { id: ordemCompraSelecionada.id },
                        }),
                },
                {
                    label: "Enviar PDF por email",
                    icon: "pi pi-envelope",
                    disabled: ordemCompraSelecionada?.status === "PE" || ordemCompraSelecionada?.status === "BA",
                    command: () => enviarOrdemCompraPorEmail(ordemCompraSelecionada),
                },
                {
                    label: "Validar",
                    icon: "pi pi-check",
                    disabled:
                        !verifyPermission([permissoes.COMPRAS_ORDEMCOMPRA_ORDEMCOMPRA_VALIDAR]) ||
                        ordemCompraSelecionada?.status !== "PE",
                    command: () => confirmarValidacaoOC(ordemCompraSelecionada),
                },
                {
                    label: "Cancelar",
                    icon: "pi pi-times",
                    disabled: ordemCompraSelecionada?.status === "BA",
                    command: () => confirmarCancelamentoOC(ordemCompraSelecionada),
                },
            ],
        },
    ];

    const statusBodyTemplate = (rowData) => {
        const status = TIPO_STATUS_ORDEM_COMPRA_CHOICE.find((st) => st.id === rowData.status);
        return <span>{status?.label || "N/A"}</span>;
    };

    const formaPgtoBodyTemplate = (rowData) => {
        const formaPgto = TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE.find(
            (forma) => forma.id === rowData.forma_pagamento
        );
        return <span>{formaPgto?.label || "N/A"}</span>;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.COMPRAS_ORDEMCOMPRA_ORDEMCOMPRA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    onClick={() =>
                        history.push({
                            pathname: "/compras/ordens-compra/ordens-compra/form",
                            state: { ordemCompraId: rowData.id },
                        })
                    }
                />
                <Menu model={itensAcoes} popup ref={menuAcoesRef} id="popup_menu_acoes" />
                <Button
                    icon="pi pi-cog"
                    aria-haspopup
                    aria-controls="popup_menu_acoes"
                    className="p-button-rounded p-button-info p-mr-2 p-mb-1"
                    onClick={(e) => {
                        setOrdemCompraSelecionada(rowData);
                        menuAcoesRef.current.toggle(e);
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.COMPRAS_ORDEMCOMPRA_ORDEMCOMPRA_EXCLUIR]}
                    componente={BotaoDelete}
                    url={url}
                    objetoId={rowData.id}
                    classNames="p-mb-1"
                    exigeConfirmacao
                    msgConfirmacao={<span>Deseja realmente excluir a ordem de compra?</span>}
                    msgToastErroExclusao="A ordem de compra não pode ser excluída."
                    msgErro400={'Apenas ordens de compra com o status "Pendente" podem ser excluídas.'}
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.COMPRAS_ORDEMCOMPRA_ORDEMCOMPRA_INCLUIR]}
                componente={Button}
                label="Nova"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/compras/ordens-compra/ordens-compra/form")}
            />
        </>
    );

    const fornecedorFiltroTemplate = (options) => {
        if (options.value?.id) options.filterModel.value = options.value?.id;
        return (
            <MakoInputFornecedor
                value={options.value}
                showClear
                onChange={(e) => options.filterCallback(e.value, options.index)}
                className="p-column-filter"
            />
        );
    };

    const dataFiltroTemplate = (options) => {
        return (
            <MakoCalendar
                valueCalendar={options.value}
                onChange={(e) => options.filterCallback(dataToStr(e.value, "yyyy-MM-dd"))}
                className="p-column-filter"
            />
        );
    };

    const statusFiltroTemplate = (options) => {
        return (
            <Dropdown
                options={TIPO_STATUS_ORDEM_COMPRA_CHOICE}
                value={options.value}
                placeholder="Selecione"
                optionValue="id"
                optionLabel="label"
                showClear
                onChange={(e) => options.filterCallback(e.value, options.index)}
                className="p-column-filter"
            />
        );
    };

    const formaFiltroTemplate = (options) => {
        return (
            <Dropdown
                options={TIPO_FORMAS_PAGAMENTO_RECEBIMENTO_CHOICE}
                value={options.value}
                placeholder="Selecione"
                optionValue="id"
                optionLabel="label"
                showClear
                onChange={(e) => options.filterCallback(e.value, options.index)}
                className="p-column-filter"
            />
        );
    };

    const codigoFiltroTemplate = (options) => {
        return <InputNumber value={options.value} onValueChange={(e) => options.filterCallback(e.value)} />;
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            filter: true,
            filterElement: codigoFiltroTemplate,
            style: { width: "8%" },
        },
        {
            field: "fornecedor.nome",
            header: "Fornecedor",
            filter: true,
            filterField: "fornecedor",
            filterElement: (e) => fornecedorFiltroTemplate(e),
        },
        {
            field: "data_pedido",
            header: "Data Pedido",
            style: { width: "10%" },
            dateFormat: "dd/MM/yyyy",
            filter: true,
            filterElement: (e) => dataFiltroTemplate(e),
        },
        {
            field: "status",
            header: "Status",
            style: { width: "8%" },
            action: (e) => statusBodyTemplate(e),
            filter: true,
            filterElement: (e) => statusFiltroTemplate(e),
        },
        {
            field: "forma_pagamento",
            header: "Forma de pagamento",
            style: { width: "15%" },
            action: (e) => formaPgtoBodyTemplate(e),
            filter: true,
            filterElement: (e) => formaFiltroTemplate(e),
        },
        { field: "valor_total_ordem_compra", header: "Valor Total", style: { width: "10%" }, money: true },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "14%" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Ordens de Compras"
                colunas={colunas}
                filtarPorEmpresa
                naoBuscarSemEmpresa
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={`${url}?query={id,fornecedor,data_pedido,status,valor_total_ordem_compra,forma_pagamento}`}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    fornecedor: { value: null, matchMode: "equals" },
                    data_pedido: { value: null, matchMode: "equals" },
                    status: { value: null, matchMode: "equals" },
                    forma_pagamento: { value: null, matchMode: "equals" },
                }}
            />
            <ConfirmDialog />
        </PageBase>
    );
};
