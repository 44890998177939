import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { PageBase } from "@/components/PageBase";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { Dropdown } from "@/components/Dropdown";
import { TIPO_CALCULO_CST_CHOICE, TIPO_MOVIMENTO_FISCAL_CHOICE } from "@/assets/constants/constants";
import { axiosPut, axiosPost } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import useEmpresa from "@/hooks/useEmpresa";
import { InputNumber } from "primereact";

const url = "/fiscal/cst-ipi/";
const urlvoltar = "/fiscal/cadastros/outros/ipi";

export const FiscalIpiForm = (props) => {
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const { empresaSelecionadaId } = useEmpresa();

    const formik = useFormik({
        initialValues: props.location.state?.ipi || {
            codigo: "",
            descricao: "",
            operacao: "",
            reverso: null,
            apropriavel: false,
            tipo_calculo: 2,
            minimo_enquadramento: null,
            maximo_enquadramento: null,
            ativo: true,
            empresa: empresaSelecionadaId,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                codigo: Yup.string().required("O campo 'código' é obrigatório."),
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                tipo_calculo: Yup.number().required("O campo 'tipo de cálculo' é obrigatório."),
                minimo_enquadramento: Yup.number()
                    .required("O campo 'mínimo enquadramento' é obrigatório.")
                    .max(values.maximo_enquadramento, "O valor mínimo não pode ser menor que o máximo."),
                maximo_enquadramento: Yup.number()
                    .required("O campo 'máximo enquadramento' é obrigatório.")
                    .min(values.minimo_enquadramento, "O valor máximo não pode ser menor que o mínimo."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const ipi = {
                ...values,
                operacao: values.operacao || "",
            };
            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(`${url}`, ipi);
                hideLoading();
                if (resposta.status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "IPI cadastrado com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        cancelar();
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(`${url}${values.id}/`, ipi);
                hideLoading();
                if (resposta.status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "IPI alterado com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        cancelar();
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const cancelar = () => {
        history.push(urlvoltar);
    };

    const ipiOptionTemplate = (option) => {
        return <div>{`${option.codigo} - ${option.descricao}`}</div>;
    };

    const ipiSelecionadoTemplate = (option, props) => {
        if (option) return ipiOptionTemplate(option);
        return <span>{props.placeholder}</span>;
    };

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Novo IPI" : "Manutenção de IPI"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="codigo">Código *</label>
                        <InputText
                            id="codigo"
                            name="codigo"
                            autoComplete="off"
                            autoFocus
                            value={formik.values.codigo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.codigo })}
                        />
                        {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-5">
                        <label htmlFor="descricao">Descrição *</label>
                        <InputText
                            id="descricao"
                            name="descricao"
                            autoComplete="off"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-5">
                        <label htmlFor="reverso">IPI reverso</label>
                        <Dropdown
                            id="reverso"
                            name="reverso"
                            url={`${url}?empresa=${empresaSelecionadaId}&limit=3000`}
                            optionValue="id"
                            optionLabel="descricao"
                            itemTemplate={ipiOptionTemplate}
                            valueTemplate={ipiSelecionadoTemplate}
                            filter
                            filterBy="codigo,descricao"
                            showClear
                            value={formik.values.reverso}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="operacao">Operação</label>
                        <Dropdown
                            id="operacao"
                            name="operacao"
                            options={TIPO_MOVIMENTO_FISCAL_CHOICE}
                            optionValue="id"
                            showClear={false}
                            value={formik.values.operacao}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="tipo_calculo">Tipo de cálculo</label>
                        <Dropdown
                            id="tipo_calculo"
                            name="tipo_calculo"
                            options={TIPO_CALCULO_CST_CHOICE}
                            showClear={false}
                            value={formik.values.tipo_calculo}
                            className={classNames({ "p-invalid": formik.errors.tipo_calculo })}
                            onChange={formik.handleChange}
                        />
                        {formik.errors.tipo_calculo && <small className="p-error">{formik.errors.tipo_calculo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="minimo_enquadramento">Mínimo enquadramento *</label>
                        <InputNumber
                            id="minimo_enquadramento"
                            name="minimo_enquadramento"
                            min={0}
                            onValueChange={formik.handleChange}
                            value={formik.values.minimo_enquadramento}
                            className={classNames({ "p-invalid": formik.errors.minimo_enquadramento })}
                        />
                        {formik.errors.minimo_enquadramento && (
                            <small className="p-error">{formik.errors.minimo_enquadramento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="maximo_enquadramento">Máximo enquadramento *</label>
                        <InputNumber
                            id="maximo_enquadramento"
                            name="maximo_enquadramento"
                            min={0}
                            onValueChange={formik.handleChange}
                            value={formik.values.maximo_enquadramento}
                            className={classNames({ "p-invalid": formik.errors.maximo_enquadramento })}
                        />
                        {formik.errors.maximo_enquadramento && (
                            <small className="p-error">{formik.errors.maximo_enquadramento}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field-checkbox p-col-12 p-md-2 p-mt-5">
                        <Checkbox
                            inputId="apropriavel"
                            name="apropriavel"
                            checked={formik.values.apropriavel}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="apropriavel">Apropriável?</label>
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-2 p-mt-5">
                        <Checkbox
                            inputId="ativo"
                            name="ativo"
                            checked={formik.values.ativo}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="ativo">Ativo?</label>
                    </div>
                </div>
                <CamposObrigatorios />
                <div className="p-grid p-col-12 p-md-6">
                    <Button label="Gravar" type="submit" icon="pi pi-save" className="p-button-info p-mr-2" />
                    <Button
                        label="Cancelar"
                        icon="pi pi-times"
                        type="button"
                        className="p-button-danger p-mr-2"
                        onClick={cancelar}
                    />
                </div>
            </form>
        </PageBase>
    );
};
