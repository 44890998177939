import React, { useRef, useState } from "react";
import MakoListagem from "@/components/MakoListagem";
import { Button } from "primereact/button";
import { axiosPost } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import { Toast } from "primereact/toast";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import { PageBase } from "@/components/PageBase";
import { BotaoDelete } from "@/components/BotaoDelete";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";

export const VincularEmpresaForm = (props) => {
    const [empresa, setEmpresa] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const listagemRef = useRef(null);
    const toastRef = useRef(null);

    async function handleVinculo() {
        try {
            showLoading();
            const resposta = await axiosPost("/produtos/tabela-preco-empresas/", {
                tabela: props.tabela,
                unidade_venda: empresa,
            });
            hideLoading();

            if (resposta.status === 201) {
                toastRef.current.show({
                    severity: "success",
                    summary: "Sucesso",
                    detail: "Empresa vinculada com sucesso!",
                    life: 1500,
                });
                setEmpresa(null);
                listagemRef.current?.buscarDados();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
            }
        } catch (error) {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 1500,
            });
            hideLoading();
        }
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_CADASTRO_TABELAPRECO_EDITAR]}
                    componente={BotaoDelete}
                    url={`/produtos/tabela-preco-empresas/`}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente remover a empresa <b>{rowData.unidade_venda.nome}</b>?
                        </span>
                    }
                    classNames="p-mb-1"
                    tooltip="Deletar plano de contas"
                    tooltipOptions={{ position: "left" }}
                    msgToastErroExclusao="A empresa não pode ser removida."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "18%" } },
        { field: "unidade_venda.identificacao", header: "Identificação", style: { width: "20%" } },
        { field: "unidade_venda.nome", header: "Empresa" },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "8%" },
        },
    ];

    return (
        <PageBase>
            <Toast ref={toastRef} />
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="natureza_operacao">Selecione uma empresa *</label>
                        <div className="p-inputgroup">
                            <MakoDropdownEmpresas
                                placeholder="Selecione uma empresa"
                                value={empresa}
                                onChange={(e) => setEmpresa(e.id)}
                            />
                            <Button
                                label="Adicionar"
                                icon="pi pi-plus"
                                type="button"
                                disabled={!empresa}
                                onClick={() => handleVinculo()}
                                className="p-button-success p-ml-2"
                            />
                        </div>
                    </div>
                </div>
                {props?.tabela ? (
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Empresas Vinculadas"
                        colunas={colunas}
                        urlPesquisa={`/produtos/tabela-preco-empresas?tabela=${props.tabela}`}
                        responsiva
                        configTabela={{
                            paginator: true,
                            lazy: true,
                        }}
                    />
                ) : null}
        </PageBase>
    );
};
