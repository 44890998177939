import React, { createContext, useCallback, useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import {
    criarListaProvisoriaParaPersistencia,
    montarObjetoParaPersistenciaDjango,
} from "@/assets/util/persistenciaDjango";
import { axiosPost } from "@/services/http";

import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import { dataToStr } from "@/assets/util/datas";

const DevolucaoFornecedorContext = createContext({});

export const DevolucaoFornecedorProvider = ({ children }) => {
    const [selecionados, setSelecionados] = useState([]);
    const [parcelas, setParcelas] = useState([]);
    const [vendas, setVendas] = useState([]);
    const [devolucao, setDevolucao] = useState(null);
    const [valorTotal, setValorTotal] = useState(0);
    const { showLoading, hideLoading } = useLoading();
    const { showError, showSuccess } = useToast();

    const {
        state: { type, data },
    } = useLocation();

    const updateDevolucao = useCallback(
        async (obj) => {
            if (!obj.id) {
                showLoading();
                const { status, data } = await axiosPost("/compras/devolucao-fornecedor/", obj);
                hideLoading();
                if (status === 201) {
                    setDevolucao({ ...data });
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar sua requisição.",
                        life: 3000,
                    });
                }
            }
        },
        [setDevolucao, showLoading, hideLoading, showError]
    );

    const updateSelecionados = useCallback(
        (objeto, operacao) => {
            const _data = criarListaProvisoriaParaPersistencia(selecionados, objeto, operacao);

            const _newTotal = _data.reduce((total, item) => total + item.valor_total, 0);

            if (objeto.item_venda !== null) {
                setVendas((prev) => {
                    if (vendas.length === 0) {
                        return [...prev, objeto.venda_info];
                    }

                    if (prev.find((item) => item.id_venda === objeto.venda_info.id_venda) !== "undefined") {
                        return [...prev, objeto.venda_info];
                    }

                    return prev;
                });
            }

            setValorTotal(_newTotal);
            setSelecionados(_data);
        },
        [selecionados, vendas, setSelecionados, setVendas]
    );

    const updateParcelas = useCallback(
        (objeto, operacao) => {
            const _data = criarListaProvisoriaParaPersistencia(parcelas, objeto, operacao);

            setParcelas([..._data]);
        },
        [parcelas, setParcelas]
    );

    const resetParcelas = useCallback(() => {
        setParcelas([]);
    }, [setParcelas]);

    const resetSelecionados = useCallback(() => {
        setSelecionados([]);
    }, [setSelecionados]);

    const persistirDevolucao = useCallback(async () => {
        switch (type) {
            case "new":
                let _data = {
                    ...devolucao,
                    valor_total_itens: valorTotal,
                    data_devolucao: dataToStr(devolucao.data_devolucao, "yyyy-MM-dd"),
                    fornecedor: devolucao.fornecedor.id,
                    transportador: devolucao.transportador.id,
                };

                _data.itemdevolucaofornecedor_set = selecionados.map((item) => {
                    let _item = {
                        ...item,
                        sku: item.sku.id,
                        unidade_medida: item.unidade_medida.id,
                    };

                    if (typeof _item?.venda_info !== "undefined") delete _item.venda_info;

                    return _item;
                });

                _data.parceladevolucao_set = parcelas.map((item) => {
                    let _item = {
                        ...item,
                        data_vencimento: dataToStr(item.data_vencimento, "yyyy-MM-dd"),
                        perfil_credor: item.perfil_credor.id,
                    };

                    return _item;
                });

                const data = {
                    ..._data,
                    itemdevolucaofornecedor_set: montarObjetoParaPersistenciaDjango(_data.itemdevolucaofornecedor_set),
                    parceladevolucao_set: montarObjetoParaPersistenciaDjango(_data.parceladevolucao_set),
                };

                showLoading();
                const response = await axiosPost("/compras/devolucao-fornecedor/", data);
                hideLoading();

                if (response.status === 201) {
                    showSuccess({
                        summary: "Sucesso, :)",
                        detail: "Sucesso ao cadastrar.",
                        life: 3000,
                    });

                    return true;
                } else {
                    return false;
                }

            case "edit":
                _data = {
                    ...devolucao,
                    valor_total_itens: valorTotal,
                    data_devolucao:
                        typeof devolucao.data_devolucao === "string"
                            ? devolucao.data_devolucao
                            : dataToStr(devolucao.data_devolucao, "yyyy-MM-dd"),
                    fornecedor: devolucao.fornecedor.id,
                    transportador: devolucao.transportador.id,
                };

                delete _data.nota_fiscal;

                _data.itemdevolucaofornecedor_set = selecionados.map((item) => {
                    let _item = {
                        ...item,
                        sku: _item.sku.id,
                    };

                    if (_item.id && typeof _item.id === "number") {
                        _item.unidade_medida =
                            typeof _item.unidade_medida !== "object" ? _item.unidade_medida : _item.unidade_medida.id;
                    } else {
                        _item.unidade_medida = _item.unidade_medida.id;

                        if (typeof _item?.venda_info !== "undefined") delete _item.venda_info;
                    }

                    return _item;
                });

                _data.parceladevolucao_set = parcelas.map((item) => {
                    let _item = {
                        ...item,
                        data_vencimento:
                            typeof _item.data_vencimento === "string"
                                ? _item.data_vencimento
                                : dataToStr(item.data_vencimento, "yyyy-MM-dd"),
                        perfil_credor:
                            typeof _item.perfil_credor === "object" ? item.perfil_credor.id : _item.perfil_credor,
                    };

                    return _item;
                });

                break;

            default:
                break;
        }
    }, [devolucao, valorTotal, type, selecionados, parcelas, showLoading, hideLoading, showSuccess]);

    const prepararEdicao = useCallback(() => {
        let _copy = { ...data };
        setValorTotal(_copy.valor_total_itens);
        setDevolucao(_copy);
    }, [data, setDevolucao, setValorTotal]);

    useEffect(() => {
        prepararEdicao();
    }, [prepararEdicao]);

    return (
        <DevolucaoFornecedorContext.Provider
            value={{
                vendas,
                parcelas,
                devolucao,
                valorTotal,
                selecionados,
                updateParcelas,
                updateDevolucao,
                resetParcelas,
                resetSelecionados,
                persistirDevolucao,
                updateSelecionados,
            }}
        >
            {children}
        </DevolucaoFornecedorContext.Provider>
    );
};

export default DevolucaoFornecedorContext;
