import React, { useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputNumber } from "primereact/inputnumber";
import { useFormik } from "formik";
import { axiosPost, axiosPut } from "@/services/http";

import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";

import { useHistory } from "react-router-dom";

const url = "/produtos/galerias-imagens-sku/";
const urlvoltar = "/produto/cadastros/galerias-imagens";

export const ProdutoGaleriaImgSkuForm = (props) => {
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const paramVazio = {
        id: "",
        nome: "",
        largura: 0,
        altura: 0,
    };

    const formik = useFormik({
        initialValues: props.location.state ? props.location.state.GaleriaImg : paramVazio,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo 'nome' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(`${url}`, values);
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Galeria de imagem cadastrada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(`${url}${values.id}/`, values);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Galeria de imagem alterada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <h3>
                        {!formik.values.id ? "Nova galeria de imagens sku" : "Manutenção de galeria de imagens sku"}
                    </h3>

                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="nome">Nome *</label>
                                <InputText
                                    id="nome"
                                    name="nome"
                                    value={formik.values.nome}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.nome })}
                                    autoComplete="off"
                                    autoFocus
                                />
                                {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="altura">Altura </label>
                                <InputNumber
                                    id="altura"
                                    name="altura"
                                    value={formik.values.altura}
                                    onValueChange={formik.handleChange}
                                    min={0}
                                    showButtons
                                    className={classNames({ "p-invalid": formik.errors.altura })}
                                    autoComplete="off"
                                    autoFocus
                                />
                                {formik.errors.altura && <small className="p-error">{formik.errors.altura}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="largura">Largura </label>
                                <InputNumber
                                    id="largura"
                                    name="largura"
                                    value={formik.values.largura}
                                    onValueChange={formik.handleChange}
                                    min={0}
                                    showButtons
                                    className={classNames({ "p-invalid": formik.errors.largura })}
                                    autoComplete="off"
                                    autoFocus
                                />
                                {formik.errors.largura && <small className="p-error">{formik.errors.largura}</small>}
                            </div>
                        </div>
                        <p>
                            <b>* Campos obrigatórios.</b>
                        </p>

                        <div className="p-grid p-col-12 p-md-6">
                            <Button label="Gravar" type="submit" className="p-button-info p-mr-2 p-mb-6" />
                            <Button
                                label="Cancelar"
                                type="reset"
                                className="p-button-danger p-mr-2 p-mb-6"
                                onClick={cancelar}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
