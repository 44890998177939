import React, { useCallback, useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { axiosGet, axiosPatch, axiosPost } from "@/services/http";
import { MakoTime } from "@/components/MakoTime";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import { horaToStr } from "@/assets/util/datas";
import { Checkbox } from "primereact/checkbox";
import { AutoComplete } from "primereact/autocomplete";
import { InputNumber } from "primereact/inputnumber";

const urlvoltar = "/vendas/cadastros/rota-entrega";

export const RotaEntregaForm = (props) => {
    const [filtered, setFiltered] = useState([]);
    const [loading, setLoading] = useState(false);
    const { showLoading, hideLoading } = useLoading();

    const toastRef = useRef(null);
    const history = useHistory();
    const rotaVazia = {
        descricao: "",
        hora_inicial: null,
        hora_final: null,
        valor_frete: 0,
        municipios: null,
        segunda: false,
        terca: false,
        quarta: false,
        quinta: false,
        sexta: false,
        sabado: false,
        domingo: false,
    };

    const { setFieldValue, ...formik } = useFormik({
        initialValues: props.location.state ? props.location.state.rotas : rotaVazia,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
                hora_inicial: Yup.date().required("O campo 'hora inicial' é obrigatório."),
                hora_final: Yup.date().required("O campo 'hora final' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            values.hora_inicial = horaToStr(values.hora_inicial, "HH:mm");
            values.hora_final = horaToStr(values.hora_final, "HH:mm");

            const inicio = values.hora_inicial.split(":"),
                fim = values.hora_final.split(":");

            if (parseInt(inicio[0] + inicio[1]) > parseInt(fim[0] + fim[1])) {
                toastRef.current.show({
                    severity: "warn",
                    summary: "Horários inválidos",
                    detail: "O horário inicial deve ser menor que o final!",
                    life: 4000,
                });
            } else {
                if (!values.id) {
                    let lista_municipios = [];
                    values.municipios.forEach((cidade) => {
                        lista_municipios.push(cidade.id);
                    });

                    showLoading();
                    const resposta = await axiosPost(`/vendas/rotas-entrega/`, {
                        ...values,
                        municipios: lista_municipios,
                    });
                    hideLoading();

                    if (resposta.status === 201) {
                        toastRef.current.show({
                            severity: "success",
                            summary: "Sucesso",
                            detail: "Rota cadastrada com sucesso!",
                            life: 1500,
                        });

                        setTimeout(() => {
                            formik.resetForm();
                            history.push(urlvoltar);
                        }, 2000);
                    } else {
                        toastRef.current.show({
                            severity: "error",
                            summary: "Erro",
                            detail: "Desculpe, não conseguimos processar a sua requisição.",
                            life: 1500,
                        });
                    }
                } else {
                    let lista_municipios = [];
                    values.municipios.forEach((cidade) => {
                        lista_municipios.push(cidade.id);
                    });

                    showLoading();
                    const resposta = await axiosPatch(`/vendas/rotas-entrega/${values.id}/`, {
                        ...values,
                        municipios: lista_municipios,
                    });
                    hideLoading();

                    if (resposta.status === 200) {
                        toastRef.current.show({
                            severity: "success",
                            summary: "Sucesso",
                            detail: "Rota alterada com sucesso!",
                            life: 1500,
                        });

                        setTimeout(() => {
                            formik.resetForm();
                            history.push(urlvoltar);
                        }, 2000);
                    } else {
                        toastRef.current.show({
                            severity: "error",
                            summary: "Erro",
                            detail: "Desculpe, não conseguimos processar a sua requisição.",
                            life: 1500,
                        });
                    }
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    async function pesquisaRota(event, setTotalRegistros) {
        let _filtered = [];

        if (event.query?.length >= 3) {
            setLoading(true);
            const resp = await axiosGet(`/pessoas/cidades?search=` + event.query);
            setLoading(false);

            if (resp.status === 200) {
                const { count, results } = resp.data;

                if (setTotalRegistros) {
                    setTotalRegistros(count);
                }

                if (results.length > 0) {
                    _filtered = results;
                }
            }
        }
        setFiltered(_filtered);
    }

    const autoCompleteTemplate = (cidade) => {
        cidade.label = `${cidade.nome} - ${cidade.estado.uf}`;
        return `${cidade.nome} - ${cidade.estado.uf}`;
    };

    const carregaCidades = useCallback(async () => {
        let lista_municipios = [];
        await formik.values.municipios.forEach(async (cidade) => {
            if (typeof cidade === "number") {
                const resposta = await axiosGet(`/pessoas/cidades/${cidade}/`);
                lista_municipios.push({
                    id: resposta.data.id,
                    nome: resposta.data.nome,
                    label: `${resposta.data.nome} - ${resposta.data.estado?.uf}`,
                    estado: resposta.data.estado,
                });
            }
        });
        setFieldValue("municipios", lista_municipios);
    }, [setFieldValue]);

    useEffect(() => {
        if (formik.values.id) carregaCidades();
    }, [carregaCidades, formik.values.id]);

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toastRef} />
                    <h3>{!formik.values.id ? "Nova Rota de Entrega" : "Manutenção de Rota de Entrega"}</h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-6">
                                <label htmlFor="descricao">Descrição *</label>
                                <InputText
                                    id="descricao"
                                    name="descricao"
                                    value={formik.values.descricao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.descricao })}
                                    autoComplete="off"
                                    autoFocus
                                />
                                {formik.errors.descricao && (
                                    <small className="p-error">{formik.errors.descricao}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="hora_inicial">Hora inicial *</label>
                                <MakoTime
                                    id="hora_inicial"
                                    name="hora_inicial"
                                    valueTime={formik.values.hora_inicial}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="hora_final">Hora final *</label>
                                <MakoTime
                                    id="hora_final"
                                    name="hora_final"
                                    valueTime={formik.values.hora_final}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="valor_frete">Valor frete</label>
                                <InputNumber
                                    id="valor_frete"
                                    name="valor_frete"
                                    mode="currency"
                                    currency="BRL"
                                    min={0}
                                    value={formik.values.valor_frete}
                                    onValueChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.valor_frete })}
                                />
                                {formik.errors.valor_frete && (
                                    <small className="p-error">{formik.errors.valor_frete}</small>
                                )}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field-checkbox p-col-12 p-md-12">
                                <br></br>
                                <Checkbox
                                    inputId="segunda"
                                    id="segunda"
                                    name="segunda"
                                    onChange={formik.handleChange}
                                    checked={formik.values.segunda}
                                    value={formik.values.segunda}
                                />
                                <label htmlFor="segunda">Segunda-feira</label>
                            </div>
                            <div className="p-field-checkbox p-col-12 p-md-12">
                                <br></br>
                                <Checkbox
                                    inputId="terca"
                                    id="terca"
                                    name="terca"
                                    onChange={formik.handleChange}
                                    checked={formik.values.terca}
                                    value={formik.values.terca}
                                />
                                <label htmlFor="terca">Terça-feira</label>
                            </div>
                            <div className="p-field-checkbox p-col-12 p-md-12">
                                <br></br>
                                <Checkbox
                                    inputId="quarta"
                                    id="quarta"
                                    name="quarta"
                                    onChange={formik.handleChange}
                                    checked={formik.values.quarta}
                                    value={formik.values.quarta}
                                />
                                <label htmlFor="quarta">Quarta-feira</label>
                            </div>
                            <div className="p-field-checkbox p-col-12 p-md-12">
                                <br></br>
                                <Checkbox
                                    inputId="quinta"
                                    id="quinta"
                                    name="quinta"
                                    onChange={formik.handleChange}
                                    checked={formik.values.quinta}
                                    value={formik.values.quinta}
                                />
                                <label htmlFor="quinta">Quinta-feira</label>
                            </div>
                            <div className="p-field-checkbox p-col-12 p-md-12">
                                <br></br>
                                <Checkbox
                                    inputId="sexta"
                                    id="sexta"
                                    name="sexta"
                                    onChange={formik.handleChange}
                                    checked={formik.values.sexta}
                                    value={formik.values.sexta}
                                />
                                <label htmlFor="sexta">Sexta-feira</label>
                            </div>
                            <div className="p-field-checkbox p-col-12 p-md-12">
                                <br></br>
                                <Checkbox
                                    inputId="sabado"
                                    id="sabado"
                                    name="sabado"
                                    onChange={formik.handleChange}
                                    checked={formik.values.sabado}
                                    value={formik.values.sabado}
                                />
                                <label htmlFor="sabado">Sábado</label>
                            </div>
                            <div className="p-field-checkbox p-col-12 p-md-12">
                                <br></br>
                                <Checkbox
                                    inputId="domingo"
                                    id="domingo"
                                    name="domingo"
                                    onChange={formik.handleChange}
                                    checked={formik.values.domingo}
                                    value={formik.values.domingo}
                                />
                                <label htmlFor="domingo">Domingo</label>
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="domingo">Municípios:</label>
                                <AutoComplete
                                    value={formik.values.municipios}
                                    suggestions={filtered}
                                    completeMethod={pesquisaRota}
                                    field="label"
                                    itemTemplate={autoCompleteTemplate}
                                    placeholder="Comece a digitar para buscar... (min 3 caracteres)"
                                    multiple
                                    disabled={loading}
                                    onChange={(e) => setFieldValue("municipios", e.value)}
                                    dropdownAriaLabel="Selecione um ou mais municípios"
                                    className={classNames({ "p-invalid": formik.errors.municipios })}
                                />
                                {formik.errors.municipios && (
                                    <small className="p-error">{formik.errors.municipios}</small>
                                )}
                            </div>
                        </div>
                        <p>
                            <b>* Campos obrigatórios.</b>
                        </p>
                        <div className="p-grid p-col-12 p-md-6 p-mt-4">
                            <Button label="Gravar" type="submit" className="p-button-info p-mr-2 p-mb-6" />
                            <Button
                                label="Cancelar"
                                type="reset"
                                className="p-button-danger p-mr-2 p-mb-6"
                                onClick={cancelar}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
