import React from "react";

import { useFormik } from "formik";
import * as Yup from "yup";
import classNames from "classnames";

import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";

import { MakoCalendar } from "@/components/MakoCalendar";

import useCaixaMovimento from "@/hooks/useCaixaMovimento";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

import { dataToStr, parseData } from "@/assets/util/datas";
import { axiosPatch } from "@/services/http";
import { isAfter } from "date-fns";


export const FormBloquear = ({ fecharModal }) => {
    const { caixaMov, updateCaixa } = useCaixaMovimento();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            motivo: null,
            data_bloqueio: new Date(),
            hora_bloqueio: new Date(),
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                motivo: Yup.string()
                    .required("O campo 'motivo' é obrigatório.")
                    .typeError("Informe um motivo"),
                data_bloqueio: Yup.date()
                    .required("O campo 'data bloqueio' é obrigatório")
                    .typeError("Seleciona um data de bloqueio."),
                hora_bloqueio: Yup.date()
                    .required("O campo 'hora bloqueio' é obrigatório")
                    .typeError("Seleciona uma hora de bloqueio."),
            })

            await formSchema.validate(values, { abortEarly: true });

            const _dateBloqueio = parseData(`${dataToStr(values.data_bloqueio, "yyyy-MM-dd")} ${dataToStr(values.hora_bloqueio, "HH:mm:ss")}`);
            const _dateAbertura = parseData(`${caixaMov.data_abertura} ${caixaMov.hora_abertura}`);

            if (isAfter(_dateBloqueio, _dateAbertura)) {
                const caixaMovi = {
                    ...values,
                    bloqueado: true,
                    data_bloqueio: dataToStr(values.data_bloqueio, "yyyy-MM-dd"),
                    hora_bloqueio: dataToStr(values.hora_bloqueio, "HH:mm:ss"),
                }

                showLoading();

                const resposta = await axiosPatch(`/financeiro/caixas-movimento/${caixaMov.id}/`, caixaMovi);

                hideLoading();

                if (resposta.status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Caixa bloqueado com sucesso!",
                        life: 1500,
                    });

                    updateCaixa(resposta.data);

                    formik.resetForm();
                    fecharModal();
                } else {
                    showError({
                        severity: "error",
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showError({
                    severity: "error",
                    summary: "Erro :(",
                    detail: "A data de fechamento deve ser posterior a abertura.",
                    life: 3000,
                });
            }

        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-8">
                    <label htmlFor="motivo">Motivo *</label>
                    <InputText
                        id="motivo"
                        name="motivo"
                        value={formik.values.motivo}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.motivo })}
                    />
                    {formik.errors.motivo && (
                        <small className="p-error">{formik.errors.motivo}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="data_bloqueio">Data Bloqueio *</label>
                    <MakoCalendar
                        id="data_bloqueio"
                        name="data_bloqueio"
                        value={formik.values.data_bloqueio}
                        onChange={formik.handleChange}
                        disabled
                        minDate={caixaMov.data_abertura}
                        className={classNames({ "p-invalid": formik.errors.data_bloqueio })}
                    />
                    {formik.errors.data_bloqueio && (
                        <small className="p-error">{formik.errors.data_bloqueio}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <label htmlFor="hora_bloqueio">Hora bloqueio *</label>
                    <Calendar
                        id=""
                        name=""
                        timeOnly
                        showTime
                        hourFormat="24"
                        disabled
                        value={formik.values.hora_bloqueio}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.hora_bloqueio })}
                    />
                    {formik.errors.hora_bloqueio && (
                        <small className="p-error">{formik.errors.hora_bloqueio}</small>
                    )}
                </div>
            </div>
            <div>
                <Button
                    label="Bloquear"
                    className="p-button-success p-mr-2"
                    type="submit"
                />
                <Button
                    label="Cancelar"
                    className="p-button-danger"
                    onClick={fecharModal}
                />
            </div>
        </form>
    )
}