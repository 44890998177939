import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { axiosDelete, axiosPost } from "@/services/http";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import { Menu } from "primereact/menu";
import { Dialog } from "primereact/dialog";
import { CalculoComissaoForm } from "./modals/calculoComissao";
import { ProcessamentoComissoesForm } from "./modals/processamentoComissoes";

const url = "/vendas/apuracao-comissao-venda/";

export const ApuracaoComissaoPage = () => {
    const [calcularComissao, setCalcularComissao] = useState(false);
    const [processarComissao, setProcessarComissao] = useState(false);
    const [redirecionar, setRedirecionar] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [apuracao, setApuracao] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const menuRef = useRef(null);
    const toastRef = useRef(null);
    const listagemRef = useRef(null);

    async function handleProcessar() {
        showLoading();
        const resposta = await axiosPost(`/vendas/apurar-comissoes/`, {});
        hideLoading();

        if (resposta.status === 200) {
            toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Comissões calculadas com sucesso!",
                life: 2500,
            });

            // setTimeout(() => {
            //     setConfirmDialog(false);
            // }, 2000);
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 1500,
            });
        }
    }

    const operacoes = [
        {
            label: "Cálculo comissões",
            icon: "pi pi-percentage",
            command: () => {
                setCalcularComissao(true);
            },
        },
        {
            label: "Processar comissões",
            icon: "pi pi-sync",
            command: () => {
                setProcessarComissao(true);
            },
        },
    ];

    const confirmarDelete = (apuracao) => {
        setApuracao(apuracao);
        setDeleteDialog(true);
    };

    const esconderDeleteDialog = () => {
        setDeleteDialog(false);
    };

    const deleteParametro = async () => {
        showLoading();
        const resposta = await axiosDelete(`${url}${apuracao.id}`);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Apuração de comissão deletada com sucesso!",
                life: 3000,
            });

            listagemRef.current?.buscarDados();
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
        setDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_COMISSAO_APURACAOCOMISSAO_CALCULAR]}
                    componente={Button}
                    icon="pi pi-cog"
                    className="p-button-rounded p-button-info p-mr-2 p-mb-1"
                    tooltip="Opções"
                    tooltipOptions={{ position: "left" }}
                    onClick={(e) => {
                        setApuracao(rowData);
                        menuRef.current?.toggle(e);
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_COMISSAO_APURACAOCOMISSAO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de apuração"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        setRedirecionar({
                            pathname: "/vendas/cadastros/apuracao-comissao/form",
                            state: { apuracao: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.VENDAS_COMISSAO_APURACAOCOMISSAO_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Deletar ocupação"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.VENDAS_COMISSAO_APURACAOCOMISSAO_INCLUIR]}
                componente={Button}
                className="p-button-success p-mr-2"
                icon="pi pi-plus"
                label="Novo"
                onClick={() => setRedirecionar("/vendas/cadastros/apuracao-comissao/form")}
                tooltipOptions={{ position: "left" }}
            />
        </>
    );

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { width: "10%" },
            filter: true,
        },
        {
            field: "regra_apuracao.descricao",
            header: "Regra",
            filter: true,
        },
        {
            field: "tipo_apuracao.descricao",
            header: "Tipo",
            filter: true,
        },
        {
            field: "status.descricao",
            header: "Status",
        },
        {
            field: "data_inicial",
            header: "Data inicial",
        },
        {
            field: "data_final",
            header: "Data final",
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "12%" },
        },
    ];

    return redirecionar ? (
        <Redirect to={redirecionar} />
    ) : (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <Menu model={operacoes} popup ref={menuRef} />
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Apuração de Comissões"
                        colunas={colunas}
                        painelEsquerdo={cabecalhoTabela}
                        urlPesquisa={url}
                        responsiva
                        filtros={{
                            id: { value: "", matchMode: "equals" },
                        }}
                        configTabela={{
                            paginator: true,
                            lazy: true,
                            filterDisplay: "menu",
                        }}
                    />
                    <ConfirmDialog
                        visible={deleteDialog}
                        onHide={esconderDeleteDialog}
                        header="Confirmação"
                        message={
                            apuracao && (
                                <span>
                                    {"Deseja realmente excluir a apuração "}
                                    <b>{apuracao.descricao}</b>?
                                </span>
                            )
                        }
                        icon="pi pi-info-circle p-mr-3"
                        accept={deleteParametro}
                        acceptLabel="Sim"
                        acceptClassName="p-button-danger"
                        reject={esconderDeleteDialog}
                        rejectLabel="Não"
                    />
                </div>
            </div>
            <Dialog
                header="Cálculo de Comissão"
                visible={calcularComissao}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "50vw", display: "block" }}
                onHide={() => setCalcularComissao(false)}
            >
                <CalculoComissaoForm apuracao={apuracao} dialog={setCalcularComissao} toast={toastRef} />
            </Dialog>
            {processarComissao ? (
                <ProcessamentoComissoesForm
                    apuracao={apuracao}
                    confirmacao={processarComissao}
                    setConfirmacao={setProcessarComissao}
                    toast={toastRef}
                />
            ) : null}
        </div>
    );
};
