import React, { useState, useRef, useEffect, useCallback } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { Dialog } from "primereact/dialog";
import { axiosPost, axiosPut, axiosGet } from "@/services/http";

import permissoes from "@/assets/constants/permissoes";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import Cropper from "react-easy-crop";
import getCroppedImg from "@/assets/util/imagemCrop";

const urlGaleria = "/produtos/galerias-imagens-sku/";
const urlTag = "/produtos/tags-imagens-sku/";

export const ModalFotosSku = ({ sku, exibirDialog }) => {
    const [galeriaValue, setGaleriaValue] = useState(null);
    const [galerias, setGalerias] = useState([]);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [cropSize, setCropSize] = useState({ width: 200, height: 200 });
    const [tagValue, setTagValue] = useState("");
    const [tagsImg, setTagsImg] = useState([]);
    const [imagem, setImagem] = useState(null);
    const [focoPixels, setFocoPixels] = useState(null);
    const [descricaoImg, setDescricaoImg] = useState("");

    const [exibirGaleria, setExibirGaleria] = useState(false);

    const [tagCad, setTagCad] = useState("");
    const [exibirTagImg, setExibirTagImg] = useState(false);

    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);

    const galeriaVazia = {
        nome: "",
        largura: 0,
        altura: 0,
    };

    const formik = useFormik({
        initialValues: galeriaVazia,
        onSubmit: handleSubmit,
    });

    const onCropCompleto = useCallback((croppedArea, croppedAreaPixels) => {
        setFocoPixels(croppedAreaPixels);
    }, []);

    async function uploadImagem() {
        try {
            const body = {
                descricao: descricaoImg,
                galeria: galeriaValue.id,
                tag: tagValue,
                imagem: await getCroppedImg(imagem, focoPixels),
                sku: sku,
            };

            showLoading();
            const resposta = await axiosPost(`/produtos/produto-upload-foto/`, body);
            hideLoading();

            if (resposta.status === 200) {
                toastRef.current.show({
                    severity: "success",
                    summary: "Sucesso",
                    detail: "Imagem gravada no produto com sucesso!",
                    life: 1500,
                });
                exibirDialog();
            } else if (resposta.status === 400) {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Erro ao fazer upload de imagem.",
                    life: 1500,
                });
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
            }
        } catch (error) {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 1500,
            });
            hideLoading();
        }
    }

    async function handleSubmit(values) {
        try {
            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(`${urlGaleria}`, values);
                hideLoading();

                if (resposta.status === 201 || resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Galeria de imagem sku cadastrada com sucesso!",
                        life: 1500,
                    });

                    listarGaleriaImgSku();
                    formik.resetForm();
                    esconderInserirGaleria();
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                const body = {
                    ...values,
                    sku: sku.id,
                };

                showLoading();
                const resposta = await axiosPut(`${urlGaleria}${values.id}/`, body);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Galeria de imagem sku alterada com sucesso!",
                        life: 1500,
                    });

                    listarGaleriaImgSku();
                    formik.resetForm();
                    esconderInserirGaleria();
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    async function handleTagImg() {
        try {
            if (tagCad !== "") {
                const body = {
                    tag: tagCad,
                };

                showLoading();
                const resposta = await axiosPost(`${urlTag}`, body);
                hideLoading();

                if (resposta.status === 201 || resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Galeria de imagem sku cadastrada com sucesso!",
                        life: 1500,
                    });

                    listarTagImg();
                    setTagCad("");
                    esconderInserirTag();
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                toastRef.current.show({
                    severity: "warn",
                    summary: "Erro",
                    detail: "Preencha o campo obrigatório",
                    life: 1500,
                });
            }
        } catch (error) {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 1500,
            });
            hideLoading();
        }
    }

    const galeriaSkuValue = (galeria) => {
        setGaleriaValue(galeria.value);
        if (galeria.value && galeria.value.largura > 0)
            setCropSize({ width: galeria.value.largura, height: galeria.value.altura });
        else setCropSize({ width: 200, height: 200 });
    };

    const listarGaleriaImgSku = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet(`/produtos/galerias-imagens-sku/?sku__id=${sku}&limit=1000`);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            setGalerias(resposta.data.results);
        }
    }, [showLoading, hideLoading, sku]);

    const listarTagImg = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/produtos/tags-imagens-sku/");
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            setTagsImg(resposta.data.results);
        }
    }, [showLoading, hideLoading]);

    const esconderInserirGaleria = () => {
        setExibirGaleria(false);
        formik.resetForm();
    };

    const esconderInserirTag = () => {
        setExibirTagImg(false);
        setTagCad("");
    };

    function validateForm() {
        if (tagCad !== "") return false;
        else return true;
    }

    useEffect(() => {
        listarGaleriaImgSku();
        listarTagImg();
    }, [listarGaleriaImgSku, listarTagImg]);

    function readFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener("load", () => resolve(reader.result), false);
            reader.readAsDataURL(file);
        });
    }

    const onFileChange = async (e) => {
        if (e.target.files?.length > 0) {
            const file = e.target.files[0];
            let imageDataUrl = await readFile(file);

            setImagem(imageDataUrl);
        }
    };

    return (
        <>
            <div className="p-grid">
                <Toast ref={toastRef} />
                <div className="p-col-12">
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="descricaoImg">Descrição *</label>
                            <InputText
                                id="descricaoImg"
                                name="descricaoImg"
                                placeholder="Insira uma descrição"
                                value={descricaoImg}
                                onChange={(e) => setDescricaoImg(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="tag">Tag</label>
                            <div className="p-inputgroup">
                                <Dropdown
                                    id="sku"
                                    name="sku"
                                    value={tagValue}
                                    placeholder="Selecione uma tag"
                                    optionLabel="tag"
                                    options={tagsImg}
                                    onChange={(e) => setTagValue(e.value)}
                                />
                                <MakoControleAcesso
                                    permissao={[permissoes.PRODUTO_CADASTRO_TAGSIMAGEMSKU_INCLUIR]}
                                    componente={Button}
                                    icon="pi pi-plus"
                                    className="p-button-success"
                                    onClick={() => setExibirTagImg(true)}
                                />
                            </div>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="galeria">Galeria *</label>
                            <div className="p-inputgroup">
                                <Dropdown
                                    id="galeria"
                                    name="galeria"
                                    value={galeriaValue}
                                    placeholder="Selecione uma galeria"
                                    optionLabel="nome"
                                    options={galerias}
                                    showClear
                                    onChange={galeriaSkuValue}
                                />
                                <MakoControleAcesso
                                    permissao={[permissoes.PRODUTO_CADASTRO_GALERIAIMAGEMSKU_INCLUIR]}
                                    componente={Button}
                                    icon="pi pi-plus"
                                    className="p-button-success"
                                    onClick={() => setExibirGaleria(true)}
                                />
                            </div>
                            {galeriaValue ? (
                                <label htmlFor="galeria">
                                    Dimensões da galeria:
                                    {galeriaValue
                                        ? galeriaValue.altura === 0
                                            ? " LIVRE"
                                            : galeriaValue.largura === 0
                                            ? " LIVRE"
                                            : ` ALTURA: ${galeriaValue.altura}px - LARGURA: ${galeriaValue.largura}px`
                                        : " LIVRE"}
                                </label>
                            ) : null}
                        </div>
                        <p className="p-ml-3 p-mb-3">
                            <b>* Campos obrigatórios.</b>
                        </p>
                    </div>
                    {imagem ? (
                        <div className="crop-app">
                            <div className="crop-container">
                                <Cropper
                                    image={imagem}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={4 / 3}
                                    cropSize={cropSize}
                                    onCropChange={setCrop}
                                    style={{ width: "120px" }}
                                    onCropSizeChange={setCropSize}
                                    onCropComplete={onCropCompleto}
                                    onZoomChange={setZoom}
                                />
                            </div>
                            <div className="crop-controls">
                                <div className="p-fluid p-formgrid p-grid ">
                                    <div className="p-field p-col-12 p-md-2">
                                        <label>
                                            <b>Zoom</b>
                                        </label>
                                        <input
                                            type="range"
                                            value={zoom}
                                            min={1}
                                            max={3}
                                            step={0.1}
                                            aria-labelledby="Zoom"
                                            onChange={(e) => {
                                                setZoom(e.target.value);
                                            }}
                                            className="zoom-range"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="crop-complete">
                                <Button
                                    style={{ marginRight: "2px" }}
                                    type="button"
                                    icon="pi pi-plus"
                                    label="Salvar"
                                    className="p-button-success"
                                    onClick={() => uploadImagem()}
                                />
                                <Button
                                    style={{ marginLeft: "2px" }}
                                    type="button"
                                    icon="pi pi-times"
                                    label="Voltar"
                                    className="p-button-danger"
                                    onClick={() => setImagem(null)}
                                />
                            </div>
                        </div>
                    ) : (
                        <input
                            disabled={!(descricaoImg && galeriaValue)}
                            type="file"
                            onChange={onFileChange}
                            accept="image/*"
                        />
                    )}
                </div>
            </div>

            <Dialog
                header="Cadastrar galeria de imagem"
                visible={exibirGaleria}
                style={{ width: "40vw", display: "block" }}
                onHide={() => esconderInserirGaleria()}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid ">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="nome">Nome *</label>
                            <InputText
                                id="nome"
                                name="nome"
                                value={formik.values.nome}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.nome })}
                                autoComplete="off"
                                autoFocus
                            />
                            {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="altura">Altura </label>
                            <InputNumber
                                id="altura"
                                name="altura"
                                value={formik.values.altura}
                                onValueChange={formik.handleChange}
                                min={0}
                                showButtons
                                className={classNames({ "p-invalid": formik.errors.altura })}
                                autoComplete="off"
                                autoFocus
                            />
                            {formik.errors.altura && <small className="p-error">{formik.errors.altura}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="largura">Largura </label>
                            <InputNumber
                                id="largura"
                                name="largura"
                                value={formik.values.largura}
                                onValueChange={formik.handleChange}
                                min={0}
                                showButtons
                                className={classNames({ "p-invalid": formik.errors.largura })}
                                autoComplete="off"
                                autoFocus
                            />
                            {formik.errors.largura && <small className="p-error">{formik.errors.largura}</small>}
                        </div>
                    </div>

                    <p>
                        <b>* Campos obrigatórios.</b>
                    </p>
                    <br />
                    <div className="p-grid p-justify-end" style={{ paddingRight: "2%" }}>
                        <Button type="submit" icon="pi pi-check-circle" label="Salvar" />
                    </div>
                </form>
            </Dialog>
            <Dialog
                header="Cadastrar tag"
                visible={exibirTagImg}
                style={{ width: "20vw", display: "block" }}
                onHide={() => esconderInserirTag()}
            >
                <form>
                    <div className="p-fluid p-formgrid p-grid ">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="tag">Tag *</label>
                            <InputText
                                id="tag"
                                name="tag"
                                value={tagCad}
                                onChange={(e) => setTagCad(e.target.value)}
                                disabled={!validateForm}
                                autoComplete="off"
                                autoFocus
                            />
                        </div>
                    </div>

                    <p>
                        <b>* Campos obrigatórios.</b>
                    </p>
                    <br />
                    <div className="p-grid p-justify-end" style={{ paddingRight: "2%" }}>
                        <Button
                            type="button"
                            icon="pi pi-check-circle"
                            label="Inserir"
                            onClick={() => handleTagImg()}
                        />
                    </div>
                </form>
            </Dialog>
        </>
    );
};
