import React from "react";
import { useHistory } from "react-router-dom";

import { CentroEstocagemFormBase } from "./formBase";
import { PageBase } from "@/components/PageBase";

export const CentroEstocagemForm = (props) => {
    const history = useHistory();

    const centroEstocagem = props.location.state;

    return (
        <PageBase>
            <h3>{!centroEstocagem?.id ? "Novo centro de estocagem" : "Manutenção de centro de estocagem"}</h3>
            <CentroEstocagemFormBase
                centroEstocagem={centroEstocagem}
                estoqueId={props.location.estoqueId}
                onCancelar={() => history.push("/estoque/cadastros/centro-estocagem")}
            />
        </PageBase>
    );
};
