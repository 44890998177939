import React, { useMemo, useRef, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import { MakoInputFornecedor } from "@/components/MakoInputs/MakoInputFornecedor";
import { MakoInputPeriodo } from "@/components/MakoInputs/MakoInputPeriodo";
import { MakoSelecionarEmails } from "@/components/MakoSelecionarEmails";
import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";
import { MakoInputNcm } from "@/components/MakoInputs/MakoInputNcm";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import useRelatorio from "@/hooks/useRelatorio";
import useClearRefs from "@/hooks/useClearRefs";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIO_PRODUTOS_INVENTARIOPRODUTO } from "@/assets/constants/relatorios";
import { dataToStr } from "@/assets/util/datas";

export const RelatorioInventarioProdutos = () => {
    const [visible, setVisible] = useState(false);
    const [emails, setEmails] = useState([]);

    const { solicitarRelatorio } = useRelatorio();

    const categoriaRef = useRef();
    useClearRefs(categoriaRef);

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            marca: null,
            data: null,
            saldo_fisico_start: null,
            saldo_fisico_end: null,
            saldo_logico_start: null,
            saldo_logico_end: null,
            categoria: null,
            fornecedor: null,
            lote: null,
            descricao: "",
            destinacao: null,
            ncm: null,
        },
        onSubmit: handleSubmit,
    });

    const filtrosTemplate = [
        {
            key: "marca",
            label: "Marca",
            path: "nome",
        },
        {
            key: "data",
            label: "Data de referência",
            type: TIPOS_FILTROS_TEMPLATE.DATE,
        },
        {
            key: ["saldo_fisico_start", "saldo_fisico_end"],
            label: "Saldo lógico entre",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: ["saldo_logico_start", "saldo_logico_end"],
            label: "Saldo físico entre",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: "categoria",
            label: "Categoria",
            path: "descricao",
        },
        {
            key: "fornecedor",
            label: "Fornecedor",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: "lote",
            label: "Lote",
            path: "motivo_inventario.descricao",
        },
        {
            key: "descricao",
            label: "Descrição",
        },
        {
            key: "destinacao",
            label: "Destinação",
            path: "nome",
        },
        {
            key: "ncm",
            label: "Ncm",
            path: "codigo",
        },
    ];

    async function handleSubmit(values = null) {
        try {
            const formSchema = Yup.object().shape({
                marca: Yup.object().nullable().typeError("Informe uma 'marca' válida."),
                categoria: Yup.number().nullable().typeError("Informe uma 'categoria' válido."),
                fornecedor: Yup.object().nullable().typeError("Informe um 'fornecedor' válida."),
                destinacao: Yup.object().nullable().nullable(),
                lote: Yup.object().nullable().typeError("Seleciona um 'lote' válido."),
                data: Yup.date()
                    .when({
                        is: () => requerRefencia,
                        then: Yup.date().nullable().required("O campo 'data referência' é obrigatório"),
                        otherwise: Yup.date().nullable(),
                    })
                    .typeError("Seleciona um 'data referência' válido."),
                saldo_fisico_start: Yup.date().nullable().typeError("Selecione um 'inicio de saldo fisico' válido."),
                saldo_fisico_end: Yup.date()
                    .when("saldo_fisico_start", {
                        is: (val) => !!val,
                        then: Yup.date()
                            .nullable()
                            .required("O campo 'saldo fisico final' é obrigatório.")
                            .min(
                                values.saldo_fisico_start || new Date(),
                                "O 'saldo fisico final' não pode ser anterior ao inicial"
                            ),
                        otherwise: Yup.date().nullable(),
                    })
                    .typeError("Seleciona um 'saldo fisico final' válido."),
                saldo_logico_start: Yup.date().nullable().typeError("Selecione uma 'inicio de saldo logico' válido."),
                saldo_logico_end: Yup.date()
                    .when("saldo_logico_start", {
                        is: (val) => !!val,
                        then: Yup.date()
                            .min(
                                values.saldo_logico_start || new Date(),
                                "O 'saldo logico final' não pode ser anterior ao inicial"
                            )
                            .nullable()
                            .required("O campo 'logico fisico final' é obrigatório."),
                        otherwise: Yup.date().nullable(),
                    })
                    .typeError("Seleciona um 'saldo logico final' válido."),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                categoria: dadosValidados.categoria ? categoriaRef.current : null,
            });
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.ncm) dadosValidados.ncm = dadosValidados.ncm.id;
            if (dadosValidados.fornecedor) dadosValidados.fornecedor = dadosValidados.fornecedor.id;
            if (dadosValidados.marca) dadosValidados.marca = dadosValidados.marca.id;
            if (dadosValidados.lote) dadosValidados.lote = dadosValidados.lote.id;
            if (dadosValidados.destinacao) dadosValidados.destinacao = dadosValidados.destinacao.id;
            if (dadosValidados.data) dadosValidados.data = dataToStr(dadosValidados.data, "yyyy-MM-dd");
            if (dadosValidados.saldo_fisico_start)
                dadosValidados.saldo_fisico_start = dataToStr(dadosValidados.saldo_fisico_start, "yyyy-MM-dd");
            if (dadosValidados.saldo_fisico_end)
                dadosValidados.saldo_fisico_end = dataToStr(dadosValidados.saldo_fisico_end, "yyyy-MM-dd");
            if (dadosValidados.saldo_logico_start)
                dadosValidados.saldo_logico_start = dataToStr(dadosValidados.saldo_logico_start, "yyyy-MM-dd");
            if (dadosValidados.saldo_logico_end)
                dadosValidados.saldo_logico_end = dataToStr(dadosValidados.saldo_logico_end, "yyyy-MM-dd");

            let filtros = {};
            if (dadosValidados) {
                Object.keys(dadosValidados).forEach((key) => {
                    if (dadosValidados[key]) filtros[key] = dadosValidados[key];
                });
            }

            solicitarRelatorio({
                chave: RELATORIO_PRODUTOS_INVENTARIOPRODUTO,
                emails,
                filtros,
                filtros_template: filtrosTemplateAplicados,
            });
            formik.resetForm();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const handleConfirm = (emails) => {
        fecharModal();
        if (emails && emails.length > 0) {
            handlePreSubmit(emails);
        }
    };

    const handlePreSubmit = (emails = []) => {
        setEmails(emails);
        formik.handleSubmit();
    };

    const fecharModal = () => {
        setVisible(() => false);
    };

    const limparFiltro = () => {
        formik.resetForm();
    };

    const onChangeData = (e) => {
        setFieldValue("saldo_fisico_start", null);
        setFieldValue("saldo_fisico_end", null);
        setFieldValue("saldo_logico_start", null);
        setFieldValue("saldo_logico_end", null);
        formik.handleChange(e);
    };

    const onChangePeriodo = (e) => {
        setFieldValue("data", null);
        formik.handleChange(e);
    };

    const selecionarCategoria = (e) => {
        categoriaRef.current = e;
    };

    const requerRefencia = useMemo(() => {
        const { saldo_fisico_start, saldo_fisico_end, saldo_logico_start, saldo_logico_end } = formik.values;
        if (!!saldo_fisico_start || !!saldo_fisico_end || !!saldo_logico_start || !!saldo_logico_end) return false;
        return true;
    }, [formik.values]);

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <h5>Filtro para emissão de relatório de inventário de produtos</h5>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="marca">Marca</label>
                            <MakoAutoComplete
                                id="marca"
                                name="marca"
                                placeholder="Digite para pesquisar... (min 4 caracteres)"
                                minCaracteresBusca={4}
                                key="id"
                                field="nome"
                                urlSearch="/produtos/marcas/?search="
                                value={formik.values.marca}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.marca })}
                            />
                            {formik.errors.marca && <small className="p-error">{formik.errors.marca}</small>}
                        </div>
                        <MakoInputPeriodo
                            label="Saldo lógico entre"
                            nameInicio="saldo_logico_start"
                            nameFinal="saldo_logico_end"
                            valueInicio={formik.values.saldo_logico_start}
                            valueFinal={formik.values.saldo_logico_end}
                            errorInicio={formik.errors.saldo_logico_start}
                            errorFinal={formik.errors.saldo_logico_end}
                            onChangeInicio={onChangePeriodo}
                            onChangeFinal={onChangePeriodo}
                        />
                        <MakoInputPeriodo
                            label="Saldo físico entre"
                            nameInicio="saldo_fisico_start"
                            nameFinal="saldo_fisico_end"
                            valueInicio={formik.values.saldo_fisico_start}
                            valueFinal={formik.values.saldo_fisico_end}
                            errorInicio={formik.errors.saldo_fisico_start}
                            errorFinal={formik.errors.saldo_fisico_end}
                            onChangeInicio={formik.handleChange}
                            onChangeFinal={formik.handleChange}
                            disabled={requerRefencia}
                        />
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-2">
                            <Label htmlFor="data" label="Data de referência" obrigatorio={requerRefencia} />
                            <MakoCalendar
                                id="data"
                                name="data"
                                valueCalendar={formik.values.data}
                                onChange={onChangeData}
                                className={classNames({ "p-invalid": formik.errors.data })}
                                disabled={!requerRefencia}
                            />
                            {formik.errors.data && <small className="p-error">{formik.errors.data}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="destinacao">Destinação</label>
                            <Dropdown
                                id="destinacao"
                                name="destinacao"
                                url="/compras/destinacoes-entradas/"
                                value={formik.values.destinacao}
                                onChange={formik.handleChange}
                                filter
                                filterBy="nome"
                                optionLabel="nome"
                                placeholder="Selecione um tipo..."
                                className={classNames({ "p-invalid": formik.errors.destinacao })}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="categoria">Categoria</label>
                            <MakoDropdownCategoriasHierarquicas
                                id="categoria"
                                name="categoria"
                                categoriaTituloSelecionavel
                                getCategoriaCompleta={selecionarCategoria}
                                value={formik.values.categoria}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.categoria })}
                            />
                            {formik.errors.categoria && <small className="p-error">{formik.errors.categoria}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="lote">Lote</label>
                            <Dropdown
                                id="lote"
                                name="lote"
                                url="/produtos/lotes-inventario/"
                                value={formik.values.lote}
                                onChange={formik.handleChange}
                                optionLabel="motivo_inventario.descricao"
                                placeholder="Selecione um lote..."
                                className={classNames({ "p-invalid": formik.errors.lote })}
                            />
                            {formik.errors.lote && <small className="p-error">{formik.errors.lote}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="fornecedor">Fornecedor</label>
                            <MakoInputFornecedor
                                id="fornecedor"
                                name="fornecedor"
                                value={formik.values.fornecedor}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.fornecedor })}
                            />
                            {formik.errors.fornecedor && <small className="p-error">{formik.errors.fornecedor}</small>}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="ncm">NCM</label>
                            <MakoInputNcm
                                id="ncm"
                                name="ncm"
                                fieldValue={setFieldValue}
                                valorNcm={formik.values.ncm}
                                className={classNames({ "p-invalid": formik.errors.ncm })}
                            />
                            {formik.errors.ncm && <small className="p-error">{formik.errors.ncm}</small>}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="descricao">Descrição contém</label>
                            <InputTextarea
                                id="descricao"
                                name="descricao"
                                value={formik.values.descricao}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.descricao })}
                                autoResize
                                rows={3}
                                maxLength={100}
                            />
                            {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                        </div>
                    </div>
                    <div className="p-grid p-col-12 p-md-6 p-mt-2">
                        <Button
                            label="Gerar PDF"
                            icon="pi pi-file-pdf"
                            type="button"
                            onClick={() => handlePreSubmit()}
                            className="p-button-info p-mr-2"
                        />
                        <Button
                            type="button"
                            onClick={() => setVisible(true)}
                            label="Enviar por email"
                            icon="pi pi-envelope"
                            className="p-button-info p-mr-2"
                        />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            onClick={() => limparFiltro()}
                            className="p-button-warning p-mr-2"
                        />
                    </div>
                </div>
            </div>
            <Dialog
                visible={visible}
                blockScroll
                onHide={fecharModal}
                style={{ width: "60vw" }}
                header="Informar emails"
            >
                <MakoSelecionarEmails onCancel={fecharModal} onConfirm={handleConfirm} />
            </Dialog>
        </div>
    );
};
