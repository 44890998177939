import React, { useCallback, useEffect, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";

import { Dropdown } from "@/components/Dropdown";

import { axiosGet, axiosPost, axiosPut } from "@/services/http";

import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

export const CaixaForm = () => {
    const [caixas, setCaixas] = useState([]);
    const [contas, setContas] = useState([]);

    const { showLoading, hideLoading } = useLoading();
    const { showError, showSuccess } = useToast();
    const { state } = useLocation();

    const history = useHistory();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            nome: "",
            conta_financeira: null,
            master: null,
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string()
                    .required("O campo 'nome' é obrigatório.")
                    .max(20, "Esse campo não pode ser maior que 20 caracteres."),
                master: Yup.number().nullable().typeError("Informe um 'caixa master' válido."),
                conta_financeira: Yup.number()
                    .required("O campo 'conta financeira' é obrigatório.")
                    .typeError("Informe uma 'conta financeira' válida."),
            });

            await formSchema.validate(values, { abortEarly: false });

            if (!values.id) {
                showLoading();
                const { status, data } = await axiosPost("/financeiro/caixas/", values);
                hideLoading();

                if (status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Caixa cadastrado com sucesso!",
                        life: 1500,
                    });

                    history.push("/financeiro/cadastros/caixas");
                } else {
                    if (data && data?.includes("duplicate key value violates unique constraint")) {
                        showError({
                            summary: "Erro :(",
                            detail: "Está conta financeira já possui um caixa associado.",
                            life: 3000,
                        });
                    } else {
                        showError({
                            summary: "Erro :(",
                            detail: "Verifique se está conta financeira está associada a um caixa.",
                            life: 3000,
                        });
                    }
                }
            } else {
                showLoading();
                const { status } = await axiosPut(`/financeiro/caixas/${values.id}/`, values);
                hideLoading();

                if (status === 200) {
                    showSuccess({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Caixa alterado com sucesso!",
                        life: 1500,
                    });

                    history.push("/financeiro/cadastros/caixas");
                } else {
                    showError({
                        severity: "error",
                        summary: "Erro :(",
                        detail: "Desculpe, não foi possível processar a sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const fetchCaixas = useCallback(async () => {
        showLoading();
        const response = await axiosGet(`/financeiro/caixas/`);
        hideLoading();

        if (response.status === 200) {
            const _data = response.data.results.filter((cx) => cx.ativo && cx.master === null);
            setCaixas(_data);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível listar os caixas.",
                life: 3000,
            });
        }
    }, [showLoading, hideLoading, setCaixas, showError]);

    const fetchContas = useCallback(async () => {
        showLoading();
        const response = await axiosGet(`/financeiro/contas-financeiras/?ativa-True&tipo_conta=1`);
        hideLoading();

        if (response.status === 200) {
            const _data = response.data.results.map((c) => {
                c.label = `${c.codigo} - ${c.descricao}`;

                return c;
            });

            setContas(_data);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível listar as contas.",
                life: 3000,
            });
        }
    }, [showLoading, hideLoading, setContas, showError]);

    useEffect(() => {
        if (state && state.caixa) {
            setValues({
                id: state.caixa.id,
                nome: state.caixa.nome,
                conta_financeira: state.caixa.conta_financeira.id,
                master: state.caixa.master,
                ativo: state.caixa.ativo,
            });
        }
    }, [state, setValues]);

    useEffect(() => {
        fetchCaixas();
        fetchContas();
    }, [fetchCaixas, fetchContas]);

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <h5>{formik.values.id ? "Editar caixa" : "Cadastrar caixa"}</h5>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="nome">Nome: *</label>
                                <InputText
                                    id="nome"
                                    name="nome"
                                    autoComplete="off"
                                    value={formik.values.nome}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.nome })}
                                />
                                {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="conta_financeira">Conta financeira: *</label>
                                <Dropdown
                                    id="conta_financeira"
                                    name="conta_financeira"
                                    options={contas}
                                    placeholder="Selecione"
                                    optionValue="id"
                                    optionLabel="label"
                                    value={formik.values.conta_financeira}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.conta_financeira })}
                                />
                                {formik.errors.conta_financeira && (
                                    <small className="p-error">{formik.errors.conta_financeira}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="master">Caixa master:</label>
                                <Dropdown
                                    id="master"
                                    name="master"
                                    options={caixas}
                                    placeholder="Selecione"
                                    optionValue="id"
                                    optionLabel="nome"
                                    value={formik.values.master}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.master })}
                                />
                                {formik.errors.master && <small className="p-error">{formik.errors.master}</small>}
                            </div>
                            <div className="p-field-checkbox p-col-12 p-md-2 p-mt-2">
                                <Checkbox
                                    inputId="ativo"
                                    name="ativo"
                                    checked={formik.values.ativo}
                                    onChange={formik.handleChange}
                                />
                                <label htmlFor="ativo">Ativo?</label>
                            </div>
                        </div>
                        <p>
                            <b>* Campos obrigatórios.</b>
                        </p>
                        <div className="p-grid p-col-12 p-md-6">
                            <Button icon="pi pi-save" label="Gravar" type="submit" className="p-button-info p-mr-2" />
                            <Button
                                icon="pi pi-trash"
                                label="Limpar"
                                type="button"
                                className="p-button-warning p-mr-2"
                                onClick={() => formik.resetForm()}
                            />
                            <Button
                                label="Cancelar"
                                type="button"
                                className="p-button-danger"
                                onClick={() => history.push("/financeiro/cadastros/caixas")}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
