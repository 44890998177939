import React, { useCallback, useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";
import { useFormik } from "formik";
import { Dropdown } from "@/components/Dropdown";
import { InputNumber } from "primereact/inputnumber";
import { axiosGet } from "@/services/http";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { MakoInputQuantidadeSku } from "@/components/MakoInputs/MakoInputQuantidadeSku";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";

import * as Yup from "yup";
import classNames from "classnames";
import useTroca from "@/hooks/useTroca";
import useLoading from "@/hooks/useLoading";

export const ItemAvulsoModalForm = (props) => {
    const [listaMotivos, setListaMotivos] = useState([]);
    const [listaEstoques, setListaEstoques] = useState([]);
    const [unidadesVenda, setUnidadesVenda] = useState([]);
    const { showLoading, hideLoading } = useLoading();
    const { handleItemTrocaDevolucao } = useTroca();
    const toastRef = useRef(null);

    const { setFieldValue, setValues, resetForm, ...formik } = useFormik({
        initialValues: {
            status: "novo",
            descricao_reduzida: "",
            sku: null,
            unidade_medida: null,
            item_venda: null,
            quantidade: 1,
            cubagem: 1,
            valor_unitario: null,
            subtotal: 0,
            encargos: 0,
            motivo_devolucao: null,
            justificativa: "",
            retorno_estoque: true,
            defeito: false,
            estoque_retorno: null,
            sku_movimenta_estoque: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                unidade_medida: Yup.object().required("O campo 'unidade de medida' é obrigatório."),
                quantidade: Yup.number().min(0).required("O campo 'quantidade' é obrigatório."),
                valor_unitario: Yup.number().required("O campo 'valor unitário' é obrigatório."),
                subtotal: Yup.number().required("O campo 'subtotal' é obrigatório."),
                encargos: Yup.number().required("O campo 'encargos' é obrigatório."),
                motivo_devolucao: Yup.number().required("O campo 'motivo de devolução' é obrigatório."),
                justificativa: Yup.string().max(100),
                sku_movimenta_estoque: Yup.object().required("O campo 'sku que movimenta o estoque' é obrigatório."),
                sku: Yup.object().required("O campo 'item' é obrigatório."),
            });

            values.descricao_reduzida = values.sku.descricao_reduzida;
            if (!values.cubagem) values.cubagem = 1;

            await formSchema.validate(values, {
                abortEarly: false,
            });

            handleItemTrocaDevolucao(values.status, {
                ...values,
                estoque_retorno: values.retorno_estoque ? values.estoque_retorno : null,
            });

            resetForm();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const autoCompleteTemplate = (item) => {
        item.label = `${item.codigo} - ${item.descricao_reduzida}`;
        return `${item.codigo} - ${item.descricao_reduzida}`;
    };

    const listarMotivos = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/vendas/motivo-devolucao?limit=10000");
        hideLoading();

        if (resposta.status === 200) setListaMotivos(resposta.data.results);
    }, [showLoading, hideLoading]);

    const listarEstoques = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/produtos/centros-estocagem?limit=10000");
        hideLoading();

        if (resposta.status === 200) setListaEstoques(resposta.data.results);
    }, [showLoading, hideLoading]);

    const listarUnidades = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/produtos/unidades-medida?limit=10000");
        hideLoading();

        if (resposta.status === 200) setUnidadesVenda(resposta.data.results);
    }, [showLoading, hideLoading]);

    useEffect(() => {
        listarEstoques();
        listarMotivos();
        listarUnidades();
    }, [listarMotivos, listarEstoques, listarUnidades]);

    useEffect(() => {
        setFieldValue(
            "subtotal",
            formik.values.valor_unitario * formik.values.quantidade * formik.values.cubagem ||
                1 + formik.values.encargos - formik.values.desconto
        );
    }, [
        setFieldValue,
        formik.values.valor_unitario,
        formik.values.quantidade,
        formik.values.encargos,
        formik.values.desconto,
        formik.values.cubagem,
    ]);
    return (
        <div className="p-grid">
            <div className="p-col-12">
                <Toast ref={toastRef} />
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="sku">Item *</label>
                            <MakoBuscaSkuPersonalizada
                                id="sku"
                                name="sku"
                                placeholder="Digite para pesquisar..."
                                skuValue={formik.values.sku}
                                skuChange={(e) => {
                                    setFieldValue("sku", e);
                                    setFieldValue("sku_movimenta_estoque", {
                                        ...e.sku_controle_saldo,
                                        label:
                                            typeof e.sku_controle_saldo === "object"
                                                ? `${e.sku_controle_saldo.codigo} - ${e.sku_controle_saldo.descricao_complementar}`
                                                : null,
                                    });
                                }}
                                skuError={formik.errors.sku}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="unidade_medida">Unidade de medida *</label>
                            <Dropdown
                                id="unidade_medida"
                                name="unidade_medida"
                                options={unidadesVenda}
                                optionLabel="nome"
                                emptyMessage="Nenhuma unidade disponível."
                                value={formik.values.unidade_medida}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.unidade_medida })}
                            />
                            {formik.errors.unidade_medida && (
                                <small className="p-error">{formik.errors.unidade_medida}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="valor_unitario">Valor Unitário *</label>
                            <MakoInputMoeda
                                id="valor_unitario"
                                name="valor_unitario"
                                permiteFracionario
                                value={formik.values.valor_unitario}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.valor_unitario })}
                            />
                            {formik.errors.valor_unitario && (
                                <small className="p-error">{formik.errors.valor_unitario}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="sku_movimenta_estoque">Item que movimenta estoque *</label>
                            <MakoAutoComplete
                                id="sku_movimenta_estoque"
                                name="sku_movimenta_estoque"
                                value={formik.values.sku_movimenta_estoque}
                                onChange={formik.handleChange}
                                minCaracteresBusca={4}
                                itemTemplate={autoCompleteTemplate}
                                field="label"
                                urlSearch={`/produtos/sku?search=`}
                                placeholder="Comece a digitar o código ou descrição do item para buscar... (min 4 caracteres)"
                                className={classNames({ "p-invalid": formik.errors.sku_movimenta_estoque })}
                            />
                            {formik.errors.sku_movimenta_estoque && (
                                <small className="p-error">{formik.errors.sku_movimenta_estoque}</small>
                            )}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="quantidade">Quantidade * </label>
                            <MakoInputQuantidadeSku
                                id="quantidade"
                                name="quantidade"
                                permiteFracionario
                                showButtons
                                value={formik.values.quantidade}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.quantidade })}
                            />
                            {formik.errors.quantidade && <small className="p-error">{formik.errors.quantidade}</small>}
                        </div>

                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="cubagem">Cubagem</label>
                            <InputNumber
                                id="cubagem"
                                name="cubagem"
                                mode="decimal"
                                disabled
                                minFractionDigits={0}
                                maxFractionDigits={4}
                                value={formik.values.cubagem}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.cubagem })}
                            />
                            {formik.errors.cubagem && <small className="p-error">{formik.errors.cubagem}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="encargos">Encargos *</label>
                            <MakoInputMoeda
                                id="encargos"
                                name="encargos"
                                permiteFracionario
                                value={formik.values.encargos}
                                onValueChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.encargos })}
                            />
                            {formik.errors.encargos && <small className="p-error">{formik.errors.encargos}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="subtotal">Subtotal *</label>
                            <MakoInputMoeda
                                id="subtotal"
                                name="subtotal"
                                permiteFracionario
                                value={formik.values.subtotal}
                                onValueChange={formik.handleChange}
                                disabled
                                className={classNames({ "p-invalid": formik.errors.subtotal })}
                            />
                            {formik.errors.subtotal && <small className="p-error">{formik.errors.subtotal}</small>}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field-checkbox p-col-12 p-md-2">
                            <br></br>
                            <Checkbox
                                inputId="defeito"
                                id="defeito"
                                name="defeito"
                                onChange={formik.handleChange}
                                checked={formik.values.defeito}
                                value={formik.values.defeito}
                                className="p-mt-5"
                            />
                            <label htmlFor="defeito" className="p-mt-5">
                                Defeito
                            </label>
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="motivo_devolucao">Motivo devolução *</label>
                            <Dropdown
                                id="motivo_devolucao"
                                name="motivo_devolucao"
                                options={listaMotivos}
                                optionValue="id"
                                optionLabel="descricao"
                                placeholder="Selecione um motivo"
                                emptyMessage="Nenhum motivo cadastrado."
                                value={formik.values.motivo_devolucao}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.motivo_devolucao })}
                            />
                            {formik.errors.motivo_devolucao && (
                                <small className="p-error">{formik.errors.motivo_devolucao}</small>
                            )}
                        </div>
                        <div className="p-field-checkbox p-col-12 p-md-2">
                            <br></br>
                            <Checkbox
                                inputId="retorno_estoque"
                                id="retorno_estoque"
                                name="retorno_estoque"
                                onChange={formik.handleChange}
                                checked={formik.values.retorno_estoque}
                                value={formik.values.retorno_estoque}
                                className="p-mt-5"
                            />
                            <label htmlFor="retorno_estoque" className="p-mt-5">
                                Retorna estoque?
                            </label>
                        </div>

                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="estoque_retorno">Estoque retorno</label>
                            <Dropdown
                                id="estoque_retorno"
                                name="estoque_retorno"
                                options={listaEstoques}
                                optionValue="id"
                                optionLabel="descricao"
                                placeholder="Selecione um motivo"
                                emptyMessage="Nenhum motivo cadastrado."
                                disabled={!formik.values.retorno_estoque}
                                value={formik.values.estoque_retorno}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.estoque_retorno })}
                            />
                            {formik.errors.estoque_retorno && (
                                <small className="p-error">{formik.errors.estoque_retorno}</small>
                            )}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="justificativa">Justificativa</label>
                            <InputText
                                id="justificativa"
                                name="justificativa"
                                value={formik.values.justificativa}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                    <div className="p-grid p-col-12 p-md-12 p-justify-end p-mt-1">
                        <Button icon="pi pi-plus" label="Inserir" type="submit" className="p-button-success p-mr-2" />
                        <Button
                            icon="pi pi-times"
                            label="Fechar"
                            type="reset"
                            className="p-button-danger"
                            onClick={() => props.esconderDialog()}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};
