import React, { useState, useRef, useEffect, useCallback } from "react";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Menu } from "primereact/menu";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { axiosGet, axiosPatch, axiosPost } from "@/services/http";
import { Dialog } from "primereact/dialog";
import { EnvioEmailFormModal } from "./modal/formEmailModal";
import { CancelaNotaFormModal } from "./modal/formCancelaModal";
import { CartaCorrecaoFormModal } from "./modal/formCartaCorrecao";

import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useClearRefs from "@/hooks/useClearRefs";
import { ModalFiltroAvancadoNf } from "./modal/formFiltroAvancado";
import { useLocalFiltro } from "@/hooks/useLocalFiltro";
import { key_filtros } from "@/assets/constants/filtros";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";
import { ContingenciaFormModal } from "./modal/formContingenciaModal";

export const ListaNotaFiscalPage = () => {
    const [filtros, setFiltro, removerFiltro, filtroString] = useLocalFiltro(key_filtros.FISCAL_NOTA_FISCAL);

    const [corpoNota, setCorpoNota] = useState(null);
    const [notaFiscal, setNotaFiscal] = useState(null);
    const [apiServico, setApiServico] = useState(null);
    const [cancelDialog, setCancelDialog] = useState(false);
    const [contingenciaDialog, setContingenciaDialog] = useState(false);
    const [envioEmail, setEnvioEmail] = useState(false);
    const [cancelaNota, setCancelaNota] = useState(false);
    const [cartaCorrecao, setCartaCorrecao] = useState(false);
    const [notaSelecionada, setNotaSelecionada] = useState(null);
    const [carregandoNota, setCarregandoNota] = useState(false);
    const [totalizadorFiltros, setTotalizadorFiltros] = useState(0);
    const { empresaSelecionadaId } = useEmpresa();
    const BASE_URL = `/fiscal/notas-fiscais?query={id, destinatario, numero, serie, modelo, valor_total_nf, operacao_fiscal, chave_nf, protocolo}&emitente__id=${empresaSelecionadaId}`;

    const [url, setUrl] = useState(() => {
        if (filtros) return `${BASE_URL}&${filtroString}`;
        return BASE_URL;
    });

    const { showLoading, hideLoading } = useLoading();
    const { showWarning, showError, showSuccess } = useToast();

    const menu = useRef(null);
    const listagemRef = useRef(null);
    const modalFiltroAvancadoRef = useRef(null);
    const history = useHistory();

    useClearRefs(listagemRef, modalFiltroAvancadoRef, menu);

    const carregarNotaFiscal = useCallback(
        async (nota) => {
            setCarregandoNota(true);
            const resposta = await axiosGet(`/fiscal/notas-fiscais?id=${nota.id}`);

            if (resposta.status === 200) {
                setNotaFiscal(resposta.data.results[0]);
            } else {
                showError({
                    summary: "Erro",
                    detail: "Falha ao carregar a nota!",
                    life: 3000,
                });
            }
            setCarregandoNota(false);
        },
        [showError]
    );

    const montaNota = useCallback(async () => {
        if (notaFiscal) {
            if (notaFiscal.versao_processo === "MigrateInvoiCy") {
                const resposta = await axiosPost(`/fiscal/nota-fiscal-invoicy/`, {
                    ...notaFiscal,
                    operacao_fiscal: notaFiscal.operacao_fiscal.id,
                });
                if (resposta.status === 200) setCorpoNota(resposta.data.nota);
                else
                    showWarning({
                        summary: "Informações incompletas",
                        detail: `Verifique as informações no formulário da nota e tente novamente. (${resposta.data?.erro})`,
                        life: 3000,
                    });
            }
        }
    }, [notaFiscal, showWarning]);

    useEffect(() => {
        montaNota();
    }, [notaFiscal, montaNota]);

    async function enviarNota(operacao) {
        try {
            const resposta = await axios.post(
                `${process.env.REACT_APP_INTEGRACAO_FISCAL}/documents/${apiServico.chave_acesso}/${
                    apiServico.cnpj_integrado
                }/${notaFiscal.modelo === 55 ? "nfe" : "nfce"}/${operacao}`,
                corpoNota
            );

            if (resposta.data?.length > 0)
                if (resposta.data[0].Codigo === 100) {
                    if (
                        resposta.data[0].Documentos[0].Situacao.SitDescricao === "Autorizado o uso da NF-e" ||
                        operacao === "pre-visualizar"
                    )
                        showSuccess({
                            summary: "Sucesso!",
                            detail: `${resposta.data[0].Descricao}!
                                \n
                                Código: ${resposta.data[0].Codigo}
                                \n
                                ${
                                    operacao === "emitir"
                                        ? `${
                                              resposta.data[0].Documentos
                                                  ? resposta.data[0].Documentos[0].Situacao.SitDescricao
                                                  : ""
                                          }`
                                        : ""
                                }`,
                            sticky: true,
                        });
                    else
                        showWarning({
                            summary: "Aviso!",
                            detail: `${resposta.data[0].Descricao}!
                                \n
                                Código: ${resposta.data[0].Codigo}
                                \n
                                ${
                                    operacao === "emitir"
                                        ? `${
                                              resposta.data[0].Documentos
                                                  ? resposta.data[0].Documentos[0].Situacao.SitDescricao
                                                  : ""
                                          }`
                                        : ""
                                }`,
                            sticky: true,
                        });

                    if (
                        resposta.data[0].Documentos[0].Situacao.SitDescricao === "Autorizado o uso da NF-e" ||
                        resposta.data[0].Documentos[0].Situacao.SitCodigo === 105 ||
                        operacao === "pre-visualizar"
                    ) {
                        if (resposta.data[0].Documentos[0].DocPDFBase64) {
                            var link = document.createElement("a");
                            link.innerHTML = "Download PDF file";
                            link.download = "file.pdf";
                            link.href = resposta.data[0].Documentos[0].DocPDFDownload;
                            document.body.appendChild(link);
                            window.open(document.body.appendChild(link));
                        }
                    }

                    if (operacao === "emitir") {
                        showLoading();
                        const json = await axiosPatch(`/fiscal/notas-fiscais/${notaFiscal.id}/`, {
                            chave_nf: resposta.data[0].Documentos[0].DocChaAcesso,
                            protocolo: resposta.data[0].Documentos[0].DocProtocolo,
                        });
                        hideLoading();
                        if (json.status !== 200) {
                            showError({
                                summary: "Erro",
                                detail: "Falha ao persistir chave e protocolo da nota! Por favor, verifique com a assistência.",
                                life: 6000,
                            });
                        } else listagemRef.current?.buscarDados();
                    }
                } else {
                    showWarning({
                        summary: "Mensagem",
                        detail: `${resposta.data[0].Descricao} \n
                            Código: ${resposta.data[0].Codigo}`,
                        life: 5000,
                    });
                }
            else if (resposta.data?.Descricao)
                showWarning({
                    summary: "Mensagem",
                    detail: `${resposta.data.Descricao} \n
                        Código: ${resposta.data.Codigo}`,
                    life: 5000,
                });
            else
                showError({
                    summary: "Erro",
                    detail: "Não foi possível realizar essa ação, verifique o layout e tente novamente.",
                    life: 5000,
                });
        } catch (error) {
            showError({
                summary: "Erro",
                detail: `Falha ao comunicar com o agente fiscal.`,
                life: 3000,
            });
        }
    }

    const downloadNota = useCallback(
        async (nota, arquivo) => {
            try {
                const body = {
                    chave_nf: nota.chave_nf,
                    numero: nota.numero,
                    serie: nota.serie,
                };

                showLoading();
                const resposta = await axiosPost(
                    `${process.env.REACT_APP_INTEGRACAO_FISCAL}/documents/${apiServico.chave_acesso}/${
                        apiServico.cnpj_integrado
                    }/${nota.modelo === "55" ? "nfe" : "nfce"}/consultar`,
                    body
                );
                hideLoading();

                if (resposta.data[0].Codigo === 100 && resposta.data[0].Documentos[0]) {
                    window.open(
                        arquivo === "xml"
                            ? resposta.data[0].Documentos[0].DocXMLLink
                            : resposta.data[0].Documentos[0].DocPDFLink
                    );

                    showSuccess({
                        summary: "Sucesso!",
                        detail: `Download do ${arquivo.toUpperCase()} ${nota.chave_nf} realizado com sucesso!`,
                        life: 3000,
                    });
                } else
                    showWarning({
                        summary: "Falha",
                        detail: `Ocorreu uma falha ao realizar o download, por favor tente novamente.`,
                        life: 3000,
                    });
            } catch (error) {
                showError({
                    summary: "Erro",
                    detail: `Falha ao realizar o download do ${arquivo.toUpperCase()}, por favor tente novamente mais tarde.`,
                    life: 3000,
                });
            }
        },
        [showLoading, hideLoading, apiServico, showSuccess, showError, showWarning]
    );

    const verificaEmissao = (chave) => {
        if (chave) return chave;
        else return "NÃO PROCESSADO";
    };

    const verificaProtocolo = (protocolo) => {
        if (protocolo) return protocolo;
        else return "NÃO TRANSMITIDO";
    };

    const botoesEmissao = [
        {
            label: "Operações NF",
            items: [
                {
                    label: "Enviar e-mail",
                    icon: "pi pi-envelope",
                    disabled: carregandoNota || !notaSelecionada?.protocolo,
                    command: () => {
                        setEnvioEmail(true);
                    },
                },
                {
                    label: "Pré-visualizar",
                    icon: "pi pi-file",
                    disabled: carregandoNota
                        ? true
                        : notaSelecionada?.protocolo || notaSelecionada?.modelo === "65"
                        ? true
                        : false,
                    command: () => {
                        enviarNota("pre-visualizar");
                    },
                },
                {
                    label: "Transmitir",
                    icon: "pi pi-arrow-up",
                    disabled: carregandoNota ? true : notaSelecionada?.protocolo ? true : false,
                    command: () => {
                        enviarNota("emitir");
                    },
                },
                {
                    label: "Carta de correção",
                    icon: "pi pi-book",
                    disabled: carregandoNota || !notaSelecionada?.protocolo,
                    command: () => {
                        setCartaCorrecao(true);
                    },
                },
                {
                    label: "Cancelar",
                    icon: "pi pi-times",
                    disabled: carregandoNota || !notaSelecionada?.protocolo,
                    command: () => {
                        setCancelDialog(true);
                    },
                },
            ],
        },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_NOTASAIDA_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de nota fiscal"
                    tooltipOptions={{ position: "left" }}
                    disabled={
                        rowData.protocolo ? true : notaSelecionada && rowData.id === notaSelecionada.id ? false : true
                    }
                    onClick={() =>
                        history.push({
                            pathname: "/fiscal/nota-saida/emissao-nota",
                            state: { notaFiscal: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_NOTASAIDA_EMITIR]}
                    model={botoesEmissao}
                    componente={Button}
                    className="p-button-rounded p-button-info p-mr-2 p-mb-1"
                    tooltip="Operações NF"
                    tooltipOptions={{ position: "left" }}
                    disabled={notaSelecionada && rowData.id === notaSelecionada.id ? false : true}
                    icon="pi pi-book"
                    loading={carregandoNota}
                    onClick={(event) => {
                        menu.current.toggle(event);
                        carregarNotaFiscal(rowData);
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_NOTASAIDA_CONSULTAR]}
                    componente={Button}
                    icon="pi pi-download"
                    className="p-button-rounded p-button-success p-mr-2 p-mb-1"
                    onClick={() => downloadNota(rowData, "xml")}
                    tooltip="Download XML"
                    tooltipOptions={{ position: "left" }}
                    disabled={
                        rowData.protocolo ? (notaSelecionada && rowData.id === notaSelecionada.id ? false : true) : true
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_NOTASAIDA_CONSULTAR]}
                    componente={Button}
                    icon="pi pi-file-pdf"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => downloadNota(rowData, "pdf")}
                    tooltip={`Imprimir ${rowData.modelo === "55" ? "NFe" : "NFCe"}`}
                    tooltipOptions={{ position: "left" }}
                    disabled={
                        rowData.protocolo ? (notaSelecionada && rowData.id === notaSelecionada.id ? false : true) : true
                    }
                />
            </div>
        );
    };

    const painelEsquerdo = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FISCAL_NOTASAIDA_INCLUIR]}
                componente={Button}
                label="Incluir Nota"
                icon="pi pi-plus"
                className="p-button-success p-button-outlined p-mr-2"
                onClick={() => history.push("/fiscal/nota-saida/emissao-nota")}
            />
            <Button
                label="Verificar Contingências"
                icon="pi pi-search"
                className="p-button-warning p-mr-2"
                onClick={() => setContingenciaDialog(true)}
            />
            <Button
                label="Filtro Avançado"
                icon="pi pi-filter"
                className="p-button-help p-mr-2"
                badge={totalizadorFiltros > 0 ? totalizadorFiltros : null}
                onClick={() => modalFiltroAvancadoRef.current?.abrirModal()}
            />
        </>
    );

    const colunas = [
        {
            field: "numero",
            header: "Número",
            style: { width: "80px" },
        },
        {
            field: "serie",
            header: "Série",
            style: { width: "50px" },
        },
        {
            field: "modelo",
            header: "Modelo",
            style: { width: "50px" },
        },
        {
            field: "destinatario.nome",
            header: "Destinatário",
            style: { minWidth: "300px" },
        },
        {
            field: "chave_nf",
            header: "Chave",
            action: (e) => verificaEmissao(e.chave_nf),
            align: "left",
            style: { minWidth: "500px" },
        },
        {
            field: "protocolo",
            header: "Protocolo",
            action: (e) => verificaProtocolo(e.protocolo),
            style: { minWidth: "200px" },
        },
        {
            field: "valor_total_nf",
            header: "Total Nf",
            align: "right",
            style: { width: "130px" },
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            alignFrozen: "right",
            style: { minWidth: "240px" },
        },
    ];

    const cancelNf = () => {
        setCancelDialog(false);
        setCancelaNota(true);
    };

    const esconderCancelDialog = () => {
        setCancelDialog(false);
    };

    const onFilter = (e, contador) => {
        setUrl(e);
        setTotalizadorFiltros(contador);
    };

    const carregaApiServico = useCallback(async () => {
        const resposta = await axiosGet(`/configuracoes/api-servicos?empresa=${empresaSelecionadaId}`);
        if (resposta.status === 200)
            if (resposta.data?.results?.length === 0) {
                showWarning({
                    summary: "API não encontrada!",
                    detail: "Não existe API de Serviço cadastrada.",
                    sticky: true,
                });
            } else setApiServico(resposta.data?.results[0]);
    }, [empresaSelecionadaId, showWarning]);

    useEffect(() => {
        carregaApiServico();
    }, [carregaApiServico]);

    return (
        <div className="p-grid">
            <Menu model={botoesEmissao} popup ref={menu} id="popup_menu" />
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Notas Fiscais"
                        colunas={colunas}
                        painelEsquerdo={painelEsquerdo}
                        urlPesquisa={url}
                        configTabela={{
                            paginator: true,
                            lazy: true,
                            filterDisplay: "menu",
                            selection: notaSelecionada,
                            onSelectionChange: (e) => setNotaSelecionada(e.value),
                            selectionMode: "single",
                            scrollable: true,
                        }}
                    />
                </div>
            </div>
            <Dialog
                header="Confirmação de envio"
                visible={envioEmail}
                breakpoints={{ "960px": "80vw" }}
                style={{ width: "30vw" }}
                onHide={() => setEnvioEmail(false)}
            >
                <EnvioEmailFormModal nota={notaFiscal ? notaFiscal : null} dialog={setEnvioEmail} />
            </Dialog>
            <Dialog
                header="Justificativa de cancelamento"
                visible={cancelaNota}
                breakpoints={{ "960px": "80vw" }}
                style={{ width: "35vw" }}
                onHide={() => setCancelaNota(false)}
            >
                <CancelaNotaFormModal nota={notaFiscal ? notaFiscal : null} dialog={setCancelaNota} />
            </Dialog>
            <Dialog
                header="Carta de Correção (CC-e)"
                visible={cartaCorrecao}
                breakpoints={{ "960px": "80vw" }}
                style={{ width: "35vw" }}
                onHide={() => setCartaCorrecao(false)}
            >
                <CartaCorrecaoFormModal nota={notaFiscal ? notaFiscal : null} dialog={setCartaCorrecao} />
            </Dialog>
            <Dialog
                header="Consultar Contingências"
                visible={contingenciaDialog}
                breakpoints={{ "960px": "80vw" }}
                style={{ width: "45vw" }}
                onHide={() => setContingenciaDialog(false)}
            >
                <ContingenciaFormModal dialog={setCartaCorrecao} apiServico={apiServico} />
            </Dialog>
            <ModalFiltroAvancadoNf
                ref={modalFiltroAvancadoRef}
                onFilter={onFilter}
                baseUrl={BASE_URL}
                filtros={filtros}
                setFiltro={setFiltro}
                removerFiltro={removerFiltro}
            />
            <ConfirmDialog
                visible={cancelDialog}
                onHide={esconderCancelDialog}
                header="Confirmação de Cancelamento"
                message={
                    notaFiscal && (
                        <span>
                            {"Deseja realmente cancelar a nota número "}
                            <b>{notaFiscal.numero}</b>?
                        </span>
                    )
                }
                icon="pi pi-info-circle p-mr-3"
                accept={cancelNf}
                acceptLabel="Sim"
                acceptClassName="p-button-danger"
                reject={esconderCancelDialog}
                rejectLabel="Não"
            />
        </div>
    );
};
