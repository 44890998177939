import React from "react";
import { FileUpload } from "primereact/fileupload";

import storageKeys from "@/assets/constants/storage";
import useLocalStorage from "@/hooks/useLocalStorage";

export const MakoFileUpload = ({ onBeforeSend, ...props }) => {
    const [storageToken] = useLocalStorage(storageKeys.TOKEN_KEY);

    const onBeforeSendFile = (e) => {
        e.xhr.setRequestHeader("Authorization", `token ${storageToken}`);

        if (onBeforeSend) {
            onBeforeSend(e);
        }
    };

    return <FileUpload {...props} onBeforeSend={onBeforeSendFile} />;
};
