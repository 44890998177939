import React from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { useFormik } from "formik";

import useOrdemCompra from "@/hooks/useOrdemCompra";

export const AnotacoesOrdemCompraForm = () => {
    const { dadosBasicos, handleAnotacoes } = useOrdemCompra();

    const formik = useFormik({
        initialValues: {
            anotacao: "",
        },
        onSubmit: handleSubmit,
    });

    function handleSubmit(values) {
        const { anotacao } = values;

        handleAnotacoes(anotacao);

        formik.resetForm();
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12">
                    <label htmlFor="anotacoes">Anotações da ordem de compra</label>
                    <InputTextarea id="anotacoes" name="anotacoes" rows={12} disabled value={dadosBasicos?.anotacoes} />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-10">
                    <label htmlFor="anotacao">Nova anotação</label>
                    <InputText
                        id="anotacao"
                        name="anotacao"
                        autoComplete="off"
                        autoFocus
                        value={formik.values.anotacao}
                        onChange={formik.handleChange}
                    />
                </div>
                <div className="p-field p-col-12 p-md-2 p-mt-auto">
                    <Button icon="pi pi-pencil" type="submit" label="Adicionar" />
                </div>
            </div>
        </form>
    );
};
