import React, { useState } from "react";

import MakoListagem from "@/components/MakoListagem";
import { Button } from "primereact/button";
import permissoes from "@/assets/constants/permissoes";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { InputText } from "primereact/inputtext";
import { useHistory } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";

const INITIAL_VALUES = {
    chave: "",
    registro: "",
    search: "",
};

export const PermissoesPerfil = () => {
    const [show, setShow] = useState(false);
    const [url, setUrl] = useState("/pessoas/permissoes-perfis/");
    const [totalizadorFiltros, setTotalizadorFiltros] = useState(0);
    const history = useHistory();

    const formik = useFormik({
        initialValues: INITIAL_VALUES,
        onSubmit: handleSubmit,
    });

    const limparFiltro = () => {
        setTotalizadorFiltros(0);
        formik.resetForm();
    };

    async function handleSubmit(values) {
        let queryParams = [];
        let contador = 0;

        if (values.chave !== "") {
            contador++;
            queryParams.push(`chave=${values.chave}`);
        }

        if (values.registro !== "") {
            contador++;
            queryParams.push(`registro=${values.registro}`);
        }

        if (values.search !== "") {
            contador++;
            queryParams.push(`search=${values.search}`);
        }

        queryParams = queryParams.join("&");

        setUrl("/pessoas/permissoes-perfis/?" + queryParams);
        setShow(false);
        setTotalizadorFiltros(contador);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_PRODUTOS_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Editar"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/configuracoes/permissoes-perfis/form",
                            state: { permissao: rowData },
                        })
                    }
                />
            </div>
        );
    };

    const leftHeader = (
        <>
            <Button
                label="Filtro Avançado"
                icon="pi pi-filter"
                badge={totalizadorFiltros > 0 ? totalizadorFiltros : null}
                className="p-button-help"
                onClick={() => setShow(true)}
            />
        </>
    );

    const colunas = [
        {
            field: "id",
            header: "Id",
        },
        {
            field: "chave",
            header: "Chave",
        },
        {
            field: "descricao",
            header: "Descrição",
        },
        {
            field: "registro",
            header: "Registro",
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "5%" },
        },
    ];

    return (
        <div className="card">
            <h5>Permissões</h5>
            <MakoListagem
                colunas={colunas}
                urlPesquisa={url}
                responsiva
                painelEsquerdo={leftHeader}
                configTabela={{
                    paginator: true,
                    lazy: true,
                    filterDisplay: "menu",
                }}
            />
            <Dialog
                header="Filtrar permissões"
                visible={show}
                style={{ width: "40vw", display: "block" }}
                onHide={() => setShow(false)}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="chave">Chave</label>
                            <InputText
                                id="chave"
                                name="chave"
                                placeholder="Digite uma chave"
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-8">
                            <label htmlFor="registro">Registro</label>
                            <InputText
                                id="registro"
                                name="registro"
                                placeholder="Digite um registro"
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="search">Pesquisar por texto</label>
                            <InputText
                                id="search"
                                name="search"
                                placeholder="Digite algo..."
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                    <br />
                    <div className="p-grid p-justify-end">
                        <Button
                            type="reset"
                            label="Cancelar"
                            onClick={() => setShow(false)}
                            className="p-button-danger p-mr-2 p-mb-2"
                        />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            onClick={() => limparFiltro()}
                            className="p-button-warning p-mr-2 p-mb-2"
                        />
                        <Button type="submit" icon="pi pi-filter" label="Filtrar" className="p-mr-2 p-mb-2" />
                    </div>
                </form>
            </Dialog>
        </div>
    );
};
