import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { PageBase } from "@/components/PageBase";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { axiosPatch, axiosPost } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

const url = "/financeiro/bancos/";
const urlvoltar = "/financeiro/cadastros/bancos";

export const FinanceiroBancoForm = (props) => {
    const { showLoading, hideLoading } = useLoading();
    const history = useHistory();
    const { showSuccess, showError } = useToast();

    const formik = useFormik({
        initialValues: props.location.state?.bancos || {
            codigo: "",
            descricao: "",
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                codigo: Yup.string().required("O campo 'código' é obrigatório."),
                descricao: Yup.string().required("O campo 'descrição' é obrigatório."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(url, values);
                hideLoading();
                if (resposta.status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Banco cadastrado com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        voltarParaListagem();
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPatch(`${url}${values.id}/`, values);
                hideLoading();
                if (resposta.status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Banco alterado com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        voltarParaListagem();
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const voltarParaListagem = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Novo banco" : "Manutenção de banco"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="codigo">Status *</label>
                        <InputText
                            id="codigo"
                            name="codigo"
                            keyfilter="pint"
                            autoComplete="off"
                            value={formik.values.codigo}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.codigo })}
                        />
                        {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-8">
                        <label htmlFor="descricao">Descrição *</label>
                        <InputText
                            id="descricao"
                            name="descricao"
                            autoComplete="off"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2 p-mt-5">
                        <div className="p-inputgroup">
                            <label className="p-mr-2">Status</label>
                            <InputSwitch
                                id="ativo"
                                name="ativo"
                                checked={formik.values.ativo}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                </div>
                <CamposObrigatorios />
                <div className="p-grid p-col-12 p-md-6">
                    <Button type="submit" label="Gravar" icon="pi pi-save" className="p-button-info p-mr-2" />
                    <Button
                        type="reset"
                        label="Voltar"
                        icon="pi pi-angle-double-left"
                        className="p-button-danger"
                        onClick={voltarParaListagem}
                    />
                </div>
            </form>
        </PageBase>
    );
};
