import React, { useState, useRef, useCallback, useEffect } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";
import useLoading from "@/hooks/useLoading";
import useFormatCnpjCpf from "@/hooks/useFomatCNPJCPF";
import { axiosGet, axiosPost, axiosPut } from "@/services/http";

export const FinanceiroContaBancariaFormBase = ({ contaBancaria, modal, aposSalvar }) => {
    const [bancos, setBancos] = useState([]);
    const [contasFinanceiras, setContasFinanceiras] = useState([]);
    const toastRef = useRef(null);
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const [formatDocumento] = useFormatCnpjCpf();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            perfil: null,
            conta_financeira: null,
            banco: null,
            agencia: "",
            agencia_dv: "",
            numero_conta: "",
            numero_conta_dv: "",
            variacao: "",
            limite: 0,
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                perfil: Yup.object()
                    .required("O campo 'perfil' é obrigatório.")
                    .typeError("O campo 'perfil' é obrigatório."),
                conta_financeira: Yup.number().nullable(),
                banco: Yup.number().required("O campo 'banco' é obrigatório.").typeError("Selecione um banco."),
                agencia: Yup.string().required("O campo 'agência' é obrigatório."),
                agencia_dv: Yup.string().required("O campo 'agência (dígito verificador)' é obrigatório."),
                numero_conta: Yup.string().required("O campo 'conta' é obrigatório."),
                numero_conta_dv: Yup.string().required("O campo 'conta (dígito verificador)' é obrigatório."),
                variacao: Yup.string().required("O campo 'variação' é obrigatório."),
            });

            await formSchema.validate(values, { abortEarly: false });

            const contaBancaria = {
                ...values,
                perfil: values.perfil.id,
            };

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost("/financeiro/contas-bancarias/", contaBancaria);
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Conta bancária cadastrada com sucesso!",
                        life: 1500,
                    });

                    if (aposSalvar) {
                        aposSalvar();
                    }

                    if (!modal) {
                        setTimeout(() => {
                            history.push("/financeiro/cadastros/contas-bancarias");
                        }, 2000);
                    }
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(`/financeiro/contas-bancarias/${values.id}/`, contaBancaria);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Conta bancária alterada com sucesso!",
                        life: 1500,
                    });

                    if (aposSalvar) {
                        aposSalvar();
                    }

                    if (!modal) {
                        setTimeout(() => {
                            history.push("/financeiro/cadastros/contas-bancarias");
                        }, 2000);
                    }
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const listarContasFinanceira = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/financeiro/contas-financeiras?limit=300");
        hideLoading();

        if (resposta.status === 200) {
            setContasFinanceiras(resposta.data.results);
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos listar as contas financeiras.",
                life: 3000,
            });
        }
    }, [showLoading, hideLoading]);

    const listarBancos = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/financeiro/bancos?limit=300");
        hideLoading();

        if (resposta.status === 200) {
            setBancos(resposta.data.results);
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não conseguimos listar os bancos.",
                life: 3000,
            });
        }
    }, [showLoading, hideLoading]);

    useEffect(() => {
        listarContasFinanceira();
        listarBancos();
    }, [listarContasFinanceira, listarBancos]);

    useEffect(() => {
        if (contaBancaria) {
            const { banco, conta_financeira, ...rest } = contaBancaria;
            setValues({
                ...rest,
                banco: banco.id,
                conta_financeira: conta_financeira?.id,
            });
        }
    }, [setValues, contaBancaria]);

    const autoCompletePerfilTemplate = (item) => {
        return <div>{`${item.nome} - ${item.doc}`}</div>;
    };

    const aposPesquisarPerfil = (data) => {
        let pessoas = [];
        if (data.length > 0) {
            data.forEach((pessoa) => {
                const { identificacao, tipo_pessoa, ...rest } = pessoa;

                if (tipo_pessoa === "PF" || tipo_pessoa === "PJ") {
                    pessoas.push({
                        ...rest,
                        doc: formatDocumento(identificacao),
                    });
                } else {
                    pessoas.push({
                        ...rest,
                        doc: identificacao,
                    });
                }
            });
        }
        return pessoas;
    };

    return (
        <>
            {!modal && <h3>{!formik.values.id ? "Nova conta bancária" : "Manutenção de conta bancária"}</h3>}
            <Toast ref={toastRef} />
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-5">
                        <label htmlFor="perfil">Perfil *</label>
                        <MakoAutoComplete
                            id="perfil"
                            name="perfil"
                            value={formik.values.perfil}
                            onChange={formik.handleChange}
                            minCaracteresBusca={4}
                            itemTemplate={autoCompletePerfilTemplate}
                            field="nome"
                            urlSearch="/pessoas/perfis?query={id,nome,identificacao,tipo_pessoa}&ativo=true&nome__unaccent__icontains="
                            placeholder="Comece a digitar para buscar... (min 4 caracteres)"
                            aposPesquisar={(e) => aposPesquisarPerfil(e)}
                            className={classNames({ "p-invalid": formik.errors.perfil })}
                        />
                        {formik.errors.perfil && <small className="p-error">{formik.errors.perfil}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="conta-financeira">Conta financeira</label>
                        <Dropdown
                            id="conta-financeira"
                            name="conta_financeira"
                            placeholder="Selecione uma conta financeira"
                            options={contasFinanceiras}
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.conta_financeira}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="banco">Banco *</label>
                        <Dropdown
                            id="banco"
                            name="banco"
                            placeholder="Selecione um banco"
                            options={bancos}
                            optionValue="id"
                            optionLabel="descricao"
                            value={formik.values.banco}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.banco })}
                        />
                        {formik.errors.banco && <small className="p-error">{formik.errors.banco}</small>}
                    </div>
                    <div className="p-field-checkbox p-col-12 p-md-1 p-mt-5">
                        <Checkbox
                            id="ativo"
                            name="ativo"
                            checked={formik.values.ativo}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="ativo">Ativa?</label>
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="agencia">Agência *</label>
                        <InputText
                            id="agencia"
                            name="agencia"
                            keyfilter={/^[0-9]/}
                            maxLength={8}
                            value={formik.values.agencia}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.agencia })}
                        />
                        {formik.errors.agencia && <small className="p-error">{formik.errors.agencia}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="agencia-dv">Agência (DV) *</label>
                        <InputText
                            id="agencia-dv"
                            name="agencia_dv"
                            keyfilter={/^[0-9]/}
                            maxLength={1}
                            value={formik.values.agencia_dv}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.agencia_dv })}
                        />
                        {formik.errors.agencia_dv && <small className="p-error">{formik.errors.agencia_dv}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="numero-conta">Conta *</label>
                        <InputText
                            id="numero-conta"
                            name="numero_conta"
                            keyfilter={/^[0-9]/}
                            maxLength={32}
                            value={formik.values.numero_conta}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.numero_conta })}
                        />
                        {formik.errors.numero_conta && <small className="p-error">{formik.errors.numero_conta}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="numero-conta-dv">Conta (DV) *</label>
                        <InputText
                            id="numero-conta-dv"
                            name="numero_conta_dv"
                            keyfilter={/^[0-9]/}
                            maxLength={1}
                            value={formik.values.numero_conta_dv}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.numero_conta_dv })}
                        />
                        {formik.errors.numero_conta_dv && (
                            <small className="p-error">{formik.errors.numero_conta_dv}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="variacao">Variação *</label>
                        <InputText
                            id="variacao"
                            name="variacao"
                            keyfilter={/^[0-9]/}
                            maxLength={3}
                            value={formik.values.variacao}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.variacao })}
                        />
                        {formik.errors.variacao && <small className="p-error">{formik.errors.variacao}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="limite">Limite</label>
                        <MakoInputMoeda
                            id="limite"
                            name="limite"
                            valueMoeda={formik.values.limite}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.limite })}
                        />
                        {formik.errors.limite && <small className="p-error">{formik.errors.limite}</small>}
                    </div>
                </div>
                <p>* Campos obrigatórios</p>
                <div className="p-grid p-col-12 p-md-6">
                    <Button label="Gravar" icon="pi pi-save" type="submit" className="p-button-info p-mr-2" />
                    {!modal && (
                        <Button
                            label="Cancelar"
                            icon="pi pi-times"
                            type="button"
                            className="p-button-danger p-mr-2"
                            onClick={() => history.push("/financeiro/cadastros/contas-bancarias")}
                        />
                    )}
                </div>
            </form>
        </>
    );
};
