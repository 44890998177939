import { useEffect } from "react";

const useKey = (type, key, callback) => {
    useEffect(() => {
        const keyPressHandler = (event) => {
            if (event.key === key && typeof callback === "function") {
                callback();
            }
        };
        window.addEventListener(type, keyPressHandler);
        return () => {
            window.removeEventListener(type, keyPressHandler);
        };
    }, [type, key, callback]);
};

export default useKey;
