import React, { useState, useRef, useEffect, useCallback } from "react";

import { Dropdown } from "primereact/dropdown";
import { axiosGet } from "@/services/http";

import { MakoListagemHierarquica } from "@/components/MakoListagemHierarquica";
import useClearRefs from "@/hooks/useClearRefs";
import useLoadingLocal from "@/hooks/useLoadingLocal";

export const CategoriasVinculadasPage = () => {
    const [grupo, setGrupo] = useState(null);
    const [listaGrupos, setListaGrupos] = useState([]);

    const [ loading, showLoading, hideLoading ] = useLoadingLocal();
    const listagemRef = useRef(null);

    useClearRefs(listagemRef);

    const listarGrupos = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/produtos/categorias-grupos/");
        hideLoading();

        if (resposta.status === 200 || resposta.status === 201) {
            setListaGrupos(resposta.data.results);
        }
    }, [showLoading, hideLoading]);

    useEffect(() => {
        listarGrupos();
    }, [listarGrupos]);

    const colunas = [
        { field: "id", header: "Código", style: { minWidth: "10%" }, expander: true, },
        { field: "nome", header: "Nome", style: { minWidth: "30%" } },
        { field: "grupo.descricao", header: "Grupo", style: { minWidth: "30%" } },
        { field: "vigencia_inicial", header: "Vigência Inicial" },
        { field: "vigencia_final", header: "Vigência Final" },
    ];

    const selecionaGrupo = (grupo) => {
        setGrupo(grupo);
    };

    const cabecalhoTabela = (
        <>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-6">
                    <label htmlFor="grupo">Grupo *</label>
                    <Dropdown
                        id="grupo"
                        name="grupo"
                        placeholder="Selecione um grupo"
                        options={listaGrupos}
                        optionValue="id"
                        optionLabel="descricao"
                        value={grupo}
                        onChange={(e) => selecionaGrupo(e.value)}
                        disabled={loading}
                    />
                </div>
            </div>
        </>
    );

    return (
        <>
            <div className="p-grid">
                <div className="p-col-12">
                    <MakoListagemHierarquica
                        ref={listagemRef}
                        urlPesquisa={`/produtos/categorias-menu?grupo_id=${grupo}`}
                        fazerBusca={!!grupo}
                        colunas={colunas}
                        cabecalho={cabecalhoTabela}
                        responsiva
                    />
                </div>
            </div>
        </>
    );
};
