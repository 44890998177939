import React, { useImperativeHandle, useState, forwardRef, useMemo } from "react";

import { getMonth, getYear, setMonth } from "date-fns";
import { parseData } from "@/assets/util/datas";
import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { InputNumber, InputText } from "primereact";
import { Dialog } from "primereact/dialog";

import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";
import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoButton } from "@/components/MakoButton";
import { Dropdown } from "@/components/Dropdown";

import useAxiosPrivate from "@/hooks/useAxiosPrivate";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";

const INITIAL_VALUE = {
    dia: null,
    mes: null,
    ano: null,
    codigo_uf: null,
    codigo_ibge: null,
    descricao: "",
};

const Modal = ({ onSuccess }, ref) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const [visible, setVisible] = useState(false);
    const [estados, setEstados] = useState([]);

    const { showSuccess, showError } = useToast();
    const { empresaSelecionada } = useEmpresa();
    const { axiosPost, axiosPatch } = useAxiosPrivate();

    const fecharModal = () => {
        setVisible(() => false);
        formik.resetForm();
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: INITIAL_VALUE,
        onSubmit: handleSubmit,
    });

    const abrirModal = (feriado = null) => {
        if (feriado)
            setValues({
                ...feriado,
                mes: feriado.mes ? setMonth(new Date(), feriado.mes) : null,
                ano: feriado.ano ? parseData(`${feriado.ano}-01-01`) : null,
                codigo_uf: feriado.codigo_uf ? feriado.codigo_uf.codigo_uf : null,
            });
        setVisible(() => true);
    };

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                dia: Yup.number().required("O campo 'dia' é obrigatório").typeError("Informe um 'dia' válido"),
                mes: Yup.date().required("O campo 'mês' é obrigatório").typeError("Informe um 'mês' válido"),
                ano: Yup.date().nullable().typeError("Informe um 'ano' válido"),
                codigo_uf: Yup.string().nullable().typeError("Informe um 'estado' válido"),
                codigo_ibge: Yup.object().nullable().typeError("Informe uma 'cidade' válida"),
                descricao: Yup.string()
                    .required("O campo 'descrição' é obrigatório")
                    .typeError("Informe uma 'descrição' válida"),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });
            dadosValidados.mes = getMonth(dadosValidados.mes);
            if (dadosValidados.ano) dadosValidados.ano = getYear(dadosValidados.ano);
            if (dadosValidados.codigo_ibge) dadosValidados.codigo_ibge = dadosValidados.codigo_ibge.codigo;

            if (!values?.id) {
                showLoading();
                const { status, data } = await axiosPost("/configuracoes/feriados/", dadosValidados);
                hideLoading();
                if (status === 201) {
                    showSuccess({
                        summary: "Sucesso!",
                        detail: data?.msg || "Feriado cadastrado com sucesso.",
                        life: 2000,
                    });
                    if (typeof onSuccess === "function") onSuccess();
                    fecharModal();
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não foi possível sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const { status, data } = await axiosPatch(`/configuracoes/feriados/${values.id}/`, dadosValidados);
                hideLoading();
                if (status === 200) {
                    showSuccess({
                        summary: "Sucesso!",
                        detail: data?.msg || "Feriado alterado com sucesso.",
                        life: 2000,
                    });
                    if (typeof onSuccess === "function") onSuccess();
                    fecharModal();
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não foi possível sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const urlUF = useMemo(() => {
        let url = "/pessoas/estados?query={id,nome,uf,codigo_uf}&limit=100";
        if (!empresaSelecionada) return url;
        const { enderecoperfil_set } = empresaSelecionada;
        if (!enderecoperfil_set || !enderecoperfil_set?.length) return url;
        const principal = enderecoperfil_set.find((endereco) => endereco.principal && endereco.ativo);
        if (principal) return `${url}&pais__sigla=${principal.cidade.estado.pais.sigla}`;
        const enderecoExistente = enderecoperfil_set.find((endereco) => endereco.ativo);
        return `${url}&pais__sigla=${enderecoExistente.cidade.estado.pais.sigla}`;
    }, [empresaSelecionada]);

    const urlCidade = useMemo(() => {
        let url = "/pessoas/cidades/?query={id,nome,codigo}&limit=1000&ordering=nome";
        const codigo_uf = formik.values.codigo_uf;
        if (!codigo_uf) return `${url}&nome__unaccent__icontains=`;
        const estado = estados.find((e) => e.codigo_uf === codigo_uf);
        if (estado) return `${url}&estado=${estado.id}&nome__unaccent__icontains=`;
        return `${url}&nome__unaccent__icontains=`;
    }, [formik.values.codigo_uf, estados]);

    return (
        <Dialog header="Cadastrar feriado" visible={visible} onHide={fecharModal} style={{ width: "80rem" }}>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="descricao">Descrição *</label>
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            onInput={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="dia">Dia *</label>
                        <InputNumber
                            id="dia"
                            name="dia"
                            useGrouping={false}
                            min={1}
                            max={31}
                            maxFractionDigits={0}
                            value={formik.values.dia}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.dia })}
                        />
                        {formik.errors.dia && <small className="p-error">{formik.errors.dia}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="mes">Mês *</label>
                        <MakoCalendar
                            id="mes"
                            name="mes"
                            dateFormat="MM"
                            view="month"
                            mask="MM"
                            placeholder="Selecione"
                            valueCalendar={formik.values.mes}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.mes })}
                        />
                        {formik.errors.mes && <small className="p-error">{formik.errors.mes}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="ano">Ano</label>
                        <MakoCalendar
                            id="ano"
                            name="ano"
                            dateFormat="yy"
                            view="year"
                            mask="yy"
                            placeholder="Selecione"
                            valueCalendar={formik.values.ano}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.ano })}
                        />
                        {formik.errors.ano && <small className="p-error">{formik.errors.ano}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="codigo_uf">Estado / Província</label>
                        <Dropdown
                            id="codigo_uf"
                            name="codigo_uf"
                            optionLabel="nome"
                            optionValue="codigo_uf"
                            setObjects={setEstados}
                            url={urlUF}
                            filter
                            showClear
                            filterBy="nome"
                            value={formik.values.codigo_uf}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.codigo_uf })}
                        />
                        {formik.errors.codigo_uf && <small className="p-error">{formik.errors.codigo_uf}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="codigo_ibge">Município</label>
                        <MakoAutoComplete
                            inputId="codigo_ibge"
                            name="codigo_ibge"
                            field="nome"
                            urlSearch={urlCidade}
                            minCaracteresBusca={2}
                            placeholder="Digite para pesquisar..."
                            value={formik.values.codigo_ibge}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.codigo_ibge })}
                        />
                        {formik.errors.codigo_ibge && <small className="p-error">{formik.errors.codigo_ibge}</small>}
                    </div>
                </div>
                <div className="p-grid p-col-12 p-md-6">
                    <MakoButton
                        loading={loading}
                        icon="pi pi-save"
                        label="Gravar"
                        type="submit"
                        className="p-button-info p-mr-2"
                    />
                    <MakoButton
                        icon="pi pi-trash"
                        label="Limpar"
                        type="reset"
                        className="p-button-warning p-mr-2"
                        onClick={formik.resetForm}
                        loading={loading}
                    />
                    <MakoButton
                        loading={loading}
                        label="Cancelar"
                        type="button"
                        className="p-button-danger"
                        onClick={fecharModal}
                    />
                </div>
            </form>
        </Dialog>
    );
};

export const FormFeriadosModal = forwardRef(Modal);
