import React, { useCallback, useState } from 'react';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import MakoListagem from '@/components/MakoListagem';

import { OP_CRUD_DJANGO } from '@/assets/util/persistenciaDjango';
import { MakoInputMoeda } from '@/components/MakoInputMoeda';

import useDevolucaoFornecedor from '@/hooks/useDevolucaoFornecedor';

import ModalItemSemVenda from './modalItemSemVenda';
import ModalItem from './modalItem';

const FormDadosItems = ({ handleAbleButton }) => {
    const [visible, setVisible] = useState(false);
    const [visibleSV, setVisibleSV] = useState(false);

    const { selecionados, valorTotal, updateSelecionados, resetSelecionados } = useDevolucaoFornecedor();

    const actionBodyTemplate = (rowData) => {
        return <>
            <Button
                icon="pi pi-trash"
                className="p-button-rounded p-button-danger"
                tooltip="Remover item"
                type='button'
                onClick={() => handleRemove(rowData)}
            />
        </>
    }

    const fecharModal = () => {
        setVisible(() => false);
    }

    const fecharModalSV = () => {
        setVisibleSV(() => false);
    };

    const handleConfirm = (value, hasSale = true) => {
        if (hasSale) {
            if (value.id == null) {
                delete value.id;
                delete value._id;

                updateSelecionados(value, OP_CRUD_DJANGO.novo)
            } else {
                if (value.id == null) {
                    delete value.id;
                } else {
                    delete value._id;
                }

                updateSelecionados(value, OP_CRUD_DJANGO.editar)
            }
            return true;
        }

        if (value.id == null && value._id == null) {
            delete value.id;
            delete value._id;

            updateSelecionados(value, OP_CRUD_DJANGO.novo)
        } else {
            if (value.id == null) {
                delete value.id;
            } else {
                delete value._id;
            }

            updateSelecionados(value, OP_CRUD_DJANGO.editar)
        }
        return true;

    }

    const handleRemove = (rowData) => {
        updateSelecionados(rowData, OP_CRUD_DJANGO.deletar);
    }

    const aposPesquisar = (lista) => {
        return lista.filter(item => item._status !== OP_CRUD_DJANGO.deletar);
    }

    const colunas = [
        {
            field: "",
            header: "Codigo",
            style: { width: "10%" },
            action: (rowData) => rowData.sku.codigo
        },
        {
            field: "sku.descricao_reduzida",
            header: "Descrição",
        },
        {
            field: "quantidade",
            header: "Quantidade",
            style: { width: "10%" }
        },
        {
            field: "valor_total",
            header: "Valor Total",
            style: { width: "12%" },
            money: true
        },
        {
            field: "",
            header: "Ações",
            style: { width: "10%" },
            action: (rowData) => actionBodyTemplate(rowData)
        }
    ];

    const handleReset = useCallback(() => {
        resetSelecionados()
        handleAbleButton(false);
    }, [])

    return (
        <div>
            <form>
                <div>
                    <Button
                        type="button"
                        icon="pi pi-plus"
                        label="Item venda"
                        className="p-button-success p-mb-2"
                        onClick={() => setVisible(true)}
                    />
                    <Button
                        type="button"
                        icon="pi pi-plus"
                        label="Item avulso"
                        className="p-button-success p-button-outlined p-mb-2 p-ml-2"
                        onClick={() => setVisibleSV(true)}
                    />
                </div>
                <div className='p-mb-4'>
                    <MakoListagem
                        titulo="Items selecionados"
                        colunas={colunas}
                        dadosLocal={selecionados}
                        aposPesquisar={aposPesquisar}
                    />
                    <div className='p-mt-4'>
                        <label><b>Valor total: </b></label>
                        <MakoInputMoeda
                            id="valor_total"
                            name="valor_total"
                            permiteFracionario
                            value={valorTotal}
                            disabled
                        />
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            className="p-button-warning p-mr-2 p-mb-2"
                            onClick={handleReset}
                        />
                    </div>
                </div>
            </form>
            <Dialog
                visible={visible}
                onHide={fecharModal}
                style={{ width: "80vw" }}
                header="Selecionar item de devolução"
            >
                <ModalItem fecharModal={fecharModal} onConfirm={handleConfirm} />
            </Dialog>
            <Dialog
                visible={visibleSV}
                onHide={fecharModalSV}
                style={{ width: "80vw" }}
                header="Informar item sem registro de venda"
            >
                <ModalItemSemVenda fecharModal={fecharModalSV} onConfirm={handleConfirm} />
            </Dialog>
        </div>
    )
}

export default React.memo(FormDadosItems);