import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { ModalEditarEnderecoEntrega } from "./ModalEditarEnderecoEntrega";
import { ModalEnderecos } from "@/components/ModalEnderecos";
import { MakoListagem } from "@/components/MakoListagem";
import { Dropdown } from "@/components/Dropdown";
import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoTime } from "@/components/MakoTime";
import { MakoInputQuantidadeSku } from "@/components/MakoInputs/MakoInputQuantidadeSku";
import { MakoDropdownEstoques } from "@/components/MakoInputs/MakoDropdownEstoques";
import { BotaoDelete } from "@/components/BotaoDelete";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { TIPOS_ENTREGA_PRODUTO } from "@/assets/constants/vendas";
import { dataToStr } from "@/assets/util/datas";
import { axiosGet, axiosPost } from "@/services/http";
import useVenda from "@/hooks/useVenda";
import useToast from "@/hooks/useToast";
import { parseNumber } from "@/assets/helpers/number";
import { formatarCasasDecimais } from "@/assets/util/util";

export const PrevisaoEntregaForm = () => {
    const [itensVenda, setItensVenda] = useState([]);
    const [enderecos, setEnderecos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingItens, setLoadingItens] = useState(false);
    const [bloqueiaCentroEstocagem, setBloqueiaCentroEstocagem] = useState(false);
    const listagemRef = useRef(null);
    const modalEnderecosRef = useRef(null);
    const modalRef = useRef(null);
    const { dadosBasicos, vendaFinalizada, bloqueiaItens } = useVenda();
    const { showWarning, showError } = useToast();

    const bloqueiaPrevisaoEntrega = vendaFinalizada || bloqueiaItens;

    const formik = useFormik({
        initialValues: {
            tipo_entrega: null,
            item_venda: [],
            retirado_em: null,
            centro_estocagem: null,
            rota_entrega: null,
            quantidade: 0,
            quantidade_restante: 0,
            data_previsao: new Date(),
            hora_previsao: new Date(),
            endereco_entrega: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                tipo_entrega: Yup.string()
                    .required("O campo 'entrega / retirada' é obrigatório.")
                    .typeError("Selecione será será uma entrega ou retirada."),
                item_venda: Yup.array().min(1, "Você precisa selecionar pelo menos um produto."),
                centro_estocagem: Yup.string()
                    .required("O campo 'centro de estocagem' é obrigatório.")
                    .typeError("Selecione um centro de estocagem."),
                quantidade:
                    values.item_venda.length === 1
                        ? Yup.number().when("item_venda", {
                              is: (val) => val && val.movimenta_cubagem,
                              then: Yup.number()
                                  .min(0.000001, "O campo 'quantidade' não pode ser ZERO.")
                                  .test({
                                      test: (val) => val <= values.quantidade_restante,
                                      message: "A quatidade informada excede a quantidade restante.",
                                  }),
                              otherwise: Yup.number()
                                  .min(1, "O campo 'quantidade' não pode ser ZERO.")
                                  .test({
                                      test: (val) => val <= values.quantidade_restante,
                                      message: "A quatidade informada excede a quantidade restante.",
                                  }),
                          })
                        : Yup.number()
                              .default(0)
                              .test({
                                  test: (val) => val <= values.quantidade_restante,
                                  message: "A quatidade informada excede a quantidade restante.",
                              }),
                data_previsao: Yup.date()
                    .nullable()
                    .when("tipo_entrega", {
                        is: (val) => val === "E",
                        then: Yup.date()
                            .typeError("Informe uma data válida.")
                            .required("O campo 'data da entrega' é obrigatório."),
                    }),
                endereco_entrega: Yup.number()
                    .nullable()
                    .when("tipo_entrega", {
                        is: (val) => val === "E",
                        then: Yup.number()
                            .required("O campo 'endereço' é obrigatório.")
                            .typeError("Selecione o endereço de entrega."),
                    }),
                retirado_em: Yup.date()
                    .nullable()
                    .when("tipo_entrega", {
                        is: (val) => val === "R",
                        then: Yup.date()
                            .typeError("Informe uma data válida.")
                            .required("O campo 'data da retirada' é obrigatório."),
                    }),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            let entregas = [];
            if (values.item_venda.length > 1) {
                entregas = values.item_venda.map((item) => ({
                    ...values,
                    retirar: values.tipo_entrega === "R",
                    quantidade: formatarCasasDecimais(parseNumber(item.quantidade) - item.quantidade_entregue, 6),
                    item_venda: item.id,
                    sku: item.sku.id,
                    data_previsao: dataToStr(values.data_previsao, "yyyy-MM-dd"),
                    hora_previsao: dataToStr(values.hora_previsao, "HH:mm:ss"),
                    retirado_em: dataToStr(values.retirado_em, "yyyy-MM-dd HH:mm:ss"),
                }));
            } else {
                entregas.push({
                    ...values,
                    retirar: values.tipo_entrega === "R",
                    item_venda: values.item_venda[0].id,
                    sku: values.item_venda[0].sku.id,
                    data_previsao: dataToStr(values.data_previsao, "yyyy-MM-dd"),
                    hora_previsao: dataToStr(values.hora_previsao, "HH:mm:ss"),
                    retirado_em: dataToStr(values.retirado_em, "yyyy-MM-dd HH:mm:ss"),
                });
            }
            setLoading(true);
            const { status } = await axiosPost(`/vendas/previsoes-entregas-itens-vendas/`, entregas);
            setLoading(false);
            if (status === 201) {
                formik.resetForm();
                listagemRef.current?.buscarDados();
                const _itens = itensVenda.map((item) => {
                    const entrega = entregas.find((el) => el.item_venda === item.id);
                    if (entrega) {
                        return {
                            ...item,
                            quantidade_entregue: item.quantidade_entregue + entrega.quantidade,
                        };
                    }
                    return item;
                });
                setItensVenda(_itens);
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não foi possível gravar as previsões de entrega.",
                    life: 3000,
                });
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const listarItensVenda = useCallback(async () => {
        const params = {
            venda: dadosBasicos?.id,
            query: "{id,descricao_derivada,sku,quantidade,quantidade_entregue,centro_estocagem}",
        };
        setLoadingItens(true);
        const { status, data } = await axiosGet("/vendas/itens-vendas/", { params });
        setLoadingItens(false);
        if (status === 200) {
            setItensVenda(data.results);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível listar os itens da venda.",
                life: 3000,
            });
        }
    }, [dadosBasicos, showError]);

    const listarEnderecosCliente = useCallback(async () => {
        if (dadosBasicos) {
            const params = {
                query: "{id,identificacao,logradouro,numero,bairro,cep}",
                perfil__id: dadosBasicos.cliente.id,
            };
            const response = await axiosGet("/pessoas/enderecos-perfis/?ativo=true", { params });
            if (response.status === 200) {
                setEnderecos(response.data.results);
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos listar os endereços do cliente.",
                    life: 3000,
                });
            }
        }
    }, [dadosBasicos, showError]);

    useEffect(() => {
        listarItensVenda();
        listarEnderecosCliente();
    }, [listarItensVenda, listarEnderecosCliente]);

    const enderecoTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{`${option.identificacao} - ${option.logradouro}, ${option.numero} - ${option.bairro} - ${option.cep}`}</div>
            </div>
        );
    };

    const enderecoSelecionadoTemplate = (option, props) => {
        if (option) return enderecoTemplate(option);
        return <span>{props.placeholder}</span>;
    };

    const dataEntregaBodyTemplate = (rowData) => {
        const data = rowData.retirar ? rowData.retirado_em : rowData.data_previsao;
        return <span>{dataToStr(data, "dd/MM/yyyy")}</span>;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-map-marker"
                    disabled={vendaFinalizada}
                    tooltip="Alterar endereço de entrega"
                    tooltipOptions={{ position: "left" }}
                    onClick={() => modalRef.current?.abrirModal(rowData)}
                    className="p-button-rounded p-button-help p-mr-2"
                />
                <BotaoDelete
                    url="/vendas/previsoes-entregas-itens-vendas/"
                    objetoId={rowData.id}
                    classNames="p-mr-2 p-mt-2"
                    msgToastErroExclusao="Desculpe, não foi possível excluir a previsão de entrega."
                    onDelete={() => {
                        const _itens = itensVenda.map((item) => {
                            if (item.id === rowData.item_venda) {
                                return {
                                    ...item,
                                    quantidade_entregue: item.quantidade_entregue - parseNumber(rowData.quantidade),
                                };
                            }
                            return item;
                        });
                        setItensVenda(_itens);
                        listagemRef.current?.buscarDados();
                    }}
                />
            </div>
        );
    };

    const colunas = [
        { field: "sku.descricao_derivada", header: "Produto / Mercadoria" },
        { field: "centro_estocagem.nome", header: "Estoque" },
        { field: "quantidade", header: "Qtd.", decimal: true, style: { maxWidth: "8%" } },
        {
            field: "data_previsao",
            header: "Data",
            style: { maxWidth: "8%" },
            action: (e) => dataEntregaBodyTemplate(e),
        },
        {
            field: "hora_previsao",
            header: "Hora",
            style: { maxWidth: "8%" },
        },
        { field: "endereco_entrega.logradouro", header: "Endereço de entrega" },
        {
            field: "action",
            header: "Ações",
            className: "p-text-center",
            style: { maxWidth: "10%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    const handleChangeItemVenda = ({ value }) => {
        if (value instanceof Array) {
            const ceItensVenda = value.map((v) => v.centro_estocagem).filter(Boolean);
            const setCeItensVenda = [...new Set(ceItensVenda)];
            if (setCeItensVenda.length > 1) {
                showWarning({
                    summary: "Aviso!",
                    detail: "Essa combinação de itens não é possível, devido aos centros de estocagens.",
                    life: 4000,
                });
            } else {
                formik.setFieldValue("item_venda", value);
                if (setCeItensVenda.length === 1) {
                    setBloqueiaCentroEstocagem(true);
                    formik.setFieldValue("centro_estocagem", ceItensVenda[0]);
                } else {
                    setBloqueiaCentroEstocagem(false);
                    formik.setFieldValue("centro_estocagem", null);
                }
                if (value.length === 1) {
                    const [itemVenda] = value;
                    const qtdRestante = parseNumber(itemVenda.quantidade) - itemVenda.quantidade_entregue;
                    formik.setFieldValue("quantidade_restante", qtdRestante);
                }
            }
        } else {
            formik.setFieldValue("item_venda", []);
            setBloqueiaCentroEstocagem(false);
            formik.setFieldValue("centro_estocagem", null);
        }
    };

    return (
        <>
            {vendaFinalizada && bloqueiaItens && (
                <h5 className="p-error p-mt-0">O estágio atual não permite alterar a previsão de entrega</h5>
            )}
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-8">
                        <label htmlFor="item-venda">Produto / mercadoria *</label>
                        <MultiSelect
                            id="item-venda"
                            name="item_venda"
                            placeholder="Selecione"
                            emptyMessage="Nenhum produto listado."
                            disabled={loadingItens || bloqueiaPrevisaoEntrega}
                            options={itensVenda}
                            optionDisabled={(option) =>
                                parseNumber(option.quantidade_entregue) >= parseNumber(option.quantidade)
                            }
                            optionLabel="descricao_derivada"
                            selectedItemsLabel="{0} produtos selecionados"
                            maxSelectedLabels={2}
                            filter
                            filterBy="descricao_derivada"
                            showClear
                            value={formik.values.item_venda}
                            onChange={handleChangeItemVenda}
                            className={classNames({ "p-invalid": formik.errors.item_venda })}
                        />
                        {formik.errors.item_venda && <small className="p-error">{formik.errors.item_venda}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="quantidade">Quantidade **</label>
                        <MakoInputQuantidadeSku
                            id="quantidade"
                            name="quantidade"
                            disabled={formik.values.item_venda.length > 1}
                            permiteFracionario={formik.values.item_venda[0]?.sku.item.movimenta_cubagem}
                            value={formik.values.quantidade}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.quantidade })}
                        />
                        {formik.errors.quantidade && <small className="p-error">{formik.errors.quantidade}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="quantidade-restante">Quantidade restante</label>
                        <MakoInputQuantidadeSku
                            id="quantidade-restante"
                            name="quantidade_restante"
                            disabled
                            permiteFracionario={formik.values.item_venda[0]?.sku.item.movimenta_cubagem}
                            value={formik.values.quantidade_restante}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="tipo-entrega">Entrega / Retirada *</label>
                        <Dropdown
                            id="tipo-entrega"
                            name="tipo_entrega"
                            options={TIPOS_ENTREGA_PRODUTO}
                            value={formik.values.tipo_entrega}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.tipo_entrega })}
                        />
                        {formik.errors.tipo_entrega && <small className="p-error">{formik.errors.tipo_entrega}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-8">
                        <label htmlFor="estoque">Centro de estocagem *</label>
                        <MakoDropdownEstoques
                            id="estoque"
                            name="centro_estocagem"
                            disabled={bloqueiaCentroEstocagem}
                            optionValue="id"
                            empresaId={dadosBasicos?.empresa.id}
                            value={formik.values.centro_estocagem}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.centro_estocagem })}
                        />
                        {formik.errors.centro_estocagem && (
                            <small className="p-error">{formik.errors.centro_estocagem}</small>
                        )}
                    </div>
                </div>
                {formik.values.tipo_entrega === "E" && (
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="rota-entrega">Rota de entrega</label>
                            <Dropdown
                                id="rota-entrega"
                                name="rota_entrega"
                                url="/vendas/rotas-entrega/"
                                // setObjects={setRotasEntregas}
                                optionValue="id"
                                optionLabel="descricao"
                                value={formik.values.rota_entrega}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-5">
                            <label htmlFor="endereco">Endereço de entrega *</label>
                            <div className="p-inputgroup">
                                <Dropdown
                                    id="endereco"
                                    name="endereco_entrega"
                                    options={enderecos}
                                    optionValue="id"
                                    optionLabel="logradouro"
                                    valueTemplate={enderecoSelecionadoTemplate}
                                    itemTemplate={enderecoTemplate}
                                    value={formik.values.endereco_entrega}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.endereco_entrega })}
                                />
                                <Button
                                    type="button"
                                    icon="pi pi-pencil"
                                    className="p-button-warning"
                                    tooltip="Gerenciar endereços"
                                    tooltipOptions={{ position: "left" }}
                                    onClick={() => modalEnderecosRef.current?.abrirModal(dadosBasicos?.cliente.id)}
                                />
                            </div>
                            {formik.errors.endereco_entrega && (
                                <small className="p-error">{formik.errors.endereco_entrega}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="data-entrega">Data da entrega *</label>
                            <MakoCalendar
                                id="data-entrega"
                                name="data_previsao"
                                minDate={dadosBasicos ? dadosBasicos.data : null}
                                valueCalendar={formik.values.data_previsao}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.data_previsao })}
                            />
                            {formik.errors.data_previsao && (
                                <small className="p-error">{formik.errors.data_previsao}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="hora-entrega">Hora da entrega</label>
                            <MakoTime
                                id="hora-entrega"
                                name="hora_previsao"
                                valueTime={formik.values.hora_previsao}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                )}
                {formik.values.tipo_entrega === "R" && (
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="retirado-em">Data da retirada *</label>
                            <MakoCalendar
                                id="retirado-em"
                                name="retirado_em"
                                minDate={dadosBasicos ? dadosBasicos.data : null}
                                valueCalendar={formik.values.retirado_em}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.retirado_em })}
                            />
                            {formik.errors.retirado_em && (
                                <small className="p-error">{formik.errors.retirado_em}</small>
                            )}
                        </div>
                    </div>
                )}
                <CamposObrigatorios className="p-m-0" />
                <CamposObrigatorios className="p-mt-0">
                    ** Campo obrigatório apenas se tipo de lançamento for parcial.
                </CamposObrigatorios>
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <Button
                            type="submit"
                            icon="pi pi-plus"
                            label="Gravar previsão de entrega"
                            disabled={bloqueiaPrevisaoEntrega}
                            loading={loading}
                            className="p-mr-2 p-mb-2"
                        />
                    </div>
                </div>
            </form>
            <MakoListagem
                ref={listagemRef}
                colunas={colunas}
                urlPesquisa={`/vendas/previsoes-entregas-itens-vendas/?item_venda__venda=${dadosBasicos?.id}`}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
            />
            <ModalEditarEnderecoEntrega ref={modalRef} onSuccess={() => listagemRef.current?.buscarDados()} />
            <ModalEnderecos ref={modalEnderecosRef} onSave={() => listarEnderecosCliente()} />
        </>
    );
};
