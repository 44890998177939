import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import { Toast } from "primereact/toast";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { axiosGet } from "@/services/http";
import * as pd from "@/assets/util/persistenciaDjango";
import useProduto from "@/hooks/useProduto";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { MakoDropdownCategoriasHierarquicas } from "@/components/MakoDropdownCategoriasHierarquicas";
import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";
import { Dropdown } from "@/components/Dropdown";
import useToast from "@/hooks/useToast";
import { BotaoDelete } from "@/components/BotaoDelete";
import { Tag } from "primereact/tag";

const CategoriaForm = () => {
    const [produto, setProduto] = useState(null);
    const [inserirCategoria, setInserirCategoria] = useState(false);
    const [grupoObrigatorio, setGrupoObrigatorio] = useState(false);
    const [listaGrupoCategorias, setListaGrupoCategorias] = useState(null);
    const { handleInfo, dadosBasicos } = useProduto();
    const toastRef = useRef(null);
    const listagemRef = useRef(null);
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showWarning } = useToast();

    const { setFieldValue, resetForm, ...formik } = useFormik({
        enableReinitialize: true,
        initialValues: {
            categoria: null,
            categoria_selecionada: null,
            grupo: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                categoria_selecionada: Yup.number().required("O campo é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const { status, data } = await handleInfo(
                "/produtos/categorias-skus/",
                { ...values, sku: produto, categoria: values.categoria.id },
                values.id ? pd.OP_CRUD_DJANGO.editar : pd.OP_CRUD_DJANGO.novo
            );

            if (status === 201) {
                esconderCategoria();
                listagemRef.current?.buscarDados();
            } else if (status === 400) {
                if (data?.non_field_errors)
                    showWarning({
                        summary: "Atenção",
                        detail: data.non_field_errors,
                        life: 5000,
                    });
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const handleStatus = async (categoria) => {
        const { status } = await handleInfo(
            "/produtos/categorias-skus/",
            { id: categoria.id, ativo: !categoria.ativo },
            pd.OP_CRUD_DJANGO.editar
        );
        if (status === 200) listagemRef.current?.buscarDados();
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_PRODUTOS_EDITAR]}
                    componente={Button}
                    icon="pi pi-cog"
                    className="p-button-rounded p-button-help p-mr-2 p-mb-1"
                    onClick={() => handleStatus(rowData)}
                    tooltip="Alterar status"
                    tooltipOptions={{ position: "left" }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_PRODUTOS_EDITAR]}
                    componente={BotaoDelete}
                    url="/produtos/categorias-skus/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={<span>Deseja realmente remover a categoria do produto?</span>}
                    classNames="p-mb-1"
                    tooltip="Deletar"
                    tooltipOptions={{ position: "left" }}
                    msgToastErroExclusao="A categoria não pode ser removida."
                    onDelete={() => {
                        listagemRef.current?.buscarDados();
                        showSuccess({
                            summary: "Sucesso!",
                            detail: "Categoria removida com sucesso.",
                            life: 3000,
                        });
                    }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <Dropdown
                id="sku"
                name="sku"
                url={`/produtos/sku?item__id=${dadosBasicos.id}`}
                style={{ width: "300px", paddingLeft: "2%" }}
                placeholder="Selecione um produto"
                optionValue="id"
                optionLabel="descricao_complementar"
                showClear={false}
                className="p-mr-2"
                value={produto}
                onChange={(e) => setProduto(e.target.value)}
            />
            <Button
                label="Inserir"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={(e) => setInserirCategoria(true)}
                disabled={!produto}
            />
        </>
    );

    const statusBodyTemplate = (status) => {
        if (status) return <Tag severity="success" value="ATIVO" />;
        return <Tag severity="danger" value="DESATIVADO" />;
    };

    const colunas = [
        { field: "sku.codigo", header: "Cód. Produto", style: { minWidth: "5%" } },
        { field: "categoria.nome", header: "Categoria" },
        { field: "grupo.descricao", header: "Grupo" },
        { field: "categoria.descricao", header: "Descrição" },
        {
            field: "ativo",
            header: "Status",
            action: (e) => statusBodyTemplate(e.ativo),
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "12%" },
        },
    ];

    const esconderCategoria = () => {
        setInserirCategoria(false);
        resetForm();
    };

    const listarGrupoCategorias = useCallback(async () => {
        showLoading();
        const obrigatorio = await axiosGet("/produtos/categorias-grupos?obrigatorio=true&limit=1&query={id}");
        const resposta = await axiosGet("/produtos/categorias-grupos?limit=10000&query={id,descricao, obrigatorio}");
        hideLoading();

        if (resposta.status === 200) setListaGrupoCategorias(resposta.data.results);
        if (obrigatorio.data.results.length > 0) setGrupoObrigatorio(true);
        else setGrupoObrigatorio(false);
    }, [showLoading, hideLoading]);

    useEffect(() => {
        listarGrupoCategorias();
    }, [listarGrupoCategorias]);

    const onChangeCategoria = useCallback(
        (e) => {
            setFieldValue("categoria", e);
        },
        [setFieldValue]
    );

    return (
        <>
            <Toast ref={toastRef} />
            <MakoListagem
                ref={listagemRef}
                urlPesquisa={`/produtos/categorias-skus?sku__id=${produto ? `${produto}` : "0"}`}
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                configTabela={{ paginator: true, lazy: true }}
            />
            <Dialog
                header="Inserir categoria"
                visible={inserirCategoria}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "30vw", display: "block" }}
                onHide={() => esconderCategoria()}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid ">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="grupo">Grupo</label>
                            <Dropdown
                                id="grupo"
                                name="grupo"
                                placeholder="Selecione um grupo"
                                options={listaGrupoCategorias}
                                optionValue="id"
                                optionLabel="descricao"
                                filter
                                filterBy="descricao"
                                showClear
                                value={formik.values.grupo}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="categoria">Categoria *</label>
                            <MakoDropdownCategoriasHierarquicas
                                id="categoria"
                                name="categoria"
                                grupoId={formik.values?.grupo?.id}
                                value={formik.values.categoria_selecionada}
                                getCategoriaCompleta={onChangeCategoria}
                                onChange={(e) => setFieldValue("categoria_selecionada", e.value)}
                                className={classNames({ "p-invalid": formik.errors.categoria_selecionada })}
                            />
                            {formik.errors.categoria_selecionada && (
                                <small className="p-error">{formik.errors.categoria_selecionada}</small>
                            )}
                        </div>
                    </div>

                    <p>
                        <b>* Campos obrigatórios.</b>
                    </p>
                    {grupoObrigatorio ? (
                        <div>
                            <h6 style={{ color: "#ff5252" }}>
                                <b>ATENÇÃO:</b> Existem grupos obrigatórios cadastrados no sistema.
                            </h6>
                        </div>
                    ) : null}
                    <br />
                    <div className="p-grid p-justify-end" style={{ paddingRight: "2%" }}>
                        <Button
                            className="p-button-danger p-button-text"
                            type="reset"
                            icon="pi pi-times"
                            label="Fechar"
                            onClick={() => esconderCategoria()}
                        />
                        <Button type="submit" icon="pi pi-check-circle" label="Inserir" />
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export default CategoriaForm;
