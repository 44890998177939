import React, { useEffect, useRef, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { Button } from 'primereact/button';

import { MakoListagemHierarquica } from '@/components/MakoListagemHierarquica';
import { MakoControleAcesso } from '@/components/MakoControleAcesso';
import { BotaoDelete } from '@/components/BotaoDelete';
import { PageBase } from "@/components/PageBase";

import permissoes from '@/assets/constants/permissoes';

import useEmpresa from '@/hooks/useEmpresa';
import useToast from '@/hooks/useToast';

const BASE_URL = "/pessoas/hierarquia-funcional-menu/";

export const HierarquiaFuncionalPage = () => {
    const [url, setUrl] = useState(BASE_URL);
    const { empresaSelecionadaId } = useEmpresa();
    const { showWarning } = useToast();

    const history = useHistory();
    const listagemRef = useRef();

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_CADASTRO_HIERARQUIAFUNCIONAL_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar Cadastro de hierarquia"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/gestao/cadastros/hierarquia-funcional/form",
                            state: { hierarquia: { ...rowData.data, cargo: rowData.data.cargo.id} },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_CADASTRO_HIERARQUIAFUNCIONAL_EXCLUIR]}
                    componente={BotaoDelete}
                    url="/pessoas/hierarquia-funcional/"
                    objetoId={rowData.data.id}
                    classNames="p-mb-1"
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir a hierarquia de código<b>{rowData.data.id}</b>?
                        </span>
                    }
                    msgToastErroExclusao="A hierarquia não pode ser excluída."
                    tooltip="Deletar hierarquia"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { width: "15%" },
            expander: true
        },
        {
            field: "pessoa.nome",
            header: "Pessoa",
        },
        {
            field: "cargo.nome",
            header: "Cargo",
        },
        {
            field: "action",
            header: "Ações",
            style: { width: "8%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.GESTAO_CADASTRO_HIERARQUIAFUNCIONAL_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/gestao/cadastros/hierarquia-funcional/form")}
            />
        </>
    );

    useEffect(() => {
        if(!empresaSelecionadaId) {
            showWarning({
                summary: "Alerta :(",
                detail: "Por favor, selecione uma empresa.",
                life: 3000,
            })
        }
    }, [empresaSelecionadaId])

    return (
        <PageBase>
            <MakoListagemHierarquica
                ref={listagemRef}
                titulo="Hierarquia Funcional"
                colunas={colunas}
                cabecalho={cabecalhoTabela}
                urlPesquisa={url + `?empresa=${empresaSelecionadaId}`} 
                tabelaConfig={{
                    scrollable: true
                }}
            />
        </PageBase>
    )
}