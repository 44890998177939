import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import { Toast } from "primereact/toast";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { axiosGet } from "@/services/http";
import useProduto from "@/hooks/useProduto";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import classNames from "classnames";
import * as Yup from "yup";
import { TIPO_MOVIMENTACAO_UNIDADE_MEDIDA_CHOICE } from "@/assets/constants/constants";
import { ProdutoUnidadeMedidaForm } from "../../Cadastros/UnidadeMedida/form";
import { Dropdown } from "@/components/Dropdown";
import * as pd from "@/assets/util/persistenciaDjango";
import { Tag } from "primereact/tag";
import { BotaoDelete } from "@/components/BotaoDelete";
import useToast from "@/hooks/useToast";

const UnidadeMedidaSkuForm = () => {
    const { handleUnidade, dadosBasicos } = useProduto();
    const [inserirUnidade, setInserirUnidade] = useState(false);
    const [listaUnidades, setListaUnidades] = useState(null);
    const [produto, setProduto] = useState("");
    const [cadastroUnidade, setCadastroUnidade] = useState(false);
    const toastRef = useRef(null);
    const listagemRef = useRef(null);
    const { showSuccess } = useToast();

    const { setFieldValue, resetForm, setValues, ...formik } = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: null,
            unidade: null,
            tipo_mov_und_medida: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                tipo_mov_und_medida: Yup.string().required("O campo 'tipo de movimentação' é obrigatório."),
                unidade: Yup.number().required("O campo 'unidade de medida' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            const resposta = await handleUnidade(
                { ...values, sku: values.sku?.id || produto },
                values.id ? pd.OP_CRUD_DJANGO.editar : pd.OP_CRUD_DJANGO.novo
            );
            if (
                (resposta instanceof Object && (resposta.status === 201 || resposta.status === 200)) ||
                resposta instanceof Array
            ) {
                resetForm();
                esconderInserirUnidade();
                listagemRef.current?.buscarDados();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const handleStatus = async (unidade) => {
        const { status } = await handleUnidade({ id: unidade.id, ativo: !unidade.ativo }, pd.OP_CRUD_DJANGO.editar);
        if (status === 200) listagemRef.current?.buscarDados();
    };

    const editarUnidade = (unidade) => {
        setValues({ ...unidade, unidade: unidade.unidade.id, tipo_mov_und_medida: unidade.tipo_mov_und_medida.id });
        setInserirUnidade(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_PRODUTOS_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro"
                    onClick={() => editarUnidade(rowData)}
                    tooltipOptions={{ position: "left" }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_PRODUTOS_EDITAR]}
                    componente={Button}
                    icon="pi pi-cog"
                    className="p-button-rounded p-button-help p-mr-2 p-mb-1"
                    onClick={() => handleStatus(rowData)}
                    tooltip="Alterar status"
                    tooltipOptions={{ position: "left" }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_PRODUTO_PRODUTOS_EDITAR]}
                    componente={BotaoDelete}
                    url="/produtos/unidades-medida-sku/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={<span>Deseja realmente remover a unidade de medida?</span>}
                    classNames="p-mb-1"
                    tooltip="Deletar"
                    tooltipOptions={{ position: "left" }}
                    msgToastErroExclusao="O unidade de medida não pode ser removida."
                    onDelete={() => {
                        listagemRef.current?.buscarDados();
                        showSuccess({
                            summary: "Sucesso!",
                            detail: "Unidade de medida removida com sucesso.",
                            life: 3000,
                        });
                    }}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <Dropdown
                id="sku"
                name="sku"
                url={`/produtos/sku?item__id=${dadosBasicos.id}`}
                style={{ width: "300px", paddingLeft: "2%" }}
                placeholder="Selecione um produto"
                optionValue="id"
                optionLabel="descricao_complementar"
                showClear={false}
                value={produto}
                onChange={(e) => setProduto(e.target.value)}
                className="p-mr-2"
            />
            <Button
                label="Inserir"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setInserirUnidade(true)}
                disabled={!produto}
            />
        </>
    );

    const statusBodyTemplate = (status) => {
        if (status) return <Tag severity="success" value="ATIVO" />;
        return <Tag severity="danger" value="DESATIVADO" />;
    };

    const colunas = [
        { field: "sku.codigo", header: "Cód. Produto", style: { minWidth: "5%" } },
        { field: "sku.descricao_reduzida", header: "Descrição produto", style: { minWidth: "25%" } },
        { field: "unidade.nome", header: "Unidade de medida" },
        { field: "tipo_mov_und_medida.descricao", header: "Tipo de movimentação", style: { minWidth: "20%" } },
        {
            field: "ativo",
            header: "Status",
            action: (e) => statusBodyTemplate(e.ativo),
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "10%" },
        },
    ];

    const listarUnidades = useCallback(async () => {
        const resposta = await axiosGet("/produtos/unidades-medida?limit=300");
        if (resposta.status === 200) setListaUnidades(resposta.data.results);
    }, []);

    useEffect(() => {
        listarUnidades();
    }, [listarUnidades]);

    const esconderInserirUnidade = () => {
        setInserirUnidade(false);
        resetForm();
    };

    const esconderCadastroUnidade = () => {
        listarUnidades();
        setCadastroUnidade(false);
    };

    return (
        <>
            <Toast ref={toastRef} />
            <MakoListagem
                ref={listagemRef}
                urlPesquisa={`/produtos/unidades-medida-sku?sku__id=${produto ? `${produto}` : "0"}`}
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                configTabela={{
                    scrollable: true,
                    paginator: true,
                    lazy: true,
                }}
            />
            <Dialog
                header="Cadastro unidade de medida"
                visible={inserirUnidade}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "35vw", display: "block" }}
                onHide={() => esconderInserirUnidade()}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid ">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="unidade">Unidade de Medida *</label>
                            <div className="p-inputgroup">
                                <Dropdown
                                    id="unidade"
                                    name="unidade"
                                    placeholder="Selecione..."
                                    optionValue="id"
                                    optionLabel="nome"
                                    filter
                                    filterBy="nome"
                                    options={listaUnidades}
                                    value={formik.values.unidade}
                                    onChange={formik.handleChange}
                                    showClear={false}
                                    className={classNames({ "p-invalid": formik.errors.unidade })}
                                />
                                <MakoControleAcesso
                                    permissao={[permissoes.PRODUTO_PRODUTO_UNIDADEMEDIDA_INCLUIR]}
                                    componente={Button}
                                    type="button"
                                    icon="pi pi-plus"
                                    className="p-button-success"
                                    tooltip="Cadastrar unidade de medida"
                                    tooltipOptions={{ position: "left" }}
                                    onClick={() => setCadastroUnidade(true)}
                                />
                            </div>
                            {formik.errors.unidade && <small className="p-error">{formik.errors.unidade}</small>}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid ">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="tipo_mov_und_medida">Tipo de movimentação *</label>
                            <Dropdown
                                id="tipo_mov_und_medida"
                                placeholder="Selecione..."
                                name="tipo_mov_und_medida"
                                optionValue="id"
                                optionLabel="descricao"
                                filter
                                filterBy="descricao"
                                options={TIPO_MOVIMENTACAO_UNIDADE_MEDIDA_CHOICE}
                                value={formik.values.tipo_mov_und_medida}
                                onChange={formik.handleChange}
                                showClear={false}
                                className={classNames({ "p-invalid": formik.errors.tipo_mov_und_medida })}
                            />
                            {formik.errors.tipo_mov_und_medida && (
                                <small className="p-error">{formik.errors.tipo_mov_und_medida}</small>
                            )}
                        </div>
                    </div>
                    <p>
                        <b>* Campos obrigatórios.</b>
                    </p>
                    <div className="p-grid p-justify-end p-mr-1">
                        <Button
                            className="p-button-danger p-button-text"
                            type="reset"
                            icon="pi pi-times"
                            label="Fechar"
                            onClick={() => esconderInserirUnidade()}
                        />
                        <Button type="submit" icon="pi pi-check-circle" label="Inserir" />
                    </div>
                </form>
            </Dialog>
            <Dialog
                header={"Cadastrar unidade de medida"}
                visible={cadastroUnidade}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "30vw", display: "block" }}
                onHide={() => esconderCadastroUnidade()}
            >
                <ProdutoUnidadeMedidaForm unidade closeDialog={esconderCadastroUnidade} />
            </Dialog>
        </>
    );
};

export default UnidadeMedidaSkuForm;
