import React, { useCallback, useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { MakoInputMotorista } from "@/components/MakoInputs/MakoInputMotorista";
import { MakoCalendarTime } from "@/components/MakoCalendarTime";
import MakoListagem from "@/components/MakoListagem";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";

import { axiosPost } from "@/services/http";
import { dataToStr } from "@/assets/util/datas";

export const FormConfirmarEntrega = ({ previsoes = [], onCancel, onFinish }) => {
    const [previsoesEditadas, setPrevisoesEditadas] = useState(previsoes);
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const [permitirSubmit, setPermitirSubmit] = useState(false);

    const { showError, showSuccess } = useToast();
    const [formatDocumento] = useFormatCNPJCPF();

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: {
            id: null,
            datahora_entrega: null,
            nome_recebedor: "",
            entregador_id: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                datahora_entrega: Yup.date()
                    .required("O campo 'data/hora entrega' é obrigatório")
                    .typeError("Informe uma 'data/hora entrega' válida"),
                nome_recebedor: Yup.string()
                    .required("O campo 'nome recenedor' é obrigatório")
                    .typeError("Informe um 'recebedor' válido"),
                entregador_id: Yup.object()
                    .required("O campo 'entregador' é obrigatório.")
                    .typeError("Informe um 'entregador' válido."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            setPrevisoesEditadas((prev) => {
                const index = prev.findIndex((item) => item.id === values.id);
                const item = {
                    ...prev[index],
                    nome_recebedor: values.nome_recebedor,
                    datahora_entrega: values.datahora_entrega,
                    entregador_id: values.entregador_id,
                };
                prev[index] = item;
                setPermitirSubmit(!prev.some((item) => !item?.datahora_entrega));
                return [...prev];
            });
            formik.resetForm();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const confirmarEntregas = useCallback(async () => {
        let previsoes = [];
        previsoesEditadas.map((previsao) => {
            previsoes.push({
                id: previsao.id,
                datahora_entrega: dataToStr(previsao.datahora_entrega, "yyyy-MM-dd HH:mm:ss"),
                nome_recebedor: previsao.nome_recebedor,
                entregador_id: previsao.entregador_id.id,
            });
        });
        showLoading();
        const { status } = await axiosPost("/vendas/finalizar-previsoes-entrega/", { previsoes });
        hideLoading();
        if (status === 201) {
            showSuccess({
                summary: "Sucesso!",
                detail: "Entregas confirmadas com sucesso!.",
                life: 1500,
            });
            formik.resetForm();
            onFinish();
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não foi possível processar sua requisição.",
                life: 3000,
            });
        }
    }, [previsoesEditadas, showLoading, hideLoading, showError, showSuccess]);

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    onClick={() => setValues(rowData)}
                />
            </div>
        );
    };

    const colunas = [
        {
            field: "cliente",
            header: "Cliente",
            action: ({ cliente }) => `${cliente.nome} - ${formatDocumento(cliente.identificacao)}`,
        },
        {
            field: "entregador_id.nome",
            header: "Entregador",
        },
        {
            field: "nome_recebedor",
            header: "Recebedor",
        },
        {
            field: "datahora_entrega",
            style: { width: "10%" },
            header: "Data/hora",
            align: "center",
            dateFormat: "dd/MM/yyyy HH:mm",
        },
        {
            field: "rota_entrega.descricao",
            header: "Rota",
        },
        {
            field: "sku",
            style: { width: "8%" },
            header: "Código",
            action: ({ sku }) => sku.id,
        },
        {
            field: "sku",
            header: "Descrição",
            action: ({ sku }) => sku.descricao_reduzida,
        },
        {
            field: "quantidade",
            style: { width: "5%" },
            header: "Qtd.",
        },
        {
            field: "action",
            header: "Ações",
            style: { width: "10%" },
            action: (rowData) => actionBodyTemplate(rowData),
        },
    ];

    const fecharModal = () => {
        onCancel();
        formik.resetForm();
    };

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                {formik.values.id && (
                    <h6>
                        Cliente {formik.values?.cliente?.nome} -{" "}
                        {formatDocumento(formik.values?.cliente?.identificacao)}:
                    </h6>
                )}
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="entregador_id">Entregador *</label>
                        <MakoInputMotorista
                            id="entregador_id"
                            name="entregador_id"
                            value={formik.values.entregador_id}
                            disabled={!formik.values?.id}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.entregador_id })}
                        />
                        {formik.errors.entregador_id && (
                            <small className="p-error">{formik.errors.entregador_id}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="nome_recebedor">Nome recebedor *</label>
                        <InputText
                            id="nome_recebedor"
                            name="nome_recebedor"
                            placeholder="Informe um nome..."
                            onChange={formik.handleChange}
                            value={formik.values.nome_recebedor}
                            disabled={!formik.values?.id}
                            autoComplete="off"
                            maxLength={100}
                            className={classNames({ "p-invalid": formik.errors.nome_recebedor })}
                        />
                        {formik.errors.nome_recebedor && (
                            <small className="p-error">{formik.errors.nome_recebedor}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="datahora_entrega">Data/hora entrega *</label>
                        <MakoCalendarTime
                            id="datahora_entrega"
                            name="datahora_entrega"
                            valueCalendar={formik.values.datahora_entrega}
                            disabled={!formik.values?.id}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.datahora_entrega })}
                        />
                        {formik.errors.datahora_entrega && (
                            <small className="p-error">{formik.errors.datahora_entrega}</small>
                        )}
                    </div>
                    <div className="p-col-12 p-md-2 p-mt-5">
                        <Button
                            type="submit"
                            label="Confirmar"
                            className="p-mr-2 p-mb-2 p-button-info"
                            disabled={!formik.values?.datahora_entrega}
                        />
                    </div>
                </div>
            </form>
            <MakoListagem titulo={"Entregas"} colunas={colunas} dadosLocal={previsoesEditadas} />
            <div className="p-grid p-mt-2">
                <div className="p-col-12 p-md-6">
                    <Button
                        icon={`pi ${loading ? "pi-spin pi-spinner" : "pi pi-check"}`}
                        type="submit"
                        label="Salvar"
                        className="p-mr-2 p-mb-2 p-button-success"
                        disabled={!permitirSubmit || loading}
                        onClick={confirmarEntregas}
                    />
                    <Button
                        type="button"
                        label="Cancelar"
                        className="p-button-danger p-mb-2"
                        onClick={fecharModal}
                        disabled={loading}
                    />
                </div>
            </div>
        </div>
    );
};
