import React, { useRef, useEffect } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoCalendar } from "@/components/MakoCalendar";
import { axiosPost, axiosPut } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import { dataToStr } from "@/assets/util/datas";

export const CompetenciaMovEstoqueForm = (props) => {
    const toastRef = useRef(null);
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            nome: "",
            data_inicio: null,
            data_fim: null,
            ativa: true,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo 'nome' é obrigatório."),
                data_inicio: Yup.date()
                    .required("O campo 'data inicial' é obrigatório.")
                    .typeError("Informe uma data válida."),
                data_fim: Yup.date()
                    .required("O campo 'data final' é obrigatório.")
                    .min(formik.values.data_inicio, "A data final não pode ser inferior a data inicial.")
                    .typeError("Informe uma data válida."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            values.data_inicio = dataToStr(values.data_inicio, "yyyy-MM-dd");
            values.data_fim = dataToStr(values.data_fim, "yyyy-MM-dd");

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost("/produtos/competencias-movimentacoes-estoques/", values);
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Competência de estoque cadastrada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        history.push("/estoque/cadastros/competencias-movimentacoes-estoque");
                    }, 1500);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(`/produtos/competencias-movimentacoes-estoques/${values.id}/`, values);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Competência de estoque alterada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push("/estoque/cadastros/competencias-movimentacoes-estoque");
                    }, 1500);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
            }
        }
    }

    useEffect(() => {
        if (props.location.state) {
            setValues(props.location.state);
        }
    }, [props.location.state, setValues]);

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <h3>
                        {!formik.values.id
                            ? "Nova competência de movimentação de estoque"
                            : "Manutenção de competência de movimentação de estoque"}
                    </h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="nome">Nome *</label>
                                <InputText
                                    id="nome"
                                    name="nome"
                                    value={formik.values.nome}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.nome })}
                                />
                                {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="data-inicio">Data inicial *</label>
                                <MakoCalendar
                                    id="data-inicio"
                                    name="data_inicio"
                                    valueCalendar={formik.values.data_inicio}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.data_inicio })}
                                />
                                {formik.errors.data_inicio && (
                                    <small className="p-error">{formik.errors.data_inicio}</small>
                                )}
                            </div>

                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="data-fim">Data final *</label>
                                <MakoCalendar
                                    id="data-fim"
                                    name="data_fim"
                                    valueCalendar={formik.values.data_fim}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.data_fim })}
                                />
                                {formik.errors.data_fim && <small className="p-error">{formik.errors.data_fim}</small>}
                            </div>
                            <div className="p-field-checkbox p-mt-4 p-col-12 p-md-3">
                                <Checkbox
                                    inputId="ativa"
                                    name="ativa"
                                    checked={formik.values.ativa}
                                    onChange={formik.handleChange}
                                />
                                <label htmlFor="ativa">{formik.values.ativa ? "Ativa" : "Inativa"}</label>
                            </div>
                        </div>
                        <p>* Campos obrigatórios.</p>
                        <div className="p-grid p-col-12 p-md-6">
                            <Button label="Gravar" type="submit" className="p-button-info p-mr-2" />
                            <Button
                                label="Cancelar"
                                type="button"
                                className="p-button-danger p-mr-2"
                                onClick={() => history.push("/estoque/cadastros/competencias-movimentacoes-estoque")}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
