import React, { useRef, useState, useCallback } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useHistory } from "react-router-dom";
import MakoAutoComplete from "@/components/MakoAutoComplete";
import { Dialog } from "primereact/dialog";
import MakoListagem from "@/components/MakoListagem";
import { ProdutoGradeAtributosFormModal } from "./formModal";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import { ConfirmDialog } from "primereact/confirmdialog";
import { axiosDelete } from "@/services/http";
import useToast from "@/hooks/useToast";
import useLoading from "@/hooks/useLoading";

const URL_VOLTAR = "/produto/cadastros/grade-atributos";

export const ProdutoGradeAtributosForm = () => {
    const history = useHistory();
    const toastRef = useRef(null);
    const searchAtributoRef = useRef(null);
    const [atributo, setAtributo] = useState("");
    const [deleteDialogTable, setDeleteDialogTable] = useState(false);
    const [gradeDialog, setGradeDialog] = useState(false);
    const [gradeAtributo, setGradeAtributo] = useState(null);
    const listagemRef = useRef(null);
    const [urlSearch, setUrlSearch] = useState("");
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();

    const deleteParametro = async () => {
        showLoading();
        const resposta = await axiosDelete(`/produtos/grades-atributos/${atributo.id}`);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            showSuccess({
                summary: "Sucesso!",
                detail: "Grade Atributo deletado com sucesso!",
                life: 2000,
            });
            listagemRef.current?.buscarDados();
        } else {
            showError({
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
        esconderDeleteDialog();
    };

    const cancelar = () => {
        history.push(URL_VOLTAR);
    };

    const setAtributoFieldValue = useCallback(async (e) => {
        setAtributo(e);
        if (e.value !== undefined) {
            searchAtributoGrade(e.value);
        }
    }, []);

    const searchAtributoGrade = (id) => {
        setUrlSearch(`/produtos/grades-atributos/?atributo__id=${id}`);
        listagemRef.current?.buscarDados();
    };

    const confirmarDelete = (gradeatributo) => {
        setAtributo(gradeatributo);
        setDeleteDialogTable(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_CADASTRO_GRADEATRIBUTO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar Cadastro de Atributo"
                    tooltipOptions={{ position: "left" }}
                    type="button"
                    onClick={() => exibirGradeDialog(rowData)}
                />
                <MakoControleAcesso
                    permissao={[permissoes.PRODUTO_CADASTRO_GRADEATRIBUTO_EXCLUIR]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    type="button"
                    onClick={() => confirmarDelete(rowData)}
                    tooltip="Deletar Atributo"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const actionValorNumerico = (rowData) => {
        let valor;
        if (rowData.valor_numerico === true) {
            valor = "Sim";
        } else {
            valor = "Não";
        }
        return (
            <>
                <div className="p-text-normal">{valor}</div>
            </>
        );
    };

    const actionCalculaMedida = (rowData) => {
        let valor;
        if (rowData.calcula_medida === true) {
            valor = "Sim";
        } else {
            valor = "Não";
        }
        return (
            <>
                <div className="p-text-normal">{valor}</div>
            </>
        );
    };

    const actionUsaPreco = (rowData) => {
        let valor;
        if (rowData.usa_preco_medida_servico === true) {
            valor = "Sim";
        } else {
            valor = "Não";
        }
        return (
            <>
                <div className="p-text-normal">{valor}</div>
            </>
        );
    };

    const actionService = (rowData) => {
        let service;
        if (rowData.servico_venda !== null) {
            service = rowData.servico_venda.nome;
        } else {
            service = "";
        }

        return (
            <>
                <div className="p-text-normal">{service}</div>
            </>
        );
    };

    const esconderGradeDialog = () => {
        setGradeDialog(false);
        listagemRef.current?.buscarDados();
    };

    const exibirGradeDialog = (grade) => {
        setGradeAtributo(grade);
        setGradeDialog(true);
    };

    const colunas = [
        { field: "id", header: "Código", style: { minWidth: "120px" } },
        { field: "atributo.nome", header: "Atributo", style: { minWidth: "250px" } },
        { field: "codigo", header: "Grade", style: { minWidth: "200px" } },
        { field: "opcoes_valor", header: "Opções", style: { minWidth: "350px" } },
        {
            field: "valor_numerico",
            header: "Valor Numérico",
            action: (e) => actionValorNumerico(e),
            style: { minWidth: "200px" },
        },
        {
            field: "calcula_medida",
            header: "Calcula Medida",
            action: (e) => actionCalculaMedida(e),
            style: { minWidth: "120px" },
        },
        {
            field: "servico_venda",
            header: "Serviço",
            action: (e) => actionService(e),
            style: { minWidth: "200px" },
        },
        {
            field: "usa_preco_medida_servico",
            header: "Usa Preço Medida Serviço",
            action: (e) => actionUsaPreco(e),
            style: { minWidth: "250px" },
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "200px" },
            frozen: true,
            alignFrozen: "right",
        },
    ];

    const esconderDeleteDialog = () => {
        setDeleteDialogTable(false);
    };

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <h4>Adicionar Grade Atributo</h4>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6">
                            <MakoAutoComplete
                                ref={searchAtributoRef}
                                label="Atributo *"
                                placeholder="Comece a digitar dados de atributos para pesquisar..."
                                namefield="atributo"
                                urlsearch="produtos/atributos/?limit=100&search="
                                optionAdicionar
                                minCaracteresBusca={1}
                                setValue={(e) => setAtributoFieldValue(e)}
                                tip="Escolher um atributo, após perquisar,
                                    clique em uma atributo para selecionar e continuar o cadastro."
                                focus
                            />
                        </div>
                    </div>
                    <>
                        {atributo?.value ? (
                            <Button
                                type="button"
                                label="Clique aqui para adicionar valor ao atributo"
                                title="Adicionar valor ao atributo"
                                className="p-button-sm p-col-align-end p-mb-2"
                                onClick={() => setGradeDialog(true)}
                            ></Button>
                        ) : (
                            <p>Pesquisar um atributo para continuar.</p>
                        )}
                        {urlSearch !== "" ? (
                            <MakoListagem
                                ref={listagemRef}
                                colunas={colunas}
                                urlPesquisa={urlSearch}
                                responsiva
                                configTabela={{
                                    paginator: true,
                                    lazy: true,
                                    scrollable: true,
                                    frozenWidth: "0.5vw",
                                }}
                            />
                        ) : null}
                    </>
                    <Dialog
                        header={`Adicionar Grade Atributo (${atributo.label})`}
                        visible={gradeDialog}
                        breakpoints={{ "960px": "75vw" }}
                        style={{ width: "65vw", display: "block" }}
                        onHide={() => setGradeDialog()}
                    >
                        <ProdutoGradeAtributosFormModal
                            listagemRef={listagemRef}
                            atributo={atributo?.value}
                            esconderDialog={esconderGradeDialog}
                            gradeAtributo={gradeAtributo}
                        />
                    </Dialog>
                    <br></br>
                    <div className="p-grid p-col-12 p-md-6">
                        <Button
                            label="Voltar"
                            type="reset"
                            className="p-button-danger p-mr-2 p-mb-6"
                            onClick={cancelar}
                        />
                    </div>
                </div>
                <ConfirmDialog
                    visible={deleteDialogTable}
                    onHide={() => esconderDeleteDialog()}
                    header="Confirmação"
                    message={
                        atributo && (
                            <span>
                                {"Deseja realmente excluir a grade atributo código: "}
                                <b>{atributo.codigo}</b>?
                            </span>
                        )
                    }
                    icon="pi pi-info-circle p-mr-3"
                    accept={deleteParametro}
                    acceptLabel="Sim"
                    acceptClassName="p-button-danger"
                    reject={esconderDeleteDialog}
                    rejectLabel="Não"
                />
            </div>
        </div>
    );
};
