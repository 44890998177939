import React, { useRef, useState } from "react";
import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Tag } from "primereact/tag";
import { axiosPost } from "@/services/http";
import MakoListagem from "@/components/MakoListagem";
import useLoading from "@/hooks/useLoading";
import { MakoCalendar } from "@/components/MakoCalendar";
import { parseData } from "@/assets/util/datas";

export const ConsultarSaldosPage = () => {
    const [item, setItem] = useState(null);
    const [data, setData] = useState(null);
    const [exibirConsulta, setExibirConsulta] = useState(false);
    const [totais, setTotais] = useState(null);
    const toastRef = useRef(null);
    const listagemRef = useRef(null);
    const { showLoading, hideLoading } = useLoading();

    async function carregarTotais() {
        showLoading();
        const resposta = await axiosPost(`/produtos/calcular-saldo/${item?.id}/`);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 201) {
            setTotais(resposta.data.result);
        }
    }

    const autoCompleteSkuTemplate = (valor) => {
        valor.label = `${valor.codigo} - ${valor.descricao_derivada}`;
        return `${valor.codigo} - ${valor.descricao_derivada}`;
    };

    const verificaEstoque = (sku) => {
        if (sku.quantidade > 0) return <Tag severity="success" value="Em estoque" style={{ width: "100px" }} />;
        return <Tag severity="danger" value="Sem estoque" style={{ width: "100px" }} />;
    };

    const limparConsulta = () => {
        setExibirConsulta(false);
        setItem(null);
    };

    const exibirResultado = () => {
        setExibirConsulta(true);
        carregarTotais();
    };

    const colunas = [
        { field: "estoque_empresa.nome", header: "Estoque" },
        { field: "id", header: "Código" },
        { field: "centro_estocagem.nome", header: "Centro Estocagem" },
        { field: "data", header: "Data" },
        { field: "quantidade", header: "Quantidade" },
        { action: (e) => verificaEstoque(e) },
    ];

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <h4>Consultar Saldos</h4>
                <div className="card">
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-9">
                            <label>Selecione um item *</label>
                            <MakoAutoComplete
                                id="sku"
                                name="sku"
                                placeholder="Busque pela descrição ou código... (min 4 caractéres)"
                                minCaracteresBusca={4}
                                value={item}
                                onChange={(e) => setItem(e.value)}
                                itemTemplate={autoCompleteSkuTemplate}
                                field="label"
                                urlSearch={"/produtos/sku?search="}
                                disabled={exibirConsulta}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="data-saldo">Data</label>
                            <MakoCalendar
                                id="data-saldo"
                                name="saldo"
                                disabled
                                valueCalendar={data}
                                onChange={(e) => setData(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="p-grid p-col-12 p-md-6">
                        <Button
                            label="Consultar"
                            type="button"
                            icon="pi pi-fw pi-search"
                            className="p-button-info"
                            onClick={() => exibirResultado()}
                            disabled={!item || typeof item !== "object"}
                        />
                        <Button
                            label="Limpar pesquisa"
                            type="reset"
                            icon="pi pi-fw pi-trash"
                            className="p-button-warning p-ml-2"
                            onClick={() => limparConsulta()}
                            disabled={!item}
                        />
                    </div>
                </div>
                {exibirConsulta ? (
                    <div>
                        <MakoListagem
                            titulo={`Resultados - Total: ${totais?.saldo_real || 0} - Total previsto: ${
                                totais?.saldo_previsto || 0
                            } (${totais?.saldo_oc || 0} registros de ordens de compras)`}
                            ref={listagemRef}
                            colunas={colunas}
                            urlPesquisa={`/produtos/saldos-sku?sku=${item.id}${
                                data ? `&data=${parseData(data, "yyyy-MM-dd")}` : ""
                            }`}
                            responsiva
                            botaoExportar
                            configTabela={{
                                paginator: true,
                                lazy: true,
                                rowGroupMode: "rowspan",
                                groupRowsBy: "estoque_empresa.nome",
                                sortMode: "single",
                                sortField: "estoque_empresa.nome",
                            }}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};
