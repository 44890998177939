import React, { useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { axiosPost, axiosPut } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import { Dropdown } from "primereact/dropdown";
import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";
import { useFormik } from "formik";
import classNames from "classnames";
import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";
import * as Yup from "yup";

export const ProdutoGradeAtributosFormModal = ({ gradeAtributo, atributo, esconderDialog }) => {
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);

    const { setFieldValue, setValues, resetForm, ...formik } = useFormik({
        initialValues: gradeAtributo?.id
            ? {
                  ...gradeAtributo,
                  id: gradeAtributo.id,
                  atributo: gradeAtributo.atributo.id,
                  servico_venda: gradeAtributo.servico_venda?.id || null,
              }
            : {
                  atributo: null,
                  codigo: "",
                  opcoes_valor: "",
                  valor_numerico: false,
                  servico_venda: null,
                  usa_preco_medida_servico: false,
                  calcula_medida: false,
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit() {
        try {
            const { values } = formik;

            const formSchema = Yup.object().shape({
                codigo: Yup.string().required("O campo 'código' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(`/produtos/grades-atributos/`, {
                    ...values,
                    atributo: atributo,
                });
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Atributo cadastrado com sucesso!",
                        life: 2000,
                    });
                    esconderDialog();
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(`/produtos/grades-atributos/${values.id}/`, values);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Atributo alterado com sucesso!",
                        life: 2000,
                    });
                    esconderDialog();
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    useEffect(() => {
        setFieldValue("opcoes_valor", "");
    }, [formik.values.valor_numerico, setFieldValue]);

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <form onSubmit={handleSubmit}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="codigo">Código *</label>
                            <InputText
                                id="codigo"
                                name="codigo"
                                value={formik.values.codigo}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.codigo })}
                                tooltip="Definir um código para grade atributo"
                                autoComplete="off"
                            />
                            {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="valor_numerico">Valor númerico? *</label>
                            <Dropdown
                                id="valor_numerico"
                                value={formik.values.valor_numerico}
                                options={SIM_NAO_BOOLEAN}
                                placeholder="Selecione..."
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.valor_numerico })}
                                optionLabel="label"
                                optionValue="id"
                                tooltip="Definir tooltip aqui."
                            />
                            {formik.errors.valor_numerico && (
                                <small className="p-error">{formik.errors.valor_numerico}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="calcula_medida ">Calcula medida?</label>
                            <Dropdown
                                id="calcula_medida"
                                value={formik.values.calcula_medida}
                                options={SIM_NAO_BOOLEAN}
                                placeholder="Selecione..."
                                onChange={formik.handleChange}
                                optionLabel="label"
                                optionValue="id"
                                tooltip="Definir tooltip aqui."
                                disabled={!formik.values.valor_numerico}
                            />
                            {formik.errors.calcula_medida && (
                                <small className="p-error">{formik.errors.calcula_medida}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-5">
                            <label htmlFor="usa_preco_medida_servico">Vlr. serviço conforme medida?</label>
                            <Dropdown
                                id="usa_preco_medida_servico"
                                value={formik.values.usa_preco_medida_servico}
                                options={SIM_NAO_BOOLEAN}
                                placeholder="Selecione..."
                                onChange={formik.handleChange}
                                optionLabel="label"
                                optionValue="id"
                                tooltip="Definir tooltip aqui."
                            />
                            {formik.errors.usa_preco_medida_servico && (
                                <small className="p-error">{formik.errors.usa_preco_medida_servico}</small>
                            )}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="codigo">Serviço de venda</label>
                            <MakoAutoComplete
                                id="servico_venda"
                                name="servico_venda"
                                placeholder="Comece a digitar dados de serviços para pesquisar..."
                                minCaracteresBusca={4}
                                value={formik.values.servico_venda}
                                onChange={formik.handleChange}
                                tooltip="Escolher um serviço, após perquisar
                                clique em uma atributo para selecionar."
                                field="nome"
                                urlSearch={
                                    formik.values.usa_preco_medida_servico
                                        ? "vendas/servicos-vendas/?limit=100&preco_simples=0.00&search="
                                        : "vendas/servicos-vendas/?limit=100&preco_medida=0.00&search="
                                }
                                className={classNames({ "p-invalid": formik.errors.servico_venda })}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="opcoes_valor">Opções</label>
                            <InputText
                                id="opcoes_valor"
                                value={formik.values.opcoes_valor}
                                onChange={formik.handleChange}
                                tooltip="Defina as opções que serão disponibilizadas para atribuição ao produto (importante separa-las por '|')."
                                disabled={formik.values.valor_numerico}
                            />
                            <p className="p-mt-2">
                                Cada opção deve ser delimitada pelo separador '|', exemplo: "opcao_1|opcao_2|opcao_3
                            </p>
                        </div>
                    </div>

                    <p>
                        <b>* Campos obrigatórios.</b>
                    </p>
                    <div className="p-grid p-col-12 p-md-6">
                        <Button
                            label="Gravar"
                            type="button"
                            onClick={() => handleSubmit()}
                            className="p-button-info p-mr-2"
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};
