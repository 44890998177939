import React, { useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { MakoBuscaSkuPersonalizada } from "@/components/MakoBuscaSkuPersonalizada";
import { MakoDropdownEstoques } from "@/components/MakoInputs/MakoDropdownEstoques";
import { MakoSelecionarEmails } from "@/components/MakoSelecionarEmails";
import { MakoCalendar } from "@/components/MakoCalendar";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import useRelatorio from "@/hooks/useRelatorio";

import { RELATORIO_PRODUTOS_MOVIMENTACAOPRODUTOS } from "@/assets/constants/relatorios";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { dataToStr } from "@/assets/util/datas";

export const RelatorioMovimentacaoProdutos = () => {
    const [visible, setVisible] = useState(false);
    const [emails, setEmails] = useState(null);

    const { solicitarRelatorio } = useRelatorio();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            data_start: null,
            data_end: null,
            id_sku: null,
            centro_estocagem: null,
        },
        onSubmit: handleSubmit,
    });

    const filtrosTemplate = [
        {
            key: "id_sku",
            label: "Produto",
            type: TIPOS_FILTROS_TEMPLATE.SKU,
        },
        {
            key: ["data_start", "data_end"],
            label: "Periodo",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: "centro_estocagem",
            label: "Centro estocagem",
            path: "descricao",
        },
    ];

    async function handleSubmit(values = null) {
        try {
            const formSchema = Yup.object().shape({
                data_start: Yup.date().nullable().typeError("Informe um 'data  inicial' válido"),
                data_end: Yup.date().when("data_start", {
                    is: (val) => !!val,
                    then: Yup.date()
                        .min(values.data_start || new Date(), "O campo 'data final' não pode ser anterior a inicial")
                        .typeError("Informe uma 'data final' válida"),
                    otherwise: Yup.date().nullable(),
                }),
                id_sku: Yup.object()
                    .required("O campo 'produto' é obrigatório")
                    .typeError("Informe um 'prduto' válido"),
                centro_estocagem: Yup.object().nullable().typeError("Informe um 'centro estocagem' válido"),
            });
            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, dadosValidados);
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.id_sku instanceof Object) dadosValidados.id_sku = dadosValidados.id_sku.id;
            if (dadosValidados.centro_estocagem instanceof Object)
                dadosValidados.centro_estocagem = dadosValidados.centro_estocagem.id;
            if (dadosValidados.data_start instanceof Date)
                dadosValidados.data_start = dataToStr(dadosValidados.data_start, "yyyy-MM-dd");
            if (dadosValidados.data_end instanceof Date)
                dadosValidados.data_end = dataToStr(dadosValidados.data_end, "yyyy-MM-dd");

            let filtros = {};
            if (dadosValidados) {
                Object.keys(dadosValidados).forEach((key) => {
                    if (dadosValidados[key]) filtros[key] = dadosValidados[key];
                });
            }
            solicitarRelatorio({
                chave: RELATORIO_PRODUTOS_MOVIMENTACAOPRODUTOS,
                emails,
                filtros,
                filtros_template: filtrosTemplateAplicados,
            });
            formik.resetForm();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const handleConfirm = (emails) => {
        fecharModal();
        if (emails && emails.length > 0) {
            handlePreSubmit(emails);
        }
    };

    const handlePreSubmit = (emails = []) => {
        setEmails(emails);
        formik.handleSubmit();
    };

    const fecharModal = () => {
        setVisible(() => false);
    };

    const limparFiltro = () => {
        formik.resetForm();
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <h5>Filtro para emissão de relatório de movimentação de produtos</h5>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="id_sku">Mercadoria</label>
                            <MakoBuscaSkuPersonalizada
                                id="id_sku"
                                name="id_sku"
                                skuValue={formik.values.id_sku}
                                skuChange={(e) => setFieldValue("id_sku", e)}
                                skuError={formik.errors.id_sku}
                            />
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-3">
                            <label htmlFor="centro_estocagem">Centro estocagem</label>
                            <MakoDropdownEstoques
                                id="centro_estocagem"
                                name="centro_estocagem"
                                value={formik.values.centro_estocagem}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.centro_estocagem })}
                            />
                            {formik.errors.centro_estocagem && (
                                <small className="p-error">{formik.errors.centro_estocagem}</small>
                            )}
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="data_start">Periodo</label>
                            <MakoCalendar
                                id="data_start"
                                name="data_start"
                                valueCalendar={formik.values.data_start}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.data_start })}
                            />
                            {formik.errors.data_start && <small className="p-error">{formik.errors.data_start}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-2 p-mt-4" style={{ paddingTop: "5px" }}>
                            <MakoCalendar
                                id="data_end"
                                name="data_end"
                                valueCalendar={formik.values.data_end}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.data_end })}
                            />
                            {formik.errors.data_end && <small className="p-error">{formik.errors.data_end}</small>}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid"></div>
                    <div className="p-grid p-col-12 p-md-12 p-mt-2">
                        <Button
                            label="Gerar PDF"
                            icon="pi pi-file-pdf"
                            type="button"
                            onClick={() => handlePreSubmit()}
                            className="p-button-info p-mr-2"
                        />
                        <Button
                            type="button"
                            onClick={() => setVisible(true)}
                            label="Enviar por email"
                            icon="pi pi-envelope"
                            className="p-button-info p-mr-2"
                        />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            onClick={() => limparFiltro()}
                            className="p-button-warning p-mr-2"
                        />
                    </div>
                </div>
            </div>
            <Dialog
                visible={visible}
                blockScroll
                onHide={fecharModal}
                style={{ width: "60vw" }}
                header="Informar emails"
            >
                <MakoSelecionarEmails onCancel={fecharModal} onConfirm={handleConfirm} />
            </Dialog>
        </div>
    );
};
