import React, { useState } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { MakoSelecionarEmails } from "@/components/MakoSelecionarEmails";
import { MakoInputCliente } from "@/components/MakoInputs/MakoInputCliente";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import useRelatorio from "@/hooks/useRelatorio";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIO_VENDAS_HISTORICOSIMPLESCLIENTE } from "@/assets/constants/relatorios";

export const RelatorioHistoricoSimplesCliente = () => {
    const [visible, setVisible] = useState(false);
    const [emails, setEmails] = useState(null);

    const { solicitarRelatorio } = useRelatorio();

    const { setValues, ...formik } = useFormik({
        initialValues: {
            cliente: null,
        },
        onSubmit: handleSubmit,
    });

    const filtrosTemplate = [
        {
            key: "cliente",
            label: "Cliente",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
    ];

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                cliente: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'cliente' é obrigatório."),
                    })
                    .typeError("Informe uma 'cliente' válida"),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, dadosValidados);
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);

            if (dadosValidados.cliente !== null) {
                dadosValidados.cliente = dadosValidados.cliente.id;
            }

            let filtros = {};
            if (dadosValidados) {
                Object.keys(dadosValidados).forEach((key) => {
                    if (dadosValidados[key]) filtros[key] = dadosValidados[key];
                });
            }
            solicitarRelatorio({
                chave: RELATORIO_VENDAS_HISTORICOSIMPLESCLIENTE,
                emails,
                filtros,
                filtros_template: filtrosTemplateAplicados,
            });
            formik.resetForm();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const handleConfirm = (emails) => {
        fecharModal();
        if (emails && emails.length > 0) {
            handlePreSubmit(emails);
        }
    };

    const handlePreSubmit = (emails = []) => {
        setEmails(emails);
        formik.handleSubmit();
    };

    const fecharModal = () => {
        setVisible(() => false);
    };

    const limparFiltro = () => {
        formik.resetForm();
    };

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <h5>Filtro para emissão de relatório de histórico de cliente</h5>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-6 p-md-6">
                            <label htmlFor="cliente">Cliente</label>
                            <MakoInputCliente
                                id="cliente"
                                name="cliente"
                                value={formik.values.cliente}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.cliente })}
                            />
                            {formik.errors.cliente && <small className="p-error">{formik.errors.cliente}</small>}
                        </div>
                    </div>
                    <div className="p-grid p-col-12 p-md-12 p-mt-2">
                        <Button
                            label="Gerar PDF"
                            icon="pi pi-file-pdf"
                            type="button"
                            onClick={() => handlePreSubmit()}
                            className="p-button-info p-mr-2"
                        />
                        <Button
                            type="button"
                            onClick={() => setVisible(true)}
                            label="Enviar por email"
                            icon="pi pi-envelope"
                            className="p-button-info p-mr-2"
                        />
                        <Button
                            type="reset"
                            icon="pi pi-trash"
                            label="Limpar"
                            onClick={() => limparFiltro()}
                            className="p-button-warning p-mr-2"
                        />
                    </div>
                </div>
            </div>
            <Dialog
                visible={visible}
                blockScroll
                onHide={fecharModal}
                style={{ width: "60vw" }}
                header="Informar emails"
            >
                <MakoSelecionarEmails onCancel={fecharModal} onConfirm={handleConfirm} />
            </Dialog>
        </div>
    );
};
