import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import { differenceInDays } from "date-fns";
import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Toast } from "primereact/toast";
// import { ModalPagamentoEfetivado } from "./ModalPagamentoEfetivado";
import { axiosGet, axiosPost } from "@/services/http";
import { parseData, dataToStr } from "@/assets/util/datas";
import { formatarCasasDecimais } from "@/assets/util/util";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";
import MakoListagem from "@/components/MakoListagem";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useLoading from "@/hooks/useLoading";
import useEmpresa from "@/hooks/useEmpresa";
import { ModalPagamentoEfetivado } from "./modals/pagamentoEfetivadoModalForm";
import { ModalTemplateRateio } from "../../TemplateRateio/ModalTemplateRateio";
import { Dropdown } from "@/components/Dropdown";
import { parseNumber } from "@/assets/helpers/number";

export const EfetivacaoPagamentoPage = (props) => {
    const [parcelas, setParcelas] = useState([]);
    const [credor, setCredor] = useState({ doc: "", nome: "", empresa: null });
    const [submitted, setSubmitted] = useState(false);
    const [permitirPagamentos, setPermitirPagamentos] = useState(true);
    const [dataPagamento, setDataPagamento] = useState(new Date());
    const [formasPagamento, setFormasPagamento] = useState([]);
    const [totalOriginal, setTotalOriginal] = useState(0);
    const [totalPago, setTotalPago] = useState(0);
    const [templatesRateio, setTemplatesRateio] = useState([]);
    const [resultadoRateio, setResultadoRateio] = useState([]);
    const [templateRateioSelecionado, setTemplateRateioSelecionado] = useState(null);
    const [linhasExpandidas, setLinhasExpandidas] = useState(null);
    const modalRef = useRef(null);
    const toastRef = useRef(null);
    const modalTemplateRateioRef = useRef(null);
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const [formatarDocumento] = useFormatCNPJCPF();
    const { empresaSelecionadaId } = useEmpresa();

    async function registrarPagamentos() {
        setSubmitted(true);

        if (dataPagamento) {
            const pagamentos = parcelas.map((parcela) => {
                const formas_pagamento = parcela.forma_pagamento
                    .filter((e) => e?.id)
                    .map((forma) => {
                        return {
                            forma_pagamento: forma.id,
                            valor: formatarCasasDecimais(forma.valor),
                        };
                    });

                return {
                    id: parcela.id,
                    formas_pagamento,
                    documento: parcela.documento,
                    vencimento: parcela.vencimento,
                    data_pagamento: dataToStr(dataPagamento, "yyyy-MM-dd"),
                    template_rateio: parcela.template_rateio,
                    quantidade_parcelas: parcela.quantidade_parcelas,
                    numero_parcela: parcela.numero_parcela,
                    conta_financeira: parcela.conta_financeira,
                    valor: formatarCasasDecimais(parcela.valor),
                    em_aberto: formatarCasasDecimais(parcela.valor),
                    pago: formatarCasasDecimais(parcela.pago),
                };
            });

            showLoading();
            const resposta = await axiosPost("/financeiro/registrar-pagamentos/", { pagamentos });
            hideLoading();

            if (resposta.status === 200) {
                toastRef.current.show({
                    severity: "success",
                    summary: "Sucesso",
                    detail: "Pagamentos registrados com sucesso!",
                    life: 1500,
                });

                setSubmitted(false);
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não foi possível registrar os pagamentos!",
                    life: 3000,
                });
            }
        }
    }

    const calcularPagamentos = useCallback(() => {
        if (props.location.state) {
            setParcelas(
                props.location.state.map((pagamento) => {
                    const { vencimento, valor } = pagamento;
                    const saldo = parseFloat(valor);
                    const diasAtraso = differenceInDays(dataPagamento, parseData(vencimento)) - 1;

                    return {
                        ...pagamento,
                        forma_pagamento: [{ ...pagamento.forma_pagamento, valor: saldo }],
                        dias: diasAtraso,
                        valor: saldo,
                    };
                })
            );

            setCredor({
                nome: props.location.state[0].credor.nome,
                doc: formatarDocumento(props.location.state[0].credor.identificacao),
                empresa: props.location.state[0].empresa,
            });
        }
    }, [props.location.state, formatarDocumento, dataPagamento]);

    useEffect(() => {
        calcularPagamentos();
    }, [calcularPagamentos]);

    const habilitarPagamentos = useCallback(() => {
        if (!empresaSelecionadaId) {
            setPermitirPagamentos(false);
            toastRef.current.show({
                severity: "warn",
                summary: "Aviso!",
                detail: "Você não possui uma empresa selecionada.",
                life: 3000,
            });

            return null;
        }
    }, [empresaSelecionadaId]);

    useEffect(() => {
        habilitarPagamentos();
    }, [habilitarPagamentos]);

    const calcularTotais = useCallback(() => {
        let _original = 0,
            _pago = 0;

        parcelas.forEach((parcela) => {
            _original += parcela.valor;
            _pago += parcela.valor_pago | 0;
        });

        setTotalOriginal(_original);

        setTotalPago(_pago);
    }, [parcelas]);

    useEffect(() => {
        calcularTotais();
    }, [calcularTotais]);

    const editarPagamento = (novoPagamento) => {
        const index = parcelas.findIndex((p) => p.id === novoPagamento.id);

        let _parcelas = [...parcelas];
        _parcelas[index] = novoPagamento;

        setParcelas(_parcelas);
    };

    const listarFormasPagamentos = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/financeiro/formas-recebimentos/?limit=300");
        hideLoading();

        if (resposta.status === 200) {
            setFormasPagamento(
                resposta.data.results.filter((e) => e.tipo.id === 0 || e.tipo.id === 2 || e.tipo.id === 3)
            );
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro",
                detail: "Desculpe, não foi possível listar as formas de pagamento!",
                life: 3000,
            });
        }
    }, [showLoading, hideLoading]);

    useEffect(() => {
        listarFormasPagamentos();
    }, [listarFormasPagamentos]);

    const numeroParcelaBodyTemplate = (rowData) => {
        if (rowData.numero_parcela === 0) {
            return <span>ENTRADA</span>;
        }

        return <span>{`${rowData.numero_parcela}/${rowData.quantidade_parcelas}`}</span>;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    onClick={() => modalRef.current?.abrirModal(rowData)}
                />
            </div>
        );
    };

    const calcularValoresRateados = useCallback(() => {
        const templatesId = parcelas.map((parcela) => parcela.template_rateio);
        if (templatesId.length >= 1 && [...new Set(templatesId)].length === 1 && templatesRateio.length > 0) {
            const template_rateio = templatesRateio.find(
                (tr) => tr.id === (templateRateioSelecionado || templatesId[0])
            );

            if (template_rateio) {
                const template = {
                    descricao: template_rateio.descricao,
                    percentual_plano_conta_financeira: template_rateio.percentual_plano_conta_financeira,
                    percentual_plano_conta_contabil: template_rateio.percentual_plano_conta_contabil,
                    percentual_fase_projeto: template_rateio.percentual_fase_projeto,
                    percentual_centro_resultado: template_rateio.percentual_centro_resultado,
                };
                let data = template_rateio.templatedetalherateio_set.map((detalhe) => {
                    const percentual = parseNumber(detalhe.percentual);
                    const TIPOS_DETALHES = {
                        1: "plano_conta_contabil",
                        2: "plano_conta_financeira",
                        3: "centro_resultado",
                        4: "fase_projeto",
                    };
                    return {
                        tipo_detalhe: detalhe.tipo_detalhe.descricao,
                        detalhe: detalhe[TIPOS_DETALHES[detalhe.tipo_detalhe.id]].descricao,
                        percentual,
                        valor: 0,
                    };
                });
                parcelas.forEach((parcela) => {
                    data.forEach((detalhe) => {
                        detalhe.valor += (parcela.recebido * detalhe.percentual) / 100;
                    });
                });
                setResultadoRateio([{ ...template, detalhe: data }]);
            }
        }
    }, [parcelas, templateRateioSelecionado, templatesRateio]);

    useEffect(() => {
        calcularValoresRateados();
    }, [calcularValoresRateados]);

    const colunas = [
        { field: "documento", header: "Documento" },
        {
            field: "numero_parcela",
            header: "Parcela",
            style: { width: "8%" },
            action: (e) => numeroParcelaBodyTemplate(e),
        },
        { field: "data_emissao", header: "Emissão", style: { width: "9%" }, dateFormat: "dd/MM/yyyy" },
        { field: "vencimento", header: "Vencimento", style: { width: "9%" }, dateFormat: "dd/MM/yyyy" },
        { field: "dias", header: "Dias", style: { width: "5%" } },
        { field: "valor", header: "Valor", money: true },
        { field: "valor_pago", header: "Pago", money: true },
        {
            field: "actions",
            header: "Ações",
            style: { width: "6%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    const colunasTemplateRateio = [
        { expander: true, style: { width: "5rem" } },
        { field: "descricao", header: "Regra de rateio" },
        { field: "percentual_plano_conta_financeira", header: "% conta financeira", decimal: true },
        { field: "percentual_plano_conta_contabil", header: "% conta contábil", decimal: true },
        { field: "percentual_fase_projeto", header: "% fase de projeto", decimal: true },
        { field: "percentual_centro_resultado", header: "% centro de resultado", decimal: true },
    ];

    const rowClass = (rowData) => {
        return {
            "table-pagamentos-overdue": differenceInDays(dataPagamento, parseData(rowData.vencimento)) > 0,
            "table-pagamentos-pending": differenceInDays(dataPagamento, parseData(rowData.vencimento)) < 0,
        };
    };

    const rowExpansionTemplate = (data) => {
        const colunas = [
            { field: "tipo_detalhe", header: "Tipo de detalhe" },
            { field: "detalhe", header: "Detalhe" },
            {
                field: "percentual",
                header: "Percentual",
                decimal: true,
                action: (e) => <span>{`${e.percentual} %`}</span>,
            },
            { field: "valor", header: "Valor do rateio", money: true },
        ];
        return (
            <div className="p-p-3">
                <MakoListagem colunas={colunas} dadosLocal={data.detalhe} />
            </div>
        );
    };

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    <ModalPagamentoEfetivado
                        ref={modalRef}
                        formasPagamento={formasPagamento}
                        onConfirmar={(e) => editarPagamento(e)}
                    />
                    <ModalTemplateRateio
                        ref={modalTemplateRateioRef}
                        formProps={{
                            derivar: true,
                            location: { state: templatesRateio.find((tr) => tr.id === templateRateioSelecionado) },
                        }}
                    />
                    <h5>Pagando parcelas</h5>
                    <Panel header="Credor">
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="doc">CPF / CNPJ</label>
                                <InputText id="doc" disabled value={credor.doc} />
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="nome-completo">Nome completo</label>
                                <InputText id="nome-completo" disabled value={credor.nome} />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="data-pagamento">Data do pagamento *</label>
                                <MakoCalendar
                                    id="data-pagamento"
                                    minDate={new Date()}
                                    valueCalendar={dataPagamento}
                                    onChange={(e) => setDataPagamento(e.value)}
                                />
                                {submitted && !dataPagamento && (
                                    <small className="p-error">O campo 'data do pagamento' é obrigatório.</small>
                                )}
                            </div>
                        </div>
                    </Panel>
                    <Panel header="Resultado do rateio" toggleable collapsed className="p-mt-3">
                        <div className="p-inputgroup p-mb-2">
                            <Dropdown
                                url="/financeiro/templates-rateios/"
                                setObjects={setTemplatesRateio}
                                optionValue="id"
                                optionLabel="descricao"
                                disabled={resultadoRateio.length === 0}
                                showClear={false}
                                value={templateRateioSelecionado}
                                onChange={(e) => setTemplateRateioSelecionado(e.value)}
                            />
                            <Button
                                type="button"
                                label="Derivar"
                                icon="pi pi-copy"
                                onClick={() => modalTemplateRateioRef.current?.abrirModal()}
                            />
                        </div>
                        <MakoListagem
                            colunas={colunasTemplateRateio}
                            dadosLocal={resultadoRateio}
                            configTabela={{
                                expandedRows: linhasExpandidas,
                                onRowToggle: (e) => setLinhasExpandidas(e.data),
                                rowExpansionTemplate,
                            }}
                        />
                    </Panel>
                    <MakoListagem
                        titulo="Parcelas à pagar"
                        colunas={colunas}
                        dadosLocal={parcelas}
                        configTabela={{
                            paginator: true,
                            rowClassName: rowClass,
                        }}
                    />
                    <Divider align="center">
                        <b>Totais</b>
                    </Divider>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="original">Total valor original</label>
                            <MakoInputMoeda id="original" disabled valueMoeda={totalOriginal} />
                        </div>
                        <div className="p-field p-col-12 p-md-2">
                            <label htmlFor="total-pagamento">Total do pagamento</label>
                            <MakoInputMoeda id="total-pagamento" disabled valueMoeda={totalPago} />
                        </div>
                    </div>
                    <Button
                        label="Registrar pagamentos"
                        icon="pi pi-dollar"
                        disabled={!permitirPagamentos}
                        onClick={registrarPagamentos}
                    />
                    <Button
                        label="Cancelar"
                        icon="pi pi-times"
                        className="p-button-danger p-ml-2"
                        onClick={() => history.push("/financeiro/financeiro/pagamentos")}
                    />
                </div>
            </div>
        </div>
    );
};
