import React, { useMemo, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";
import { Dropdown } from "@/components/Dropdown";
import { TIPOS_BUSCA_SKU } from "@/assets/constants/constants";
import { parseNumber } from "@/assets/helpers/number";
import { VENDA_TIPO_BUSCA_PRODUTO_PADRAO } from "@/assets/constants/parametros";
import useParam from "@/hooks/useParam";

export const MakoBuscaSkuPersonalizadaDevolucao = ({
    disabledBusca,
    skuValue,
    skuChange,
    skuError,
    vendaId,
    statusItem = "F",
}) => {
    const { getParam } = useParam();
    const tipoBuscaPadrao = useMemo(() => {
        const param = getParam(VENDA_TIPO_BUSCA_PRODUTO_PADRAO);
        return parseNumber(param?.valor, 1);
    }, [getParam]);

    const [tipoBusca, setTipoBusca] = useState(tipoBuscaPadrao);
    const [codigoSKU, setCodigoSKU] = useState("");
    const [descricaoSKU, setDescricaoSKU] = useState("");

    const obterInformacoesSKU = (sku) => {
        if (sku instanceof Object) {
            let codigo = "";
            let descricao = "";
            if (vendaId) {
                codigo = sku.sku.codigo;
                descricao = sku.sku.descricao_complementar;
            } else {
                codigo = sku.codigo;
                descricao = sku.descricao_derivada;
            }
            setCodigoSKU(codigo);
            setDescricaoSKU(descricao);
        } else {
            setCodigoSKU("");
            setDescricaoSKU("");
        }
    };

    const onChangeSKU = (value) => {
        if (typeof skuChange === "function") skuChange(value);
        obterInformacoesSKU(value);
    };

    const urlPesquisa = useMemo(() => {
        if (vendaId) {
            return `/vendas/itens-vendas/?venda=${vendaId}&tipo_busca=${tipoBusca}&busca=`;
        } else {
            let url = `/produtos/sku-busca-personalizada/?tipo_busca=${tipoBusca}`;
            if (statusItem) url = `${url}&status=${statusItem}`;
            return `${url}&busca=`;
        }
    }, [vendaId, statusItem, tipoBusca]);

    const autoCompleteItemTemplate = (item) => {
        let codigo = "";
        let descricao = "";
        if (vendaId) {
            codigo = item.sku.codigo;
            descricao = item.sku.descricao_complementar;
        } else {
            codigo = item.codigo;
            descricao = item.descricao_derivada;
        }
        return (
            <div style={{ width: "40rem" }}>
                <div className="p-grid">
                    <div className="p-col-4">
                        <span>{`Código: `}</span>
                        <b>{codigo}</b>
                    </div>
                    <div className="p-col-8">
                        <span>{`Descrição: `}</span>
                        <b>{descricao}</b>
                    </div>
                </div>
            </div>
        );
    };

    const limparCampos = () => {
        if (typeof skuChange === "function") {
            skuChange(null);
            setCodigoSKU("");
            setDescricaoSKU("");
        }
    };

    return (
        <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12 p-md-2">
                <label htmlFor="tipo-busca">Campo de busca</label>
                <Dropdown
                    id="tipo-busca"
                    options={TIPOS_BUSCA_SKU}
                    disabled={disabledBusca}
                    value={tipoBusca}
                    onChange={(e) => setTipoBusca(e.value)}
                />
            </div>
            <div className="p-field p-col-12 p-md-3">
                <label htmlFor="sku">Buscar produto / mercadoria *</label>
                <div className="p-inputgroup">
                    <MakoAutoComplete
                        id="sku"
                        name="sku"
                        placeholder="Digite para buscar..."
                        disabled={disabledBusca}
                        value={skuValue}
                        delay={500}
                        onChange={(e) => onChangeSKU(e.value)}
                        itemTemplate={autoCompleteItemTemplate}
                        field="descricao_derivada"
                        urlSearch={urlPesquisa}
                        showEmptyMessage
                        emptyMessage="Produto não encontrado na venda."
                        className={classNames({ "p-invalid": skuError })}
                    />
                    <Button type="button" icon="pi pi-trash" onClick={limparCampos} className="p-button-warning" />
                </div>
                {skuError && <small className="p-error">{skuError}</small>}
            </div>
            <div className="p-field p-col-12 p-md-2">
                <label htmlFor="codigo">Código do produto</label>
                <InputText id="codigo" name="codigo" value={codigoSKU} disabled />
            </div>
            <div className="p-field p-col-12 p-md-5">
                <label htmlFor="descricao">Descrição</label>
                <InputText id="descricao" name="descricao" value={descricaoSKU} disabled />
            </div>
        </div>
    );
};
