import React, { useState, useRef, useEffect, useCallback } from "react";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { axiosGet, axiosPatch } from "@/services/http";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import MakoListagem from "@/components/MakoListagem";
import permissoes from "@/assets/constants/permissoes";
import useLoading from "@/hooks/useLoading";
import { BotaoDelete } from "@/components/BotaoDelete";
import { PageBase } from "@/components/PageBase";

const url = "/financeiro/versoes-planos-contas-contabeis/";

export const ContabilVersaoPlanoContasPage = () => {
    const [vincularEmpresa, setVincularEmpresa] = useState(false);
    const [redirecionar, setRedirecionar] = useState(null);
    const [versaoContas, setVersaoContas] = useState(null);
    const [listEmpresaVinculada, setListEmpresaVinculada] = useState([]);
    const [empresaVinculada, setEmpresaVinculada] = useState(null);
    const [exibirEmpresasVinc, setExibirEmpresasVinc] = useState(false);
    const [perfis, setPerfis] = useState([]);
    const { showLoading, hideLoading } = useLoading();
    const [filtro, setFiltro] = useState("");
    const toastRef = useRef(null);
    const listagemRef = useRef(null);
    const opcoesPesquisa = [
        { id: "todos", label: "Todos", value: "" },
        { id: "ativos", label: "Ativos", value: "true" },
        { id: "inativos", label: "Inativos", value: "false" },
    ];

    async function handleVincular() {
        try {
            if (empresaVinculada !== null) {
                const body = {
                    descricao: versaoContas.descricao,
                    empresas: { add: [empresaVinculada] },
                    inicio_vigencia: versaoContas.inicio_vigencia,
                    final_vigencia: versaoContas.final_vigencia,
                    ativo: versaoContas.ativo,
                };

                showLoading();
                const resposta = await axiosPatch(`${url}${versaoContas.id}/`, body);
                hideLoading();

                if (resposta.status === 201 || resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Versão de plano de contas cadastrada com sucesso!",
                        life: 1500,
                    });
                    listagemRef.current?.buscarDados();
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                toastRef.current.show({
                    severity: "warn",
                    summary: "Ops :(",
                    detail: "Selecione uma empresa para realizar o vinculo.",
                    life: 1500,
                });
            }
        } catch (error) {
            toastRef.current.show({
                severity: "error",
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 1500,
            });
            hideLoading();
        }
    }

    const getPerfis = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet("/pessoas/perfis?tipo_pessoa=PJ");
        hideLoading();

        if (resposta.status === 200) {
            setPerfis(resposta.data.results);
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Look",
                detail: "Desculpe :(\nNão conseguimos listar as versões do plano de contas.",
                life: 3000,
            });
        }
    }, [showLoading, hideLoading]);

    const esconderVincularEmpresa = () => {
        setVincularEmpresa(false);
        setEmpresaVinculada(null);
    };

    const exibirVincularEmpresa = (rowData) => {
        setVincularEmpresa(true);
        setVersaoContas(rowData);
    };

    const renderFooter = () => {
        return (
            <div>
                <Button
                    label="Cancelar"
                    icon="pi pi-times"
                    onClick={() => esconderVincularEmpresa()}
                    className="p-button-text"
                />
                <Button label="Confirmar" icon="pi pi-check" className="p-button" onClick={() => handleVincular()} />
            </div>
        );
    };

    const opcoesVersaoPlano = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.CONTABIL_CONTABIL_VERSAOPLANOCONTAS_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar Cadastro de versão do plano de contas"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        setRedirecionar({
                            pathname: "/contabil/versao-plano-contas/form",
                            state: { versaoContas: rowData },
                        })
                    }
                />

                <MakoControleAcesso
                    permissao={[permissoes.CONTABIL_CONTABIL_VERSAOPLANOCONTAS_EDITAR]}
                    componente={Button}
                    icon="pi pi-sitemap"
                    className="p-button-rounded p-button-info p-mr-2 p-mb-1"
                    onClick={() => exibirVincularEmpresa(rowData)}
                    tooltip="Vincular plano de contas a empresas"
                    tooltipOptions={{ position: "left" }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.CONTABIL_CONTABIL_VERSAOPLANOCONTAS_EXCLUIR]}
                    componente={BotaoDelete}
                    url={url}
                    objetoId={rowData.id}
                    classNames="p-mb-1"
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir a versão <b>{rowData.descricao}</b>?
                        </span>
                    }
                    msgToastErroExclusao="A versão de plano de contas não pode ser excluída."
                    tooltip="Deletar versão"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    async function desvincularEmpresa(empresa) {
        const body = {
            descricao: versaoContas.descricao,
            empresas: { remove: [empresa.id] },
            inicio_vigencia: versaoContas.inicio_vigencia,
            final_vigencia: versaoContas.final_vigencia,
            ativo: versaoContas.ativo,
        };

        showLoading();
        const resposta = await axiosPatch(`${url}${versaoContas.id}/`, body);
        hideLoading();

        if (resposta.status === 200 || resposta.status === 204) {
            toastRef.current.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Empresa desvinculada com sucesso!",
                life: 3000,
            });
            listagemRef.current?.buscarDados();
            setExibirEmpresasVinc(false);
        } else {
            toastRef.current.show({
                severity: "error",
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
            hideLoading();
        }
    }

    useEffect(() => {
        getPerfis();
    }, [getPerfis]);

    const opcoesEmpresasVinculadas = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.CONTABIL_CONTABIL_VERSAOPLANOCONTAS_EDITAR]}
                    componente={Button}
                    icon="pi pi-times"
                    className="p-button-rounded p-button-danger p-mr-2 p-mb-1"
                    onClick={() => desvincularEmpresa(rowData)}
                    tooltip="Desvincular empresa da versao do plano de contas"
                    tooltipOptions={{ position: "left" }}
                />
            </div>
        );
    };

    const filtroStatus = (status) => {
        listagemRef.current.filter(status.value, "ativo", "contains");
        setFiltro(status.value);
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.CONTABIL_CONTABIL_VERSAOPLANOCONTAS_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => setRedirecionar("/contabil/versao-plano-contas/form")}
            />

            <Dropdown
                id="opcoesPesquisa"
                name="opcoesPesquisa"
                style={{ width: "120px" }}
                value={filtro}
                options={opcoesPesquisa}
                optionLabel="label"
                placeholder={!filtro ? ("" ? filtro : "Todos") : filtro ? "Ativos" : false ? filtro : "Inativos"}
                onChange={filtroStatus}
            />
        </>
    );

    const exibirListaEmpresasVinc = (row) => {
        setVersaoContas(row);
        setListEmpresaVinculada(row.empresas);
        setExibirEmpresasVinc(true);
    };

    const qtdEmpresasTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    label={rowData.empresas.length === 0 ? "0" : rowData.empresas.length}
                    onClick={() => exibirListaEmpresasVinc(rowData)}
                    className="p-button-outlined p-button-primary"
                />
            </div>
        );
    };

    const colunas = [
        { field: "id", header: "Código", style: { width: "12%" } },
        { field: "descricao", header: "Descrição", style: { width: "30%" } },
        { field: "empresas", header: "Empresas", action: (e) => qtdEmpresasTemplate(e) },
        { field: "inicio_vigencia", header: "Vigência Inicial" },
        { field: "final_vigencia", header: "Vigência Final" },

        { field: "ativo", style: { width: "0" } },
        {
            field: "action",
            header: "Ações",
            action: (e) => opcoesVersaoPlano(e),
            style: { width: "15%" },
        },
    ];

    const colunasEmpresa = [
        { field: "id", header: "Código", style: { width: "12%" } },
        { field: "nome", header: "Empresa", style: { width: "60%" } },
        {
            field: "action",
            header: "Remover",
            action: (e) => opcoesEmpresasVinculadas(e),
            style: { width: "15%" },
        },
    ];

    return redirecionar ? (
        <Redirect to={redirecionar} />
    ) : (
        <PageBase>
            <Toast ref={toastRef} />
            <MakoListagem
                ref={listagemRef}
                titulo="Listagem de versões do plano de contas contábeis"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                responsiva
                configTabela={{
                    paginator: true,
                }}
            />

            <Dialog
                header="Vincular Empresas"
                visible={vincularEmpresa}
                style={{ width: "30vw" }}
                footer={renderFooter()}
                onHide={() => esconderVincularEmpresa()}
            >
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12" style={{ width: "100%", paddingLeft: "4%" }}>
                        <label htmlFor="empresa_vinculada">Empresa *</label>
                        <Dropdown
                            id="empresa_vinculada"
                            name="empresa_vinculada"
                            className="p-col-12 p-md-12"
                            value={empresaVinculada}
                            placeholder="Selecione uma empresa"
                            filter
                            filterBy="nome"
                            optionLabel="nome"
                            optionValue="id"
                            options={perfis}
                            onChange={(e) => setEmpresaVinculada(e.value)}
                        />
                    </div>
                </div>
            </Dialog>
            <Dialog
                header="Empresas vinculadas"
                visible={exibirEmpresasVinc}
                style={{ width: "30vw" }}
                onHide={() => setExibirEmpresasVinc(false)}
            >
                <MakoListagem
                    ref={listagemRef}
                    colunas={colunasEmpresa}
                    dadosLocal={listEmpresaVinculada}
                    responsiva
                    configTabela={{ paginator: false }}
                />
            </Dialog>
        </PageBase>
    );
};
