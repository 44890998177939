import React from "react";

import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";

import { PageBase } from "@/components/PageBase";
import { Dropdown } from "@/components/Dropdown";

import { TIPO_CARGO_CHOICE } from "@/assets/constants/constants";
import { axiosPost, axiosPut } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

const url = "/pessoas/cargos/";

export const CargosForm = (props) => {
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    
    const history = useHistory();

    const formik = useFormik({
        initialValues: props.location.state?.tag || {
            nome: "",
            nivel: null,
            ativo: true,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo 'nome' é obrigatório."),
                nivel: Yup.string().nullable().required("O campo 'nivel' é obrigatório."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(`${url}`, values);
                hideLoading();
                if (resposta.status === 201) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Cargo cadastrado com sucesso!",
                        life: 1500,
                    });
                    voltarParaListagem();
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(`${url}${values.id}/`, values);
                hideLoading();
                if (resposta.status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Cargo alterada com sucesso!",
                        life: 1500,
                    });
                    voltarParaListagem();
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const voltarParaListagem = () => {
        history.push("/gestao/cadastros/cargos");
    };

    return (
        <PageBase>
            {<h3>{!formik.values.id ? "Nova cargo" : "Manutenção de cargo"}</h3>}
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="nome">Nome: *</label>
                        <InputText
                            id="nome"
                            name="nome"
                            value={formik.values.nome}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.nome })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="nivel">Nível: *</label>
                        <Dropdown
                            id="nivel"
                            name="nivel"
                            options={TIPO_CARGO_CHOICE}
                            optionValue="value"
                            optionLabel="label"
                            value={formik.values.nivel}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.nivel })}
                            autoComplete="off"
                            autoFocus
                        />
                        {formik.errors.nivel && <small className="p-error">{formik.errors.nivel}</small>}
                    </div>
                    <div className="p-field-checkbox p-mt-4 p-col-12 p-md-3">
                        <Checkbox
                            inputId="ativo"
                            name="ativo"
                            checked={formik.values.ativo}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="ativo">Ativo?</label>
                    </div>
                </div>
                <p>
                    <b>* Campos obrigatórios.</b>
                </p>
                <div className="p-grid p-col-12 p-md-6">
                    <Button icon="pi pi-save" label="Gravar" type="submit" className="p-button-info p-mr-2" />
                    <Button
                        icon="pi pi-angle-double-left"
                        label="Cancelar"
                        type="reset"
                        className="p-button-danger"
                        onClick={voltarParaListagem}
                    />
                </div>
            </form>
        </PageBase>
    );
};
