import { OrdemCompraPage } from "@/pages/Compras/OrdemCompra/OrdemCompra";
import { TabOrdemCompraContext } from "@/pages/Compras/OrdemCompra/OrdemCompra/form";
import { CategoriaOcPage } from "@/pages/Compras/OrdemCompra/CategoriaOC";
import { CategoriaOcForm } from "@/pages/Compras/OrdemCompra/CategoriaOC/form";
import { ConsultarOrdemCompraPage } from "@/pages/Compras/OrdemCompra/ConsultarOC";
import { TiposEntradaPage } from "@/pages/Compras/Cadastros/TiposEntrada";
import { TiposEntradaForm } from "@/pages/Compras/Cadastros/TiposEntrada/form";
import { DestinacaoEntradaPage } from "@/pages/Compras/Cadastros/DestinacaoEntrada";
import { DestinacaoEntradaForm } from "@/pages/Compras/Cadastros/DestinacaoEntrada/form";
import { ConsultarSaldosPage } from "@/pages/Compras/ConsultarSaldos";
import { EntradaProdutosPage } from "@/pages/Compras/Entrada/EntradaProdutos";
import { TabEntradaProdutosContext } from "@/pages/Compras/Entrada/EntradaProdutos/form";
import { VendaDevolucaoFornecedorPage } from "@/pages/Compras/Entrada/DevolucaoFornecedor";
import { VendaDevolucaoFornecedorForm } from "@/pages/Compras/Entrada/DevolucaoFornecedor/DevolucaoFornecedorForm";
import { RegistroNecessidadeCompraPage } from "@/pages/Compras/Cadastros/RegistroNecessidadeCompra";

export const comprasRoutes = [
    { path: "/compras/consultar-saldos", component: ConsultarSaldosPage },
    { path: "/compras/ordens-compra/ordens-compra", component: OrdemCompraPage },
    { path: "/compras/ordens-compra/ordens-compra/form", component: TabOrdemCompraContext },
    { path: "/compras/ordens-compra/categorias-ordens-compra", component: CategoriaOcPage },
    { path: "/compras/ordens-compra/categorias-ordens-compra/form", component: CategoriaOcForm },
    { path: "/compras/ordens-compra/consultar-ordens-compra", component: ConsultarOrdemCompraPage },
    { path: "/compras/cadastros/tipos-entrada", component: TiposEntradaPage },
    { path: "/compras/cadastros/tipos-entrada/form", component: TiposEntradaForm },
    { path: "/compras/cadastros/destinacoes", component: DestinacaoEntradaPage },
    { path: "/compras/cadastros/destinacoes/form", component: DestinacaoEntradaForm },
    { path: "/compras/cadastros/registro-necessidade-compra", component: RegistroNecessidadeCompraPage },
    { path: "/compras/entrada/entrada-produtos", component: EntradaProdutosPage },
    { path: "/compras/entrada/entrada-produtos/form", component: TabEntradaProdutosContext },
    { path: "/compras/entrada/devolucao-fornecedor", component: VendaDevolucaoFornecedorPage },
    { path: "/compras/entrada/devolucao-fornecedor/form", component: VendaDevolucaoFornecedorForm },
];
