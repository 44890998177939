import React, { useRef, useState } from "react";
import { Button } from "primereact/button";

import { ModalReplicarItensOC } from "./ModalReplicarItensOC";
import MakoListagem from "@/components/MakoListagem";
import useEntrada from "@/hooks/useEntrada";
import useClearRefs from "@/hooks/useClearRefs";

export const OrdemCompraEntradaForm = () => {
    const [expandedRows, setExpandedRows] = useState(null);
    const { ordensCompra, entradaPendente, handleRemoverOrdemCompra } = useEntrada();
    const modalReplicarItensOC = useRef();

    useClearRefs(modalReplicarItensOC);

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-copy"
                    disabled={!entradaPendente}
                    onClick={() => modalReplicarItensOC.current?.abrirModal(rowData.itemordemcompra_set)}
                    className="p-button-rounded p-button-info p-mr-2 p-mt-1"
                />
                <Button
                    icon="pi pi-trash"
                    disabled={!entradaPendente}
                    onClick={() => handleRemoverOrdemCompra()}
                    className="p-button-rounded p-button-danger p-mt-1"
                />
            </div>
        );
    };

    const colunas = [
        { expander: true, style: { width: "3em" } },
        { field: "id", header: "Num OC" },
        { field: "data_pedido", header: "Data do pedido", dateFormat: "dd/MM/yyyy" },
        { field: "vencimento_inicial", header: "Venc. inicial", dateFormat: "dd/MM/yyyy" },
        { field: "vencimento_antecipacao", header: "Venc. antecipação", dateFormat: "dd/MM/yyyy" },
        { field: "valor_antecipacao", header: "Vlr. antecipação", money: true },
        { field: "valor_total_ordem_compra", header: "Valor total", money: true },
        { field: "actions", header: "Ações", action: (e) => actionBodyTemplate(e) },
    ];

    const rowExpansionTemplate = (data) => {
        const colunas = [
            { field: "sku.descricao_reduzida", header: "Produto" },
            { field: "quantidade", header: "Qtd.", decimal: true, align: "right" },
            { field: "valor_unitario", header: "Vlr. unitário", money: true },
            { field: "outros_valores", header: "Outros valores", money: true },
            { field: "valor_ipi", header: "IPI", money: true },
            { field: "valor_icms", header: "ICMS", money: true },
            { field: "total_item", header: "Valor total", money: true },
        ];

        return (
            <div className="orders-subtable">
                <MakoListagem
                    titulo="Itens da ordem de compra"
                    colunas={colunas}
                    dadosLocal={data.itemordemcompra_set}
                />
            </div>
        );
    };

    return (
        <>
            <MakoListagem
                colunas={colunas}
                dadosLocal={ordensCompra}
                configTabela={{
                    expandedRows,
                    onRowToggle: (e) => setExpandedRows(e.data),
                    rowExpansionTemplate,
                }}
            />
            <ModalReplicarItensOC ref={modalReplicarItensOC} />
        </>
    );
};
