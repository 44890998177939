import React, { useRef } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import { axiosPost, axiosPut } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import { useHistory } from "react-router-dom";

const url = "/produtos/marcas/";

export const ProdutoMarcasForm = (props) => {
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const marcaVazia = {
        id: "",
        nome: "",
    };

    const formik = useFormik({
        initialValues: props.location?.state ? props.location.state.marcas : marcaVazia,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo 'nome' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(`${url}`, values);
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Marca cadastrada com sucesso!",
                        life: 3000,
                    });
                    formik.resetForm();

                    if (!props.marca)
                        setTimeout(() => {
                            history.push("/produto/cadastros/marcas");
                        }, 2000);
                    else props.dialog(false);
                } else {
                    if (resposta.data?.nome)
                        toastRef.current.show({
                            severity: "error",
                            summary: "Erro",
                            detail: `Falha ao realizar cadastro (${resposta.data?.nome}).`,
                            life: 3000,
                        });
                    else
                        toastRef.current.show({
                            severity: "error",
                            summary: "Erro",
                            detail: "Desculpe, não conseguimos processar a sua requisição.",
                            life: 1500,
                        });
                }
            } else {
                showLoading();
                const resposta = await axiosPut(`${url}${values.id}/`, values);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Marca alterada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push("/produto/cadastros/marcas");
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    function validateForm() {
        return formik.values.nome !== "";
    }

    const cancelar = () => {
        formik.resetForm();
        history.push("/produto/cadastros/marcas");
    };

    return (
        <div className="p-grid">
            <Toast ref={toastRef} />
            <div className="p-col-12">
                <div className="card">
                    {props.marca ? null : <h3>{!formik.values.id ? "Nova marca" : "Manutenção de marca"}</h3>}
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className={props.marca ? "p-field p-col-12 p-md-12" : "p-field p-col-12 p-md-4"}>
                                <label htmlFor="nome">Nome *</label>
                                <InputText
                                    id="nome"
                                    name="nome"
                                    value={formik.values.nome}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.nome })}
                                    autoComplete="off"
                                    autoFocus
                                />
                                {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                            </div>
                        </div>
                        <p>
                            <b>* Campos obrigatórios.</b>
                        </p>
                        <div className="p-grid p-col-12 p-md-6">
                            <Button
                                label="Gravar"
                                type="submit"
                                className={`p-button-info p-mr-2 ${props.marca ? "" : "p-mb-6"}`}
                                disabled={!validateForm()}
                            />
                            {props.marca ? (
                                <Button
                                    label="Fechar"
                                    type="reset"
                                    className="p-button-danger p-mr-2"
                                    onClick={() => props.dialog(false)}
                                />
                            ) : (
                                <Button
                                    label="Cancelar"
                                    type="reset"
                                    className="p-button-danger p-mr-2 p-mb-6"
                                    onClick={cancelar}
                                />
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
