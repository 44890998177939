import React, { forwardRef, useImperativeHandle, useState, useRef } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Messages } from "primereact/messages";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import useVenda from "@/hooks/useVenda";

const ModalEditarParcelaComponente = (props, ref) => {
    const [visible, setVisible] = useState(false);
    const msgRef = useRef(null);
    const { handleAlterarParcelas } = useVenda();

    const formik = useFormik({
        initialValues: {
            valor_parcela: 0,
            dia_vencimento: 1,
            replicar: false,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                valor_parcela: Yup.number()
                    .required("O campo 'valor da parcela' é obrigatório.")
                    .min(0.01, "O valor da parcela não pode ser ZERO."),
                dia_vencimento: Yup.number()
                    .required("O campo 'dia do vencimento' é obrigatório.")
                    .min(1, "O dia do vencimento não pode ser menor que 1.")
                    .max(31, "O dia do vencimento não pode ser superior a 31."),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            const alterouParcela = await handleAlterarParcelas(values);
            if (alterouParcela) {
                fecharModal();
            } else {
                msgRef.current?.show([
                    {
                        severity: "warn",
                        detail: "Com esse valor de parcela não é possível realizar a venda.",
                        life: 5000,
                    },
                ]);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const abrirModal = (parcela) => {
        setVisible(true);
        formik.setValues(parcela);
    };

    const fecharModal = () => {
        setVisible(false);
        formik.resetForm();
    };

    const footerDialog = () => {
        return (
            <div>
                <Button
                    label="Cancelar"
                    icon="pi pi-times"
                    onClick={fecharModal}
                    className="p-button-text p-button-danger"
                />
                <Button label="Confirmar" type="submit" icon="pi pi-save" onClick={formik.handleSubmit} autoFocus />
            </div>
        );
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    return (
        <Dialog
            visible={visible}
            header="Alterar parcela"
            footer={footerDialog}
            style={{ width: "40vw" }}
            onHide={fecharModal}
            blockScroll
        >
            <Messages ref={msgRef} />
            <form>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="valor-parcela">Valor da parcela</label>
                        <MakoInputMoeda
                            id="valor-parcela"
                            name="valor_parcela"
                            valueMoeda={formik.values.valor_parcela}
                            onChangeMoeda={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor_parcela })}
                        />
                        {formik.errors.valor_parcela && (
                            <small className="p-error">{formik.errors.valor_parcela}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="dia-vencimento">Dia do vencimento</label>
                        <InputNumber
                            inputId="dia-vencimento"
                            name="dia_vencimento"
                            showButtons
                            min={1}
                            max={31}
                            value={formik.values.dia_vencimento}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.dia_vencimento })}
                        />
                        {formik.errors.dia_vencimento && (
                            <small className="p-error">{formik.errors.dia_vencimento}</small>
                        )}
                    </div>
                    <div className="p-field-checkbox p-mt-4 p-col-12 p-md-4">
                        <Checkbox
                            inputId="replicar"
                            name="replicar"
                            checked={formik.values.replicar}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="replicar">Replicar para as demais parcelas</label>
                    </div>
                </div>
            </form>
        </Dialog>
    );
};

export const ModalEditarParcela = forwardRef(ModalEditarParcelaComponente);
