import React, { forwardRef, useImperativeHandle, useState } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { axiosPost, axiosPut } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

const Modal = ({ isRoot }, ref) => {
    const [visible, setVisible] = useState(false);
    const [usuario, setUsuario] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showWarning, showError } = useToast();

    const formik = useFormik({
        initialValues: {
            isRoot,
            new_password: "",
            current_password: "",
            confirmpass: "",
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                current_password: Yup.string().when("isRoot", {
                    is: (val) => val === false,
                    then: Yup.string().trim("Não pode ser em branco.").required("O campo 'senha atual' é obrigatório."),
                    otherwise: Yup.string().notRequired().default(""),
                }),
                new_password: Yup.string()
                    .trim("Não pode ser em branco.")
                    .matches(
                        /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})./,
                        "Senha fraca"
                    )
                    .required("O campo 'nova senha' é obrigatório."),
                confirmpass: Yup.string()
                    .required("O campo 'confirmar senha' é obrigatório.")
                    .oneOf([formik.values.new_password], "As senhas não são iguais."),
            });
            await formSchema.validate(values, { abortEarly: false });
            const { new_password, current_password } = values;
            if (!isRoot) {
                showLoading();
                const { status, data } = await axiosPost(`/auth/users/set_password/`, {
                    new_password,
                    current_password,
                });
                hideLoading();
                if (status === 204) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Senha alterada com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        formik.resetForm();
                        setUsuario(null);
                        setVisible(false);
                    }, 2000);
                } else if (status === 400 && data?.current_password) {
                    showWarning({
                        summary: data.current_password,
                        detail: "Senha atual informada é inválida, por favor verifique e tente novamente.",
                        life: 5000,
                    });
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            } else {
                const body = {
                    username: usuario.username,
                    password: new_password,
                };
                showLoading();
                const resposta = await axiosPut(`/pessoas/usuario/${usuario.id}/`, body);
                hideLoading();
                if (resposta.status === 200) {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Senha alterada com sucesso!",
                        life: 1500,
                    });
                    setTimeout(() => {
                        setVisible(false);
                    }, 2000);
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const abrirModal = (usuario) => {
        setVisible(true);
        setUsuario(usuario);
    };

    const fecharModal = () => {
        setVisible(false);
        formik.resetForm();
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    return (
        <Dialog
            header="Alterar senha de usuário"
            visible={visible}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "30vw" }}
            onHide={fecharModal}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid ">
                    {!isRoot && (
                        <div className="p-field p-col-12">
                            <label htmlFor="senha-atual">Senha atual *</label>
                            <Password
                                id="senha-atual"
                                name="current_password"
                                toggleMask
                                mediumRegex
                                feedback={false}
                                autoComplete="off"
                                value={formik.values.current_password}
                                onChange={formik.handleChange}
                                className={classNames({ "p-invalid": formik.errors.current_password })}
                            />
                            {formik.errors.current_password && (
                                <small className="p-error">{formik.errors.current_password}</small>
                            )}
                        </div>
                    )}
                    <div className="p-field p-col-12">
                        <label htmlFor="nova-senha">Nova senha *</label>
                        <Password
                            id="nova-senha"
                            name="new_password"
                            toggleMask
                            mediumRegex
                            promptLabel="Informe a senha"
                            weakLabel="Fraca"
                            mediumLabel="Média"
                            strongLabel="Forte"
                            autoComplete="off"
                            value={formik.values.new_password}
                            onInput={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.new_password })}
                        />
                        {formik.errors.new_password && <small className="p-error">{formik.errors.new_password}</small>}
                    </div>
                    <div className="p-field p-col-12">
                        <label htmlFor="confirmpass">Confirmar senha *</label>
                        <Password
                            id="confirmpass"
                            name="confirmpass"
                            feedback={false}
                            autoComplete="off"
                            value={formik.values.confirmpass}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.confirmpass })}
                        />
                        {formik.errors.confirmpass && <small className="p-error">{formik.errors.confirmpass}</small>}
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-md-2">
                        <Button type="submit" icon="pi pi-save" label="Salvar" />
                    </div>
                </div>
            </form>
        </Dialog>
    );
};

export const ModalAlterarSenhaUsuario = forwardRef(Modal);
