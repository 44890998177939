import { useCallback, useEffect } from "react";

import useAuth from "./useAuth";
import api from "@/services/axios";
import {
    axiosDelete as apiDelete,
    axiosGet as apiGet,
    axiosPatch as apiPatch,
    axiosPost as apiPost,
    axiosPut as apiPut,
} from "@/services/http";

const useAxiosPrivate = () => {
    const { token } = useAuth();

    useEffect(() => {
        const requestIntercept = api.interceptors.request.use((config) => {
            if (!config.headers?.Authorization && token) {
                config.headers = { ...config.headers, Authorization: `token ${token}` };
            }
            return config;
        });

        return () => {
            api.interceptors.request.eject(requestIntercept);
        };
    }, [token]);

    const axiosGet = useCallback((url, options) => {
        return apiGet(url, options, api);
    }, []);

    const axiosPost = useCallback((url, body, options) => {
        return apiPost(url, body, options, api);
    }, []);

    const axiosPut = useCallback((url, body) => {
        return apiPut(url, body, api);
    }, []);

    const axiosPatch = useCallback((url, body) => {
        return apiPatch(url, body, api);
    }, []);

    const axiosDelete = useCallback((url) => {
        return apiDelete(url, api);
    }, []);

    return { axiosGet, axiosPost, axiosPut, axiosPatch, axiosDelete };
};

export default useAxiosPrivate;
