import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { BotaoDelete } from "@/components/BotaoDelete";
import MakoListagem from "@/components/MakoListagem";

import permissoes from "@/assets/constants/permissoes";

import useClearRefs from "@/hooks/useClearRefs";
import { AtivoFiltroTemplate, DateFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";

export const CompetenciaMovEstoquePage = () => {
    const listagemRef = useRef();
    const history = useHistory();

    useClearRefs(listagemRef);

    const painelEsquerdoTabela = (
        <Button
            label="Nova"
            icon="pi pi-plus"
            className="p-button-success p-mr-2"
            onClick={() => history.push("/estoque/cadastros/competencias-movimentacoes-estoque/form")}
        />
    );

    const statusBodyTemplate = (rowData) => {
        if (rowData.ativa) {
            return <Tag severity="success" value="ATIVO" />;
        }
        return <Tag severity="danger" value="INATIVO" />;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.ESTOQUE_CADASTRO_COMPMOVESTOQUE_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    onClick={() =>
                        history.push({
                            pathname: "/estoque/cadastros/competencias-movimentacoes-estoque/form",
                            state: rowData,
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.ESTOQUE_CADASTRO_COMPMOVESTOQUE_EXCLUIR]}
                    componente={BotaoDelete}
                    url={"/produtos/competencias-movimentacoes-estoques/"}
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Confirma a exclusão da competência: <b>{rowData.nome}</b>?
                        </span>
                    }
                    msgToastErroExclusao="A competência não pode ser excluído."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const colunas = [
        { field: "nome", header: "Nome", filter: true, filterElement: TextoFiltroTemplate },
        { field: "data_inicio", header: "Data de início", dateFormat: "dd/MM/yyyy", filter: true, filterElement: DateFiltroTemplate },
        { field: "data_fim", header: "Data fim", dateFormat: "dd/MM/yyyy", filter: true, filterElement: DateFiltroTemplate },
        { field: "ativa", header: "Status", action: (e) => statusBodyTemplate(e), filter: true, filterElement: AtivoFiltroTemplate },
        { field: "actions", header: "Ações", style: { width: "10%" }, action: (e) => actionBodyTemplate(e) },
    ];

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Competência de movimentação de estoque"
                        colunas={colunas}
                        urlPesquisa="/produtos/competencias-movimentacoes-estoques/"
                        painelEsquerdo={painelEsquerdoTabela}
                        configTabela={{ lazy: true, paginator: true }}
                        filtros={{
                            nome: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                            },
                            data_inicio: { value: null, matchMode: "equals" },
                            data_fim: { value: null, matchMode: "equals" },
                            ativa: { value: null, matchMode: "equals" }
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
