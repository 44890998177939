import React, { useImperativeHandle, useState, forwardRef } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { MakoAutoComplete } from "@/components/MakoAutoComplete/index2";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";
import { SITUACAO_DEVOLUCAO } from "@/assets/constants/vendas";
import { dataToStr } from "@/assets/util/datas";
import { totalizadorFiltrosAplicados } from "@/assets/util/util";

const INITIAL_VALUES = {
    empresa: null,
    cliente: null,
    incluido_por: null,
    data: null,
    valor_total: null,
    valor_voucher: null,
    status: null,
};

const Componente = ({ urlBase, onConfirm }, ref) => {
    const [visible, setVisible] = useState(false);

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: INITIAL_VALUES,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                empresa: Yup.number().notRequired().nullable().typeError("Selecione uma empresa"),
                cliente: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'cliente' é obrigatório"),
                    })
                    .notRequired()
                    .nullable()
                    .typeError("Informe um 'cliente' válida"),
                incluido_por: Yup.object()
                    .shape({
                        id: Yup.number().required("O campo 'incluido por' é obrigatório"),
                    })
                    .notRequired()
                    .nullable()
                    .typeError("Informe um 'incluido por' válida"),
                data: Yup.date().notRequired().nullable().typeError("Informe uma 'data' válida"),
                valor_total: Yup.number().notRequired().nullable().typeError("Informe uma 'valor total' válido"),
                valor_voucher: Yup.number().notRequired().nullable().typeError("Informe uma 'valor voucher' válido"),
            });
            await formSchema.validate(values, {
                abortEarly: false,
            });
            let params = [];
            Object.keys(values).forEach((key) => {
                if (values[key] !== null) {
                    if (key === "cliente" || key === "incluido_por") params.push(`${key}=${values[key].id}`);
                    if (key === "data") params.push(`${key}=${dataToStr(values[key], "yyyy-MM-dd")}`);
                }
            });
            if (params.length > 0) {
                let newUrl = urlBase;
                if (!newUrl.includes("?")) newUrl = `${newUrl}?`;
                newUrl = `${newUrl}${params.join("&")}`;
                if (typeof onConfirm === "function")
                    onConfirm(newUrl, totalizadorFiltrosAplicados(INITIAL_VALUES, values));
            } else {
                if (typeof onConfirm === "function")
                    onConfirm(urlBase, totalizadorFiltrosAplicados(INITIAL_VALUES, values));
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const abrirModal = () => {
        setVisible(true);
    };

    const fecharModal = () => {
        formik.resetForm();
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const autoCompleteClienteTemplate = (item) => {
        return <div>{`${item.nome} - ${item.identificacao}${item.telefone ? ` - ${item.telefone}` : ""}`}</div>;
    };

    return (
        <Dialog
            visible={visible}
            header="Filtro avançado"
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "75vw", display: "block" }}
            onHide={fecharModal}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="empresa">Empresa:</label>
                        <MakoDropdownEmpresas
                            id="empresa"
                            name="empresa"
                            value={formik.values.empresa}
                            onChange={(e) => setFieldValue("empresa", e.id)}
                            className={classNames({ "p-invalid": formik.errors.empresa })}
                        />
                        {formik.errors.empresa && <small className="p-error">{formik.errors.empresa}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="cliente">Cliente:</label>
                        <MakoAutoComplete
                            id="cliente"
                            name="cliente"
                            value={formik.values.cliente}
                            onChange={formik.handleChange}
                            key="id"
                            field="nome"
                            minCaracteresBusca={4}
                            itemTemplate={autoCompleteClienteTemplate}
                            urlSearch="/pessoas/perfis?query={id,nome,identificacao,tipo_pessoa,telefoneperfil_set}&ativo=true&nome__unaccent__icontains="
                            placeholder="Digite o nome do cliente para buscar... (min 4 caracteres)"
                            className={classNames({ "p-invalid": formik.errors.cliente })}
                        />
                        {formik.errors.cliente && <small className="p-error">{formik.errors.cliente}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="incluido_por">Incluido por:</label>
                        <MakoAutoComplete
                            id="incluido_por"
                            name="incluido_por"
                            value={formik.values.incluido_por}
                            onChange={formik.handleChange}
                            key="id"
                            field="nome"
                            minCaracteresBusca={4}
                            itemTemplate={autoCompleteClienteTemplate}
                            urlSearch="/pessoas/perfis?query={id,nome,identificacao,tipo_pessoa,telefoneperfil_set}&ativo=true&nome__unaccent__icontains="
                            placeholder="Digite o nome para buscar... (min 4 caracteres)"
                            className={classNames({ "p-invalid": formik.errors.incluido_por })}
                        />
                        {formik.errors.incluido_por && <small className="p-error">{formik.errors.incluido_por}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="data">Data:</label>
                        <MakoCalendar
                            id="data"
                            name="data"
                            valueCalendar={formik.values.data}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data })}
                        />
                        {formik.errors.data && <small className="p-error">{formik.errors.data}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor_total">Valor total:</label>
                        <MakoInputMoeda
                            id="valor_total"
                            name="valor_total"
                            value={formik.values.valor_total}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor_total })}
                        />
                        {formik.errors.valor_total && <small className="p-error">{formik.errors.valor_total}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="valor_voucher">Valor voucher:</label>
                        <MakoInputMoeda
                            id="valor_voucher"
                            name="valor_voucher"
                            value={formik.values.valor_voucher}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.valor_voucher })}
                        />
                        {formik.errors.valor_voucher && (
                            <small className="p-error">{formik.errors.valor_voucher}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="status">Status:</label>
                        <Dropdown
                            id="status"
                            name="status"
                            options={SITUACAO_DEVOLUCAO}
                            optionLabel="label"
                            optionValue="value"
                            value={formik.values.status}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.status })}
                        />
                        {formik.errors.status && <small className="p-error">{formik.errors.status}</small>}
                    </div>
                </div>
                <div className="p-grid p-justify-end p-mt-2">
                    <Button
                        type="reset"
                        icon="pi pi-times"
                        label="Cancelar"
                        onClick={fecharModal}
                        className="p-button-danger p-mr-2"
                    />
                    <Button
                        type="reset"
                        icon="pi pi-trash"
                        label="Limpar"
                        onClick={() => formik.resetForm()}
                        className="p-button-warning p-mr-2"
                    />
                    <Button type="submit" icon="pi pi-filter" label="Filtrar" className="p-mr-2" />
                </div>
            </form>
        </Dialog>
    );
};

export const FiltroAvancadoDevolucao = forwardRef(Componente);
