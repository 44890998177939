import React from "react";

import { FinanceiroChavePixFormBase } from "./formBase";

export const FinanceiroChavePixForm = (props) => {
    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <FinanceiroChavePixFormBase
                        chavePix={{
                            ...props.location.state?.chavePix,
                            tipo_chave: props.location.state?.chavePix.tipo_chave.id,
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
