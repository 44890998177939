import React, { useRef } from "react";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import MakoListagem from "@/components/MakoListagem";
import { PageBase } from "@/components/PageBase";
import { BotaoDelete } from "@/components/BotaoDelete";
import permissoes from "@/assets/constants/permissoes";

export const VinculoMotoristaVeiculoPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_CADASTRO_TAGSPERFIL_EXCLUIR]}
                    componente={BotaoDelete}
                    url={"/pessoas/vinculo-motorista-veiculo/"}
                    objetoId={rowData.id}
                    classNames="p-mb-1"
                    exigeConfirmacao
                    msgConfirmacao={<span>Deseja realmente remover o vínculo?</span>}
                    msgToastErroExclusao="A o vínculo não pode ser desfeito."
                    tooltip="Remover vínculo"
                    tooltipOptions={{ position: "left" }}
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.GESTAO_CADASTRO_TAGSPERFIL_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => history.push("/gestao/cadastros/vinculo-motorista-veiculo/form")}
            />
        </>
    );

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { width: "12%" },
            filter: true,
        },
        { field: "perfil_motorista.nome", header: "Motorista" },
        { field: "perfil_motorista.identificacao", header: "CPF/Cnpj" },
        { field: "veiculo_entrega.placa", header: "Placa" },
        { field: "veiculo_entrega.chassi", header: "Chassi" },
        { field: "veiculo_entrega.renavan", header: "Renavan" },
        {
            field: "action",
            header: "Ações",
            style: { width: "12%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Vínculo Motorista com Veículo"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={"/pessoas/vinculo-motorista-veiculo/"}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
            />
        </PageBase>
    );
};
