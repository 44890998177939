import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";

import { ModalAcertoEstoque } from "./modalForm";
import permissoes from "@/assets/constants/permissoes";
import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { axiosDelete } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import useClearRefs from "@/hooks/useClearRefs";
import useToast from "@/hooks/useToast";
import {
    EstoqueFiltroTemplate,
    TextoFiltroTemplate,
    UnidadeMedidaFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import { QuantidadeFiltroTemplate } from "@/components/MakoFiltrosCabecalho/QuantidadeFiltroTemplate";

export const AcertoEstoquePage = () => {
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [acertoEstoque, setAcertoEstoque] = useState(null);
    const modalAcertoRef = useRef(null);
    const listagemRef = useRef(null);
    const { showLoading, hideLoading } = useLoading();
    const { showError, showSuccess } = useToast();
    useClearRefs(listagemRef, modalAcertoRef);

    const confirmarDelete = (acertoEstoque) => {
        setAcertoEstoque(acertoEstoque);
        setDeleteDialog(true);
    };

    const painelEsquerdo = (
        <>
            <Button
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success p-mr-2 p-mb-2"
                onClick={() => modalAcertoRef.current?.abrirModal()}
            />
        </>
    );

    const deletarAcertoEstoque = async () => {
        showLoading();
        const resposta = await axiosDelete(`/produtos/acerto-estoque/${acertoEstoque.id}/`);
        hideLoading();

        if (resposta.status === 204) {
            showSuccess({
                summary: "Sucesso",
                detail: "Saldo inicial deletado com sucesso!",
                life: 1500,
            });

            setTimeout(() => {
                listagemRef.current?.buscarDados();
            }, 1500);
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
        }

        setDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[
                        permissoes.ESTOQUE_MOVIMENTACOES,
                        permissoes.ESTOQUE_MOVIMENTACOES_ACERTOESTOQUE_EDITAR,
                    ]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    onClick={() => modalAcertoRef.current?.abrirModal(rowData)}
                />
                <MakoControleAcesso
                    permissao={[
                        permissoes.ESTOQUE_MOVIMENTACOES,
                        permissoes.ESTOQUE_MOVIMENTACOES_ACERTOESTOQUE_EXCLUIR,
                    ]}
                    componente={Button}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2"
                    onClick={() => confirmarDelete(rowData)}
                />
            </div>
        );
    };

    const quantidadeTamplateBody = (rowData) => {
        if (rowData.quantidade < 0) {
            return <span style={{ color: "#ff5252" }}>{rowData.quantidade}</span>;
        }

        return <span>{rowData.quantidade}</span>;
    };

    const EstoquesFiltroTemplate = (options) => {
        return <EstoqueFiltroTemplate options={options} />;
    };

    const colunas = [
        {
            field: "sku.codigo",
            header: "Código",
            style: { width: "7%" },
            filter: true,
            filterElement: TextoFiltroTemplate,
            filterField: "sku__codigo",
        },
        {
            field: "sku.descricao_derivada",
            header: "Descrição",
            filter: true,
            filterElement: TextoFiltroTemplate,
            filterField: "sku__descricao_reduzida",
        },
        {
            field: "centro_estocagem.nome",
            header: "Centro de Estocagem",
            style: { width: "20%" },
            filter: true,
            filterElement: EstoquesFiltroTemplate,
            filterField: "centro_estocagem",
        },
        {
            field: "sku.unidade_padrao.nome",
            header: "Unidade",
            style: { width: "10%" },
            filter: true,
            filterElement: UnidadeMedidaFiltroTemplate,
            filterField: "sku__unidade_padrao",
        },
        {
            field: "quantidade",
            header: "Quantidade",
            style: { width: "10%" },
            action: (e) => quantidadeTamplateBody(e),
            filter: true,
            filterElement: QuantidadeFiltroTemplate,
        },
        { field: "action", header: "Ações", action: (e) => actionBodyTemplate(e), style: { width: "10%" } },
    ];

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <MakoListagem
                        ref={listagemRef}
                        titulo="Lançamentos de acerto de estoque"
                        colunas={colunas}
                        painelEsquerdo={painelEsquerdo}
                        urlPesquisa="/produtos/acerto-estoque/"
                        configTabela={{
                            paginator: true,
                            lazy: true,
                        }}
                        filtros={{
                            sku__codigo: { value: null, matchMode: "equals" },
                            sku__descricao_reduzida: {
                                operator: "and",
                                constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                            },
                            centro_estocagem: { value: null, matchMode: "equals" },
                            sku__unidade_padrao: { value: null, matchMode: "equals" },
                            quantidade: { value: null, matchMode: "equals" },
                        }}
                    />
                    <ModalAcertoEstoque ref={modalAcertoRef} aposFechar={() => listagemRef.current?.buscarDados()} />
                    <ConfirmDialog
                        visible={deleteDialog}
                        onHide={() => setDeleteDialog(false)}
                        header="Confirmação"
                        message={
                            acertoEstoque && (
                                <span>
                                    {"Deseja realmente excluir o acerto de estoque do produto "}
                                    <b>{acertoEstoque.sku.descricao_derivada}</b>?
                                </span>
                            )
                        }
                        icon="pi pi-info-circle p-mr-3"
                        accept={deletarAcertoEstoque}
                        acceptLabel="Sim"
                        acceptClassName="p-button-danger"
                        reject={() => setDeleteDialog(false)}
                        rejectLabel="Não"
                    />
                </div>
            </div>
        </div>
    );
};
