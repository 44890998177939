import React, { useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { axiosPatch, axiosPost } from "@/services/http";

import useLoading from "@/hooks/useLoading";
import classNames from "classnames";
import * as Yup from "yup";

const url = "/financeiro/carteiras-contas-financeiras/";
const urlvoltar = "/financeiro/cadastros/carteira-conta-financeira";

export const FinanceiroCarteiraFinanceiraForm = (props) => {
    const { showLoading, hideLoading } = useLoading();
    const toastRef = useRef(null);
    const history = useHistory();
    const carteiraVazia = {
        codigo: "",
        descricao: "",
        ativo: true,
    };

    const formik = useFormik({
        initialValues: props.location.state ? props.location.state.carteiras : carteiraVazia,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            if (!values.id) {
                showLoading();
                const resposta = await axiosPost(`${url}`, values);
                hideLoading();

                if (resposta.status === 201) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Carteira de contas financeiras cadastrada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            } else {
                const body = {
                    codigo: values.codigo,
                    descricao: values.descricao,
                    ativo: ativo,
                };

                showLoading();
                const resposta = await axiosPatch(`${url}${values.id}/`, body);
                hideLoading();

                if (resposta.status === 200) {
                    toastRef.current.show({
                        severity: "success",
                        summary: "Sucesso",
                        detail: "Carteira de contas financeiras alterada com sucesso!",
                        life: 1500,
                    });

                    setTimeout(() => {
                        formik.resetForm();
                        history.push(urlvoltar);
                    }, 2000);
                } else {
                    toastRef.current.show({
                        severity: "error",
                        summary: "Erro :(",
                        detail: "Desculpe, não conseguimos processar a sua requisição.",
                        life: 1500,
                    });
                }
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
                hideLoading();
            } else {
                toastRef.current.show({
                    severity: "error",
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 1500,
                });
                hideLoading();
            }
        }
    }

    function validateForm() {
        return formik.values.descricao !== "" && formik.values.codigo !== "";
    }

    const cancelar = () => {
        formik.resetForm();
        history.push(urlvoltar);
    };

    const [ativo, setAtivo] = useState(formik.values.ativo);

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toastRef} />
                    <h3>
                        {!formik.values.id
                            ? "Nova carteira de contas financeiras"
                            : "Manutenção de carteira de contas financeiras"}
                    </h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-5">
                                <label htmlFor="descricao">Descrição *</label>
                                <InputText
                                    id="descricao"
                                    name="descricao"
                                    value={formik.values.descricao}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.descricao })}
                                    autoComplete="off"
                                    autoFocus
                                />
                                {formik.errors.descricao && (
                                    <small className="p-error">{formik.errors.descricao}</small>
                                )}
                            </div>
                            <div className="p-field p-col-12 p-md-2">
                                <label htmlFor="codigo">Código *</label>
                                <InputText
                                    id="codigo"
                                    name="codigo"
                                    keyfilter={/^[0-9]/}
                                    value={formik.values.codigo}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": formik.errors.codigo })}
                                    autoComplete="off"
                                />
                                {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-1 ">
                                <h5>
                                    <label htmlFor="status">Status</label>
                                </h5>
                                <InputSwitch
                                    id="ativo"
                                    name="ativo"
                                    checked={ativo}
                                    disabled={formik.values.id ? false : true}
                                    value={formik.values.ativo}
                                    onChange={(e) => setAtivo(e.value)}
                                    autoFocus
                                />
                            </div>
                        </div>
                        <p>
                            <b>* Campos obrigatórios.</b>
                        </p>

                        <div className="p-grid p-col-12 p-md-6">
                            <Button
                                label="Gravar"
                                type="submit"
                                className="p-button-info p-mr-2 p-mb-6"
                                disabled={!validateForm()}
                            />
                            <Button
                                label="Cancelar"
                                type="reset"
                                className="p-button-danger p-mr-2 p-mb-6"
                                onClick={cancelar}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
