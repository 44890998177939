import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";

import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { PageBase } from "@/components/PageBase";
import { BotaoDelete } from "@/components/BotaoDelete";
import permissoes from "@/assets/constants/permissoes";

const url = "/financeiro/bancos/";

export const FinanceiroBancoPage = () => {
    const listagemRef = useRef(null);
    const history = useHistory();

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTRO_BANCO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    onClick={() =>
                        history.push({
                            pathname: "/financeiro/cadastros/bancos/form",
                            state: { bancos: rowData },
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_CADASTRO_BANCO_EXCLUIR]}
                    componente={BotaoDelete}
                    url={url}
                    objetoId={rowData.id}
                    classNames="p-mb-1"
                    exigeConfirmacao
                    msgConfirmacao={
                        <span>
                            Deseja realmente excluir o banco <b>{rowData.descricao}</b>?
                        </span>
                    }
                    msgToastErroExclusao="O banco não pode ser excluído."
                    onDelete={() => listagemRef.current?.buscarDados()}
                />
            </div>
        );
    };

    const cabecalhoTabela = (
        <MakoControleAcesso
            permissao={[permissoes.FINANCEIRO_CADASTRO_BANCO_INCLUIR]}
            componente={Button}
            label="Novo"
            icon="pi pi-plus"
            className="p-button-success p-mr-2"
            onClick={() => history.push("/financeiro/cadastros/bancos/form")}
        />
    );

    const colunas = [
        {
            field: "codigo",
            header: "Código",
            style: { width: "10%" },
            filter: true,
        },
        { field: "descricao", header: "Descrição", filter: true },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "10%" },
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Bancos"
                urlPesquisa={url}
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    codigo: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "equals" }],
                    },
                }}
            />
        </PageBase>
    );
};
