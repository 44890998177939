import React from 'react';

import { gerarId } from '@/assets/util/util';

const MakoCardProdutoInfo = ({
    title,
    subtitle,
    column_1,
    column_2,
    items,
}) => {
    return (
        <div className="card">
            <h4>{title}</h4>
            <p>{subtitle}</p>

            <ul className="widget-image-list">
                <li>
                    <span>{column_1}</span>
                    <span>{column_2}</span>
                </li>
                {items.map((item) => (
                    <li key={gerarId()}>
                        <span>
                            <img src={item.src} alt={item.alt} />
                            <span>{item.column_1}</span>
                        </span>
                        <span className="listitem-value">{item.column_2}</span>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default MakoCardProdutoInfo;

