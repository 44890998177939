import React, { createContext, useCallback, useEffect, useState } from "react";

import useCaixa from "@/hooks/useCaixa";
import useParam from "@/hooks/useParam";
import useToast from "@/hooks/useToast";

import {
    FINANCEIRO_PLANODECONTAS_PADRAO_SANGRIA,
    FINANCEIRO_PLANODECONTAS_PADRAO_SUPRIMENTO,
    FINANCEIRO_PLANODECONTAS_PADRAO_TRANSFERENCIA,
} from "@/assets/constants/parametros";

const CaixaMovimentoContext = createContext({});

const PADRAO_PLANO = {
    sangria: null,
    suprimento: null,
    transferencia: null,
};

export const CaixaMovimentoProvider = ({ children }) => {
    const [padraoPlanos, setPadraoPlanos] = useState(PADRAO_PLANO);

    const { caixa, loadCaixa, setCaixaUsuario, clearCaixa: resetar } = useCaixa();
    const { showWarning } = useToast();
    const { getParam } = useParam();

    const fetchCaixa = useCallback(async () => {
        await loadCaixa();
    }, [loadCaixa]);

    const clearCaixa = useCallback(() => {
        resetar();
    }, [resetar]);

    const updateCaixa = useCallback(
        (values) => {
            setCaixaUsuario(values);
        },
        [setCaixaUsuario]
    );

    const verifyParams = useCallback(() => {
        let _padrao = PADRAO_PLANO;
        let _params = getParam(FINANCEIRO_PLANODECONTAS_PADRAO_SANGRIA);
        if (_params) {
            _padrao.sangria = _params.valor;
        }

        _params = getParam(FINANCEIRO_PLANODECONTAS_PADRAO_SUPRIMENTO);
        if (_params) {
            _padrao.suprimento = _params.valor;
        }

        _params = getParam(FINANCEIRO_PLANODECONTAS_PADRAO_TRANSFERENCIA);
        if (_params) {
            _padrao.transferencia = _params.valor;
        }

        let message = "Você precisa definir o plano de contas padrão para o parâmetro:";
        let hasNull = false;

        Object.keys(_padrao).forEach((key) => {
            if (_padrao[key] === null) {
                hasNull = true;
                message = `${message} ${key},`;
            }
        });

        if (hasNull) {
            showWarning({
                summary: "Alerta :(",
                detail: message.slice(0, message.length - 1),
                life: 5000,
            });
        } else {
            setPadraoPlanos(_padrao);
        }
    }, [getParam, showWarning]);

    useEffect(() => {
        verifyParams();
    }, [verifyParams]);

    return (
        <CaixaMovimentoContext.Provider
            value={{
                caixaMov: caixa,
                padraoPlanos,
                fetchCaixa,
                updateCaixa,
                clearCaixa,
            }}
        >
            {children}
        </CaixaMovimentoContext.Provider>
    );
};

export default CaixaMovimentoContext;
