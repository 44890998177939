import React, { useCallback, useEffect, useRef, useState } from "react";

import { MakoCalendar } from "@/components/MakoCalendar";
import { SplitButton } from "primereact/splitbutton";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import MakoListagem from "@/components/MakoListagem";

import permissoes from "@/assets/constants/permissoes";

import { FormVisualizarLancamento } from "./formVisualizarLancamento";
import { FormEditarHistorico } from "./formEditarHistorico";
import { FormTransferencia } from "./formTransferencia";
import { FormResumoCaixa } from "./formResumoCaixa";
import { FormDesbloquear } from "./formDesbloquear";
import { FormSuprimento } from "./formSuprimento";
import { FormBloquear } from "./formBloquear";
import { FormSangria } from "./formSangria";
import { FormFechar } from "./formFechar";
import { FormAbrir } from "./formAbrir";

import useCaixaMovimento from "@/hooks/useCaixaMovimento";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";

import { CaixaMovimentoProvider } from "@/contexts/caixaMovimentoContext";

import { NATUREZA_PLANO_CONTAS } from "@/assets/constants/constants";
import { parseData } from "@/assets/util/datas";
import { axiosPost } from "@/services/http";

const Content = () => {
    const [ocultar, setOcultar] = useState(true);
    const [visible, setVisible] = useState(false);
    const [modalProps, setModalProps] = useState({
        header: "",
        style: { width: "55vw" },
        content: "",
    });

    const { caixaMov, padraoPlanos, fetchCaixa: handleCaixa } = useCaixaMovimento();
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showError } = useToast();

    const abrirModal = (modalProps) => {
        setModalProps(modalProps);
        setVisible(true);
    };

    const fecharModal = (updateMovimentacao = false) => {
        if (updateMovimentacao && !ocultar) listagemRef?.current?.buscarDados();

        setVisible(false);
    };

    const listagemRef = useRef();

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_CAIXAMOV_MOVIMENTAR]}
                    componente={Button}
                    icon="pi pi-eye"
                    className="p-button-rounded p-button-success p-mr-2 p-mb-1"
                    onClick={() =>
                        abrirModal({
                            header: `Lançamento N.°${rowData.id}`,
                            style: { width: "60vw" },
                            content: <FormVisualizarLancamento lancamento={rowData} />,
                        })
                    }
                />
                <MakoControleAcesso
                    permissao={[permissoes.FINANCEIRO_FINANCEIRO_CAIXAMOV_MOVIMENTAR]}
                    componente={SplitButton}
                    dropdownIcon="pi pi-cog"
                    className="p-button-rounded p-button-secondary p-mr-2 p-mb-1"
                    model={[
                        {
                            label: "Editar Hist",
                            command: () =>
                                abrirModal({
                                    header: `Editar histórico - Lançamento N.°${rowData.id}`,
                                    style: { width: "60vw" },
                                    content: (
                                        <FormEditarHistorico
                                            movimentacao={rowData}
                                            handleClose={fecharModal}
                                            onUpdate={handleUpdateLancamento}
                                        />
                                    ),
                                }),
                        },
                        {
                            label: "Estornar",
                            disabled: true,
                        },
                        {
                            label: "Exportar",
                            disabled: true,
                        },
                    ]}
                    buttonProps={{ style: { display: "none" } }}
                    menuButtonProps={{ className: "p-button-rounded" }}
                />
            </div>
        );
    };

    const handleUpdateLancamento = () => {
        listagemRef.current?.buscarDados();
    };

    const tipoMovimentoTemplate = (tipo) => {
        return NATUREZA_PLANO_CONTAS.filter((item) => item.id === tipo)[0].label;
    };

    const getTypeConta = (caixa) => {
        if (caixa.conta_financeira) {
            return "conta_financeira";
        }

        if (caixa.conta_bancaria) {
            return "conta_bancaria";
        }

        if (caixa.conta_poupanca) {
            return "conta_poupanca";
        }

        if (caixa.conta_investimento) {
            return "conta_investimento";
        }
    };

    const formatContaVinculada = (caixa) => {
        const tipoConta = getTypeConta(caixa);

        return `${caixa[tipoConta].tipo_conta.descricao} | ${caixa[tipoConta].descricao}`;
    };

    const atualizarResumo = useCallback(async () => {
        showLoading();
        const response = await axiosPost(`/financeiro/gerar-resumo-caixa-movimento/${caixaMov.id}`);
        hideLoading();

        if (response.status === 201) {
            await handleCaixa();
        } else {
            showError({
                summary: "Erro :(",
                detail: "Desculpe, não conseguimos processar sua requisição.",
                life: 3000,
            });
        }
    }, [showLoading, hideLoading, showError, caixaMov, handleCaixa]);

    const colunas = [
        {
            field: "id",
            header: "Lançamento",
        },
        {
            field: "historico",
            header: "Histórico",
        },
        {
            field: "usuario_lancamento.nome",
            header: "Credor / Sacado",
        },
        {
            field: "tipo_movimento",
            header: "Forma Pgto/Rebto",
            action: (e) => tipoMovimentoTemplate(e.tipo_movimento),
        },
        {
            field: "valor",
            header: "Valor",
            money: true,
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
        },
    ];

    const rowClass = (movimentacao) => {
        return {
            "mako-table-effective-text": movimentacao.valor > 0,
            "mako-table-overdue-text": movimentacao.valor < 0,
        };
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => handleCaixa(), []);

    return (
        <div>
            <div className="p-grid">
                {caixaMov ? (
                    <div className="p-col-12">
                        <div className="card">
                            <h5>
                                Informações do caixa{" "}
                                {caixaMov.bloqueado ? (
                                    <i className="pi pi-lock" style={{ color: "#D32F2F" }} />
                                ) : (
                                    <i className="pi pi-unlock" style={{ color: "#689F38" }} />
                                )}
                            </h5>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-1">
                                    <label htmlFor="id">ID</label>
                                    <InputText id="id" name="id" value={caixaMov.id} disabled />
                                </div>
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="conta">Conta vinculada</label>
                                    <InputText
                                        id="conta"
                                        name="conta"
                                        value={formatContaVinculada(caixaMov.caixa)}
                                        disabled
                                    />
                                </div>
                                <div className="p-field p-col-12 p-md-1">
                                    <label htmlFor="codigo_caixa">Código</label>
                                    <InputText
                                        id="codigo_caixa"
                                        name="codigo_caixa"
                                        value={caixaMov.caixa[getTypeConta(caixaMov.caixa)].codigo}
                                        disabled
                                    />
                                </div>
                                <div className="p-field p-col-12 p-md-3">
                                    <label htmlFor="operador">Operador atual</label>
                                    <InputText id="operador" name="operador" value={caixaMov.operador.nome} disabled />
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="data_abertura">Data de abertura</label>
                                    <MakoCalendar
                                        id="data_abertura"
                                        name="data_abertura"
                                        valueCalendar={parseData(caixaMov.data_abertura)}
                                        disabled
                                    />
                                </div>
                                <div className="p-field p-col-12 p-md-2">
                                    <label htmlFor="hora_abertura">Hora abertura</label>
                                    <InputText
                                        id="hora_abertura"
                                        name="hora_abertura"
                                        value={caixaMov.hora_abertura.slice(0, 5)}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="card">
                                <div className="p-fluid p-formgrid p-grid">
                                    <div className="p-field p-col-12 p-md-3">
                                        <MakoControleAcesso
                                            componente={Button}
                                            permissao={[
                                                permissoes.FINANCEIRO_FINANCEIRO_CAIXAMOV_FECHAR,
                                                permissoes.FINANCEIRO_FINANCEIRO_CAIXAMOV_DESBLOQUEAR,
                                                permissoes.FINANCEIRO_FINANCEIRO_CAIXAMOV_DESBLOQUEAR_TERCEIRO,
                                            ]}
                                            label="Fechamento"
                                            className="p-mr-2"
                                            exibirDesabilitadoSemPermissao={true}
                                            onClick={() =>
                                                abrirModal({
                                                    header: "Fechar o caixa",
                                                    style: { width: "80vw" },
                                                    content: (
                                                        <FormFechar
                                                            fecharModal={fecharModal}
                                                            handleCaixa={handleCaixa}
                                                        />
                                                    ),
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="p-field p-col-12 p-md-3">
                                        <MakoControleAcesso
                                            componente={Button}
                                            permissao={[
                                                permissoes.FINANCEIRO_FINANCEIRO_CAIXAMOV_BLOQUEAR,
                                                permissoes.FINANCEIRO_FINANCEIRO_CAIXAMOV_BLOQUEAR_TERCEIRO,
                                            ]}
                                            label="Bloqueio"
                                            className="p-mr-2"
                                            onClick={() =>
                                                abrirModal({
                                                    header: `Bloquear o caixa '${caixaMov.caixa.nome}'`,
                                                    style: { width: "80vw" },
                                                    content: <FormBloquear fecharModal={fecharModal} />,
                                                })
                                            }
                                            disabled={caixaMov?.bloqueado}
                                        />
                                    </div>
                                    <div className="p-field p-col-12 p-md-3">
                                        <MakoControleAcesso
                                            componente={Button}
                                            permissao={[permissoes.FINANCEIRO_FINANCEIRO_CAIXAMOV_SANGRAR]}
                                            label="Sangria"
                                            className="p-mr-2"
                                            disabled={!padraoPlanos.sangria || caixaMov.bloqueado}
                                            onClick={() =>
                                                abrirModal({
                                                    header: "Efetuar sangria",
                                                    content: <FormSangria fecharModal={fecharModal} />,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="p-field p-col-12 p-md-3">
                                        <MakoControleAcesso
                                            componente={Button}
                                            permissao={[permissoes.FINANCEIRO_FINANCEIRO_CAIXAMOV_TRANSFERIR]}
                                            label="Transferência"
                                            disabled={!padraoPlanos?.transferencia || caixaMov.bloqueado}
                                            onClick={() =>
                                                abrirModal({
                                                    header: "Efetuar transferência",
                                                    style: { width: "60vw" },
                                                    content: <FormTransferencia fecharModal={fecharModal} />,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="p-fluid p-formgrid p-grid p-mt-4">
                                    <div className="p-field p-col-12 p-md-3">
                                        <Button
                                            label="Ocultar movimentação"
                                            className="p-mr-2"
                                            onClick={() => setOcultar((prev) => !prev)}
                                        />
                                    </div>
                                    <div className="p-field p-col-12 p-md-3">
                                        <MakoControleAcesso
                                            componente={Button}
                                            permissao={[
                                                permissoes.FINANCEIRO_FINANCEIRO_CAIXAMOV_DESBLOQUEAR,
                                                permissoes.FINANCEIRO_FINANCEIRO_CAIXAMOV_DESBLOQUEAR_TERCEIRO,
                                            ]}
                                            label="Desbloqueio"
                                            className="p-mr-2"
                                            onClick={() =>
                                                abrirModal({
                                                    header: `Desbloquear o caixa '${caixaMov.caixa.nome}'`,
                                                    style: { width: "55vw" },
                                                    content: (
                                                        <FormDesbloquear
                                                            fecharModal={fecharModal}
                                                            handleCaixa={handleCaixa}
                                                        />
                                                    ),
                                                })
                                            }
                                            disabled={!caixaMov.bloqueado}
                                        />
                                    </div>
                                    <div className="p-field p-col-12 p-md-3">
                                        <MakoControleAcesso
                                            componente={Button}
                                            permissao={[permissoes.FINANCEIRO_FINANCEIRO_CAIXAMOV_SUPRIR]}
                                            label="Suprimento"
                                            disabled={!padraoPlanos?.suprimento || caixaMov.bloqueado}
                                            className="p-mr-2"
                                            onClick={() =>
                                                abrirModal({
                                                    header: "Efetuar suprimento",
                                                    content: <FormSuprimento fecharModal={fecharModal} />,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="p-field p-col-12 p-md-3">
                                        <MakoControleAcesso
                                            componente={Button}
                                            permissao={[permissoes.RELATORIOS_EMITIR_CAIXA_MOVIMENTO_RESUMO]}
                                            label="Resumo"
                                            className="p-mr-2"
                                            onClick={() =>
                                                abrirModal({
                                                    header: null,
                                                    style: { width: "60vw" },
                                                    content: <FormResumoCaixa onFinish={fecharModal} />,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            {!ocultar && (
                                <>
                                    <span className="p-mr-2">Saldo atual:</span>
                                    <div>
                                        <MakoInputMoeda
                                            disabled
                                            valueMoeda={
                                                parseFloat(caixaMov.dinheiro_inicial) +
                                                caixaMov.resumos.reduce(
                                                    (total, item) => total + parseFloat(item.valor),
                                                    0
                                                )
                                            }
                                        />
                                        <Button
                                            icon={`pi ${loading ? "pi-spin pi-spinner" : "pi-sync"}`}
                                            tooltip="Atualizar saldo"
                                            className="p-button-info p-ml-2"
                                            type="button"
                                            onClick={atualizarResumo}
                                        />
                                    </div>
                                    <MakoListagem
                                        ref={listagemRef}
                                        titulo="Movimentação de caixa"
                                        colunas={colunas}
                                        responsiva
                                        urlPesquisa={`/financeiro/movimentacao-contas-corrente/?ordering=-id&conta_financeira=${caixaMov.caixa.conta_financeira.id}`}
                                        mostrarGlobalSearch
                                        configTabela={{
                                            rowClassName: rowClass,
                                            paginator: true,
                                            lazy: true,
                                        }}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="p-col-12">
                        <div className="card">
                            <h5>Caixa</h5>
                            <MakoControleAcesso
                                componente={Button}
                                permissao={[
                                    permissoes.FINANCEIRO_FINANCEIRO_CAIXAMOV_MOVIMENTAR,
                                    permissoes.FINANCEIRO_FINANCEIRO_CAIXAMOV_ABRIR,
                                ]}
                                exibirDesabilitadoSemPermissao={true}
                                label="Abrir o caixa"
                                onClick={() =>
                                    abrirModal({
                                        header: "Abrir caixa",
                                        style: { width: "80vw" },
                                        content: <FormAbrir fecharModal={fecharModal} handleCaixa={handleCaixa} />,
                                    })
                                }
                            />
                        </div>
                    </div>
                )}
                <Dialog visible={visible} onHide={fecharModal} {...modalProps}>
                    {modalProps.content}
                </Dialog>
            </div>
        </div>
    );
};

export const GerenciarCaixa = () => {
    return (
        <CaixaMovimentoProvider>
            <Content />
        </CaixaMovimentoProvider>
    );
};
