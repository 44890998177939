import React, { useImperativeHandle, useState, forwardRef } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoInputPerfil } from "@/components/MakoInputs/MakoInputPerfil";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import { axiosPost } from "@/services/http";
import useToast from "@/hooks/useToast";

const Modal = ({ principal, onGravar }, ref) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const { showSuccess, showError } = useToast();

    const formik = useFormik({
        initialValues: {
            perfil: null,
            empresa_origem_hierarquia: null,
            empresa_tributo: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                perfil: Yup.object()
                    .typeError("Selecione uma pessoa física / jurídica.")
                    .required("O campo 'nova pessoa física / jurídica' é obrigatório."),
                empresa_origem_hierarquia: Yup.number().nullable().notRequired(),
                empresa_tributo: Yup.number().nullable().notRequired(),
            });
            const { perfil, ...rest } = await formSchema.validate(values, { abortEarly: false });
            const body = {
                ...rest,
                perfil: perfil.id,
                principal,
            };
            setLoading(true);
            const { status } = await axiosPost("/pessoas/grupos-perfis/", body);
            setLoading(false);
            if (status === 201) {
                showSuccess({
                    summary: "Sucesso!",
                    detail: "Pessoa vinculada com sucesso!",
                    life: 1500,
                });
                setTimeout(() => {
                    if (typeof onGravar === "function") onGravar();
                    fecharModal();
                }, 2000);
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não foi possível vincular a pessoa ao sistema.",
                    life: 3000,
                });
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const fecharModal = () => {
        formik.resetForm();
        setVisible(false);
    };

    const abrirModal = () => {
        setVisible(true);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const footer = (
        <div>
            <Button type="submit" label="Vincular" icon="pi pi-save" loading={loading} onClick={formik.handleSubmit} />
        </div>
    );

    return (
        <Dialog
            header="Incluindo pessoa física / jurídica ao gerenciamento do sistema"
            visible={visible}
            onHide={fecharModal}
            style={{ width: "50vw" }}
            footer={footer}
        >
            <form>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="pessoa">Nova pessoa física / jurídica</label>
                        <MakoInputPerfil
                            id="pessoa"
                            name="perfil"
                            value={formik.values.perfil}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.perfil })}
                        />
                        {formik.errors.perfil && <small className="p-error">{formik.errors.perfil}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="tributos">Importar cadastro de tributos de</label>
                        <MakoDropdownEmpresas
                            id="tributos"
                            name="empresa_tributo"
                            disabled={principal}
                            value={formik.values.empresa_tributo}
                            onChange={(e) => formik.setFieldValue("empresa_tributo", e.id)}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="hierarquia-funcional">Utilizar hierarquia funcional de</label>
                        <MakoDropdownEmpresas
                            id="hierarquia-funcional"
                            name="empresa_origem_hierarquia"
                            disabled={principal}
                            value={formik.values.empresa_origem_hierarquia}
                            onChange={(e) => formik.setFieldValue("empresa_origem_hierarquia", e.id)}
                        />
                    </div>
                </div>
            </form>
        </Dialog>
    );
};

export const ModalVincularPessoas = forwardRef(Modal);
