import React, { createContext, useCallback, useState } from "react";
import { addDays } from "date-fns";
import { axiosGet, axiosPatch, axiosPost } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import { dataToStr } from "@/assets/util/datas";
import { parseNumber } from "@/assets/helpers/number";
import { gerarParcelas } from "@/assets/util/calcFinanceiros";
import { formatarCasasDecimais } from "@/assets/util/util";
import useToast from "@/hooks/useToast";

const PagamentoContext = createContext({});

export const PagamentoProvider = ({ children }) => {
    const [submit, setSubmit] = useState(false);
    const [submitParcelas, setSubmitParcelas] = useState([]);
    const [pagamento, setPagamento] = useState(null);
    const [pagamentos, setPagamentos] = useState(null);
    const [previsoes, setPrevisoes] = useState([]);
    const { showLoading, hideLoading } = useLoading();
    const { showError } = useToast();

    const handleSubmitPagamento = useCallback((dados) => {
        setPagamento(dados);
        setSubmit(true);
        setPagamentos([]);
    }, []);

    const handleLancarParcelas = (value) => {
        const { tipo_parcela, repeticao, quantidade, data_primeira_parcela, ...configParcela } = value;
        const qtdParcelas = parseNumber(quantidade);
        let valorParcelas = [];
        if (tipo_parcela === "dividir") {
            valorParcelas = gerarParcelas(pagamento.valor_total, qtdParcelas);
        } else {
            valorParcelas.push(pagamento.valor_total);
        }
        const _parcelas = [];
        const { historico_padrao, valor_total, ...pag } = pagamento;
        const agrupador = new Date().getTime();
        for (let numParcela = 0; numParcela < qtdParcelas; numParcela++) {
            _parcelas.push({
                ...pag,
                ...configParcela,
                agrupador,
                historico_padrao: historico_padrao.id,
                historico_original: historico_padrao.descricao,
                vencimento: addDays(data_primeira_parcela, parseNumber(repeticao) * numParcela),
                quantidade_parcelas: qtdParcelas,
                numero_parcela: numParcela + 1,
                valor_total: tipo_parcela === "dividir" ? valor_total : valor_total * qtdParcelas,
                valor: tipo_parcela === "dividir" ? valorParcelas[numParcela] : valorParcelas[0],
            });
        }
        setPagamentos(_parcelas);
        setSubmitParcelas(true);
    };

    const handleEditarPagamento = useCallback(
        async (values, index) => {
            const { vencimento, valor, historico_original, previsao } = values;
            const _pagamentos = [...pagamentos];
            _pagamentos[index] = {
                ...pagamentos[index],
                vencimento,
                valor,
                historico_original,
                previsao,
                _editado: true,
            };
            const totalParcelas = _pagamentos.reduce((total, pag) => total + parseNumber(pag.valor), 0);
            setSubmitParcelas(parseNumber(totalParcelas) === parseNumber(_pagamentos[0].valor_total));
            setPagamentos(_pagamentos);
        },
        [pagamentos]
    );

    const handlePreencherPagamento = useCallback(
        async (idPagamento) => {
            showLoading();
            const { status, data } = await axiosGet(`/financeiro/pagamentos?documento=${idPagamento}`);
            hideLoading();
            if (status === 200) {
                if (data.results?.length > 0) {
                    setPagamento(data.results[0]);
                    setPagamentos(data.results);
                    setSubmit(true);
                    setSubmitParcelas(true);
                } else {
                    showError({
                        summary: "Erro",
                        detail: "Desculpe, não foi possível carregar os pagamentos.",
                        life: 3000,
                    });
                }
            }
        },
        [showLoading, hideLoading, showError]
    );

    const handlePagamentos = useCallback(async () => {
        const body = pagamentos.map(
            ({ fracionamentopagamento_set, pagamentoefetivado_set, previsaopagamento_set, ...pag }) => ({
                ...pag,
                credor: pag.credor.id,
                forma_pagamento: typeof pag.forma_pagamento === "object" ? pag.forma_pagamento.id : pag.forma_pagamento,
                data_lancamento: dataToStr(pag.data_lancamento, "yyyy-MM-dd"),
                data_emissao: dataToStr(pag.data_emissao, "yyyy-MM-dd"),
                vencimento: dataToStr(pag.vencimento, "yyyy-MM-dd"),
                historico_padrao:
                    typeof pag.historico_padrao === "object" ? pag.historico_padrao.id : pag.historico_padrao,
                valor: formatarCasasDecimais(pag.valor),
            })
        );

        if (!pagamento.id) {
            const { status, data } = await axiosPost("/financeiro/pagamentos/", body);
            return { status, data };
        } else {
            const alterados = body.filter((pag) => pag._editado);
            return await Promise.all(
                alterados.map(async (pag) => {
                    const { status, data } = await axiosPatch(`/financeiro/pagamentos/${pag.id}/`, pag);
                    return { status, data };
                })
            );
        }
    }, [pagamento, pagamentos]);

    return (
        <PagamentoContext.Provider
            value={{
                submit,
                submitParcelas,
                pagamento,
                pagamentos,
                previsoes,
                setPagamento,
                setPrevisoes,
                setSubmit,
                handleSubmitPagamento,
                handleLancarParcelas,
                handleEditarPagamento,
                handlePagamentos,
                handlePreencherPagamento,
            }}
        >
            {children}
        </PagamentoContext.Provider>
    );
};

export default PagamentoContext;
