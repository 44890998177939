import React, { useCallback, useEffect, useImperativeHandle, useState, forwardRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import { Dropdown } from "@/components/Dropdown";
import { axiosGet, axiosPatch } from "@/services/http";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";

const Modal = ({ operacaoFiscalId }, ref) => {
    const [visible, setVisible] = useState(false);
    const [listaItens, setListaItens] = useState([]);
    const [raiz, setRaiz] = useState(null);
    const [alvo, setAlvo] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();

    const abrirModal = () => {
        setVisible(true);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    async function importarTributos() {
        try {
            showLoading();
            const dados = await axiosGet(`/fiscal/itens-operacoes-fiscais/${raiz}/`);
            hideLoading();
            const skuBr = dados.data.skuoperacaofiscalbr_set[0];
            const skuUf = dados.data.skuoperacaofiscalbr_set[0];

            const body = {
                skuoperacaofiscalbr_set: {
                    update: {
                        [alvo.skuoperacaofiscalbr_set[0].id]: {
                            ...skuBr,
                            id: null,
                            cst_cofins: skuBr.cst_cofins?.id,
                            cst_ipi: skuBr.cst_ipi?.id,
                            cst_pis: skuBr.cst_pis?.id,
                            enquadramento_ipi: skuBr.enquadramento_ipi?.id,
                        },
                    },
                },
                skuoperacaofiscaluf_set: {
                    update: {
                        [alvo.skuoperacaofiscalbr_set[0].id]: { ...skuUf, id: null },
                    },
                },
            };

            showLoading();
            const resposta = await axiosPatch(`/fiscal/itens-operacoes-fiscais/${alvo.id}/`, body);
            hideLoading();

            if (resposta?.status === 200) {
                showSuccess({
                    summary: "Sucesso",
                    detail: "Tributos importados com sucesso!",
                    life: 1500,
                });
                setVisible(false);
            } else {
                showError({
                    summary: "Erro",
                    detail: "Desculpe, não conseguimos processar a sua requisição.",
                    life: 3000,
                });
            }
        } catch (error) {
            showError({
                summary: "Erro",
                detail: "Desculpe, não conseguimos processar a sua requisição.",
                life: 3000,
            });
        }
    }

    const listarItens = useCallback(async () => {
        showLoading();
        const resposta = await axiosGet(
            `/fiscal/itens-operacoes-fiscais?operacao_fiscal=${operacaoFiscalId}&limit=10000`
        );
        hideLoading();

        if (resposta.status === 200) {
            setListaItens(resposta.data.results);
        }
    }, [showLoading, hideLoading, operacaoFiscalId]);

    function optionValidate(value) {
        if (value.id === alvo?.id) return true;
        return false;
    }

    useEffect(() => {
        setRaiz(null);
    }, [alvo]);

    useEffect(() => {
        listarItens();
    }, [listarItens]);

    return (
        <Dialog
            header="Importar Tributos do Produto/Mercadoria"
            visible={visible}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "45vw", display: "block" }}
            onHide={() => setVisible(false)}
        >
            <form>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="item">Produto/mercadoria alvo: </label>
                        <Dropdown
                            id="item"
                            name="item"
                            value={alvo}
                            placeholder="Selecione..."
                            optionLabel="sku.descricao_reduzida"
                            options={listaItens}
                            onChange={(e) => setAlvo(e.target.value)}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="importar">Importar dados de:</label>
                        <Dropdown
                            id="importar"
                            name="importar"
                            value={raiz}
                            placeholder="Selecione..."
                            optionLabel="sku.descricao_reduzida"
                            optionValue="id"
                            className="p-mr-2"
                            optionDisabled={optionValidate}
                            options={listaItens}
                            disabled={!alvo}
                            onChange={(e) => setRaiz(e.target.value)}
                        />
                    </div>
                </div>
                <div className="p-grid p-justify-center p-col-12 p-md-12">
                    <Button
                        label="Importar"
                        type="button"
                        icon="pi pi-align-justify"
                        className="p-button-info p-mr-2"
                        onClick={() => importarTributos()}
                    />
                </div>
            </form>
        </Dialog>
    );
};

export const ModalImportarTributos = forwardRef(Modal);
