import React, { createContext, useCallback, useEffect, useState } from "react";
import { add } from "date-fns";

import { axiosDelete, axiosGet, axiosPost, axiosPatch } from "@/services/http";
import { dataToStr, horaToStr } from "@/assets/util/datas";
import * as pd from "@/assets/util/persistenciaDjango";
import { formatarCasasDecimais, maxDiasEmUmMes } from "@/assets/util/util";
import { VENDA_TIPOMOVIMENTACAO_PADRAO } from "@/assets/constants/parametros";
import { gerarParcelas } from "@/assets/util/calcFinanceiros";
import { parseNumber } from "@/assets/helpers/number";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";
import useParam from "@/hooks/useParam";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";

const VendaContext = createContext({});

export const VendaProvider = ({ children }) => {
    const [dadosBasicos, setDadosBasicos] = useState(null);
    const [parcelasVenda, setParcelasVenda] = useState([]);
    const [vendedor, setVendedor] = useState(null);
    const { getParam } = useParam();
    const { empresaSelecionadaId } = useEmpresa();
    const { showWarning, showError } = useToast();
    const [formatarDocumento] = useFormatCNPJCPF();

    const carregarPapelPerfilVendedor = useCallback(async () => {
        if (dadosBasicos?.vendedor) {
            const { status, data } = await axiosGet(
                `/pessoas/perfis/${dadosBasicos?.vendedor}/?query={vinculoperfilpapel_set}`
            );
            if (status === 200) {
                const papelVendedor = data.vinculoperfilpapel_set.find(
                    (vinculo) => vinculo.papel.chave.id === "VND" && vinculo.ativo
                );
                if (papelVendedor) {
                    setVendedor(papelVendedor);
                } else {
                    showWarning({
                        summary: "Aviso!",
                        detail: "Papel perfil de vendedor não encontrado.",
                        life: 3000,
                    });
                }
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não conseguimos buscar os papéis perfis do vendedor.",
                    life: 3000,
                });
            }
        }
    }, [dadosBasicos, showWarning, showError]);

    useEffect(() => {
        carregarPapelPerfilVendedor();
    }, [carregarPapelPerfilVendedor]);

    const handleDadosBasicos = useCallback(
        async (values) => {
            const tipoMovEstoquePadrao = getParam(VENDA_TIPOMOVIMENTACAO_PADRAO);
            if (!values.id) {
                const novaVenda = {
                    ...values,
                    cliente: values.cliente.id,
                    data: dataToStr(values.data, "yyyy-MM-dd"),
                    hora: horaToStr(values.hora, "HH:mm:ss"),
                    empresa: empresaSelecionadaId,
                    tipo_movimentacao: tipoMovEstoquePadrao.valor,
                    quantidade_parcelas: 0,
                };
                const json = await axiosPost("/vendas/vendas/", novaVenda);
                setDadosBasicos(json.data);
                return json;
            } else {
                const { vendedor, supervisor, observacoes, plano_recebimento } = values;
                const vendaEditada = {
                    data: dataToStr(values.data, "yyyy-MM-dd"),
                    hora: horaToStr(values.hora, "HH:mm:ss"),
                    vendedor,
                    supervisor,
                    plano_recebimento,
                    observacoes,
                };
                let diffVenda = {};
                Object.entries(vendaEditada).forEach(([k, v]) => {
                    if (v !== dadosBasicos[k]) {
                        diffVenda[k] = v;
                    }
                });
                if (Object.keys(diffVenda).length > 0) {
                    const json = await axiosPatch(`/vendas/vendas/${values.id}/`, diffVenda);
                    if (json.status === 200) setDadosBasicos(json.data);
                    return json;
                }
                return { status: 204, data: values };
            }
        },
        [getParam, dadosBasicos, empresaSelecionadaId]
    );

    const addValorItemVenda = useCallback(
        (item) => {
            const { valor_total_av, valor_total_ap, valor_desconto, ...rest } = dadosBasicos;
            setDadosBasicos({
                ...rest,
                valor_total_av: parseNumber(valor_total_av) + item.valor_total_av,
                valor_total_ap: parseNumber(valor_total_ap) + item.valor_total_ap,
                valor_desconto: parseNumber(valor_desconto) + item.desconto,
            });
        },
        [dadosBasicos]
    );

    const subtrairValorItemVenda = useCallback(
        (item) => {
            const { valor_total_av, valor_total_ap, valor_desconto, ...rest } = dadosBasicos;
            setDadosBasicos({
                ...rest,
                valor_total_av: parseNumber(valor_total_av) - item.valor_total_av,
                valor_total_ap: parseNumber(valor_total_ap) - item.valor_total_ap,
                valor_desconto: parseNumber(valor_desconto) - item.desconto,
            });
        },
        [dadosBasicos]
    );

    const handleItemVenda = useCallback(
        async (values, op) => {
            const item = {
                ...values,
                venda: dadosBasicos.id,
                sku: values.sku.id,
                sku_movimenta_estoque: values.sku.sku_controle_saldo.id,
                tabela_preco: values.tabela_preco?.id,
                cubagem: formatarCasasDecimais(values.cubagem, 5),
                desconto: formatarCasasDecimais(values.desconto),
            };
            if (op === pd.OP_CRUD_DJANGO.novo) {
                const { status } = await axiosPost("/vendas/itens-vendas/", item);
                if (status === 201) {
                    addValorItemVenda(item);
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não foi possível inserir o item na venda.",
                        life: 3000,
                    });
                }
            } else if (op === pd.OP_CRUD_DJANGO.deletar) {
                const { status } = await axiosDelete(`/vendas/itens-vendas/${values.id}/`);
                if (status === 204) {
                    subtrairValorItemVenda(item);
                } else {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não foi possível remover o item na venda.",
                        life: 3000,
                    });
                }
            }
        },
        [dadosBasicos, addValorItemVenda, subtrairValorItemVenda, showError]
    );

    const handleLimparParcelas = useCallback(
        async (onSuccess) => {
            const { status } = await axiosDelete(`/vendas/${dadosBasicos.id}/deletar-parcelas/`);
            if (status === 204) {
                if (typeof onSuccess === "function") await onSuccess();
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não foi possível deletar as parcelas anteriores.",
                    life: 3000,
                });
            }
        },
        [dadosBasicos, showError]
    );

    const handleParcelasVenda = useCallback(
        async (parcela, plano_recebimento) => {
            let _parcelas = [];
            const intervaloParcela = plano_recebimento.intervalo_parcelas.split(",");
            const percentMinEntrada = parseNumber(plano_recebimento.percentual_minimo_entrada);
            const valor_minimo_entrada = formatarCasasDecimais(
                parcela.valor_total_com_desconto * (percentMinEntrada / 100)
            );
            if (parcela.valor_entrada > 0) {
                _parcelas.push({
                    ...parcela,
                    numero_parcela: 0,
                    quantidade_parcelas: parcela.quantidade_parcelas + 1,
                    data_vencimento: parcela.data_venc_entrada,
                    valor_parcela: parcela.valor_entrada,
                    forma_recebimento: parcela.forma_recebimento_entrada,
                    valor_minimo_entrada,
                });
            }
            if (parcela.valor_financiado > 0) {
                const valoresParcelas = gerarParcelas(parcela.valor_financiado, parcela.quantidade_parcelas);
                valoresParcelas.forEach((vlrParcela, index) => {
                    const dias1Parcela = index === 0 ? 0 : parseInt(intervaloParcela[index]);
                    let data_vencimento = add(parcela.data_vencimento, { days: dias1Parcela });
                    if (plano_recebimento.dia_vencimento && plano_recebimento.dia_vencimento !== 0) {
                        const dataAux = add(parcela.data_vencimento, { months: index });
                        let diaVencParcela = plano_recebimento.dia_vencimento;
                        const mesVencParcela = dataAux.getMonth() + 1;
                        const anoVencParcela = dataAux.getFullYear();
                        const maxDiaMesParcela = maxDiasEmUmMes(mesVencParcela, anoVencParcela);
                        if (diaVencParcela > maxDiaMesParcela) diaVencParcela = maxDiaMesParcela;
                        data_vencimento = new Date(anoVencParcela, mesVencParcela - 1, diaVencParcela);
                    }
                    _parcelas.push({
                        ...parcela,
                        numero_parcela: index + 1,
                        quantidade_parcelas:
                            parcela.valor_entrada > 0 ? parcela.quantidade_parcelas + 1 : parcela.quantidade_parcelas,
                        data_vencimento,
                        valor_parcela: vlrParcela,
                        valor_minimo_entrada,
                    });
                });
            }
            const popularParcelas = async () => {
                const parcelas = _parcelas.map((p) => ({
                    ...p,
                    venda: dadosBasicos?.id,
                    forma_recebimento: p.forma_recebimento.id,
                    data_vencimento: dataToStr(p.data_vencimento, "yyyy-MM-dd"),
                }));
                const { status } = await axiosPost("/vendas/parcelas-vendas/", parcelas);
                if (status !== 201) {
                    showError({
                        summary: "Erro :(",
                        detail: "Desculpe, não foi possível lançar as parcelas.",
                        life: 3000,
                    });
                }
            };
            await handleLimparParcelas(popularParcelas);
        },
        [dadosBasicos, showError, handleLimparParcelas]
    );

    const redefinirDataVencimentoParcela = (dataVencimentoParcela, diaVencimento) => {
        const [anoVencParcela, mesVencParcela] = dataVencimentoParcela.split("-");
        const maxDiaMesParcela = maxDiasEmUmMes(mesVencParcela, anoVencParcela);
        const diaVencParcela = diaVencimento <= maxDiaMesParcela ? diaVencimento : maxDiaMesParcela;
        return `${anoVencParcela}-${mesVencParcela}-${diaVencParcela}`;
    };

    const handleAlterarParcelas = useCallback(
        async (values) => {
            let _parcelas = parcelasVenda;
            const { valor_total_av, valor_frete } = dadosBasicos;
            const valorSemEncargos = parseNumber(valor_total_av) + parseNumber(valor_frete);
            if (values.replicar) {
                const novoValorTotalParcelas = _parcelas.reduce((total, parcela) => {
                    if (parcela.numero_parcela !== 0) return total + parseNumber(parcela.valor_parcela);
                    return total;
                }, 0);
                if (novoValorTotalParcelas < valorSemEncargos) return false;
                const novasParcelas = _parcelas.map((parcela) => {
                    const data_vencimento = redefinirDataVencimentoParcela(
                        parcela.data_vencimento,
                        values.dia_vencimento
                    );
                    return {
                        ...parcela,
                        forma_recebimento: parcela.forma_recebimento.id,
                        valor_parcela: values.valor_parcela,
                        data_vencimento,
                    };
                });
                await Promise.all(
                    novasParcelas.map(async (parcela) => {
                        const { status } = await axiosPatch(`/vendas/parcelas-vendas/${parcela.id}/`, parcela);
                        if (status !== 200) {
                            showError({
                                summary: "Erro :(",
                                detail: `Não foi possível gravar as alterações na parcela número ${parcela.numero_parcela}.`,
                                life: 3000,
                            });
                        }
                    })
                );
            } else {
                const valorAtualParcelas = _parcelas.reduce((total, parcela) => {
                    if (parcela.id === values.id_parcela) return total;
                    return total + parseNumber(parcela.valor_parcela);
                }, 0);
                if (valorAtualParcelas + values.valor_parcela < valorSemEncargos) return false;
                const _parcela = _parcelas.find((parcela) => parcela.id === values.id_parcela);
                _parcela.data_vencimento = redefinirDataVencimentoParcela(
                    _parcela.data_vencimento,
                    values.dia_vencimento
                );
                _parcela.valor_parcela = values.valor_parcela;
                const { status } = await axiosPatch(`/vendas/parcelas-vendas/${_parcela.id}/`, _parcela);
                if (status !== 200) {
                    showError({
                        summary: "Erro :(",
                        detail: `Não foi possível gravar as alterações na parcela número ${_parcela.numero_parcela}.`,
                        life: 3000,
                    });
                }
            }
            return true;
        },
        [dadosBasicos, parcelasVenda, showError]
    );

    const buscarVendaBancoDeDados = useCallback(
        async (vendaId) => {
            const { status, data } = await axiosGet(`/vendas/vendas/${vendaId}/`);
            if (status === 200) {
                setDadosBasicos({
                    ...data,
                    cliente: {
                        ...data.cliente,
                        label: `${data.cliente.nome} - ${formatarDocumento(data.cliente.identificacao)}`,
                    },
                });
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não foi possível localizar a venda.",
                    life: 3000,
                });
            }
        },
        [showError, formatarDocumento]
    );

    return (
        <VendaContext.Provider
            value={{
                dadosBasicos,
                vendedor,
                vendaFinalizada: !!(dadosBasicos?.situacao === "F"),
                bloqueiaItens: !!(dadosBasicos?.sequencia_estagio_venda?.altera_itens === false),
                parcelasVenda,
                setParcelasVenda,
                handleDadosBasicos,
                handleItemVenda,
                handleParcelasVenda,
                handleAlterarParcelas,
                handleLimparParcelas,
                buscarVendaBancoDeDados,
                // buscarTroca,
            }}
        >
            {children}
        </VendaContext.Provider>
    );
};

export default VendaContext;
